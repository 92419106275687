import React, { useEffect, useState } from "react";
import { isInteger } from "lodash";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { useStoreState, useStoreActions } from "easy-peasy";
import { fetchSchoolDismissals } from "services/apiSchools";
import { randKey, strPresent } from "lib/util";
import { GRADES } from "utils/constants";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import SchoolCode from "components/admin_v2/ui/SchoolCode";
import usePrefixedInput from "lib/usePrefixedInput";

const StudentDetailsForm = ({ student, formStore, cls }) => {
  const [formState, formActions] = formStore;
  const { details, errors } = formState;

  const [programs, setPrograms] = useState([]);
  const [dismissalGroups, setDismissalGroups] = useState([]);
  const { school, schools, hasMultipleSchools } = useStoreState((s) => s.app);
  const { changeSchool } = useStoreActions((s) => s.app);
  const isExistingStudent = !!student.id;

  const [sisIdValue, updateSisId] = usePrefixedInput({
    value: details?.sisId,
    prefix: school?.code,
    update: (sisId) => formActions.setDetails({ ...details, sisId })
  });

  const onSchoolChange = (e) => {
    updateField("schoolId", e);
    changeSchool(e.target.value);
  };

  useEffect(() => {
    if (!details || !details.schoolId) return;

    fetchSchoolDismissals(details.schoolId)
      .then((r) => {
        setPrograms(r.programs);
        setDismissalGroups(r.dismissal_groups);
      })
      .catch((err) => {
        console.error("An error occured: ", err?.message);
      });
  }, [details?.schoolId]);

  if (!details) return null;

  const { id } = details;
  const schoolsList = hasMultipleSchools ? schools : [school];
  let grade;
  if (details.grade) {
    const gradeStr = details.grade.toString();
    grade = GRADES.find((g) => g === gradeStr.toUpperCase());
  }

  const updateField = (key, e) => {
    const value = e.target.value;
    const newDetails = { ...details, [key]: value };
    formActions.setDetails(newDetails);
  };

  return (
    <Grid container className={cls.grid} spacing={5}>
      <Grid item xs={6}>
        <FormControl className={cls.field} error={!!errors?.schoolId} variant="standard" fullWidth>
          <InputLabel id={`student-${id}-school-label`}>
            <RequiredLabel label="School" condition={isInteger(details.schoolId)} />
          </InputLabel>
          <Select
            labelId={`student-${id}-school-label`}
            id={`student-${id}-school`}
            value={schoolsList.length > 0 ? details.schoolId : ""}
            onChange={onSchoolChange}
            inputProps={{ readOnly: !hasMultipleSchools || isExistingStudent }}
            disabled={!hasMultipleSchools || isExistingStudent}
          >
            {schoolsList.map((school) => (
              <MenuItem key={randKey()} value={school.id}>
                {school.name}
              </MenuItem>
            ))}
          </Select>
          {errors?.schoolId && <FormHelperText>{errors?.schoolId[0]}</FormHelperText>}
        </FormControl>
        <TextField
          fullWidth
          variant="standard"
          id={`student-${id}-firstName`}
          label={<RequiredLabel label="First Name" condition={strPresent(details.firstName)} />}
          value={details.firstName || ""}
          onChange={(e) => updateField("firstName", e)}
          className={cls.field}
          error={!!errors?.firstName}
          helperText={errors?.firstName?.[0]}
        />
        <TextField
          fullWidth
          variant="standard"
          id={`student-${id}-lastName`}
          label={<RequiredLabel label="Last Name" condition={strPresent(details.lastName)} />}
          value={details.lastName || ""}
          onChange={(e) => updateField("lastName", e)}
          className={cls.field}
          error={!!errors?.lastName}
          helperText={errors?.lastName?.[0]}
        />
        <FormControl className={cls.field} error={!!errors?.grade} variant="standard" fullWidth>
          <InputLabel id={`student-${id}-grade-label`}>
            <RequiredLabel label="Grade" condition={strPresent(grade)} />
          </InputLabel>
          <Select
            labelId={`student-${id}-grade-label`}
            id={`student-${id}-grade`}
            value={grade || ""}
            onChange={(e) => updateField("grade", e)}
          >
            <MenuItem key={`grade-0`} value="">
              {" "}
            </MenuItem>
            {GRADES.map((grade) => (
              <MenuItem key={`grade-${grade}`} id={`grade-${grade}`} value={grade}>
                {grade}
              </MenuItem>
            ))}
          </Select>
          {errors?.grade && <FormHelperText>{errors?.grade[0]}</FormHelperText>}
        </FormControl>
        {!!student.id && (
          <TextField
            fullWidth
            variant="standard"
            id={`student-${id}-createdAt`}
            label="Created at"
            value={details.createdAt || ""}
            className={cls.field}
            InputProps={{
              readOnly: true
            }}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <FormControl className={cls.field} error={!!errors?.programId} variant="standard" fullWidth>
          <InputLabel id={`student-program-label`}>
            <RequiredLabel label="Program" condition={!!details.programId} />
          </InputLabel>
          <Select
            labelId={`student-program-label`}
            id={`student-program`}
            value={details.programId || ""}
            onChange={(e) => updateField("programId", e)}
          >
            <MenuItem key={randKey()} value="">
              {" "}
            </MenuItem>
            {programs.map((program) => (
              <MenuItem key={randKey()} id={`program-${program.id}`} value={program.id}>
                {program.name}
              </MenuItem>
            ))}
          </Select>
          {errors?.programId && <FormHelperText>{errors?.programId[0]}</FormHelperText>}
        </FormControl>
        <FormControl
          className={cls.field}
          error={!!errors?.dismissalGroupId}
          variant="standard"
          fullWidth
        >
          <InputLabel id={`student-${id}-group-label`}>Dismissal Group</InputLabel>
          <Select
            labelId={`student-${id}-group-label`}
            id={`student-${id}-group`}
            value={details.dismissalGroupId || ""}
            onChange={(e) => updateField("dismissalGroupId", e)}
          >
            <MenuItem key={randKey()} value="">
              {" "}
            </MenuItem>
            {dismissalGroups.map((group) => (
              <MenuItem key={randKey()} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
          {errors?.dismissalGroupId && (
            <FormHelperText>{errors?.dismissalGroupId[0]}</FormHelperText>
          )}
        </FormControl>
        <TextField
          fullWidth
          variant="standard"
          id={`student-${id}-sisId`}
          label={<RequiredLabel label="SSID" condition={strPresent(details.sisId)} />}
          value={sisIdValue}
          onChange={updateSisId}
          className={cls.field}
          error={!!errors?.sisId}
          helperText={errors?.sisId?.[0]}
          InputProps={{ startAdornment: <SchoolCode code={school?.code} /> }}
        />
        <TextField
          fullWidth
          variant="standard"
          id={`student-${id}-transportationId`}
          label="Transportation ID"
          value={details.transportationId || ""}
          onChange={(e) => updateField("transportationId", e)}
          className={cls.field}
          error={!!errors?.transportationId}
          helperText={errors?.transportationId?.[0]}
        />
        {isExistingStudent && (
          <TextField
            fullWidth
            variant="standard"
            id={`student-${id}-updatedAt`}
            label="Updated at"
            value={details.updatedAt || ""}
            className={cls.field}
            InputProps={{
              readOnly: true
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default StudentDetailsForm;
