import React, { useState, useRef } from "react";
import { Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBackIos, ArrowForwardIos, CalendarToday } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { CalendarBtn, useBtnStyles } from "./Buttons";
import { toDate } from "lib/dates";

const CalendarSelector = ({
  label,
  date = new Date(),
  onSelectDate,
  next,
  prev,
  flex = "1",
  pickerProps = {},
  disabled = false
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const pickerRef = useRef();
  const classes = useStyles({ flex });
  const clsCalendar = useBtnStyles({ thin: true });

  return (
    <Box className={classes.calSelector}>
      <CalendarBtn
        pl={1}
        onClick={() => onSelectDate(prev)}
        key="cal-prev-date"
        id="cal-prev-date"
        thin="true"
        disabled={prev === null || disabled}
      >
        <ArrowBackIos color="secondary" className={classes.calPrev} />
      </CalendarBtn>
      <Button className={classes.week} variant="outlined" disabled key="cal-week">
        {label}
      </Button>
      <CalendarBtn
        onClick={() => onSelectDate(next)}
        key="cal-next-date"
        id="cal-next-date"
        thin="true"
        disabled={disabled}
      >
        <ArrowForwardIos color="secondary" />
      </CalendarBtn>
      <Button
        className={clsCalendar.calendarBtn}
        ref={pickerRef}
        variant="outlined"
        key="cal-open"
        onClick={() => setPickerOpen(true)}
        disabled={disabled}
      >
        <CalendarToday color="secondary" />
      </Button>
      <DatePicker
        open={pickerOpen}
        onOpen={() => setPickerOpen(true)}
        onClose={() => setPickerOpen(false)}
        value={toDate(date)}
        onChange={onSelectDate}
        renderInput={(_props) => null}
        PopperProps={{ anchorEl: () => pickerRef.current, placement: "auto" }}
        disabled={disabled}
        {...pickerProps}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  calSelector: {
    flex: (props) => props.flex
  },
  calPrev: {
    position: "relative",
    left: theme.spacing(0.5)
  },
  week: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(1),
    color: `${theme.components.MuiButton.styleOverrides.outlined.color} !important`,
    borderColor: `${theme.components.MuiButton.styleOverrides.outlined.borderColor} !important`
  }
}));

export default CalendarSelector;
