import React from "react";
import { Dialog, Divider, Typography } from "@mui/material";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import { useLocalStore, useStoreActions, useStoreState } from "easy-peasy";
import { get } from "lodash";
import { useHistory } from "react-router";
import { routeWithDate } from "lib/queryString";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";
import I18n from "utils/i18n.js";
import { addTripStore } from "../stores/addTripStore";
import RouteTypeSelector from "./RouteTypeSelector";
import TripForm from "./TripForm";
import useStyles from "./useStyles";

// AddTripDialog: main dialog for adding student to selected or new route/stops.
// - RouteTypeSelector: select regular/custom route (only custom route is available for now)
// - TripForm: create or select route -> RouteNewForm || RouteForm
//
// RouteNewForm: create new route with direct/return and stops
// - Form data
// - RoutePeriodSelect
// - RouteNewDirectionsForm -> RouteNewStopForm (anchor select + StopNewForm) + RouteNewReturnForm (TimePickerInput)
// - Form data
//
// RouteForm: select route, create or select stops for chosen route directions
// - RouteSelect
// - RoutePeriodSelect
// - RouteStopForm (1 or 2 depends on selected route directions) -> StopNewForm || StopSelect
//
// StopNewForm: create or select address or set school address + time for the stop
// - AddressNewForm || AddressSelect
// - TimePickerInput
const AddTripDialog = ({ day, open, onClose }) => {
  const { getSchool, readOnly } = useStoreState((s) => s.app);
  const { student } = useStoreState((s) => s.calendar);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const school = getSchool(student.school_id);
  const tripStore = useLocalStore(() =>
    addTripStore({ period: { startDate: day }, school, student, day })
  );
  const [state, actions] = tripStore;
  const history = useHistory();
  const cls = useStyles();

  const closeDialog = (_e, _reason, routeId = null, withRefresh = false) => {
    // redirect to freshly created route, we don't need to check school here as it should be set up
    if (routeId) {
      actions.resetState();
      history.push(routeWithDate(state.day, routeId, { schoolSlug: state.school.slug }));
      return;
    }
    actions.resetState();
    onClose(withRefresh);
  };

  const onSubmit = () => {
    actions
      .submit()
      .then((r) => {
        closeDialog(null, null, r.createdRouteId, true);
        setFlashMessage({ message: r.message });
      })
      .catch((err) => {
        const errors = get(err.response, "data.errors", {
          base: I18n.t("route.create.message.fail_trip")
        });
        actions.setErrors(errors);
        setFlashMessage({ message: I18n.t("route.create.message.fail_trip") });
      })
      .finally(() => actions.setLoading(false));
  };

  if (!open || readOnly) return null;

  return (
    <Dialog open={open} maxWidth="md" onClose={closeDialog} scroll="paper">
      <FormDialogTitle onClose={closeDialog}>
        <Typography variant="h1">
          {I18n.t("ui.student.weekly.add_trip_header", { name: student.name })}
          {withoutAccommodation(student) ? null : (
            <StudentAccommodationPopup student={student} marginLeft={1} />
          )}
        </Typography>
        <Typography variant="subtitle1">
          <b>
            {school.name} - Grade {student.grade}
          </b>
        </Typography>
      </FormDialogTitle>
      <FormDialogContent>
        <RouteTypeSelector cls={cls} store={tripStore} disabledType="regular" />
        <Divider className={cls.divider} />
        <TripForm cls={cls} store={tripStore} />
      </FormDialogContent>
      <FormDialogActions onSubmit={onSubmit} onClose={closeDialog} disabled={state.isDisabled} />
    </Dialog>
  );
};

export default AddTripDialog;
