import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { fetchSchools, fetchSchool, archiveSchool } from "services/apiSchools";
import { setFlashMessage } from "services";

export const defaultState = {
  // status
  loading: false,
  refresh: false,
  // filters
  scope: "active",
  query: null,
  // data
  schools: [],
  school: null,
  errors: {}
};

export const schoolsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["scope", "query"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchSchools: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope"]);

    actions.setLoading(true);

    return fetchSchools({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.schools = data.schools;
    state.collection = data.schools;
    state.total = parseInt(data.total);
  }),

  addSchool: action((state, schoolForm) => {
    let schoolsList = state.schools;
    schoolsList.unshift(schoolForm);
    state.schools = schoolsList;
  }),

  removeSchool: action((state, id) => {
    const newSchoolsList = state.schools.filter((sl) => sl.id !== id);
    state.schools = newSchoolsList;
  }),

  fetchSchool: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchSchool(id)
      .then((resp) => {
        actions.setSchool(resp.school);
      })
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  toggleArchive: thunk((actions, id, h) => {
    const school = h.getState().school;
    actions.setLoading(true);

    archiveSchool(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (school) {
          actions.setSchool(resp.school);
        } else {
          actions.removeSchool(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
