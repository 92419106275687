import React from "react";
import withStyles from "@mui/styles/withStyles";
import Radio from "@mui/material/Radio";

const GreenRadio = withStyles((theme) => ({
  root: {
    color: theme.custom.GREEN,
    "&$checked": {
      color: theme.custom.GREEN
    }
  },
  checked: {}
}))((props) => <Radio color="default" {...props} />);

export default GreenRadio;
