import React, { Fragment, useCallback } from "react";
import memoize from "fast-memoize";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText
} from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import { useLocalStore } from "easy-peasy";
import { strPresent, commaErrors, formatPhone } from "lib/util";
import useSorting from "lib/useSorting";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import PhoneInput from "components/admin_v2/common/PhoneInput";
import TableActionCell from "./TableActionCell";
import TableHeaders from "./TableHeaders";
import CollapsibleSection from "./CollapsibleSection";
import { validE164Format } from "lib/util";
import { useTableFormStyles, clsTable } from "components/admin_v2/common/useTableFormStyles";
import phoneNumbersStore from "./stores/phoneNumbersStore";
import { LANGUAGES, LINE_TYPES } from "utils/constants";

const HEADERS = [
  { field: "primary", label: "Primary" },
  { field: "number", label: "Number", required: true },
  { field: "name", label: "Name", required: true },
  { field: "lineType", label: "Type" },
  { field: "optOutCommunication", label: "Opt-Out Comms" },
  { field: "language", label: "Language" },
  { field: "twilio", label: "Twilio" },
  { field: "editMode", label: "Actions" }
];

const PhoneNumberRow = ({ data, idx, formStore, cls, withTwilio, disabled, updateField }) => {
  const isEditMode = formStore[0].isEditMode(idx);
  const { entity, errors } = data;
  const { clsPrimary, clsCell } = clsTable(cls, isEditMode);
  return (
    <TableRow hover tabIndex={-1} key={`phone-${idx}-row`}>
      <TableCell className={clsPrimary} key={`phone-${idx}-primary`}>
        {isEditMode ? (
          <>
            <GreenCheckbox
              checked={entity.primary}
              onChange={updateField(idx, "primary")}
              className={cls.check}
              disabled={disabled}
            />
            {errors?.primary && (
              <FormHelperText error>{commaErrors(errors?.primary)}</FormHelperText>
            )}
          </>
        ) : (
          entity.primary && <CheckIcon />
        )}
      </TableCell>
      <TableCell className={clsCell} key={`phone-${idx}-number`}>
        {isEditMode ? (
          <TextField
            variant="standard"
            id={`phone-${idx}-number-input`}
            label={<RequiredLabel label="Number" condition={strPresent(entity.number)} />}
            InputProps={{
              inputComponent: PhoneInput
            }}
            value={entity.number || ""}
            onChange={updateField(idx, "number")}
            className={cls.field}
            error={!!errors?.number}
            helperText={commaErrors(errors?.number)}
            disabled={disabled}
          />
        ) : (
          formatPhone(entity.number)
        )}
      </TableCell>
      <TableCell className={clsCell} key={`phone-${idx}-name`}>
        {isEditMode ? (
          <TextField
            variant="standard"
            id={`phone-${idx}-name-input`}
            label={<RequiredLabel label="Name" condition={strPresent(entity.name)} />}
            value={entity.name || ""}
            onChange={updateField(idx, "name")}
            className={cls.field}
            disabled={disabled}
            error={!!errors?.name}
            helperText={commaErrors(errors?.name)}
          />
        ) : (
          entity.name
        )}
      </TableCell>
      <TableCell className={[clsCell, cls.cellType].join(" ")} key={`phone-${idx}-lineType`}>
        {isEditMode ? (
          <FormControl className={cls.field} variant="standard" error={!!errors?.lineType}>
            <InputLabel id={`phone-${idx}-lineType-label`}>Type</InputLabel>
            <Select
              labelId={`phone-${idx}-lineType-label`}
              id={`phone-${idx}-lineType`}
              value={entity.lineType || "mobile"}
              onChange={updateField(idx, "lineType")}
              disabled={disabled}
            >
              {LINE_TYPES.map((lineType) => (
                <MenuItem key={`line-type-${idx}-${lineType}`} value={lineType}>
                  {lineType}
                </MenuItem>
              ))}
            </Select>
            {errors?.lineType && (
              <FormHelperText error>{commaErrors(errors?.lineType)}</FormHelperText>
            )}
          </FormControl>
        ) : (
          entity.lineType
        )}
      </TableCell>
      {!withTwilio && (
        <TableCell className={[clsCell, cls.cellOpt].join(" ")} key={`phone-${idx}-opt`}>
          {isEditMode ? (
            <FormControl className={cls.field} variant="standard">
              <InputLabel id={`phone-${idx}-opt-label`}>Opt Out</InputLabel>
              <Select
                labelId={`phone-${idx}-opt-label`}
                id={`phone-${idx}-opt`}
                value={entity.optOutCommunication ? "YES" : "NO"}
                onChange={updateField(idx, "optOutCommunication")}
                disabled={disabled}
              >
                {["YES", "NO"].map((opt) => (
                  <MenuItem key={`opt-${idx}-${opt}`} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
              {errors?.optOutCommunication && (
                <FormHelperText error>{commaErrors(errors?.optOutCommunication)}</FormHelperText>
              )}
            </FormControl>
          ) : (
            entity.optOutCommunication
          )}
        </TableCell>
      )}
      <TableCell className={[clsCell, cls.cellType].join(" ")} key={`phone-${idx}-lang`}>
        {isEditMode ? (
          <FormControl className={cls.field} variant="standard">
            <InputLabel id={`phone-${idx}-lang-label`}>Language</InputLabel>
            <Select
              labelId={`phone-${idx}-lang-label`}
              id={`phone-${idx}-lang`}
              value={entity.language || "english"}
              onChange={(e) => updateField(idx, "language", e)}
              disabled={disabled}
            >
              {LANGUAGES.map((lang) => (
                <MenuItem key={`lang-${idx}-${lang}`} value={lang}>
                  {lang}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          entity.language
        )}
      </TableCell>
      {withTwilio && (
        <TableCell className={[clsCell, cls.smCell].join(" ")} key={`phone-${idx}-twilio`}>
          {isEditMode ? (
            <>
              <GreenCheckbox
                id={`phone-${idx}-twilio`}
                checked={entity.twilio}
                onChange={updateField(idx, "twilio")}
                className={cls.check}
                disabled={disabled}
              />
              {errors?.twilio && (
                <FormHelperText error>{commaErrors(errors?.twilio)}</FormHelperText>
              )}
            </>
          ) : (
            entity.twilio && <CheckIcon />
          )}
        </TableCell>
      )}
      <TableActionCell store={formStore} idx={idx} disabled={disabled} />
    </TableRow>
  );
};

const PhoneNumbersForm = ({
  phoneNumbers,
  parentEntity,
  phoneRequired = true,
  maxSize,
  collapsible = false,
  disabled = false,
  withTwilio = false
}) => {
  const cls = useTableFormStyles();
  const formStore = useLocalStore(() =>
    phoneNumbersStore({ data: phoneNumbers, noDataAllowed: !phoneRequired, parentEntity })
  );
  const [state, actions] = formStore;
  const { sortableColumn } = useSorting({
    field: "primary",
    order: "asc",
    callback: actions.sortBy
  });

  const updateField = useCallback(
    memoize((idx, field) => (e) => {
      let value = e.target.value;
      if (field === "optOutCommunication") {
        value = e.target.value === "YES";
      }
      if (field === "twilio" || field === "primary") {
        value = e.target.checked;
      }
      actions.updateRowField({ idx, field, value });
      if (field === "number") {
        validateNumber(idx, value);
      }
    }),
    []
  );

  if (!phoneNumbers) return;

  const rows = state.data.map((data, idx) => (
    <PhoneNumberRow
      key={`phone-${idx}-row`}
      idx={idx}
      formStore={formStore}
      cls={cls}
      data={data}
      disabled={disabled}
      withTwilio={withTwilio}
      updateField={updateField}
    />
  ));

  const validateNumber = (idx, number) => {
    const err = number.length === 0 || validE164Format(number) ? null : "invalid number";
    const errors = state.getRow(idx)?.errors || {};
    actions.setErrors({ idx, errors: { ...errors, number: err } });
  };

  const tableHeaders = HEADERS.filter((h) =>
    withTwilio ? h.field !== "optOutCommunication" : h.field !== "twilio"
  ).map(sortableColumn);

  const table = (
    <>
      <Table
        className={`${cls.table} ${parentEntity.type.toLowerCase()}-phoneNumbers`}
        aria-label="table"
      >
        <TableHead>
          <TableRow>
            <TableHeaders headers={tableHeaders} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      {(!maxSize || state.data.length < maxSize) && (
        <Button className={cls.btn} variant="contained" color="secondary" onClick={actions.addRow}>
          New Number
        </Button>
      )}
    </>
  );

  return collapsible ? (
    <CollapsibleSection title="phoneNumbers" count={state.dataPersisted.length}>
      {table}
    </CollapsibleSection>
  ) : (
    table
  );
};

export default PhoneNumbersForm;
