import React from "react";
import { Box, TableRow, TableCell, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState } from "easy-peasy";
import RouteCalendar from "components/admin_v2/common/RouteCalendar";

const ScheduleRepeatRow = () => {
  const cls = useStyles();
  const { route, date, direction } = useStoreState((s) => s.routeEditor);
  if (!(route.with_schedule && route.not_occurs_on)) return null;

  return (
    <TableRow>
      <TableCell scope="row" colSpan="6" align="center">
        <Box py={1} className={cls.scheduleDetails} borderRadius={1}>
          <RouteCalendar route={route} date={date} tripTypes={direction}>
            <Typography color="error" display="inline" component="sup" className={cls.bold}>
              **
            </Typography>
            <Typography className={cls.bold} display="inline" component="span">
              {route.schedule_details}&nbsp;
            </Typography>
          </RouteCalendar>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  scheduleDetails: {
    backgroundColor: theme.custom.MEDIUM_GREY,
    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      alignItems: "center"
    }
  },
  bold: {
    fontWeight: theme.custom.SEMI_BOLD
  }
}));

export default ScheduleRepeatRow;
