import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";

const RequiredNote = () => {
  const cls = useStyles();
  return (
    <Box>
      <Typography className={cls.requiredNote} variant="button">
        {I18n.t("ui.required_note")}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  requiredNote: {
    color: theme.custom.RED
  }
}));

export default RequiredNote;
