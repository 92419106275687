import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { vendorsApiUrl } from "./api";

export const fetchRoute = async (routeId, params = {}) => {
  const query = queryString(params);
  return await xhr.get(vendorsApiUrl(`routes/${routeId}?${query}`));
};

export const fetchRouteOptions = async (routeId) => {
  return await xhr.get(vendorsApiUrl(`routes/${routeId}/options`));
};

export const fetchVendorSchedulesInfo = async (routeId, params) => {
  const query = queryString(params);
  return await xhr.get(vendorsApiUrl(`routes/${routeId}/vendor_schedules_info?${query}`));
};

export const fetchFutureOneDayAssignments = async (routeId, params) => {
  const query = queryString(params);
  return await xhr.get(vendorsApiUrl(`routes/${routeId}/future_one_day_assignments?${query}`));
};
