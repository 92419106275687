import { action } from "easy-peasy";
import { each, has } from "lodash";

export const defaultPaginationState = {
  page: 1,
  perPage: 25
};

export const enhancePaginationProps = (props) => {
  each(["page", "perPage"], (key) => {
    if (has(props, key)) props[key] = parseInt(props[key]);
  });
  return { ...defaultPaginationState, ...props };
};

export const paginationStore = (initial = {}) => ({
  page: parseInt(initial.page || defaultPaginationState.page),
  perPage: parseInt(initial.perPage || defaultPaginationState.perPage),
  total: parseInt(initial.total || 0),

  setPage: action((state, val) => {
    state.page = parseInt(val);
  }),

  setPerPage: action((state, val) => {
    state.perPage = parseInt(val);
  }),

  setTotal: action((state, val) => {
    state.total = parseInt(val);
  })
});
