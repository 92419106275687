import React from "react";
import { snakeCase } from "lodash";
import { Paper, Box, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import I18n from "utils/i18n.js";
import { formatPhone } from "lib/util";
import BooleanText from "components/admin_v2/common/BooleanText";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";

const DistrictAssociations = ({ district }) => {
  const cls = useCommonPageStyles();
  const clsTableForm = useTableFormStyles();

  if (!district) return;

  const associations = {
    phoneNumbers: [
      { column: "Name", value: (p) => p.name },
      { column: "Number", value: (p) => formatPhone(p.number) },
      { column: "Line Type", value: (p) => p.lineType },
      {
        column: "Opt-Out communication",
        value: (p) => <BooleanText val={p.optOutCommunication} />
      },
      {
        column: "Primary Phone",
        value: (p) => <BooleanText val={p.primary} />
      },
      {
        column: "Twilio provisioned?",
        value: (p) => <BooleanText val={p.twilio} />
      },
      { column: "Language", value: (p) => p.language }
    ]
  };

  return Object.entries(associations).map(([itemsName, columns]) => (
    <Box my={5} key={itemsName}>
      <Paper className={cls.paper}>
        <Box className={cls.subTitle}>
          {I18n.t(`district_config.district.title.${snakeCase(itemsName)}`)}
        </Box>
        <Table aria-label="table" className={cls.table}>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  className={clsTableForm.tableHeader}
                  key={`${itemsName}-header-${col.column}`}
                >
                  {col.column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {district[itemsName]?.map((item) => (
              <TableRow hover tabIndex={-1} key={`${itemsName}-row-${item.id}`}>
                {columns.map((col) => (
                  <TableCell className={clsTableForm.cell} key={`${itemsName}-cell-${col.column}`}>
                    {col.value(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  ));
};

export default DistrictAssociations;
