import React, { Fragment, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import GroupIcon from "@mui/icons-material/Group";
import { startsWith } from "lodash";
import { pageRoutes, slugsFor } from "services/api";
import { formatDateUrl, formatHumanDate } from "lib/dates";
import { fetchDayStops } from "services/apiRoutes";

const stopLabelFor = (stop, idx) => {
  if (!stop.anchor) return idx + 1;
  return startsWith(stop.tripType, "to") ? "A" : "D";
};

const RouteStops = ({ route, date, tripType }) => {
  const classes = useStyles();
  const [stops, setStops] = useState([]);

  useEffect(() => {
    fetchDayStops(route.id, { date: formatDateUrl(date), trip_type: tripType }).then((r) => {
      setStops(r);
    });
  }, []);

  return (
    <Fragment>
      <Box className={classes.stopsWrapper} mt={2}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1" className={classes.showStopslabel}>
              {`${route.name} Route Stops`}
            </Typography>
            {` | ${formatHumanDate(date)}`}
          </Box>
          <Link
            href={pageRoutes.route(route.id, slugsFor(route))}
            target="_blank"
            underline="always"
          >
            open route in a new window
          </Link>
        </Box>
        <Divider variant="fullWidth" className={classes.separator} />
        <Table className={classes.stopsTable} data-test-id="stops-table">
          <TableBody>
            {stops.map((stop, idx) => (
              <TableRow key={`stop-${stop.id}`}>
                <StopCell className={classes.stopNumber}>
                  <Avatar className={classes.numberChip}>{stopLabelFor(stop, idx)}</Avatar>
                </StopCell>
                <StopCell className={classes.stopAddress}>
                  <Typography variant="subtitle1">{stop.address}</Typography>
                </StopCell>
                <StopCell align="right" className={classes.stdCount}>
                  {!stop.anchor && <Typography variant="subtitle1">{stop.students}</Typography>}
                </StopCell>
                <StopCell align="right" className={classes.stdIcon}>
                  {!stop.anchor && <GroupIcon size="medium" />}
                </StopCell>
                <StopCell align="right">
                  <Typography variant="body2" className={classes.stopTime} display="block">
                    {stop.time}
                  </Typography>
                </StopCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Fragment>
  );
};

const StopCell = withStyles((_theme) => ({
  root: {
    border: "none",
    borderCollapse: "initial",
    paddingBottom: 0
  }
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  stopsWrapper: {
    width: "100%",
    backgroundColor: theme.custom.WHITE,
    padding: theme.spacing(1),
    border: `1px ${theme.custom.YELLOW} solid`,
    borderRadius: theme.spacing(0.5)
  },
  showStopslabel: {
    fontWeight: theme.custom.BOLD,
    display: "inline-block"
  },
  separator: {
    marginTop: theme.spacing(1)
  },
  numberChip: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: "0.9rem",
    fontWeight: theme.custom.BOLD,
    color: theme.custom.DARK_GREY_1,
    backgroundColor: theme.custom.LIGHT_GREY
  },
  stopNumber: {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    width: "5%"
  },
  stopAddress: {
    width: "70%"
  },
  stdCount: {
    paddingRight: theme.spacing(0.5)
  },
  stdIcon: {
    paddingRight: 0,
    paddingLeft: 0
  },
  stopTime: {
    width: "100%",
    textAlign: "center",
    backgroundColor: theme.palette.background.darkGrey,
    color: theme.custom.WHITE,
    fontWeight: theme.custom.SEMI_BOLD,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1.5)
  }
}));

export default RouteStops;
