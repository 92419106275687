import React from "react";
import { Box } from "@mui/material";
import { ScopeButtons } from "components/admin_v2/common/FilterHelpers";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const SCOPES = [
  ["active", I18n.t("route.scope.active")],
  ["archived", I18n.t("route.scope.archived")]
];

const DismissalGroupsFilter = ({ state, updateQueryString }) => {
  const cls = useCommonPageStyles();
  const updateScope = (params) => updateQueryString({ page: 1, scope: params.scope });

  return (
    <Box p={2} display="flex" justifyContent="space-between" className={cls.scopeBtns}>
      <ScopeButtons options={SCOPES} currentScope={state.scope} onChange={updateScope} />
    </Box>
  );
};

export default DismissalGroupsFilter;
