import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { startsWith } from "lodash";
import PeriodSelector from "components/admin_v2/common/PeriodSelector";
import I18n from "utils/i18n.js";

const RoutePeriodSelect = ({
  cls,
  store,
  changeRequest = { status: false, route: null },
  withOwnLabel = true
}) => {
  const [state, actions] = store;
  // NOTE: this is all because we may have one period selector for custom trip 2 sessions
  // and we need to get intersection of available days then
  let stops = {};
  let stopToAdd = { id: null };
  state.tripTypes.forEach((tripType) => {
    const stopType = startsWith(tripType, "to") ? "pickup" : "dropoff";
    const stop = state.getStopFor(tripType, stopType);
    if (stop) {
      stops[tripType] = stop;
      stopToAdd.id = stopToAdd.id ? stopToAdd.id + stop.id : stop.id;
    }
  });

  return (
    <Fragment>
      {withOwnLabel && (
        <Box component="label" mt={2} className={cls.label}>
          {I18n.t("ui.route.dates.label")}
        </Box>
      )}
      <PeriodSelector
        period={state.period}
        referenceDate={state.day}
        label={!withOwnLabel}
        onPeriodChange={actions.setPeriod}
        route={{
          // we need to pass current route, not chosen stop route in case of change request
          routeId: changeRequest.status ? changeRequest.route?.id : state.route.id,
          routeType: state.routeType,
          endDate: state.route.end_date,
          tripType: state.tripTypes.length === 1 ? state.tripTypes[0] : null,
          stops: state.stops,
          tripTypes: state.tripTypes,
          withRepeatWeek: state.workflow?.createRoute,
          notOccursOn: state.route?.not_occurs_on,
          isNewRoute: changeRequest.isNewStudent
        }}
        stopToAdd={stopToAdd}
        student={state.student}
        changeRequest={changeRequest.status}
        errors={state.errors}
      />
    </Fragment>
  );
};

export default RoutePeriodSelect;
