import React from "react";
import { IconButton, TableCell } from "@mui/material";
import cn from "classnames";
import StudentTableRow from "./StudentTableRow";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./useStyles";

const AddedStudentRow = ({ student, day, actions }) => {
  const cls = useStyles();

  const onDelete = (_e) => {
    actions.removeStudent(student);
  };

  return (
    <StudentTableRow student={student} day={day}>
      <TableCell align="right" className={cn(cls.cell, cls.labelsCell)} />
      <TableCell align="right" className={cls.cell}>
        <IconButton aria-label="delete" onClick={onDelete} size="large">
          <CloseIcon />
        </IconButton>
      </TableCell>
    </StudentTableRow>
  );
};

export default AddedStudentRow;
