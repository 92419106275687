import {
  parse,
  parseISO,
  addDays,
  startOfWeek,
  eachDayOfInterval,
  format,
  isPast,
  isDate,
  isToday,
  isAfter,
  isBefore,
  isEqual,
  isValid
} from "date-fns";

export const getMonday = (startDate) => startOfWeek(startDate, { weekStartsOn: 1 });

export const daysOfWeek = (date) => {
  if (!date) return;

  const weekStart = startOfWeek(date, { weekStartsOn: 1 });
  return eachDayOfInterval({
    start: weekStart,
    end: addDays(weekStart, 4)
  });
};

export const dateOrMonday = (date) => {
  if ([0, 6].includes(date.getDay())) {
    return startOfWeek(addDays(date, 7), { weekStartsOn: 1 });
  }
  return date;
};

export const dateOrCurrentMonday = (date) => {
  if ([0, 6].includes(date.getDay())) {
    return startOfWeek(date, { weekStartsOn: 1 });
  }
  return date;
};

export const dateOrMondayFromQuery = (date) =>
  date ? dateOrMonday(parse(date, "yyyy-MM-dd", new Date())) : new Date();

export const parseDateFromUrl = () => {
  const matched = location.search.match(/date=(\d{4}-\d{2}-\d{2})/);
  return matched ? parseISO(matched[1]) : new Date();
};

export const parseTime = (time) => (time ? parse(time, "HH:mm", new Date()) : null);

export const formatDateUrl = (date) => (date ? format(toDate(date), "yyyy-MM-dd") : null);

export const formatHumanDate = (date) => (date ? format(toDate(date), "MMM d, yyyy") : "");

export const formatHumanDateAndTime = (date) => format(toDate(date), "MMM d, yyyy HH:mm");

export const formatSimpleDate = (date) => (date ? format(toDate(date), "MMMM do") : "");

export const formatDate = (date) => (date ? format(toDate(date), "MM/dd/yyyy") : "");

export const formatTime = (time) => (isDate(time) && isValid(time) ? format(time, "H:m") : time);

export const formatDisplayTime = (time) =>
  isDate(time) && isValid(time) ? format(time, "HH:mm") : time;

export const formatTimeWithTz = (time, tz) => `${formatDisplayTime(time)} ${tz}`;

export const toDate = (date) => (typeof date === "string" ? parseISO(date) : date);

export const findStartDate = (referenceDate) => {
  return isPast(referenceDate) ? dateOrMonday(new Date()) : referenceDate;
};

export const pastAndNotToday = (date) => isPast(date) && !isToday(date);

export const sameDayOrAfter = (date1, date2) => isEqual(date1, date2) || isAfter(date1, date2);
export const sameDayOrBefore = (date1, date2) => isEqual(date1, date2) || isBefore(date1, date2);

const toTimes = (d1, d2) => [
  new Date(1970, 1, 1, d1.getHours(), d1.getMinutes()),
  new Date(1970, 1, 1, d2.getHours(), d2.getMinutes())
];

const diffDuration = (d1, d2) => (d1 > d2 ? d1 - d2 : d2 - d1) + d2.getTimezoneOffset() * 60 * 1000;

export const formatTimeDifference = (date1, date2) => {
  if (!date1 || !date2 || !isValid(date1) || !isValid(date2)) return null;
  const [d1, d2] = toTimes(date1, date2);
  return `${d1 > d2 ? "-" : ""}${formatDisplayTime(new Date(diffDuration(d1, d2)))}`;
};

export const deltaToTime = (delta) => {
  const absDelta = Math.abs(delta);
  const hours = Math.trunc(absDelta / (60 * 60));
  const minutes = (absDelta - hours * (60 * 60)) / 60;
  return new Date(1970, 1, 1, hours, minutes);
};

export const formatDelta = (delta) => {
  if (!delta) return "";
  const time = deltaToTime(delta);
  return `${delta < 0 ? "-" : ""}${formatDisplayTime(time)}`;
};

export const timeDifference = (date1, date2) => {
  if (!date1 || !date2 || !isValid(date1) || !isValid(date2)) return null;
  const [d1, d2] = toTimes(date1, date2);
  return d2 - d1;
};

export const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

// JS getDay() returns values starting from 0 (Sunday)
// e.g. 1 for Monday, 2 for Tuesday, etc.
// So we have to deduct 1 from that value, to use weekDays list
export const weekDay = (date) => (date ? weekDays[date.getDay() - 1] : null);

export const dateInTimeZone = (date, tzOffset) => {
  return new Date(date.getTime() + (tzOffset || 0) * 1000);
};
