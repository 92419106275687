import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";

const ArchivedBadge = ({ size = "regular" }) => {
  const cls = useStyles();
  const fontSize = size === "small" ? "0.6em" : "1em";
  return (
    <span className={cls.badge} style={{ fontSize }} title={I18n.t("ui.route.archived")}>
      {I18n.t("ui.route.archived")}
    </span>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {
    border: `1px solid ${theme.custom.DARK_RED}`,
    borderRadius: theme.spacing(0.5),
    color: theme.custom.DARK_RED,
    fontWeight: theme.custom.SEMI_BOLD,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.25, 2)
  }
}));

export default ArchivedBadge;
