import React, { useState } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import analytics from "./analytics";
import hotjar from "./hotjar";

export default function useAnalytics(userId) {
  const location = useLocation();
  const [path, setPath] = useState(null);

  React.useEffect(() => {
    analytics.init();
    hotjar.init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    if (path && currentPath.replace(/\?$/, "") === path.replace(/\?$/, "")) return;
    setPath(currentPath);
    analytics.sendPageview({ path: currentPath, userId });
    hotjar.sendPageview({ path: currentPath, userId });
  }, [location.pathname, location.search]);
}
