import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import cn from "classnames";
import { snakeCase } from "lodash";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SettingsConfigTabs from "components/admin_v2/settings_config/SettingsConfigTabs";
import I18n from "utils/i18n.js";
import { programsStore } from "components/admin_v2/settings_config/stores/programsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DETAIL_FIELDS = ["name", "abbreviation", "createdAt"];

const Program = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => programsStore());
  const id = props.match.params.id;
  const { program } = state;

  useEffect(() => {
    if (!id) return;

    actions.fetchProgram(id);
  }, [id]);

  const detail = (prop) => {
    return (
      <Box className={cn(`details-${prop}`, cls.detail)} key={prop}>
        <div className={cls.detailHeader}>
          {I18n.t(`settings_config.program.labels.${snakeCase(prop)}`)}
        </div>
        <div>{program[prop] || "--"}</div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("settings_config.title")} />
      <SettingsConfigTabs />
      <BreadCrumbs
        path={pageRoutes.programs()}
        label={I18n.t("settings_config.programs.breadcrumb")}
      />
      {program && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {program.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <RouterLink className={cls.actionBtn} to={pageRoutes.programEdit(program.id)}>
              <OutlinedBtn>{I18n.t("settings_config.edit_program.btn")}</OutlinedBtn>
            </RouterLink>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
              {program.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
            </OutlinedBtn>
          </Box>
        </Box>
      )}
      <Paper className={cn("program-details", cls.paper)}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={cls.subTitle}>{I18n.t("settings_config.program.title.details")}</Box>
            <Grid container className={cls.grid} spacing={5}>
              <Grid item xs={6}>
                {program && DETAIL_FIELDS.map(detail)}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default Program;
