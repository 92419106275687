import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import GreenRadio from "components/admin_v2/ui/GreenRadio";

const TripType = ({ form, onChange, cls, hasAll = false }) => {
  return (
    <FormControl component="fieldset" className={cls.field}>
      <FormLabel>Session: </FormLabel>
      <RadioGroup
        row
        aria-label="tripType"
        name="tripType"
        value={form.tripType}
        onChange={onChange}
      >
        {hasAll && <FormControlLabel value="" control={<GreenRadio />} label="All" />}
        <FormControlLabel value="to_school" control={<GreenRadio />} label="To School" />
        <FormControlLabel value="from_school" control={<GreenRadio />} label="From School" />
      </RadioGroup>
    </FormControl>
  );
};

export default TripType;
