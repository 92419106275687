import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CancelBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import I18n from "utils/i18n.js";

const ModeChangeButtons = ({
  saveChanges,
  onCancel,
  isDisabled,
  inline = false,
  withChangeRequest = false
}) => {
  const cls = useStyles();

  return (
    <Box>
      <PrimaryBtn
        onClick={saveChanges}
        className={withChangeRequest ? cls.changeReqBtn : null}
        disabled={isDisabled}
        withMargin
      >
        {I18n.t(`change_requests.dialog.button.${withChangeRequest ? "request" : "save"}`)}
      </PrimaryBtn>
      {!inline && (
        <CancelBtn onClick={onCancel}>{I18n.t("change_requests.dialog.button.cancel")}</CancelBtn>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  changeReqBtn: {
    backgroundColor: theme.custom.YELLOW,
    color: theme.custom.DARK_GREY_1,
    "&:hover": {
      backgroundColor: theme.custom.YELLOW
    }
  }
}));

export default ModeChangeButtons;
