import React from "react";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import makeStyles from "@mui/styles/makeStyles";

const WarningLabel = ({ label, nowrap = true }) => {
  const cls = useStyles({ nowrap });

  return (
    <Box className={cls.warningLabel} display="flex">
      <WarningIcon fontSize="small" />
      <Box ml={1}>
        <b>{label}</b>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  warningLabel: {
    whiteSpace: (props) => (props.nowrap ? "nowrap" : ""),
    color: theme.custom.YELLOW
  }
}));

export default WarningLabel;
