import React, { useState, Fragment, useCallback } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import RouteSelector from "./RouteSelector";
import StopsTable from "./StopsTable";
import RouteTabs from "./RouteTabs";
import AvlPushAlert from "./AvlPushAlert";
import StopStudentsDialog from "./StopStudentsDialog";
import RouteDirectionBtns from "components/admin_v2/common/RouteDirectionBtns";
import DatePickerInput from "components/admin_v2/ui/DatePickerInput";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import CreateReturnRoute from "./route/CreateReturnRoute";
import { useRouteDateUpdate } from "./common/hooks";
import { RouteTitle, RouteSubtitle } from "./common/RouteTitles";
import useStyles from "./useStyles";

const RouteDetails = (props) => {
  const cls = useStyles();
  const { isUserAdmin, readOnly } = useStoreState((s) => s.app);
  const { route, date, stop, hideEmptyStops } = useStoreState((s) => s.routeEditor);
  const { setStop, toggleEmptyStops } = useStoreActions((s) => s.routeEditor);
  const [studentsDialogOpen, setStudentsDialogOpen] = useState(false);
  const [changeRoute, selectDate, selectDirection] = useRouteDateUpdate(props);

  const toggleStudentsDialog = useCallback((stop) => {
    if (readOnly) return;

    if (stop) setStop(stop);
    setStudentsDialogOpen((d) => !d);
  }, []);

  const withCreateReturn = isUserAdmin && route && route.directions.length < 2;
  const showPushToAvl = route && !route.avl_pushed && isUserAdmin && !route.discarded;

  return (
    <Fragment>
      <TopPageLayout
        title={<RouteTitle route={route} />}
        subtitle={<RouteSubtitle />}
        filters={<RouteSelector route={route} onChange={changeRoute} />}
      >
        {showPushToAvl && <AvlPushAlert route={route} />}
        {route?.comment?.length > 0 && <Box className={cls.comment}>{route.comment}</Box>}
      </TopPageLayout>
      <RouteTabs route={route} location={location} />
      <Paper className={cls.root}>
        {route && (
          <Box className={cls.routeDetailsTop}>
            <RouteDirectionBtns onSelect={selectDirection} flex={withCreateReturn ? 0 : 1} />
            {withCreateReturn && <CreateReturnRoute />}
            <FormControlLabel
              key="hide-empty"
              control={
                <GreenCheckbox
                  checked={hideEmptyStops}
                  onChange={toggleEmptyStops}
                  value={hideEmptyStops}
                />
              }
              label="Hide empty stops"
              className={cls.hideStops}
            />
            <span className={cls.datePicker}>
              <DatePickerInput selectedDate={date} onChange={selectDate} />
            </span>
          </Box>
        )}
        {route ? (
          <StopsTable
            route={route}
            onColumnSelect={selectDate}
            onStopSelect={toggleStudentsDialog}
          />
        ) : (
          <Fragment>
            <Typography variant="body2">Route not found</Typography>
          </Fragment>
        )}
      </Paper>
      <StopStudentsDialog
        route={route}
        stop={stop}
        open={!route?.discarded && studentsDialogOpen}
        toggleDialog={toggleStudentsDialog}
      />
    </Fragment>
  );
};

export default RouteDetails;
