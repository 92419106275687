import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { filter, sortBy } from "lodash";
import useCommonPageStyles from "../common/useCommonPageStyles";

const SchoolsSelect = ({
  schools,
  schoolIds,
  onChange,
  multiple = true,
  errors = null,
  disabled = false,
  rest
}) => {
  const groupBy = (option) => {
    if (option.id == "all") return "";
    else return option.district_name || "Without District";
  };
  const cls = useCommonPageStyles();

  return (
    <Autocomplete
      multiple={multiple}
      options={sortBy(schools, "district_name")}
      groupBy={groupBy}
      getOptionLabel={(option) =>
        option.code && option.name ? `${option.code}:  ${option.name}` : option.name || ""
      }
      onChange={onChange}
      size="small"
      disabled={disabled}
      value={
        multiple
          ? filter(schools, (s) => schoolIds.indexOf(s.id) > -1)
          : schools.find((s) => s.id === schoolIds) || null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder={schoolIds.length === 0 ? "Select Schools" : ""}
          error={!!errors}
          helperText={errors}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          {option.code ? <span className={cls.schoolCode}>{option.code}</span> : null}
          <span>{option.name}</span>
        </li>
      )}
      {...rest}
    />
  );
};

export default SchoolsSelect;
