import { action, thunk, computed } from "easy-peasy";
import { assign, castArray, get, each, pick, keys, map, values, flatMap, compact } from "lodash";
import { isSameDay } from "date-fns";
import { easyStateSetters } from "lib/easyState";
import { saveScheduleChange } from "services/api";
import { getStudentCalendarDay } from "services/apiStudents";
import { formatDateUrl, dateInTimeZone, formatSimpleDate } from "lib/dates";
import { getEventType } from "components/admin_v2/students/common/StopLabel";
import { TRIP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import store from "context/admin_v2/store";
import I18n from "utils/i18n.js";

// possible values: base, chooseStudent, the latter is in use only for admin chreq
export const WORKFLOW_TYPES = {
  base: "base",
  chooseStudent: "chooseStudent"
};
// possible values:
// change_mode: Create mode (i.e. PPU, walk)
// change_to_existing_stop: Change to an existing stop
// create_new_stop_on_route: Create a new stop on an existing route
// create_new_route: Create a new route
// create_one_off_route: Create a one-off route
// change_to_existing_oneoff_stop: Change to an existing stop on a oneoff route
// create_new_oneoff_stop: Change to a new stop on a oneoff route
// request_new_stop: Request a new stop
export const TRIP_MODE_TYPES = {
  change_mode: "change_mode",
  change_to_existing_stop: "change_to_existing_stop",
  create_new_stop_on_route: "create_new_stop_on_route",
  create_new_route: "create_new_route",
  create_one_off_route: "create_one_off_route",
  change_to_existing_oneoff_stop: "change_to_existing_oneoff_stop",
  create_new_oneoff_stop: "create_new_oneoff_stop",
  request_new_stop: "request_new_stop"
};

export const defaultState = {
  // status
  loading: false,
  refresh: true,
  // data
  currentDate: null,
  calendar: null, // data from backend
  trips: [], // shaped data for trips from calendar data
  student: null,
  errors: {},
  // state
  // hash based on trip index, contains changes data shaped as
  // { changeRequest: false|true,
  //   currentEventType: chosen event type,
  //   initialEventType: initial event type,
  //   dropdownEnabled: false|true,
  //   isDisabled: false|true,
  //   isAddTrip: false|true, makes sense only for custom trip
  //   action: callback to be used in submiting changes, should return Promise
  //   editing: false|true
  //   note: only when staff requesting change
  //   chageMode: chosen change mode, make sense only for change event }
  details: {},
  workflow: WORKFLOW_TYPES.base,
  // flags
  isCustom: false,
  isAdminChangeRequest: false,
  isNewStudent: false
};

// const withEvents = (data) =>
//  ["activity", "cancel", "student_add", "is_student_add", "no_show"].some((e) => data[e]);

const getChangeMode = ({ changeTripEvent, isRequestMode, isNewStudent, isOneOff, isAddEvent }) => {
  if (changeTripEvent !== "change") return null;
  if (!isRequestMode && (isOneOff || isAddEvent)) return TRIP_MODE_TYPES.create_one_off_route;
  if (isNewStudent) {
    return isRequestMode ? TRIP_MODE_TYPES.request_new_stop : TRIP_MODE_TYPES.create_new_route;
  }
  return null;
};

const initDetailsChangeFrom = (eventType, _trip) => {
  let details = { isDisabled: true, dropdownEnabled: true, action: null, changeRequest: false };
  if (eventType === "ok") {
    details.eventType = eventType;
    details.editing = false;
    // if (trip && (withEvents(trip) || withEvents(trip.events || {}))) {
    //   details.dropdownEnabled = false;
    //   details.eventType = "ok";
    // }
    return details;
  }
  details.eventType = eventType;
  details.editing = ["activity", "cancel", "no_show"].includes(eventType) ? false : true;
  details.changeRequest = eventType === "change";
  return details;
};

// TODO: shape data for custom and not custom the same way at backend
// initialize trip details from calendar data and chosen event
const initDetailsFrom = ({
  trips,
  changeTripType,
  changeTripEvent,
  changeTripStopId,
  isRequestMode,
  isAddTrip = false,
  isNewStudent = false,
  isCustom = false
}) => {
  let details = {};
  trips.forEach((trip, idx) => {
    if (trip?.change_request) {
      details[idx] = {
        eventType: "change_req",
        initialEventType: "change_req",
        isAddTrip,
        changeMode: null,
        dropdownEnabled: false,
        editing: false,
        isDisabled: true
      };
      return;
    }
    const stop = trip ? trip.stop || trip.student_add?.stop : null;
    // check stop for custom trips and only session for regular as they have 0-1 trips for one session
    let editing = isCustom
      ? trip?.trip_type === changeTripType &&
        ([stop?.id, trip.student_add?.stop?.id].includes(changeTripStopId) || isAddTrip)
      : TRIP_TYPES.regular[idx] === changeTripType;
    editing = editing && ["one_off", "change", "remove"].includes(changeTripEvent);
    const initialEventType = trip
      ? getEventType(
          trip,
          stop,
          true // flag that we're quering for change request dialog
        )
      : "ok";
    let eventType = editing ? changeTripEvent : initialEventType;
    eventType = isNewStudent ? "change" : eventType || initialEventType;
    const changeMode = getChangeMode({
      changeTripEvent,
      isRequestMode,
      isNewStudent,
      isOneOff: initialEventType === "one_off",
      isAddEvent: initialEventType === "student_add"
    });
    details[idx] = {
      eventType,
      dropdownEnabled: true,
      changeMode,
      changeRequest: isNewStudent,
      initialEventType,
      editing: isNewStudent || editing,
      isAddTrip,
      isDisabled: true
    };
  });
  return details;
};

export const modeChangeStore = (initialData = {}) => ({
  ...easyStateSetters(defaultState, initialData),

  // check if all data is set
  isActive: computed(
    (state) => state.currentDate !== null && state.student !== null && state.student.id
  ),
  isDayOff: computed((state) => state.calendar?.trips?.day_off),
  // calculate if we can submit or not
  isDisabled: computed((state) => {
    if (state.loading) return true;
    let details = values(state.details);
    // return true if no changes were done
    details = details.filter((s) => s.initialEventType !== s.eventType);
    if (details.length === 0) return true;
    // return based on sub components status or event type
    return details.some((s) => {
      if (["activity", "cancel", "no_show", "remove", "ok"].includes(s.eventType)) return false;
      return s.isDisabled;
    });
  }),
  // if period enabled
  isPeriodEnabled: computed(
    (state) => (idx) => get(state.details, `${idx}.eventType`) !== "one_off"
  ),
  isRequestMode: computed(
    (state) => !store.getState().app.isUserAdmin || state.isAdminChangeRequest
  ),
  isOneOff: computed(
    (state) => (idx) => get(state.details, `${idx}.initialEventType`) === "one_off"
  ),
  isAddEvent: computed(
    (state) => (idx) => get(state.details, `${idx}.initialEventType`) === "student_add"
  ),
  // if we're on a screen where need to change student
  isChoosingStudent: computed((state) => state.workflow === WORKFLOW_TYPES.chooseStudent),
  // get data properties
  getTrip: computed((state) => (idx) => state.trips[idx]),
  getTripData: computed((state) => (idx) => {
    const trip = state.getTrip(idx);
    const details = state.getDetails(idx);
    // if we're editing the student_add stop for a student_add trip or
    // we're either editing a normal stop OR the cancel stop for a student_add trip.
    return trip?.student_add &&
      ["student_add", "one_off", "no_show"].includes(details.initialEventType)
      ? trip.student_add
      : trip;
  }),
  getDetails: computed((state) => (idx) => get(state.details, idx)),
  // get min date based on today in school tz
  minDate: computed((state) => dateInTimeZone(new Date(), state.school?.time_zone_offset)),
  // check if withChangeRequest, make sense only for staff
  withChangeRequest: computed(
    (state) =>
      state.isRequestMode &&
      (state.isAdminChangeRequest || values(state.details).some((s) => s.changeRequest))
  ),
  withEditorBox: computed((state) => ({ idx, trip }) => {
    const details = state.details[idx];
    if (!state.isCustom && state.isDayOff) return false;
    if (state.isDayOff && trip?.route?.route_type === "activity") return false;
    return details.editing || trip?.change_request;
  }),
  // get student school
  school: computed((state) =>
    state.student ? store.getState().app.getSchool(state.student.school_id) : null
  ),

  /* Data updaters */
  resetState: action((state, params) => {
    const defaultParams = pick(defaultState, "details", "trips", "calendar", "errors");
    if (params.currentDate) {
      if (isSameDay(state.currentDate, params.currentDate)) delete params.currentDate;
    }
    assign(state, defaultParams, params);
  }),

  resetDate: thunk((actions, date, h) => {
    if (isSameDay(h.getState().currentDate, date)) return;
    actions.resetState({ currentDate: date });
  }),

  resetUpdates: action((state, _params) => {
    state.errors = {};
    state.trips = [];
    state.calendar = null;
    state.details = {};
  }),

  updateCalendar: action((state, props) => {
    const { calendar, changeTripType, changeTripEvent, changeTripStopId } = props;
    const trips = calendar?.trips || {};
    if (state.isCustom) {
      state.trips = props.addTrip
        ? [{ stop: { anchor: { anchor_types: ["dropoff"] } }, trip_type: "to_custom" }]
        : compact(flatMap(TRIP_TYPES.custom, (direction) => trips[direction]?.stops));
    } else {
      state.trips = flatMap(TRIP_TYPES.regular, (direction) => trips[direction]);
    }
    state.student = calendar.student;
    state.details = initDetailsFrom({
      trips: state.trips,
      isCustom: state.isCustom,
      isAddTrip: props.addTrip,
      isNewStudent: state.isNewStudent,
      isRequestMode: state.isRequestMode,
      changeTripType,
      changeTripEvent,
      changeTripStopId
    });
    state.calendar = calendar;
    state.errors = {};
  }),

  updateCalendarTrip: action((state, { idx, eventType }) => {
    const trip = state.getTrip(idx);
    const { isRequestMode, isNewStudent } = state;
    state.details[idx].changeMode = getChangeMode({
      changeTripEvent: eventType,
      isRequestMode,
      isNewStudent,
      isOneOff: state.isOneOff(idx)
    });
    assign(state.details[idx], initDetailsChangeFrom(eventType, trip));
  }),

  updateCompletedEvents: action((state) => {
    each(state.details, (data) => {
      if (!data.editing && data.eventType !== data.initialEventType) data.completed = true;
    });
  }),

  updateTrip: action((state, params) => {
    const { idx, ...rest } = params;
    assign(state.trips[idx], rest);
  }),

  updateTripDetails: action((state, params) => {
    const { idx, ...rest } = params;
    assign(state.details[idx], rest);
  }),

  /* Data fetch/submission */
  fetchStudentCalendarDay: thunk((actions, params = {}, h) => {
    const state = h.getState();
    if (!state.student?.id || !state.currentDate || state.loading) return;

    actions.setLoading(true);
    // we're adding custom trip for student
    if (state.isCustom && params.changeTripEvent === "change" && params.changeTripStopId == null) {
      params.addTrip = true;
    }

    return getStudentCalendarDay(state.student.id, formatDateUrl(state.currentDate), {
      onlyCustom: state.isCustom,
      addTrip: params.addTrip,
      // need this in case of add custom trip change request
      changeReqId: params.changeRequestId,
      // send stopId to fetch only data for that student and that route, we need that for custom trips
      // that are not inlined and event is not remove
      stopId:
        !state.isCustom || params.inline || params.changeTripEvent === "remove"
          ? null
          : params.changeTripStopId
    })
      .then((calendar) => {
        actions.updateCalendar({ calendar, ...params });
      })
      .catch((_err) => actions.setErrors(I18n.t("ui.mode_editor.message.fail_fetch")))
      .finally(() => {
        actions.setLoading(false);
      });
  }),

  submit: thunk(async (actions, params = {}, h) => {
    const state = h.getState();
    const { isUserAdmin } = params;
    if (
      state.currentDate &&
      !isUserAdmin &&
      !isSameDay(state.currentDate, state.minDate) &&
      state.currentDate < state.minDate
    ) {
      const data = {
        errors: I18n.t("ui.mode_editor.message.date_in_past", {
          date: formatSimpleDate(state.minDate)
        })
      };
      return Promise.reject({ response: { data } });
    }

    actions.setLoading(true);
    try {
      let responses = castArray(await actions.submitEvents());
      actions.updateCompletedEvents();
      const details = state.details;
      for (const idx in keys(details)) {
        const data = details[idx];
        if (data.initialEventType === data.eventType || data.completed || !data.action) continue;
        const trip = state.getTripData(idx);
        const resp = await data.action({
          eventType: data.eventType,
          note: data.note,
          tripId: trip?.stop?.id
        });
        actions.updateTripDetails({ idx, completed: true });
        responses.push(resp);
      }
      return responses;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setLoading(false);
    }
  }),

  submitEvents: thunk((actions, _params = {}, h) => {
    const state = h.getState();
    const events = compact(
      map(state.details, (data, idx) => {
        if (data.initialEventType === data.eventType || data.editing) return null;
        const trip = state.getTripData(idx);
        return {
          id: state.student.id,
          date: formatDateUrl(state.currentDate),
          eventType: data.eventType === "ok" ? null : data.eventType,
          stopId: trip?.student_add?.stop?.id || trip?.stop?.id,
          tripType: trip?.trip_type || TRIP_TYPES.regular[idx]
        };
      })
    );

    if (events.length === 0) return [];

    return saveScheduleChange({
      school_id: state.student.school_id,
      student_id: state.student.id,
      events,
      stops: []
    });
  })
});
