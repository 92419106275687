import { action, computed } from "easy-peasy";
import { isValid, parseISO } from "date-fns";
import { formatDateUrl } from "lib/dates";
import tableSettingStore from "components/admin_v2/forms/stores/tableSettingStore";
import { createDayOff, removeDayOff, saveDayOff } from "services/apiDaysOff";

const initDataFromModel = (data) => ({
  ...data,
  startDate: parseISO(data.startDate),
  endDate: parseISO(data.endDate)
});

const daysOffStore = (initial = {}) => ({
  ...tableSettingStore({
    entity: { name: "Day Off", key: "dayOff" },
    endPoints: {
      createEntity: createDayOff,
      removeEntity: removeDayOff,
      saveEntity: saveDayOff
    },
    ...initial,
    data: initial.data?.map((d) => initDataFromModel(d)) ?? []
  }),

  // validate day off
  isValid: computed((state) => (idx) => {
    const entity = state.getRow(idx)?.entity || {};
    return isValid(entity.startDate) && isValid(entity.endDate);
  }),

  initData: computed((_state) => (data) => initDataFromModel(data)),

  newEntity: computed((_state) => {
    return {
      id: null,
      comment: "",
      offType: "single_day",
      startDate: null,
      endDate: null
    };
  }),

  formData: computed((state) => (idx) => {
    const entity = state.data[idx].entity;
    return {
      ...entity,
      startDate: isValid(entity.startDate) ? formatDateUrl(entity.startDate) : null,
      endDate: isValid(entity.endDate) ? formatDateUrl(entity.endDate) : null
    };
  }),

  updateStartDate: action((state, payload) => {
    const { idx, value } = payload;
    let entity = state.data[idx].entity;
    entity.startDate = value;
    if (entity.offType === "single_day") entity.endDate = value;
  })
});

export default daysOffStore;
