import React from "react";
import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { useStoreState } from "easy-peasy";
import { pull } from "lodash";
import { Divider } from "@mui/material";
import { TRIP_MODE_TYPES } from "../stores/modeChangeStore";
import useTripStyles from "components/admin_v2/students/trip/useStyles";
import I18n from "utils/i18n.js";

const EDIT_FULL_MODES = {
  custom: [
    TRIP_MODE_TYPES.change_to_existing_stop,
    TRIP_MODE_TYPES.create_new_stop_on_route,
    TRIP_MODE_TYPES.create_new_route
  ],
  regular: [
    TRIP_MODE_TYPES.change_mode,
    TRIP_MODE_TYPES.change_to_existing_stop,
    TRIP_MODE_TYPES.create_new_stop_on_route,
    TRIP_MODE_TYPES.create_new_route,
    TRIP_MODE_TYPES.create_one_off_route,
    TRIP_MODE_TYPES.change_to_existing_oneoff_stop,
    TRIP_MODE_TYPES.create_new_oneoff_stop
  ]
};
const EDIT_ONE_OFF_MODES = {
  custom: [],
  regular: [TRIP_MODE_TYPES.create_one_off_route]
};
const EDIT_REQUEST_MODES = {
  custom: [TRIP_MODE_TYPES.change_to_existing_stop, TRIP_MODE_TYPES.request_new_stop],
  regular: [
    TRIP_MODE_TYPES.change_mode,
    TRIP_MODE_TYPES.change_to_existing_stop,
    TRIP_MODE_TYPES.request_new_stop
  ]
};

const getEditModes = (isRequestMode, isOneOff, isAddEvent, isCustom) => {
  const subMode = isCustom ? "custom" : "regular";
  if (isRequestMode) return EDIT_REQUEST_MODES[subMode];
  return isOneOff || isAddEvent ? EDIT_ONE_OFF_MODES[subMode] : EDIT_FULL_MODES[subMode];
};

const ModeChangeSelect = ({ modeChangeStore, idx }) => {
  const cls = useTripStyles();
  const { isFutureDate } = useStoreState((s) => s.app);
  const [modeChangeState, modeChangeActions] = modeChangeStore;
  const isOneOff = modeChangeState.isOneOff(idx);
  const isAddEvent = modeChangeState.isAddEvent(idx);
  const tripDetails = modeChangeState.getDetails(idx);
  let editModes = [
    ...getEditModes(modeChangeState.isRequestMode, isOneOff, isAddEvent, modeChangeState.isCustom)
  ];
  const isFuture = isFutureDate(modeChangeState.student.school_id, modeChangeState.currentDate);
  if (isFuture) pull(editModes, TRIP_MODE_TYPES.create_one_off_route);

  const mapOptions = (options) => {
    let menuItems = [];

    options.forEach((option) => {
      if (option === TRIP_MODE_TYPES.create_one_off_route) {
        menuItems.push(<Divider key={`divider-${idx}`} />);
      }

      menuItems.push(
        <MenuItem key={`${option}-${idx}`} value={option}>
          {I18n.t(`ui.mode_editor.mode.${option}`)}
        </MenuItem>
      );
    });

    return menuItems;
  };

  const chooseOption = () => {
    if (isOneOff || isAddEvent) return null;

    return (
      <MenuItem key={`choose-option-${idx}`} value="">
        {I18n.t("ui.mode_editor.mode.choose")}
      </MenuItem>
    );
  };

  const updateChangeMode = (e) => {
    const changeMode = e.target.value === "" ? null : e.target.value;
    if (tripDetails.changeMode === changeMode) return;
    modeChangeActions.updateTripDetails({ idx, changeMode });
  };

  return (
    <FormControl className={cls.withTopMargin} required fullWidth>
      <FormLabel className={cls.label}>{I18n.t("ui.mode_editor.change_mode")}</FormLabel>
      <Select
        id={`changeMode-${idx}`}
        className={cls.field}
        value={tripDetails.changeMode || ""}
        onChange={updateChangeMode}
        disabled={isOneOff || isAddEvent}
        variant="outlined"
        fullWidth={true}
        displayEmpty
      >
        {chooseOption()}
        {mapOptions(editModes)}
      </Select>
    </FormControl>
  );
};

export default ModeChangeSelect;
