import React from "react";
import TripEditorForm from "../common/TripEditorForm";
import TripChangeEditor from "./TripChangeEditor";
import UndoPersistentChange from "../common/UndoPersistentChange";
import { TRIP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import I18n from "utils/i18n.js";

const RegularTrip = ({ modeChangeStore, trip, idx }) => {
  const [state, actions] = modeChangeStore;
  const tripType = TRIP_TYPES.regular[idx];
  const tripDetails = state.getDetails(idx);

  const updateStudentAttendance = (eventType) => {
    if (eventType === tripDetails.eventType) return;
    actions.updateCalendarTrip({ idx, eventType });
  };

  return (
    <TripChangeEditor modeChangeStore={modeChangeStore} tripType={tripType} idx={idx}>
      <TripEditorForm
        trip={trip}
        tripType={tripType}
        stop={state.getTripData(idx)?.stop}
        route={state.getTripData(idx)?.route}
        student={state.student}
        day={state.currentDate}
        dayOff={state.dayOff}
        label={I18n.t(
          `change_requests.dialog.editor.${
            trip?.change_request ? "change_request." : ""
          }${tripType}`
        )}
        tripDetails={tripDetails}
        onEventSelect={updateStudentAttendance}
      >
        <UndoPersistentChange modeChangeStore={modeChangeStore} idx={idx} />
      </TripEditorForm>
    </TripChangeEditor>
  );
};

export default RegularTrip;
