import React, { memo } from "react";
import cn from "classnames";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import { TimePicker } from "@mui/x-date-pickers";

const TimePickerInput = ({ dateTime, onChange, clsName = null, disabled = false }) => {
  const cls = useStyles();
  return (
    <Box className={cn(clsName || cls.pickerFixedWidth, cls.timePicker)}>
      <TimePicker
        mask="__:__"
        ampm={false}
        value={dateTime}
        onChange={onChange}
        disabled={disabled}
        inputFormat="HH:mm"
        desktopModeMediaQuery="@media (min-width:320px)"
        renderInput={(props) => (
          <TextField name="time-select" type="text" variant="standard" {...props} />
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  pickerFixedWidth: {
    width: theme.spacing(18)
  },
  timePicker: {
    backgroundColor: theme.custom.WHITE,
    padding: theme.spacing(0.5, 1),
    border: `1px ${theme.custom.MEDIUM_GREY} solid`,
    borderRadius: theme.spacing(0.5)
  }
}));

export default memo(TimePickerInput);
