import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import I18n from "utils/i18n.js";

export const DayOffLabel = (props) => (
  <Box
    borderRadius={1}
    bgcolor="background.darkGrey"
    textAlign="center"
    color="primary.contrastText"
    {...props}
  >
    <Typography variant="button">{props.label}</Typography>
  </Box>
);

export const DayOffChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.custom.YELLOW,
    borderRadius: theme.spacing(0.5),
    height: "auto",
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  }
}))((props) => (
  <Chip label={<Typography variant="button">{I18n.t("ui.day_off.date")}</Typography>} {...props} />
));
