import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import RouteAnchorForm from "components/admin_v2/students/trip/route/RouteAnchorForm";
import RouteSelect from "components/admin_v2/students/trip/route/RouteSelect";
import RouteStopsForm from "components/admin_v2/students/trip/route/RouteStopsForm";
import RouteStopForm from "components/admin_v2/students/trip/route/RouteStopForm";
import { getAnchorType, isOtherRoute } from "components/admin_v2/students/stores/stopsModel";
import useTripEditorStyles from "../common/useTripEditorStyles";
import I18n from "utils/i18n.js";

// Route form for staff (can't create new route, no route select on creating stop but anchor set for other trips)
const RequestRouteForm = ({ cls, store, children, isNewStop, trip, withTopMargin = true }) => {
  const [state, _actions] = store;
  const tripType = state.tripTypes[0];
  const clsTrip = useTripEditorStyles();

  if (!tripType) return null;

  const stops = () => {
    if (state.route?.id) {
      return (
        <RouteStopsForm
          key={`r-direction-${tripType}`}
          tripType={tripType}
          store={store}
          withoutStopToggle={true}
          cls={cls}
        />
      );
    }
    let stops = [];
    if (isNewStop) {
      stops.push(
        <RouteStopForm
          key={`r-direction-${tripType}`}
          withCheckBox={false}
          withoutStopToggle={true}
          direction={{ type: tripType }}
          store={store}
          cls={cls}
        />
      );
    }
    if (isNewStop && isOtherRoute(tripType)) {
      stops.push(
        <RouteAnchorForm
          currentAnchor={trip?.stop?.anchor}
          key={`add-anchor-${tripType}`}
          cls={cls}
          store={store}
          tripType={tripType}
        />
      );
    }
    return getAnchorType(tripType) === "dropoff" ? stops : stops.reverse();
  };

  return (
    <Fragment>
      {!isNewStop && <RouteSelect store={store} extraParams={{ riders_type: state.rideType }} />}
      {isNewStop && (
        <Box className={clsTrip.newStopInfo} mt={withTopMargin ? 2 : 0}>
          <Typography variant="subtitle1" className={clsTrip.label}>
            {I18n.t("ui.mode_editor.message.new_stop")}
          </Typography>
        </Box>
      )}
      {children}
      {stops()}
    </Fragment>
  );
};

export default RequestRouteForm;
