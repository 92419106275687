import React, { Fragment } from "react";
import { Grid, FormControl, FormLabel, TextField } from "@mui/material";
import { strPresent } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import SchoolsSelect from "components/admin_v2/ui/SchoolsSelect";
import I18n from "utils/i18n.js";
import isDate from "date-fns/esm/fp/isDate/index.js";

const DistrictDetailsForm = ({ formStore }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = formStore;
  const { details, errors } = state;

  if (!details) return null;

  const updateField = (key, e) => {
    let value;
    if (isDate(e)) {
      value = e;
    } else {
      value = e.target.value;
    }
    const newDetails = { ...details, [key]: value };
    actions.setDetails(newDetails);
  };

  const handleSchoolsChange = (_e, newValues) => {
    const newDetails = { ...details, schoolIds: newValues.map((v) => v.id) };
    actions.setDetails(newDetails);
  };

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            name="name"
            label={
              <RequiredLabel
                label={I18n.t("district_config.district.form.name.label")}
                condition={strPresent(details.name)}
              />
            }
            value={details.name || ""}
            onChange={(e) => updateField("name", e)}
            className={cls.field}
            error={!!errors?.name}
            helperText={errors?.name?.[0]}
          />
          <TextField
            variant="standard"
            name="slug"
            label={
              <RequiredLabel
                label={I18n.t("district_config.district.form.slug.label")}
                condition={strPresent(details.slug)}
              />
            }
            value={details.slug || ""}
            onChange={(e) => updateField("slug", e)}
            className={cls.field}
            error={!!errors?.slug}
            helperText={errors?.slug?.[0]}
          />
          <TextField
            variant="standard"
            name="code"
            label={
              <RequiredLabel
                label={I18n.t("district_config.district.form.code.label")}
                condition={strPresent(details.code)}
              />
            }
            value={details.code || ""}
            onChange={(e) => updateField("code", e)}
            className={cls.field}
            error={!!errors?.code}
            helperText={errors?.code?.[0]}
          />
          <TextField
            variant="standard"
            name="icabbi-account-ref"
            label={I18n.t("district_config.district.form.icabbi_account_ref.label")}
            value={details.icabbiAccountRef || ""}
            onChange={(e) => updateField("icabbiAccountRef", e)}
            className={cls.field}
            error={!!errors?.icabbiAccountRef}
            helperText={errors?.icabbiAccountRef?.[0]}
          />
          <TextField
            variant="standard"
            name="hotline-email"
            label={I18n.t("district_config.district.form.hotline_email.label")}
            value={details.hotlineEmail || ""}
            onChange={(e) => updateField("hotlineEmail", e)}
            className={cls.field}
            error={!!errors?.hotlineEmail}
            helperText={errors?.hotlineEmail?.[0]}
          />
          <FormControl className={cls.field} variant="outlined" fullWidth>
            <FormLabel className={cls.label}>
              {I18n.t("district_config.district.form.schools.label")}
            </FormLabel>
            <SchoolsSelect
              schools={state.allSchools || []}
              schoolIds={details.schoolIds || []}
              onChange={handleSchoolsChange}
              errors={errors?.schoolIds}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DistrictDetailsForm;
