import React, { Fragment, useEffect } from "react";
import { Box, Paper, TableRow, TableCell, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { startCase, compact } from "lodash";
import { useLocalStore, useStoreState } from "easy-peasy";
import useSorting from "lib/useSorting";
import useMessageBus from "lib/useMessageBus";
import { updateWithRouter } from "lib/queryString";
import ButtonLink from "components/admin_v2/ui/ButtonLink";
import Spinner from "components/admin_v2/ui/Spinner";
import { pageRoutes } from "services/api";
import { camelizeLocationSearch } from "lib/queryString";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import ReportFilesFilters from "./ReportFilesFilters";
import ReportGenerationStatus from "./ReportGenerationStatus";
import { reportsStore } from "./stores/reportFilesStore";

const ALL_VEHICLE_TYPES_SIZE = {
  adm: 3,
  curbside: 9,
  district_curbside: 9
};

const PENDING_STATUS = "pending";
const DONE_STATUS = "done";

const ReportFiles = (props) => {
  const cls = useStyles();
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });
  const { user, isUserAdmin, hasMultipleSchools } = useStoreState((s) => s.app);
  const reportStore = useLocalStore(() => reportsStore(camelizeLocationSearch(props)));
  const [state, actions] = reportStore;

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchReports({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [
    state.page,
    state.perPage,
    state.schoolId,
    state.scope,
    state.reportType,
    state.period,
    sortBy
  ]);

  useMessageBus({
    channel: `reports-${user?.id}`,
    condition: user?.id,
    received: (r) => actions.reportReceived(r)
  });

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const actionButtons = [
    <ButtonLink variant="contained" color="primary" key="btn-new-report" to={pageRoutes.reports()}>
      New Report
    </ButtonLink>
  ];

  const headers = compact(
    [
      { field: "created_at", label: "Created" },
      { field: "date", label: "Date" },
      { field: "report_type", label: "Report" },
      { field: "trip_type", label: "Trip Type" },
      { field: "transportation_types", label: "Transportation Types", skipSorting: true },
      {
        field: "school",
        label: "School Name",
        skipSorting: true,
        forFalseCondition: !(isUserAdmin || hasMultipleSchools)
      },
      {
        field: "vendor",
        label: "Vendor Name",
        skipSorting: true
      },
      {
        field: "order_by_dismissal_group",
        label: <span className={cls.cellSm}>Ordered by Dismissal Group</span>
      },
      { field: "created_by", label: "Created By" },
      { field: "report_format", label: "Format" },
      { field: "download", label: "File Name/Download", skipSorting: true },
      { field: "status", label: "Status", skipSorting: true }
    ].map((item) => sortableColumn(item, isUserAdmin))
  );

  const createdByUser = (report) => report.creatorId === user.id;

  const transportationTypes = (report) => {
    const typesSelected = report.transportationTypes?.length;
    if (!typesSelected) return "";

    if (ALL_VEHICLE_TYPES_SIZE[report.reportType] === typesSelected) {
      return "All Types";
    }

    return report.transportationTypes.join("; ");
  };

  const row = (report) => (
    <TableRow key={`report-${report.id}`}>
      <TableCell component="th">{report.createdAt}</TableCell>
      <TableCell>{report.dateS}</TableCell>
      <TableCell>{report.reportName}</TableCell>
      <TableCell>{startCase(report.tripType)}</TableCell>
      <TableCell>{transportationTypes(report)}</TableCell>
      {(isUserAdmin || hasMultipleSchools) && (
        <TableCell>{report.schoolNames?.join("; ")}</TableCell>
      )}
      <TableCell>{report.vendor}</TableCell>
      <TableCell className={cls.cellSm}>
        {report.reportType === "curbside" ? report.orderByDismissalGroup?.toString() : ""}
      </TableCell>
      <TableCell>{createdByUser(report) ? "Me" : report.createdBy}</TableCell>
      <TableCell>{report.reportFormat?.toUpperCase() || "CSV"}</TableCell>
      <TableCell>
        <Tooltip title={"Click to download this file"} arrow={true}>
          <a
            href={report.filePath}
            download={report.fileName}
            target="_blank"
            className={cls.downloadLink}
            rel="noopener noreferrer"
            onClick={() => actions.reportDownloaded(report)}
          >
            {report.fileName}
          </a>
        </Tooltip>
      </TableCell>
      <TableCell style={{ position: "relative" }}>
        <Box display="flex" className={cls[report.status]}>
          {report.status === PENDING_STATUS && <Spinner size="small" withMargin={false} />}
          {report.status === DONE_STATUS &&
            report.filePath &&
            report.downloaded === false &&
            createdByUser(report) && (
              <Tooltip title={"New report. No one has downloaded this report yet."} arrow={true}>
                <span className={cls.badge}></span>
              </Tooltip>
            )}
          {startCase(report.status)}
        </Box>
      </TableCell>
    </TableRow>
  );

  return (
    <Fragment>
      <TopPageLayout title="Reports" actionButtons={actionButtons} />
      <Box my={3}>
        <Paper className={cls.paper}>
          <ReportGenerationStatus reportStore={reportStore} withBottomMargin={true} />
          <ReportFilesFilters reportStore={reportStore} updateQueryString={updateQueryString} />
          {state.loading ? (
            <Spinner />
          ) : (
            <PaginatedTable
              headers={headers}
              count={state.total}
              perPage={state.perPage}
              page={state.page}
              onPageChange={updatePage}
              onChangePerPage={updatePerPage}
            >
              {state.reports.map(row)}
            </PaginatedTable>
          )}
        </Paper>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  cellSm: {
    display: "table-cell",
    maxWidth: "9em",
    whiteSpace: "break-spaces"
  },
  pending: {
    marginLeft: theme.spacing(-3.5)
  },
  failed: {
    color: theme.custom.RED
  },
  downloadLink: {
    display: "block",
    maxWidth: theme.spacing(14),
    overflow: "hidden",
    wordWrap: "break-word"
  },
  badge: {
    position: "absolute",
    display: "inline-block",
    right: theme.spacing(-1),
    top: `calc(50% - ${theme.spacing(1.5 / 2)})`,
    fontSize: "1.2rem",
    backgroundColor: theme.custom.RED,
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: theme.spacing(0.75)
  }
}));

export default ReportFiles;
