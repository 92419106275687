import { action, computed } from "easy-peasy";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";

// this store should be mixed in another componentStore.
// for example:
//    const myFormStore = useLocalStore((model) => ({
//      // form store fields ...
//      ...phoneNumbersStore(model?.phoneNumbers)
//    }))
//
const phoneNumbersStore = (initial) => ({
  ...easyState("phoneNumbers", initial || []),
  ...easyState("removedPhones", []),

  phoneNumbersAttributes: computed((state) => [...state.phoneNumbers, ...state.removedPhones]),

  isValidPhoneNumbers: computed((state) => (payload = {}) => {
    const phones = state.phoneNumbers;
    const validCount = payload?.noPhoneAllowed ? true : phones.length > 0;
    const filled = phones.every((p) => strPresent(p.name) && strPresent(p.number));
    return validCount && filled;
  }),

  newPhoneNumber: action((state, _payload) => {
    state.phoneNumbers = [
      ...state.phoneNumbers,
      {
        id: null,
        name: "",
        number: "",
        primary: state.phoneNumbers.length === 0, // 1st should be primary
        lineType: "mobile",
        optOutCommunication: false,
        language: "english"
      }
    ];
  }),

  updatePhoneNumber: action((state, payload) => {
    const { index, field, value } = payload;
    if (state.phoneNumbers[index]) {
      state.phoneNumbers[index][field] = value;
    }
  }),

  updatePrimaryPhone: action((state, index) => {
    const newPhones = state.phoneNumbers.map((phone, i) => {
      return { ...phone, primary: i === index };
    });
    state.phoneNumbers = newPhones;
  }),

  removePhoneNumber: action((state, index) => {
    const phone = state.phoneNumbers[index];
    if (phone.id) {
      // if it's a persisted phone we must set to _destroy
      state.removedPhones.push({ ...phone, _destroy: true });
    }
    state.phoneNumbers.splice(index, 1);

    // if we removed the primary, set another primary
    if (phone.primary && state.phoneNumbers.length > 0) {
      state.phoneNumbers[0].primary = true;
    }
  })
});

export default phoneNumbersStore;
