import React from "react";
import { Box, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import { isEmpty, startsWith } from "lodash";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import StopNewForm from "../stop/StopNewForm";
import StopSelect from "../stop/StopSelect";
import ToggleCreate from "components/admin_v2/common/ToggleCreate";

const RouteStopForm = ({
  store,
  withCheckBox,
  direction,
  cls,
  stopLabel = null,
  disabled = false,
  withoutStopToggle = false,
  withTopMargin = false,
  StopSelectComponent = StopSelect
}) => {
  const [state, actions] = store;
  const tripType = direction.type;
  const stopType = startsWith(direction.type, "to") ? "pickup" : "dropoff";
  const createStop = state.getWorkflowFor(tripType, stopType, "chooseStop") !== "chooseStop";
  const label = stopLabel || I18n.t(`ui.stop.${stopType}`);
  const labels = {
    links: { choose: I18n.t("ui.stop.choose"), new: I18n.t("ui.stop.new") },
    labels: { choose: label, new: label }
  };
  const active = state.tripTypes.indexOf(direction.type) !== -1;
  const { errors, errorStop, errorTime } = state.getStopErrorsFor(tripType, stopType);

  const stopForm = active ? (
    createStop ? (
      <StopNewForm
        store={store}
        cls={cls}
        stopType={stopType}
        tripType={tripType}
        disabled={disabled}
      />
    ) : (
      <StopSelectComponent
        store={store}
        stopType={stopType}
        tripType={tripType}
        disabled={disabled}
      />
    )
  ) : null;

  const onChange = (e) => {
    actions.setTripType({ tripType: direction.type, checked: e.target.checked });
  };

  const setCreateStop = (status) =>
    actions.setStop({ stopType, tripType, stop: {}, workflow: status ? "choose" : "chooseStop" });

  const errorList = createStop && errorTime ? errorStop : errors;

  return (
    <FormControl
      fullWidth
      error={!isEmpty(errors)}
      className={withTopMargin ? cls.withTopMargin : null}
    >
      {withCheckBox && (
        <FormControlLabel
          control={<GreenCheckbox checked={active} onChange={onChange} />}
          label={direction.label}
        />
      )}
      <ErrorList errors={errorList} />
      {active && !disabled && !withoutStopToggle && (
        <ToggleCreate labels={labels} cls={cls} create={createStop} setCreate={setCreateStop} />
      )}
      {(disabled || (withoutStopToggle && active)) && (
        <Box display="flex" alignItems="baseline" mt={1}>
          <FormLabel className={cls.label}>{label}</FormLabel>
        </Box>
      )}
      {stopForm}
    </FormControl>
  );
};

export default RouteStopForm;
