import React from "react";
import classNames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState } from "easy-peasy";
import { yellowEvent, redEvent } from "./EventHelpers";
import I18n from "utils/i18n.js";

const EventSelect = (props) => {
  const {
    tripType,
    eventType,
    disabled,
    onChange,
    date = null,
    schoolId = null,
    isOtherRoute = false,
    dayOff = false,
    withPPU = false,
    withActivity = false
  } = props;
  const { user, isUserAdmin, isFutureDate, isPastDate, readOnly } = useStoreState((s) => s.app);
  const classes = useStyles(props);
  const selectClass = classNames(
    classes.eventSelect,
    { yellowEvent: yellowEvent(eventType) },
    { redEvent: redEvent(eventType) }
  );

  const updateEventType = (e) => {
    onChange(e.target.value);
  };

  const isFuture = isFutureDate(schoolId, date);
  const isReadOnly = isUserAdmin ? false : isPastDate(schoolId, date);
  const iconClass = classNames(classes.selectIcon, {
    disabled: disabled || isReadOnly || readOnly
  });

  const okItem = (
    <MenuItem value={eventType === "student_add" ? "student_add" : "ok"} className={classes.select}>
      {I18n.t(`student.event.${dayOff ? "day_off" : "ok"}`)}
    </MenuItem>
  );

  if (isReadOnly) {
    return (
      <Select
        id={`${tripType}-event`}
        className={selectClass}
        value={eventType || "ok"}
        onChange={updateEventType}
        disabled={true}
        variant="outlined"
        fullWidth={true}
        classes={{ icon: iconClass, select: selectClass }}
      >
        {eventType !== "ok" ? (
          <MenuItem value={eventType} className={`${classes[eventType]}`}>
            {I18n.t(`student.event.${eventType}`)}
          </MenuItem>
        ) : (
          okItem
        )}
      </Select>
    );
  }

  return (
    <Select
      id={`${tripType}-event`}
      className={selectClass}
      value={eventType || "ok"}
      onChange={updateEventType}
      disabled={disabled || readOnly}
      variant="outlined"
      fullWidth={true}
      classes={{ icon: iconClass, select: selectClass }}
    >
      {eventType === "change_req" ? (
        <MenuItem value={eventType} className={classes.change}>
          CH. REQ.
        </MenuItem>
      ) : (
        okItem
      )}
      {["ok", "activity"].includes(eventType) && !isOtherRoute && withActivity ? (
        <MenuItem value="activity" className={classes.cancel}>
          Activity
        </MenuItem>
      ) : null}
      {["ok", "cancel"].includes(eventType) ? (
        <MenuItem value="cancel" className={classes.cancel}>
          Cancel
        </MenuItem>
      ) : null}
      {withPPU && ["ok", "ppu"].includes(eventType) && !isOtherRoute ? (
        <MenuItem value="ppu" className={classes.ppu}>
          PPU
        </MenuItem>
      ) : null}
      {!isFuture && ["ok", "no_show"].includes(eventType) ? (
        <MenuItem value="no_show" className={classes.no_show}>
          No Show
        </MenuItem>
      ) : null}
      {["ok", "change"].includes(eventType) ? (
        <MenuItem value={"change"} className={`${classes.change}`} key="change">
          Change
        </MenuItem>
      ) : null}
      {!isFuture &&
      ((["ok", "one_off"].includes(eventType) && user.admin && !isOtherRoute) || disabled) ? (
        <MenuItem value={"one_off"} className={`${classes.one_off}`} key="one_off">
          1-Off
        </MenuItem>
      ) : null}
      {eventType !== "student_add" && eventType !== "change" && isOtherRoute ? (
        <MenuItem value={"remove"} className={`${classes.remove}`} key="remove">
          Remove
        </MenuItem>
      ) : null}
      {eventType === "student_add" && user.admin && !isOtherRoute
        ? [
            <MenuItem value="ok" key="ok">
              Cancel Pass
            </MenuItem>,
            <MenuItem value="no_show" className={classes.no_show} key="no_show">
              No Show
            </MenuItem>,
            <MenuItem value={"one_off"} className={`${classes.one_off}`} key="one_off">
              1-Off
            </MenuItem>
          ]
        : null}
      {eventType === "student_add" && user.admin && isOtherRoute
        ? [
            <MenuItem value="ok" key="ok">
              Cancel Pass
            </MenuItem>,
            <MenuItem value="no_show" className={classes.no_show} key="no_show">
              No Show
            </MenuItem>
          ]
        : null}
    </Select>
  );
};

const useStyles = makeStyles((theme) => ({
  ok: {
    color: theme.custom.MEDIUM_DARK_GREY,
    minHeight: theme.spacing(4)
  },
  selectIcon: {
    "&.disabled": {
      color: theme.custom.WHITE
    }
  },
  cancel: {
    color: theme.custom.YELLOW,
    fontWeight: theme.custom.SEMI_BOLD
  },
  ppu: {
    color: theme.custom.YELLOW,
    fontWeight: theme.custom.SEMI_BOLD
  },
  no_show: {
    color: theme.custom.RED,
    fontWeight: theme.custom.SEMI_BOLD
  },
  change: {
    color: theme.custom.YELLOW,
    fontWeight: theme.custom.SEMI_BOLD
  },
  one_off: {
    color: theme.custom.RED,
    fontWeight: theme.custom.SEMI_BOLD
  },
  remove: {
    color: theme.custom.RED,
    fontWeight: theme.custom.SEMI_BOLD
  },
  eventSelect: {
    minWidth: "65px",
    width: (props) => (props.fullWidth ? "100%" : "auto"),
    fontSize: "14px",
    lineHeight: "normal",
    textAlign: "left",
    backgroundColor: theme.custom.WHITE,
    "&.redEvent": {
      color: theme.custom.RED,
      fontWeight: theme.custom.SEMI_BOLD
    },
    "&.yellowEvent": {
      color: theme.custom.YELLOW,
      fontWeight: theme.custom.SEMI_BOLD
    },
    "& .Mui-disabled": {
      "-webkitTextFillColor": "inherit"
    }
  }
}));

export default EventSelect;
