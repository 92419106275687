import React from "react";
import { useHistory } from "react-router";
import { Portal } from "react-portal";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Dialog,
  Typography,
  FormLabel,
  FormGroup,
  TextField,
  DialogActions
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { OutlinedBtn, CancelBtn, DangerBtn } from "components/admin_v2/ui/Buttons";
import { FormDialogTitle, FormDialogContent } from "components/admin_v2/ui/FormDialog";
import Spinner from "components/admin_v2/ui/Spinner";
import { pageRoutes } from "services/api";
import { formatDateUrl } from "lib/dates";
import enrollmentStudentStore from "./stores/enrollmentStudentStore";
import I18n from "utils/i18n.js";

const UnenrollStudent = ({ student }) => {
  const cls = useStyles();
  const history = useHistory();
  const { isUserAdmin, readOnly } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [state, actions] = useLocalStore(() => enrollmentStudentStore({ student }));

  const open = () => actions.setOpen(true);
  const close = () => actions.setOpen(false);

  const submit = () => {
    actions
      .unenroll()
      .then((_) => {
        history.push(pageRoutes.students());
        setFlashMessage({
          message: <UnenrolledMsg date={state.date} admin={isUserAdmin} />
        });
      })
      .catch((r) =>
        setFlashMessage({
          message: r.message || I18n.t("student.unenroll.failure", { student: student.name })
        })
      );
  };

  return (
    <>
      {!readOnly && (
        <OutlinedBtn className={cls.unenrollBtn} onClick={open} disabled={student.discarded}>
          {I18n.t(`students.unenroll.btn.${student.riderType}`)}
        </OutlinedBtn>
      )}
      <Portal>
        <Dialog open={state.open} onClose={close} maxWidth="md" id="unenroll-student-dialog">
          {state.processing ? (
            <Spinner />
          ) : (
            <>
              <FormDialogTitle onClose={close}>
                <Typography variant="h1">
                  {I18n.t("students.unenroll.form_title", { name: student.name })}
                </Typography>
              </FormDialogTitle>
              <FormDialogContent>
                <FormLabel className={cls.label}>
                  {I18n.t("students.form.effective_date")}
                </FormLabel>
                <DatePicker
                  open={state.pickerOpen}
                  onOpen={() => actions.setPickerOpen(true)}
                  onClose={() => actions.setPickerOpen(false)}
                  value={state.date}
                  onChange={actions.setDate}
                  className={cls.input}
                  desktopModeMediaQuery="@media (min-width:320px)"
                  renderInput={(props) => (
                    <TextField name="unenroll-student-date" variant="outlined" {...props} />
                  )}
                />
                {!isUserAdmin && (
                  <Box mt={1}>
                    <Typography variant="subtitle1" className={cls.label}>
                      {I18n.t("students.form.add_note")}:
                    </Typography>
                    <FormGroup row>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={3}
                        className={cls.note}
                        onChange={(e) => actions.setNote(e.target.value)}
                      />
                    </FormGroup>
                  </Box>
                )}
              </FormDialogContent>
              <DialogActions className={cls.actions}>
                <DangerBtn onClick={submit}>
                  {isUserAdmin
                    ? I18n.t(`students.unenroll.btn.${student.riderType}`)
                    : I18n.t("students.unenroll.request")}
                </DangerBtn>
                <CancelBtn onClick={close}>{I18n.t("ui.btn.cancel")}</CancelBtn>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Portal>
    </>
  );
};

export const UnenrolledMsg = ({ date, admin }) => {
  const cls = useStyles();

  if (admin) {
    return (
      <>
        The student has been deactivated from routes starting from {formatDateUrl(date)}.<br />
        <span className={cls.ml}>In addition, the student was archived</span>
      </>
    );
  } else {
    return "A request for unenrolling this student was created.";
  }
};

export const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    color: theme.palette.text.primary,
    fontWeight: theme.custom.BOLD,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  input: {
    backgroundColor: theme.custom.WHITE,
    minWidth: theme.spacing(30)
  },
  unenrollBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  actions: {
    padding: 0,
    marginTop: theme.spacing(1),
    justifyContent: "flex-start"
  },
  note: {
    backgroundColor: theme.custom.WHITE,
    width: "100%"
  },
  ml: {
    marginLeft: "2.5em"
  }
}));

export default UnenrollStudent;
