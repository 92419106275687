import React, { Fragment } from "react";
import classNames from "classnames";
import { Grid, TextField } from "@mui/material";
import ErrorList from "components/admin_v2/ui/ErrorList";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { searchAddress } from "services/apiAddresses";
import { strPresent } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import I18n from "utils/i18n.js";

const PrimaryAddressForm = ({ store, updateField }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { details, errors } = state;

  const onOptionSelect = (data) => {
    const value = data ? data.description : null;
    const newDetails = { ...details, address: value };
    actions.setDetails(newDetails);
  };

  return (
    <>
      <hr />
      <div className={cls.subTitle}>{I18n.t("common_config.address.title")}</div>
      <ErrorList errors={errors} prefix="addresses" />
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <RequiredLabel
            label={I18n.t("common_config.address.form.address")}
            condition={strPresent(details.address)}
          />
          <AutocompleteSelect
            id="addr-new-address-input"
            getOptions={searchAddress}
            onOptionSelect={onOptionSelect}
            defaultSchool={"all"}
            searchParams={{ withoutSchool: true, withTz: true }}
            labelField={"description"}
            defaultValue={details.address || ""}
            placeholder="Address"
            maxWidth="100%"
          />
          <TextField
            id="addr-new-name-input"
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("common_config.address.form.name")}
                condition={strPresent(details.addressName)}
              />
            }
            value={details.addressName || ""}
            onChange={updateField("addressName")}
            className={classNames("addr-name", cls.field)}
            error={!!errors?.addressName}
            helperText={errors?.addressName?.[0]}
          />
          <TextField
            id="addr-new-lat-input"
            variant="standard"
            label={I18n.t("common_config.address.form.lat")}
            value={details.addressLat || ""}
            onChange={updateField("lat")}
            className={classNames("addr-lat", cls.field)}
            error={!!errors?.addressLat}
            helperText={errors?.addressLat?.[0]}
          />
          <TextField
            id="addr-new-lng-input"
            variant="standard"
            label={I18n.t("common_config.address.form.lng")}
            value={details.addressLng || ""}
            onChange={updateField("lng")}
            className={classNames("addr-lng", cls.field)}
            error={!!errors?.addressLng}
            helperText={errors?.addressLng?.[0]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PrimaryAddressForm;
