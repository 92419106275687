import React, { useEffect, useCallback } from "react";
import { Box, Link, Paper, TableRow, TableCell } from "@mui/material";
import SearchInput from "components/admin_v2/ui/SearchInput";
import { useLocalStore, useStoreActions } from "easy-peasy";
import BatchSelect from "components/admin_v2/common/BatchSelect";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import AddStopLocationDialog from "./AddStopLocationDialog";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { stopLocationsStore } from "./stores/stopLocationsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import { batchArchive } from "services/apiStopLocations";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { NON_RIDING_TYPES } from "utils/constants";

const StopLocations = (props) => {
  const cls = useCommonPageStyles();
  const { onChangeScope } = useStoreActions((s) => s.app);
  const store = useLocalStore(() => stopLocationsStore(camelizeLocationSearch(props)));
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);
  const updateQueryString = updateWithRouter(props);
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  const resetState = useCallback(() => {
    actions.toggleRefresh();
    updateQueryString({ page: 1 });
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchStopLocations({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [searchInput, state.page, state.perPage, state.refresh, sortBy]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  const canEdit = (stopLocation) => {
    !stopLocation.is_school && !NON_RIDING_TYPES.includes(stopLocation.name);
  };

  const updatePage = (_e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const toggleAddStopLocationDialog = () => {
    actions.setAddStopLocationDialogOpen(!state.addStopLocationDialogOpen);
  };

  const headers = [
    {
      field: "batchCheck",
      label: (
        <GreenCheckbox
          checked={state.allSelected}
          onChange={(e) => actions.updateAllSelected(e.target.checked)}
        />
      ),
      skipSorting: true
    },
    { field: "id", label: "ID" },
    { field: "school", label: "School" },
    { field: "name", label: "Name" },
    { field: "address", label: "Address" },
    { field: "duplicate", label: "Copy?" },
    { field: "created_at", label: "Created" },
    { field: "", label: "" }
  ].map(sortableColumn);

  const row = (stopLocation) => (
    <TableRow key={`stop_location-${stopLocation.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <GreenCheckbox
          checked={state.isSelected(stopLocation.id)}
          onChange={(e) =>
            actions.updateSelectedIds({ isAdd: e.target.checked, id: stopLocation.id })
          }
        />
      </TableCell>
      <TableCell component="th" style={{ width: "5%" }}>
        <RouterLink to={pageRoutes.stopLocation(stopLocation.id)} className={cls.tableLink}>
          {stopLocation.id}
        </RouterLink>
      </TableCell>
      <TableCell style={{ width: "15%" }}>{stopLocation.school}</TableCell>
      <TableCell style={{ width: "25%" }}>{stopLocation.name}</TableCell>
      <TableCell style={{ width: "25%" }}>{stopLocation.address}</TableCell>
      <TableCell>{stopLocation.duplicate ? "Yes" : "No"}</TableCell>
      <TableCell>{stopLocation.created_at}</TableCell>
      <TableCell>
        <RouterLink to={pageRoutes.stopLocation(stopLocation.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.view")}
        </RouterLink>
        {canEdit(stopLocation) && (
          <RouterLink to={pageRoutes.stopLocationEdit(stopLocation.id)} className={cls.tableLink}>
            {I18n.t("ui.btn.edit")}
          </RouterLink>
        )}
        <Link
          onClick={() => actions.archiveStopLocation(stopLocation.id)}
          className={cls.tableLink}
          underline="hover"
        >
          {I18n.t("ui.btn.archive")}
        </Link>
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <Box className={cls.btnsWrapper}>
        <OutlinedBtn onClick={toggleAddStopLocationDialog}>
          {I18n.t("data_management.new_stop_location.btn")}
        </OutlinedBtn>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          <BatchSelect
            store={store}
            actionsList={[{ label: I18n.t("ui.btn.archive"), action: batchArchive }]}
          />
          <SearchInput cls value={state.query} onChange={onSearch} />
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.stopLocations.map(row)}
          </PaginatedTable>
        )}
      </Paper>
      <AddStopLocationDialog
        cls={cls}
        open={state.addStopLocationDialogOpen}
        store={store}
        toggleDialog={toggleAddStopLocationDialog}
      />
    </React.Fragment>
  );
};

export default StopLocations;
