import React, { useState } from "react";
import { FormLabel, FormGroup, FormControlLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import makeStyles from "@mui/styles/makeStyles";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import useDidMountEffect from "lib/useDidMountEffect";
import { dateOrMonday, toDate, formatDateUrl } from "lib/dates";
import I18n from "utils/i18n.js";

const DateRangeFilter = ({ state, updateQueryString }) => {
  const [dateRange, setDateRange] = useState({
    from: dateOrMonday(toDate(state.from) || new Date()),
    to: toDate(state.to) || new Date()
  });
  const [datePickersOpen, setDatePickersOpen] = useState({
    from: false,
    to: false
  });
  const [active, setActive] = useState(!!state.from);
  const cls = useStyles();

  useDidMountEffect(() => {
    if (active && (dateRange.from || dateRange.to)) {
      updateQueryString({
        page: 1,
        from: formatDateUrl(dateRange.from),
        to: formatDateUrl(dateRange.to)
      });
    }
  }, [dateRange, active]);

  const updateActive = (e) => {
    if (!e.target.checked && state.to) {
      updateQueryString({ page: 1, from: null, to: null });
    }
    setActive(e.target.checked);
  };

  return (
    <FormGroup row className={cls.form}>
      <FormControlLabel
        control={<GreenCheckbox checked={active} onChange={updateActive} />}
        label={I18n.t("ui.queues.filter.date_range")}
        id="filter-date-range"
      />
      <FormLabel className={cls.withMargin}>{I18n.t("ui.queues.filter.date_from")}</FormLabel>
      <DatePicker
        disabled={!active}
        open={datePickersOpen.from}
        onOpen={() => setDatePickersOpen({ ...datePickersOpen, from: true })}
        onClose={() => setDatePickersOpen({ ...datePickersOpen, from: false })}
        value={dateRange.from}
        onChange={(date) => setDateRange({ ...dateRange, from: date })}
        renderInput={(props) => <TextField {...props} variant="outlined" />}
      />
      <FormLabel sx={{ ml: 1 }} className={cls.withMargin}>
        {I18n.t("ui.queues.filter.date_to")}
      </FormLabel>
      <DatePicker
        disabled={!active}
        open={datePickersOpen.to}
        onOpen={() => setDatePickersOpen({ ...datePickersOpen, to: true })}
        onClose={() => setDatePickersOpen({ ...datePickersOpen, to: false })}
        value={dateRange.to}
        onChange={(date) => setDateRange({ ...dateRange, to: date })}
        renderInput={(props) => <TextField {...props} variant="outlined" />}
      />
    </FormGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  withMargin: {
    marginRight: theme.spacing(1)
  }
}));

export default DateRangeFilter;
