import React from "react";
import { Box, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const UnenrolledLabel = withStyles((theme) => ({
  root: {
    border: `2px solid ${theme.custom.DARK_RED}`,
    borderRadius: theme.spacing(0.5),
    color: theme.custom.DARK_RED,
    fontWeight: theme.custom.SEMI_BOLD,
    textAlign: "center"
  }
}))((props) => (
  <Box {...props}>
    <Typography variant="button">{props.label}</Typography>
  </Box>
));

export default UnenrolledLabel;
