import React, { Fragment } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { IconButton, TextField } from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { addDays, subDays } from "date-fns";
import { toDate } from "lib/dates";

const DatePickerInput = ({
  className = "",
  selectedDate,
  onChange,
  label,
  withArrows = true,
  inputVariant = "standard",
  disablePast = false
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      {withArrows ? (
        <IconButton
          className={classes.weekChange}
          size="small"
          onClick={() => onChange(subDays(selectedDate, 7))}
        >
          <ArrowBackIos fontSize="inherit" />
        </IconButton>
      ) : null}
      <DatePicker
        className={className}
        showToolbar={false}
        inputFormat="MM/dd/yyyy"
        value={toDate(selectedDate)}
        onChange={(newDate, _) => onChange(newDate)}
        disablePast={disablePast}
        label={label}
        renderInput={(props) => <TextField margin="normal" variant={inputVariant} {...props} />}
      />
      {withArrows ? (
        <IconButton
          className={classes.weekChange}
          size="small"
          onClick={() => onChange(addDays(selectedDate, 7))}
        >
          <ArrowForwardIos fontSize="inherit" />
        </IconButton>
      ) : null}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  weekChange: {
    margin: theme.spacing(1, 1, 0, 1)
  }
}));

export default DatePickerInput;
