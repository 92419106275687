import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CheckboxList from "./CheckboxList";

const Grades = ({ form, errors, options, updateForm, reportType }) => {
  const cls = useStyles();
  const grades = reportType === "avery" ? options.grades.slice(0, -1) : options.grades;

  const updateGrades = (val) => {
    updateForm({ grades: val.map((v) => v.id) });
  };
  const values = grades.filter((g) => form.grades.includes(g.id));
  return (
    <CheckboxList
      name="grade"
      label="Grades"
      options={grades}
      values={values}
      update={updateGrades}
      error={errors?.grades?.[0]}
      className={cls.grade}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  grade: {
    minWidth: theme.spacing(8)
  }
}));

export default Grades;
