import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { addDays, format as dateFormat, isToday, startOfWeek } from "date-fns";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { parseDateFromUrl, formatDateUrl } from "lib/dates";
import { appendParamToUrl } from "lib/util";
import useDidMountEffect from "lib/useDidMountEffect";
import { pageRoutes } from "services/api";
import { getStudentCalendar } from "services/apiStudents";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";
import Spinner from "components/admin_v2/ui/Spinner";
import StudentModeChangeDialog from "components/admin_v2/mode_editor/StudentModeChangeDialog";
import ChangeRequestDialog from "components/admin_v2/change_requests/ChangeRequestDialog";
import CalendarSelector from "components/admin_v2/ui/CalendarSelector";
import CalendarDay from "./CalendarDay";
import StudentHeader from "./StudentHeader";
import { StudentAccommodationLabel } from "components/admin_v2/ui/StudentAccommodation";

const StudentCalendar = (props) => {
  const {
    student,
    date,
    trips,
    refresh,
    modeDialogDate,
    changeTripType,
    reqDialogOpen,
    dialogChangeReq
  } = useStoreState((s) => s.calendar);
  const {
    setStudent,
    updateCalendar,
    setDate,
    setRefresh,
    setModeDialogOpen,
    resetChangeTripData,
    closeReqDialog
  } = useStoreActions((s) => s.calendar);
  const [loading, setLoading] = useState(false);
  const cls = useStyles();
  const id = props.match.params.id;
  const schoolSlug = props.match.params.school_slug;

  const { onChangeScope } = useStoreActions((s) => s.app);

  const fetchStudentCalendar = useCallback((id, date) => {
    setLoading(true);
    let params = {};
    if (!isToday(date)) params.date = date;
    params.school_slug = schoolSlug;

    getStudentCalendar(id, params)
      .then((r) => {
        updateCalendar(r);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setDate(parseDateFromUrl());
    setRefresh(!refresh);
  }, []);

  useDidMountEffect(() => {
    if (!date) return;
    fetchStudentCalendar(id, date);
  }, [id, date, refresh]);

  const resetState = useCallback(() => {
    setStudent(null);
    props.history.push(pageRoutes.students());
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  if (!student) {
    return (
      <Box my={10}>
        <Typography variant="h2" align="center">
          {loading ? null : "Student not found!"}
        </Typography>
      </Box>
    );
  }

  if (!date) {
    return null;
  }

  const selectDate = (newDate) => {
    setDate(newDate);
    appendParamToUrl("date", formatDateUrl(newDate));
  };

  const onModeDialogClose = () => {
    resetChangeTripData();
    setModeDialogOpen(false);
  };

  const onModeDialogSave = () => {
    setModeDialogOpen(false);
    setRefresh(!refresh);
  };

  const onChangeReqDialogClose = () => closeReqDialog();

  const onChangeReqDialogSave = () => {
    closeReqDialog();
    setRefresh(!refresh);
  };

  const monday = startOfWeek(date, { weekStartsOn: 1 });
  const friday = addDays(monday, 4);
  const selectorLabel = [monday, friday].map((d) => dateFormat(d, "LLL d")).join(" - ");
  return (
    <Fragment>
      <StudentHeader student={student} view="calendar">
        <StudentAccommodationLabel student={student} />
      </StudentHeader>
      <Box className={cls.calHeader} my={2.5}>
        <CalendarSelector
          label={selectorLabel}
          date={date}
          onSelectDate={selectDate}
          next={addDays(date, 7)}
          prev={addDays(date, -7)}
          flex="1"
        />
        <Box>
          <Button className={cls.btn} variant="contained" color="secondary" key="btn-weekly">
            Weekly
          </Button>
          <Button className={cls.btn} variant="outlined" disabled key="btn-monthly">
            Monthly
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Spinner />
      ) : (
        <Grid container justifyContent="center" spacing={2}>
          {trips.map((dayTrips, index) => (
            <CalendarDay dayTrips={dayTrips} index={index} key={`str-${index}`} />
          ))}
        </Grid>
      )}
      <StudentModeChangeDialog
        onClose={onModeDialogClose}
        onCancel={onModeDialogClose}
        onSave={onModeDialogSave}
        student={student}
        date={modeDialogDate}
        isCustom={isOtherRoute(changeTripType)}
        showViewProfile={false}
      />
      <ChangeRequestDialog
        onClose={onChangeReqDialogClose}
        onCancel={onChangeReqDialogClose}
        onSave={onChangeReqDialogSave}
        reqDialogOpen={reqDialogOpen}
        changeReq={dialogChangeReq}
      />
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  btn: {
    height: "100%",
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1, 3)
  },
  calHeader: {
    display: "flex"
  }
}));

export default StudentCalendar;
