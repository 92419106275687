import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { format as dateFormat, parseISO, isValid } from "date-fns";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Box, TableCell, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import classNames from "classnames";
import { formatTime, formatDisplayTime, parseTime, weekDays } from "lib/dates";
import { updateStop } from "services/api";
import { tripTz } from "components/admin_v2/trips/tripHelpers";

const TimeBox = withStyles((theme) => ({
  root: {
    fontWeight: theme.custom.BOLD,
    borderRadius: theme.spacing(0.5)
  }
}))(Box);

const StopTimeInput = ({ stop, date, newStop, isSelected }) => {
  const cls = useStyles();
  const { linkDays } = useStoreState((s) => s.routeEditor);
  const isDayDisabled = useStoreState((s) => s.routeEditor.isDayDisabled(date));
  const { school } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const { updateStopData } = useStoreActions((s) => s.routeEditor);
  const [active, setActive] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const [stopTime, setStopTime] = useState(parseTime(stop?.display_time));

  useEffect(() => {
    setStopTime(parseTime(stop?.display_time));
  }, [stop]);

  if (!stop)
    return (
      <TableCell component="td" scope="row" align="center">
        <TimeBox className={cls.notLinkable}>-- : --</TimeBox>
      </TableCell>
    );

  const notLinked = !stop || isSelected;
  const showTime = !linkDays || notLinked;
  const stopCls = (newStop, linkDays, isSelected, isDayDisabled) => {
    if (isDayDisabled) return classNames(cls.time, cls.dayDisabled);
    if (newStop) return cls.newStop;
    return !linkDays || isSelected ? cls.time : cls.notLinkable;
  };

  const toggleInput = () => {
    if (!stop || isDayDisabled) return;

    setActive(!active);
  };

  const onEnter = (e) => {
    if (e.keyCode === 13 && e.target.value) {
      toggleInput();
      if (formatDisplayTime(stopTime) === stop.display_time) return;

      updateStops();
    }
  };

  const onBlur = (_e) => {
    if (timePickerOpen) return;
    toggleInput();
    if (formatDisplayTime(stopTime) === stop.display_time) return;

    updateStops();
  };

  const updateStops = () => {
    if (!isValid(stopTime)) return;

    const params = {
      school_id: school?.id || stop.school_id,
      start_date: date,
      stop: { time: formatTime(stopTime) }
    };
    const weekDay = dateFormat(parseISO(date), "EEEE");

    params.week_days = linkDays ? weekDays : [weekDay];

    updateStop(stop.id, params)
      .then((r) => {
        setFlashMessage({ message: r.message, type: r.type });
        updateStopData(r.stops);
      })
      .catch((err) => setFlashMessage({ message: err.message }));
  };

  const onChange = (time) => {
    setStopTime(time);
  };

  const onTimePickerDialogOpen = (e) => {
    e.preventDefault();
    setTimePickerOpen(true);
  };

  const onClose = () => setTimePickerOpen(false);

  if (active)
    return (
      <TableCell component="td" scope="row" align="center">
        <Box>
          <TimePicker
            mask="__:__"
            inputFormat="HH:mm"
            ampm={false}
            OpenPickerButtonProps={{ size: "small" }}
            InputAdornmentProps={{ onMouseDown: onTimePickerDialogOpen }}
            value={stopTime}
            onChange={onChange}
            onClose={onClose}
            desktopModeMediaQuery="@media (min-width:320px)"
            renderInput={(props) => (
              <TextField
                name="time-input"
                onKeyDown={onEnter}
                onBlur={onBlur}
                autoFocus
                variant="outlined"
                type="text"
                className={cls.input}
                {...props}
              />
            )}
          />
        </Box>
      </TableCell>
    );

  return (
    <TableCell component="td" scope="row" align="center">
      {showTime ? (
        <TimeBox
          className={stopCls(newStop, linkDays, isSelected, isDayDisabled)}
          onClick={toggleInput}
          id="stop-time"
        >
          {stop.display_time}
          {tripTz(stop.time_zone_abbr)}
        </TimeBox>
      ) : null}
    </TableCell>
  );
};

const useStyles = makeStyles((theme) => ({
  dayDisabled: {
    color: theme.custom.MEDIUM_GREY,
    pointerEvents: "none"
  },
  time: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    backgroundColor: theme.custom.LIGHT_GREY,
    cursor: "pointer"
  },
  notLinkable: {
    borderStyle: "solid",
    borderColor: theme.custom.LIGHT_GREY,
    borderWidth: theme.spacing(0.2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  newStop: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    color: theme.custom.WHITE,
    backgroundColor: theme.custom.GREEN,
    cursor: "pointer"
  },
  input: {
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
      fontSize: "0.87rem",
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      paddingRight: 0
    },
    "& .MuiIconButton-sizeSmall": {
      padding: "3px",
      fontSize: "1.25rem",
      "& .MuiSvgIcon-root": {
        fontSize: "inherit"
      }
    }
  }
}));

export default StopTimeInput;
