import React, { useState, useEffect } from "react";
import { Box, Link, Typography, TextField } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreActions } from "easy-peasy";
import { compareAsc, isSameDay, isSameMonth } from "date-fns";
import { fetchRouteSchedule } from "services/apiRoutes";
import { formatDateUrl } from "lib/dates";
import useDidMountEffect from "lib/useDidMountEffect";
import I18n from "utils/i18n.js";

const RouteCalendar = ({ date, route, tripTypes, children = null }) => {
  const cls = useStyles();
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [activeDays, setActiveDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [monthDate, setMonthDate] = useState(date);

  const toggleDetails = () => setOpen(!open);

  useDidMountEffect(() => setOpen(false), [route.id]);

  useEffect(() => {
    if (!open) return;

    setLoading(true);
    fetchRouteSchedule(route.id, { date: formatDateUrl(monthDate), tripTypes })
      .then((days) => setActiveDays(days))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => setLoading(false));
  }, [open, date, monthDate]);

  const renderDay = (calendarDate, selectedDates, pickersDayProps) => {
    const dayProps = { ...pickersDayProps, selected: isSameDay(calendarDate, date) };
    if (!activeDays[formatDateUrl(calendarDate)]) {
      return isSameDay(calendarDate, date) ? (
        <PickersDay {...dayProps} classes={{ root: cls.currentDisabled }} />
      ) : (
        <PickersDay {...dayProps} />
      );
    }
    const clsDay =
      compareAsc(calendarDate, date) >= 0 || isSameDay(calendarDate, date)
        ? cls.allowedDays
        : cls.pastAllowedDays;
    return <PickersDay {...dayProps} classes={{ root: clsDay }} />;
  };

  return (
    <Box className={cls.calendar}>
      {children || (
        <Typography color="error" display="inline">
          {route.schedule_details}&nbsp;
        </Typography>
      )}
      <Link component="button" className={cls.link} onClick={toggleDetails} underline="hover">
        <Typography>{I18n.t(`ui.schedule.details.${open ? "hide" : "show"}`)}</Typography>
      </Link>
      {open && (
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          reduceAnimations={true}
          showToolbar={false}
          showDaysOutsideCurrentMonth={true}
          orientation="portrait"
          openTo="day"
          loading={loading}
          value={isSameMonth(date, monthDate) ? date : monthDate}
          views={["day"]}
          readOnly={true}
          onChange={() => {}}
          onMonthChange={(month) => setMonthDate(month)}
          renderInput={(props) => <TextField {...props} />}
          renderDay={renderDay}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    verticalAlign: "baseline"
  },
  calendar: {
    "& .MuiPickersDay-today, .Mui-selected": {
      color: "inherit"
    },
    "& .MuiPickersDay-dayOutsideMonth": {
      opacity: "0.5"
    },
    "& .MuiPickerStaticWrapper-root": {
      backgroundColor: "transparent",
      marginTop: theme.spacing(0.5)
    }
  },
  allowedDays: {
    backgroundColor: `${theme.custom.GREEN} !important`,
    opacity: "1"
  },
  pastAllowedDays: {
    backgroundColor: theme.custom.MEDIUM_GREY,
    opacity: "1"
  },
  currentDisabled: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.custom.RED}`
  }
}));

export default RouteCalendar;
