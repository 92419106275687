import React from "react";
import { RadioGroup, FormControlLabel } from "@mui/material";
import { map } from "lodash";
import StarIcon from "@mui/icons-material/Star";
import I18n from "utils/i18n.js";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const SCHEDULES_FILTER_OPTIONS = ["today", "future", "all"];

const SchedulesRadioFilter = ({ selected, onChange }) => {
  const cls = useCommonPageStyles();

  const typeOptionsRadio = map(SCHEDULES_FILTER_OPTIONS, (opt) => (
    <FormControlLabel
      value={opt}
      key={`filter-${opt}`}
      control={<GreenRadio />}
      data-test-id={`date-filter-${opt}`}
      label={
        <>
          {I18n.t(`data_management.assigned_vehicles.filter.${opt}`)}
          {opt === "today" && <StarIcon fontSize="small" className={cls.icon} />}
        </>
      }
    />
  ));

  return (
    <RadioGroup value={selected} onChange={onChange} row>
      {typeOptionsRadio}
    </RadioGroup>
  );
};

export default SchedulesRadioFilter;
