import React, { Fragment } from "react";
import { Box, Divider } from "@mui/material";
import ChangeRequest from "./ChangeRequest";
import RemoveEditor from "./RemoveEditor";
import TripModeEditor from "./TripModeEditor";
import useTripEditorStyles from "../common/useTripEditorStyles";

const TripChangeEditor = ({ modeChangeStore, tripType, idx, children }) => {
  const cls = useTripEditorStyles();
  const [state, _actions] = modeChangeStore;
  const trip = state.getTrip(idx);
  const details = state.getDetails(idx);

  const editorBox = () => {
    if (trip?.change_request) return <ChangeRequest trip={trip} tripType={tripType} />;
    if (details.eventType === "remove")
      return <RemoveEditor modeChangeStore={modeChangeStore} idx={idx} />;
    return (
      <TripModeEditor
        modeChangeStore={modeChangeStore}
        route={state.getTripData(idx)?.route}
        tripType={tripType}
        idx={idx}
      />
    );
  };

  return (
    <Box
      className={cls.tripEditorWrapper}
      display={state.loading ? "none" : "block"}
      data-test-id="tripEditor"
    >
      {children}
      {state.withEditorBox({ idx, trip }) ? (
        <Fragment>
          <Divider variant="fullWidth" className={cls.separator} />
          <Box className={cls.changeBox}>{editorBox()}</Box>
        </Fragment>
      ) : null}
    </Box>
  );
};

export default TripChangeEditor;
