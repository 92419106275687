import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Spinner from "components/admin_v2/ui/Spinner";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";

const ReportGenerationStatus = ({ reportStore, withBottomMargin = false }) => {
  const [state, actions] = reportStore;
  const cls = useStyles({ withBottomMargin });
  const generatedReports = state.generated?.map((g, i) => (
    <Box className={cls.message} key={`report-msg-${i}`}>
      {g.reportType === "route_sheets" && g.reportFormat == "email" ? (
        I18n.t("report.status.route_sheets.email")
      ) : (
        <Fragment>
          <Box display="flex" mr={1}>
            {I18n.t("report.status.ready")}
          </Box>
          <a
            href={g.filePath}
            download={g.fileName}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => actions.reportDownloaded(g)}
          >
            {g.fileName}
          </a>
        </Fragment>
      )}
      <a href="#" onClick={() => actions.removeGenerated(i)} className={cls.close}>
        X
      </a>
    </Box>
  ));

  return (
    <>
      {state.generating && (
        <Box className={cls.message}>
          <Spinner size="small" withMargin={false} />
          <Box display="flex" ml={1} mr={1}>
            {I18n.t(
              `report.status.generating.${state.generatingCount > 1 ? "multiple" : "single"}`
            )}
          </Box>
          <NavLink to={pageRoutes.reportFiles()}>See all reports</NavLink>
          <a href="#" onClick={() => actions.setGenerating(false)} className={cls.close}>
            X
          </a>
        </Box>
      )}
      {generatedReports}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  message: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    backgroundColor: theme.custom.GREEN,
    color: theme.custom.WHITE,
    padding: theme.spacing(1, 2),
    marginBottom: (props) => theme.spacing(props.withBottomMargin ? 2 : 0),
    marginTop: (props) => theme.spacing(props.withBottomMargin ? 0 : 2),
    borderRadius: theme.spacing(0.5),
    fontSize: "1rem",
    fontWeight: theme.custom.SEMIBOLD,

    "& a": {
      textDecoration: "underline",
      color: theme.custom.WHITE
    }
  },
  close: {
    position: "absolute",
    textDecoration: "none !important",
    fontWeight: theme.custom.BOLD,
    right: theme.spacing(2)
  }
}));

export default ReportGenerationStatus;
