import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import {
  fetchRouteImports,
  submitRouteImport,
  fetchImportConflicts,
  resolveConflicts
} from "services/apiRoutesImports";
import { setFlashMessage } from "services";
import { toFormData } from "lib/util";
import { refreshModel } from "./refreshModel";

const IMPORT_FORM = {
  files: null,
  startDate: null
};

export const defaultState = {
  status: null,
  // status
  addImportDialogOpen: false,
  loading: false,
  submitDisabled: false,
  // data
  imports: [],
  newImport: { ...IMPORT_FORM },
  conflicts: [],
  errors: {}
};

export const routeImportsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...refreshModel(),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["status"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchImports: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["page", "perPage", "status"]);

    actions.setLoading(true);

    return fetchRouteImports({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  fetchImportConflicts: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchImportConflicts(id)
      .then((resp) => actions.setConflicts(resp.conflicts))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.imports = data.imports;
    state.total = parseInt(data.total);
  }),

  addImport: action((state, newImport) => {
    let importsList = state.imports;
    importsList.unshift(newImport);
    state.imports = importsList;
  }),

  importChange: action((state, updatedImport) => {
    state.imports = state.imports.map((i) => {
      return i.id === updatedImport.id ? updatedImport : i;
    });
  }),

  changeResolution: action((state, { conflict, resolution }) => {
    state.conflicts = state.conflicts.map((c) => {
      return c.id === conflict.id ? { ...conflict, resolution: resolution } : c;
    });
  }),

  changeAll: action((state, resolution) => {
    state.conflicts = state.conflicts.map((c) => {
      return { ...c, resolution: resolution };
    });
  }),

  submitConflicts: thunk((_actions, id, h) => {
    const state = h.getState();
    let params = { resolve: {} };
    state.conflicts.forEach((c) => {
      params.resolve[c.id] = c.resolution;
    });

    resolveConflicts(id, params)
      .then((resp) => {
        setFlashMessage(resp.message);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  submit: thunk((actions, _payload, h) => {
    actions.setSubmitDisabled(true);
    actions.setErrors(null);
    const state = h.getState();

    submitRouteImport(toFormData(state.newImport))
      .then((resp) => {
        setFlashMessage(resp.message);
        actions.addImport(resp.import);
        actions.setAddImportDialogOpen(false);
        actions.setNewImport({ ...IMPORT_FORM });
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;
        actions.setErrors(errors);
      })
      .finally(() => actions.setSubmitDisabled(false));
  })
});
