import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const saveChangeRequests = async (params) => {
  const url = apiUrl(`change_requests`);
  return await xhr.post(url, params, { transformData: true });
};

export const cancelChangeReq = async (id) => {
  const url = apiUrl(`change_requests/${id}/cancel`);
  return await xhr.post(url);
};

export const resolveChangeReq = async (id, params) => {
  const url = apiUrl(`change_requests/${id}/resolve`);
  return await xhr.post(url, params);
};

export const fetchChangeRequests = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`change_requests?${query}`));
};

export const fetchChangeRequest = async (id, params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`change_requests/${id}?${query}`));
};

export const changeRequestsFilters = async () => {
  const query = queryString({});
  return await xhr.get(apiUrl(`change_requests/filters?${query}`));
};

export const changeRequestsNotification = async () => {
  const query = queryString({});
  return await xhr.get(apiUrl(`change_requests/notification?${query}`));
};
