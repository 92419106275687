import React from "react";
import Box from "@mui/material/Box";
import I18n from "utils/i18n.js";

const WaypointPopup = ({ trip, cls, lat, lng, time }) => {
  return (
    <>
      <Box className={cls.popup}>
        <p className={cls.label}>{I18n.t("ui.avl.labels.time")}</p>
        <p className={cls.popupInfo}>{`${time} ${trip.time_zone_abbr}`}</p>
        <p className={cls.label}>{I18n.t("ui.avl.labels.coords")}</p>
        <p className={cls.popupInfo}>{`${lat}, ${lng}`}</p>
        <p className={cls.label}>{I18n.t("ui.avl.labels.vehicle")}</p>
        <p className={cls.popupInfo}>{trip.vehicle_info}</p>
      </Box>
    </>
  );
};

export default WaypointPopup;
