import React, { Fragment } from "react";
import { Divider } from "@mui/material";
import { isEmpty, map } from "lodash";
import RoutePeriodSelect from "./RoutePeriodSelect";
import RouteSelect from "./RouteSelect";
import RouteStopForm from "./RouteStopForm";
import { componentsJoin } from "lib/util";

const RouteForm = ({ cls, store }) => {
  const [state, _actions] = store;

  const withReturn = state.route?.directions?.length > 1;
  const directions = map(state.route?.directions || [], (direction) => (
    <RouteStopForm
      key={`r-direction-${direction.type}`}
      withCheckBox={withReturn}
      direction={direction}
      store={store}
      cls={cls}
    />
  ));

  const renderSeparator = (idx) => <Divider key={`r-direction-${idx}`} className={cls.divider} />;

  return (
    <Fragment>
      <RouteSelect store={store} />
      <RoutePeriodSelect store={store} cls={cls} />
      {!isEmpty(directions) && (
        <Fragment>
          <Divider className={cls.divider} />
          {componentsJoin(directions, renderSeparator)}
        </Fragment>
      )}
    </Fragment>
  );
};

export default RouteForm;
