import React from "react";
import { Box, FormControl, RadioGroup, FormControlLabel, FormLabel } from "@mui/material";
import { map, values } from "lodash";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import { ROUTABLE_TYPES } from "components/admin_v2/students/stores/addTripStore";
import FormSelect from "components/admin_v2/ui/FormSelect";
import { useStoreState } from "easy-peasy";
import I18n from "utils/i18n.js";

const RouteHeaderSelector = ({ cls, store }) => {
  const [state, actions] = store;
  // TODO: (DISTRICT) Should we refetch here? Or at other places? We will keep schools/districts till page reload
  // even on adding school/district
  const { schools, districts, district } = useStoreState((s) => s.app);

  const resetToDefault = ({ routableType, routeType, id, isCrossSchool }) => {
    const routable = (isCrossSchool ? districts : schools).find((r) => r.id == id);
    let schoolId = isCrossSchool ? null : id;
    let districtId = isCrossSchool ? id : null;
    actions.resetState({
      routableType,
      routeType,
      schoolId,
      districtId,
      period: {
        startDate: state.day,
        endDate: routable?.last_day || routable?.lastDay || null,
        week: 1
      },
      workflow: { createRoute: true, stops: {} }
    });
  };

  const handleChange = (event) => {
    const routableType = event.target.value;
    const isCrossSchool = routableType === ROUTABLE_TYPES.crossSchool;
    const id = isCrossSchool ? district?.id : state.school?.id;
    resetToDefault({ routableType, routeType: "regular", id, isCrossSchool });
  };

  const onRoutableChange = (event) => {
    const id = event.target.value;
    resetToDefault({
      routableType: state.routableType,
      routeType: state.routeType,
      id,
      isCrossSchool: state.isCrossSchool
    });
  };

  const routeTypesRadio = map(values(ROUTABLE_TYPES), (type) => (
    <FormControlLabel
      value={type}
      key={`routable-type-${type}`}
      control={<GreenRadio />}
      label={I18n.t(`ui.route.routable_type.select.${type}`)}
      className={cls.radioInput}
    />
  ));

  return (
    <Box mt={4}>
      <FormControl component="fieldset" fullWidth={true}>
        <RadioGroup value={state.routableType} onChange={handleChange} row>
          {routeTypesRadio}
        </RadioGroup>
      </FormControl>
      <FormControl className={cls.withTopMargin} fullWidth required>
        <FormLabel className={cls.label}>
          {I18n.t(`ui.route.routable_type.label.${state.routableType}`)}
        </FormLabel>
        <FormSelect
          itemType={state.routableType}
          options={state.isCrossSchool ? districts : schools}
          value={state.isCrossSchool ? state.districtId : state.schoolId}
          onChange={onRoutableChange}
          cls={cls}
          clsKeys={["select", "select"]}
          withoutLabel={true}
          required
        />
      </FormControl>
    </Box>
  );
};

export default RouteHeaderSelector;
