import React, { useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FormControlLabel from "@mui/material/FormControlLabel";
import { capitalize } from "lodash";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import AddStudentImportDialog from "./AddStudentImportDialog";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { studentImportsStore } from "./stores/studentImportsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import useMessageBus from "lib/useMessageBus";

const STATUS_FILTERS = ["failed", "in-progress", "success"];

const StudentImports = (props) => {
  const cls = useCommonPageStyles();
  const { school, schools } = useStoreState((s) => s.app);
  const { onChangeScope } = useStoreActions((s) => s.app);
  const store = useLocalStore(() => studentImportsStore(camelizeLocationSearch(props)));
  const [state, actions] = store;

  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  const resetState = useCallback(() => {
    actions.toggleRefresh();
    updateQueryString({ page: 1 });
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    if (schools.length === 0) return;

    actions.setNewImport({ ...state.newImport, importableId: school?.id });
  }, [school, schools]);

  useEffect(() => {
    actions.fetchImports({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [state.page, state.perPage, state.status, state.refresh, sortBy]);

  useMessageBus({
    channel: "imports-students_imports",
    condition: true,
    received: (r) => actions.importChange(r.import)
  });

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSelectStatus = (status) => {
    const newStatus = state.status === status ? null : status;
    updateQueryString({ page: 1, status: newStatus });
  };

  const toggleAddImportDialog = () => {
    actions.setAddImportDialogOpen(!state.addImportDialogOpen);
  };

  const headers = [
    { field: "id", label: "ID" },
    { field: "school", label: "School" },
    { field: "created_at", label: "Initiated at" },
    { field: "status", label: "Status" },
    { field: "import_errors", label: "Errors", skipSorting: true },
    { field: "details", label: "Details", skipSorting: true }
  ].map(sortableColumn);

  const row = (studentImport) => (
    <TableRow key={`import-${studentImport.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        {studentImport.id}
      </TableCell>
      <TableCell style={{ width: "20%" }}>{studentImport.school}</TableCell>
      <TableCell style={{ width: "10%" }}>{studentImport.created_at}</TableCell>
      <TableCell style={{ width: "10%" }}>
        <Box className={`${cls[studentImport.status]} ${cls.statusBox}`}>
          {capitalize(studentImport.status)}
        </Box>
      </TableCell>
      <TableCell style={{ width: "25%" }}>{studentImport.display_errors}</TableCell>
      <TableCell>
        {studentImport.details?.map((d, idx) => (
          <div key={`${studentImport.id}-${idx}`}>{d}</div>
        ))}
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <Box className={cls.btnsWrapper}>
        <OutlinedBtn onClick={toggleAddImportDialog}>
          {I18n.t("data_management.new_student_import.btn")}
        </OutlinedBtn>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          {STATUS_FILTERS.map((status) => (
            <FormControlLabel
              key={status}
              className={cls.filter}
              control={
                <GreenCheckbox
                  checked={state.status === status}
                  onChange={() => onSelectStatus(status)}
                />
              }
              label={capitalize(status)}
            />
          ))}
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.imports.map(row)}
          </PaginatedTable>
        )}
      </Paper>
      <AddStudentImportDialog
        store={store}
        cls={cls}
        open={state.addImportDialogOpen}
        toggleDialog={toggleAddImportDialog}
      />
    </React.Fragment>
  );
};

export default StudentImports;
