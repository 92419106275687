import React, { Fragment, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { componentsJoin } from "lib/util";
import StopLabel, { getEventType } from "../common/StopLabel";
import { getAnchorType } from "../stores/stopsModel";
import ModeChangeTrip from "./ModeChangeTrip";
import { CANCEL_EVENT_LIST } from "components/admin_v2/common/EventHelpers";
import I18n from "utils/i18n.js";

const CustomTrip = ({ day, trip, index }) => {
  const { anchor } = trip;
  const anchorType = getAnchorType(trip.trip_type);
  const eventType = useMemo(() => getEventType(trip.events, trip), [trip]);
  const isChangeAdd = trip.events.change_add;
  const textDecoration = CANCEL_EVENT_LIST.includes(eventType) ? "line-through" : "none";
  const stop = (
    <Fragment key="ct-stop">
      <StopLabel
        day={day}
        eventType={eventType}
        stop={isChangeAdd ? null : trip}
        withRouteLink={!isChangeAdd && anchorType === "dropoff"}
        extraLabel={I18n.t(`ui.stop.label.${anchorType === "dropoff" ? "pickup" : "dropoff"}`)}
      />
      <Box sx={{ textDecoration }} pt={anchorType !== "dropoff" ? 2 : 0}>
        {isChangeAdd ? null : trip.address}
      </Box>
    </Fragment>
  );
  const anchorStop = (
    <Fragment key="ct-anchor">
      <StopLabel
        day={day}
        stop={{ ...anchor, route: trip.route, vehicle: trip.vehicle }}
        withRouteLink={anchorType !== "dropoff"}
        extraLabel={I18n.t(`ui.stop.label.${anchorType}`)}
      />
      <Box sx={{ textDecoration }} pt={anchorType === "dropoff" ? 2 : 0}>
        {anchor?.address}
      </Box>
    </Fragment>
  );
  const renderSeparator = (idx) => <Box key={`ct-sep-${idx}`} mt={3} />;

  return (
    <Box mb={2} mx={-1} px={1} py={1.5} bgcolor="background.lightGrey" borderRadius={1}>
      <ModeChangeTrip
        day={day}
        trip={trip}
        tripType={trip.trip_type}
        index={index}
        eventType={eventType}
        addEvent={trip.student_add || trip.is_student_add}
      >
        {isChangeAdd ? (
          <Fragment>
            {stop}
            <Box mt={1}>
              <Typography variant="body2">
                <strong>{I18n.t("ui.mode_editor.add")}</strong>
              </Typography>
              <Typography variant="body2">{trip.route?.name}</Typography>
              <Typography variant="body2">
                {trip.time ? `${trip.time}, ${trip.address}` : trip.address}
              </Typography>
            </Box>
          </Fragment>
        ) : (
          componentsJoin(
            anchorType === "dropoff" ? [stop, anchorStop] : [anchorStop, stop],
            renderSeparator
          )
        )}
        <Box mb={2} />
      </ModeChangeTrip>
    </Box>
  );
};

export default CustomTrip;
