import React from "react";
import { Box, FormControl, FormLabel, FormHelperText } from "@mui/material";
import { isEmpty, get, map } from "lodash";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import AddressNewForm from "../address/AddressNewForm";
import { getReturnStopType } from "components/admin_v2/students/common";

const RouteNewReturnForm = ({ store, stopTypes, tripType, cls }) => {
  const [state, actions] = store;

  const onChangeTime = (stopType, time) => actions.setStopTime({ stopType, tripType, time });

  const stops = map(stopTypes, (stopType) => {
    const { errorStop, errorTime } = state.getStopErrorsFor(tripType, stopType);
    return (
      <Box key={`stop-return-${stopType}`} mb={2}>
        <FormControl fullWidth margin="normal" error={!isEmpty(errorStop)}>
          <ErrorList errors={errorStop} />
          <FormLabel className={cls.label}>{I18n.t(`ui.stop.${stopType}`)}</FormLabel>
          <AddressNewForm
            store={store}
            stopType={getReturnStopType(stopType)}
            tripType="direct"
            cls={cls}
            returnTrip={true}
          />
        </FormControl>
        <FormControl error={!!errorTime} required>
          <FormLabel className={cls.label}>{I18n.t(`ui.stop.time.${stopType}`)}</FormLabel>
          <TimePickerInput
            dateTime={get(state.stops, `${tripType}.${stopType}.time`, null)}
            onChange={(time) => onChangeTime(stopType, time)}
          />
          {errorTime && <FormHelperText>{errorTime}</FormHelperText>}
        </FormControl>
      </Box>
    );
  });

  return stops;
};

export default RouteNewReturnForm;
