import { action, thunk, computed } from "easy-peasy";
import { assign, pick } from "lodash";
import { isBefore } from "date-fns";
import { parseTime } from "lib/dates";
import { easyStateSetters } from "lib/easyState";
import {
  apiFetchRoutePairs,
  createRoutePair,
  removeRoutePair,
  saveRoutePair,
  fetchRoutesList,
  fetchPairedRouteData
} from "services/apiRoutePairs";
import { fetchVendorsList } from "services/apiVendors";
import { setFlashMessage } from "services";
import { EDIT_MODE } from "components/admin_v2/forms/stores/tableSettingStore";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import alertStore from "components/admin_v2/common/stores/alertStore";
import tableSettingStore from "components/admin_v2/forms/stores/tableSettingStore";
import I18n from "utils/i18n.js";

export const BOTH_DIRECTIONS = ["to_school", "from_school"];

export const defaultState = {
  // options
  vehicleTypes: [],
  // status
  loading: false,
  refresh: false,
  // data
  transportationVendors: [],
  vendorsRoutes: {}
};

export const pairedRoutesStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...tableSettingStore({
    entity: { name: "RoutePair", key: "routePair" },
    endPoints: {
      createEntity: createRoutePair,
      removeEntity: removeRoutePair,
      saveEntity: saveRoutePair
    },
    data: []
  }),
  ...alertStore(initialData),

  invalidDirections: computed((state) => (idx) => {
    const data = state.getRow(idx)?.entity || {};
    if (!data.directions || !data.tierOneRoute || !data.tierTwoRoute) return false;

    return data.directions.some(
      (d) =>
        (d === "to_school" &&
          [data.tierOneRoute, data.tierTwoRoute].some((r) => !r?.inboundFirstPickup)) ||
        (d === "from_school" &&
          [data.tierOneRoute, data.tierTwoRoute].some((r) => !r?.outboundFirstPickup))
    );
  }),

  isValid: computed((state) => (idx) => {
    const data = state.getRow(idx)?.entity || {};

    return (
      data.transportationVendorId &&
      data.tierOneRouteId &&
      data.tierTwoRouteId &&
      data.directions &&
      !state.invalidDirections(idx)
    );
  }),

  formData: computed((state) => (idx) => {
    return state.data[idx].entity;
  }),

  newEntity: computed((_state) => {
    return {
      id: null,
      directions: null,
      tierOneRouteId: null,
      tierTwoRouteId: null,
      tierOneRoute: null,
      tierTwoRoute: null,
      notes: ""
    };
  }),

  setFromRouter: action((state, props) => {
    assign(state, enhancePaginationProps(props));
  }),

  validateMismatch: thunk((actions, params, h) => {
    const { idx } = params;
    const data = h.getState().getRow(idx).entity;
    const { tierOneRoute, tierTwoRoute } = data;

    const mismatch = {
      idx: idx,
      vehiclesMismatch: data.directions.some(
        (d) =>
          (d === "to_school" &&
            data.tierOneRoute.inboundVehicle !== data.tierTwoRoute.inboundVehicle) ||
          (d === "from_school" &&
            data.tierOneRoute.outboundVehicle !== data.tierTwoRoute.outboundVehicle)
      ),
      timesMismatch: data.directions.some(
        (d) =>
          (d === "to_school" &&
            isBefore(
              parseTime(tierTwoRoute.inboundFirstPickup),
              parseTime(tierOneRoute.inboundLastDropoff)
            )) ||
          (d === "from_school" &&
            isBefore(
              parseTime(tierTwoRoute.outboundFirstPickup),
              parseTime(tierOneRoute.outboundLastDropoff)
            ))
      )
    };

    if (mismatch.vehiclesMismatch || mismatch.timesMismatch) {
      actions.setMessageParams(mismatch);
    } else {
      actions.save({ idx });
    }
  }),

  setMessageParams: action((state, params) => {
    const title = !params.timesMismatch
      ? "data_management.paired_routes.messages.vehicles_mismatch.title"
      : "data_management.paired_routes.messages.times_mismatch.title";
    const submitLabel = !params.timesMismatch
      ? "data_management.paired_routes.messages.vehicles_mismatch.button"
      : "data_management.paired_routes.messages.times_mismatch.button";

    state.alert = {
      ...params,
      title: I18n.t(title),
      iconType: "info",
      submitLabel: I18n.t(submitLabel),
      cancelLabel: I18n.t("data_management.paired_routes.messages.button.cancel"),
      submitParams: { idx: params.idx }
    };
    state.alertOpen = true;
  }),

  fetchRoutePairs: thunk((actions, params, h) => {
    actions.setLoading(true);
    const stateParams = pick(h.getState(), ["page", "perPage"]);

    return apiFetchRoutePairs({ ...params, ...stateParams })
      .then((r) => actions.updateData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  fetchVendorOptions: thunk((actions, _params, _h) => {
    return fetchVendorsList({ onlyRiding: 1 })
      .then((r) => actions.setTransportationVendors(r.vendors))
      .catch((err) => setFlashMessage(err.message));
  }),

  fetchRoutesOptions: thunk((actions, _params, _h) => {
    return fetchRoutesList()
      .then((r) => actions.setVendorsRoutes(r.routes))
      .catch((err) => setFlashMessage(err.message));
  }),

  fetchRouteData: thunk((actions, params, _h) => {
    if (!params.routeId) {
      let fields = [params.field];
      if (params.field === "transportationVendorId") {
        fields = ["tierOneRouteId", "tierTwoRouteId"];
      }
      fields.forEach((field) => {
        actions.setPairedRouteData({ idx: params.idx, field: field, data: null });
      });
      return;
    }

    return fetchPairedRouteData({ routeId: params.routeId })
      .then((r) =>
        actions.setPairedRouteData({
          idx: params.idx,
          field: params.field,
          data: r.route
        })
      )
      .catch((err) => setFlashMessage(err.message));
  }),

  setPairedRouteData: action((state, payload) => {
    const { idx, field, data } = payload;
    state.data[idx].entity = { ...state.data[idx].entity, [field.slice(0, -2)]: data };
  }),

  updateData: action((state, data) => {
    state.data = [];
    state.routePairs = data.routePairs;
    state.total = parseInt(data.total);
    data.routePairs.forEach((rp) => {
      let entity = state.data.find((sd) => rp.id === sd.entity.id);
      if (entity) {
        entity.entity = rp;
      } else {
        state.data.push({
          entity: rp,
          errors: {},
          editMode: EDIT_MODE.view
        });
      }
    });
  }),

  newRoutePair: action((state, _payload) => {
    state.routePairs.push({
      id: null,
      vendorId: null,
      tierOneRouteId: null,
      tierTwoRouteId: null,
      directions: BOTH_DIRECTIONS
    });
  })
});
