import React from "react";
import classNames from "classnames";
import { TableCell } from "@mui/material";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";

const TableHeaders = ({ headers }) => {
  const cls = useTableFormStyles();

  return headers.map((header) => {
    const clsCell = classNames(cls.tableHeader, {
      [cls.smCell]: header.key === "sort-by-primary",
      [cls.iconCell]: header.key === "sort-by-editMode"
    });
    return (
      <TableCell key={header.key} align={header.align} className={clsCell}>
        <>
          {header.label}
          {header.required ? <sup>*</sup> : null}
          {header.subLabel ? (
            <>
              <br />
              <small>({header.subLabel})</small>
            </>
          ) : null}
        </>
      </TableCell>
    );
  });
};

export default TableHeaders;
