import { action, thunk, computed } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { camelizeKeys } from "humps";
import { strPresent } from "lib/util";
import {
  fetchVehicles,
  fetchVehicle,
  archiveVehicle,
  createVehicle,
  saveVehicle
} from "services/apiVehicles";
import { fetchTrackingDevicesList } from "services/apiDevices";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { setFlashMessage } from "services";

const REQUIRED = ["name", "transportationVendorId", "vehicleType"];
const FORM_ATTRS = [
  "id",
  "name",
  "transportationVendorId",
  "vehicleType",
  "licensePlate",
  "icabbiId",
  "capacity",
  "trackingDeviceId"
];

export const defaultState = {
  // status
  addVehicleDialogOpen: false,
  loading: false,
  submitDisabled: false,
  refresh: false,
  // filters
  scope: "active",
  query: null,
  // data
  vehicles: [],
  vehicle: null,
  vehicleForm: {},
  errors: {},
  trackingDevices: []
};

export const vehiclesStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  isValid: computed((state) => {
    const { vehicleForm } = state;
    const validVehicle = REQUIRED.every((field) => strPresent(vehicleForm[field]?.toString()));
    return validVehicle;
  }),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["query", "scope"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchVehicles: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope"]);

    actions.setLoading(true);

    return fetchVehicles({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.vehicles = data.vehicles;
    state.collection = data.vehicles;
    state.total = parseInt(data.total);
  }),

  addVehicle: action((state, vehicle) => {
    let vehiclesList = state.vehicles;
    vehiclesList.unshift(vehicle);
    state.vehicles = vehiclesList;
  }),

  removeVehicle: action((state, archivedId) => {
    const newStopsList = state.vehicles.filter((sl) => sl.id !== archivedId);
    state.vehicles = newStopsList;
  }),

  updateTrackingDevices: thunk(async (actions, vendorId, _h) => {
    const resp = await fetchTrackingDevicesList({ vendorId: vendorId });
    actions.setTrackingDevices(resp.trackingDevices);
  }),

  save: thunk(async (actions, _payload, h) => {
    const state = h.getState();
    if (!state.isValid) return Promise.reject();

    actions.setErrors({});
    try {
      const formData = { vehicle: state.vehicleForm };
      if (state.vehicleForm.id) {
        return await saveVehicle(state.vehicleForm.id, formData);
      } else {
        return await createVehicle(formData);
      }
    } catch (err) {
      const errors = err.response?.data?.errors;
      actions.setErrors(camelizeKeys(errors));
      throw err;
    }
  }),

  fetchVehicle: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchVehicle(id)
      .then((resp) => {
        actions.setVehicle(resp.vehicle);
        actions.setVehicleForm({ ...pick(resp.vehicle, FORM_ATTRS) });
      })
      .finally(() => actions.setLoading(false));
  }),

  toggleArchive: thunk((actions, id, h) => {
    const vehicle = h.getState().vehicle;
    actions.setLoading(true);

    archiveVehicle(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (vehicle) {
          actions.setVehicle(resp.vehicle);
        } else {
          actions.removeVehicle(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
