import React from "react";
import cn from "classnames";
import { snakeCase } from "lodash";
import { Paper, Box, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import I18n from "utils/i18n.js";
import BooleanText from "components/admin_v2/common/BooleanText";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import { formatPhone } from "lib/util";

const UserAssociations = ({ user }) => {
  const cls = useCommonPageStyles();
  const clsTableForm = useTableFormStyles();

  if (!user) return;

  const associations = {
    schools: [
      { column: "Name", value: (s) => s.name },
      { column: "District", value: (s) => s.districtName }
    ],
    phoneNumbers: [
      { column: "Name", value: (p) => p.name },
      { column: "Number", value: (p) => formatPhone(p.number) },
      { column: "Line Type", value: (p) => p.lineType },
      { column: "Opt-Out communication", value: (p) => <BooleanText val={p.optOutCommunication} /> }
    ],
    communicationGroups: [
      { column: "Name", value: (cg) => cg.name },
      { column: "Twilio Number", value: (cg) => formatPhone(cg.twilioNumber) },
      { column: "Contacts", value: (cg) => cg.contactsCount }
    ]
  };

  return Object.entries(associations).map(([itemsName, columns]) => (
    <Box my={5} key={itemsName}>
      <Paper className={cls.paper}>
        <Box className={cls.subTitle}>
          {I18n.t(`settings_config.user.title.${snakeCase(itemsName)}`)}
        </Box>
        <Table aria-label="table" className={cn(`user-${itemsName}`, cls.table)}>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  className={clsTableForm.tableHeader}
                  key={`phone-numbers-header-${col.column}`}
                >
                  {col.column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {user[itemsName]?.map((item) => (
              <TableRow hover tabIndex={-1} key={`${itemsName}-row-${item.id}`}>
                {columns.map((col) => (
                  <TableCell className={clsTableForm.cell} key={`${itemsName}-cell-${col.column}`}>
                    {col.value(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  ));
};
export default UserAssociations;
