import { action } from "easy-peasy";
import easyState from "lib/easyState";

export const refreshModel = () => ({
  ...easyState("refresh", false),

  toggleRefresh: action((state) => {
    if (!state.page || state.page === 1) state.refresh = !state.refresh;
  })
});
