import React, { Fragment } from "react";
import { Divider, FormControlLabel } from "@mui/material";
import { map, values } from "lodash";
import RouteNewStopForm from "./RouteNewStopForm";
import RouteNewReturnForm from "./RouteNewReturnForm";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import I18n from "utils/i18n.js";
import { STOP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";

const RouteNewDirectionsForm = ({ cls, store }) => {
  const [state, actions] = store;

  const stops = map(
    isOtherRoute(state.routeType) ? values(STOP_TYPES) : [STOP_TYPES.pickup],
    (stopType) => (
      <RouteNewStopForm
        key={`r-direction-${stopType}`}
        stopType={stopType}
        store={store}
        tripType="direct"
        cls={cls}
      />
    )
  );

  return (
    <Fragment>
      {stops}
      <Divider className={cls.divider} />
      <FormControlLabel
        control={
          <GreenCheckbox
            checked={state.returnTrip}
            onChange={(e) => actions.setReturnTrip(e.target.checked)}
          />
        }
        label={I18n.t("ui.route.return")}
      />
      {state.returnTrip && (
        <RouteNewReturnForm
          cls={cls}
          tripType="return"
          store={store}
          stopTypes={isOtherRoute(state.routeType) ? values(STOP_TYPES) : [STOP_TYPES.dropoff]}
        />
      )}
    </Fragment>
  );
};

export default RouteNewDirectionsForm;
