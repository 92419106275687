import React, { useEffect } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreActions, useStoreState } from "easy-peasy";
import useCommonPageStyles from "../common/useCommonPageStyles";

const DistrictSelector = ({ disabled = false }) => {
  const { district, districts } = useStoreState((s) => s.app);
  const { changeDistrict, updateDistricts } = useStoreActions((s) => s.app);
  const cls = useStyles();
  const cCls = useCommonPageStyles();

  useEffect(() => {
    updateDistricts();
  }, []);

  const handleChange = (e) => {
    changeDistrict(e.target.value);
  };

  return (
    <FormControl variant="outlined" className={cls.selector}>
      <Select
        value={districts.length < 1 ? "" : district?.id || ""}
        onChange={handleChange}
        displayEmpty
        classes={{ select: cls.selector }}
        disabled={disabled}
      >
        <MenuItem value="">
          <Typography variant="body2">All Districts</Typography>
        </MenuItem>
        {districts?.map(({ id, name, code }) => (
          <MenuItem key={`district-sel-${id}`} value={id}>
            <span className={cCls.schoolCode}>{code}</span> {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  selector: {
    marginRight: theme.spacing(2),
    width: theme.spacing(40),
    backgroundColor: theme.custom.WHITE,
    borderRadius: theme.spacing(0.5)
  }
}));

export default DistrictSelector;
