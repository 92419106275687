import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchStudentImports, submitStudentImport } from "services/apiStudentsImports";
import { setFlashMessage } from "services";
import { toFormData } from "lib/util";
import { refreshModel } from "./refreshModel";

const IMPORT_FORM = {
  importableId: null,
  files: null
};

export const defaultState = {
  schoolId: null,
  status: null,
  // status
  addImportDialogOpen: false,
  loading: false,
  submitDisabled: false,
  // data
  imports: [],
  newImport: { ...IMPORT_FORM },
  errors: {}
};

export const studentImportsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...refreshModel(),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["schoolId", "status"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchImports: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["page", "perPage", "status"]);

    actions.setLoading(true);

    return fetchStudentImports({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => {
        actions.setLoading(false);
      });
  }),

  setData: action((state, data) => {
    state.imports = data.imports;
    state.total = parseInt(data.total);
  }),

  addImport: action((state, newImport) => {
    let importsList = state.imports;
    importsList.unshift(newImport);
    state.imports = importsList;
  }),

  importChange: action((state, updatedImport) => {
    state.imports = state.imports.map((i) => {
      return i.id === updatedImport.id ? updatedImport : i;
    });
  }),

  submit: thunk((actions, _payload, h) => {
    actions.setSubmitDisabled(true);
    actions.setErrors({});
    const state = h.getState();

    submitStudentImport(toFormData(state.newImport))
      .then((resp) => {
        setFlashMessage(resp.message);
        actions.addImport(resp.import);
        actions.setAddImportDialogOpen(false);
        actions.setNewImport({ ...IMPORT_FORM });
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;
        actions.setErrors(errors);
      })
      .finally(() => actions.setSubmitDisabled(false));
  })
});
