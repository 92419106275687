import makeStyles from "@mui/styles/makeStyles";

const useCommonPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  mt: {
    marginTop: theme.spacing(4)
  },
  ml: {
    marginLeft: theme.spacing(1)
  },
  withTopMargin: {
    marginTop: theme.spacing(2)
  },
  withBottomMargin: {
    marginBottom: theme.spacing(2)
  },
  secondRowMt: {
    marginTop: theme.spacing(5)
  },
  disabled: {
    opacity: "0.5"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  grid: {
    flexGrow: 1
  },
  gridCenter: {
    justifyContent: "center"
  },
  filters: {
    display: "flex",
    marginLeft: theme.spacing(2)
  },
  filter: {
    marginRight: theme.spacing(4),
    color: theme.custom.DARK_GREY_2
  },
  dateRangeFilters: {
    display: "flex",
    alignItems: "center"
  },
  rangeLabel: {
    display: "flex",
    margin: theme.spacing(0, 1)
  },
  scopeBtns: {
    padding: theme.spacing(0)
  },
  field: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rightWarning: {
    display: "inline-block",
    color: theme.custom.YELLOW
  },
  halfField: {
    width: "50%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  searchField: {
    width: "25%",
    backgroundColor: theme.custom.WHITE
  },
  required: {
    color: theme.custom.RED
  },
  btnsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    alignItems: "center"
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  actionBtn: {
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    "&:hover": {
      textDecoration: "none"
    },
    "&.active": {
      backgroundColor: theme.custom.DARK_GREY_2,
      color: theme.custom.WHITE
    }
  },
  sessionsLink: {
    color: theme.custom.DARK_GREY_1,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none"
    }
  },
  fullSelect: {
    width: "100%"
  },
  days: {
    display: "flex",
    justifyContent: "space-between"
  },
  formSection: {
    marginBottom: theme.spacing(5)
  },
  formSectionMb: {
    marginBottom: theme.spacing(2)
  },
  dialog: {
    "& .MuiPaper-root": {
      width: "100%"
    }
  },
  dialogCont: {
    backgroundColor: `${theme.custom.WHITE} !important`
  },
  dialogContWoXPaddding: {
    padding: 0,
    paddingBottom: theme.spacing(2)
  },
  label: {
    color: theme.palette.text.primary,
    display: "block",
    fontWeight: theme.custom.BOLD,
    fontSize: "0.9rem",
    marginBottom: theme.spacing(1),
    "&:focus, &:active, &.Mui-focused": {
      color: theme.palette.text.primary
    }
  },
  select: {
    width: "100%"
  },
  halfSelect: {
    width: "40%"
  },
  twoThirdsSelect: {
    marginLeft: theme.spacing(4),
    width: "60%"
  },
  detailsBold: {
    fontWeight: theme.custom.SEMI_BOLD
  },
  statusBox: {
    textAlign: "center",
    fontWeight: "bold",
    padding: theme.spacing(0.5),
    color: theme.custom.WHITE,
    borderRadius: theme.spacing(0.5)
  },
  success: {
    backgroundColor: theme.custom.GREEN
  },
  failed: {
    backgroundColor: theme.custom.DARK_GREY_2
  },
  "message-failed": {
    backgroundColor: theme.custom.RED
  },
  "message-pending": {
    backgroundColor: theme.custom.YELLOW
  },
  "message-sent": {
    backgroundColor: theme.custom.GREEN
  },
  pending: {
    backgroundColor: theme.custom.YELLOW
  },
  canceled: {
    backgroundColor: theme.custom.DARK_GREY_2
  },
  "in-progress": {
    backgroundColor: theme.custom.YELLOW
  },
  conflicts: {
    backgroundColor: theme.custom.YELLOW
  },
  successText: {
    color: theme.custom.GREEN
  },
  failedText: {
    color: theme.custom.DARK_GREY_2
  },
  "in-progressText": {
    color: theme.custom.YELLOW
  },
  conflictsText: {
    color: theme.custom.YELLOW
  },
  subTitle: {
    fontSize: "1rem",
    fontWeight: theme.custom.BOLD,
    marginBottom: theme.spacing(2)
  },
  collapsibleHeader: {
    "& a": {
      fontSize: "1rem",
      fontWeight: theme.custom.BOLD,
      marginBottom: theme.spacing(2),
      color: theme.custom.BLUE,
      cursor: "pointer",
      verticalAlign: "middle"
    },

    "& .caret": {
      fontSize: theme.spacing(4),
      verticalAlign: "middle",
      position: "relative",
      top: "-1px"
    },

    "& aside": {
      display: "inline",
      color: theme.custom.MEDIUM_DARK_GREY,
      fontSize: "0.8rem",
      marginLeft: theme.spacing(1)
    }
  },
  schoolControl: {
    width: "30%"
  },
  detailsFlex: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  detail: {
    fontSize: "1rem",
    paddingTop: theme.spacing(3),
    borderBottom: `solid 1px ${theme.custom.MEDIUM_GREY}`
  },
  detailHeader: {
    color: theme.custom.MEDIUM_DARK_GREY,
    fontSize: "0.8rem"
  },
  halfWidth: {
    width: "40%"
  },
  infoBox: {
    backgroundColor: theme.palette.background.lightGrey
  },
  tableLink: {
    marginRight: theme.spacing(3),
    textDecoration: "underline",
    cursor: "pointer"
  },
  textField: {
    height: theme.spacing(5),
    backgroundColor: theme.custom.WHITE
  },
  formDivider: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  titleWithBtn: {
    marginBottom: theme.spacing(2),
    display: "flex",
    "justify-content": "space-between"
  },
  signInBtn: {
    color: theme.custom.WHITE,
    backgroundColor: theme.custom.DARK_GREY_2,
    "&:hover": {
      backgroundColor: theme.custom.DARK_GREY_2
    }
  },
  cancelLink: {
    color: theme.custom.BLUE,
    textDecoration: "none",
    marginLeft: theme.spacing(3),
    position: "relative",
    bottom: "-2px",

    "&:visited": {
      color: theme.custom.BLUE
    }
  },
  itemsCount: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  smallLabel: {
    fontSize: "0.8rem"
  },
  warningLabel: {
    backgroundColor: theme.custom.YELLOW,
    fontWeight: theme.custom.BOLD,
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    borderRadius: theme.spacing(0.5)
  },
  icon: {
    verticalAlign: "text-bottom"
  },
  warningLabelIcon: {
    marginRight: theme.spacing(1)
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    color: theme.custom.BLUE
  },
  bookingActions: {
    display: "flex",
    alignItems: "center"
  },
  actionIcon: {
    marginLeft: theme.spacing(2),
    color: theme.custom.DARK_GREY_2,
    cursor: "pointer"
  },
  centerWarningLabel: {
    margin: `${theme.spacing(6)} auto`,
    width: "55%",
    color: theme.custom.DARK_YELLOW,
    fontWeight: theme.custom.BOLD
  },
  crossedOut: {
    textDecoration: "line-through"
  },
  greyLabel: {
    fontSize: "0.9rem",
    fontStyle: "italic"
  },
  schoolCode: {
    backgroundColor: theme.custom.LIGHT_GREY,
    fontSize: "0.75rem",
    padding: theme.spacing(0.25, 1),
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1.5)
  },
  hint: {
    display: "flex",
    alignItems: "center",
    color: theme.custom.DARK_GREY_2,
    fontSize: "0.8rem",
    fontStyle: "italic"
  },
  radioNegativeMargin: {
    marginTop: theme.spacing(-1)
  },
  radioNegativeMargin2: {
    marginTop: theme.spacing(-2)
  },
  calendarIcon: {
    marginRight: theme.spacing(1),
    color: theme.custom.DARK_RED
  },
  alarmIcon: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(1),
    color: theme.custom.PURPLE
  }
}));

export default useCommonPageStyles;
