import React, { useMemo } from "react";
import { Box, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreActions, useStoreState } from "easy-peasy";
import StopLabel, { getStop, getEventType } from "../common/StopLabel";
import ModeChangeTrip from "./ModeChangeTrip";

const DayTrip = ({ day, trip, tripType, index }) => {
  const cls = useStyles();
  const stop = getStop(trip);

  const { student } = useStoreState((s) => s.calendar);
  const { setModeDialogOpen, setModeDialogDate, resetChangeTripData } = useStoreActions(
    (s) => s.calendar
  );
  const eventType = useMemo(() => getEventType(trip, stop), [trip, stop]);

  const onClickStopAddress = (e) => {
    e.preventDefault();
    if (stop?.route_discarded) return;
    setModeDialogDate(day);
    resetChangeTripData();
    setModeDialogOpen(true);
  };

  const address = stop || student.addresses.find((a) => a.primary) || student.addresses[0];

  return (
    <ModeChangeTrip
      day={day}
      trip={{
        change_request: trip.change_request?.id,
        with_activity: trip.with_activity,
        route: { start_time: stop?.route?.start_time },
        id: stop?.id,
        events: { ...(stop?.events || {}), change_request: trip.change_request },
        stop
      }}
      tripType={tripType}
      index={index}
      eventType={eventType}
      addEvent={trip.student_add || stop?.is_student_add}
    >
      <StopLabel day={day} stop={stop} eventType={eventType} withRouteLink={true} />
      <Box mb={1}>
        <Typography className={`${cls.address} ${eventType}`} variant="body2">
          <Link href="#modeChange" onClick={onClickStopAddress} underline="hover">
            {address?.address || ""}
          </Link>
        </Typography>
      </Box>
    </ModeChangeTrip>
  );
};

const useStyles = makeStyles(() => ({
  address: {
    height: "3.6rem",
    lineHeight: "1.2rem",
    "&.cancel": { textDecoration: "line-through" },
    "&.activity": { textDecoration: "line-through" },
    "&.no_show": { textDecoration: "line-through" }
  }
}));

export default DayTrip;
