import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { filter, orderBy } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import I18n from "utils/i18n.js";

const ALL = I18n.t(`report.form.label.route_options.all`);

const MultiRoutesSelect = ({ routes, routeIds, onChange, loading, rest, id, disabled = false }) => {
  const groupBy = (option) => {
    return option.routable_name ? option.routable_name : option;
  };

  const renderOption = (props, option, input) => {
    return (
      <FormControlLabel
        key={`routes-select-${option?.id}`}
        className="routes-select--option"
        control={<GreenCheckbox checked={input.selected} value={option.id} />}
        label={option.name || ""}
        style={{ marginLeft: "1em" }}
        {...props}
      />
    );
  };

  const selectGroup = (groupName, checked) => {
    const selectedRoutes = routes.filter((r) => {
      const inGroup = groupName == ALL ? true : r.routable_name === groupName;
      const alreadySelected = routeIds.indexOf(r.id) > -1;
      return checked ? !inGroup && alreadySelected : inGroup || alreadySelected;
    });
    onChange({}, selectedRoutes);
  };

  const renderGroup = (params) => {
    const grouped =
      params.group == ALL
        ? routes
        : routes.filter((r) => {
            return r.routable_name === params.group;
          });
    const checked = grouped.length > 0 && grouped.every((r) => routeIds.indexOf(r.id) > -1);

    return (
      <Box key={params.key}>
        <FormControlLabel
          key={`routes-select-group--${params.key}`}
          control={
            <GreenCheckbox
              checked={checked}
              value={params.group}
              onChange={() => selectGroup(params.group, checked)}
            />
          }
          label={params.group || ""}
          style={{ marginLeft: "0.5em" }}
        />
        {!(params.group == ALL) && params.children}
      </Box>
    );
  };

  let options = [];
  if (routes.length > 0) {
    options.push(ALL);
  }

  options = options.concat(orderBy(routes, ["routable_type", "name"], ["desc", "asc"]));

  return (
    <Autocomplete
      id={id}
      multiple={true}
      options={options}
      groupBy={groupBy}
      getOptionLabel={(option) => option.name || ""}
      renderOption={renderOption}
      renderGroup={renderGroup}
      disableCloseOnSelect={true}
      onChange={onChange}
      size="small"
      loading={loading}
      value={filter(routes, (r) => routeIds.indexOf(r.id) > -1)}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder={routeIds.length === 0 ? "Select Routes" : ""}
        />
      )}
      {...rest}
    />
  );
};

export default MultiRoutesSelect;
