import React, { Fragment } from "react";
import FormSelect from "components/admin_v2/ui/FormSelect";
import Spinner from "components/admin_v2/ui/Spinner";
import TransportationTypes from "./TransportationTypes";
import useOptions from "./useOptions";
import useStyles from "./useStyles";

const DEFAULT_OPTIONS = {
  transportationTypes: [],
  transportationVendors: [],
  programs: []
};

const AdmReportForm = ({ reportStore }) => {
  const cls = useStyles();
  const [state, actions] = reportStore;
  const form = state.admForm;
  const { options, loading } = useOptions(state.reportType, DEFAULT_OPTIONS);

  if (loading) return <Spinner />;

  const updateForm = (data) => {
    actions.setAdmForm({ ...form, ...data });
  };

  const handleChange = (field) => {
    return (e) => {
      const val = field === "transportationVendorIds" ? [e.target.value] : e.target.value;
      updateForm({ [field]: val });
    };
  };

  return (
    <Fragment>
      <TransportationTypes
        form={form}
        errors={state.reportErrors}
        options={options}
        updateForm={updateForm}
      />
      <FormSelect
        itemType="vendor"
        options={options.transportationVendors}
        value={form.transportationVendorIds[0]}
        onChange={handleChange("transportationVendorIds")}
        cls={cls}
      />
      <FormSelect
        itemType="program"
        options={options.programs}
        value={form.programId}
        onChange={handleChange("programId")}
        cls={cls}
      />
    </Fragment>
  );
};

export default AdmReportForm;
