import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { isAfter, isBefore } from "date-fns";
import { capitalize } from "lodash";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import FormDatePicker from "components/admin_v2/ui/FormDatePicker";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import { exportsStore } from "./stores/exportsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const Exports = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => exportsStore(camelizeLocationSearch(props)));
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchExports({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [state.page, state.perPage, sortBy]);

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const headers = [
    { field: "id", label: "ID" },
    { field: "export_type", label: "Type" },
    { field: "effective_dates", label: "Effective Dates", skipSorting: true },
    { field: "created_at", label: "Initiated at" },
    { field: "status", label: "Status" },
    { field: "upload_status", label: "Upload" },
    { field: "file_path", label: "File", skipSorting: true }
  ].map(sortableColumn);

  const row = (exp) => (
    <TableRow key={`export-${exp.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        {exp.id}
      </TableCell>
      <TableCell>{exp.exportType}</TableCell>
      <TableCell>{exp.effectiveDates}</TableCell>
      <TableCell>{exp.createdAt}</TableCell>
      <TableCell style={{ width: "15%" }}>
        <Box className={`${cls[exp.status]} ${cls.statusBox}`}>{capitalize(exp.status)}</Box>
      </TableCell>
      <TableCell style={{ width: "15%" }}>
        <Box className={`${cls[exp.uploadStatus]} ${cls.statusBox}`}>
          {capitalize(exp.uploadStatus)}
        </Box>
      </TableCell>
      <TableCell>
        <Link href={exp.filePath} target="_blank" underline="always">
          {I18n.t("data_management.exports.file_link")}
        </Link>
      </TableCell>
    </TableRow>
  );

  const onEffectiveFromChange = (date) => {
    actions.setEffectiveFrom(date);
    if (isBefore(date, state.effectiveTo)) return;

    actions.setEffectiveTo(date);
  };

  const onEffectiveToChange = (date) => {
    actions.setEffectiveTo(date);
    if (isAfter(date, state.effectiveFrom)) return;

    actions.setEffectiveFrom(date);
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <Box className={cls.btnsWrapper}>
        <Box display="flex" className={cls.dateRangeFilters}>
          <FormDatePicker date={state.effectiveFrom} onChange={onEffectiveFromChange} />
          <Typography variant="subtitle1" className={cls.rangeLabel}>
            {I18n.t("ui.export.date_range_to")}
          </Typography>
          <FormDatePicker date={state.effectiveTo} onChange={onEffectiveToChange} />
        </Box>
        <PrimaryBtn color="primary" className={cls.actionBtn} onClick={actions.runExport}>
          {I18n.t("data_management.exports.run_export")}
        </PrimaryBtn>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}></Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.exports.map(row)}
          </PaginatedTable>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default Exports;
