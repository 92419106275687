import React from "react";
import { useStoreState } from "easy-peasy";
import NavTabs from "components/admin_v2/common/NavTabs";
import { pageRoutes, slugsFor } from "services/api";

const RouteTabs = ({ route, location }) => {
  const { isUserAdmin } = useStoreState((s) => s.app);
  if (!route) return null;

  let tabs = [{ label: "View Route", path: pageRoutes.route(route.id, slugsFor(route)) }];

  if (isUserAdmin && !route.non_riding_type) {
    tabs.push({
      label: "Edit Stops",
      path: pageRoutes.routeStops(route.id, slugsFor(route)),
      disabled: route.discarded
    });
  }

  if (isUserAdmin) {
    tabs.push({
      label: "Edit Route Details",
      path: pageRoutes.routeEdit(route.id, slugsFor(route)),
      disabled: route.discarded
    });
  }

  return <NavTabs tabs={tabs} location={location} />;
};

export default RouteTabs;
