import React, { Fragment, useEffect } from "react";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { addDays } from "date-fns";
import { useLocalStore, useStoreState } from "easy-peasy";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";
import useMessageBus from "lib/useMessageBus";
import ButtonLink from "components/admin_v2/ui/ButtonLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import CurbsideReportForm from "components/admin_v2/reports/forms/CurbsideReportForm";
import AdmReportForm from "components/admin_v2/reports/forms/AdmReportForm";
import AveryLabelsReportForm from "components/admin_v2/reports/forms/AveryLabelsReportForm";
import AllTripsReportForm from "components/admin_v2/reports/forms/AllTripsReportForm";
import RouteSheetsReportForm from "components/admin_v2/reports/forms/RouteSheetsReportForm";
import CreateReportHeader from "components/admin_v2/reports/CreateReportHeader";
import CreateReportButtons from "components/admin_v2/reports/CreateReportButtons";
import Spinner from "components/admin_v2/ui/Spinner";
import { reportStore } from "components/admin_v2/reports/stores/createReportStore";
import { ROUTE_SHEETS_ADD_DAYS } from "utils/constants";

const REPORT_COMPONENTS = {
  curbside: { component: CurbsideReportForm, forAll: true },
  district_curbside: { component: CurbsideReportForm, forAll: true },
  adm: { component: AdmReportForm, forAll: false },
  avery: { component: AveryLabelsReportForm, forAll: true },
  all_trips: { component: AllTripsReportForm, forAll: true },
  route_sheets: { component: RouteSheetsReportForm, forAll: true }
};

const CreateReport = () => {
  const cls = useStyles();
  const { school, user, isUserAdmin } = useStoreState((s) => s.app);
  const store = useLocalStore(() =>
    reportStore({
      date: new Date(),
      dateTo: addDays(new Date(), ROUTE_SHEETS_ADD_DAYS),
      schoolIds: school ? [school.id] : []
    })
  );
  const [state, actions] = store;

  useEffect(() => {
    actions.loadUnseen();
  }, [state.schoolIds]);

  useMessageBus({
    channel: `reports-${user?.id}`,
    condition: user?.id,
    received: actions.reportReceived
  });

  const actionButtons = [
    <ButtonLink
      variant="outlined"
      color="primary"
      key="btn-view-report"
      to={pageRoutes.reportFiles()}
    >
      {I18n.t("report.form.btn.view")}
    </ButtonLink>,
    <Badge badgeContent={state.unseen} color="error" key="badge-unseen" className={cls.badge} />
  ];

  const reportData = REPORT_COMPONENTS[state.reportType] || null;
  const ReportComponent = reportData ? reportData.component : null;

  return (
    <Fragment>
      <TopPageLayout title="Reports" actionButtons={actionButtons} />
      <Box my={3}>
        <Paper className={cls.paper}>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              <Box mb={2}>
                <CreateReportHeader reportStore={store} />
              </Box>
              {reportData && (reportData.forAll || isUserAdmin) && (
                <ReportComponent reportStore={store} />
              )}
              <CreateReportButtons reportStore={store} />
            </>
          )}
        </Paper>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {
    position: "relative",
    right: theme.spacing(-2),
    fontSize: "1.2rem"
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

export default CreateReport;
