import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const searchDismissalGroups = async (params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`dismissal_groups/search?${query}`));
};

export const fetchDismissalGroups = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`dismissal_groups?${query}`), { transformData: true });
};

export const fetchDismissalGroup = async (id) => {
  return await xhr.get(apiUrl(`dismissal_groups/${id}`), { transformData: true });
};

export const createDismissalGroup = async (params) => {
  const url = apiUrl(`dismissal_groups`);
  return await xhr.post(url, params, { transformData: true });
};

export const saveDismissalGroup = async (id, params) => {
  const url = apiUrl(`dismissal_groups/${id}`);
  return await xhr.post(url, params, { transformData: true });
};

export const archiveDismissalGroup = async (id) => {
  const url = apiUrl(`dismissal_groups/${id}/archive`);
  return await xhr.post(url, {}, { transformData: true });
};

export const batchArchive = async (ids) => {
  const url = apiUrl(`dismissal_groups/batch_archive`);
  return await xhr.post(url, { group_ids: ids });
};

export const batchUnarchive = async (ids) => {
  const url = apiUrl(`dismissal_groups/batch_unarchive`);
  return await xhr.post(url, { group_ids: ids });
};
