import React, { Fragment } from "react";
import { isString } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "./StyledTableCell";

/*
  Usage:
    <PaginatedTable
      headers={["ID", "Name"]}
      size="small"
      count={10}
      perPage={25}
      page={1}
      onPageChange={(newPage) => { ... }}
      onRowsPerPageChange={(newPerPage) => { ... }}
    >
      {people.map((person, idx) => {
        return (
          <TableRow key={`person-${idx}`}>
            <TableCell component="th">
              {person.id}
            </TableCell>
            <TableCell align="right">{person.name}</TableCell>
          </TableRow>
        );
      })}
    </PaginatedTable>
*/
const PaginatedTable = ({
  size,
  page,
  perPage,
  onPageChange,
  onChangePerPage,
  count,
  rowsPerPageOptions,
  children,
  fixed,
  headers = [],
  pagination = true,
  tableCls = null,
  headerClass = null,
  headerLinkCls = null
}) => {
  const classes = useStyles();
  page = page || 1;
  perPage = perPage || 25;
  rowsPerPageOptions = rowsPerPageOptions || [25, 50];
  count = count || 0;
  const tableClass = fixed ? classes.largeTable : tableCls || "";

  const tableHeaders = headers.map((header) => {
    return isString(header)
      ? { label: header, key: header, align: "left" }
      : { label: header.label, key: header.key, align: header.align };
  });

  return (
    <Fragment>
      <Table aria-label="simple table" size={size} className={tableClass}>
        <TableHead className={headerClass}>
          <TableRow>
            {tableHeaders.map((header) => (
              <StyledTableCell key={header.key} align={header.align} className={headerLinkCls}>
                {header.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      {pagination && (headers.length > 0 || children.length > 0) && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangePerPage}
        />
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  largeTable: {
    tableLayout: "fixed",
    "& .MuiTableCell-root": {
      color: theme.custom.DARK_GREY_2
    }
  }
}));

export default PaginatedTable;
