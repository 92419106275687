import makeStyles from "@mui/styles/makeStyles";

export const useAppbarStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: (props) => {
      if (props.vendors) {
        return theme.custom.DARK_GREEN;
      } else if (props.parents) {
        return theme.custom.DARK_GREY_2;
      } else {
        return theme.palette.primary.main;
      }
    },
    color: theme.palette.primary.contrastText,
    height: theme.spacing(10.3),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2.2),
    paddingBottom: theme.spacing(2.2),
    "&.admin": {
      backgroundColor: theme.custom.DARK_GREY_2
    }
  },
  toolbarReduced: {
    height: theme.spacing(5),
    minHeight: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between"
  },
  toolbarContainer: {
    display: "flex",
    flex: "1",
    maxWidth: theme.custom.CONTAINER_WIDTH,
    margin: "0 auto"
  },
  toolbarContainerReduced: {
    display: "flex",
    maxWidth: theme.custom.CONTAINER_WIDTH,
    marginRight: "0"
  },
  parentsToolbar: {
    justifyContent: "space-between"
  },
  toolbarTitleReduced: {
    maxWidth: theme.spacing(20)
  },
  toolbarTitle: {
    flex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  signOut: {
    color: theme.palette.primary.contrastText,
    fontSize: "0.87rem",
    fontWeight: "bold"
  },
  contrastLink: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  },
  incidentReport: {
    color: theme.palette.primary.contrastText,
    fontSize: "0.87rem",
    fontWeight: "bold",
    marginRight: theme.spacing(4)
  },
  toolbarDivider: {
    margin: (0, theme.spacing(4)),
    width: theme.spacing(0.1),
    height: "100%",
    opacity: "25%",
    backgroundColor: theme.custom.WHITE
  },
  selectors: {
    position: "relative",
    top: theme.spacing(1.5),
    flex: 1
  },
  logo: {
    width: theme.spacing(9.4)
  },
  logoReduced: {
    marginLeft: 0,
    width: theme.spacing(5)
  },
  dividerReduced: {
    marginTop: theme.spacing(1.5),
    minHeight: theme.spacing(1.5),
    borderColor: theme.custom.WHITE,
    borderWidth: theme.spacing(0.1)
  }
}));
