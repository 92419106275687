import React, { Fragment } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import DatePickerInput from "components/admin_v2/ui/DatePickerInput";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import RouteDirectionBtns from "components/admin_v2/common/RouteDirectionBtns";
import RouteSelector from "./RouteSelector";
import EditStopsTable from "./EditStopsTable";
import RouteTabs from "./RouteTabs";
import RouteStopsHint from "./RouteStopsHint";
import { useRouteDateUpdate } from "./common/hooks";
import { RouteTitle, RouteSubtitle } from "./common/RouteTitles";
import useStyles from "./useStyles";

const RouteStops = (props) => {
  const cls = useStyles();
  const { isUserAdmin } = useStoreState((s) => s.app);
  const { route, date, direction, linkDays } = useStoreState((s) => s.routeEditor);
  const { setLinkDays } = useStoreActions((s) => s.routeEditor);
  const [changeRoute, selectDate, selectDirection] = useRouteDateUpdate(props);

  const toggleLinkDays = () => {
    setLinkDays(!linkDays);
  };

  const linkDaysLink = (
    <Link onClick={toggleLinkDays} component="button" underline="always" id="link-days">
      {linkDays ? "Unlink Weekdays" : "Link Weekdays"}
    </Link>
  );

  if (!isUserAdmin || route?.non_riding_type) return null;

  return (
    <Fragment>
      <TopPageLayout
        title={<RouteTitle route={route} />}
        subtitle={<RouteSubtitle />}
        filters={<RouteSelector route={route} onChange={changeRoute} />}
      />
      <RouteTabs route={route} location={location} />
      <Paper className={cls.root}>
        <Box className={cls.routeDetailsTop}>
          <RouteDirectionBtns direction={direction} onSelect={selectDirection} flex="inherit" />
          <Box display="flex" alignItems="center">
            {linkDaysLink}
            {linkDays && <RouteStopsHint />}
          </Box>
          <Box className={cls.datePicker}>
            <DatePickerInput selectedDate={date} onChange={selectDate} />
          </Box>
        </Box>
        {route ? (
          <EditStopsTable onColumnSelect={selectDate} />
        ) : (
          <Fragment>
            <Typography variant="body2">Route not found</Typography>
          </Fragment>
        )}
      </Paper>
    </Fragment>
  );
};

export default RouteStops;
