import React, { Fragment, useEffect, useCallback } from "react";
import { useLocalStore } from "easy-peasy";
import { get } from "lodash";
import PeriodSelector from "components/admin_v2/common/PeriodSelector";
import ErrorList from "components/admin_v2/ui/ErrorList";
import { removeEditorStore } from "../stores/removeEditorStore";

const RemoveEditor = ({ modeChangeStore, idx }) => {
  const [modeChangeState, modeChangeActions, store] = modeChangeStore;
  const trip = modeChangeState.getTrip(idx);
  const [state, actions] = useLocalStore(() =>
    removeEditorStore({ date: modeChangeState.currentDate })
  );

  const onSubmit = useCallback((_params) => {
    return actions
      .submit({
        trip,
        student: store.getState().student,
        currentDate: store.getState().currentDate
      })
      .catch((err) => {
        const error = get(err.response, "data.message");
        actions.setErrors({ "": error });
        throw err;
      });
  }, []);

  useEffect(() => {
    modeChangeActions.updateTripDetails({ idx, action: onSubmit });
  }, []);

  const onUpdatePeriod = (period) => actions.updateState(period);

  return (
    <Fragment>
      <ErrorList errors={state.errors} />
      <PeriodSelector
        student={modeChangeState.student}
        stopToAdd={trip.stop}
        period={state.period}
        onPeriodChange={onUpdatePeriod}
        changeRequest={true}
        route={{ routeId: trip.route?.id, notOccursOn: trip.route?.not_occurs_on }}
      />
    </Fragment>
  );
};

export default RemoveEditor;
