import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { startCase } from "lodash";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import useStyles from "./useStyles";

const CheckboxList = ({ name, label, options, values, update, error, className = "" }) => {
  // `options` and `values` can be both a list of { id, name } objects,
  // or a simple array of string

  const cls = useStyles();

  const change = (value) => {
    return (e) => {
      const checked = e.target.checked;
      const opts = [...values];
      const index = opts.indexOf(value);
      if (index === -1 && checked) {
        opts.push(value);
      } else if (index !== -1 && !checked) {
        opts.splice(index, 1);
      }
      update(opts);
    };
  };

  const checked = (value) => {
    return values.indexOf(value) !== -1;
  };

  const toggleAll = (e) => {
    const checked = e.target.checked;
    const opts = checked ? [...options] : [];
    update(opts);
  };

  const allChecked = options?.every((v) => values.indexOf(v) !== -1);
  const optionCls = [cls.inline, className].join(" ");

  return (
    <FormControl id={`${name}-options`} component="fieldset" className={cls.field} error={!!error}>
      <FormLabel>{label}: </FormLabel>
      <FormGroup row={true}>
        <FormControlLabel
          key={`form-${name}--all`}
          control={<GreenCheckbox checked={allChecked} onChange={toggleAll} value="all" />}
          label="All"
          className={optionCls}
        />
        {options.map((option) => {
          const value = option.id || option;
          return (
            <FormControlLabel
              key={`form-${name}--${value}`}
              control={
                <GreenCheckbox checked={checked(option)} onChange={change(option)} value={value} />
              }
              label={startCase(option.name || option)}
              className={optionCls}
            />
          );
        })}
      </FormGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxList;
