import React, { useEffect } from "react";
import { useLocalStore, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import I18n from "utils/i18n.js";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import VehicleForm from "./VehicleForm";
import { vehiclesStore } from "components/admin_v2/vendor_config/stores/vehiclesStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const VehicleEdit = (props) => {
  const id = props.match.params.id;
  const cls = useCommonPageStyles();
  const history = useHistory();
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const store = useLocalStore(() => vehiclesStore());
  const [state, actions] = store;
  const { vehicle } = state;

  useEffect(() => {
    if (!id) return;

    actions.fetchVehicle(id);
  }, [id]);

  const onSubmit = () => {
    actions.save().then((r) => {
      setFlashMessage({ message: r.message });
      history.push(pageRoutes.vehicle(r.vehicle.id));
    });
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      <BreadCrumbs
        path={pageRoutes.vehicles()}
        label={I18n.t("vendor_config.vehicles.breadcrumb")}
      />
      <Box className={cls.titleWithBtn}>
        <Typography variant="h1" gutterBottom>
          {vehicle?.name}
        </Typography>
        <Box className={cls.btnsWrapper}>
          <OutlinedBtn className={`${cls.actionBtn} active`} disabled>
            {I18n.t("vendor_config.edit_vehicle.btn")}
          </OutlinedBtn>
          <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
            {vehicle?.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
          </OutlinedBtn>
        </Box>
      </Box>
      <Paper className={cls.paper}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <VehicleForm store={store} onSubmit={onSubmit} />
            <PrimaryBtn onClick={onSubmit} disabled={!state.isValid} className="form-save">
              {I18n.t("vendor_config.vehicles.form.save")}
            </PrimaryBtn>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default VehicleEdit;
