import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchVehicles = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`vehicles?${query}`), { transformData: true });
};

export const fetchVehicle = async (id) => {
  return await xhr.get(apiUrl(`vehicles/${id}`), { transformData: true });
};

export const createVehicle = async (params) => {
  const url = apiUrl(`vehicles`);
  return await xhr.post(url, params, { transformData: true });
};

export const saveVehicle = async (id, params) => {
  const url = apiUrl(`vehicles/${id}`);
  return await xhr.post(url, params, { transformData: true });
};

export const archiveVehicle = async (id) => {
  const url = apiUrl(`vehicles/${id}/archive`);
  return await xhr.post(url, {}, { transformData: true });
};

export const batchArchive = async (ids) => {
  const url = apiUrl(`vehicles/batch_archive`);
  return await xhr.post(url, { vehicle_ids: ids });
};

export const batchUnarchive = async (ids) => {
  const url = apiUrl(`vehicles/batch_unarchive`);
  return await xhr.post(url, { vehicle_ids: ids });
};
