import React from "react";
import { forIn, unset } from "lodash";

export default function useTesting(config) {
  React.useEffect(() => {
    if (__testing) {
      Object.assign(__testing, config);
    }
    return () => {
      if (__testing) {
        forIn(config, (_, name) => unset(__testing, name));
      }
    };
  }, []);
}
