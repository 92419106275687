import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import _ from "lodash";
import { getMetaValue } from "./util";
import store from "context/admin_v2/store";
import { pageRoutes } from "services/api";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

const redirectIfSignedOff = (e) => {
  if (e.response.status === 401) {
    window.location.href = pageRoutes.signIn();
  }
};

const xhrPost = async function xhrPost(url, data, options = {}) {
  try {
    if (!(data instanceof FormData)) {
      data = decamelizeKeys(data || {});
      if (!data.school_id) {
        data.school_id = store.getState().app.school?.id;
      }
      if (!data.district_id) {
        data.district_id = store.getState().app.district?.id;
      }
      data = _.pickBy(data, _.identity);
      data = JSON.stringify(data);
    }
    const resp = await axios.post(url, data, {
      headers: { "X-CSRF-Token": getMetaValue("csrf-token") }
    });
    if (options.preserveResponse) return resp;
    if (options.transformData) return camelizeKeys(resp.data);
    return resp.data;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    const msg =
      _.get(e, "response.data.message") ||
      _.get(e, "response.data.error") ||
      _.get(e, "response.data.errors") ||
      e.response.statusText;

    e.message = _.isString(msg) ? msg : _.values(msg).join("; ");

    if (!options.withoutRedirect) {
      redirectIfSignedOff(e);
    }

    throw options.transformData ? camelizeKeys(e) : e;
  }
};

const xhrGet = async function xhrGet(url, options = {}) {
  try {
    const resp = await axios.get(url);
    if (options.preserveResponse) return resp;
    if (options.transformData) return camelizeKeys(resp.data);
    return resp.data;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    const msg =
      _.get(e, "response.data.message") || _.get(e, "response.data.error") || e.response.statusText;

    redirectIfSignedOff(e);

    e.message = msg;
    throw e;
  }
};

const xhrDelete = async function xhrDelete(url, _opts = {}) {
  const resp = await axios.delete(url, {
    headers: { "X-CSRF-Token": getMetaValue("csrf-token") }
  });
  return resp.status;
};

export default { post: xhrPost, get: xhrGet, delete: xhrDelete };
