import React, { useEffect, useRef } from "react";
import * as ReactDOMClient from "react-dom/client";
import mapboxgl from "mapbox-gl";
import { useStoreState } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import { filter, without } from "lodash";
import { fetchTripMap, fetchTripDirections } from "services/apiTrips";
import TripPopup from "./TripPopup";
import StopPopup from "./StopPopup";
import WaypointPopup from "./WaypointPopup";
import {
  matchRoute,
  addSchools,
  addSchoolsData,
  addTripsData,
  addRoutes,
  addRouteData,
  showActiveTrip,
  hideInactiveTrips,
  removeOpacity,
  hideProjectedRoutes,
  addStopsData,
  updateStopsData,
  removeSelectedTripData,
  addActualRoute,
  addActualRouteData,
  addRouteWaypoints,
  addRouteWaypointsData,
  changeCursorToPointer,
  changeCursorToDefault
} from "./mapboxApi";
import { flyTo } from "./mapboxApi";

mapboxgl.accessToken = ENV.MAPBOX_KEY;
const MAPSTYLES = ENV.MAPBOX_STYLES || "mapbox://styles/mapbox/streets-v11";
const WAYPOINT_POPUP_OPTS = { offset: 10, closeButton: true, closeOnClick: true };
const DEFAULT_POPUP_OPTS = { offset: 25, closeButton: false, closeOnClick: false };
// preferred center of map on load. currently using Minneapolis central location
const MAP_CENTER_COORDS = [-93.259077, 44.977719];

const getBoundingBox = (coords) => {
  let bounds = {};
  let lat;
  let lng;

  coords.forEach((coord) => {
    lng = parseFloat(coord[0]);
    lat = parseFloat(coord[1]);
    bounds.xMin = bounds.xMin < lng ? bounds.xMin : lng;
    bounds.xMax = bounds.xMax > lng ? bounds.xMax : lng;
    bounds.yMin = bounds.yMin < lat ? bounds.yMin : lat;
    bounds.yMax = bounds.yMax > lat ? bounds.yMax : lat;
  });

  return [
    [bounds.xMin, bounds.yMin],
    [bounds.xMax, bounds.yMax]
  ];
};

const Map = ({ tripsStore }) => {
  const cls = useStyles();
  const { school, district, schools } = useStoreState((s) => s.app);
  const [state, actions] = tripsStore;
  const { trips, activeTrips, selectedTrip, tripInfo, map } = state;
  const mapboxEl = useRef(null);
  const popupNode = useRef(null);
  const popupRoot = useRef(null);
  const popUpRef = useRef(new mapboxgl.Popup());
  const activeTripsIds = activeTrips.map((at) => at.id);
  const inactiveTrips = filter(
    trips,
    (t) => !activeTripsIds.includes(t.id) && !(selectedTrip?.trip?.id === t.id)
  );
  const schoolToData = (school) => ({
    name: school.name,
    address: school.address.address,
    lng: school.address.lng,
    lat: school.address.lat
  });

  const schoolsData = school
    ? [schoolToData(school)]
    : filter(schools, (s) => {
        if (state.timeZone && s.time_zone_abbr !== state.timeZone) {
          return false;
        }
        if (district && s.district_id !== district.id) {
          return false;
        }
        if (trips.length > 0 && !trips.some((t) => t.school_ids.includes(s.id))) {
          return false;
        }

        return s.address?.lng && s.address?.lat && s.address?.address;
      }).map((s) => schoolToData(s));

  // initialize map & sources/layers for displaying on the map:
  useEffect(() => {
    if (map) return;

    // add div for map popups
    popupNode.current = document.createElement("div");
    popupRoot.current = ReactDOMClient.createRoot(popupNode.current);

    // initialize map
    const newMap = new mapboxgl.Map({
      container: mapboxEl.current,
      style: MAPSTYLES,
      zoom: 10,
      center: school ? [school.address.lng, school.address.lat] : MAP_CENTER_COORDS
    });

    // add zoom buttons
    newMap.addControl(new mapboxgl.NavigationControl());

    // add sources and layers for:
    newMap.on("load", () => {
      // Schools source & layers
      addSchools(newMap);
      // Stops layer when trip is selected
      addStopsData(newMap);
      // Trips icon layers for all trips
      addTripsData(newMap, trips);
      // Routes line layers for all trips
      addRoutes(newMap, trips);
      // Selected trip past route line layer
      addActualRoute(newMap);
      // Selected trip past route waypoints icons layer
      addRouteWaypoints(newMap);
      // change cursor when hover over waypoints
      newMap.on("mouseenter", "act-route-waypoints", () => changeCursorToPointer(newMap));
      newMap.on("mouseleave", "act-route-waypoints", () => changeCursorToDefault(newMap));
      actions.setMap(newMap);
    });

    return () => {
      actions.setMap(null);
      newMap.remove();
    };
  }, []);

  useEffect(() => {
    if (!map) return;

    const startedTrips = filter(trips, function (t) {
      return t.last_position;
    });

    // Schools location
    addSchoolsData(map, schoolsData);
    // fit map to make all started trips visible on initial map load
    map.fitBounds(
      getBoundingBox([
        ...startedTrips.map((t) => [t.last_position.lng, t.last_position.lat]),
        ...schoolsData.map((sd) => [sd.lng, sd.lat])
      ]),
      { padding: 60 }
    );
  }, [school, map]);

  useEffect(() => {
    if (!map) return;
    // render all checked trips (highlight marker)
    renderActiveTrips(activeTrips);

    if (selectedTrip) {
      renderActiveTrips([selectedTrip.trip]);
      // render route only for selectedTrip
      if (selectedTrip.trip.ended_at && selectedTrip.positions.length) {
        hideProjectedRoutes(map, [selectedTrip.trip]);
      } else {
        addProjectedRoute(selectedTrip.trip);
      }
      // hide all checked trips routes except the selected one
      hideProjectedRoutes(map, without(activeTrips, selectedTrip.trip));
      // add selected trip stops data on a map
      updateStopsData(map, selectedTrip);
    } else {
      // render routes for activeTrips
      activeTrips.forEach((trip) => addProjectedRoute(trip));
      // remove selected trip stops data from map
      removeSelectedTripData(map);
      // check and set correct schools data if removed trip selection
      addSchoolsData(map, schoolsData);
    }

    // remove marker highlighting/ add opacity for all unchecked trips
    hideInactiveTrips(map, inactiveTrips);

    // remove opacity if all trips are unchecked
    if (activeTrips.length === 0) {
      removeOpacity(map, trips);
    }
  }, [activeTrips, selectedTrip, map]);

  useEffect(() => {
    popUpRef.current.options = DEFAULT_POPUP_OPTS;

    if (!tripInfo) {
      popUpRef.current.remove();
      return;
    }

    popupRoot.current?.render(<TripPopup trip={state.tripInfo} cls={cls} />);
    try {
      // set popup on map
      popUpRef.current
        .setLngLat([tripInfo.last_position.lng, tripInfo.last_position.lat])
        .setDOMContent(popupNode.current)
        .addTo(map);
    } catch (e) {
      console.error("Failed to add point to map", tripInfo?.last_position);
      console.error(e);
    }
  }, [tripInfo]);

  // render popup with next stop info for a trip
  const showNextStopPopup = (e) => {
    const tripId = e.features[0].properties.tripId;

    actions.fetchTripPopupInfo(tripId);
  };

  // remove popup with next stop info for a trip
  const hideNextStopPopup = () => actions.setTripInfo(null);

  // add hover listeners for each stop
  useEffect(() => {
    if (!map) return;

    trips.forEach((trip) => {
      map.on("mouseenter", `current-pos-${trip.id}`, showNextStopPopup);
      map.on("mouseleave", `current-pos-${trip.id}`, hideNextStopPopup);
    });

    return () => {
      trips.forEach((trip) => {
        map.off("mouseenter", `current-pos-${trip.id}`, showNextStopPopup);
        map.off("mouseleave", `current-pos-${trip.id}`, hideNextStopPopup);
      });
      popUpRef.current.remove();
    };
  }, [map, trips]);

  const showStopPopup = (e) => {
    const [lng, lat] = e.features[0].geometry.coordinates.slice();
    const address = e.features[0].properties.address;
    const label = e.features[0].properties.label;

    popupRoot.current?.render(<StopPopup address={address} label={label} cls={cls} />);

    // set offset, click-to-close, close button
    popUpRef.current.options = DEFAULT_POPUP_OPTS;
    // set popup on map
    popUpRef.current.setLngLat([lng, lat]).setDOMContent(popupNode.current).addTo(map);
  };

  const hideStopPopup = () => {
    popUpRef.current.remove();
  };

  // add hover listeners for stops
  useEffect(() => {
    if (!map) return;

    map.on("mouseenter", "stops", showStopPopup);
    map.on("mouseleave", "stops", hideStopPopup);
    map.on("mouseenter", "schools-blue-circle", showStopPopup);
    map.on("mouseleave", "schools-blue-circle", hideStopPopup);
    map.on("mouseenter", "school-stops-circle", showStopPopup);
    map.on("mouseleave", "school-stops-circle", hideStopPopup);

    return () => {
      map.off("mouseenter", "stops", showStopPopup);
      map.off("mouseleave", "stops", hideStopPopup);
      map.off("mouseenter", "schools-blue-circle", showStopPopup);
      map.off("mouseleave", "schools-blue-circle", hideStopPopup);
      map.off("mouseenter", "school-stops-circle", showStopPopup);
      map.off("mouseleave", "school-stops-circle", hideStopPopup);
      popUpRef.current.remove();
    };
  }, [map]);

  useEffect(() => {
    if (selectedTrip && map) centerMapToTrip(map, selectedTrip);
  }, [selectedTrip?.trip?.id, map]);

  // add past route data for selected trip
  useEffect(() => {
    if (!map) return;

    const coords = selectedTrip ? selectedTrip.positions.map((p) => [p.lng, p.lat]) : [];
    if (coords.length < 2) return;

    matchRoute(coords).then((r) => {
      addActualRouteData(map, r, selectedTrip?.trip);
    });
  }, [selectedTrip?.positions]);

  const showWaypointPopup = (e) => {
    const [lng, lat] = e.features[0].geometry.coordinates.slice();
    const time = e.features[0].properties.time;

    popupRoot.current?.render(
      <WaypointPopup trip={selectedTrip.trip} cls={cls} lat={lat} lng={lng} time={time} />
    );

    // set offset, click-to-close, close button
    popUpRef.current.options = WAYPOINT_POPUP_OPTS;
    // set popup on map
    popUpRef.current.setLngLat([lng, lat]).setDOMContent(popupNode.current).addTo(map);
  };

  // add waypoints data for selected trip
  useEffect(() => {
    if (!map) return;

    // if showWaypoints false or trip was "unselected" - remove waypoints data from map
    // also remove currently opened popup
    const positions = state.showWaypoints && selectedTrip ? selectedTrip.positions : [];
    addRouteWaypointsData(map, positions);
    popUpRef.current.remove();

    if (!selectedTrip || !state.showWaypoints) return;

    // show waypoint popup on click
    map.on("click", "act-route-waypoints", showWaypointPopup);

    return () => {
      map.off("click", "act-route-waypoints", showWaypointPopup);
    };
  }, [selectedTrip, state.showWaypoints]);

  const shouldProjectRoute = (trip) => {
    return !selectedTrip || selectedTrip.trip.id === trip.id;
  };

  const renderActiveTrips = (tripsList) => {
    tripsList.forEach((trip) => {
      if (trip.last_position) showActiveTrip(map, trip);
      if (trip.ended_at) hideInactiveTrips(map, [trip]);
    });
  };

  const addProjectedRoute = (trip) => {
    if (!shouldProjectRoute(trip)) return;

    if (selectedTrip) {
      showRoute(selectedTrip);
    } else {
      fetchTripMap(trip.id).then((resp) => {
        showRoute(resp);
      });
    }
  };

  const centerMapToTrip = (map, selectedTrip) => {
    const { lng, lat } = selectedTrip.trip.last_position || selectedTrip.stops[0];
    flyTo(map, [lng, lat]);
  };

  const showRoute = (tripData) => {
    const trip = tripData.trip;

    const stops = filter(tripData.stops, (s, idx) => {
      return (
        s.lng &&
        s.lat &&
        !trip.stops_info[s.id]?.completed &&
        idx > tripData.trip.max_completed_stop_idx
      );
    });

    let routeCoords = stops.map((s) => [s.lng, s.lat]);

    if (trip.last_position) {
      routeCoords.unshift([trip.last_position.lng, trip.last_position.lat]);
    }

    if (routeCoords.length < 2) return;

    fetchTripDirections(trip.id).then((r) => {
      addRouteData(map, trip, r);
    });
  };

  return <div ref={mapboxEl} className={cls.map} />;
};

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "100%",
    borderRadius: theme.spacing(1)
  },
  vehicle: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: "2px solid #fff",
    borderRadius: "50%",
    background: theme.custom.GREEN
  },
  popup: {
    fontSize: "0.8rem",
    maxWidth: theme.spacing(32),
    color: theme.custom.DARK_GREY_2
  },
  popupInfo: {
    marginTop: theme.spacing(0)
  },
  routeName: {
    fontWeight: theme.custom.BOLD,
    margin: theme.spacing(0, 0, 1, 0)
  },
  stopAddress: {
    margin: theme.spacing(0)
  },
  label: {
    fontWeight: theme.custom.SEMI_BOLD,
    margin: theme.spacing(0)
  },
  timeWrapper: {
    display: "flex",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5)
  },
  striked: {
    textDecoration: "line-through"
  },
  time: {
    marginRight: theme.spacing(0.5),
    fontWeight: theme.custom.SEMI_BOLD
  },
  stopStudent: {
    margin: theme.spacing(0)
  },
  tripTz: {
    fontSize: "0.6rem"
  }
}));

export default Map;
