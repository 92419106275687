import React, { useState, Fragment } from "react";
import { Dialog, DialogContent, DialogActions, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import makeStyles from "@mui/styles/makeStyles";
import Spinner from "components/admin_v2/ui/Spinner";
import { FormDialogTitle } from "components/admin_v2/ui/FormDialog";
import I18n from "utils/i18n.js";
import useDidMountEffect from "lib/useDidMountEffect";

const AveryLabelsPreview = ({ reportStore }) => {
  const [state, actions] = reportStore;
  const { open, content } = state.dialog;
  const [page, setPage] = useState(1);
  const cls = useStyles();

  const { pages, total } = content || {};

  useDidMountEffect(() => setPage(1), [total]);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={actions.closeDialog}
        className={cls.dialog}
        maxWidth="lg"
        fullWidth={true}
      >
        {content === null ? (
          <Spinner />
        ) : (
          <Fragment>
            <FormDialogTitle onClose={actions.closeDialog}>
              <Typography variant="h1">{I18n.t("report.preview.avery")}</Typography>
              <Box className={cls.pagination}>
                <Box className={cls.paginationTotal}>
                  <Typography variant="subtitle1">{total} students</Typography>
                </Box>
                <Box className={cls.paginationBtns}>
                  <Button
                    className={[cls.btn, cls.btnLeft].join(" ")}
                    variant="outlined"
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  >
                    <ArrowBackIos color="secondary" />
                  </Button>
                  <Button className={cls.pageCurrent} variant="outlined" disabled>
                    <span className={cls.bold}>{page}</span>
                    <span className={cls.pageSep}>/</span>
                    {pages.length}
                  </Button>
                  <Button
                    className={cls.btn}
                    variant="outlined"
                    disabled={page === pages.length}
                    onClick={() => setPage(page + 1)}
                  >
                    <ArrowForwardIos color="secondary" />
                  </Button>
                </Box>
              </Box>
            </FormDialogTitle>
            <DialogContent className={cls.content}>
              <Box className={cls.preview}>
                <div dangerouslySetInnerHTML={{ __html: pages[page - 1] }} />
              </Box>
            </DialogContent>
            <DialogActions className={cls.footer}>
              <Button
                key={`avery-btn-print`}
                variant="contained"
                color="primary"
                className={cls.actionBtn}
                onClick={() => actions.printAveryLabels(false)}
              >
                {I18n.t("ui.btn.print")}
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
      <div id="avery-print" className={cls.hide} />
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperFullWidth": {
      width: "calc(8.5in + 80px)"
    }
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: theme.custom.BOLD
  },
  pagination: {
    display: "flex",
    fontSize: "0.8rem",
    marginTop: theme.spacing(1)
  },
  paginationTotal: {
    flex: "1",
    fontWeight: theme.custom.SEMI_BOLD
  },
  pageSep: {
    margin: theme.spacing(0, 1)
  },
  pageCurrent: {
    margin: theme.spacing(0, 1)
  },
  preview: {
    border: `solid 1px ${theme.custom.LIGHT_GREY}`,
    width: "8.5in",
    height: "11in",

    "& .avery-label": {
      border: `solid 1px ${theme.custom.DARK_GREY_2}`
    }
  },
  btn: {
    padding: "5px 2px",
    minWidth: theme.spacing(4)
  },
  btnLeft: {
    "& .MuiSvgIcon-root": {
      position: "relative",
      left: "4px"
    }
  },
  bold: {
    fontWeight: theme.custom.BOLD
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0
  },
  footer: {
    padding: 0,
    marginTop: theme.spacing(2),
    justifyContent: "flex-start"
  },
  actionBtn: {
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(15)
  },
  hide: {
    display: "none"
  }
}));

export default AveryLabelsPreview;
