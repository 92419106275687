import React, { Fragment, useCallback } from "react";
import { useLocalStore } from "easy-peasy";
import { startCase } from "lodash";
import cn from "classnames";
import memoize from "fast-memoize";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText
} from "@mui/material";
import FormDatePicker from "components/admin_v2/ui/FormDatePicker";
import TableActionCell from "components/admin_v2/forms/TableActionCell";
import TableHeaders from "components/admin_v2/forms/TableHeaders";
import CollapsibleSection from "components/admin_v2/forms/CollapsibleSection";
import useSorting from "lib/useSorting";
import { commaErrors } from "lib/util";
import { formatHumanDate } from "lib/dates";
import { useTableFormStyles, clsTable } from "components/admin_v2/common/useTableFormStyles";
import daysOffStore from "../stores/daysOffStore";
import I18n from "utils/i18n.js";
import useTesting from "lib/useTesting";

const OFF_TYPES = ["single_day", "interval"];
const I18N_KEY = "school_config.day_off.form";

const HEADERS = [
  { field: "offType", label: I18n.t(`${I18N_KEY}.off_type.label`), required: true },
  { field: "startDate", label: I18n.t(`${I18N_KEY}.start_date.label`), required: true },
  { field: "endDate", label: I18n.t(`${I18N_KEY}.end_date.label`) },
  { field: "comment", label: I18n.t(`${I18N_KEY}.comment.label`) },
  { field: "editMode", label: "Actions" }
];

const DayOffRow = ({ data, idx, formStore, cls, updateField }) => {
  const isEditMode = formStore[0].isEditMode(idx);
  const { entity, errors } = data;
  const { clsCell } = clsTable(cls, isEditMode);
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell className={cn(clsCell, cls.cellMedium)}>
        {isEditMode ? (
          <FormControl className={cls.field} variant="standard" error={!!errors?.offType}>
            <InputLabel>{I18n.t(`${I18N_KEY}.off_type.label`)}</InputLabel>
            <Select value={entity.offType || "single_day"} onChange={updateField(idx, "offType")}>
              {OFF_TYPES.map((offType) => (
                <MenuItem key={`dayoff-${idx}-${offType}`} value={offType}>
                  {startCase(offType)}
                </MenuItem>
              ))}
            </Select>
            {errors?.offType && (
              <FormHelperText error>{commaErrors(errors?.offType)}</FormHelperText>
            )}
          </FormControl>
        ) : (
          startCase(entity.offType)
        )}
      </TableCell>
      <TableCell className={cn(clsCell, cls.cellMedium)}>
        {isEditMode ? (
          <FormDatePicker
            date={entity.startDate}
            onChange={updateField(idx, "startDate")}
            errors={errors?.startDate}
            reverse
          />
        ) : (
          formatHumanDate(entity.startDate)
        )}
      </TableCell>
      <TableCell className={cn(clsCell, cls.cellMedium)}>
        {isEditMode ? (
          <FormDatePicker
            date={entity.endDate}
            onChange={updateField(idx, "endDate")}
            disabled={entity.offType === "single_day"}
            errors={errors?.endDate}
            reverse
          />
        ) : entity.offType === "single_day" ? null : (
          formatHumanDate(entity.endDate)
        )}
      </TableCell>
      <TableCell className={clsCell}>
        {isEditMode ? (
          <TextField
            variant="standard"
            label={I18n.t(`${I18N_KEY}.comment.label`)}
            value={entity.comment || ""}
            onChange={updateField(idx, "comment")}
            className={cn("dayOff-comment", cls.field)}
            error={!!errors?.comment}
            helperText={commaErrors(errors?.comment)}
          />
        ) : (
          entity.comment
        )}
      </TableCell>
      <TableActionCell store={formStore} idx={idx} />
    </TableRow>
  );
};

const DaysOffForm = ({ daysOff, parentEntity, collapsible = true }) => {
  const cls = useTableFormStyles();
  const formStore = useLocalStore(() =>
    daysOffStore({ data: daysOff, noDataAllowed: true, parentEntity })
  );
  const [state, actions] = formStore;
  const { sortableColumn } = useSorting({
    field: "offType",
    order: "asc",
    callback: actions.sortBy
  });

  useTesting({
    "dayOff:setStartDate": (value) => {
      actions.updateStartDate({ idx: 0, field: "startDate", value });
    }
  });

  // NOTE: we need memoize here as useCallback will give us not a lot because new function will be created on each rerender
  // Another option is to move idx, field to data attributes of input
  const updateField = useCallback(
    memoize((idx, field) => (e) => {
      let value;
      if (["startDate", "endDate"].includes(field)) {
        value = e;
      } else {
        value = e.target.value;
      }
      field === "startDate"
        ? actions.updateStartDate({ idx, field, value })
        : actions.updateRowField({ idx, field, value });
    }),
    []
  );

  const tableHeaders = HEADERS.map(sortableColumn);

  const rows = state.data.map((data, idx) => (
    <DayOffRow
      key={`dayoff-${idx}-row`}
      idx={idx}
      formStore={formStore}
      cls={cls}
      data={data}
      updateField={updateField}
    />
  ));

  const table = (
    <>
      <Table
        className={`${cls.table} ${parentEntity.type.toLowerCase()}-daysOff`}
        aria-label="table"
      >
        <TableHead>
          <TableRow>
            <TableHeaders headers={tableHeaders} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <Button className={cls.btn} variant="contained" color="secondary" onClick={actions.addRow}>
        {I18n.t("school_config.new_day_off.btn")}
      </Button>
    </>
  );

  return collapsible ? (
    <CollapsibleSection title="daysOff" count={state.dataPersisted.length}>
      {table}
    </CollapsibleSection>
  ) : (
    table
  );
};

export default DaysOffForm;
