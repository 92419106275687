import React, { useEffect } from "react";
import FormSelect from "components/admin_v2/ui/FormSelect";
import { useStoreState } from "easy-peasy";

const AddressSchoolForm = ({ store, cls, children }) => {
  const [state, actions] = store;
  const { route } = useStoreState((s) => s.routeEditor);

  useEffect(() => {
    actions.fetchSchoolsData({ route });
  }, []);

  const onChange = (e) => actions.setSchoolStopLocation({ id: e.target.value });

  return (
    <>
      <FormSelect
        itemType="school"
        i18Key="stop"
        options={state.schoolAddresses}
        value={state.stopLocation?.masterId}
        onChange={onChange}
        cls={cls}
        clsKeys={["select", "select"]}
        withoutLabel={true}
        required
      />
      {children}
    </>
  );
};

export default AddressSchoolForm;
