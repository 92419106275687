import { action, thunk, computed } from "easy-peasy";
import { pick, pull } from "lodash";
import { camelizeKeys } from "humps";
import { easyStateSetters } from "lib/easyState";
import { strPresent } from "lib/util";
import { createSchool, fetchSchool, saveSchool } from "services/apiSchools";
import scopeStore from "components/admin_v2/common/stores/scopeStore";
import { formatDateUrl } from "lib/dates";
import { EDIT_MODE } from "components/admin_v2/forms/stores/tableSettingStore";
import { setFlashMessage } from "services";

const REQUIRED = {
  new: ["name", "slug", "firstDay", "lastDay", "address", "addressName"],
  persisted: ["name", "slug", "firstDay", "lastDay"]
};

const defaultState = {
  // status
  loading: false,
  editMode: EDIT_MODE.view,
  // data
  errors: {},
  school: null,
  details: {},
  initialData: []
};

const pickSchoolParams = (school) =>
  pick(school, [
    "name",
    "slug",
    "code",
    "icabbiAccountRef",
    "hotlineEmail",
    "firstDay",
    "lastDay",
    "gradesServed",
    "schoolDaysCount",
    "allAdminsAccess",
    "districtId"
  ]);

const initDetailsFromSchool = (school) => {
  return {
    ...pickSchoolParams(school),
    slackIntegrationAttributes: { ...pick(school?.slackIntegration, ["id", "enabled"]) }
  };
};

export const schoolFormStore = (school, editMode = EDIT_MODE.view) => {
  const details = initDetailsFromSchool(school);

  return {
    ...easyStateSetters(defaultState, { school, details, editMode }),
    ...scopeStore(),

    updateDetails: action((state, payload = {}) => {
      state.details = { ...state.details, ...payload };
    }),

    isGradeChecked: computed((state) => (grade) => state.details.gradesServed?.includes(grade)),

    isEditMode: computed((state) => state.editMode === EDIT_MODE.edit),

    updateSelectedGrades: action((state, data) => {
      const { isAdd, value } = data;
      let selectedGrades = state.details.gradesServed;
      if (isAdd) {
        selectedGrades.push(value);
      } else {
        pull(selectedGrades, value);
      }

      state.details = { ...state.details, gradesServed: selectedGrades };
    }),

    isValid: computed((state) => {
      const { details } = state;
      return REQUIRED[state.school?.id ? "persisted" : "new"].every((field) =>
        strPresent(details[field]?.toString())
      );
    }),

    formData: computed((state) => {
      let { details } = state;
      const data = {
        ...details,
        firstDay: formatDateUrl(details.firstDay),
        lastDay: formatDateUrl(details.lastDay)
      };
      if (!data.slackIntegrationAttributes.id) {
        delete data.slackIntegrationAttributes;
      }
      return data;
    }),

    updateSchool: action((state, school) => {
      state.school = { ...state.school, ...pickSchoolParams(school) };
      state.school.slackIntegration = school.slackIntegration;
      state.editMode = EDIT_MODE.view;
    }),

    cancel: action((state) => {
      state.details = initDetailsFromSchool(state.school);
      state.editMode = EDIT_MODE.view;
    }),

    refetch: thunk((actions, _params = {}, h) => {
      const state = h.getState();

      fetchSchool(state.school.id)
        .then((resp) => actions.setSchool(resp.school))
        .catch((err) => setFlashMessage(err.message));
    }),

    save: thunk(async (actions, _payload, h) => {
      const state = h.getState();
      if (!state.isValid) return Promise.reject();

      actions.setErrors({});
      actions.setLoading(true);
      const data = state.formData;
      try {
        const school = state.school;
        if (school.id) {
          const updatedData = await saveSchool(school.id, data);
          actions.updateSchool(updatedData.school);
          return Promise.resolve(updatedData);
        } else {
          return await createSchool(data);
        }
      } catch (err) {
        const errors = err.response?.data?.errors;
        actions.setErrors(camelizeKeys(errors));
        throw err;
      } finally {
        actions.setLoading(false);
      }
    })
  };
};
