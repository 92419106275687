import React, { useEffect, useCallback } from "react";
import {
  Box,
  Paper,
  TableRow,
  Typography,
  Table,
  TableHead,
  TableBody,
  Button,
  TableCell,
  FormControl
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { isBefore } from "date-fns";
import memoize from "fast-memoize";
import cn from "classnames";
import { reject, isEqual, startCase, compact, intersection } from "lodash";
import ArrowDropDownRounded from "@mui/icons-material/ArrowDropDownRounded";
import { useLocalStore, useStoreState } from "easy-peasy";
import { pageRoutes } from "services/api";
import VendorSelector from "components/admin_v2/nav/VendorSelector";
import AlertDialog from "components/admin_v2/common/AlertDialog";
import FormSelect from "components/admin_v2/ui/FormSelect";
import Spinner from "components/admin_v2/ui/Spinner";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import { tripTz } from "components/admin_v2/trips/tripHelpers";
import { pairedRoutesStore } from "./paired_routes/stores/pairedRoutesStore";
import I18n from "utils/i18n.js";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { parseTime } from "lib/dates";
import { truncate } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { useTableFormStyles, clsTable } from "components/admin_v2/common/useTableFormStyles";
import StyledTableCell from "components/admin_v2/ui/StyledTableCell";
import TableActionCell from "components/admin_v2/forms/TableActionCell";

const PAIRED_ROUTES_HEADER_KEYS = [
  { key: "vendor", required: true },
  { key: "direction", required: true },
  { key: "tier_1_route", required: true },
  { key: "tier_1_times" },
  { key: "tier_2_route", required: true },
  { key: "tier_2_times" },
  { key: "status", size: "xsmall" },
  { key: "notes" },
  { key: "actions" }
];

const PAIRS_DIRECTIONS = [
  { id: ["to_school", "from_school"], name: "To and From School" },
  { id: ["to_school"], name: "To School" },
  { id: ["from_school"], name: "From School" }
];

const AlertMessage = ({ state }) => {
  const vehiclesMessage = state.alert.vehiclesMismatch
    ? I18n.t("data_management.paired_routes.messages.vehicles_mismatch.message")
    : null;
  const timesMessage = state.alert.timesMismatch
    ? I18n.t("data_management.paired_routes.messages.times_mismatch.message")
    : null;
  const messages = compact([vehiclesMessage, timesMessage]);

  return (
    <>
      {messages.map((m, idx) => (
        <Box key={idx} mt={idx ? 1 : 0}>
          {m}
        </Box>
      ))}
    </>
  );
};

const routeVehicles = (route, cls, directions, tierOneRoute = null) => {
  if (!route) return null;
  const inboundMisaligned = tierOneRoute && route.inboundVehicle !== tierOneRoute.inboundVehicle;
  const outboundMisaligned = tierOneRoute && route.outboundVehicle !== tierOneRoute.outboundVehicle;
  const showInbound = directions?.includes("to_school");
  const showOutbound = directions?.includes("from_school");
  const inboundVehicle = route.inboundFirstPickup ? route.inboundVehicle : "-";
  const outboundVehicle = route.outboundFirstPickup ? route.outboundVehicle : "-";

  return (
    <>
      {showInbound && (
        <Box mt={1} className={inboundMisaligned ? cls.textAlert : null}>
          {I18n.t(`data_management.paired_routes.vehicles.inbound`, {
            vehicle: inboundVehicle || "Unassigned"
          })}
        </Box>
      )}
      {showOutbound && (
        <Box mt={showInbound ? 0 : 1} className={outboundMisaligned ? cls.textAlert : null}>
          {I18n.t(`data_management.paired_routes.vehicles.outbound`, {
            vehicle: outboundVehicle || "Unassigned"
          })}
        </Box>
      )}
    </>
  );
};

const routeTimes = (route, cls, directions, tierOneRoute = null) => {
  if (!route) return null;

  const inboundMisaligned =
    tierOneRoute &&
    isBefore(parseTime(route.inboundFirstPickup), parseTime(tierOneRoute.inboundLastDropoff));
  const outboundMisaligned =
    tierOneRoute &&
    isBefore(parseTime(route.outboundFirstPickup), parseTime(tierOneRoute.outboundLastDropoff));
  const showInbound = directions?.includes("to_school");
  const showOutbound = directions?.includes("from_school");

  return (
    <>
      {showInbound && (
        <>
          <Box className={inboundMisaligned ? cls.textAlert : null}>
            {I18n.t(`data_management.paired_routes.times.pickup`, {
              time: route.inboundFirstPickup || "-"
            })}
            {route.inboundFirstPickup && tripTz(route.timeZoneAbbr)}
          </Box>
          <Box className={inboundMisaligned ? cls.textAlert : null}>
            {I18n.t(`data_management.paired_routes.times.dropoff`, {
              time: route.inboundLastDropoff || "-"
            })}
            {route.inboundLastDropoff && tripTz(route.timeZoneAbbr)}
          </Box>
        </>
      )}
      {showOutbound && (
        <>
          {" "}
          <Box mt={showInbound ? 1 : 0} className={outboundMisaligned ? cls.textAlert : null}>
            {I18n.t(`data_management.paired_routes.times.pickup`, {
              time: route.outboundFirstPickup || "-"
            })}
            {route.outboundFirstPickup && tripTz(route.timeZoneAbbr)}
          </Box>
          <Box className={outboundMisaligned ? cls.textAlert : null}>
            {I18n.t(`data_management.paired_routes.times.dropoff`, {
              time: route.outboundLastDropoff || "-"
            })}
            {route.outboundLastDropoff && tripTz(route.timeZoneAbbr)}
          </Box>
        </>
      )}
    </>
  );
};

const directionError = (directions, route) => {
  if (!route || !directions) return null;

  return directions.find(
    (d) =>
      (d === "to_school" && !route.inboundFirstPickup) ||
      (d === "from_school" && !route.outboundFirstPickup)
  );
};

const RoutePairRow = ({ data, idx, store, cls, onSave, updateField }) => {
  const isEditMode = store[0].isEditMode(idx);
  const { entity } = data;
  const { clsCell } = clsTable(cls, isEditMode, { verticalAlign: "top", smallerText: true });
  const { transportationVendors, vendorsRoutes } = store[0];
  const tierOneErr = isEditMode ? directionError(entity.directions, entity.tierOneRoute) : null;
  const tierTwoErr = isEditMode ? directionError(entity.directions, entity.tierTwoRoute) : null;
  let tierOneRouteOptions = reject(vendorsRoutes[entity.transportationVendorId], function (r) {
    return (
      r.id === entity.tierTwoRouteId || intersection(r.existingPairs, entity.directions).length
    );
  });
  let tierTwoRouteOptions = reject(vendorsRoutes[entity.transportationVendorId], function (r) {
    return (
      r.id === entity.tierOneRouteId || intersection(r.existingPairs, entity.directions).length
    );
  });

  if (entity.id) {
    tierOneRouteOptions = tierOneRouteOptions.concat(entity.tierOneRoute);
    tierTwoRouteOptions = tierTwoRouteOptions.concat(entity.tierTwoRoute);
  }

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell className={cn(clsCell, cls.cellMedium)}>
        {isEditMode ? (
          <FormControl className={cls.field} variant="standard">
            <FormSelect
              itemType="vendor"
              options={transportationVendors}
              value={entity.transportationVendorId}
              onChange={updateField(idx, "transportationVendorId")}
              cls={cls}
              withoutLabel={true}
            />
          </FormControl>
        ) : (
          entity.vendor
        )}
      </TableCell>
      <TableCell className={cn(clsCell, cls.cellMedium)}>
        {isEditMode ? (
          <FormSelect
            itemType="directions"
            options={PAIRS_DIRECTIONS}
            value={PAIRS_DIRECTIONS.find((d) => isEqual(d.id, entity.directions))?.id}
            onChange={updateField(idx, "directions")}
            cls={cls}
            withoutLabel={true}
          />
        ) : (
          I18n.t(`data_management.paired_routes.directions.${entity.directions.join("_")}`)
        )}
      </TableCell>
      <TableCell className={cn(clsCell, cls.cellMedium)}>
        {isEditMode ? (
          <FormSelect
            itemType="tierOneRouteId"
            options={tierOneRouteOptions}
            value={entity.tierOneRouteId}
            onChange={updateField(idx, "tierOneRouteId")}
            cls={cls}
            withoutLabel={true}
            errorNoMargin={true}
            error={
              tierOneErr
                ? I18n.t(`data_management.paired_routes.errors.directions`, {
                    direction: startCase(tierOneErr)
                  })
                : null
            }
          />
        ) : (
          <b>{entity.tierOneRoute.name}</b>
        )}
        {routeVehicles(entity.tierOneRoute, cls, entity.directions)}
      </TableCell>
      <TableCell className={clsCell}>
        {routeTimes(entity.tierOneRoute, cls, entity.directions)}
      </TableCell>
      <TableCell className={cn(clsCell, cls.cellMedium)}>
        {isEditMode ? (
          <FormSelect
            itemType="tierTwoRouteId"
            options={tierTwoRouteOptions}
            value={entity.tierTwoRouteId}
            onChange={updateField(idx, "tierTwoRouteId")}
            cls={cls}
            withoutLabel={true}
            errorNoMargin={true}
            error={
              tierTwoErr
                ? I18n.t(`data_management.paired_routes.errors.directions`, {
                    direction: startCase(tierTwoErr)
                  })
                : null
            }
          />
        ) : (
          <b>{entity.tierTwoRoute.name}</b>
        )}
        {routeVehicles(entity.tierTwoRoute, cls, entity.directions, entity.tierOneRoute)}
      </TableCell>
      <TableCell className={clsCell}>
        {routeTimes(entity.tierTwoRoute, cls, entity.directions, entity.tierOneRoute)}
      </TableCell>
      <TableCell className={cn(clsCell, cls.cellXSmall)}>
        <div style={{ paddingTop: "0.75em" }}>{entity.discardedAt ? "Archived" : "Active"}</div>
      </TableCell>
      <TableCell className={cn(clsCell, cls.cellLarge)}>
        {isEditMode ? (
          <FormControl className={cls.field} variant="standard">
            <textarea
              style={{ boxSizing: "border-box", padding: "4px", resize: "none" }}
              value={entity.notes}
              onChange={updateField(idx, "notes")}
              rows={4}
            />
          </FormControl>
        ) : (
          truncate(entity.notes || "", 80)
        )}
      </TableCell>
      <TableActionCell store={store} idx={idx} saveCallback={onSave} verticalAlign="top" />
    </TableRow>
  );
};

const PairedRoutes = (props) => {
  const cls = useCommonPageStyles();
  const tableCls = useTableFormStyles();
  const store = useLocalStore(() => pairedRoutesStore());
  const { vendorId } = useStoreState((s) => s.vendor);
  const [state, actions] = store;
  const { data } = state;

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchVendorOptions();
    actions.fetchRoutePairs({ vendorId: vendorId });
  }, [state.page, state.perPage, vendorId]);

  useEffect(() => {
    actions.fetchRoutesOptions();
  }, [state.listChanged]);

  // NOTE: we need memoize here as useCallback will give us not a lot because new function will be created on each rerender
  // Another option is to move idx, field to data attributes of input
  const updateField = useCallback(
    memoize((idx, field) => (e) => {
      const value = e.target.value;
      actions.updateRowField({ idx, field, value });

      if (field === "transportationVendorId") {
        actions.updateRowField({ idx, field: "tierOneRouteId", value: null });
        actions.updateRowField({ idx, field: "tierTwoRouteId", value: null });
        actions.fetchRouteData({ idx, field, routeId: null });
      }
      if (["tierOneRouteId", "tierTwoRouteId"].includes(field)) {
        actions.fetchRouteData({ idx, field, routeId: value });
      }
    }),
    []
  );

  const onSave = ({ idx }) => {
    actions.validateMismatch({ idx });
  };

  const onSubmit = (params) => {
    actions.save(params);
  };

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const rows = state.data.map((data, idx) => (
    <RoutePairRow
      key={`routePair-${idx}-row`}
      idx={idx}
      store={store}
      cls={tableCls}
      data={data}
      onSave={onSave}
      updateField={updateField}
    />
  ));

  return (
    <>
      <BreadCrumbs path={pageRoutes.routes()} label={I18n.t("data_management.routes.breadcrumb")} />
      <Paper className={cls.paper}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Box display="flex">
              <ArrowDropDownRounded className="caret" fontSize="large" />
              <Typography variant="h1" gutterBottom>
                {I18n.t("data_management.paired_routes.title")}
              </Typography>
              <aside className={cls.itemsCount}>
                ({I18n.t("ui.items", { count: data.length })})
              </aside>
            </Box>
            <Box mb={4}>
              <VendorSelector key="vendor-select" placeholder="All Vendors" />
            </Box>
            <Table aria-label="table" className={tableCls.fixedTable}>
              <TableHead>
                <TableRow>
                  {PAIRED_ROUTES_HEADER_KEYS.map((header) => (
                    <StyledTableCell
                      className={cn(tableCls.tableHeader, {
                        [tableCls.cellXSmall]: header.size == "xsmall"
                      })}
                      key={header.key}
                    >
                      {I18n.t(`data_management.paired_routes.headers.${header.key}`)}
                      {header.required && <span className={cls.required}>*</span>}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
            <Button
              className={tableCls.btn}
              variant="contained"
              color="secondary"
              onClick={actions.addRow}
            >
              Add New Pair
            </Button>
            <TablePagination
              rowsPerPageOptions={[25, 50]}
              component="div"
              count={state.total}
              rowsPerPage={state.perPage}
              page={state.page - 1}
              onPageChange={updatePage}
              onRowsPerPageChange={updatePerPage}
            />
            <AlertDialog store={store} callBack={onSubmit} {...state.alert}>
              {state.alertOpen ? <AlertMessage state={state} /> : null}
            </AlertDialog>
          </>
        )}
      </Paper>
    </>
  );
};

export default PairedRoutes;
