import React, { Fragment } from "react";
import { Box, Button, Divider, Grid, Typography, Link } from "@mui/material";
import { FormDialogTitle } from "components/admin_v2/ui/FormDialog";
import { useStoreState, useStoreActions } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import StickyNote2SharpIcon from "@mui/icons-material/StickyNote2Sharp";
import { DayOffChip } from "components/admin_v2/ui/DayOffLabel";
import DaySelector from "components/admin_v2/common/DaySelector";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { pageRoutes, slugsFor } from "services/api";
import ModeChangeButtons from "./ModeChangeButtons";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";
import { WORKFLOW_TYPES } from "../stores/modeChangeStore";
import I18n from "utils/i18n.js";
import { truncate } from "lib/util";

const ModeChangeForm = ({
  modeChangeStore,
  showViewProfile,
  children,
  onCancel,
  saveChanges,
  onDateChange = null,
  inline = false
}) => {
  const cls = useStyles({ inline });
  const [state, actions] = modeChangeStore;
  const { isUserAdmin } = useStoreState((s) => s.app);
  const { setModeDialogOpen } = useStoreActions((s) => s.calendar);
  const withChangeRequest = state.withChangeRequest && (!isUserAdmin || state.isAdminChangeRequest);
  const student = state.student;
  const title = `${student.first_name} ${student.last_name}`;
  const contact = student.primary_phone_number
    ? `${student.primary_phone_number.number}${
        student.primary_phone_number.name ? ` (${student.primary_phone_number.name})` : ""
      }`
    : "";
  const onBack = (_e) => actions.setWorkflow(WORKFLOW_TYPES.chooseStudent);
  const onSelectDate = onDateChange || actions.resetDate;
  const onViewProfile = (_e) => {
    setModeDialogOpen(false);
  };
  const viewProfile = showViewProfile ? (
    <RouterLink
      to={pageRoutes.student(student.id, slugsFor(student))}
      schoolId={student.school_id}
      component={Button}
      variant="outlined"
      className={cls.profileBtn}
      onClick={onViewProfile}
    >
      View Profile
    </RouterLink>
  ) : null;

  return (
    <Fragment>
      {state.isAdminChangeRequest && (
        <>
          <FormDialogTitle onClose={onCancel}>
            <Box display="flex" alignItems="center">
              <Typography variant="h2">{I18n.t("ui.mode_editor.new_request")}</Typography>
            </Box>
          </FormDialogTitle>
          <Link
            onClick={onBack}
            className={cls.backBtn}
            component="button"
            underline="always"
            id="back-to-student-choose"
          >
            {I18n.t("ui.mode_editor.back")}
          </Link>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs>
          <Box display="flex" alignItems="center">
            <Typography variant="h2">{title}</Typography>
            {withoutAccommodation(student) ? null : (
              <StudentAccommodationPopup student={student} marginLeft={1} />
            )}
          </Box>
          {inline && (
            <Box mt={1} fontWeight="fontWeightBold" fontSize="subtitle2.fontSize">
              {student.school_name}
            </Box>
          )}
          <Typography variant="body1">
            {student.program} | {`Grade ${student.grade}`}
          </Typography>
          <Typography variant="body1">{contact}</Typography>
          {student.accommodation_note ? (
            <Typography variant="body1" className={cls.riderNote}>
              <StickyNote2SharpIcon className={cls.riderNoteIcon} fontSize="small" />
              <span>{truncate(student.accommodation_note, 180)}</span>
            </Typography>
          ) : null}
        </Grid>
        {showViewProfile && inline && <Grid item>{viewProfile}</Grid>}
      </Grid>
      <Divider variant="fullWidth" className={cls.separator} />
      <Grid container spacing={2} className={cls.daySelectWrapper}>
        <Grid item xs>
          <Box display="flex">
            <DaySelector
              date={state.currentDate}
              onSelectDate={onSelectDate}
              skipWeekends={true}
              minDate={isUserAdmin ? null : state.minDate}
            />
            {state.calendar?.trips?.day_off && <DayOffChip />}
          </Box>
        </Grid>
        {showViewProfile && !inline && <Grid item>{viewProfile}</Grid>}
      </Grid>
      {children}
      <Divider variant="fullWidth" className={cls.separator} />
      <ModeChangeButtons
        withChangeRequest={withChangeRequest}
        isDisabled={state.isDisabled}
        saveChanges={saveChanges}
        onCancel={onCancel}
        inline={inline}
      />
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  separator: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  backBtn: {
    textAlign: "left",
    marginBottom: theme.spacing(2)
  },
  changeReqBtn: {
    backgroundColor: theme.custom.YELLOW,
    color: theme.custom.DARK_GREY_1,
    "&:hover": {
      backgroundColor: theme.custom.YELLOW
    }
  },
  profileBtn: {
    height: (props) => (props.inline ? "" : "100%"),
    backgroundColor: theme.custom.WHITE
  },
  daySelectWrapper: {
    marginBottom: theme.spacing(2)
  },
  riderNote: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  riderNoteIcon: {
    marginRight: theme.spacing(0.5),
    marginBottom: "-0.15em",
    fontSize: "1em"
  }
}));

export default ModeChangeForm;
