import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import { useStoreState } from "easy-peasy";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import DateRangeFilter from "./DateRangeFilter";
import StudentSearchFilter from "./StudentSearchFilter";
import { useFilterStyles, ScopeButtons } from "components/admin_v2/common/FilterHelpers";
import I18n from "utils/i18n.js";

const SCOPE_OPTIONS = [
  ["requests", "Requests"],
  ["issues", "Issues"]
];

const ChangeRequestsFilter = ({ state, updateQueryString }) => {
  const cls = useFilterStyles();
  const { isUserAdmin } = useStoreState((s) => s.app);

  const onSelectRequestor = (e) => {
    updateQueryString({ page: 1, requestor_id: e.target.value });
  };

  const onSelectStatus = (e) => {
    updateQueryString({ page: 1, status: e.target.value });
  };

  return (
    <Fragment>
      <Box className={cls.options}>
        <DateRangeFilter state={state} updateQueryString={updateQueryString} />
      </Box>
      <Box className={cls.options}>
        <Box display="flex">
          {isUserAdmin && (
            <ScopeButtons
              options={SCOPE_OPTIONS}
              currentScope={state.scope}
              onChange={updateQueryString}
            />
          )}
        </Box>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flexWrap="wrap">
          <StudentSearchFilter state={state} updateQueryString={updateQueryString} />
          <SelectFilter
            id="requestor-filter"
            label={I18n.t("ui.queues.filter.requestor")}
            value={state.requestorId}
            onChange={onSelectRequestor}
            items={state.filters.requestors}
          />
          <SelectFilter
            id="status-filter"
            value={state.status}
            onChange={onSelectStatus}
            items={state.filters.statuses}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default ChangeRequestsFilter;
