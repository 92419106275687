import React from "react";
import { Box, TextField } from "@mui/material";
import cn from "classnames";
import useDidMountEffect from "lib/useDidMountEffect";
import FormSelect from "components/admin_v2/ui/FormSelect";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import { get } from "lodash";
import { addMinutes, format, isValid } from "date-fns";

const USER_TIME_OPTION = { id: "add_new", name: "Enter a new time" };
const DEFAULT_TIME_INPUT = { time: new Date(), waitTime: 0 };

const SchoolTimeForm = (props) => {
  const { store, stopType, tripType, cls, tz, userAnchorTime } = props;
  const [state, actions] = store;
  const stop = get(state.stops, `${tripType}.${stopType}`);
  const departureTime = isValid(userAnchorTime.time)
    ? format(addMinutes(userAnchorTime.time, userAnchorTime.waitTime || 0), "HH:mm")
    : null;
  const selectedSchool = state.selectedSchool({ stopType, tripType });
  let schoolTimes = state.schoolTimes({ stopType, tripType });
  schoolTimes.push(USER_TIME_OPTION);

  useDidMountEffect(() => {
    if (schoolTimes.length > 1) {
      actions.setStopSchoolTime({ timeId: schoolTimes[0]?.id, stopType, tripType });
    } else {
      actions.setUserAnchorTime({ time: DEFAULT_TIME_INPUT, stopType, tripType });
    }
  }, [selectedSchool?.id]);

  const onChange = (e) => {
    const val = e.target.value;
    if (val == USER_TIME_OPTION.id) {
      actions.setUserAnchorTime({ time: DEFAULT_TIME_INPUT, stopType, tripType });
    } else {
      actions.setStopSchoolTime({ timeId: e.target.value, stopType, tripType });
    }
  };

  const updateAnchorTime = (data) => {
    actions.setUserAnchorTime({ time: { ...userAnchorTime, ...data }, stopType, tripType });
  };

  const handleAnchorTimeChange = (field) => {
    return (e) => {
      const val = field == "time" ? e : e.target.value;
      updateAnchorTime({ [field]: val });
    };
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <FormSelect
          itemType="schoolTime"
          i18Key="stop"
          options={schoolTimes}
          value={userAnchorTime.time ? "add_new" : stop?.timeId}
          onChange={onChange}
          cls={cls}
          clsKeys={["halfSelect", "select"]}
          withoutLabel={true}
          required
        />
        {userAnchorTime.time && (
          <>
            <TimePickerInput
              dateTime={userAnchorTime.time}
              onChange={handleAnchorTimeChange("time")}
              clsName={cls.timePicker}
            />
            <TextField
              variant="outlined"
              value={userAnchorTime.waitTime || 0}
              type="number"
              inputProps={{ min: 0 }}
              onChange={handleAnchorTimeChange("waitTime")}
              className={cn(cls.timeField, cls.waitTimeField)}
              disabled={tripType == "to_school"}
            />
            <Box className={cls.timeField}>
              {departureTime && (
                <>
                  {departureTime}
                  {state.routable && <span className={"time-zone"}>{tz}</span>}
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default SchoolTimeForm;
