import React, { Fragment, useState } from "react";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";

// use for sticky sorting
function getPersistedSort(app, page) {
  if (!app || !page) return;

  const val = localStorage.getItem(`${app}:${page}:sortBy`);
  if (!val) return;

  return JSON.parse(val);
}

// update sticky sorting
function setPersistedStore({ app, page, sortBy }) {
  if (!app || !page) return;

  localStorage.setItem(`${app}:${page}:sortBy`, JSON.stringify(sortBy));
}

export const useSorting = (defaults, localSettings = {}) => {
  const callback = defaults?.callback || null;
  defaults = getPersistedSort(localSettings.app, localSettings.page) ||
    defaults || {
      field: "id",
      order: "asc"
    };

  if (!defaults.callback) {
    defaults.callback = callback;
  }

  const [sortBy, setSortBy] = useState({ field: defaults.field, order: defaults.order });
  const cls = useStyles();

  const updateSorting = (field) => {
    let newSortBy = {};
    if (field !== sortBy.field) {
      newSortBy = { field: field, order: "asc" };
    } else {
      const newOrder = sortBy.order === "asc" ? "desc" : "asc";
      newSortBy = { field: field, order: newOrder };
    }
    setSortBy(newSortBy);
    setPersistedStore({ app: localSettings.app, page: localSettings.page, sortBy: newSortBy });
    if (defaults.callback) defaults.callback(newSortBy);
  };

  // Usage:
  //   const headers = [
  //     { field: "last_name", label: "Last Name", required: true, subLabel: "Surname" },
  //     { field: "id", label: "ID" },
  //     { field: "first_name", label: "First Name" },
  //     { field: "grade", label: "Grade", skipSorting: true }
  //   }
  //
  //   <PaginatedTable
  //     headers={headers}
  //     ... />
  //
  const sortableColumn = (
    {
      field,
      label,
      tooltip = null,
      subLabel = null,
      required = false,
      skipSorting,
      forAdmin,
      forFalseCondition,
      align = "left",
      clsName = null
    },
    isUserAdmin = false
  ) => {
    if (!isUserAdmin && forAdmin) return null;
    if (forFalseCondition) return null;
    if (skipSorting) return { label, align, subLabel, required, key: field };

    let column = (
      <>
        {label}
        {required ? <sup>*</sup> : null}
        {subLabel ? (
          <>
            <br />
            <small>{subLabel}</small>
          </>
        ) : null}
      </>
    );
    if (sortBy.field === field) {
      column = (
        <>
          {sortBy.order === "asc" ? (
            <ArrowDownward className={cls.sortIcon} />
          ) : (
            <ArrowUpward className={cls.sortIcon} />
          )}
          {column}
        </>
      );
    }
    return {
      label: (
        <Tooltip
          title={I18n.t("ui.sorting", { label: tooltip || label })}
          placement="top"
          arrow={true}
        >
          <span className={cls.sortable} onClick={() => updateSorting(field)}>
            {column}
          </span>
        </Tooltip>
      ),
      align: align,
      key: `sort-by-${field}`,
      clsName: clsName
    };
  };

  const clearSortBy = () => {
    setSortBy(defaults);
  };

  return {
    sortBy,
    updateSorting,
    sortableColumn,
    clearSortBy
  };
};

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    fontSize: "0.87rem",
    marginRight: theme.spacing(0.5),
    position: "relative",
    top: theme.spacing(0.25)
  },
  sortable: {
    cursor: "pointer"
  }
}));

export default useSorting;
