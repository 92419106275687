import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchStudentImports = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`student_imports?${query}`));
};

export const submitStudentImport = async (params) => {
  const url = apiUrl(`student_imports`);
  return await xhr.post(url, params);
};
