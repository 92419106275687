import React, { Fragment } from "react";
import { Dialog, Typography } from "@mui/material";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import { useStoreState, useStoreActions } from "easy-peasy";
import DismissalGroupForm from "./DismissalGroupForm";
import I18n from "utils/i18n.js";

const AddDismissalGroupDialog = ({ cls, open, store, toggleDialog }) => {
  const { school } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [state, actions] = store;

  const closeDialog = () => {
    toggleDialog();
    actions.setErrors({});
    actions.setGroupForm({ schoolId: school?.id });
  };

  const onSubmit = () => {
    actions.save().then((r) => {
      setFlashMessage({ message: r.message });
      actions.addDismissalGroup(r.dismissalGroup);
      toggleDialog();
      actions.setGroupForm({ schoolId: school?.id });
    });
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <Fragment>
        <FormDialogTitle onClose={closeDialog}>
          <Typography variant="h1">{I18n.t("school_config.new_dismissal_group.title")}</Typography>
        </FormDialogTitle>
        <FormDialogContent className={cls.dialogCont}>
          <DismissalGroupForm store={store} />
        </FormDialogContent>
        <FormDialogActions
          onSubmit={onSubmit}
          onClose={closeDialog}
          disabled={!state.isValid}
          submitLabel={I18n.t("school_config.dismissal_groups.form.submit")}
        />
      </Fragment>
    </Dialog>
  );
};

export default AddDismissalGroupDialog;
