import React, { useState, useCallback, memo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, FormControl, FormLabel, FormHelperText, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const FormDatePicker = ({
  date,
  onChange,
  label,
  errors,
  required,
  desc,
  disabled,
  disablePast = false,
  labelCls = null,
  name = "date-select",
  format = "MM/dd/yyyy"
}) => {
  const [open, setOpen] = useState(false);
  const cls = useStyles();
  const onOpen = useCallback((_e) => setOpen(true), []);
  const onClose = useCallback((_e) => setOpen(false), []);

  return (
    <FormControl className={cls.fieldWrap} error={!!errors}>
      <Box display="flex" alignItems="start">
        <FormLabel className={labelCls || cls.label} required={!!required}>
          {label}
        </FormLabel>
        {desc && <div className={cls.desc}> {desc}</div>}
      </Box>
      <Box display="flex" alignItems="start">
        <DatePicker
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          value={date === "" ? null : date}
          onChange={onChange}
          disabled={disabled}
          disablePast={disablePast}
          inputFormat={format}
          renderInput={(props) => <TextField name={name} variant="outlined" {...props} />}
        />
      </Box>
      {errors && <FormHelperText>{errors[0]}</FormHelperText>}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldWrap: {
    "& + &": {
      marginLeft: theme.spacing(4)
    }
  },
  fieldDate: {
    maxWidth: "150px",
    marginRight: theme.spacing(1)
  },
  label: {
    display: "block",
    fontSize: "1rem",
    marginBottom: theme.spacing(1)
  },
  desc: {
    display: "inline",
    fontStyle: "italic",
    fontSize: "0.8rem"
  }
}));

export default memo(FormDatePicker);
