import { compact, map } from "lodash";
import I18n from "utils/i18n.js";

const REPORT_TYPES = [
  { value: "curbside", forAll: true },
  { value: "district_curbside", forAll: true, withDistrict: true },
  { value: "weekly_curbside", forAll: true },
  { value: "all_trips", forAll: true },
  { value: "adm", forAll: false },
  { value: "historical_trips", forAll: false },
  { value: "students", forAll: false },
  { value: "avery", forAll: true },
  { value: "changelogs", forAll: false },
  { value: "route_sheets", forAll: true },
  { value: "school_events", forAll: false }
];

export const reportTypes = (user, withAvery = true, withDistrict = true) => {
  const isAdmin = user?.role === "admin";
  const isRestrictedAdmin = user?.restricted_admin;

  return compact(
    map(REPORT_TYPES, (report) => {
      if (!(report.forAll || isAdmin)) return null;
      if (report.withDistrict && !withDistrict) return null;
      if (report.value === "avery" && !withAvery) return null;
      if (report.value === "adm" && isRestrictedAdmin) return null;

      return { id: report.value, name: I18n.t(`report.types.${report.value}`) };
    })
  );
};
