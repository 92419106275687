import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, FormLabel, FormGroup, TextField, Divider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ChangeReqFormStatus from "./form/ChangeReqFormStatus";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";

const ChangeReqUnenroll = ({ requestStore, changeReq }) => {
  const cls = useStyles();
  const { isUserAdmin } = useStoreState((s) => s.app);
  const [state, actions] = requestStore;
  const [pickerOpen, setPickerOpen] = useState(false);

  const updateNote = (e) => {
    actions.setApproverNote(e.target.value);
  };

  return (
    <Box className={cls.wrapper}>
      <Box className={cls.info}>
        <Box className={cls.redBox}>
          <Typography variant="subtitle1" className={cls.label}>
            {I18n.t("change_requests.dialog.unenroll")}
          </Typography>
          <Typography variant="subtitle1">{`By: ${changeReq.requestor}`}</Typography>
        </Box>
      </Box>
      <Divider variant="fullWidth" className={cls.separator} />
      <Box className={cls.changeBox}>
        <ErrorList errors={state.getTopLevelErrors} />
        <FormLabel className={cls.inputLabel}>Effective Date of unenroll</FormLabel>
        <DatePicker
          open={pickerOpen}
          onOpen={() => setPickerOpen(true)}
          onClose={() => setPickerOpen(false)}
          value={state.effectiveDate}
          onChange={actions.setEffectiveDate}
          className={cls.input}
          desktopModeMediaQuery="@media (min-width:320px)"
          renderInput={(props) => <TextField {...props} variant="outlined" />}
        />
        <Box mt={1}>
          <Typography variant="subtitle1" className={cls.inputLabel}>
            Notes:
          </Typography>
          <FormGroup row>
            <TextField
              variant="outlined"
              multiline
              disabled={true}
              rows={3}
              className={cls.note}
              value={changeReq.requestor_note || ""}
            />
          </FormGroup>
        </Box>
      </Box>
      <Box className={cls.resolutionBox}>
        <Box className={cls.mb}>
          <Typography variant="subtitle1" className={cls.inputLabel}>
            {I18n.t("change_requests.dialog.resolve_request")}:
          </Typography>
          <ChangeReqFormStatus
            cls={cls}
            store={requestStore}
            isUserAdmin={isUserAdmin}
            statusType="unenroll_form"
          />
        </Box>
        <Box>
          <TextField
            variant="outlined"
            multiline
            rows={2}
            className={cls.note}
            onChange={updateNote}
            placeholder={I18n.t("change_requests.unenroll_form.approver_note")}
            defaultValue={changeReq.approver_note || ""}
            disabled={!isUserAdmin || state.resolved}
          />
        </Box>
        .
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  separator: {
    marginTop: theme.spacing(2)
  },
  label: {
    fontWeight: theme.custom.BOLD
  },
  wrapper: {
    backgroundColor: theme.palette.background.lightGrey,
    borderRadius: theme.spacing(0.6),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  info: {
    padding: theme.spacing(2)
  },
  redBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.custom.RED,
    color: theme.custom.WHITE,
    width: "100%",
    borderRadius: theme.spacing(0.5),
    borderColor: theme.custom.RED,
    borderWidth: theme.spacing(0.1),
    borderStyle: "solid",
    fontWeight: theme.custom.BOLD
  },
  changeBox: {
    padding: theme.spacing(2),
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: theme.custom.RED
  },
  resolutionBox: {
    padding: theme.spacing(2, 2.5, 0.5)
  },
  mb: {
    marginBottom: theme.spacing(2)
  },
  inputLabel: {
    display: "block",
    color: theme.palette.text.primary,
    fontWeight: theme.custom.BOLD,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  input: {
    backgroundColor: theme.custom.WHITE,
    minWidth: theme.spacing(30)
  },
  select: {
    width: theme.spacing(36),
    backgroundColor: theme.custom.WHITE,
    "& .MuiSelect-selectMenu": {
      backgroundColor: theme.custom.WHITE
    }
  },
  note: {
    backgroundColor: theme.custom.WHITE,
    width: "100%",
    "& .Mui-disabled": {
      color: theme.palette.text.primary
    }
  }
}));

export default ChangeReqUnenroll;
