import React, { useEffect } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import { Typography, Box } from "@mui/material";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DistrictConfigTabs from "components/admin_v2/district_config/DistrictConfigTabs";
import DistrictForm from "components/admin_v2/district_config/districts/DistrictForm";
import I18n from "utils/i18n.js";
import { districtsStore } from "components/admin_v2/district_config/stores/districtsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { Redirect } from "react-router-dom";

const DistrictEdit = (props) => {
  const { user, isRestrictedAdmin } = useStoreState((s) => s.app);
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => districtsStore());
  const district = state.district;
  const id = props.match.params.id;

  useEffect(() => {
    if (!id) return;

    actions.fetchDistrict(id);

    return () => {
      actions.setDistrict(null);
    };
  }, [id]);

  if (
    isRestrictedAdmin &&
    district &&
    (!user?.with_districts || !user.school_ids.some((sid) => district.schoolIds.includes(sid)))
  ) {
    return <Redirect to={pageRoutes.districts()} />;
  }

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("district_config.title")} />
      <DistrictConfigTabs />
      <BreadCrumbs
        path={pageRoutes.districts()}
        label={I18n.t("district_config.districts.breadcrumb")}
      />
      {district && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {district.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <OutlinedBtn className={`${cls.actionBtn} active`} disabled>
              {I18n.t("district_config.edit_district.btn")}
            </OutlinedBtn>
            {!isRestrictedAdmin && (
              <OutlinedBtn className={cls.actionBtn} onClick={() => actions.deleteDistrict(id)}>
                {I18n.t("ui.btn.delete")}
              </OutlinedBtn>
            )}
          </Box>
        </Box>
      )}
      {state.loading ? <Spinner /> : <DistrictForm district={district} />}
    </React.Fragment>
  );
};

export default DistrictEdit;
