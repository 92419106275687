import { computed } from "easy-peasy";
import { strPresent } from "lib/util";
import tableSettingStore from "./tableSettingStore";
import { createPhoneNumber, removePhoneNumber, savePhoneNumber } from "services/apiPhoneNumbers";

const phoneNumbersStore = (initial = {}) => ({
  ...tableSettingStore({
    entity: { name: "Phone Number", key: "phoneNumber" },
    endPoints: {
      createEntity: createPhoneNumber,
      removeEntity: removePhoneNumber,
      saveEntity: savePhoneNumber
    },
    ...initial
  }),

  // validate address
  isValid: computed((state) => (idx) => {
    const data = state.getRow(idx)?.entity || {};
    return strPresent(data.name) && strPresent(data.number);
  }),

  newEntity: computed((state) => {
    return {
      name: "",
      number: "",
      primary: state.data.length === 0, // 1st should be primary
      lineType: "mobile",
      optOutCommunication: false,
      language: "english"
    };
  }),

  formData: computed((state) => (idx) => {
    return state.data[idx].entity;
  })
});

export default phoneNumbersStore;
