import { action, computed } from "easy-peasy";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";

// this store should be mixed in another componentStore.
// for example:
//    const myFormStore = useLocalStore((model) => ({
//      // form store fields ...
//      ...addressesStore(model?.addresses)
//    }))
//
const addressesStore = (initial) => ({
  ...easyState("addresses", initial || []),
  ...easyState("removedAddrs", []),

  // Use this method to get all addresses to be sent by the form submit
  // getAddresses
  addressesAttributes: computed((state) => {
    const { addresses, removedAddrs } = state;
    return [...addresses, ...removedAddrs];
  }),

  // validateAddresses
  isValidAddresses: computed((state) => (payload = {}) => {
    const addrs = state.addresses;
    const validCount = payload?.noAddressAllowed ? true : addrs.length > 0;
    return validCount && addrs.every((a) => strPresent(a.name) && strPresent(a.address));
  }),

  newAddress: action((state, _payload) => {
    state.addresses.push({
      id: null,
      name: "",
      address: "",
      primary: state.addresses.length === 0, // the first one is primary
      lat: null,
      lng: null
    });
  }),

  updateAddress: action((state, payload) => {
    const { index, field, value } = payload;
    if (state.addresses[index]) {
      state.addresses[index][field] = value;
    }
  }),

  updatePrimaryAddress: action((state, index) => {
    const newAddrs = state.addresses.map((addr, i) => {
      return { ...addr, primary: i === index };
    });
    state.addresses = newAddrs;
  }),

  removeAddress: action((state, index) => {
    const addr = state.addresses[index];
    if (addr.id) {
      // if it's a persisted address we must set to _destroy
      state.removedAddrs.push({ ...addr, _destroy: true });
    }
    state.addresses.splice(index, 1);

    // if we removed the primary, set another primary
    if (addr.primary && state.addresses.length > 0) {
      state.addresses[0].primary = true;
    }
  })
});

export default addressesStore;
