import React, { Fragment } from "react";
import { startCase } from "lodash";
import cn from "classnames";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { useStoreState } from "easy-peasy";
import { strPresent, commaErrors } from "lib/util";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import I18n from "utils/i18n.js";
import { Box } from "@mui/material";

const I18N_KEY = "school_config.comms_groups.form";
const REPLY_TYPES = ["one_to_one", "one_to_many"];

const CommsGroupDetailsForm = ({ store }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { details, group, errors } = state;
  const { schools } = useStoreState((s) => s.app);

  const updateField = (key, e) => {
    let value;
    if (["avlNotifications", "reserved", "preventAutoReplies"].includes(key)) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    const newDetails = { ...details, [key]: value };
    actions.setDetails(newDetails);
  };

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t(`${I18N_KEY}.name.label`)}
                condition={strPresent(details.name)}
              />
            }
            value={details.name || ""}
            onChange={(e) => updateField("name", e)}
            className={cn("comm-group-name", cls.field)}
            error={!!errors?.name}
            helperText={commaErrors(errors?.name)}
            disabled={!!(details?.id && group?.routeId)}
          />
          {schools.length && (
            <FormControl className={cls.field} error={!!errors?.schoolId} variant="standard">
              <InputLabel>{I18n.t(`${I18N_KEY}.school.label`)}</InputLabel>
              <Select
                value={details?.schoolId || ""}
                onChange={(e) => updateField("schoolId", e)}
                disabled={!!details?.id}
              >
                {schools.map((school) => (
                  <MenuItem key={school.id} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.schoolUsers?.school && (
                <FormHelperText>{commaErrors(errors?.school)}</FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl className={cls.field} variant="standard">
            <InputLabel>{I18n.t(`${I18N_KEY}.reply_type.label`)}</InputLabel>
            <Select
              className="comm-group-replyType"
              value={details?.replyType || "one_to_one"}
              onChange={(e) => updateField("replyType", e)}
              MenuProps={{ className: "comm-group-replyType-menu" }}
            >
              {REPLY_TYPES.map((replyType) => (
                <MenuItem key={`comms-group-${replyType}`} value={replyType}>
                  {startCase(replyType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={details.avlNotifications}
                  onChange={(e) => updateField("avlNotifications", e)}
                />
              }
              label={I18n.t(`${I18N_KEY}.avl_notifications.label`)}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={details.reserved}
                  onChange={(e) => updateField("reserved", e)}
                />
              }
              label={
                <span style={styles.checkboxLabel}>
                  Reserved group
                  <aside style={styles.aside}>
                    (messages won&apos;t be shown on other feeds, like school and district)
                  </aside>
                </span>
              }
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={details.preventAutoReplies}
                  onChange={(e) => updateField("preventAutoReplies", e)}
                />
              }
              label={
                <span style={styles.checkboxLabel}>
                  Prevent auto-replies
                  <aside style={styles.aside}>
                    (Do not send an auto-reply for after-hours messages)
                  </aside>
                </span>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const styles = {
  checkboxLabel: {
    display: "flex",
    alignItem: "center"
  },
  aside: {
    fontSize: "0.8rem",
    marginLeft: "0.5rem",
    color: "rgba(0,0,0,0.6)",
    position: "relative",
    top: "4px"
  }
};

export default CommsGroupDetailsForm;
