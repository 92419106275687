import React, { useCallback } from "react";
import classNames from "classnames";
import { Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@mui/material";
import { formatDisplayTime } from "lib/dates";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import { DELTA_TYPE } from "../stores/daysDeltaStore";
import I18n from "utils/i18n.js";

const I18N_KEY = "school_config.day_delta.popup.form.school_delta_times";

const DayDeltaTimesTable = ({ store }) => {
  const cls = useTableFormStyles();
  const [state, actions] = store;

  const updateAllChecked = useCallback((e) => actions.updateAllChecked(e.target.checked), []);

  const updateTimeChecked = useCallback(
    (idx) => (e) => actions.updateTimeChecked({ idx, checked: e.target.checked }),
    []
  );

  const clsMedium = classNames(cls.cellBase, cls.cellMedium);
  const clsSm = classNames(cls.cellBase, cls.checkCell);
  const isDeltaType = state.dayDelta.deltaType === DELTA_TYPE.delta;

  const rows = state.data.map((delta, idx) => {
    const clsCell = classNames(clsMedium, { [cls.cellDisabled]: !delta.active });
    const clsCellBase = classNames(cls.cellBase, { [cls.cellDisabled]: !delta.active });
    const tableRow = (
      <TableRow tabIndex={-1} key={`deltatime-${idx}-row`}>
        <TableCell className={cls.checkCell}>
          <Checkbox
            checked={delta.checked}
            onChange={updateTimeChecked(idx)}
            disabled={!delta.active}
          />
        </TableCell>
        <TableCell className={clsCell}>{delta.name}</TableCell>
        <TableCell className={clsCellBase}>{formatDisplayTime(delta.originalTime)}</TableCell>
        <TableCell className={clsCellBase}>
          {delta.waitTime ? `${delta.waitTime} min` : ""}
        </TableCell>
        <TableCell className={classNames(clsCellBase, isDeltaType ? {} : cls.highlighted)}>
          {formatDisplayTime(delta.newTime)}
        </TableCell>
        <TableCell className={classNames(clsCellBase, isDeltaType ? cls.highlighted : {})}>
          {delta.displayDelta}
        </TableCell>
      </TableRow>
    );
    return delta.active ? (
      tableRow
    ) : (
      <Tooltip title={I18n.t(`${I18N_KEY}.disabled`)} arrow={true} key={`deltatime-${idx}-row`}>
        {tableRow}
      </Tooltip>
    );
  });

  return (
    <Table aria-label="table">
      <TableHead>
        <TableRow>
          <TableCell className={clsSm}>
            <Checkbox
              name="dt-all-checked"
              checked={state.allSelected}
              onChange={updateAllChecked}
              disabled={state.isPast}
            />
          </TableCell>
          <TableCell className={clsMedium}>{I18n.t(`${I18N_KEY}.name`)}</TableCell>
          <TableCell className={cls.cellBase}>{I18n.t(`${I18N_KEY}.original_time`)}</TableCell>
          <TableCell className={cls.cellBase}>{I18n.t(`${I18N_KEY}.wait_time`)}</TableCell>
          <TableCell className={classNames(cls.cellBase, isDeltaType ? {} : cls.highlighted)}>
            {I18n.t(`${I18N_KEY}.new_time`)}
          </TableCell>
          <TableCell className={classNames(cls.cellBase, isDeltaType ? cls.highlighted : {})}>
            {I18n.t(`${I18N_KEY}.delta`)}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};

export default DayDeltaTimesTable;
