import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Popover, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { isEmpty } from "lodash";
import classNames from "classnames";
import I18n from "utils/i18n.js";

export const accommodationList = (types, none_key = "accommodation_empty") =>
  types.length
    ? types.map((a) => I18n.t(`student.accommodation.accommodation_types.${a}`)).join(", ")
    : I18n.t(`student.accommodation.${none_key}`);

const indifferentAcccomodationData = (student) => {
  const note = student?.accommodation_note || student?.accommodationNote;
  const types = student?.selected_accommodation_types || student?.selectedAccommodationTypes || [];

  return { note, types };
};

export const withoutAccommodation = (student) => {
  const { note, types } = indifferentAcccomodationData(student);
  return isEmpty(note) && isEmpty(types);
};

export const StudentAccommodationLabel = ({ student }) => {
  const cls = useStyles();
  if (withoutAccommodation(student)) return null;

  const { note, types } = indifferentAcccomodationData(student);
  return (
    <Box mt={1} mb={2.5} width={1}>
      <Box className={cls.info} display="inline-flex">
        <InfoIcon fontSize="small" className={cls.infoIcon} />
        <Typography variant="subtitle2">{accommodationList(types)}</Typography>
      </Box>
      {!isEmpty(note) ? (
        <Box className={cls.note} mt={1} p={1} bgcolor="background.white">
          {note}
        </Box>
      ) : null}
    </Box>
  );
};

export const StudentAccommodationPopup = ({ student, marginLeft = 0, fontSize = "small" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const cls = useStyles({ marginLeft });

  if (withoutAccommodation(student)) return null;

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const { note, types } = indifferentAcccomodationData(student);

  return (
    <>
      <InfoIcon
        aria-owns={open ? `acc-${student.id}-popover` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        fontSize={fontSize}
        className={classNames(cls.info, cls.infoPopover)}
      />
      <Popover
        id={`acc-${student.id}-popover`}
        className={cls.popover}
        classes={{
          paper: cls.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box display="flex" mb={1} justifyContent="space-between">
          <Typography>{student.name}</Typography>
          <InfoIcon fontSize="small" className={cls.info} />
        </Box>
        <Typography className={classNames(cls.info, cls.mb1)} variant="subtitle2">
          {accommodationList(types)}
        </Typography>
        <Typography variant="subtitle2" className={cls.note} variantMapping={{ subtitle2: "p" }}>
          {note}
        </Typography>
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  info: {
    color: theme.custom.YELLOW
  },
  infoIcon: {
    marginRight: theme.spacing(1)
  },
  infoPopover: {
    cursor: "pointer",
    marginLeft: (props) => theme.spacing(props.marginLeft || 0)
  },
  note: {
    whiteSpace: "pre-wrap",
    overflowWrap: "anywhere"
  },
  mb1: {
    marginBottom: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: theme.spacing(32),
    maxWidth: theme.spacing(52)
  },
  popover: {
    pointerEvents: "none"
  }
}));
