import React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import SelectFilter from "components/admin_v2/ui/SelectFilter";

const ROUTE_SCOPES = [
  { id: "active", name: "Active Routes" },
  { id: "archived", name: "Archived Routes" }
];

const RoutesFilter = ({ state, actions, updateQueryString }) => {
  const updateScope = (e) => {
    const scope = e.target.value;
    const params = { page: 1, scope: scope };
    if (scope === "archived") actions.setRunning(false);
    updateQueryString(params);
  };

  const updateVehicleType = (e) => {
    updateQueryString({ page: 1, vehicle_type: e.target.value });
  };

  const updateVendor = (e) => {
    updateQueryString({ page: 1, vendor: e.target.value });
  };

  const changeRunning = (e) => {
    const checked = e.target.checked;
    actions.setRunning(checked);
    updateQueryString({ page: 1 });
  };

  return (
    <Box p={2} display="flex" justifyContent="space-between">
      <Box>
        <SelectFilter
          id="routes-scope"
          value={state.scope}
          items={ROUTE_SCOPES}
          onChange={updateScope}
        />
        {state.scope !== "archived" ? (
          <FormControlLabel
            label="Running Today"
            style={{ marginLeft: "1em" }}
            control={
              <Checkbox
                checked={state.running}
                onChange={changeRunning}
                data-test-id="routes-scope-running"
              ></Checkbox>
            }
          />
        ) : null}
      </Box>
      <Box>
        <SelectFilter
          id="vt-filter"
          label="All Vehicle Types"
          value={state.vehicleType}
          onChange={updateVehicleType}
          items={state.vehicleTypes}
        />
        <SelectFilter
          id="tv-filter"
          label="All Vendors"
          value={state.vendor}
          onChange={updateVendor}
          items={state.transportationVendors}
        />
      </Box>
    </Box>
  );
};

export default RoutesFilter;
