import { action, computed, thunk } from "easy-peasy";
import { isValid, addMinutes } from "date-fns";
import { some, isEmpty } from "lodash";
import { strPresent } from "lib/util";
import { parseTime, formatTime } from "lib/dates";
import tableSettingStore from "components/admin_v2/forms/stores/tableSettingStore";
import alertStore from "components/admin_v2/common/stores/alertStore";
import {
  createSchoolTime,
  removeSchoolTime,
  saveSchoolTime,
  checkConnectedRecords
} from "services/apiSchoolTimes";
import { setFlashMessage } from "services";
import { camelizeKeys } from "humps";
import I18n from "utils/i18n.js";

const initDataFromModel = (data) => ({
  ...data,
  time: parseTime(data.time)
});

const schoolTimesStore = (initial = {}) => ({
  ...tableSettingStore({
    entity: { name: "School Time", key: "schoolTime" },
    endPoints: {
      createEntity: createSchoolTime,
      removeEntity: removeSchoolTime,
      saveEntity: saveSchoolTime
    },
    ...initial,
    data: initial.data?.map((d) => initDataFromModel(d)) ?? []
  }),
  ...alertStore(initial),

  // validate school time
  isValid: computed((state) => (idx) => {
    const entity = state.getRow(idx)?.entity || {};
    return (
      strPresent(entity.name) &&
      state.isUniqueName(idx) &&
      Number.isInteger(entity.waitTime) &&
      isValid(entity.time)
    );
  }),

  isUniqueName: computed((state) => (idx) => {
    const entity = state.getRow(idx)?.entity || {};
    const sameNames = state.dataPersisted.filter((d) => d.entity.name === entity.name);
    return isEmpty(sameNames) || (sameNames.length === 1 && sameNames[0].entity.id === entity.id);
  }),

  initData: computed((_state) => (data) => initDataFromModel(data)),

  newEntity: computed((state) => {
    const time = new Date();
    return {
      id: null,
      name: "",
      time,
      departureTime: time,
      primary: !some(
        state.dataPersisted,
        (d) => d.entity.primary && d.entity.timeType === "dropoff"
      ), // 1st should be primary
      timeType: "dropoff",
      waitTime: 0
    };
  }),

  formData: computed((state) => (idx) => {
    const entity = state.data[idx].entity;
    return {
      ...entity,
      time: formatTime(entity.time)
    };
  }),

  setMessageParams: action((state, params) => {
    if (params.new) {
      state.alert = {
        ...params,
        title: I18n.t("school_config.school_time.messages.new.title"),
        iconType: "info",
        submitLabel: I18n.t("school_config.school_time.messages.new.button.ok"),
        cancelLabel: I18n.t("school_config.school_time.messages.new.button.cancel"),
        submitParams: { new: true }
      };
    } else {
      state.alert = {
        ...params,
        title: I18n.t("school_config.school_time.messages.update.title"),
        iconType: "warning",
        submitLabel: I18n.t("school_config.school_time.messages.update.button.ok"),
        cancelLabel: I18n.t("school_config.school_time.messages.update.button.cancel"),
        submitParams: { idx: params.idx }
      };
    }
    state.alertOpen = true;
  }),

  validateName: action((state, { idx }) => {
    const error = state.isUniqueName(idx)
      ? null
      : I18n.t("data_management.errors.unique", { name: "Name" });
    state.data[idx].errors = {
      ...state.data[idx].errors,
      name: error
    };
  }),

  updateDepartureTime: action((state, payload) => {
    let entity = state.data[payload.idx].entity;
    if (isValid(entity.time) && Number.isInteger(entity.waitTime)) {
      entity.departureTime = addMinutes(entity.time, entity.waitTime);
    }
  }),

  updatePrimary: action((state, payload) => {
    const { idx } = payload;
    const entity = state.data[idx].entity;
    state.data
      .filter(
        (d) => d.entity.id && d.entity.id !== entity.id && d.entity.timeType === entity.timeType
      )
      .forEach((d) => {
        if (d.entity.id) d.entity.primary = false;
      });
    state.initialData
      .filter((d) => d.id !== entity.id && d.timeType === entity.timeType)
      .forEach((d) => {
        d.primary = false;
      });
  }),

  validateOtherNames: thunk((actions, { idx }, h) => {
    const state = h.getState();
    state.data.forEach((d, id) => {
      if (id !== idx) actions.validateName({ idx: id });
    });
  }),

  checkConnectedRecords: thunk((actions, { idx }, h) => {
    const state = h.getState();
    const data = state.data[idx].entity;
    checkConnectedRecords(data.id)
      .then((resp) => {
        if (resp.daysDelta || !isEmpty(resp.routes)) {
          actions.setMessageParams({ ...resp, idx });
        } else {
          actions.save({ idx });
        }
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;
        actions.setErrors({ idx, errors: camelizeKeys(errors) });
        setFlashMessage(
          err.response?.data?.message ||
            I18n.t("data_management.errors.fail", { name: state.entity.name })
        );
      });
  })
});

export default schoolTimesStore;
