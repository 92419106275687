import React, { useEffect } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreActions, useStoreState } from "easy-peasy";
import SchoolsSelect from "components/admin_v2/ui/SchoolsSelect";
import useCommonPageStyles from "../common/useCommonPageStyles";

const SchoolSelector = ({ disabled = false }) => {
  const { district, districts, school, allSchools, districtSchools } = useStoreState((s) => s.app);
  const { changeSchool, updateSchools } = useStoreActions((s) => s.app);
  const cls = useStyles();
  const cCls = useCommonPageStyles();

  useEffect(() => {
    updateSchools();
  }, []);

  const handleChange = (e) => {
    changeSchool(e.target.value);
  };

  const handleAutocompleteChange = (_e, school) => changeSchool(school?.id);

  let value;
  if (districtSchools.length < 1) value = "";
  else if (school?.id) value = school.id;
  else if (allSchools) value = "all";
  else value = "";

  return (
    <FormControl variant="outlined" className={cls.selector}>
      {districtSchools.length === 0 || district || !districts.length ? (
        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          classes={{ select: cls.selector }}
          disabled={disabled}
        >
          <MenuItem value="">
            <Typography variant="body2">Select School</Typography>
          </MenuItem>
          <MenuItem value="all">
            <Typography variant="body2">All Schools</Typography>
          </MenuItem>
          {districtSchools?.map(({ id, name, code }) => (
            <MenuItem key={`school-sel-${id}`} value={id}>
              <span className={cCls.schoolCode}>{code}</span> {name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <SchoolsSelect
          schoolIds={value}
          schools={[{ name: "All Schools", id: "all", district_name: "" }, ...districtSchools]}
          onChange={handleAutocompleteChange}
          multiple={false}
          classes={{ select: cls.selector }}
          disabled={disabled}
        />
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  selector: {
    width: theme.spacing(40),
    backgroundColor: theme.custom.WHITE,
    borderRadius: theme.spacing(0.5)
  }
}));

export default SchoolSelector;
