import React from "react";
import { useLocalStore } from "easy-peasy";
import { isEmpty } from "lodash";
import { addTripStore } from "components/admin_v2/students/stores/addTripStore";
import { parseTime } from "lib/dates";
import RouteAnchorForm from "./RouteAnchorForm";

const RouteAnchorInfo = ({ store, cls, tripType, withTopMargin = false }) => {
  const [state, _actions] = store;
  const anchor = state.getRouteAnchorFor(tripType);
  const anchorData = {
    ...anchor,
    time: anchor ? parseTime(anchor.time) : "",
    stopLocation: { address: anchor.address }
  };

  const defaultData = () => ({
    anchor: state.anchor,
    routeType: state.routeType,
    school: state.school,
    route: state.route,
    stops: { [tripType]: { [state.anchor]: anchorData } },
    tripTypes: state.tripTypes,
    day: state.day,
    workflow: { createRoute: false, stops: { [tripType]: { [state.anchor]: "new" } } }
  });

  const anchorStore = useLocalStore(() => addTripStore(defaultData()), [state.route?.id, anchor]);

  return !isEmpty(anchor) ? (
    <RouteAnchorForm
      tripType={tripType}
      disabled={true}
      store={anchorStore}
      cls={cls}
      withTopMargin={withTopMargin}
    />
  ) : null;
};

export default RouteAnchorInfo;
