import React from "react";
import { Box, Chip } from "@mui/material";
import EventSelect from "components/admin_v2/common/EventSelect";
import RouteLink from "components/admin_v2/mode_editor/common/RouteLink";
import { getAnchorType } from "components/admin_v2/students/stores/stopsModel";

const ChangeReqStopHeader = ({ requestStore, changeReq, cls }) => {
  const state = requestStore[0];
  const trip = state.getTrip;

  let stops = [trip.stop];
  if (trip.stop?.anchor) stops.push(trip.stop.anchor);
  if (getAnchorType(changeReq.trip_type) === "pickup") stops = stops.reverse();

  return stops.map((stop, idx) => (
    <Box display="flex" key={`ch-req-header-${idx}`} mt={1}>
      <Box mr={1}>
        <Chip className={cls.stopTimeChip} label={stop ? stop.time : "--:--"} />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <RouteLink
          day={state.effectiveDate}
          stop={stop}
          route={trip.route}
          student={changeReq.student}
        />
        {idx === 0 && (
          <Box>
            <EventSelect eventType="change_req" disabled={true} />
          </Box>
        )}
      </Box>
    </Box>
  ));
};

export default ChangeReqStopHeader;
