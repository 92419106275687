import React, { Fragment, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState } from "easy-peasy";
import { Box, Typography, Grid, RadioGroup, FormControlLabel, TextField } from "@mui/material";
import PeriodSelector from "components/admin_v2/common/PeriodSelector";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import ErrorList from "components/admin_v2/ui/ErrorList";
import RouteStopForm from "components/admin_v2/students/trip/route/RouteStopForm";
import AddRouteForm from "./route/AddRouteForm";
import ChangeReqFormStatus from "./form/ChangeReqFormStatus";
import RouteSelectForm from "./route/RouteSelectForm";
import RouteStopSelect from "./stop/RouteStopSelect";
import {
  getAnchorType,
  getRouteType,
  isOtherRoute
} from "components/admin_v2/students/stores/stopsModel";
import useTripStyles from "components/admin_v2/students/trip/useStyles";
import I18n from "utils/i18n.js";

const ChangeReqForm = ({ changeReq, requestStore, currentRoute }) => {
  const cls = useStyles();
  const clsTrip = useTripStyles();
  const { isUserAdmin } = useStoreState((s) => s.app);
  const [createRoute, setCreateRoute] = useState(false);
  const [state, actions] = requestStore;
  const anchorType = getAnchorType(changeReq.trip_type);
  const routeType = getRouteType(
    changeReq.route?.route_type || changeReq.route?.routeType || changeReq.trip_type
  );

  const toggleCreateRoute = () => {
    setCreateRoute(!createRoute);
  };

  const updateNote = (value) => {
    actions.setApproverNote(value);
  };

  const isDisabled = state.resolved || !isUserAdmin;

  const RouteComponent = createRoute ? AddRouteForm : RouteSelectForm;
  let components = [
    <RouteStopForm
      key={`r-direction-${changeReq.trip_type}`}
      withCheckBox={false}
      direction={{ type: changeReq.trip_type }}
      disabled={isDisabled}
      stopLabel={
        <Typography variant="subtitle1" className={cls.label}>
          {I18n.t(`change_requests.dialog.change_to.${changeReq.trip_type}`)}
        </Typography>
      }
      store={requestStore}
      cls={clsTrip}
      StopSelectComponent={RouteStopSelect}
    />,
    <RouteComponent
      key={`r-direction-anchor-${changeReq.trip_type}`}
      cls={cls}
      store={requestStore}
      toggleForm={toggleCreateRoute}
      isDisabled={isDisabled}
    />
  ];

  return (
    <Fragment>
      <ErrorList errors={state.getTopLevelErrors} />
      <PeriodSelector
        period={state.period}
        onPeriodChange={actions.setPeriod}
        route={{ routeId: currentRoute?.id, tripType: changeReq.trip_type }}
        student={changeReq.student}
        stopToAdd={state.getStopFor(changeReq.trip_type, state.stopType)}
        label={false}
        disabled={isDisabled}
        changeRequest={true}
      />
      {changeReq.requestor_note ? (
        <Box className={cls.reqNote}>{changeReq.requestor_note}</Box>
      ) : null}
      {anchorType === "dropoff" ? components : components.reverse()}
      <Box mt={2}>
        <Typography variant="subtitle1" className={cls.label}>
          Resolve Request
        </Typography>
      </Box>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item sm={5}>
          <ChangeReqFormStatus
            cls={cls}
            store={requestStore}
            isUserAdmin={isUserAdmin}
            statusType="status"
          />
        </Grid>
        <Grid item sm={7}>
          {state.oneDayChange && !isOtherRoute(routeType) ? (
            <RadioGroup value={state.eventType} row>
              <FormControlLabel
                value={state.eventType}
                id="change-event"
                control={
                  <GreenRadio
                    onChange={() => actions.setEventType("change")}
                    checked={state.eventType === "change"}
                    className="event-change"
                    disabled={isDisabled}
                  />
                }
                label="Change"
              />
              <FormControlLabel
                value=""
                id="one-off-event"
                control={
                  <GreenRadio
                    onChange={() => actions.setEventType("one_off")}
                    checked={state.eventType === "one_off"}
                    className="event-change"
                    disabled={isDisabled}
                  />
                }
                label="1-OFF"
              />
            </RadioGroup>
          ) : null}
        </Grid>
      </Grid>
      <TextField
        variant="outlined"
        multiline
        rows={2}
        className={cls.note}
        onChange={(e) => updateNote(e.target.value)}
        placeholder="Comment on resolution"
        defaultValue={changeReq.approver_note || ""}
        disabled={isDisabled}
      />
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  reqNote: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    width: "100%",
    borderRadius: theme.spacing(0.5),
    borderColor: theme.custom.MEDIUM_GREY,
    borderWidth: theme.spacing(0.1),
    borderStyle: "solid"
  },
  checkLabel: {
    alignSelf: "flex-end",
    marginRight: 0,
    "& .MuiCheckbox-root": {
      padding: 0
    }
  },
  label: {
    fontWeight: theme.custom.BOLD
  },
  scheduleLink: {
    paddingTop: theme.spacing(1)
  },
  select: {
    width: "100%",
    backgroundColor: theme.custom.WHITE,
    "& .MuiSelect-selectMenu": {
      backgroundColor: theme.custom.WHITE
    }
  },
  note: {
    backgroundColor: theme.custom.WHITE,
    width: "100%",
    marginTop: theme.spacing(2)
  }
}));

export default ChangeReqForm;
