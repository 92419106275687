import React from "react";
import { FormControl, RadioGroup, FormControlLabel, FormHelperText } from "@mui/material";
import { map } from "lodash";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import I18n from "utils/i18n.js";

const ROUTE_TYPES = ["regular", "custom", "activity"];

const RouteTypeSelector = ({ cls, store, disabledType = null }) => {
  const [state, actions] = store;

  const handleChange = (event) => {
    if (state.workflow.createRoute) {
      actions.updateRouteType(event.target.value);
    } else {
      actions.resetState({ routeType: event.target.value });
    }
  };

  const routeTypesRadio = map(ROUTE_TYPES, (type) => (
    <FormControlLabel
      value={type}
      key={`route-type-${type}`}
      control={
        <GreenRadio
          disabled={type === disabledType || (type === "custom" && state.isCrossSchool)}
        />
      }
      label={I18n.t(`ui.route.route_type.${type}`)}
      className={cls.radioInput}
    />
  ));

  return (
    <FormControl component="fieldset" error={!!state.errors.base} fullWidth={true}>
      <RadioGroup value={state.routeType} onChange={handleChange} row>
        {routeTypesRadio}
      </RadioGroup>
      {state.errors?.base && <FormHelperText>{state.errors?.base}</FormHelperText>}
    </FormControl>
  );
};

export default RouteTypeSelector;
