import React, { useEffect } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import { snakeCase } from "lodash";
import { Typography, Paper, Box, Grid } from "@mui/material";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DistrictConfigTabs from "components/admin_v2/district_config/DistrictConfigTabs";
import DistrictAssociations from "components/admin_v2/district_config/districts/DistrictAssociations";
import I18n from "utils/i18n.js";
import { districtsStore } from "components/admin_v2/district_config/stores/districtsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { Redirect } from "react-router-dom";

const DETAIL_FIELDS = [
  "name",
  "slug",
  "code",
  "hotlineEmail",
  "icabbiAccountRef",
  "twilioNumber",
  "timeZoneAbbr",
  "schools"
];

const District = (props) => {
  const { user, isRestrictedAdmin } = useStoreState((s) => s.app);
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => districtsStore());
  const id = props.match.params.id;
  const district = state.district;

  useEffect(() => {
    if (!id) return;

    actions.fetchDistrict(id);

    return () => {
      actions.setDistrict(null);
    };
  }, [id]);

  const fieldVal = (val) => (Array.isArray(val) ? val.map((s) => s.name).join(", ") : val);

  const detail = (prop) => {
    return (
      <Box className={cls.detail} key={prop}>
        <div className={cls.detailHeader}>
          {I18n.t(`district_config.district.labels.${snakeCase(prop)}`)}
        </div>
        <div>{fieldVal(district[prop] || "--")}</div>
      </Box>
    );
  };

  if (state.loading) {
    return <Spinner />;
  }

  if (
    isRestrictedAdmin &&
    district &&
    (!user?.with_districts || !user.school_ids.some((sid) => district.schoolIds.includes(sid)))
  ) {
    return <Redirect to={pageRoutes.districts()} />;
  }

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("district_config.title")} />
      <DistrictConfigTabs />
      <BreadCrumbs
        path={pageRoutes.districts()}
        label={I18n.t("district_config.districts.breadcrumb")}
      />
      {district && (
        <>
          <Box className={cls.titleWithBtn}>
            <Typography variant="h1" gutterBottom>
              {district.name}
            </Typography>
            <Box className={cls.btnsWrapper}>
              <RouterLink className={cls.actionBtn} to={pageRoutes.districtEdit(district.id)}>
                <OutlinedBtn>{I18n.t("district_config.edit_district.btn")}</OutlinedBtn>
              </RouterLink>
              {!isRestrictedAdmin && (
                <OutlinedBtn
                  disabled={true}
                  className={cls.actionBtn}
                  onClick={() => actions.toggleArchive(id)}
                >
                  {district.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
                </OutlinedBtn>
              )}
            </Box>
          </Box>
          <Box my={5}>
            <Paper className={cls.paper}>
              <Box className={cls.subTitle}>{I18n.t("district_config.district.title.details")}</Box>
              <Grid container className={cls.grid} spacing={5}>
                <Grid item xs={6}>
                  {district && DETAIL_FIELDS.map(detail)}
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <DistrictAssociations district={district} />
        </>
      )}
    </React.Fragment>
  );
};

export default District;
