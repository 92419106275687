import React from "react";
import { Box, Typography } from "@mui/material";
import { DayOffChip } from "components/admin_v2/ui/DayOffLabel";
import DaySelector from "components/admin_v2/common/DaySelector";
import { formatHumanDate } from "lib/dates";

const ChangeReqEffectiveDate = ({ requestStore, changeReq, isUserAdmin, cls }) => {
  const [state, actions] = requestStore;
  const isDisabled = state.resolved || !isUserAdmin || state.isUnenroll;

  const onSelectDate = (date) => actions.updateEffectiveDate(date);

  return (
    <Box display="flex">
      {isDisabled ? (
        <Typography variant="subtitle1" className={cls.label}>
          {formatHumanDate(changeReq?.start_date)}
        </Typography>
      ) : (
        <>
          <DaySelector
            date={state.effectiveDate}
            onSelectDate={onSelectDate}
            skipWeekends={true}
            minDate={state.minDate}
          />
          {state.trip?.day_off && <DayOffChip />}
        </>
      )}
    </Box>
  );
};

export default ChangeReqEffectiveDate;
