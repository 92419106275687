import React, { Fragment, useCallback } from "react";
import memoize from "fast-memoize";
import { useLocalStore } from "easy-peasy";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  Button,
  FormHelperText
} from "@mui/material";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import TableActionCell from "./TableActionCell";
import TableHeaders from "./TableHeaders";
import { Check as CheckIcon } from "@mui/icons-material";
import { searchAddress } from "services/apiAddresses";
import { strPresent, commaErrors } from "lib/util";
import { clsTable, useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import CollapsibleSection from "./CollapsibleSection";
import useSorting from "lib/useSorting";
import addressesStore from "./stores/addressesStore";

const HEADERS = [
  { field: "primary", label: "Primary" },
  { field: "address", label: "Address", required: true },
  { field: "name", label: "Name", required: true },
  { field: "lat", label: "Lat" },
  { field: "lng", label: "Lon" },
  { field: "timezone", label: "Timezone" },
  { field: "editMode", label: "Actions" }
];

const AddressRow = ({ data, idx, formStore, cls, withTz, schoolId, updateField, updateSelect }) => {
  const isEditMode = formStore[0].isEditMode(idx);
  const { entity, errors } = data;
  const { clsPrimary, clsSelect, clsCell } = clsTable(cls, isEditMode);
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell className={clsPrimary} key={`addr-${idx}-primary`}>
        {isEditMode ? (
          <>
            <GreenCheckbox
              checked={entity.primary}
              onChange={updateField(idx, "primary")}
              className={cls.check}
            />
            {errors?.primary && (
              <FormHelperText error>{commaErrors(errors?.primary)}</FormHelperText>
            )}
          </>
        ) : (
          entity.primary && <CheckIcon />
        )}
      </TableCell>
      <TableCell className={clsSelect} key={`addr-${idx}-address`}>
        {isEditMode ? (
          <>
            <AutocompleteSelect
              id={`addr-${idx}-address-input`}
              getOptions={searchAddress}
              onOptionSelect={updateSelect(idx)}
              defaultSchool={"none"}
              searchParams={{ school_id: schoolId, withTz }}
              labelField={"description"}
              defaultValue={entity.address || ""}
              placeholder="Address"
              maxWidth="100%"
            />
            {errors?.address && (
              <FormHelperText error>{commaErrors(errors?.address)}</FormHelperText>
            )}
          </>
        ) : (
          entity.address
        )}
      </TableCell>
      <TableCell className={clsCell} key={`addr-${idx}-name`}>
        {isEditMode ? (
          <TextField
            variant="standard"
            id={`addr-${idx}-name-input`}
            label={<RequiredLabel label="Name" condition={strPresent(entity.name)} />}
            value={entity.name || ""}
            onChange={updateField(idx, "name")}
            className={cls.field}
            error={!!errors?.name}
            helperText={commaErrors(errors?.name)}
          />
        ) : (
          entity.name
        )}
      </TableCell>
      <TableCell className={clsCell} key={`addr-${idx}-lat`}>
        {isEditMode ? (
          <TextField
            variant="standard"
            id={`addr-${idx}-lat-input`}
            label="Lat"
            value={entity.lat || ""}
            onChange={updateField(idx, "lat")}
            className={cls.field}
            type="number"
            inputProps={{ step: "any" }}
            error={!!errors?.lat}
            helperText={commaErrors(errors?.lat)}
          />
        ) : (
          entity.lat
        )}
      </TableCell>
      <TableCell className={clsCell} key={`addr-${idx}-lng`}>
        {isEditMode ? (
          <TextField
            variant="standard"
            id={`addr-${idx}-lng-input`}
            label="Lon"
            value={entity.lng || ""}
            onChange={updateField(idx, "lng")}
            className={cls.field}
            type="number"
            inputProps={{ step: "any" }}
            error={!!errors?.lng}
            helperText={commaErrors(errors?.lng)}
          />
        ) : (
          entity.lng
        )}
      </TableCell>
      {withTz && (
        <TableCell className={clsCell} key={`addr-${idx}-time-zone`}>
          <Typography variant="body2" className={isEditMode ? cls.marginTop : ""}>
            {entity.timezone}
          </Typography>
        </TableCell>
      )}
      <TableActionCell store={formStore} idx={idx} />
    </TableRow>
  );
};

const AddressesForm = ({
  addresses,
  parentEntity,
  collapsible = false,
  schoolId = null,
  addressRequired = true,
  withTz = false
}) => {
  const cls = useTableFormStyles();
  const formStore = useLocalStore(() =>
    addressesStore({ data: addresses, noDataAllowed: !addressRequired, parentEntity })
  );
  const [state, actions] = formStore;
  const { sortableColumn } = useSorting({
    field: "primary",
    order: "asc",
    callback: actions.sortBy
  });

  const updateField = useCallback(
    memoize((idx, field) => (e) => {
      let value = e.target.value;
      if (field === "twilio" || field === "primary") {
        value = e.target.checked;
      }
      actions.updateRowField({ idx, field, value });
    }),
    []
  );

  const updateSelect = useCallback(
    memoize((idx) => (data) => {
      const value = data ? data.description : null;
      actions.updateRowField({ idx, field: "address", value });
    }),
    []
  );

  if (!state.data) return;

  const rows = state.data.map((data, idx) => (
    <AddressRow
      key={`addr-${idx}-row`}
      idx={idx}
      formStore={formStore}
      cls={cls}
      data={data}
      schoolId={schoolId}
      withTz={withTz}
      updateField={updateField}
      updateSelect={updateSelect}
    />
  ));

  const tableHeaders = (withTz ? HEADERS : HEADERS.filter((h) => h.field !== "timezone")).map(
    sortableColumn
  );

  const table = (
    <>
      <Table
        className={`${cls.table} ${parentEntity.type.toLowerCase()}-addresses`}
        aria-label="table"
      >
        <TableHead>
          <TableRow>
            <TableHeaders headers={tableHeaders} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <Button className={cls.btn} variant="contained" color="secondary" onClick={actions.addRow}>
        New Address
      </Button>
    </>
  );

  return collapsible ? (
    <CollapsibleSection title="addresses" count={state.dataPersisted.length}>
      {table}
    </CollapsibleSection>
  ) : (
    table
  );
};

export default AddressesForm;
