import React from "react";
import { Link } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ReactRouterLinkV6, useLocation } from "react-router-dom-v5-compat";
import { useHistory } from "react-router";
import { useStoreActions, useStoreState } from "easy-peasy";

const RouterLink = (props) => {
  const { search } = useLocation();
  const { schoolId, to, disabled = false, v6 = false, withQuery = false, ...rest } = props;

  if (disabled) {
    return props.children;
  }

  return schoolId ? (
    <RouterLinkWithSchool {...props} />
  ) : (
    <Link
      to={withQuery ? to + search : to}
      {...rest}
      component={v6 ? ReactRouterLinkV6 : ReactRouterLink}
      underline="hover"
    />
  );
};

const RouterLinkWithSchool = (props) => {
  const { schoolId, component, onClick, ...rest } = props;
  const { school } = useStoreState((s) => s.app);
  const { changeSchool } = useStoreActions((s) => s.app);
  const history = useHistory();

  if ((!schoolId || schoolId === school?.id) && !component) {
    return <Link {...rest} component={ReactRouterLink} underline="hover" />;
  }

  const onLinkClick = (e) => {
    e.preventDefault();
    if (onClick) onClick();
    changeSchool(schoolId).then(() => {
      // redirect after changing school
      history.push(rest.to);
    });
  };

  const LinkComponent = component || Link;
  return <LinkComponent {...rest} component={ReactRouterLink} onClick={onLinkClick} />;
};

export default RouterLink;
