import React from "react";
import Box from "@mui/material/Box";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import cn from "classnames";
import { tripColor, tripTz } from "./tripHelpers";

const ActualTime = ({ type, trip, cls }) => {
  if (!["pickup", "dropoff"].includes(type)) return null;

  const tripActualTime = type === "pickup" ? trip.actual_pickup : trip.actual_dropoff;
  const tripActualEta = type === "pickup" ? trip.pickup_eta : trip.dropoff_eta;
  const timeCls = cn(cls?.cellContent, { [`${tripColor(trip).name}`]: !tripActualTime });
  const showTime = tripActualTime || (trip.last_position && !trip.ended_at);

  return showTime ? (
    <Box component="span" className={timeCls}>
      {tripActualTime || tripActualEta}
      {tripTz(trip.time_zone_abbr)}
      {!tripActualTime && <FiberManualRecordIcon className={`icon ${cls?.etaIcon}`} />}
    </Box>
  ) : (
    "-"
  );
};

export default ActualTime;
