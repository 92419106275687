import React, { useCallback } from "react";
import classNames from "classnames";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Link,
  List,
  ListItem,
  Collapse
} from "@mui/material";
import { useStoreState, useLocalStore } from "easy-peasy";
import useSorting from "lib/useSorting";
import useDidMountEffect from "lib/useDidMountEffect";
import { formatTimeWithTz } from "lib/dates";
import CollapsibleSection from "components/admin_v2/forms/CollapsibleSection";
import TableHeaders from "components/admin_v2/forms/TableHeaders";
import { TableActionEdit } from "components/admin_v2/forms/TableActionCell";
import DaysDeltaTimesDialog from "./DaysDeltaTimesDialog";
import { useTableFormStyles, clsTable } from "components/admin_v2/common/useTableFormStyles";
import DayDeltaOccurrencesTable from "./DayDeltaOccurrencesTable";
import DaysDeltaFilters from "./DaysDeltaFilters";
import I18n from "utils/i18n.js";
import daysDeltaStore, { OFF_TYPE } from "../stores/daysDeltaStore";

const I18N_KEY = "school_config.day_delta.form";

const HEADERS = [
  { field: "adjustmentType", label: I18n.t(`${I18N_KEY}.adjustment_type.label`), required: true },
  { field: "name", label: I18n.t(`${I18N_KEY}.name.label`), required: true, skipSorting: true },
  { field: "delta", label: I18n.t(`${I18N_KEY}.delta.label`), required: true },
  {
    field: "startDate",
    label: I18n.t(`${I18N_KEY}.days.label`),
    required: true
  },
  {
    field: "occurrence",
    label: I18n.t(`${I18N_KEY}.occurrence.label`),
    skipSorting: true
  },
  { field: "comment", label: I18n.t(`${I18N_KEY}.comment.label`) },
  { field: "editMode", label: "Actions", skipSorting: true }
];

const CollapsibleData = ({ title, expanded, setExpanded, idx, children }) => {
  const toggleExpanded = useCallback(() => {
    setExpanded({ idx });
  }, [idx]);

  return (
    <>
      <div>
        <b>{title} </b>
        <Link component="button" onClick={toggleExpanded}>
          ({I18n.t(`school_config.day_delta.actions.${expanded ? "collapse" : "expand"}`)})
        </Link>
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </>
  );
};

const DayDeltaOccurrence = ({ formStore, idx }) => {
  const { isPastDate } = useStoreState((s) => s.app);
  const [state, actions] = formStore;
  const data = state.getRow(idx);
  const activeLength = data.entity.occurrences.filter(
    (d) => !isPastDate(state.parentEntity?.id, d)
  ).length;
  return (
    <CollapsibleData
      title={I18n.t("school_config.day_delta.occurrence.title", { count: activeLength })}
      expanded={data.expanded}
      setExpanded={actions.toggleExpanded}
      idx={idx}
    >
      <DayDeltaOccurrencesTable
        schoolId={state.parentEntity?.id}
        occurrences={data.entity.occurrences}
        styles={{ width: "100%" }}
      />
    </CollapsibleData>
  );
};

const DayDeltaRow = ({ data, idx, formStore, editCallback, cls }) => {
  const [state, _actions] = formStore;
  const timeZoneAbbr = state.parentEntity?.timeZoneAbbr;
  const { entity } = data;
  const { isPastDate } = useStoreState((s) => s.app);
  if (
    state.timesType !== "all" &&
    !entity.occurrences.some((d) => !isPastDate(state.parentEntity?.id, d))
  ) {
    return null;
  }
  const { clsMedium, clsCell } = clsTable(cls, false, { verticalAlign: "top" });
  const namesTimes = state.namesTimes(idx).map((t, i) => {
    const clsLi = classNames({ [cls.cellStrikeThrough]: !t.active });
    return (
      <ListItem key={`nt-${i}`} className={clsLi} sx={{ paddingTop: 0 }}>
        <Box>
          {t.name} (
          {I18n.t("school_config.day_delta.arrival.original", {
            time: formatTimeWithTz(t.originalTime, timeZoneAbbr)
          })}
          )
          {t.active ? (
            <span className={cls.cellActive}>
              {" "}
              (
              {I18n.t("school_config.day_delta.arrival.new", {
                time: formatTimeWithTz(t.newTime, timeZoneAbbr)
              })}
              )
            </span>
          ) : null}
        </Box>
      </ListItem>
    );
  });
  return (
    <TableRow hover tabIndex={-1} id={`dayDelta-${idx}-row`}>
      <TableCell className={classNames(cls.cellType, cls.clsView, cls.cellTop)}>
        {I18n.t(`school_config.day_delta.adjustment_type.${entity.adjustmentType}`)}
      </TableCell>
      <TableCell className={classNames(cls.cellLarge, cls.clsView, cls.cellTop)}>
        <List sx={{ paddingTop: 0 }}>{namesTimes}</List>
      </TableCell>
      <TableCell className={clsCell}>{entity.displayDelta}</TableCell>
      <TableCell className={clsCell}>{entity.days}</TableCell>
      <TableCell className={clsMedium}>
        {entity.description}
        {entity.offType === OFF_TYPE.singleDay ? null : (
          <DayDeltaOccurrence formStore={formStore} idx={idx} />
        )}
      </TableCell>
      <TableCell className={clsMedium}>{entity.comment}</TableCell>
      <TableActionEdit idx={idx} name="dayDelta" editCallback={editCallback} withoutTop={false} />
    </TableRow>
  );
};

const DaysDeltaForm = ({ daysDelta, schoolTimes, parentEntity, collapsible = true }) => {
  const cls = useTableFormStyles();
  const formStore = useLocalStore(() =>
    daysDeltaStore({
      data: daysDelta,
      schoolTimes,
      noDataAllowed: true,
      parentEntity
    })
  );
  const [state, actions] = formStore;
  const { sortBy, sortableColumn } = useSorting(
    {
      field: "startDate",
      order: "asc",
      callback: actions.sortBy
    },
    { app: "settings", page: "schoolDeltas" }
  );
  const { viewTimesOpen } = state;

  // NOTE: need to update data on school times or days delta changes
  // it should be possible to implement through dependencies to useLocalStore
  // but for unknown reason it's not working, maybe because of some caching and
  // passing formStore down to underlaying components
  useDidMountEffect(() => {
    actions.updateData({ daysDelta, schoolTimes });
    actions.sortBy(sortBy);
  }, [schoolTimes, daysDelta]);

  const viewTimes = useCallback((e) => {
    actions.toggleViewTimes({ open: true, idx: e.currentTarget.dataset.idx });
  }, []);

  const onCloseDialog = ({ refetch = false }) => {
    actions.toggleViewTimes({ open: false });
    if (refetch) state.parentEntity.refetch();
  };

  const tableHeaders = HEADERS.map(sortableColumn);

  const rows = state.data.map((data, idx) => (
    <DayDeltaRow
      key={`dayDelta-${idx}-row`}
      idx={idx}
      formStore={formStore}
      cls={cls}
      data={data}
      editCallback={viewTimes}
    />
  ));

  const table = (
    <>
      <DaysDeltaFilters formStore={formStore} />
      <Table
        className={`${cls.table} ${parentEntity.type.toLowerCase()}-dayDelta`}
        id="school-deltaTimes"
        aria-label="table"
      >
        <TableHead>
          <TableRow>
            <TableHeaders headers={tableHeaders} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <Button
        data-idx={-1}
        className={cls.btn}
        variant="contained"
        color="secondary"
        onClick={viewTimes}
      >
        {I18n.t("school_config.new_day_delta.btn")}
      </Button>
      {viewTimesOpen && (
        <DaysDeltaTimesDialog
          open={viewTimes}
          formStore={formStore}
          onCloseDialog={onCloseDialog}
        />
      )}
    </>
  );

  return collapsible ? (
    <CollapsibleSection title="daysDelta" count={state.dataPersisted.length}>
      {table}
    </CollapsibleSection>
  ) : (
    table
  );
};

export default DaysDeltaForm;
