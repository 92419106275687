import { useEffect } from "react";

const useMessageBus = ({ channel, received, condition, dependencies = [] }) => {
  useEffect(() => {
    if (!condition) return;

    MessageBus.subscribe(channel, (resp) => {
      received(JSON.parse(resp));
    });

    return () => {
      MessageBus.unsubscribe(channel);
    };
  }, [condition, ...dependencies]);

  return;
};

export default useMessageBus;
