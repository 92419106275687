import React from "react";
import { Typography } from "@mui/material";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import VendorForm from "components/admin_v2/vendor_config/vendors/VendorForm";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";

const NEW_VENDOR = {
  provider: "drivers"
};

const VendorNew = (_props) => {
  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      <BreadCrumbs path={pageRoutes.vendors()} label={I18n.t("vendor_config.vendors.breadcrumb")} />
      <Typography variant="h1" gutterBottom>
        {I18n.t("vendor_config.new_vendor.title")}
      </Typography>
      <VendorForm vendor={NEW_VENDOR} />
    </React.Fragment>
  );
};

export default VendorNew;
