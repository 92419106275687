import React from "react";
import cn from "classnames";
import { Select, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import I18n from "utils/i18n.js";

const STATUS_BG_CLS = {
  alert: "white-bg",
  on_time: "green-bg",
  arrived: "blue-bg",
  not_started: "grey-bg",
  all_late: "red-bg"
};

const TripsStatusFilter = ({ state, updateQueryString }) => {
  const cls = useStyles();

  const idFor = (label) => `status-${label}`;

  const updateStatus = (e) => {
    updateQueryString({ status: e.target.value });
  };

  return (
    <Select
      className={cls.statusSelect}
      labelId={idFor("label")}
      id={idFor("select")}
      value={state.status || ""}
      onChange={updateStatus}
      disabled={state.loading}
      displayEmpty
    >
      <MenuItem key={idFor("all")} value="">
        <Typography variant="body2">{I18n.t("trip.avl_labels.status")}</Typography>
      </MenuItem>
      {state.statusesMapping.map(({ id, name }) => (
        <MenuItem key={idFor(id)} value={id} className={cls.statusOption}>
          {id !== "all_late" ? (
            <span className={cn(STATUS_BG_CLS[id], cls.statusOptLabel, cls.fullOpt)}>&nbsp;</span>
          ) : (
            <>
              <span className={cn("yellow-bg", cls.statusOptLabel, cls.halfOption, cls.topHalf)}>
                &nbsp;
              </span>
              <span className={cn("red-bg", cls.statusOptLabel, cls.halfOption, cls.bottomHalf)}>
                &nbsp;
              </span>
            </>
          )}
          {}
          <span className={cls.statusLabel}>{name}</span>
        </MenuItem>
      ))}
    </Select>
  );
};

export default TripsStatusFilter;

const useStyles = makeStyles((theme) => ({
  statusSelect: {
    marginLeft: theme.spacing(2),
    minWidth: theme.spacing(25)
  },
  statusOption: {
    position: "relative"
  },
  statusOptLabel: {
    minWidth: theme.spacing(2.5),
    minHeight: "100%",
    position: "absolute",
    borderRight: `1px solid ${theme.custom.DARK_GREY_2}`,
    borderTop: `1px solid ${theme.custom.DARK_GREY_2}`
  },
  fullOpt: {
    top: "0",
    left: "0",
    bottom: "0"
  },
  statusLabel: {
    marginLeft: theme.spacing(2.5)
  },
  halfOption: {
    height: "50%",
    minHeight: "50%",
    borderTop: theme.spacing(0)
  },
  topHalf: {
    top: "0",
    left: "0"
  },
  bottomHalf: {
    bottom: "0",
    left: "0"
  }
}));
