import React, { Fragment, useState } from "react";
import { useStoreState } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import { Divider } from "@mui/material";
import { DropdownTrips } from "components/admin_v2/common/CustomTripsBtns";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import ErrorList from "components/admin_v2/ui/ErrorList";
import CustomTrip from "./trips/CustomTrip";
import I18n from "utils/i18n.js";

const CustomTrips = ({ modeChangeStore, onSave }) => {
  const cls = useStyles();
  const { getSchool } = useStoreState((s) => s.app);
  const [openExtraTrips, setOpenExtraTrips] = useState(false);
  const [state, _actions] = modeChangeStore;
  const trips = state.trips;
  const countTrips = trips.length;

  if (countTrips === 0 || !state.calendar) return null;

  const school = getSchool(state.student.school_id);
  const extraTrips = trips.map((trip, idx) => (
    <CustomTrip
      key={`ctrip-${idx}`}
      trip={trip}
      idx={idx}
      school={school}
      modeChangeStore={modeChangeStore}
    />
  ));

  return (
    <Fragment>
      <Divider className={cls.separator} />
      {openExtraTrips && (
        <Fragment>
          <ErrorList errors={state.errors} />
          {extraTrips}
          <PrimaryBtn onClick={onSave} disabled={state.isDisabled}>
            {I18n.t("ui.btn.save")}
          </PrimaryBtn>
          <Divider className={cls.separator} />
        </Fragment>
      )}
      <DropdownTrips
        countTrips={countTrips}
        openExtraTrips={openExtraTrips}
        setOpenExtraTrips={setOpenExtraTrips}
      />
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  separator: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}));

export default CustomTrips;
