import { action, thunk, computed } from "easy-peasy";
import { pick } from "lodash";
import { formatDateUrl } from "lib/dates";
import { easyStateSetters } from "lib/easyState";
import { submitVendorSchedule as apiSubmitVendorSchedule } from "services/apiVendorSchedules";
import { submitVendorSchedule as vendorsApiSubmitVendorSchedule } from "services/vendorsApiVendorSchedules";
import {
  fetchRoute as apiFetchRoute,
  fetchVendorSchedulesInfo as apiFetchVendorSchedulesInfo,
  fetchFutureOneDayAssignments as apiFetchFutureOneDayAssignments
} from "services/apiRoutes";
import {
  fetchRoute as vendorsApiFetchRoute,
  fetchVendorSchedulesInfo as vendorsApiFetchVendorSchedulesInfo,
  fetchFutureOneDayAssignments as vendorsApiFetchFutureOneDayAssignments
} from "services/vendorsApiRoutes";
import { updateTrip as apiUpdateTrip } from "services/apiTrips";
import { setFlashMessage as setAdminFlashMessage } from "services";
import { setFlashMessage as setVendorFlashMessage } from "services/vendorsFlashMessage";
import { simulateTracking } from "services/apiTrips";

const DEFAULT_FORM = {
  transportationVendorId: null,
  inboundVehicleId: null,
  outboundVehicleId: null,
  startDate: new Date(),
  permanent: null,
  note: null,
  applyInbound: false,
  applyOutbound: false,
  applyInboundPair: true,
  applyOutboundPair: true
};
const SCHEDULE_FIELDS = [
  "transportationVendorId",
  "inboundVehicleId",
  "outboundVehicleId",
  "permanent",
  "applyInboundPair",
  "applyOutboundPair",
  "note"
];
const OPTS_FIELDS = ["startDate", "applyInbound", "applyOutbound"];

export const defaultState = {
  // status
  loading: false,
  bookingEdit: false,
  // data
  route: null,
  inboundVehicle: null,
  outboundVehicle: null,
  schedulesUpdatedAt: null,
  schedulesUpdatedBy: null,
  futureAssignmentsWarning: false,
  // form
  form: DEFAULT_FORM,
  bookingId: null,
  note: "",
  errors: {},
  options: {
    transportation_vendors: [],
    vehicles: []
  }
};

export const vendorScheduleDialogStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),

  isValid: computed((state) => {
    return !!state.form?.startDate && state.form.permanent !== null;
  }),

  provider: computed((state) => {
    return state.options.transportation_vendors.find(
      (v) => v.id === state.form?.transportationVendorId
    )?.provider;
  }),

  resetForm: action((state) => {
    state.form = defaultState.form;
    state.note = "";
  }),

  initializeForm: action((state, defaultFields) => {
    state.form = { ...DEFAULT_FORM, ...defaultFields };
    state.loading = false;
  }),

  fetchRoute: thunk((actions, { routeId, page, date }) => {
    const fetchFn = page === "vendors_dashboard" ? vendorsApiFetchRoute : apiFetchRoute;
    fetchFn(routeId, { withPairs: true, date: date }).then((resp) => actions.setRoute(resp));
  }),

  updateForm: thunk((actions, { field, evt }, h) => {
    const form = h.getState().form;
    let value;
    if (field === "startDate") {
      value = evt;
    } else if (["applyInbound", "applyOutbound"].includes(field)) {
      value = evt.target.checked;
    } else if (["applyInboundPair", "applyOutboundPair"].includes(field)) {
      value = evt.target.value === "true";
    } else if (field === "permanent") {
      value = evt.target.value === "true";
    } else if (evt) {
      value = evt.target ? evt.target.value : evt.id;
    } else {
      value = null;
    }

    let params = { [field]: value };
    if (field === "transportationVendorId") {
      params["inboundVehicleId"] = null;
      params["outboundVehicleId"] = null;
    }
    if (field === "permanent" && value === true) {
      params["applyInboundPair"] = true;
      params["applyOutboundPair"] = true;
    }
    if (field === "permanent" && value === false) {
      params["applyInboundPair"] = false;
      params["applyOutboundPair"] = false;
    }

    actions.setForm({ ...form, ...params });
  }),

  submitBookingId: thunk((actions, { tripId, page }, h) => {
    const bookingId = h.getState().bookingId;
    const setFlashMessage =
      page === "vendors_dashboard" ? setVendorFlashMessage : setAdminFlashMessage;

    return apiUpdateTrip(tripId, { bookingId: bookingId })
      .then((r) => {
        setFlashMessage(r.message);
        actions.setBookingEdit(false);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  submit: thunk((actions, { routeId, onSuccess, page, date }, h) => {
    const state = h.getState();
    const setFlashMessage =
      page === "vendors_dashboard" ? setVendorFlashMessage : setAdminFlashMessage;
    const submitFn =
      page === "vendors_dashboard" ? vendorsApiSubmitVendorSchedule : apiSubmitVendorSchedule;
    const { form } = state;
    const scheduleParams = pick(form, SCHEDULE_FIELDS);
    let opts = pick(form, OPTS_FIELDS);
    opts.startDate = formatDateUrl(opts.startDate);

    if (!state.isValid) {
      setFlashMessage("Please fill out all required fields");
      return;
    }

    actions.setLoading(true);
    return submitFn(routeId, scheduleParams, opts, { date: date })
      .then((r) => {
        setFlashMessage(r.message);
        actions.resetForm();
        onSuccess();
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  }),

  submitTrackingForm: thunk((actions, tripId, _h) => {
    simulateTracking(tripId, { delay: -3 })
      .then((resp) => {
        setAdminFlashMessage(resp.message);
      })
      .catch((err) => {
        setAdminFlashMessage(err.message);
      });
  }),

  fetchFutureAssignments: thunk((actions, { page }, h) => {
    const state = h.getState();
    const route = state.route;
    const date = state.form.startDate;

    const fetchFn =
      page === "vendors_dashboard"
        ? vendorsApiFetchFutureOneDayAssignments
        : apiFetchFutureOneDayAssignments;

    fetchFn(route.id, { date: formatDateUrl(date) }).then((resp) => {
      actions.setFutureAssignmentsWarning(resp);
    });
  }),

  fetchSchedulesUpdateInfo: thunk((actions, { page, date }, h) => {
    const route = h.getState().route;
    const fetchFn =
      page === "vendors_dashboard"
        ? vendorsApiFetchVendorSchedulesInfo
        : apiFetchVendorSchedulesInfo;
    const setFlashMessage =
      page === "vendors_dashboard" ? setVendorFlashMessage : setAdminFlashMessage;

    fetchFn(route.id, { date: date })
      .then((resp) => {
        actions.setSchedulesUpdatedAt(resp.last_updated_at);
        actions.setSchedulesUpdatedBy(resp.last_updated_by);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  })
});
