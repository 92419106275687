import React from "react";
import { useHistory } from "react-router";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { pageRoutes } from "services/api";
import enrollmentStudentStore from "./stores/enrollmentStudentStore";
import { useStyles } from "./UnenrollStudent";
import I18n from "utils/i18n.js";

const ReenrollStudent = ({ student }) => {
  const cls = useStyles();
  const history = useHistory();
  const { isUserAdmin } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [state, actions] = useLocalStore(() => enrollmentStudentStore({ student }));

  if (!isUserAdmin) return null;

  const submit = () => {
    actions
      .reenroll()
      .then((_) => {
        history.push(pageRoutes.students());
        setFlashMessage({
          message: I18n.t("student.reenroll.success", { student: student.name })
        });
      })
      .catch((r) =>
        setFlashMessage({
          message: r.message || I18n.t("student.reenroll.failure", { student: student.name })
        })
      );
  };

  return (
    <OutlinedBtn
      className={cls.unenrollBtn}
      onClick={submit}
      disabled={state.processing || !student.discarded}
    >
      {I18n.t(`students.reenroll.btn.${student.riderType}`)}
    </OutlinedBtn>
  );
};

export default ReenrollStudent;
