import React, { Fragment, useCallback, useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import { get, omit, isString } from "lodash";
import { isSameDay } from "date-fns";
import ErrorList from "components/admin_v2/ui/ErrorList";
import CreateModeForm from "../modes/CreateModeForm";
import ChangeToExistingStopForm from "../modes/ChangeToExistingStopForm";
import CreateNewStopForm from "../modes/CreateNewStopForm";
import CreateNewRouteForm from "../modes/CreateNewRouteForm";
import CreateOneOffRoute from "../modes/CreateOneOffRoute";
import CreateNewOneoffStopForm from "../modes/CreateNewOneoffStopForm";
import ChangeToExistingOneoffStopForm from "../modes/ChangeToExistingOneoffStopForm";
import RequestNewStopForm from "../modes/RequestNewStopForm";
import RouteOptionsChoice from "components/admin_v2/students/trip/route/RouteOptionsChoice";
import RoutePeriodSelect from "components/admin_v2/students/trip/route/RoutePeriodSelect";
import useStyles from "components/admin_v2/students/trip/useStyles";
import { addTripStore } from "components/admin_v2/students/stores/addTripStore";
import { defaultTripEditData } from "../stores/defaultData";
import { TRIP_MODE_TYPES } from "../stores/modeChangeStore";
import { getStopType } from "components/admin_v2/students/stores/stopsModel";
import AddNote from "../common/AddNote";
import ModeChangeSelect from "../common/ModeChangeSelect";
import { toDate } from "lib/dates";
import I18n from "utils/i18n.js";

// modes:
// change_mode: Create mode (i.e. PPU, walk)
// change_to_existing_stop: Change to an existing stop
// create_new_stop_on_route: Create a new stop on an existing route
// create_new_route: Create a new route
// create_one_off_route: Create a one-off route
// change_to_existing_oneoff_stop: Change to an existing stop on a oneoff route
// create_new_oneoff_stop: Change to a new stop on a oneoff route
// request_new_stop: Request a new stop
const TRIP_MODE_COMPONENTS = {
  [TRIP_MODE_TYPES.change_mode]: CreateModeForm,
  [TRIP_MODE_TYPES.change_to_existing_stop]: ChangeToExistingStopForm,
  [TRIP_MODE_TYPES.create_new_stop_on_route]: CreateNewStopForm,
  [TRIP_MODE_TYPES.create_new_route]: CreateNewRouteForm,
  [TRIP_MODE_TYPES.create_one_off_route]: CreateOneOffRoute,
  [TRIP_MODE_TYPES.create_new_oneoff_stop]: CreateNewOneoffStopForm,
  [TRIP_MODE_TYPES.change_to_existing_oneoff_stop]: ChangeToExistingOneoffStopForm,
  [TRIP_MODE_TYPES.request_new_stop]: RequestNewStopForm
};

// not non riding
const ONE_DAY_CHANGE_TYPES = ["bus"];

const TripModeEditor = ({ modeChangeStore, route, tripType, idx }) => {
  const [modeChangeState, modeChangeActions, store] = modeChangeStore;
  const isOneOff = modeChangeState.isOneOff(idx);
  const tripDetails = modeChangeState.getDetails(idx);
  const defaultData = () =>
    defaultTripEditData({
      date: modeChangeState.currentDate,
      school: modeChangeState.school,
      student: modeChangeState.student,
      rideType: route?.riders_type,
      isAdmin: modeChangeState.isRequestMode,
      route,
      tripType,
      isNewStudent: modeChangeState.isNewStudent,
      changeMode: tripDetails.changeMode,
      isOneOff
    });
  // initial data
  const tripEditStore = useLocalStore(() => addTripStore(defaultData()), [tripDetails.changeMode]);
  const [state, _actions, tripStore] = tripEditStore;
  const isDisabled =
    modeChangeState.isRequestMode && tripDetails.changeRequest
      ? state.isChangeDisabled
      : state.isDisabled;
  const cls = useStyles();

  const onSubmit = useCallback(
    (params) => {
      const details = store.getState().getDetails(idx);
      const withChangeRequest = store.getState().isRequestMode && details.changeRequest;
      const newParams = omit(params, "eventType");
      newParams.submitType = withChangeRequest ? "change" : "assign";
      newParams.eventTypes = {
        [tripType]: [
          TRIP_MODE_TYPES.create_one_off_route,
          TRIP_MODE_TYPES.change_to_existing_oneoff_stop,
          TRIP_MODE_TYPES.create_new_oneoff_stop
        ].includes(details.changeMode)
          ? "one_off"
          : params.eventType
      };
      // need to set this otherwise trip type on route creation for custom route will be direct
      newParams.keepTripType = true;
      const tripActions = tripStore.getActions();
      return tripActions
        .submit(newParams)
        .catch((err) => {
          let errors = get(
            err.response,
            "data.errors",
            get(err.response, "data.message", {
              base: I18n.t("ui.mode_editor.message.fail_trip")
            })
          );
          if (isString(errors)) errors = { "": errors };
          tripActions.setErrors(errors);
          throw err;
        })
        .finally(() => tripActions.setLoading(false));
    },
    [tripStore]
  );

  useEffect(() => {
    modeChangeActions.updateTripDetails({ idx, action: onSubmit });
  }, [onSubmit]);

  // update without change request in case of 1-day change for specific verhicles or to non riding
  useEffect(() => {
    if (!modeChangeState.isRequestMode) return;
    let isChangeSubmittable =
      (state.route?.id &&
        ONE_DAY_CHANGE_TYPES.includes(state.route.vehicle_type) &&
        isSameDay(toDate(state.period.endDate), toDate(state.period.startDate))) ||
      (state.route?.id && state.route.non_riding_type);
    modeChangeActions.updateTripDetails({
      idx,
      changeRequest: modeChangeState.isAdminChangeRequest || isChangeSubmittable !== true
    });
  }, [state.route?.id, state.period.endDate]);

  useEffect(() => {
    modeChangeActions.updateTripDetails({ idx, isDisabled });
  }, [isDisabled]);

  const onDirectionChange = (newTripType) => {
    if (tripDetails.isAddTrip) modeChangeActions.updateTrip({ idx, trip_type: newTripType });
  };

  const ModeComponent = tripDetails.changeMode
    ? TRIP_MODE_COMPONENTS[tripDetails.changeMode]
    : null;

  return (
    <Fragment>
      <ErrorList errors={state.getTopLevelErrors} />
      <ModeChangeSelect idx={idx} modeChangeStore={modeChangeStore} />
      {tripDetails.changeMode && tripDetails.changeMode === state.changeMode && (
        <>
          {tripDetails.isAddTrip && (
            <RouteOptionsChoice
              store={tripEditStore}
              onDirectionChange={onDirectionChange}
              withDirections={tripDetails.isAddTrip}
              isNewStop={tripDetails.changeMode === TRIP_MODE_TYPES.request_new_stop}
              cls={cls}
            />
          )}
          <ModeComponent
            store={tripEditStore}
            stopType={getStopType(tripType)}
            tripType={tripType}
            cls={cls}
            tripIdx={idx}
            trip={modeChangeState.getTripData(idx)}
            tripDetails={tripDetails}
            updateTripDetails={modeChangeActions.updateTripDetails}
          />
          {![
            TRIP_MODE_TYPES.create_one_off_route,
            TRIP_MODE_TYPES.change_to_existing_oneoff_stop,
            TRIP_MODE_TYPES.create_new_oneoff_stop,
            TRIP_MODE_TYPES.create_new_route
          ].includes(tripDetails.changeMode) && (
            <RoutePeriodSelect
              store={tripEditStore}
              cls={cls}
              withOwnLabel={false}
              changeRequest={{ status: true, isNewStudent: modeChangeState.isNewStudent, route }}
            />
          )}
        </>
      )}
      {tripDetails?.changeRequest && <AddNote idx={idx} modeChangeStore={modeChangeStore} />}
    </Fragment>
  );
};

export default TripModeEditor;
