import { computed, thunk } from "easy-peasy";
import { pick } from "lodash";
import { camelizeKeys } from "humps";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";
import { createCommsGroup, saveCommsGroup } from "services/apiCommsGroups";
import { fetchContactOptions } from "services/apiSchools";
import groupContactsStore from "./groupContactsStore";
import phoneNumbersStore from "components/admin_v2/common/stores/phoneNumbersStore";
import { setFlashMessage } from "services";

const REQUIRED = ["name", "replyType"];

export const commsGroupFormStore = (group) => {
  const details = {
    ...pick(group, [
      "id",
      "name",
      "replyType",
      "schoolId",
      "avlNotifications",
      "reserved",
      "preventAutoReplies"
    ])
  };

  return {
    ...easyState("group", group),
    ...easyState("details", details),
    ...easyState("contactOptions", []),
    ...easyState("errors"),
    ...easyState("loading", false),

    ...phoneNumbersStore(group?.phoneNumbers),
    ...groupContactsStore(group?.groupContacts),

    isValid: computed((state) => {
      const { details } = state;
      const validDetails = REQUIRED.every((field) => strPresent(details[field]?.toString()));
      const validPhones = state.isValidPhoneNumbers({ noPhoneAllowed: true });
      return validDetails && validPhones;
    }),

    fetchContactOptions: thunk((actions, schoolId, _h) => {
      if (!schoolId) {
        actions.setContactOptions([]);
      } else {
        fetchContactOptions(schoolId)
          .then((r) => actions.setContactOptions(r.contacts))
          .catch((err) => setFlashMessage(err.message));
      }
    }),

    formData: computed((state) => {
      return {
        group: {
          ...state.details,
          phoneNumbersAttributes: state.phoneNumbersAttributes,
          groupContactsAttributes: state.groupContactsAttributes
        }
      };
    }),

    save: thunk(async (actions, _payload, h) => {
      const state = h.getState();
      if (!state.isValid) return;

      actions.setErrors(null);
      actions.setLoading(true);
      const data = state.formData;

      try {
        const group = state.group;

        if (group.id) {
          return await saveCommsGroup(group.id, data);
        } else {
          return await createCommsGroup(data);
        }
      } catch (err) {
        const errors = err.response?.data?.errors;
        actions.setErrors(camelizeKeys(errors));
        throw err;
      } finally {
        actions.setLoading(false);
      }
    })
  };
};
