import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Box, Button, Link, TableCell, Typography } from "@mui/material";
import { ArrowRightRounded, ArrowDropDownRounded, Group, EditOutlined } from "@mui/icons-material";
import { useStoreState } from "easy-peasy";
import classNames from "classnames";
import { values, startsWith } from "lodash";
import { randKey, simplePluralize } from "lib/util";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";
import RiderType from "components/shared/RiderType";
import useStyles from "./useStyles";
import I18n from "utils/i18n.js";
import { tripTz } from "components/admin_v2/trips/tripHelpers";

const checkEvents = (student) => values(student?.events || []).indexOf(true) > -1;
const len = (arr) => (arr ? arr.length : 0);
const studentSign = (type) => (type === "pickup" ? "+ " : "- ");

const ScheduleCell = ({
  data,
  stopAnchor,
  capacity,
  counter,
  expandAll,
  selectedDay,
  onStopSelect
}) => {
  const cls = useStyles();
  const {
    date,
    stop,
    students,
    pickup_students,
    dropoff_students,
    add_events,
    _counters,
    filtered,
    activities
  } = data;
  const isSelected = date === selectedDay;
  const [expanded, setExpanded] = useState({ pickup: true, dropoff: true });
  const isDayDisabled = useStoreState((s) => s.routeEditor.isDayDisabled(date));
  const activeStudents = students.filter((s) => !s.student.day_off);
  const activeAddEvents = add_events.filter((s) => !s.student.day_off);

  let activePickupStudents = pickup_students.filter((s) => !s.student.day_off);
  let activeDropoffStudents = dropoff_students.filter((s) => !s.student.day_off);
  if (!stopAnchor && stop && startsWith(stop.trip_type, "to")) {
    activePickupStudents = [...activePickupStudents, ...activeAddEvents];
  } else if (!stopAnchor && stop && startsWith(stop.trip_type, "from")) {
    activeDropoffStudents = [...activeDropoffStudents, ...activeAddEvents];
  }

  const clsColumn = classNames({ [cls.selectedCol]: isSelected });
  const clsStop = classNames({
    [cls.timeBox]: stop,
    [cls.time]: stop && !isDayDisabled,
    [cls.dayDisabled]: isDayDisabled && stop,
    [cls.emptyTime]: !stop,
    [cls.withEvents]: activeStudents.some(checkEvents) || activeAddEvents.length
  });

  const onStopLinkClick = () => {
    if (stop) onStopSelect({ date, stop, students, add_events, activities });
  };

  const handleExpandClick = (type) => {
    setExpanded({ ...expanded, [type]: !expanded[type] });
  };

  useEffect(() => {
    if (expandAll) {
      setExpanded({ pickup: true, dropoff: true });
    } else {
      setExpanded({ pickup: false, dropoff: false });
    }
  }, [expandAll]);

  const studentsList = (type, students) => {
    return (
      <>
        <Box
          display="flex"
          className={cn(cls.nowrap, cls.studentsListLink)}
          onClick={() => handleExpandClick(type)}
        >
          {expanded[type] ? (
            <ArrowDropDownRounded className="caret" />
          ) : (
            <ArrowRightRounded className="caret" />
          )}
          {I18n.t(`ui.students.${type}`, {
            students: simplePluralize(len(students), "Student")
          })}
        </Box>
        {expanded[type] && (
          <Box ml={2}>
            {students.map(({ student, events }) => (
              <Typography
                key={student.id}
                variant="body2"
                className={
                  student.day_off || checkEvents({ student, events }) ? cls.strikedThrough : ""
                }
              >
                {studentSign(type)}
                {student.name}
                <RiderType rider={student} />
                {withoutAccommodation(student) ? null : (
                  <StudentAccommodationPopup student={student} marginLeft={1} fontSize="inherit" />
                )}
              </Typography>
            ))}
          </Box>
        )}
      </>
    );
  };

  const editStudents =
    stop && !stopAnchor ? (
      <Link
        variant="body2"
        component="button"
        className={cls.stopLink}
        onClick={onStopLinkClick}
        underline="hover"
        data-test-id="students-list-dialog"
      >
        <EditOutlined fontSize="small" />
      </Link>
    ) : (
      <Box className={cls.editPlaceholder} />
    );

  return (
    <TableCell
      key={`stop-${stop ? stop.id : randKey()}`}
      component="td"
      scope="row"
      align="center"
      className={clsColumn}
    >
      <Box className={cls.stopContent}>
        <Box className={cls.mainStopInfo}>
          <Button className={clsStop} variant="contained" size="small">
            {stop ? stop.display_time : ""}
            {tripTz(stop?.time_zone_abbr)}
          </Button>
          {!isDayDisabled && !isSelected && (
            <Box display="flex">
              <Box>
                {activePickupStudents.length > 0 && (
                  <Box>
                    {I18n.t("ui.students.pickup", {
                      students: simplePluralize(len(activePickupStudents), "Student")
                    })}
                  </Box>
                )}
                {activeDropoffStudents.length > 0 && (
                  <Box>
                    {I18n.t("ui.students.dropoff", {
                      students: simplePluralize(len(activeDropoffStudents), "Student")
                    })}
                  </Box>
                )}
              </Box>
              {editStudents}
            </Box>
          )}
          {isSelected && (
            <Box display="flex">
              <Group fontSize="small" />
              <Box
                ml={1}
                title={`Total riders when leaving this stop: ${counter}  /  Vehicle Capacity: ${capacity}`}
              >
                {`${counter}/${capacity}`}
              </Box>
            </Box>
          )}
        </Box>
        {isSelected && !isDayDisabled && (
          <>
            <Box className={cls.stopStudentsInfo}>
              {activePickupStudents.length > 0 && studentsList("pickup", activePickupStudents)}
              {activeDropoffStudents.length > 0 && studentsList("dropoff", activeDropoffStudents)}
              {filtered.regular > 0 && (
                <Typography key="st-filtered-in" variant="body2">
                  <b>
                    {I18n.t("ui.students.pickup", {
                      students: simplePluralize(filtered.regular, "Student")
                    })}
                  </b>
                </Typography>
              )}
              {filtered.terminus > 0 && (
                <Typography key="st-filtered-in" variant="body2">
                  <b>
                    {I18n.t("ui.students.dropoff", {
                      students: simplePluralize(filtered.terminus, "Student")
                    })}
                  </b>
                </Typography>
              )}
            </Box>
          </>
        )}
        {isSelected && !isDayDisabled && editStudents}
      </Box>
    </TableCell>
  );
};

export default ScheduleCell;
