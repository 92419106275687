import React from "react";
import { FormControl, FormLabel } from "@mui/material";
import RouteSelect from "components/admin_v2/students/trip/route/RouteSelect";
import RouteAnchorInfo from "components/admin_v2/students/trip/route/RouteAnchorInfo";
import AddRouteStopForm from "../route/AddRouteStopForm";
import I18n from "utils/i18n.js";

const CreateNewOneoffStopForm = ({ store, tripType, stopType, cls, children }) => {
  const [state, _actions] = store;

  return (
    <>
      <FormControl required fullWidth>
        <FormLabel className={cls.label}>
          {I18n.t(`ui.mode_editor.form.existing_stop.${stopType}`)}
        </FormLabel>
        <RouteSelect
          store={store}
          extraParams={{ riders_type: state.rideType, only_riding: true, oneoff: true }}
        />
        {children}
      </FormControl>
      {state.route?.id && (
        <>
          <AddRouteStopForm
            tripType={tripType}
            stopType={stopType}
            store={store}
            cls={cls}
            withoutChoose={true}
          />
          <RouteAnchorInfo store={store} cls={cls} tripType={tripType} />
        </>
      )}
    </>
  );
};

export default CreateNewOneoffStopForm;
