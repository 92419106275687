import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchStudents = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`students?${query}`), { transformData: true });
};

export const getStudent = async (id, params = {}) => {
  const query = queryString(params);
  const url = apiUrl(`students/${id}/profile?${query}`);
  return await xhr.get(url, { transformData: true });
};

export const saveStudent = async (id, data, school) => {
  return await xhr.post(apiUrl(`students/${id}`), { school_id: school?.id, student: data });
};

export const getStudentCalendar = async (id, params = {}) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`students/${id}/calendar?${query}`));
};

export const getStudentCalendarDay = async (id, date, params = { withCustom: false }) => {
  const query = queryString({ date, ...params }, true);
  return await xhr.get(apiUrl(`students/${id}/calendar_day?${query}`));
};

export const saveStudentRouteEvent = async (params) => {
  return await xhr.post(apiUrl(`students/${params.id}/route_event`), params);
};

export const saveQuickStudentEvent = async (id, params) => {
  return await xhr.post(apiUrl(`students/${id}/quick_event`), params);
};

export const createStudent = (params, schoolId) => {
  return xhr.post(apiUrl("students"), { school_id: schoolId, student: params });
};

export const assignStudent = async (params = {}) => {
  const url = apiUrl(`students/${params.id}/add_schedule`);
  return await xhr.post(url, params, { transformData: true });
};

export const reenrollStudent = async (params = {}) => {
  const url = apiUrl(`students/${params.studentId}/reenroll`);
  return await xhr.post(url, params);
};

export const reenrollStudents = async (students = []) => {
  const url = apiUrl(`students/reenroll`);
  return await xhr.post(url, { students }, { transformData: true });
};

export const unenrollStudent = async (params = {}) => {
  const url = apiUrl(`students/${params.studentId}/unenroll`);
  return await xhr.post(url, params);
};

export const unenrollStudents = async (students = []) => {
  const url = apiUrl(`students/unenroll`);
  return await xhr.post(url, { students }, { transformData: true });
};

export const searchStudents = async (params) => {
  const query = queryString(params);
  return await xhr.get(
    apiUrl(`students/search?${query}${params.withFullProfile ? "&full=true" : ""}`)
  );
};
