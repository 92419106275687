import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import memoize from "fast-memoize";
import { parseISO } from "date-fns";
import { useLocalStore, useStoreActions } from "easy-peasy";
import {
  Dialog,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  TextField,
  MenuItem
} from "@mui/material";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActionsBothSides
} from "components/admin_v2/ui/FormDialog";
import { DangerBtn } from "components/admin_v2/ui/Buttons";
import ErrorList from "components/admin_v2/ui/ErrorList";
import Spinner from "components/admin_v2/ui/Spinner";
import FormDatePicker from "components/admin_v2/ui/FormDatePicker";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import { commaErrors } from "lib/util";
import DayDeltaTimesTable from "./DayDeltaTimesTable";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import useFormStyles from "components/admin_v2/students/trip/useStyles";
import useTesting from "lib/useTesting";
import dayDeltaTimesStore from "../stores/dayDeltaTimesStore";
import { OFF_TYPE, ADJUSTMENT_TYPE, DELTA_TYPE } from "../stores/daysDeltaStore";
import DayDeltaOccurrencesTable from "./DayDeltaOccurrencesTable";
import I18n from "utils/i18n.js";

const I18N_KEY = "school_config.day_delta.popup";

const DaysDeltaTimesDialog = ({ formStore, onCloseDialog }) => {
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [stateDayDelta, actionsDayDelta] = formStore;
  const store = useLocalStore(() =>
    dayDeltaTimesStore({
      dayDelta: stateDayDelta.getViewDayDelta,
      schoolTimes: stateDayDelta.schoolTimes,
      parentEntity: stateDayDelta.parentEntity
    })
  );
  const [state, actions] = store;
  const { dayDelta, errors, parentEntity } = state;
  const cls = useCommonPageStyles();
  const clsForm = useFormStyles();

  useTesting({
    "dayDeltaTime:setStartDate": (value) => {
      actions.updateDayDelta({ field: "startDate", value });
      actions.recalculateStatuses();
    }
  });

  useEffect(() => {
    actions.initData();
  }, []);

  const closeDialog = () => onCloseDialog({ open: false });

  const onSubmit = () => {
    actions
      .save()
      .then((r) => {
        setFlashMessage({ message: r.message });
        onCloseDialog({ open: false, refetch: true });
      })
      .catch((err) =>
        setFlashMessage({
          message:
            err.response?.data?.message ||
            I18n.t("data_management.errors.fail", { name: "Early Release/Late Start" })
        })
      );
  };

  const updateField = useCallback(
    memoize((field) => (e) => {
      let value = ["startDate", "endDate", "time"].includes(field) ? e : e.target.value;
      actions.updateDayDelta({ field, value });
      if (field === "adjustmentType") actions.initData();
      else actions.recalculateStatuses();
    }),
    []
  );

  const onOccurrenceRemove = (e) => {
    if (window.confirm("Are you sure?")) {
      const params = { id: dayDelta.id, excludedDates: [e.currentTarget.dataset.idx] };
      actions
        .removeOccurrence(params)
        .then((data) =>
          actions.updateDayDelta({
            field: "occurrences",
            value: data.dayDelta.occurrences.map((d) => parseISO(d))
          })
        )
        .catch(() => {});
    }
  };

  const onRemove = () => {
    if (window.confirm("Are you sure?")) {
      const params = { idx: stateDayDelta.viewIndex };
      actionsDayDelta
        .remove(params)
        .then(() => onCloseDialog({ open: false }))
        .catch(() => {});
    }
  };

  return (
    <Dialog open={true} maxWidth="md" onClose={closeDialog} fullWidth={true} className={cls.dialog}>
      <FormDialogTitle onClose={closeDialog}>
        <Typography variant="h1">
          {I18n.t(`${I18N_KEY}.title.${state.dayDelta.id ? "edit" : "new"}`)}
        </Typography>
      </FormDialogTitle>
      <FormDialogContent className={classNames(cls.dialogContWoXPaddding, cls.dialogCont)}>
        <ErrorList errors={state.errors} />
        {state.loading ? (
          <Spinner />
        ) : (
          <Grid container className={cls.grid} spacing={2}>
            <Grid item xs={4}>
              <FormControl className={cls.field} error={!!errors?.adjustmentType} required>
                <FormLabel className={clsForm.label}>
                  {I18n.t(`${I18N_KEY}.form.adjustment_type.label`)}
                </FormLabel>
                <Select
                  labelId="dd-adjustmentType-label"
                  id="dd-adjustmentType"
                  value={dayDelta.adjustmentType || ""}
                  onChange={updateField("adjustmentType")}
                  disabled={!!dayDelta.id}
                >
                  <MenuItem key={`adj-0`} value="">
                    {" "}
                  </MenuItem>
                  {Object.entries(ADJUSTMENT_TYPE).map(([_key, a]) => (
                    <MenuItem key={`adj-${a}`} id={`adj-${a}`} value={a}>
                      {I18n.t(`school_config.day_delta.adjustment_type.${a}`)}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.adjustmentType && (
                  <FormHelperText>{commaErrors(errors.adjustmentType)}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel className={clsForm.label} required>
                {I18n.t(`${I18N_KEY}.form.date.label`)}
              </FormLabel>
              <Grid container item spacing={4}>
                <Grid item xs="auto">
                  <FormDatePicker
                    date={dayDelta.startDate}
                    name="dd-startDate"
                    onChange={updateField("startDate")}
                    disabled={dayDelta.id && state.isPast}
                    errors={errors?.startDate}
                  />
                  {dayDelta.offType === OFF_TYPE.interval ? (
                    <>
                      <Typography variant="subtitle1">to</Typography>
                      <FormDatePicker
                        date={dayDelta.endDate}
                        name="dd-endDate"
                        onChange={updateField("endDate")}
                        errors={errors?.endDate}
                      />
                    </>
                  ) : null}
                </Grid>
                <Grid item xs="auto">
                  <FormControl className={cls.field} error={!!errors?.offType} variant="standard">
                    <Select
                      labelId="dd-offType-label"
                      id="dd-offType"
                      value={dayDelta.offType || ""}
                      onChange={updateField("offType")}
                    >
                      {Object.entries(OFF_TYPE).map(([_key, o]) => (
                        <MenuItem key={`offType-${o}`} id={`offType-${o}`} value={o}>
                          {I18n.t(`${I18N_KEY}.form.off_type.${o}`, {
                            day: state.getDay
                          })}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors?.offType && (
                      <FormHelperText>{commaErrors(errors.offType)}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormLabel className={clsForm.label} required>
                {I18n.t(`${I18N_KEY}.form.delta_type.label`)}
              </FormLabel>
              <Grid container item spacing={4}>
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <GreenRadio
                        checked={dayDelta.deltaType === DELTA_TYPE.time}
                        onChange={updateField("deltaType")}
                        value={DELTA_TYPE.time}
                        name="dd-deltaType-time"
                      />
                    }
                    label={I18n.t(`${I18N_KEY}.form.delta_type.time`)}
                  />
                  <TimePickerInput
                    id={`dd-time`}
                    dateTime={dayDelta.time || ""}
                    disabled={dayDelta.deltaType !== DELTA_TYPE.time}
                    onChange={updateField("time")}
                  />
                  {errors?.time && (
                    <FormHelperText error>{commaErrors(errors.time)}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <GreenRadio
                        checked={dayDelta.deltaType === DELTA_TYPE.delta}
                        onChange={updateField("deltaType")}
                        value={DELTA_TYPE.delta}
                        name="dd-deltaType-delta"
                      />
                    }
                    label={I18n.t(`${I18N_KEY}.form.delta_type.delta`)}
                  />
                  <Grid container item spacing={2} alignItems="center">
                    <Grid item xs="auto">
                      <FormControl>
                        <OutlinedInput
                          id="dd-delta-hours"
                          value={dayDelta.deltaHours}
                          onChange={updateField("deltaHours")}
                          endAdornment={<InputAdornment position="end">hours</InputAdornment>}
                          className={clsForm.numberInput}
                          disabled={dayDelta.deltaType !== DELTA_TYPE.delta}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            "aria-label": "hours"
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs="auto">
                      <FormControl>
                        <OutlinedInput
                          id="dd-delta-minutes"
                          value={dayDelta.deltaMinutes}
                          onChange={updateField("deltaMinutes")}
                          endAdornment={<InputAdornment position="end">minutes</InputAdornment>}
                          className={clsForm.numberInput}
                          disabled={dayDelta.deltaType !== DELTA_TYPE.delta}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            "aria-label": "minutes"
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs="auto">
                      {I18n.t(`${I18N_KEY}.form.delta_type.${dayDelta.adjustmentType}`)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormLabel className={clsForm.label}>
                {I18n.t(`${I18N_KEY}.form.school_delta_times.label`)}
              </FormLabel>
              <DayDeltaTimesTable store={store} />
            </Grid>
            {dayDelta.id ? (
              <Grid item xs={12} my={3}>
                <DayDeltaOccurrencesTable
                  schoolId={parentEntity.id}
                  occurrences={dayDelta.occurrences}
                  removeCallback={onOccurrenceRemove}
                  styles={{ width: "auto" }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={8}>
              <FormControl fullWidth>
                <FormLabel className={clsForm.label}>
                  {I18n.t(`${I18N_KEY}.form.comment.label`)}
                </FormLabel>
                <TextField
                  variant="outlined"
                  multiline
                  rows={2}
                  className={cls.comment}
                  value={dayDelta.comment}
                  onChange={updateField("comment")}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </FormDialogContent>
      <FormDialogActionsBothSides
        submitLabel={dayDelta.id ? null : I18n.t(`${I18N_KEY}.actions.create`)}
        onSubmit={onSubmit}
        onClose={closeDialog}
        disabled={state.isDisabled}
      >
        {dayDelta.id ? (
          <DangerBtn onClick={onRemove} disabled={state.isPast}>
            {I18n.t(`${I18N_KEY}.actions.delete`)}
          </DangerBtn>
        ) : null}
      </FormDialogActionsBothSides>
    </Dialog>
  );
};

export default DaysDeltaTimesDialog;
