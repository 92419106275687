import React from "react";
import { FormControl, FormLabel } from "@mui/material";
import { isEmpty } from "lodash";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import StopNewForm from "components/admin_v2/students/trip/stop/StopNewForm";

const AddRouteStopForm = ({ store, cls, stopType, tripType, withoutChoose = false }) => {
  const [state, _actions] = store;
  const { errors, errorStop } = state.getStopErrorsFor(tripType, stopType);

  return (
    <FormControl className={cls.withTopMargin} fullWidth error={!isEmpty(errors)} required>
      <ErrorList errors={errorStop} />
      <FormLabel className={cls.label}>
        {I18n.t(withoutChoose ? `ui.stop.create.${stopType}` : `ui.stop.${stopType}`)}
      </FormLabel>
      <StopNewForm
        store={store}
        cls={cls}
        stopType={stopType}
        tripType={tripType}
        withoutChoose={withoutChoose}
      />
    </FormControl>
  );
};

export default AddRouteStopForm;
