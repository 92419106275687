import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useStoreActions, useStoreState } from "easy-peasy";
import AddRouteDialog from "./AddRouteDialog";
import I18n from "utils/i18n.js";
import { pushToAvl } from "services/apiRoutes";
import { NON_RIDING_TYPES } from "utils/constants";

const AvlPushAlert = ({ route }) => {
  const cls = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const [addRouteDialogOpen, setAddRouteDialogOpen] = useState(false);
  const { isUserAdmin } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);

  useEffect(() => {
    if (NON_RIDING_TYPES.includes(route.vehicle_type)) return;

    setShowAlert(true);
  }, [route?.id]);

  if (!showAlert) return null;

  const toggleAddRouteDialog = () => {
    setAddRouteDialogOpen((d) => !d);
  };

  const toggleShowAlert = () => {
    setShowAlert((a) => !a);
  };

  const routeAvlPush = () => {
    pushToAvl(route.id)
      .then((r) => {
        setFlashMessage({ message: r.message });
        toggleShowAlert();
      })
      .catch((err) => {
        setFlashMessage({ message: err.message });
      });
  };

  const addNewRoute = () => {
    toggleAddRouteDialog();
  };

  return (
    <Box className={cls.avlPushWrapper}>
      <Typography variant="body1" className={cls.linksWrapper}>
        <span className={cls.alertMsg}>{I18n.t("ui.route.actions.created")}</span>
        <Link onClick={routeAvlPush} className={cls.alertLink} underline="always">
          {I18n.t("ui.route.push_to_avl")}
        </Link>
        <span>|</span>
        <Link onClick={addNewRoute} className={cls.alertLink} underline="always">
          {I18n.t("ui.route.create_another")}
        </Link>
      </Typography>
      <Link onClick={toggleShowAlert} className={cls.closeBtn} underline="hover">
        <CloseIcon fontSize="large" />
      </Link>
      {isUserAdmin ? (
        <AddRouteDialog open={addRouteDialogOpen} toggleDialog={toggleAddRouteDialog} />
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  avlPushWrapper: {
    backgroundColor: theme.custom.YELLOW,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    display: "flex",
    alignItems: "center"
  },
  linksWrapper: {
    flex: 1
  },
  alertMsg: {
    fontWeight: theme.custom.BOLD,
    marginRight: theme.spacing(1)
  },
  alertLink: {
    color: theme.custom.DARK_GREY_1,
    cursor: "pointer",
    margin: theme.spacing(0, 1)
  },
  closeBtn: {
    cursor: "pointer",
    color: theme.custom.WHITE
  }
}));

export default AvlPushAlert;
