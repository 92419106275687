import { action, thunk, computed } from "easy-peasy";
import { assign, pick, orderBy } from "lodash";
import { parseISO, isWithinInterval, startOfDay, endOfDay } from "date-fns";
import { easyStateSetters } from "lib/easyState";
import {
  fetchVendorSchedules as apiFetchVendorSchedules,
  removeVendorSchedule as apiRemoveVendorSchedule
} from "services/apiVendorSchedules";
import { setFlashMessage } from "services";

export const defaultState = {
  // filters
  dateScope: "today",
  // status
  refreshVendorSchedules: false,
  schedulesLoading: false,
  vendorScheduleDialogOpen: false,
  noFallbackError: false,
  // data
  vendorSchedules: []
};

export const vendorSchedulesStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),

  anotherVendor: computed((state) => (schedule, direction) => {
    if (schedule.permanent) return false;

    return state.vendorSchedules.some(
      (vs) =>
        vs.id !== schedule.id &&
        vs.directions.includes(direction) &&
        vs[`${direction}Vehicle`] &&
        isWithinInterval(parseISO(schedule.startDate), {
          start: startOfDay(parseISO(vs.startDate)),
          end: endOfDay(parseISO(vs.endDate))
        })
    );
  }),

  setFromRouter: action((state, props) => {
    if (props.refresh) props.refresh = !state.refresh;
    assign(state, props);
  }),

  fetchVendorSchedules: thunk((actions, params, h) => {
    actions.setSchedulesLoading(true);
    const stateParams = pick(h.getState(), ["dateScope"]);

    return apiFetchVendorSchedules({ ...params, ...stateParams })
      .then((r) => actions.setData({ data: r, sortBy: params.sortBy }))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setSchedulesLoading(false));
  }),

  setData: action((state, { data, sortBy }) => {
    state.vendorSchedules = orderBy(data.vendorSchedules, sortBy.field, sortBy.order);
    state.noFallbackError = false;
  }),

  deleteSchedule: thunk((actions, id, h) => {
    const state = h.getState();

    return apiRemoveVendorSchedule(id)
      .then((r) => {
        setFlashMessage(r.message);
        actions.setRefreshVendorSchedules(!state.refreshVendorSchedules);
      })
      .catch((err) => {
        setFlashMessage(err.message);
        actions.setNoFallbackError(true);
      });
  }),

  sortBy: action((state, sortBy) => {
    const { field, order } = sortBy;
    state.vendorSchedules = orderBy(state.vendorSchedules, field, order);
  })
});
