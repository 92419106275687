import React from "react";
import { map } from "lodash";
import { makeStyles } from "@mui/styles";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import { TRIP_TYPE_FILTER_OPTIONS } from "utils/constants";
import I18n from "utils/i18n.js";

const TripTypeRadioFilter = ({ selected, onChange, disabled = false }) => {
  const typeOptionsRadio = map(TRIP_TYPE_FILTER_OPTIONS, (opt) => (
    <FormControlLabel
      value={opt}
      key={`filter-${opt}`}
      control={<GreenRadio />}
      label={I18n.t(`ui.curbside.filter.${opt}`)}
      disabled={disabled}
    />
  ));

  return (
    <RadioGroup value={selected} onChange={onChange} row>
      {typeOptionsRadio}
    </RadioGroup>
  );
};

export const useStyles = makeStyles((_theme) => ({}));

export default TripTypeRadioFilter;
