import React, { Fragment, useMemo } from "react";
import cn from "classnames";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Link, Grid, Typography, Divider, Checkbox } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GroupIcon from "@mui/icons-material/Group";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NumberChip from "components/admin_v2/ui/NumberChip";
import OverflowTooltip from "components/admin_v2/ui/OverflowTooltip";
import RouterLink from "components/admin_v2/nav/RouterLink";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";
import TripStop from "./TripStop";
import { setPersistedFilter } from "lib/persistedFilter";

const Trip = ({ tripInfo, onClose, tripsStore }) => {
  const cls = useStyles();
  const [state, actions] = tripsStore;
  const trip = tripInfo.trip;

  const toggleHideStops = () => {
    actions.setShowCompletedStops(!state.showCompletedStops);
  };

  const toggleShowWaypoints = () => {
    actions.setShowWaypoints(!state.showWaypoints);
  };

  const toggleExpandAll = () => {
    actions.setCollapseAllStudents(!state.collapseAllStudents);
    setPersistedFilter({
      app: "avl",
      page: "map",
      filter: "collapseAllStudents",
      value: !state.collapseAllStudents
    });
  };

  const visibleStops = useMemo(() => {
    return state.showCompletedStops
      ? tripInfo.stops
      : tripInfo.stops.filter((s) => !trip.stops_info[s.id]?.completed);
  }, [state.showCompletedStops, tripInfo]);

  return (
    <Fragment>
      <Box display="flex">
        <CloseIcon onClick={() => onClose(null)} className={cls.closeBtn} />
      </Box>
      <Box className={cls.tripHeader}>
        <Box flex="1" className={cls.headerItemWrapper}>
          <ArrowBackIosIcon
            size="large"
            onClick={() => actions.selectTripAround({ tripId: trip.id })}
            className={cls.arrowLink}
          />
          <OverflowTooltip text={trip.route_name} wrapperCls={cls.routeLinkWrapper}>
            <RouterLink
              to={pageRoutes.route(trip.route_id, trip.school_slug)}
              target="_blank"
              underline="always"
            >
              <>
                {trip.route_name}
                {trip.vendor && <span className={cls.routeNameVendor}>({trip.vendor})</span>}
              </>
            </RouterLink>
          </OverflowTooltip>
          <ArrowForwardIosIcon
            size="large"
            onClick={() => actions.selectTripAround({ tripId: trip.id, next: true })}
            className={cls.arrowLink}
          />
        </Box>
        <Box className={cls.headerItemWrapper}>
          <GroupIcon size="large" />
          <div>{trip.riders_count}</div>
        </Box>
      </Box>
      <Divider variant="fullWidth" className={cls.separator} />
      <Grid container className={cls.controls}>
        <Grid item className={cls.leftChip}>
          <Checkbox
            checked={state.showWaypoints}
            onChange={toggleShowWaypoints}
            style={{ padding: "0px" }}
          />
        </Grid>
        <Grid item className={cls.stopInfoCenter}>
          <Typography variant="body2">{I18n.t("ui.avl.show_waypoints")}</Typography>
        </Grid>
      </Grid>
      <Grid container className={cn(cls.stopsItem, cls.stopsItemLine)}>
        <Grid item className={cls.leftChip}>
          <Link
            underline="none"
            color="secondary"
            onClick={toggleHideStops}
            className={cls.toggleStops}
          >
            <NumberChip className={cls.stopChip}>
              <KeyboardArrowDownIcon />
            </NumberChip>
          </Link>
        </Grid>
        <Grid item>
          <Link
            underline="none"
            color="secondary"
            onClick={toggleHideStops}
            className={cls.toggleStops}
          >
            <Typography variant="body2">
              {state.showCompletedStops
                ? I18n.t("ui.avl.hide_completed_stops")
                : I18n.t("ui.avl.show_completed_stops")}
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid container className={cn(cls.stopsItem, cls.stopsItemLine)}>
        <Grid item className={cls.leftChip}></Grid>
        <Grid item>
          <Link
            underline="none"
            color="primary"
            onClick={toggleExpandAll}
            className={cls.toggleCollapse}
          >
            {"Expand All / Collapse All Students"}
          </Link>
        </Grid>
      </Grid>
      {visibleStops.map((stop) => (
        <TripStop
          tripInfo={tripInfo}
          stop={stop}
          tripsStore={tripsStore}
          key={`stop-${stop.id}`}
          cls={cls}
        />
      ))}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  tripHeader: {
    verticalAlign: "middle",
    height: theme.spacing(7),
    padding: theme.spacing(2, 1),
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  separator: {
    margin: "auto",
    width: "95%",
    marginBottom: theme.spacing(2)
  },
  closeBtn: {
    cursor: "pointer",
    color: theme.custom.DARK_GREY_2,
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  stopsItem: {
    padding: theme.spacing(0, 1, 2, 1),
    position: "relative",
    "& .MuiGrid-item": {
      overflow: "hidden !important"
    },
    "&:before": {
      borderLeft: `${theme.spacing(0.25)} dashed ${theme.custom.DARK_GREY_2}`,
      width: theme.spacing(0.25),
      display: "block",
      content: "''",
      position: "absolute",
      marginLeft: theme.spacing(1.5),
      height: "100%"
    },
    "&:last-child:before": {
      display: "none"
    }
  },
  stopsItemLine: {
    "&:before": {
      borderLeft: `${theme.spacing(0.25)} solid ${theme.custom.DARK_GREY_2}`
    }
  },
  toggleStops: {
    cursor: "pointer"
  },
  toggleCollapse: {
    cursor: "pointer",
    marginTop: theme.spacing(2)
  },
  leftChip: {
    width: "15%",
    color: theme.custom.DARK_GREY_2,
    backgroundColor: theme.custom.MEDIUM_GREY_1
  },
  mainInfo: {
    width: "50%"
  },
  stopInfoCenter: {
    display: "flex",
    alignItems: "center",
    color: theme.custom.DARK_GREY_2
  },
  studentsInfo: {
    width: "10%",
    textAlign: "center",
    fontSize: "0.8rem"
  },
  timeInfo: {
    width: "15%",
    fontWeight: theme.custom.BOLD
  },
  stopChip: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: theme.custom.DARK_GREY_2,
    background: theme.custom.WHITE,
    border: `solid 2px ${theme.custom.DARK_GREY_2}`
  },
  boldInfo: {
    color: theme.custom.DARK_GREY_2,
    fontWeight: theme.custom.BOLD
  },
  missedLabel: {
    color: theme.custom.DARK_GREY_2
  },
  studentsIcon: {
    fontSize: "14px",
    color: theme.custom.MEDIUM_GREY_1
  },
  schoolChip: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: theme.custom.BLUE,
    color: theme.custom.WHITE,
    border: `solid 2px ${theme.custom.WHITE}`
  },
  striked: {
    textDecoration: "line-through"
  },
  timeDiff: {
    fontWeight: theme.custom.BOLD
  },
  vehicleChip: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: theme.custom.YELLOW,
    color: theme.custom.DARK_GREY_1,
    border: `solid 2px ${theme.custom.WHITE}`,
    fontWeight: theme.custom.BOLD
  },
  controls: {
    padding: theme.spacing(0, 1, 2, 1)
  },
  headerItemWrapper: {
    fontWeight: theme.custom.BOLD,
    backgroundColor: theme.custom.LIGHT_GREY_2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: theme.spacing(1),
    padding: theme.spacing(3, 2),
    maxWidth: "80%",
    borderRadius: theme.spacing(0.5)
  },
  routeLinkWrapper: {
    display: "inline-block",
    width: "70%",
    maxWidth: "70%",
    verticalAlign: "bottom",
    cursor: "pointer"
  },
  routeNameVendor: {
    fontWeight: 400,
    marginLeft: "8px"
  },
  arrowLink: {
    cursor: "pointer",
    color: theme.custom.DARK_GREY_2
  },
  missed: {
    backgroundColor: theme.custom.DARK_GREY_2,
    color: theme.custom.WHITE,
    border: `solid 2px ${theme.custom.WHITE}`
  },
  onTime: {
    "&.statusLabel": {
      color: theme.custom.GREEN
    },
    "&.statusChip": {
      background: theme.custom.GREEN,
      color: theme.custom.DARK_GREY_1,
      border: `solid 2px ${theme.custom.WHITE}`
    }
  },
  late: {
    "&.statusLabel": {
      color: theme.custom.YELLOW
    },
    "&.statusChip": {
      background: theme.custom.YELLOW,
      color: theme.custom.DARK_GREY_1,
      border: `solid 2px ${theme.custom.WHITE}`
    }
  },
  veryLate: {
    "&.statusLabel": {
      color: theme.custom.RED
    },
    "&.statusChip": {
      background: theme.custom.RED,
      color: theme.custom.DARK_GREY_1,
      border: `solid 2px ${theme.custom.WHITE}`
    }
  },
  attentionChip: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(-0.5),
    left: theme.spacing(0.25),
    borderWidth: "1px",
    fontSize: "0.8em"
  },
  stopTz: {
    fontWeight: 600
  },
  studentsListLink: {
    color: theme.custom.BLUE,
    cursor: "pointer",
    fontWeight: theme.custom.BOLD,
    alignItems: "center"
  },
  studentsList: {
    marginLeft: theme.spacing(3)
  }
}));

export default Trip;
