import React, { Fragment, useState } from "react";
import { Box, Link } from "@mui/material";
import AddReturnDialog from "./AddReturnDialog";
import I18n from "utils/i18n.js";

const CreateReturnRoute = () => {
  const [open, setOpen] = useState(false);

  const toggleDialog = () => setOpen(!open);

  return (
    <Fragment>
      <Box mr={2} flexGrow={1}>
        <Link component="button" underline="always" onClick={toggleDialog}>
          {I18n.t("ui.route.details.create_return")}
        </Link>
      </Box>
      <AddReturnDialog open={open} toggleDialog={toggleDialog} />
    </Fragment>
  );
};

export default CreateReturnRoute;
