import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";
import { useStoreActions, useStoreState } from "easy-peasy";
import { isEmpty, flatMap, compact } from "lodash";
import {
  DISTRICT_ALL_SLUG,
  getDistrictSlugFromPath,
  getSchoolSlugFromPath,
  isUnscopedPath,
  isPagePart
} from "context/history";
import CreateReport from "./CreateReport";
import Curbside from "./Curbside";
import Dashboard from "./Dashboard";
import Districts from "./district_config/districts/Districts";
import District from "./district_config/districts/District";
import DistrictNew from "./district_config/districts/DistrictNew";
import DistrictEdit from "./district_config/districts/DistrictEdit";
import DismissalGroups from "./school_config/dismissals/DismissalGroups";
import DismissalGroup from "./school_config/dismissals/DismissalGroup";
import DismissalGroupEdit from "./school_config/dismissals/DismissalGroupEdit";
import CommsGroups from "./school_config/comms_config/CommsGroups";
import CommsGroupNew from "./school_config/comms_config/CommsGroupNew";
import CommsGroup from "./school_config/comms_config/CommsGroup";
import CommsGroupEdit from "./school_config/comms_config/CommsGroupEdit";
import Exports from "./data_management/Exports";
import Programs from "./settings_config/programs/Programs";
import Program from "./settings_config/programs/Program";
import ProgramEdit from "./settings_config/programs/ProgramEdit";
import Routes from "./Routes";
import RouteDetails from "./routes/RouteDetails";
import RouteEdit from "./routes/RouteEdit";
import RouteStops from "./routes/RouteStops";
import PairedRoutes from "./PairedRoutes";
import ReportFiles from "./reports/ReportFiles";
import Search from "./Search";
import Students from "./Students";
import StudentCalendar from "./students/StudentCalendar";
import StudentProfile from "./students/StudentProfile";
import StudentEdit from "./students/StudentEdit";
import StudentNew from "./students/StudentNew";
import StudentImports from "./data_management/StudentImports";
import RouteImports from "./data_management/RouteImports";
import RouteImport from "./data_management/RouteImport";
import StopLocations from "./data_management/StopLocations";
import StopLocation from "./data_management/StopLocation";
import StopLocationEdit from "./data_management/StopLocationEdit";
import Schools from "./school_config/schools/Schools";
import SchoolNew from "./school_config/schools/SchoolNew";
import SchoolEdit from "./school_config/schools/SchoolEdit";
import Staffs from "./school_config/staff/Staffs";
import Staff from "./school_config/staff/Staff";
import StaffEdit from "./school_config/staff/StaffEdit";
import StaffNew from "./school_config/staff/StaffNew";
import Trips from "./Trips";
import TextBlasts from "./data_management/TextBlasts";
import TextBlast from "./data_management/TextBlast";
import Vehicles from "./vendor_config/vehicles/Vehicles";
import Vehicle from "./vendor_config/vehicles/Vehicle";
import VehicleEdit from "./vendor_config/vehicles/VehicleEdit";
import Vendors from "./vendor_config/vendors/Vendors";
import Vendor from "./vendor_config/vendors/Vendor";
import VendorNew from "./vendor_config/vendors/VendorNew";
import VendorEdit from "./vendor_config/vendors/VendorEdit";
import VendorStaffs from "./vendor_config/staff/VendorStaffs";
import VendorStaff from "./vendor_config/staff/VendorStaff";
import VendorStaffEdit from "./vendor_config/staff/VendorStaffEdit";
import VendorStaffNew from "./vendor_config/staff/VendorStaffNew";
import Devices from "./vendor_config/tracking_devices/Devices";
import Users from "./settings_config/users/Users";
import User from "./settings_config/users/User";
import UserEdit from "./settings_config/users/UserEdit";
import UserNew from "./settings_config/users/UserNew";
import Queues from "./Queues";
import NewSession from "./NewSession";
import NewPassword from "./NewPassword";
import EditPassword from "./EditPassword";
import AdvancedSearch from "./AdvancedSearch";
// NOTE: uncomment when drivers pages need to be shown again
// import Drivers from "./vendor_config/drivers/Drivers";
// import Driver from "./vendor_config/drivers/Driver";
// import DriverEdit from "./vendor_config/drivers/DriverEdit";
// import DriverNew from "./vendor_config/drivers/DriverNew";

const ROUTE_SCOPES = {
  ALL: "all", // all scoped + unscoped
  UNSCOPED: "unscoped", // only unscoped, default value
  SCOPED: "scoped", // scoped, school is optional
  STRICT: "strict" // scoped, school is mandatory
};

const listScopedRoutes = (routes) => {
  const currentPaths = ["/admin/:district_slug", "/admin/:district_slug/:school_slug"];

  return flatMap(routes, (route) =>
    currentPaths.map((p) => {
      const routePath = `${p}${route.path}`;
      return (
        <CompatRoute exact path={routePath} key={`d(s)-${routePath}`} component={route.component} />
      );
    })
  );
};

const listStrictScopedRoutes = (routes) => {
  const currentPath = "/admin/:district_slug/:school_slug";

  return routes.map((route) => (
    <CompatRoute
      exact
      path={`${currentPath}${route.path}`}
      key={`ds-${route.path}`}
      component={route.component}
    />
  ));
};

const Page = (_props) => {
  const { user, district, districts, school, schools, readOnly } = useStoreState((s) => s.app);
  const { changeDistrict, changeSchool, setSchool, setDistrict } = useStoreActions((s) => s.app);

  // We need this to adjust the selected school and url slug correlation,
  // since we were losing the context on some specific flows
  const schoolSlug = getSchoolSlugFromPath();
  const districtSlug = getDistrictSlugFromPath();

  useEffect(() => {
    if (
      isEmpty(schools) ||
      (isEmpty(districts) && districtSlug !== DISTRICT_ALL_SLUG) ||
      isUnscopedPath()
    )
      return;

    const currentDistrict = districtSlug ? districts.find((s) => s.slug === districtSlug) : null;
    if (districtSlug && districtSlug !== DISTRICT_ALL_SLUG && !currentDistrict) {
      changeDistrict(null);
    } else if (currentDistrict?.id !== district?.id) {
      setDistrict(currentDistrict);
    }
    const currentSchool = schoolSlug ? schools.find((s) => s.slug === schoolSlug) : null;
    if (schoolSlug && !currentSchool && !isPagePart(schoolSlug)) {
      changeSchool(null);
    } else if (currentSchool?.id !== school?.id) {
      setSchool(currentSchool);
    }
  }, [schoolSlug, districtSlug, districts, schools]);

  // top level paths
  let routes = [
    { path: "/curbside", component: Curbside, scope: ROUTE_SCOPES.ALL },
    { path: "/students", component: Students, scope: ROUTE_SCOPES.SCOPED },
    { path: "/routes", component: Routes, scope: ROUTE_SCOPES.SCOPED },
    { path: "/reports", component: CreateReport, scope: ROUTE_SCOPES.ALL },
    { path: "/reports/files", component: ReportFiles, scope: ROUTE_SCOPES.ALL },
    { path: "/trips", component: Trips, scope: ROUTE_SCOPES.ALL },
    { path: "/queues", component: Queues, scope: ROUTE_SCOPES.SCOPED }
  ];

  const restricted = user?.restricted_access;
  if (user?.role === "admin") {
    routes.push(
      ...compact([
        { path: "/districts", component: Districts },
        { path: "/districts/new", component: DistrictNew },
        { path: "/districts/:id", component: District },
        { path: "/districts/:id/edit", component: DistrictEdit },
        { path: "/search", component: Search },
        { path: "/student_imports", component: StudentImports, scope: ROUTE_SCOPES.ALL },
        restricted ? null : { path: "/route_imports", component: RouteImports },
        restricted ? null : { path: "/route_imports/:id/conflicts", component: RouteImport },
        { path: "/text_blasts", component: TextBlasts, scope: ROUTE_SCOPES.ALL },
        { path: "/text_blasts/:id", component: TextBlast, scope: ROUTE_SCOPES.ALL },
        { path: "/stop_locations", component: StopLocations, scope: ROUTE_SCOPES.ALL },
        { path: "/stop_locations/:id", component: StopLocation, scope: ROUTE_SCOPES.ALL },
        { path: "/stop_locations/:id/edit", component: StopLocationEdit, scope: ROUTE_SCOPES.ALL },
        { path: "/dismissal_groups", component: DismissalGroups, scope: ROUTE_SCOPES.ALL },
        { path: "/dismissal_groups/:id", component: DismissalGroup, scope: ROUTE_SCOPES.ALL },
        {
          path: "/dismissal_groups/:id/edit",
          component: DismissalGroupEdit,
          scope: ROUTE_SCOPES.ALL
        },
        { path: "/communication_groups", component: CommsGroups, scope: ROUTE_SCOPES.ALL },
        { path: "/communication_groups/new", component: CommsGroupNew, scope: ROUTE_SCOPES.ALL },
        { path: "/communication_groups/:id", component: CommsGroup, scope: ROUTE_SCOPES.ALL },
        {
          path: "/communication_groups/:id/edit",
          component: CommsGroupEdit,
          scope: ROUTE_SCOPES.ALL
        },
        { path: "/schools", component: Schools },
        restricted ? null : { path: "/schools/new", component: SchoolNew },
        { path: "/schools/:id", component: SchoolEdit },
        { path: "/students/new", component: StudentNew },
        { path: "/users", component: Users, scope: ROUTE_SCOPES.ALL },
        restricted ? null : { path: "/users/new", component: UserNew, scope: ROUTE_SCOPES.ALL },
        { path: "/users/:id", component: User, scope: ROUTE_SCOPES.ALL },
        { path: "/users/:id/edit", component: UserEdit, scope: ROUTE_SCOPES.ALL },
        { path: "/school_staff", component: Staffs, scope: ROUTE_SCOPES.ALL },
        { path: "/school_staff/new", component: StaffNew, scope: ROUTE_SCOPES.ALL },
        { path: "/school_staff/:id", component: Staff, scope: ROUTE_SCOPES.ALL },
        { path: "/school_staff/:id/edit", component: StaffEdit, scope: ROUTE_SCOPES.ALL },
        { path: "/vehicles", component: Vehicles },
        { path: "/vehicles/:id", component: Vehicle },
        { path: "/vehicles/:id/edit", component: VehicleEdit },
        { path: "/vendors", component: Vendors },
        restricted ? null : { path: "/vendors/new", component: VendorNew },
        { path: "/vendors/:id", component: Vendor },
        restricted ? null : { path: "/vendors/:id/edit", component: VendorEdit },
        { path: "/vendor_staff", component: VendorStaffs },
        { path: "/vendor_staff/new", component: VendorStaffNew },
        { path: "/vendor_staff/:id", component: VendorStaff },
        { path: "/vendor_staff/:id/edit", component: VendorStaffEdit },
        { path: "/tracking_devices", component: Devices },
        { path: "/programs", component: Programs },
        { path: "/programs/:id", component: Program },
        { path: "/programs/:id/edit", component: ProgramEdit },
        { path: "/exports", component: Exports },
        { path: "/routes/paired", component: PairedRoutes }
        // NOTE: uncomment when drivers pages need to be shown again
        // { path: "/drivers", component: Drivers },
        // { path: "/drivers/new", component: DriverNew },
        // { path: "/drivers/:id", component: Driver },
        // { path: "/drivers/:id/edit", component: DriverEdit }
      ])
    );
  }

  // common (admin and school_staff) routes
  routes.push(...[{ path: "/advanced_search", component: AdvancedSearch }]);

  // scoped urls
  routes.push(
    ...[
      { path: "/students/:id", component: StudentCalendar, scope: ROUTE_SCOPES.STRICT },
      { path: "/students/:id/profile", component: StudentProfile, scope: ROUTE_SCOPES.STRICT },
      { path: "/routes/:id", component: RouteDetails, scope: ROUTE_SCOPES.SCOPED }
    ]
  );

  if (!readOnly) {
    routes.push(
      ...[
        { path: "/students/new", component: StudentNew, scope: ROUTE_SCOPES.SCOPED },
        { path: "/students/:id/edit", component: StudentEdit, scope: ROUTE_SCOPES.STRICT },
        { path: "/routes/:id/edit", component: RouteEdit, scope: ROUTE_SCOPES.SCOPED },
        { path: "/routes/:id/stops", component: RouteStops, scope: ROUTE_SCOPES.SCOPED }
      ]
    );
  }

  let unscopedRoutes = routes.filter(
    (route) =>
      !route.scope || route.scope === ROUTE_SCOPES.ALL || route.scope === ROUTE_SCOPES.UNSCOPED
  );
  // we need to have "/" path at the end
  unscopedRoutes.push({ path: "/", component: Dashboard });
  const scopedRoutes = routes.filter(
    (route) => route.scope === ROUTE_SCOPES.SCOPED || route.scope === ROUTE_SCOPES.ALL
  );
  const strictScopedRoutes = routes.filter((route) => route.scope === ROUTE_SCOPES.STRICT);

  // sessions & passwords routes
  const authRoutes = [
    { path: "/users/sign_in", component: NewSession },
    { path: "/users/password/new", component: NewPassword },
    { path: "/users/password/edit", component: EditPassword }
  ];

  return (
    <div>
      <main>
        <Switch>
          {listScopedRoutes(scopedRoutes)}
          {listStrictScopedRoutes(strictScopedRoutes)}
          {unscopedRoutes.map((route) => (
            <CompatRoute
              exact
              path={`/admin${route.path}`}
              key={route.path}
              component={route.component}
            />
          ))}
          {listScopedRoutes([{ path: "/", component: Dashboard }])}
          {authRoutes.map((route) => (
            <CompatRoute exact path={route.path} key={route.path} component={route.component} />
          ))}
        </Switch>
      </main>
    </div>
  );
};

export default Page;
