import React from "react";
import withStyles from "@mui/styles/withStyles";
import Switch from "@mui/material/Switch";

const GreenSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.grey[400], // You can adjust this for the color when the switch is off
    "&$checked": {
      color: theme.custom.GREEN
    },
    "&$checked + $track": {
      backgroundColor: theme.custom.GREEN
    }
  },
  checked: {},
  track: {}
}))((props) => <Switch {...props} />);

export default GreenSwitch;
