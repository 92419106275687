import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { dateOrMonday } from "lib/dates";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchChangeRequests, changeRequestsFilters } from "services/apiChangeRequests";
import { setFlashMessage } from "services";
import {
  defaultState as changeReqDialogDefaultState,
  changeReqDialogModel
} from "./changeReqDialogModel";

const FILTERS = {
  schools: [],
  requestors: [],
  statuses: []
};

export const defaultState = {
  // options
  filters: FILTERS,
  // filters
  schoolId: null,
  scope: "requests",
  requestorId: null,
  status: "unresolved",
  student: null,
  to: null,
  from: null,
  dateFiltersActive: false,
  // status
  refresh: false,
  newReqDialogOpen: false,
  loading: false,
  // data
  changeRequests: [],
  date: dateOrMonday(new Date()),
  ...changeReqDialogDefaultState
};

const RESET_PROPS = ["requestorId", "student"];

export const queuesStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...changeReqDialogModel(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, [
      "schoolId",
      "requestorId",
      "status",
      "scope",
      "student",
      "to",
      "from"
    ]);
    if (props.refresh) props.refresh = !state.refresh;
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchChangeRequestsFilters: thunk((actions) => {
    actions.setLoading(true);
    changeRequestsFilters()
      .then((data) => actions.setFilters(data))
      .finally(() => actions.setLoading(false));
  }),

  fetchChangeRequestsData: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), [
      "page",
      "perPage",
      "requestorId",
      "status",
      "student",
      "status",
      "to",
      "from"
    ]);

    actions.setLoading(true);

    return fetchChangeRequests({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  reset: action((state) => {
    const defaultProps = pick(defaultState, RESET_PROPS);
    assign(state, defaultProps, enhancePaginationProps({}));
  }),

  setData: action((state, data) => {
    state.changeRequests = data.change_requests;
    state.total = parseInt(data.total);
  })
});
