import React from "react";
import { Box, RadioGroup, FormControlLabel } from "@mui/material";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import { TRIP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import I18n from "utils/i18n.js";

const RIDE_TYPES = ["students", "delivery"];

const RouteOptionsChoice = ({
  cls,
  store,
  onDirectionChange,
  isNewStop = false,
  withDirections = false
}) => {
  const [state, actions] = store;

  const onRideTypeChange = (e) => actions.updateRideType(e.target.value);
  const onTripTypeChange = (e) => {
    actions.updateTripType({ tripType: e.target.value, isNewStop });
    if (onDirectionChange) onDirectionChange(e.target.value);
  };

  const rideTypesRadio = RIDE_TYPES.map((type) => (
    <FormControlLabel
      value={type}
      key={`ride-type-${type}`}
      control={<GreenRadio />}
      label={I18n.t(`ui.route.riders_type.${type}`)}
      className={withDirections ? null : cls.radioInput}
    />
  ));

  const directionTypesRadio = TRIP_TYPES[state.routeType].map((type) => (
    <FormControlLabel
      value={type}
      key={`trip-type-${type}`}
      control={<GreenRadio />}
      label={I18n.t(`ui.route.trip_type.${type}`)}
    />
  ));

  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      {!isNewStop && (
        <Box flexGrow={1}>
          <RadioGroup value={state.rideType} onChange={onRideTypeChange} row>
            {rideTypesRadio}
          </RadioGroup>
        </Box>
      )}
      {withDirections && (
        <Box flexGrow={1}>
          <RadioGroup value={state.tripTypes[0]} onChange={onTripTypeChange} row>
            {directionTypesRadio}
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};

export default RouteOptionsChoice;
