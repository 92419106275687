import React, { Fragment } from "react";
import { orderBy } from "lodash";
import { Dialog, Typography, TextField, FormControl, FormLabel } from "@mui/material";
import { useStoreState } from "easy-peasy";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import FormSelect from "components/admin_v2/ui/FormSelect";
import FileUpload from "components/admin_v2/ui/FileUpload";
import I18n from "utils/i18n.js";

const AddTextBlastDialog = ({ store, cls, open, toggleDialog }) => {
  const { schools } = useStoreState((s) => s.app);
  const [state, actions] = store;
  const tzAbbr = schools.find((s) => s.id === state.newBlast.schoolId)?.time_zone_abbr || "---";

  const closeDialog = () => {
    actions.setErrors({});
    toggleDialog();
  };

  const handleChange = (field) => (e) => {
    let val;
    if (field === "processAt") {
      val = e;
    } else if (field === "schoolId") {
      val = e.target.value;
    }
    actions.setNewBlast({ ...state.newBlast, [field]: val });
  };

  const onFileSelect = (e) => {
    actions.setNewBlast({ ...state.newBlast, file: e.target.files[0] });
  };

  const onSubmit = () => {
    actions.submit();
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <Fragment>
        <FormDialogTitle onClose={closeDialog}>
          <Typography variant="h1">{I18n.t("data_management.new_text_blast.title")}</Typography>
        </FormDialogTitle>
        <FormDialogContent className={cls.dialogCont}>
          <FormControl className={cls.field}>
            <FormSelect
              itemType="school"
              options={orderBy(schools, "name")}
              value={state.newBlast.schoolId}
              onChange={handleChange("schoolId")}
              cls={cls}
              clsKeys={["fullSelect", "fullSelect"]}
              error={state.errors?.school}
              required
            />
          </FormControl>
          <FormControl className={cls.halField}>
            <FormLabel className={cls.label} required>
              {I18n.t("data_management.text_blasts.form.process_at.label", { tz: tzAbbr })}
            </FormLabel>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              value={state.newBlast.processAt}
              onChange={handleChange("processAt")}
            />
          </FormControl>
          <FormControl className={cls.field}>
            <FileUpload
              itemType="file"
              accept=".csv"
              file={state.newBlast.file}
              onChange={onFileSelect}
              error={state.errors?.file}
              required
            />
          </FormControl>
        </FormDialogContent>
        <FormDialogActions
          onSubmit={onSubmit}
          onClose={closeDialog}
          disabled={state.submitDisabled}
          submitLabel={I18n.t("data_management.text_blasts.form.submit")}
        />
      </Fragment>
    </Dialog>
  );
};

export default AddTextBlastDialog;
