import { useCallback, useEffect, useMemo } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { get } from "lodash";
import { appendParamToUrl } from "lib/util";
import { getMonday, parseDateFromUrl, formatDateUrl } from "lib/dates";
import { parseLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";

export const useDirectionScheduleUpdate = (location, setLoading, withoutStudents = false) => {
  const { date, route, direction } = useStoreState((s) => s.routeEditor);
  const { updateSchedule, updateVehicleAssignments, setDirection } = useStoreActions(
    (s) => s.routeEditor
  );
  const referenceDate = useMemo(() => formatDateUrl(getMonday(date)), [date]);

  useEffect(() => {
    if (!direction || !route) return;

    const tripType = get(parseLocationSearch({ location }), "direction") || direction;
    if (tripType && tripType !== direction) {
      setDirection(tripType);
      return;
    }
    setLoading(true);
    updateVehicleAssignments({
      date: referenceDate,
      selectedDate: date,
      direction: tripType
    });
    updateSchedule({
      date: referenceDate,
      selectedDate: date,
      direction: tripType,
      withoutStudents
    }).finally(() => {
      setLoading(false);
    });
  }, [route?.id, direction, referenceDate]);
};

export const useRouteDateUpdate = (props) => {
  const { onChangeScope } = useStoreActions((s) => s.app);
  const { route } = useStoreState((s) => s.routeEditor);
  const { setRoute, updateRoute, setDate, setDirection, resetRoute } = useStoreActions(
    (s) => s.routeEditor
  );

  useEffect(() => {
    setDate(parseDateFromUrl());
  }, []);

  const resetState = useCallback(() => {
    resetRoute();
    props.history.push(pageRoutes.routes());
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    const routeId = props.match.params.id;
    if (!routeId || route?.id == routeId) return;
    updateRoute(routeId);
  }, [props.match.params.id]);

  const selectDate = useCallback((newDate) => {
    setDate(newDate);
    appendParamToUrl("date", formatDateUrl(newDate));
  }, []);

  const changeRoute = (route) => {
    setRoute(route);
    if (route?.id) props.history.push(pageRoutes.route(route.id));
  };

  const selectDirection = (dir) => {
    setDirection(dir);
    appendParamToUrl("direction", dir);
  };

  return [changeRoute, selectDate, selectDirection];
};
