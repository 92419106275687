import makeStyles from "@mui/styles/makeStyles";

export const useContainerStyles = makeStyles((theme) => ({
  mainGutterPadding: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));

export const useHomeStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: "100%",
    padding: theme.spacing(0, 0)
  },
  mainGrid: {
    backgroundColor: theme.palette.background.lightGrey,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 0
  },
  whiteGrid: {
    backgroundColor: theme.custom.WHITE
  },
  signInGrid: {
    display: "block",
    height: `calc(100vh - ${theme.spacing(25)})`
  },
  pageContainer: {
    maxWidth: theme.custom.CONTAINER_WIDTH,
    margin: "0 auto"
  },
  footer: {
    height: 50,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 17),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  footerMenu: {
    flex: 1
  }
}));
