import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const reportOptions = async (reportType = null) => {
  const query = queryString({ reportType }, true);
  const url = apiUrl(`reports/options?${query}`, true);
  return await xhr.get(url, { transformData: true });
};

export const reportsUnseen = async (schoolIds) => {
  const query = queryString({ schoolIds }, true);
  const url = apiUrl(`reports/unseen?${query}`, true);
  return await xhr.get(url, { transformData: true });
};

export const fetchReports = async (params) => {
  const query = queryString(params, true);
  const url = apiUrl(`reports?${query}`, true);
  return await xhr.get(url, { transformData: true });
};

export const generateReport = async (params) => {
  const url = apiUrl("reports/generate", true);
  return await xhr.post(url, params, { transformData: true });
};

export const previewReport = async (params) => {
  const url = apiUrl("reports/preview", true);
  return await xhr.post(url, params, { transformData: true });
};

export const reportDownloaded = async (report) => {
  const url = apiUrl(`reports/${report.id}/downloaded`, true);
  return await xhr.post(url, {}, { transformData: true });
};
