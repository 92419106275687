import { action, thunk } from "easy-peasy";
import { easyStateSetters } from "lib/easyState";
import { assign, concat, findIndex, pick } from "lodash";
import { camelizeKeys } from "humps";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchReports, reportDownloaded } from "services/apiReports";
import { setFlashMessage } from "services";
import store from "context/admin_v2/store";

export const defaultState = {
  // filters
  period: null,
  reportType: null,
  schoolId: null,
  scope: "self",
  // status
  loading: true,
  // data
  generated: [],
  reports: []
};

export const reportsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["schoolId", "scope", "reportType", "period"]);
    if (props.refresh) props.refresh = !state.refresh;
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  addGenerated: action((state, report) => {
    state.generated = [...state.generated, report];
  }),

  fetchReports: thunk((actions, params, h) => {
    actions.setLoading(true);
    const stateParams = pick(h.getState(), [
      "page",
      "perPage",
      "period",
      "reportType",
      "schoolId",
      "scope"
    ]);

    return fetchReports({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  removeGenerated: action((state, index) => {
    state.generated.splice(index, 1);
  }),

  reportDownloaded: thunk((actions, report) => {
    reportDownloaded(report)
      .then((report) => actions.setDownloaded(report))
      .catch((err) => setFlashMessage(err.message));
  }),

  reportReceived: thunk((actions, report) => {
    const jidValid = store.getState().app.jidValid;
    report = camelizeKeys(report);
    if (!jidValid(report.jid, report.report?.creatorId)) return;

    if (report.status === "failed") {
      setFlashMessage(`Report generation failed: ${report.error}`);
      if (report.report) actions.updateReport(report.report);
    } else {
      actions.addGenerated(report.report);
      actions.updateReport(report.report);
    }
  }),

  setData: action((state, data) => {
    state.reports = data.reports;
    state.total = data.total;
  }),

  setDownloaded: action((state, report) => {
    state.reports = state.reports.map((r) => {
      return r.id === report.id ? { ...r, downloaded: true } : r;
    });
  }),

  updateReport: action((state, report) => {
    const idx = findIndex(state.reports, ["id", report.id]);
    if (idx < 0) {
      state.reports = concat([report], state.reports);
    } else {
      state.reports[idx] = report;
    }
  })
});
