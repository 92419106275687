import React from "react";
import { Box, Typography, TextField, FormGroup } from "@mui/material";
import useTripEditorStyles from "./useTripEditorStyles";
import I18n from "utils/i18n.js";

const AddNote = ({ modeChangeStore, idx }) => {
  const [modeChangeState, modeChangeActions] = modeChangeStore;
  const details = modeChangeState.getDetails(idx);
  const clsTrip = useTripEditorStyles();

  const onUpdateNote = (e) => {
    modeChangeActions.updateTripDetails({ idx, note: e.target.value });
  };

  return details?.changeRequest && modeChangeState.isRequestMode ? (
    <Box mt={1}>
      <Typography variant="subtitle1" className={clsTrip.label}>
        {I18n.t("ui.mode_editor.add_note")}
      </Typography>
      <FormGroup row className={clsTrip.days}>
        <TextField
          variant="outlined"
          multiline
          rows={4}
          value={details.note || ""}
          className={clsTrip.note}
          onChange={onUpdateNote}
        />
      </FormGroup>
    </Box>
  ) : null;
};

export default AddNote;
