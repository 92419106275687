import withStyles from "@mui/styles/withStyles";
import { MenuList } from "@mui/material";

export const TabMenuList = withStyles((theme) => ({
  root: {
    height: theme.spacing(7),
    display: "flex",
    padding: theme.spacing(0),
    "&:focus": {
      outline: "none"
    }
  }
}))(MenuList);
