import React from "react";
import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import AnchorLabel from "components/admin_v2/ui/AnchorLabel";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { slugsFor } from "services/api";
import { routeWithDate } from "lib/queryString";

const RouteLink = ({ day, stop, route, children, student = null, withRouteLink = true }) => {
  return (
    <Box>
      {withRouteLink && route ? (
        <Typography variant="body2">
          <strong>{capitalize(route.vehicle_type)}</strong>
          {" | "}
          <RouterLink
            to={routeWithDate(day, route.id, slugsFor(student))}
            schoolId={student?.school_id}
          >
            {route.name}
          </RouterLink>
          {" | "}
          {route.vendor_name}
        </Typography>
      ) : (
        <Typography>
          <strong>Unassigned</strong>
        </Typography>
      )}
      <Typography variant="body2">
        {stop?.address}
        {stop?.anchor_types && <AnchorLabel withLine={true} />}
      </Typography>
      {children}
    </Box>
  );
};

export default RouteLink;
