import React, { useEffect, useCallback } from "react";
import { Box, Link, Paper, TableRow, TableCell } from "@mui/material";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import BatchSelect from "components/admin_v2/common/BatchSelect";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import AddDismissalGroupDialog from "./AddDismissalGroupDialog";
import RouterLink from "components/admin_v2/nav/RouterLink";
import DismissalGroupsFilter from "components/admin_v2/school_config/dismissals/DismissalGroupsFilter";
import SearchInput from "components/admin_v2/ui/SearchInput";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { dismissalGroupsStore } from "components/admin_v2/school_config/stores/dismissalGroupsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import { batchArchive, batchUnarchive } from "services/apiDismissalGroups";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DismissalGroups = (props) => {
  const cls = useCommonPageStyles();
  const { school, district } = useStoreState((s) => s.app);
  const { onChangeScope } = useStoreActions((s) => s.app);
  const store = useLocalStore(() => dismissalGroupsStore(camelizeLocationSearch(props)));
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);
  const updateQueryString = updateWithRouter(props);
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  const resetState = useCallback(() => {
    updateQueryString({ page: 1 });
    actions.toggleRefresh();
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchDismissalGroups({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [searchInput, state.page, state.perPage, state.scope, state.refresh, sortBy]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  useEffect(() => {
    actions.setGroupForm({ ...state.groupForm, schoolId: school?.id });
  }, [school, district]);

  let actionsList = [];
  if (state.scope == "archived") {
    actionsList.push({ label: I18n.t("ui.btn.unarchive"), action: batchUnarchive });
  } else {
    actionsList.push({ label: I18n.t("ui.btn.archive"), action: batchArchive });
  }

  const updatePage = (_e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const toggleAddDismissalGroupDialog = () => {
    actions.setAddDismissalGroupDialogOpen(!state.addDismissalGroupDialogOpen);
  };

  const headers = [
    {
      field: "batchCheck",
      label: (
        <GreenCheckbox
          checked={state.allSelected}
          onChange={(e) => actions.updateAllSelected(e.target.checked)}
        />
      ),
      skipSorting: true
    },
    { field: "id", label: "ID" },
    { field: "school", label: "School" },
    { field: "name", label: "Dismissal Group" },
    { field: "created_at", label: "Created" },
    { field: "", label: "" }
  ].map(sortableColumn);

  const row = (dismissalGroup) => (
    <TableRow key={`dismissal_group-${dismissalGroup.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <GreenCheckbox
          checked={state.isSelected(dismissalGroup.id)}
          onChange={(e) =>
            actions.updateSelectedIds({ isAdd: e.target.checked, id: dismissalGroup.id })
          }
        />
      </TableCell>
      <TableCell component="th" style={{ width: "5%" }}>
        <RouterLink to={pageRoutes.dismissalGroup(dismissalGroup.id)} className={cls.tableLink}>
          {dismissalGroup.id}
        </RouterLink>
      </TableCell>
      <TableCell style={{ width: "15%" }}>{dismissalGroup.school}</TableCell>
      <TableCell style={{ width: "25%" }}>{dismissalGroup.name}</TableCell>
      <TableCell>{dismissalGroup.createdAt}</TableCell>
      <TableCell>
        <RouterLink to={pageRoutes.dismissalGroup(dismissalGroup.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.view")}
        </RouterLink>
        <RouterLink to={pageRoutes.dismissalGroupEdit(dismissalGroup.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.edit")}
        </RouterLink>
        <Link
          onClick={() => actions.toggleArchive(dismissalGroup.id)}
          className={cls.tableLink}
          underline="hover"
        >
          {dismissalGroup.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
        </Link>
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <Box className={cls.btnsWrapper}>
        <OutlinedBtn onClick={toggleAddDismissalGroupDialog}>
          {I18n.t("school_config.new_dismissal_group.btn")}
        </OutlinedBtn>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          <BatchSelect store={store} actionsList={actionsList} />
          <DismissalGroupsFilter state={state} updateQueryString={updateQueryString} />
          <SearchInput cls value={state.query} onChange={onSearch} />
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.dismissalGroups.map(row)}
          </PaginatedTable>
        )}
      </Paper>
      <AddDismissalGroupDialog
        cls={cls}
        open={state.addDismissalGroupDialogOpen}
        store={store}
        toggleDialog={toggleAddDismissalGroupDialog}
      />
    </React.Fragment>
  );
};

export default DismissalGroups;
