import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const RequiredLabel = ({ label, text = "(required)", condition }) => {
  const cls = useStyles();
  return (
    <>
      {label}
      {!condition && <span className={cls.required}>* {text}</span>}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  required: {
    color: theme.custom.RED
  }
}));

export default RequiredLabel;
