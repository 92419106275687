import React from "react";
import NavTabs from "components/admin_v2/common/NavTabs";
import { pageRoutes } from "services/api";

const DistrictConfigTabs = () => {
  const tabs = [{ label: "District Details", path: pageRoutes.districts(), exact: false }];

  return <NavTabs tabs={tabs} />;
};

export default DistrictConfigTabs;
