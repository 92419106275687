import React from "react";
import { Typography, Paper } from "@mui/material";
import { useLocalStore } from "easy-peasy";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import ErrorList from "components/admin_v2/ui/ErrorList";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import SchoolDetailsForm from "components/admin_v2/school_config/schools/SchoolDetailsForm";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";
import { schoolFormStore } from "components/admin_v2/school_config/stores/schoolFormStore";
import { EDIT_MODE } from "components/admin_v2/forms/stores/tableSettingStore";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const NEW_SCHOOL = {
  firstDay: new Date(),
  lastDay: new Date(),
  gradesServed: [],
  allAdminsAccess: true
};

const SchoolNew = (_props) => {
  const cls = useCommonPageStyles();
  const formStore = useLocalStore(() => schoolFormStore(NEW_SCHOOL, EDIT_MODE.edit));
  const [state, _actions] = formStore;

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <BreadCrumbs path={pageRoutes.schools()} label={I18n.t("school_config.schools.breadcrumb")} />
      <Typography variant="h1" gutterBottom>
        {I18n.t("school_config.new_school.title")}
      </Typography>
      <Paper className={cls.paper}>
        <div className={cls.subTitle}>{I18n.t("school_config.school.title.details")}</div>
        <ErrorList errors={state.errors} prefix="base" />
        <SchoolDetailsForm formStore={formStore} />
      </Paper>
    </React.Fragment>
  );
};

export default SchoolNew;
