import React, { Fragment, useState } from "react";
import { Box, Link, FormControl, FormControlLabel, FormLabel, Typography } from "@mui/material";
import AnchorLabel from "components/admin_v2/ui/AnchorLabel";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import RouteSelect from "components/admin_v2/students/trip/route/RouteSelect";
import RouteStops from "./RouteStops";
import ChangeRequestInfo from "../common/ChangeRequestInfo";
import useTripStyles from "components/admin_v2/students/trip/useStyles";

import I18n from "utils/i18n.js";

const RouteSelectForm = ({ store, toggleForm, cls, isDisabled }) => {
  const clsTrip = useTripStyles();
  const [showSchedule, setShowSchedule] = useState(false);
  const [sameAnchor, setSameAnchor] = useState(true);
  const [state, _actions] = store;
  const changeReq = state.changeReq;
  const anchor = changeReq.stops?.anchor;

  const toggleShowSchedule = () => {
    setShowSchedule(!showSchedule);
  };

  const showScheduleLink = (
    <Link onClick={toggleShowSchedule} component="button" underline="always">
      {showSchedule ? "Hide route stops" : "Show route stops"}
    </Link>
  );

  const createRouteLink = isDisabled ? (
    <Box />
  ) : (
    <Link onClick={toggleForm} component="button" underline="always" id="new-route-link">
      Create New Route
    </Link>
  );

  return (
    <Fragment>
      <ChangeRequestInfo changeReq={changeReq} cls={cls} />
      <FormControl
        fullWidth={true}
        className={anchor || state.anchorType === "pickup" ? null : clsTrip.withTopMargin}
      >
        <Box display="flex" justifyContent="space-between" alignItems="baseline">
          <Box display="flex" alignItems="baseline">
            <FormLabel className={clsTrip.label}>
              <Typography variant="subtitle1" className={cls.label}>
                {I18n.t(`change_requests.dialog.anchor.${changeReq.trip_type}`)}
              </Typography>
            </FormLabel>
            <Box component="span" ml={1}>
              <AnchorLabel />
            </Box>
          </Box>
          <Box display="flex" alignItems="baseline">
            {anchor && !isDisabled && (
              <Fragment>
                <FormControlLabel
                  className={cls.checkLabel}
                  control={
                    <GreenCheckbox
                      checked={sameAnchor}
                      onChange={() => setSameAnchor(!sameAnchor)}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {I18n.t("change_requests.dialog.anchor.same")}
                    </Typography>
                  }
                />
                <Box color="primary" mx={2}>
                  <Typography color="primary">|</Typography>
                </Box>
              </Fragment>
            )}
            {createRouteLink}
          </Box>
        </Box>
        <RouteSelect
          store={store}
          disabled={isDisabled}
          labelField={`anchors.${changeReq.trip_type}.name_route_time`}
          extraParams={
            sameAnchor && anchor
              ? { anchor_id: anchor.stop_location?.id, anchor_time: anchor.time }
              : null
          }
        />
      </FormControl>
      <Box mt={1} display="flex" justifyContent="flex-end">
        {state.route ? showScheduleLink : null}
      </Box>
      {showSchedule && state.route ? (
        <RouteStops
          route={state.route}
          date={state.period.startDate}
          tripType={changeReq.trip_type}
          school={changeReq.school}
        />
      ) : null}
    </Fragment>
  );
};

export default RouteSelectForm;
