import React, { Fragment } from "react";
import cn from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function FlashMessage() {
  const classes = useStyles();
  const { flashMessage } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFlashMessage(null);
  };

  if (!flashMessage) return null;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={true}
      onClose={handleClose}
      autoHideDuration={6000}
      ContentProps={{
        "aria-describedby": "message-id",
        className: cn("flash-notice", classes.snackbar)
      }}
      message={
        <Fragment>
          <InfoIcon className={classes.icon} />
          <span id="message-id" className={classes.msg}>
            {flashMessage.message}
          </span>
        </Fragment>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  snackbar: {
    minWidth: "400px",
    maxWidth: "600px"
  },
  icon: {
    marginRight: theme.spacing(1),
    position: "relative",
    top: theme.spacing(0.25)
  },
  msg: {
    position: "relative",
    top: theme.spacing(-0.5)
  },
  close: {
    padding: theme.spacing(0.5)
  }
}));
