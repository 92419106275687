import React, { useEffect, useCallback } from "react";
import cn from "classnames";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FormControlLabel from "@mui/material/FormControlLabel";
import { capitalize } from "lodash";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import AddTextBlastDialog from "./AddTextBlastDialog";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { pageRoutes } from "services/api";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { textBlastsStore } from "./stores/textBlastsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import useSorting from "lib/useSorting";
import useMessageBus from "lib/useMessageBus";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const STATUS_FILTERS = ["failed", "in-progress", "success"];

const TextBlasts = (props) => {
  const cls = useCommonPageStyles();
  const { school, schools } = useStoreState((s) => s.app);
  const { onChangeScope } = useStoreActions((s) => s.app);
  const store = useLocalStore(() => textBlastsStore(camelizeLocationSearch(props)));
  const [state, actions] = store;

  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  const resetState = useCallback(() => {
    actions.toggleRefresh();
    updateQueryString({ page: 1 });
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    if (!schools.length) return;

    actions.setNewBlast({ ...state.newBlast, schoolId: school?.id });
  }, [school, schools]);

  useEffect(() => {
    actions.fetchBlasts({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [state.page, state.perPage, state.status, state.refresh, sortBy]);

  useMessageBus({
    channel: "textblasts-updates",
    condition: true,
    received: (r) => actions.textBlastChange(r.text_blast)
  });

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSelectStatus = (status) => {
    const newStatus = state.status === status ? null : status;
    updateQueryString({ page: 1, status: newStatus });
  };

  const toggleAddTextBlastDialog = () => {
    actions.setNewBlast({ ...state.newBlast, processAt: new Date() });
    actions.setAddTextBlastDialogOpen(!state.addTextBlastDialogOpen);
  };

  const headers = [
    { field: "id", label: "ID" },
    { field: "school", label: "School" },
    { field: "created_at", label: "Created at" },
    { field: "process_at", label: "Process at" },
    { field: "status", label: "Status" },
    { field: "user", label: "Initiated by" },
    { field: "", label: "", skipSorting: true }
  ].map(sortableColumn);

  const row = (textBlast) => (
    <TableRow key={`blast-${textBlast.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <RouterLink to={pageRoutes.textBlast(textBlast.id)} className={cls.tableLink}>
          {textBlast.id}
        </RouterLink>
      </TableCell>
      <TableCell>{textBlast.school}</TableCell>
      <TableCell>{textBlast.created_at}</TableCell>
      <TableCell>
        {textBlast.process_at}
        <span className={cn(cls.smallLabel, cls.ml)}>{textBlast.time_zone_abbr}</span>
      </TableCell>
      <TableCell>
        <Box className={`${cls[textBlast.status]} ${cls.statusBox}`}>
          {capitalize(textBlast.status)}
        </Box>
      </TableCell>
      <TableCell>{textBlast.user}</TableCell>
      <TableCell align="right">
        <RouterLink to={pageRoutes.textBlast(textBlast.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.view")}
        </RouterLink>
        {textBlast.status === "pending" && (
          <Link onClick={() => actions.cancelTextBlast(textBlast.id)} className={cls.tableLink}>
            {I18n.t("ui.btn.cancel")}
          </Link>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <Box className={cls.btnsWrapper}>
        <OutlinedBtn onClick={toggleAddTextBlastDialog}>
          {I18n.t("data_management.new_text_blast.btn")}
        </OutlinedBtn>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          {STATUS_FILTERS.map((status) => (
            <FormControlLabel
              key={status}
              className={cls.filter}
              control={
                <GreenCheckbox
                  checked={state.status === status}
                  onChange={() => onSelectStatus(status)}
                />
              }
              label={capitalize(status)}
            />
          ))}
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.textBlasts.map(row)}
          </PaginatedTable>
        )}
      </Paper>
      <AddTextBlastDialog
        store={store}
        cls={cls}
        open={state.addTextBlastDialogOpen}
        toggleDialog={toggleAddTextBlastDialog}
      />
    </React.Fragment>
  );
};

export default TextBlasts;
