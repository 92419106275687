import React, { Fragment, useEffect } from "react";
import { isNull, isString, get } from "lodash";
import { useLocalStore } from "easy-peasy";
import { TextField, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { autocompleteStore } from "./stores/autocompleteStore";
import useDebounce from "lib/useDebounce";

const AutocompleteSelect = ({
  id,
  defaultSchool = "none",
  clearAfterChange,
  searchParams = {},
  defaultValue,
  resetOptions,
  // callbacks
  getOptions,
  onOptionSelect,
  // autocomplete UI
  labelField,
  placeholder,
  localSearch = false,
  renderOption = null,
  filterOptions = null,
  variant = "outlined",
  maxWidth,
  padding = 0,
  multiple = false,
  disabled = false,
  title = null
}) => {
  const [state, actions] = useLocalStore(() => autocompleteStore({ value: defaultValue }));
  const cls = useStyles({ maxWidth, padding });
  const debouncedInput = useDebounce(state.input);

  useEffect(() => {
    if (!localSearch && !isNull(debouncedInput))
      actions.fetchOptions({ getOptions, searchParams, defaultSchool, debouncedInput });
  }, [debouncedInput]);

  useEffect(() => {
    actions.reset({ defaultValue });
    actions.fetchOptions({ getOptions, searchParams, defaultSchool, debouncedInput: defaultValue });
  }, [searchParams?.school_id, searchParams?.date, searchParams?.anchor_id, resetOptions]);

  const onChange = (_e, value, reason) => {
    actions.setLoading(false);
    if (!clearAfterChange) {
      actions.updateValue({ value });
      if (reason === "clear") actions.fetchOptions({ getOptions, searchParams, defaultSchool });
    }
    onOptionSelect(value);
  };

  const handleInput = (e) => actions.setInput(e.target.value);
  const extraOptions = filterOptions ? { filterOptions } : {};

  return (
    <Autocomplete
      id={id}
      open={state.open}
      onOpen={() => actions.setOpen(true)}
      onClose={() => actions.setOpen(false)}
      blurOnSelect={true}
      autoHighlight={true}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      getOptionLabel={(option) =>
        isString(option) ? option : get(option, labelField, get(option, "label", ""))
      }
      renderOption={renderOption}
      options={state.options}
      loading={state.loading}
      className={[cls.searchField, cls.select].join(" ")}
      classes={{ inputRoot: cls.searchInnerField }}
      onChange={onChange}
      value={state.value}
      disabled={disabled}
      multiple={multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant={variant}
          onChange={handleInput}
          placeholder={placeholder}
          title={title}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {state.loading ? (
                  <CircularProgress color="inherit" size={20} className={cls.searchLoading} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
        />
      )}
      {...extraOptions}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  searchField: {
    padding: `${theme.spacing(0)} !important`,
    height: theme.spacing(5),
    backgroundColor: theme.custom.WHITE
  },
  searchInnerField: {
    padding: (props) => `${theme.spacing(0)} ${theme.spacing(props.padding)} !important`,
    height: theme.spacing(5)
  },
  select: {
    maxWidth: (props) => props.maxWidth
  },
  searchLoading: {
    marginRight: theme.spacing(4)
  }
}));

export default AutocompleteSelect;
