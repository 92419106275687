import React from "react";
import InputAdornment from "@mui/material/InputAdornment";

const SchoolCode = ({ code }) => {
  if (!code) return null;

  return (
    <InputAdornment position="start" style={{ marginRight: "0" }}>
      {code}_
    </InputAdornment>
  );
};

export default SchoolCode;
