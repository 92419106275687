import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState } from "easy-peasy";
import useDidMountEffect from "lib/useDidMountEffect";
import useDebounce from "lib/useDebounce";
import I18n from "utils/i18n.js";

const StudentSearchFilter = ({ state, updateQueryString }) => {
  const [search, setSearch] = useState(state.student);
  const { filtersReset } = useStoreState((s) => s.app);
  const debouncedInput = useDebounce(search);
  const cls = useStyles();

  useDidMountEffect(() => {
    updateQueryString({ page: 1, student: search });
  }, [debouncedInput]);

  useEffect(() => {
    if (filtersReset) setSearch("");
  }, [filtersReset]);

  const onChange = (e) => setSearch(e.target.value);

  return (
    <TextField
      variant="standard"
      id="student-search"
      className={cls.formControl}
      label={I18n.t("ui.queues.filter.student")}
      type="search"
      value={search || ""}
      onChange={onChange}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(2)
  }
}));

export default StudentSearchFilter;
