import React, { Fragment } from "react";
import { snakeCase } from "lodash";
import { CancelRounded, EditOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useLocalStore } from "easy-peasy";
import I18n from "utils/i18n.js";
import AddressesForm from "components/admin_v2/forms/AddressesForm";
import PhoneNumbersForm from "components/admin_v2/forms/PhoneNumbersForm";
import { IconLink } from "components/admin_v2/forms/TableActionCell";
import SchoolDetailsForm from "./SchoolDetailsForm";
import SchoolTimesForm from "./SchoolTimesForm";
import DaysOffForm from "./DaysOffForm";
import DaysDeltaForm from "./DaysDeltaForm";
import { schoolFormStore } from "components/admin_v2/school_config/stores/schoolFormStore";
import { EDIT_MODE } from "components/admin_v2/forms/stores/tableSettingStore";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const SchoolForm = ({ school }) => {
  const cls = useCommonPageStyles();
  const formStore = useLocalStore(() => schoolFormStore(school));
  const [state, actions] = formStore;

  const parentEntity = { type: "School", id: school.id, refetch: actions.refetch };
  const forms = {
    details: <SchoolDetailsForm formStore={formStore} />,
    addresses: (
      <AddressesForm
        addresses={state.school.addresses}
        parentEntity={parentEntity}
        schoolId={school?.id}
        withoutSchool={!school?.id}
        withTz={true}
        collapsible={true}
      />
    ),
    schoolTimes: (
      <SchoolTimesForm
        schoolTimes={state.school.schoolTimes}
        parentEntity={{ ...parentEntity, timeZoneAbbr: school.timeZoneAbbr }}
        collapsible={true}
      />
    ),
    phoneNumbers: (
      <PhoneNumbersForm
        phoneNumbers={state.school.phoneNumbers}
        parentEntity={parentEntity}
        phoneRequired={false}
        withTwilio={true}
        collapsible={true}
      />
    ),
    daysDelta: (
      <DaysDeltaForm
        daysDelta={state.school.daysDelta}
        schoolTimes={state.school.schoolTimes}
        parentEntity={{
          ...parentEntity,
          timeZoneAbbr: school.timeZoneAbbr,
          timeZoneOffset: school.timeZoneOffset
        }}
        collapsible={true}
      />
    ),
    daysOff: (
      <DaysOffForm daysOff={state.school.daysOff} parentEntity={parentEntity} collapsible={true} />
    )
  };

  const edit = () => actions.setEditMode(EDIT_MODE.edit);
  const cancel = () => actions.cancel();

  return (
    <Fragment>
      {Object.entries(forms).map(([formTitle, form]) => (
        <Box key={formTitle} my={2.5} className={`school-form-${snakeCase(formTitle)}`}>
          <Paper className={cls.paper}>
            {formTitle === "details" ? (
              <>
                <Box
                  sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  mb={2}
                >
                  <Box className={cls.subTitle} mb={0}>
                    {I18n.t(`school_config.school.title.${snakeCase(formTitle)}`)}
                  </Box>
                  {state.isEditMode ? (
                    <IconLink onClick={cancel} type="cancel">
                      <CancelRounded />
                    </IconLink>
                  ) : (
                    <IconLink onClick={edit} type="edit">
                      <EditOutlined />
                    </IconLink>
                  )}
                </Box>
                {form}
              </>
            ) : (
              <>{form}</>
            )}
          </Paper>
        </Box>
      ))}
    </Fragment>
  );
};

export default SchoolForm;
