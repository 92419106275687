import { action, computed, thunk } from "easy-peasy";
import { isSameDay, add, isValid, format } from "date-fns";
import { assign, omit, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import {
  formatTime,
  formatDateUrl,
  timeDifference,
  parseTime,
  formatDelta,
  formatTimeDifference,
  deltaToTime
} from "lib/dates";
import { updateDayDelta, createDayDelta } from "services/apiDayDelta";
import { OFF_TYPE, ADJUSTMENT_TYPE, DELTA_TYPE } from "./daysDeltaStore";

const HOURS_THRESHOLD = 2;

const defaultState = {
  loading: false,
  errors: {},
  // status
  isPast: false,
  allSelected: false,
  // data
  dayDelta: {},
  initialParams: {},
  parentEntity: {},
  schoolTimes: [],
  timeDiff: 0,
  data: []
};

const DAY_DELTA_PARAMS = ["startDate", "endDate", "delta"];

const initDataFromModel = (data) => {
  const delta = data.dayDelta.delta ? deltaToTime(data.dayDelta.delta) : null;
  const time = parseTime(data.dayDelta.displayTime);
  return {
    ...omit(data, ["dayDelta"]),
    dayDelta: {
      ...data.dayDelta,
      time,
      deltaHours: delta ? delta.getHours() : 0,
      deltaMinutes: delta ? delta.getMinutes() : 0
    },
    initialParams: {
      ...pick(data.dayDelta, DAY_DELTA_PARAMS),
      time: formatTime(time)
    }
  };
};

const dayDeltaTimesStore = (initial) => ({
  ...easyStateSetters(defaultState, initDataFromModel(initial)),

  schoolDeltaTimes: computed((state) => {
    return state.data
      .filter((d) => d.active && (d.checked || d.deltaTimeId))
      .map((d) => ({
        id: d.deltaTimeId,
        _destroy: !d.checked,
        schoolTimeId: d.schoolTimeId
      }));
  }),

  getDay: computed((state) =>
    isValid(state.dayDelta.startDate) ? format(state.dayDelta.startDate, "EEEE") : "Unknown"
  ),

  getThresholdTimes: computed((state) => {
    const current = new Date();
    // we need to take now in school time zone
    const now = new Date(
      current.getTime() +
        state.parentEntity.timeZoneOffset * 1000 +
        current.getTimezoneOffset() * 60 * 1000
    );
    const nowWithThreshold = add(now, { hours: HOURS_THRESHOLD });
    return [now, nowWithThreshold];
  }),

  getDeltaNewTime: computed((state) => (time) => {
    const { dayDelta } = state;
    let delta = null,
      displayDelta = null,
      newTime = null;
    if (dayDelta.deltaType === DELTA_TYPE.time) {
      newTime = isValid(dayDelta.time) ? dayDelta.time : null;
      delta = timeDifference(time, newTime);
      displayDelta = formatTimeDifference(time, newTime);
    } else {
      delta = dayDelta.delta;
      displayDelta = formatDelta(delta);
      newTime = dayDelta.delta ? new Date(time.getTime() + delta * 1000) : null;
    }
    return { delta, displayDelta, newTime };
  }),

  isActive: computed((state) => ({ time, newTime, now, nowWithThreshold }) => {
    const isActive = state.isDateDelta(now);
    return isActive
      ? timeDifference(newTime, nowWithThreshold) < 0 && timeDifference(time, nowWithThreshold) < 0
      : state.dayDelta.startDate > now;
  }),

  isDateDelta: computed(
    (state) => (date) =>
      isValid(state.dayDelta.startDate) ? isSameDay(state.dayDelta.startDate, date) : false
  ),

  isDatePast: computed((state) => (now) => {
    const isActive = state.isDateDelta(now);
    return isActive ? false : state.dayDelta.startDate < now;
  }),

  isDisabled: computed((state) => {
    const isDisabled = state.isPast || !state.isValid;
    const { dayDelta, initialParams } = state;
    // must be applied to at least one time
    if (!state.data.some((d) => d.checked)) return true;
    if (dayDelta.offType === OFF_TYPE.singleDay || !isDisabled) return isDisabled;
    if (
      dayDelta.startDate === initialParams.startDate &&
      formatTime(dayDelta.time) === initialParams.time &&
      dayDelta.delta === initialParams.delta
    )
      return false;
    return isDisabled;
  }),

  // validate day delta
  isValid: computed((state) => {
    const entity = state.dayDelta;
    return (
      isValid(entity.startDate) &&
      (entity.offType === OFF_TYPE.singleDay || isValid(entity.endDate)) &&
      (entity.deltaType === DELTA_TYPE.delta || isValid(entity.time))
    );
  }),

  initData: action((state) => {
    const { dayDelta, schoolTimes } = state;

    const [now, nowWithThreshold] = state.getThresholdTimes;
    const stType = dayDelta.adjustmentType === ADJUSTMENT_TYPE.earlyRelease ? "pickup" : "dropoff";

    state.data = (schoolTimes?.filter((s) => s.timeType === stType) || []).map((schoolTime) => {
      const deltaTime = dayDelta.schoolDeltaTimes.find((d) => d.schoolTimeId == schoolTime.id);
      const time = parseTime(schoolTime.time);
      const { delta, displayDelta, newTime } = state.getDeltaNewTime(time);
      // not allow to check on/off times that will be on a way in 2 hours
      const active = state.isActive({ time, newTime, now, nowWithThreshold });
      return {
        checked: !!deltaTime || (!state.dayDelta.id && active),
        deltaTimeId: deltaTime?.id,
        active,
        name: schoolTime.name,
        schoolTimeId: schoolTime.id,
        waitTime: schoolTime.waitTime,
        time,
        originalTime: time,
        newTime,
        delta,
        displayDelta
      };
    });
    state.isPast = state.isDatePast(now);
  }),

  recalculateStatuses: action((state) => {
    const [now, nowWithThreshold] = state.getThresholdTimes;
    state.data.forEach((d) => {
      const { delta, displayDelta, newTime } = state.getDeltaNewTime(d.time);
      // not allow to check on/off times that will be on a way in 2 hours
      const active = state.isActive({ time: d.time, newTime, now, nowWithThreshold });
      const checked = d.checked
        ? d.checked && (!!d.deltaTimeId || (!state.dayDelta.id && active))
        : false;
      assign(d, { delta, displayDelta, newTime, active, checked });
    });
    state.isPast = state.isDatePast(now);
  }),

  updateAllChecked: action((state, checked) => {
    if (state.isPast) return;
    state.data.forEach((d) => {
      if (d.active) d.checked = checked;
    });
    state.allSelected = checked;
  }),

  updateDayDelta: action((state, { field, value }) => {
    state.dayDelta[field] = value;
    if (field === "adjustmentType" && !state.dayDelta.id) state.dayDelta.delta *= -1;
    if (field === "deltaHours" || field === "deltaMinutes") {
      const { deltaHours, deltaMinutes } = state.dayDelta;
      state.dayDelta.delta = (deltaHours || 0) * 60 * 60 + (deltaMinutes || 0) * 60;
      if (state.dayDelta.adjustmentType === ADJUSTMENT_TYPE.earlyRelease)
        state.dayDelta.delta *= -1;
    }
    if (field === "offType" && value === "interval") {
      // start with an empty endDate
      state.dayDelta.endDate = null;
    }
  }),

  updateTimeChecked: action((state, { idx, checked }) => {
    const deltaTime = state.data[idx];
    if (!deltaTime?.active) return;
    deltaTime.checked = checked;
  }),

  removeOccurrence: thunk(async (actions, data = {}, h) => {
    const state = h.getState();
    try {
      const { dayDelta, parentEntity } = state;
      const params = {
        ...data,
        parentEntity: pick(parentEntity, ["id", "type"])
      };
      return await updateDayDelta(dayDelta.id, params);
    } catch (err) {
      const errors = err.response?.data?.errors;
      actions.setErrors(errors);
      throw err;
    }
  }),

  save: thunk(async (actions, _params = {}, h) => {
    const state = h.getState();
    actions.setLoading(true);
    try {
      const { dayDelta, parentEntity } = state;
      const params = {
        ...omit(dayDelta, ["schoolDeltaTimes", "deltaHours", "deltaMinutes", "occurrences"]),
        dayOfWeek: state.offType === OFF_TYPE.interval ? state.getDay : null,
        time: formatTime(dayDelta.time),
        startDate: isValid(dayDelta.startDate) ? formatDateUrl(dayDelta.startDate) : null,
        endDate: isValid(dayDelta.endDate) ? formatDateUrl(dayDelta.endDate) : null,
        parentEntity: pick(parentEntity, ["id", "type"]),
        schoolDeltaTimesAttributes: state.schoolDeltaTimes
      };
      if (dayDelta.id) {
        return await updateDayDelta(dayDelta.id, params);
      } else {
        return await createDayDelta(params);
      }
    } catch (err) {
      const errors = err.response?.data?.errors;
      actions.setErrors(errors);
      throw err;
    } finally {
      actions.setLoading(false);
    }
  })
});

export default dayDeltaTimesStore;
