import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { chain } from "lodash";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Spinner from "components/admin_v2/ui/Spinner";
import ScheduleRow from "./ScheduleRow";
import AddStopDialog from "./AddStopDialog";
import StopsTableHeader from "./StopsTableHeader";
import ArchivedRow from "./ArchivedRow";
import { useDirectionScheduleUpdate } from "./common/hooks";
import DayOffsRow from "./schedule/DayOffsRow";
import ScheduleRepeatRow from "./schedule/ScheduleRepeatRow";
import VendorScheduleDialog from "components/admin_v2/routes/vendor_schedules/VendorScheduleDialog.jsx";
import { directionSign } from "./common";
import { TRIP_DIRECTIONS } from "utils/constants";

const StopsTable = ({ route, onColumnSelect, onStopSelect }) => {
  const cls = useStyles();
  const { user } = useStoreState((s) => s.app);
  const { direction, sortedStops, selectedDay, vendorScheduleDialogOpen } = useStoreState(
    (s) => s.routeEditor
  );
  const { setVendorScheduleDialogOpen, updateVehicleAssignments } = useStoreActions(
    (s) => s.routeEditor
  );
  const [loading, setLoading] = useState(false);
  const [addStopDialogOpen, setAddStopDialogOpen] = useState(false);
  const [expandAll, setExpandAll] = useState(true);

  useDirectionScheduleUpdate(location, setLoading);

  const onVendorScheduleUpdate = () => {
    updateVehicleAssignments({ date: selectedDay, direction: direction });
    setVendorScheduleDialogOpen(false);
  };

  const closeVendorScheduleDialog = () => {
    setVendorScheduleDialogOpen(false);
  };

  const toggleAddStopDialog = () => {
    if (route.discarded || route.non_riding_type) return;

    setAddStopDialogOpen((d) => !d);
  };

  let counter = 0;
  const counterSign = directionSign(direction);

  let capacity = 0;
  if (route.vehicle_capacity && route.vehicle_capacity > 0) {
    capacity = route.vehicle_capacity;
  } else {
    const maxCount = chain(sortedStops({ withFilteredSchedule: true }))
      .flatMap((stopsRow) => stopsRow.stops.filter((s) => s.date == selectedDay))
      .flatMap((s) => s.counters.regular)
      .sum()
      .value();
    capacity = maxCount;
  }

  const rows = () =>
    sortedStops({ withFilteredSchedule: true }).map((stopsRow, idx) => {
      const selectedStop = stopsRow.stops.find((s) => s.date == selectedDay);
      if (selectedStop) {
        counter += counterSign * (selectedStop.counters.regular - selectedStop.counters.terminus);
      }

      return (
        <ScheduleRow
          stopsRow={stopsRow}
          stopNumber={idx + 1}
          onStopSelect={onStopSelect}
          capacity={capacity}
          expandAll={expandAll}
          counter={counter}
          selectedDay={selectedDay}
          key={`stop-location-${stopsRow.stop_location.id}`}
        />
      );
    });

  return loading ? (
    <Spinner />
  ) : (
    <Table className={cls.stopsTable} data-test-id="stops-table">
      <TableBody>
        <TableRow>
          <TableCell className={cls.addStopCell}>
            {user.admin && (
              <span className={cls.addStop} disabled={route?.discarded || route.non_riding_type}>
                <AddCircleIcon
                  onClick={toggleAddStopDialog}
                  color="primary"
                  className={cls.addStopIcon}
                  id="add-stop-i"
                />
                <Typography display="inline" className={cls.addStopLabel}>
                  Add a Stop
                </Typography>
              </span>
            )}
          </TableCell>
          <StopsTableHeader onColumnSelect={onColumnSelect} handleExpand={setExpandAll} />
        </TableRow>
        {route.discarded && <ArchivedRow />}
        <DayOffsRow />
        <ScheduleRepeatRow />
        {rows()}
      </TableBody>
      {user.admin ? (
        <AddStopDialog open={addStopDialogOpen} toggleDialog={toggleAddStopDialog} />
      ) : null}
      {vendorScheduleDialogOpen && (
        <VendorScheduleDialog
          open={vendorScheduleDialogOpen}
          page="route"
          selectedDirection={TRIP_DIRECTIONS[direction]}
          selectedDateRouteId={route.id}
          onClose={closeVendorScheduleDialog}
          onSuccess={onVendorScheduleUpdate}
          selectedDate={selectedDay}
        />
      )}
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  stopsTable: {
    borderTopWidth: theme.spacing(0.1),
    borderTopColor: theme.palette.background.mediumGrey,
    borderTopStyle: "solid",
    "& .MuiTableCell-root": {
      borderWidth: theme.spacing(0.1),
      borderColor: theme.palette.background.mediumGrey,
      borderStyle: "solid"
    }
  },
  addStopCell: {
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    border: "none !important"
  },
  addStop: {
    display: "flex",
    alignItems: "center",
    "&[disabled]": {
      opacity: "0.5"
    }
  },
  addStopLabel: {
    marginLeft: theme.spacing(2),
    fontWeight: theme.custom.BOLD
  },
  addStopIcon: {
    cursor: "pointer",
    fontSize: "2.8rem"
  }
}));

export default StopsTable;
