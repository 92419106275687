import React, { Fragment, useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import InfoIcon from "@mui/icons-material/Info";
import { useStoreActions, useStoreState } from "easy-peasy";
import { formatPhone } from "lib/util";
import makeStyles from "@mui/styles/makeStyles";
import { pageRoutes } from "services/api";
import { getStudent } from "services/apiStudents";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { accommodationList } from "components/admin_v2/ui/StudentAccommodation";
import { randKey } from "lib/util";
import StudentHeader from "./StudentHeader";
import ReenrollStudent from "./ReenrollStudent";
import UnenrollStudent from "./UnenrollStudent";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import I18n from "utils/i18n.js";
import { Link } from "react-router-dom";
import { intersection } from "lodash";

const StudentProfile = (props) => {
  const cls = useStyles();
  const clsPage = useCommonPageStyles();
  const clsTableForm = useTableFormStyles();
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { onChangeScope } = useStoreActions((s) => s.app);
  const { user } = useStoreState((s) => s.app);

  const schoolSlug = props.match.params.school_slug;
  const id = props.match.params.id;

  useEffect(() => {
    setLoading(true);

    getStudent(id, { school_slug: schoolSlug })
      .then((r) => {
        setStudent(r.student);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const resetState = useCallback(() => {
    setStudent(null);
    props.history.push(pageRoutes.students());
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  if (!student) {
    return (
      <Box my={10}>
        <Typography variant="h2" align="center">
          {loading ? null : "Student not found!"}
        </Typography>
      </Box>
    );
  }

  const detail = ({ label, value, minWidth = 1 }) => (
    <Box className={clsPage.detail} minWidth={minWidth} key={`dt-${label}-${value}`}>
      <div className={clsPage.detailHeader}>{label}</div>
      <div>{value || "--"}</div>
    </Box>
  );

  const details = {
    left: [
      { label: "School", value: student.schoolName },
      { label: "First Name", value: student.firstName },
      { label: "Last Name", value: student.lastName },
      { label: "Grade", value: student.grade },
      { label: "Created At", value: student.createdAt }
    ],
    right: [
      { label: "Program", value: student.program },
      { label: "Dismissal Group", value: student.dismissalGroup?.name },
      { label: "SSID", value: student.sisId },
      { label: "Transportation ID", value: student.transportationId },
      { label: "Updated At", value: student.updatedAt }
    ]
  };

  const accommodation = [
    {
      label: I18n.t("student.accommodation.accommodation_type"),
      value: accommodationList(student.selectedAccommodationTypes, "accommodation_none"),
      minWidth: "8rem"
    },
    {
      label: I18n.t("student.accommodation.accommodation_note"),
      value: student.accommodationNote
    }
  ];

  const addresses = [
    {
      column: "Primary",
      value: (a) => <GreenCheckbox checked={a.primary} disabled />,
      cls: clsTableForm.smCell
    },
    { column: "Address", value: (a) => a.address },
    { column: "Name", value: (a) => a.name },
    { column: "Lat", value: (a) => a.lat },
    { column: "Lon", value: (a) => a.lng }
  ];

  const phoneNumbers = [
    {
      column: "Primary",
      value: (p) => <GreenCheckbox checked={p.primary} disabled />,
      cls: clsTableForm.smCell
    },
    { column: "Number", value: (p) => formatPhone(p.number) },
    { column: "Name", value: (p) => p.name },
    { column: "Type", value: (p) => p.lineType },
    {
      column: "Opt-Out comms",
      value: (p) => {
        const opt = p.optOutCommunication ? "yes" : "no";
        return <span className={`${cls.box} ${opt}`}>{opt}</span>;
      }
    },
    { column: "Language", value: (p) => p.language },
    {
      column: (
        <span title="Indicates if a phone number is linked to others">
          Linked <InfoIcon fontSize="small" className={cls.linkedInfo} />
        </span>
      ),
      value: (p) =>
        p.linked.map((l) => <LinkedContact key={`${l.type}-${l.id}`} contact={l} user={user} />)
    }
  ];
  return (
    <Fragment>
      <StudentHeader student={student} view="profile" />
      <Box my={2.5}>
        <Grid container className={cls.grid} spacing={5}>
          <Grid item sm={7}>
            <Paper className={cls.paper}>
              <div className={[cls.title, cls.mb0].join(" ")} data-test-id="title">
                Student Details
              </div>
              <Grid container className={cls.grid} spacing={5}>
                <Grid item xs={6}>
                  {details.left.map(detail)}
                </Grid>
                <Grid item xs={6}>
                  {details.right.map(detail)}
                </Grid>
              </Grid>
              {student?.discarded ? (
                <ReenrollStudent student={student} />
              ) : (
                <UnenrollStudent student={student} />
              )}
            </Paper>
          </Grid>
          <Grid item sm={5}>
            <Paper className={cls.paper}>
              <div className={cls.title} data-test-id="title">
                Communication Groups
              </div>
              <Table aria-label="table" className={cls.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={clsTableForm.tableHeader}>Name</TableCell>
                    <TableCell className={clsTableForm.tableHeader}>Twilio Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {student.communicationGroups.map((g) => (
                    <TableRow hover tabIndex={-1} key={randKey()}>
                      <TableCell className={clsTableForm.cell} key={randKey()}>
                        {g.name}
                      </TableCell>
                      <TableCell className={clsTableForm.cell} key={randKey()}>
                        {formatPhone(g.twilioNumber)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box my={5}>
        <Paper className={cls.paper}>
          <div className={[cls.title, cls.mb0].join(" ")} data-test-id="title">
            {I18n.t("student.accommodation.title")}
          </div>
          <Grid container className={cls.grid}>
            <Grid item>{[accommodation[0]].map(detail)}</Grid>
            <Grid item xs={12}>
              <Box className={clsTableForm.tableHeader} mt={2} mb={1}>
                {accommodation[1].label}
              </Box>
              <Box className={cls.note} borderRadius={1}>
                {accommodation[1].value}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box my={5}>
        <Paper className={cls.paper}>
          <div className={cls.title} data-test-id="title">
            Addresses
          </div>
          <Table aria-label="table" className={cls.table}>
            <TableHead>
              <TableRow>
                {addresses.map((item) => (
                  <TableCell
                    className={[clsTableForm.tableHeader, item.cls].join(" ")}
                    key={randKey()}
                  >
                    {item.column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {student.addresses.map((addr) => (
                <TableRow hover tabIndex={-1} key={randKey()}>
                  {addresses.map((item) => (
                    <TableCell className={[clsTableForm.cell, item.cls].join(" ")} key={randKey()}>
                      {item.value(addr)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <Box my={5}>
        <Paper className={cls.paper}>
          <div className={cls.title} data-test-id="title">
            Phone Numbers
          </div>
          <Table aria-label="table" className={cls.table}>
            <TableHead>
              <TableRow>
                {phoneNumbers.map((item) => (
                  <TableCell
                    className={[clsTableForm.tableHeader, item.cls].join(" ")}
                    key={randKey()}
                  >
                    {item.column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {student.phoneNumbers.map((phone) => (
                <TableRow hover tabIndex={-1} key={randKey()}>
                  {phoneNumbers.map((item) => (
                    <TableCell className={[clsTableForm.cell, item.cls].join(" ")} key={randKey()}>
                      {item.value(phone)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Fragment>
  );
};

const LinkedContact = ({ contact, user }) => {
  const schoolAccess = intersection(user.school_ids, contact.schoolIds).length > 0;
  if (contact.type === "User" && contact.id === user.id) {
    return <div>Current User</div>;
  }
  if (schoolAccess && (user.role === "admin" || contact.type === "Student")) {
    return (
      <div>
        <Link to={contact.path}>{contact.name}</Link>
      </div>
    );
  }
  return <div>{contact.type === "User" ? "Other 4mativ User" : "Rider at another school"}</div>;
};

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1
  },
  title: {
    fontSize: "1rem",
    fontWeight: theme.custom.BOLD,
    marginBottom: theme.spacing(2)
  },
  mb0: {
    marginBottom: 0
  },
  paper: {
    padding: theme.spacing(2),
    fontSize: "1rem"
  },
  table: {
    "& .MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root": {
      borderBottom: 0
    }
  },
  box: {
    backgroundColor: theme.custom.MEDIUM_GREY,
    color: theme.custom.WHITE,
    fontWeight: theme.custom.BOLD,
    fontSize: "0.85rem",
    textTransform: "uppercase",
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(0.5, 3),
    "&.no": {
      backgroundColor: theme.custom.GREEN
    }
  },
  note: {
    padding: theme.spacing(2),
    border: `solid 1px ${theme.custom.MEDIUM_GREY}`,
    whiteSpace: "pre-wrap",
    overflowWrap: "anywhere"
  },
  linkedInfo: {
    color: theme.custom.BLUE,
    fontSize: "1rem",
    position: "relative",
    top: "2px",
    left: "4px"
  }
}));

export default StudentProfile;
