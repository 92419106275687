import React, { Fragment } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize, isEmpty } from "lodash";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { routeWithDate } from "lib/queryString";

export const getStop = (trip) => {
  if (isEmpty(trip.stops)) return null;

  const studentAddStops = trip.stops.filter((s) => s.is_student_add);
  const studentAddStop =
    studentAddStops.length === 1
      ? studentAddStops[0]
      : studentAddStops.find((s) => isEmpty(s.events?.data)) ||
        studentAddStops[studentAddStops.length - 1];
  if (studentAddStop) {
    return studentAddStop;
  }
  return trip.stops[0];
};

export const getEventType = (trip, stop, forChangeRequest = false) => {
  if (trip.change_request) return "change_req";
  if (!stop) return "ok";
  if (trip.no_show) return "no_show";
  if (trip.one_off) return "one_off";
  // we never return change for change request dialog original event
  // the only possible case with change is student add event
  if (trip.change && !forChangeRequest && !stop?.is_student_add) return "change";
  if (trip.student_add || stop?.is_student_add) return "student_add";
  if (trip.cancel) return "cancel";
  if (trip.activity) return "activity";
  return "ok";
};

const StopLabel = ({ day, stop, eventType = "ok", withRouteLink = false, extraLabel = null }) => {
  const cls = useStyles();

  if (!stop) {
    return (
      <Fragment>
        <Box>
          <span className={`${cls.stopTime} unassigned`}>Unassigned</span>
          {extraLabel && <span>&nbsp;&nbsp;{extraLabel}</span>}
        </Box>
        {withRouteLink && (
          <Box mt={1} mb={1}>
            <strong>---</strong>
          </Box>
        )}
      </Fragment>
    );
  }

  const { time, route } = stop;
  return (
    <Fragment>
      <Box>
        <span className={`${cls.stopTime} ${eventType}`}>{time}</span>
        {extraLabel && <span>&nbsp;&nbsp;{extraLabel}</span>}
      </Box>
      {withRouteLink && (
        <Box mt={1} mb={1} className={`${cls.stopInfo} ${eventType}`}>
          <strong>{capitalize(route.vehicle_type)}</strong>
          {" | "}
          <RouterLink
            className={cls.routeLink}
            to={routeWithDate(day, route.id)}
            data-test-id="routeLink"
          >
            <strong>{route.name}</strong>
          </RouterLink>
          {" | "}
          {route.vendor_name}
        </Box>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  routeLink: {
    fontWeight: theme.custom.SEMI_BOLD,
    textDecoration: "underline"
  },
  stopInfo: {
    "&.cancel": { textDecoration: "line-through" },
    "&.activity": { textDecoration: "line-through" },
    "&.no_show": { textDecoration: "line-through" }
  },
  stopTime: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.custom.WHITE,
    padding: theme.spacing(0.25, 1.5),
    fontSize: "0.87rem",
    fontWeight: theme.custom.SEMI_BOLD,
    minWidth: "3.75rem",
    borderRadius: theme.spacing(0.5),
    "&.cancel": { backgroundColor: theme.custom.YELLOW },
    "&.change": { backgroundColor: theme.custom.YELLOW },
    "&.activity": { backgroundColor: theme.custom.YELLOW },
    "&.student_add": { backgroundColor: theme.custom.YELLOW },
    "&.no_show": { backgroundColor: theme.custom.RED },
    "&.one_off": { backgroundColor: theme.custom.RED },
    "&.unassigned": {
      backgroundColor: theme.custom.LIGHT_GREY,
      border: `solid 1px ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main
    }
  }
}));

export default StopLabel;
