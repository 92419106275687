import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import easyState from "lib/easyState";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchTrackingDevices } from "services/apiDevices";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { setFlashMessage } from "services";

export const defaultState = {
  // status
  loading: false,
  // filters
  query: null,
  // data
  devices: []
};

export const devicesStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),
  ...easyState("trackingDevices", []),
  ...easyState("openDialog", false),
  ...easyState("dialogDevice", null),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["query"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchDevices: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope"]);

    actions.setLoading(true);

    return fetchTrackingDevices({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.devices = data.trackingDevices;
    state.collection = data.trackingDevices;
    state.total = parseInt(data.total);
  }),

  dialogOpen: action((state, device) => {
    state.dialogDevice = device;
    state.openDialog = true;
  }),

  dialogClose: action((state, _) => {
    state.openDialog = false;
    state.dialogDevice = null;
  })
});
