import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchTrackingDevices = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`tracking_devices?${query}`), { transformData: true });
};

export const fetchTrackingDevicesList = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`tracking_devices/list?${query}`), { transformData: true });
};

export const fetchTrackingDevice = async (id) => {
  return await xhr.get(apiUrl(`tracking_devices/${id}`), { transformData: true });
};

export const saveTrackingDevice = async (id, params) => {
  const url = apiUrl(`tracking_devices/${id}`);
  return await xhr.post(url, { tracking_device: params }, { transformData: true });
};

export const createTrackingDevice = async (params) => {
  const url = apiUrl(`tracking_devices`);
  return await xhr.post(url, params);
};

export const deleteTrackingDevice = async (id) => {
  const url = apiUrl(`tracking_devices/${id}/delete`);
  return await xhr.post(url, {});
};

export const batchDelete = async (ids) => {
  const url = apiUrl(`tracking_devices/batch_delete`);
  return await xhr.post(url, { devices_ids: ids });
};

export const fetchApiDevices = async (provider) => {
  const url = `/api/avl/${provider}/devices`;
  return await xhr.get(url);
};

// function display(provider, option) {
//   if (provider == 'zonar') {
//     return JSON.stringify({ id: option.id, name: option.name, vin: option.vin, fleet: option.fleet });
//   }

//   return JSON.stringify(option);
// }
