import xhr from "lib/xhr";
import { apiUrl } from "./api";

export const createPhoneNumber = async (params) => {
  const url = apiUrl(`phone_numbers`);
  return await xhr.post(url, { phone_number: params }, { transformData: true });
};

export const removePhoneNumber = async (id, params) => {
  const url = apiUrl(`phone_numbers/${id}/delete`);
  return await xhr.post(url, { phone_number: params }, { transformData: true });
};

export const savePhoneNumber = async (id, params) => {
  const url = apiUrl(`phone_numbers/${id}`);
  return await xhr.post(url, { phone_number: params }, { transformData: true });
};
