import React from "react";
import { useStoreState } from "easy-peasy";
import { first } from "lodash";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { searchStopLocations } from "services/apiStopLocations";

const AddressSelect = ({ store }) => {
  const [state, actions] = store;
  const { route } = useStoreState((s) => s.routeEditor);

  return (
    <AutocompleteSelect
      getOptions={searchStopLocations}
      onOptionSelect={actions.setStopLocation}
      searchParams={{ school_id: first(route?.school_ids), school_ids: route?.school_ids }}
      labelField={"name"}
      defaultValue={state.stopLocation || ""}
    />
  );
};

export default AddressSelect;
