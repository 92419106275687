import React, { useCallback } from "react";
import { useLocalStore } from "easy-peasy";
import { capitalize, compact } from "lodash";
import memoize from "fast-memoize";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Box
} from "@mui/material";
import classNames from "classnames";
import useSorting from "lib/useSorting";
import { strPresent, commaErrors } from "lib/util";
import { formatDisplayTime } from "lib/dates";
import AlertDialog from "components/admin_v2/common/AlertDialog";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import CollapsibleSection from "components/admin_v2/forms/CollapsibleSection";
import TableActionCell from "components/admin_v2/forms/TableActionCell";
import TableHeaders from "components/admin_v2/forms/TableHeaders";
import { Check as CheckIcon } from "@mui/icons-material";
import { useTableFormStyles, clsTable } from "components/admin_v2/common/useTableFormStyles";
import schoolTimesStore from "../stores/schoolTimesStore";
import I18n from "utils/i18n.js";

const TIME_TYPES = ["pickup", "dropoff"];
const I18N_KEY = "school_config.school_time.form";

const HEADERS = [
  { field: "primary", label: I18n.t(`${I18N_KEY}.primary.label`) },
  { field: "name", label: I18n.t(`${I18N_KEY}.name.label`), required: true },
  { field: "time", label: I18n.t(`${I18N_KEY}.time.label`), required: true },
  {
    field: "waitTime",
    label: I18n.t(`${I18N_KEY}.wait_time.label`),
    subLabel: I18n.t(`${I18N_KEY}.wait_time.sublabel`)
  },
  {
    field: "departureTime",
    label: I18n.t(`${I18N_KEY}.departure_time.label`),
    subLabel: I18n.t(`${I18N_KEY}.departure_time.sublabel`)
  },
  { field: "timeType", label: I18n.t(`${I18N_KEY}.time_type.label`), required: true },
  { field: "editMode", label: "Actions" }
];

const AlertMessage = ({ state }) => {
  if (state.alert.new) {
    return <span>{I18n.t("school_config.school_time.messages.new.text")}</span>;
  }
  const routeMessage = state.alert.routes.length ? (
    <>
      {I18n.t("school_config.school_time.messages.update.routes")}
      <ul>
        {state.alert.routes.map((r) => (
          <li key={r}>{r}</li>
        ))}
      </ul>
    </>
  ) : null;
  const deltaMessage = state.alert.daysDelta
    ? I18n.t("school_config.school_time.messages.update.delta_times")
    : null;
  const messages = compact([routeMessage, deltaMessage]);
  if (messages.length === 1) {
    return (
      <>
        {routeMessage || deltaMessage}
        {
          <Box sx={{ fontWeight: "bold", mt: 2 }}>
            {I18n.t("school_config.school_time.messages.update.cta")}
          </Box>
        }
      </>
    );
  } else {
    return (
      <>
        <Box component="ol" sx={{ marginTop: 0 }}>
          {[routeMessage, deltaMessage].map((m, idx) => (
            <li key={idx}>{m}</li>
          ))}
        </Box>
        {
          <Box sx={{ fontWeight: "bold", mt: 2 }}>
            {I18n.t("school_config.school_time.messages.update.cta")}
          </Box>
        }
      </>
    );
  }
};

const SchoolTimeRow = ({ data, idx, formStore, cls, updateField, onSave, onRemove }) => {
  const [state, _actions] = formStore;
  const isEditMode = state.isEditMode(idx);
  const timeZoneAbbr = state.parentEntity?.timeZoneAbbr;
  const { entity, errors } = data;
  const { clsPrimary, clsMedium, clsCell } = clsTable(cls, isEditMode);
  return (
    <TableRow hover tabIndex={-1} id={`st-${idx}-row`}>
      <TableCell className={clsPrimary}>
        {isEditMode ? (
          <>
            <GreenCheckbox
              checked={entity.primary}
              onChange={updateField(idx, "primary")}
              className={cls.check}
            />
            {errors?.primary && (
              <FormHelperText error>{commaErrors(errors?.primary)}</FormHelperText>
            )}
          </>
        ) : (
          entity.primary && <CheckIcon />
        )}
      </TableCell>
      <TableCell className={clsCell}>
        {isEditMode ? (
          <TextField
            id={`st-${idx}-name`}
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t(`${I18N_KEY}.name.label`)}
                condition={strPresent(entity.name)}
              />
            }
            value={entity.name || ""}
            onChange={updateField(idx, "name")}
            className={cls.field}
            error={!!errors?.name}
            helperText={commaErrors(errors?.name)}
          />
        ) : (
          entity.name
        )}
      </TableCell>
      <TableCell className={clsMedium}>
        {isEditMode ? (
          <>
            <TimePickerInput
              id={`st-${idx}-time`}
              dateTime={entity.time}
              onChange={updateField(idx, "time")}
            />
            {errors?.time && <FormHelperText error>{commaErrors(errors?.time)}</FormHelperText>}
          </>
        ) : (
          `${formatDisplayTime(entity.time)} ${timeZoneAbbr}`
        )}
      </TableCell>
      <TableCell className={clsCell}>
        {isEditMode ? (
          <TextField
            id={`st-${idx}-waitTime`}
            variant="standard"
            value={entity.waitTime || "0"}
            type="number"
            label={I18n.t(`${I18N_KEY}.wait_time.label`)}
            onChange={updateField(idx, "waitTime")}
            className={cls.field}
            error={!!errors?.waitTime}
            helperText={commaErrors(errors?.waitTime)}
          />
        ) : (
          entity.waitTime
        )}
      </TableCell>
      <TableCell className={classNames(cls.cellBase, cls.cellView)}>{`${formatDisplayTime(
        entity.departureTime
      )} ${timeZoneAbbr}`}</TableCell>
      <TableCell className={clsMedium}>
        {isEditMode ? (
          <FormControl className={cls.field} variant="standard" error={!!errors?.timeType}>
            <InputLabel>{I18n.t(`${I18N_KEY}.time_type.label`)}</InputLabel>
            <Select
              id={`st-${idx}-timeType`}
              value={entity.timeType || "dropoff"}
              onChange={updateField(idx, "timeType")}
            >
              {TIME_TYPES.map((timeType) => (
                <MenuItem key={`${idx}-${timeType}`} value={timeType}>
                  {capitalize(timeType)}
                </MenuItem>
              ))}
            </Select>
            {errors?.timeType && (
              <FormHelperText error>{commaErrors(errors?.timeType)}</FormHelperText>
            )}
          </FormControl>
        ) : (
          capitalize(entity.timeType)
        )}
      </TableCell>
      <TableActionCell
        store={formStore}
        idx={idx}
        saveCallback={onSave}
        removeCallback={onRemove}
      />
    </TableRow>
  );
};

const SchoolTimesForm = ({ schoolTimes, parentEntity, collapsible = true }) => {
  const cls = useTableFormStyles();
  const formStore = useLocalStore(() =>
    schoolTimesStore({ data: schoolTimes, noDataAllowed: true, parentEntity })
  );
  const [state, actions] = formStore;
  const { sortableColumn } = useSorting({
    field: "primary",
    order: "asc",
    callback: actions.sortBy
  });

  // NOTE: we need memoize here as useCallback will give us not a lot because new function will be created on each rerender
  // Another option is to move index, field to data attributes of input
  const updateField = useCallback(
    memoize((idx, field) => (e) => {
      let value;
      switch (field) {
        case "time":
          value = e;
          break;
        case "waitTime":
          value = parseInt(e.target.value, 10) || 0;
          break;
        case "primary":
          value = e.target.checked;
          break;
        default:
          value = e.target.value;
      }
      actions.updateRowField({ idx, field, value });
      if (field === "time" || field === "waitTime") actions.updateDepartureTime({ idx });
      if (field === "name") actions.validateName({ idx, value });
    }),
    []
  );

  const onSave = ({ idx }) => {
    const entity = state.getRow(idx).entity;
    if (entity.id) {
      actions.checkConnectedRecords({ idx });
    } else {
      actions
        .save({ idx })
        .then(() => {
          parentEntity.refetch();
          actions.setMessageParams({ idx, new: true });
        })
        .catch(() => {});
    }
  };

  const onRemove = (params) => {
    actions
      .remove(params)
      .then(() => parentEntity.refetch())
      .catch(() => {});
  };

  const onSubmit = (params) => {
    if (params.new) {
      const el = document.getElementById("school-deltaTimes");
      if (el) el.scrollIntoView();
    } else {
      actions
        .save(params)
        .then(() => {
          parentEntity.refetch();
          actions.validateOtherNames(params);
        })
        .catch(() => {});
    }
  };

  const tableHeaders = HEADERS.map(sortableColumn);

  const rows = state.data.map((data, idx) => (
    <SchoolTimeRow
      key={`time-${idx}-row`}
      idx={idx}
      formStore={formStore}
      cls={cls}
      data={data}
      updateField={updateField}
      onSave={onSave}
      onRemove={onRemove}
    />
  ));

  const table = (
    <>
      <Table
        className={`${cls.table} ${parentEntity.type.toLowerCase()}-schoolTimes`}
        aria-label="table"
      >
        <TableHead>
          <TableRow>
            <TableHeaders headers={tableHeaders} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <Button className={cls.btn} variant="contained" color="secondary" onClick={actions.addRow}>
        {I18n.t("school_config.new_school_time.btn")}
      </Button>
      <AlertDialog store={formStore} callBack={onSubmit} {...state.alert}>
        {state.alertOpen ? <AlertMessage state={state} /> : null}
      </AlertDialog>
    </>
  );

  return collapsible ? (
    <CollapsibleSection title="schoolTimes" count={state.dataPersisted.length}>
      {table}
    </CollapsibleSection>
  ) : (
    table
  );
};

export default SchoolTimesForm;
