import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { useLocalStore, useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router";
import { castArray, get, isString } from "lodash";
import { slugsFor } from "services/api";
import { routeWithDate } from "lib/queryString";
import { errorMessages } from "lib/validation";
import ModeAdminChangeRequest from "./ModeAdminChangeRequest";
import ModeChangeEditor from "./ModeChangeEditor";
import CustomTrips from "./CustomTrips";
import { modeChangeStore as modeStore, WORKFLOW_TYPES } from "./stores/modeChangeStore";
import I18n from "utils/i18n.js";

const StudentModeChangeDialog = ({
  inline = false,
  isCustom = false,
  onClose,
  onCancel,
  onDateChange,
  onSave,
  student,
  date,
  showViewProfile,
  refreshOnSave,
  isAdminChangeRequest = false,
  isNewStudent = false
}) => {
  const history = useHistory();
  const { school, isUserAdmin } = useStoreState((s) => s.app);
  const { changeSchool, setFlashMessage } = useStoreActions((s) => s.app);
  const { modeDialogOpen, changeParams } = useStoreState((s) => s.calendar);
  // This is main store, it'll keep current event state and manage all submission/disable logic.
  // There are several different cases and number of components that are responsible to show the data/implement submission for each particular case + return disabled status
  // The decision of what components to use is made at trips/TripChangeEditor.
  // Cases:
  // - events that don't require additional data are processed at main store
  // - trips/TripModeEditor: change for all users, possible changes depend on user role/current change status and date
  // - trips/RemoveEditor: remove for admin and staff where we should show remove period
  // - trips/ChangeRequest: change request (read only for admins and undo for staff) if changes were already requested
  // All children components are providing back submission callback + disable status
  // Reset data on changes
  const modeChangeStore = useLocalStore(
    (prevState) =>
      modeStore({
        currentDate: date,
        workflow: isAdminChangeRequest ? WORKFLOW_TYPES.chooseStudent : WORKFLOW_TYPES.base,
        refresh:
          prevState && (modeDialogOpen || inline)
            ? !prevState.refresh
            : prevState?.refresh || false,
        student,
        isNewStudent,
        isAdminChangeRequest,
        isCustom
      }),
    [date, student, school, isCustom, modeDialogOpen]
  );
  const [state, actions] = modeChangeStore;
  const isActive = (modeDialogOpen || inline) && state.isActive;

  useEffect(() => {
    if (!isActive || state.loading || state.isChoosingStudent) return;

    // date or student was changed, need to reset data
    actions.resetUpdates();
    actions.fetchStudentCalendarDay({
      inline,
      ...changeParams
    });
  }, [state.currentDate, state.refresh, state.workflow]);

  if (!isActive && !state.isChoosingStudent) return null;

  const onSaveChanges = (params = {}) => {
    if (onSave) onSave();
    if (refreshOnSave) {
      actions.resetUpdates(params);
      actions.fetchStudentCalendarDay(params);
    }
  };

  const redirectToRoute = (response) => {
    if (onClose) onClose();
    history.push(
      routeWithDate(state.currentDate, response.createdRouteId, slugsFor(state.student))
    );
  };

  const onSuccess = (responses, params = {}) => {
    const response = responses.find((r) => r?.createdRouteId);
    // redirect to route page if any route was created, check if we may need to change school
    if (response) {
      if (state.student?.school_id && school?.id !== state.student.school_id) {
        changeSchool(state.student.school_id).then(() => {
          redirectToRoute(response);
        });
      } else {
        redirectToRoute(response);
      }
    } else {
      onSaveChanges(params);
    }
    setFlashMessage({
      message:
        responses.map((r) => r.message).join(" | ") ||
        I18n.t("ui.mode_editor.message.success", {
          name: `${state.student.first_name} ${state.student.last_name}`
        })
    });
  };

  const onError = (errors) => {
    const message = I18n.t("ui.mode_editor.message.fail", {
      name: `${state.student.first_name} ${state.student.last_name}`
    });
    setFlashMessage({
      message: [message].concat(errorMessages(errors)).join(" | ")
    });
  };

  const saveChanges = () => {
    actions
      .submit({ isUserAdmin })
      .then((data) => onSuccess(castArray(data)))
      .catch((err) => {
        let errors = get(err.response, "data.errors", { "": err.message });
        if (isString(errors)) errors = { "": errors };
        onError(errors);
        actions.setErrors(errors);
      })
      .finally(() => actions.setLoading(false));
  };

  return inline ? (
    isCustom ? (
      <CustomTrips modeChangeStore={modeChangeStore} onSave={saveChanges} />
    ) : (
      <ModeChangeEditor
        modeChangeStore={modeChangeStore}
        showViewProfile={showViewProfile}
        saveChanges={saveChanges}
        onDateChange={onDateChange}
        inline={inline}
      />
    )
  ) : (
    <Dialog open={modeDialogOpen} maxWidth="md" onClose={onClose}>
      {state.isChoosingStudent ? (
        <ModeAdminChangeRequest modeChangeStore={modeChangeStore} onCancel={onCancel} />
      ) : (
        <ModeChangeEditor
          modeChangeStore={modeChangeStore}
          showViewProfile={showViewProfile}
          saveChanges={saveChanges}
          inline={inline}
          onCancel={onCancel}
        />
      )}
    </Dialog>
  );
};

export default StudentModeChangeDialog;
