import React, { Fragment } from "react";
import { Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import I18n from "utils/i18n.js";

const AnchorLabel = withStyles((theme) => ({
  root: {
    color: theme.custom.GREEN,
    verticalAlign: "middle"
  }
}))(({ classes, withLine = false }) => {
  return (
    <Fragment>
      {withLine && " | "}
      <Box component="span" className={classes.root}>
        <FiberManualRecordIcon style={{ fontSize: "inherit" }} />
      </Box>
      {I18n.t("ui.stop.anchor")}
    </Fragment>
  );
});

export default AnchorLabel;
