import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import I18n from "utils/i18n.js";
import { getStudent } from "services/apiStudents";
import StudentHeader from "./StudentHeader";
import StudentForm from "./StudentForm";

const StudentEdit = (props) => {
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);

  const schoolSlug = props.match.params.school_slug;
  const id = props.match.params.id;

  useEffect(() => {
    setLoading(true);

    getStudent(id, { school_slug: schoolSlug })
      .then((r) => {
        setStudent(r.student);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  if (!student) {
    return (
      <Box my={10}>
        <Typography variant="h2" align="center">
          {loading ? null : I18n.t("students.not_found")}
        </Typography>
      </Box>
    );
  }

  return (
    <Fragment>
      <StudentHeader student={student} view="edit" />
      <StudentForm student={student} />
    </Fragment>
  );
};

export default StudentEdit;
