import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import { FormDialogTitle, FormDialogActions } from "components/admin_v2/ui/FormDialog";
import { Box, Divider, Typography, FormControl, FormLabel } from "@mui/material";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import FormSelect from "components/admin_v2/ui/FormSelect";
import { searchStudents } from "services/apiStudents";
import useTripStyles from "components/admin_v2/students/trip/useStyles";
import I18n from "utils/i18n.js";

const ModeAdminChangeRequest = ({ modeChangeStore, onCancel }) => {
  const { schools, school } = useStoreState((s) => s.app);
  const [state, actions] = modeChangeStore;
  const [schoolId, setSchoolId] = useState(state.student?.school_id || school?.id);
  const cls = useTripStyles();

  const onSchoolSelect = (e) => setSchoolId(e.target.value);

  return (
    <>
      <FormDialogTitle onClose={onCancel}>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">{I18n.t("ui.mode_editor.new_request")}</Typography>
        </Box>
      </FormDialogTitle>
      <Divider variant="fullWidth" className={cls.divider} />
      <FormControl className={cls.withBottomMargin} fullWidth>
        <FormLabel className={cls.label}>{I18n.t("ui.mode_editor.filter.school.label")}</FormLabel>
        <FormSelect
          itemType="school"
          options={schools}
          value={schoolId}
          onChange={onSchoolSelect}
          cls={cls}
          i18Key="mode_editor.filter"
          clsKeys={["select", "select"]}
          withoutLabel={true}
        />
      </FormControl>
      <FormLabel className={cls.label}>{I18n.t("ui.mode_editor.filter.student")}</FormLabel>
      <AutocompleteSelect
        id={`chreq-student-search`}
        getOptions={searchStudents}
        onOptionSelect={(student) => actions.setStudent(student)}
        searchParams={{ school_id: schoolId, withFullProfile: true }}
        placeholder={I18n.t("ui.mode_editor.filter.student_placeholder")}
        labelField={"name_with_grade"}
        padding={1.125}
        defaultValue={state.student}
        defaultSchool="all"
      />
      <Divider variant="fullWidth" className={cls.divider} />
      <FormDialogActions
        onSubmit={() => actions.setWorkflow("base")}
        submitLabel={I18n.t("ui.btn.next")}
        disabled={!state.student?.id}
        onClose={onCancel}
      />
    </>
  );
};

export default ModeAdminChangeRequest;
