import React, { useEffect } from "react";
import { map } from "lodash";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import { useStoreState } from "easy-peasy";
import FormSelect from "components/admin_v2/ui/FormSelect";
import MultiRoutesSelect from "components/admin_v2/ui/MultiRoutesSelect";
import Spinner from "components/admin_v2/ui/Spinner";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import useOptions from "./useOptions";
import useStyles from "./useStyles";
import I18n from "utils/i18n.js";

const REPORT_FORMATS = [
  { id: "pdf", name: "Route Sheets ONLY (PDF)" },
  {
    id: "email",
    name: "Summary of changes compared to last cycle (Email) with attached Route Sheets (PDF)"
  }
];

const ROUTES_FOR_OPTIONS = [
  { id: "operator", name: "Operator" },
  { id: "school", name: "School" }
];

const RouteSheetsReportForm = ({ reportStore }) => {
  const cls = useStyles();
  const [state, actions] = reportStore;
  const form = state.routeSheetsForm;
  const { options, loading } = useOptions(state.reportType, {});
  const { schools, user } = useStoreState((s) => s.app);

  const updateForm = (data) => {
    actions.setRouteSheetsForm({ ...form, ...data });
  };

  useEffect(() => {
    actions.updateRouteOptions();
  }, [form.transportationVendorIds, state.schoolIds, state.date]);

  useEffect(() => {
    if (!user || user.admin) return;

    updateForm({ routesFor: "school" });
  }, [user]);

  if (loading) return <Spinner />;

  const changeRoutesForSelection = (field, e) => {
    actions.setRoutes([]);

    if (field === "school") {
      actions.setSchoolIds([e.target.value]);
      updateForm({ transportationVendorIds: [] });
    } else {
      actions.setSchoolIds([]);
      updateForm({ transportationVendorIds: [e.target.value] });
    }
  };

  const handleRoutesChange = (_e, newValues) => updateForm({ routeIds: map(newValues, "id") });
  const handleChange = (field) => {
    return (e) => {
      let updateVal = e.target.value;
      if (field === "routesWithChanges") {
        updateVal = e.target.value === "true";
      }
      const params = field === "routesWithChanges" ? { routeIds: [] } : {};
      updateForm({ [field]: updateVal, ...params });
    };
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item sx={{ width: "66%" }}>
        <FormControl component="fieldset" className={cls.field}>
          <FormLabel className={cls.label}>{I18n.t("report.form.label.routes_for")}</FormLabel>
          <RadioGroup
            aria-label="reportRoutesFor"
            name="reportRoutesFor"
            value={form.routesFor}
            onChange={handleChange("routesFor")}
          >
            {ROUTES_FOR_OPTIONS.map((option) =>
              !user.admin && option.id === "operator" ? null : (
                <Grid container alignItems="center" key={`wrapper-${option.id}`} spacing={4}>
                  <Grid item sx={{ width: "15%" }} className={cls.smallerPadding}>
                    <FormControlLabel
                      data-test-id={`routes-for-${option.id}`}
                      value={option.id}
                      control={<GreenRadio />}
                      label={option.name}
                      key={option.name}
                      disabled={!user?.admin}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "30%", marginTop: "12px" }}
                    className={cls.smallerPadding}
                  >
                    <FormSelect
                      itemType={option.id}
                      options={option.id === "school" ? schools : options.transportationVendors}
                      value={
                        option.id === "school"
                          ? state.schoolIds[0]
                          : form.transportationVendorIds[0]
                      }
                      onChange={(evt) => changeRoutesForSelection(option.id, evt)}
                      cls={cls}
                      key={`select-${option.id}`}
                      withoutLabel={true}
                      disabled={option.id !== form.routesFor}
                    />
                  </Grid>
                </Grid>
              )
            )}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" className={cls.field}>
          <FormLabel className={cls.label}>
            {I18n.t("report.form.label.routes_selection")}
          </FormLabel>
          <RadioGroup
            aria-label="reportRoutesSelection"
            name="reportRoutesSelection"
            value={form.routesWithChanges}
            onChange={handleChange("routesWithChanges")}
          >
            <FormControlLabel
              data-test-id={`routes-with-changes-true`}
              value={true}
              control={<GreenRadio />}
              label={I18n.t(`report.form.label.route_options.with_changes`)}
            />
            <FormControlLabel
              data-test-id={`routes-with-changes-false`}
              value={false}
              control={<GreenRadio />}
              label={I18n.t("report.form.label.routes")}
            />
          </RadioGroup>
        </FormControl>
        <FormControl className={cls.field} variant="outlined" fullWidth>
          <MultiRoutesSelect
            id="adm-routes-select"
            routes={state.routes}
            routeIds={form.routeIds}
            onChange={handleRoutesChange}
            loading={state.routesLoading}
            disabled={form.routesWithChanges}
          />
        </FormControl>

        <FormControl component="fieldset" className={cls.field}>
          <FormLabel className={cls.label}>{I18n.t("report.form.label.outputs")}</FormLabel>
          <RadioGroup
            aria-label="reportFormat"
            name="reportFormat"
            value={form.reportFormat || "pdf"}
            onChange={handleChange("reportFormat")}
          >
            {REPORT_FORMATS.map((format) => (
              <FormControlLabel
                data-test-id={`format-${format.id}`}
                value={format.id}
                control={<GreenRadio />}
                label={format.name}
                key={format.name}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default RouteSheetsReportForm;
