import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import {
  fetchVendors,
  fetchVendor,
  archiveVendor,
  refetchVendorDevices
} from "services/apiVendors";
import { setFlashMessage } from "services";

export const defaultState = {
  // status
  loading: false,
  refresh: false,
  // filters
  scope: "active",
  query: null,
  // data
  vendors: [],
  vendor: null,
  errors: {}
};

export const vendorsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["scope", "query"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchVendors: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope"]);

    actions.setLoading(true);

    return fetchVendors({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.vendors = data.vendors;
    state.collection = data.vendors;
    state.total = parseInt(data.total);
  }),

  addVendor: action((state, vendorForm) => {
    let vendorsList = state.vendors;
    vendorsList.unshift(vendorForm);
    state.vendors = vendorsList;
  }),

  removeVendor: action((state, id) => {
    const newVendorsList = state.vendors.filter((sl) => sl.id !== id);
    state.vendors = newVendorsList;
  }),

  refetchDevices: action((_state, id) => {
    refetchVendorDevices(id)
      .then((resp) => {
        setFlashMessage(resp.message);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  fetchVendor: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchVendor(id)
      .then((resp) => {
        actions.setVendor(resp.vendor);
      })
      .finally(() => actions.setLoading(false));
  }),

  toggleArchive: thunk((actions, id, h) => {
    const vendor = h.getState().vendor;
    actions.setLoading(true);

    archiveVendor(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (vendor) {
          actions.setVendor(resp.vendor);
        } else {
          actions.removeVendor(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
