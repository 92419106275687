import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { decamelize } from "humps";
import I18n from "utils/i18n.js";

const FileUpload = (props) => {
  const { itemType, accept, file, onChange, error, required } = props;
  const cls = useStyles();
  const i18Key = `ui.${props.i18Key || "form"}.${decamelize(itemType)}`;

  return (
    <FormControl error={!!error} required={!!required} className={cls.uploadWrapper}>
      <FormLabel required={!!required}>{I18n.t(`${i18Key}.label`)}</FormLabel>
      <Box className={cls.inputWrapper}>
        <label htmlFor="upload-file" className={cls.input}>
          <input
            style={{ display: "none" }}
            id="upload-file"
            name="upload-file"
            type="file"
            accept={accept || ""}
            onChange={onChange}
          />

          <Button variant="outlined" component="span" className={cls.uploadBtn}>
            {I18n.t(`${i18Key}.empty`)}
          </Button>
        </label>
        <Box className={cls.fileName}>{file?.name || I18n.t(`${i18Key}.no_file`)}</Box>
      </Box>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  uploadWrapper: {
    width: "100%"
  },
  inputWrapper: {
    display: "flex",
    marginTop: theme.spacing(1)
  },
  input: {
    width: "25%",
    marginRight: theme.spacing(2)
  },
  uploadBtn: {
    width: "100%",
    color: theme.custom.WHITE,
    backgroundColor: theme.custom.DARK_GREY_1,
    "&:hover": {
      color: theme.custom.WHITE,
      backgroundColor: theme.custom.DARK_GREY_2
    }
  },
  fileName: {
    width: "45%",
    borderRadius: "3px",
    padding: theme.spacing(1),
    backgroundColor: theme.custom.LIGHT_GREY
  },
  uploadInput: {
    width: "20%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

export default FileUpload;
