import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { tripColor, timeDiffStr, tripTz } from "./tripHelpers";
import I18n from "utils/i18n.js";

const TripPopup = ({ trip, cls }) => {
  const isLate = trip.eta_diff > 0;
  const timeCls = isLate ? cls.striked : "";
  const etaCls = `${cls.time} ${tripColor(trip).name}`;
  const vehicleCls = `${cls.routeName} ${tripColor(trip).name}`;
  const vehicle = trip.vehicle_info;

  const nextStopInfo = (
    <Box>
      <Divider />
      <p className={cls.label}>{I18n.t("ui.trip.next_stop")}</p>
      <p>{trip.next_stop?.location_name}</p>
      <p>{trip.next_stop?.address}</p>
      <Box className={cls.timeWrapper}>
        <Box className={`${cls.time} ${timeCls}`}>{trip.next_stop?.time}</Box>
        {trip.next_stop && trip.next_stop.eta_diff !== 0
          ? [
              <Box className={etaCls} key="new-stop-time">
                {trip.next_stop?.new_time}
              </Box>,
              <Box className={etaCls} key="stop-eta-diff">
                {timeDiffStr(trip.next_stop.eta_diff)}
              </Box>
            ]
          : null}
        {trip.next_stop && tripTz(trip.time_zone_abbr, cls.tripTz)}
      </Box>
      {trip.next_stop?.students?.length > 0 ? (
        <>
          <Divider />
          <p className={cls.label}>{I18n.t("ui.trip.students")}</p>
          {trip.next_stop.students.map((name) => (
            <p className={cls.stopStudent} key={`student-${name}`}>
              {name}
            </p>
          ))}
        </>
      ) : null}
    </Box>
  );

  return (
    <div>
      <Box className={cls.popup}>
        <p className={cls.routeName}>{trip.route_name}</p>
        <p className={vehicleCls}>{vehicle}</p>
        {nextStopInfo}
      </Box>
    </div>
  );
};

export default TripPopup;
