import xhr from "lib/xhr";
import { apiUrl } from "./api";
import { queryString } from "lib/util";

export const checkConnectedRecords = async (id, params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`school_times/${id}/check_connected_records?${query}`), {
    transformData: true
  });
};

export const createSchoolTime = async (params) => {
  const url = apiUrl(`school_times`);
  return await xhr.post(url, { school_time: params }, { transformData: true });
};

export const removeSchoolTime = async (id, params) => {
  const url = apiUrl(`school_times/${id}/delete`);
  return await xhr.post(url, { school_time: params }, { transformData: true });
};

export const saveSchoolTime = async (id, params) => {
  const url = apiUrl(`school_times/${id}`);
  return await xhr.post(url, { school_time: params }, { transformData: true });
};
