import xhr from "lib/xhr";
import { apiUrl } from "./api";

export const createDayOff = async (params) => {
  const url = apiUrl(`days_off`);
  return await xhr.post(url, { day_off: params }, { transformData: true });
};

export const removeDayOff = async (id, params) => {
  const url = apiUrl(`days_off/${id}/delete`);
  return await xhr.post(url, { day_off: params }, { transformData: true });
};

export const saveDayOff = async (id, params) => {
  const url = apiUrl(`days_off/${id}`);
  return await xhr.post(url, { day_off: params }, { transformData: true });
};
