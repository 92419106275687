import React, { Fragment } from "react";
import cn from "classnames";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { strPresent } from "lib/util";
import SchoolsSelectForm from "components/admin_v2/common/SchoolsSelectForm";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import I18n from "utils/i18n.js";
import { Box, FormControlLabel } from "@mui/material";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { useStoreState } from "easy-peasy";

const UserDetailsForm = ({ store }) => {
  const isRestrictedAdmin = useStoreState((s) => s.app.isRestrictedAdmin);

  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { details, errors } = state;

  const updateField = (key, e) => {
    let value = e.target.value;
    if (key === "restrictedAccess") {
      value = e.target.checked;
    }
    actions.setDetails({ ...details, [key]: value });
  };

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("settings_config.users.form.email.label")}
                condition={strPresent(details.email)}
              />
            }
            value={details.email || ""}
            onChange={(e) => updateField("email", e)}
            className={cls.field}
            error={!!errors?.email}
            helperText={errors?.email?.[0]}
          />
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("settings_config.users.form.first_name.label")}
                condition={strPresent(details.firstName)}
              />
            }
            value={details.firstName || ""}
            onChange={(e) => updateField("firstName", e)}
            className={cn("user-firstName", cls.field)}
            error={!!errors?.firstName}
            helperText={errors?.firstName?.[0]}
          />
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("settings_config.users.form.last_name.label")}
                condition={strPresent(details.lastName)}
              />
            }
            value={details.lastName || ""}
            onChange={(e) => updateField("lastName", e)}
            className={cn("user-lastName", cls.field)}
            error={!!errors?.lastName}
            helperText={errors?.lastName?.[0]}
          />
          {!isRestrictedAdmin && (
            <>
              <Box>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={details.restrictedAccess}
                      onChange={(e) => updateField("restrictedAccess", e)}
                    />
                  }
                  label={I18n.t("settings_config.users.form.restricted_access.label")}
                />
              </Box>
              <SchoolsSelectForm store={store} cls={cls} />
            </>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserDetailsForm;
