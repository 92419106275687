import { useState, useEffect } from "react";
import { reportOptions } from "services/apiReports";

export default function useOptions(reportType, defaultOptions) {
  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reportOptions(reportType)
      .then((data) => setOptions((opts) => ({ ...opts, ...data })))
      .finally(() => setLoading(false));
  }, []);

  return { loading, options };
}
