import { useEffect, useRef } from "react";
import _ from "lodash";
import { decamelizeKeys } from "humps";
import { formatDateUrl, formatHumanDateAndTime } from "./dates";
import store from "context/admin_v2/store";

export const getPersistedState = (title, type) => {
  const val = localStorage.getItem(`persisted:${title}:${type}`);
  if (!val) return undefined;

  return JSON.parse(val);
};

export const setPersistedState = (title, type, value) => {
  localStorage.setItem(`persisted:${title}:${type}`, JSON.stringify(value));
};

export function parameterize(str) {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, "-");
}

//
// Find the vertical position for an element, considering all
// it's parents offsets
//
export function findYPos(obj) {
  var curtop = 0;
  if (obj && obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [curtop];
  }
}

//
// Find wether a DOM element is currently visible on the
// viewport for the user
//
export function elementIsVisible(elem) {
  if (!(elem instanceof Element)) throw Error("DomUtil: elem is not an element.");
  const style = getComputedStyle(elem);
  if (style.display === "none") return false;
  if (style.visibility !== "visible") return false;
  if (style.opacity < 0.1) return false;
  if (
    elem.offsetWidth +
      elem.offsetHeight +
      elem.getBoundingClientRect().height +
      elem.getBoundingClientRect().width ===
    0
  ) {
    return false;
  }
  const elemCenter = {
    x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
    y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
  };
  if (elemCenter.x < 0) return false;
  if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
  if (elemCenter.y < 0) return false;
  if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false;
  let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y);
  do {
    if (pointContainer === elem) return true;
  } while ((pointContainer = pointContainer.parentNode));
  return false;
}

export function distanceFromBottom(el) {
  return Math.abs(el.scrollHeight - el.scrollTop - el.clientHeight);
}

export function formatPhone(number) {
  if (number && number.length)
    return number.replace(/^\+1/, "").replace(/(\d{3})(\d{3})(\d|D{4})/, "$1-$2-$3");
}

export const validE164Format = (number) =>
  number.replace(/[-\_\s\.\(\)]/g, "").match(/\+?\d{10,}/)?.[0]?.length > 0; // eslint-disable-line no-useless-escape

export function queryString(params, transformKeys = true) {
  const esc = encodeURIComponent;
  if (!("district_id" in params) && !("districtId" in params)) {
    params.district_id = store.getState().app.district?.id;
  }
  if (!("school_id" in params) && !("schoolId" in params)) {
    params.school_id = store.getState().app.school?.id;
  }
  params = _.pickBy(params, _.identity);

  return _.chain(transformKeys ? decamelizeKeys(params) : params)
    .map((v, k) => (_.isNil(v) ? null : `${esc(k)}=${esc(v)}`))
    .filter()
    .value()
    .join("&");
}

export const useDocumentTitle = (title) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = defaultTitle.current;
    };
  }, [title]);
};

export function changeFavicon(src) {
  document.head || (document.head = document.getElementsByTagName("head")[0]);

  var link = document.createElement("link"),
    oldLink = document.getElementById("dynamic-favicon");
  link.id = "dynamic-favicon";
  link.rel = "shortcut icon";
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}

export function randKey() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "");
}

export function appendParamToUrl(param_name, param) {
  const currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.set(param_name, param);
  window.history.pushState("", "", `?${currentUrlParams.toString()}`);
}

export function simplePluralize(count, noun, suffix = "s") {
  return `${count} ${noun}${count !== 1 ? suffix : ""}`;
}

export function padTime(num) {
  return _.padStart(_.toString(num), 2, "0");
}

export function toJS(proxy) {
  return JSON.parse(JSON.stringify(proxy));
}

export function getMetaValue(name) {
  const metaValue = document.head.querySelector(`meta[name="${name}"]`);
  return metaValue ? metaValue.getAttribute("content") : null;
}

export function printHTML(elem, printable = null) {
  printable = printable || window.open("", "PRINT");

  printable.document.write(`
    <html>
    <head><title></title></head>
    <body style="margin: 0">${elem.outerHTML}</body>
    </html>
  `);
  printable.document.close();
  printable.focus();

  printable.print();
  printable.close();
}

export function componentsJoin(components, renderSeparator) {
  return components.reduce(
    (acc, curr) => (acc.length ? [...acc, renderSeparator(acc.length), curr] : [curr]),
    []
  );
}

export function identifier(item) {
  return item ? `${item.type}-${item.id}` : null;
}

const GSM7 =
  /^[0-9a-zA-Z@Δ¡¿£_!Φ"¥Γ#èΛ¤éΩ%ùΠ&ìΨòΣçΘΞ:Ø;ÄäøÆ,<Ööæ=ÑñÅß>ÜüåÉ§à€~ \s\$\.\-\+\(\)\*\\\/\?\|\^\}\{\[\]\'\r\n]*$/; // eslint-disable-line no-useless-escape
export const findGsm7InvalidChars = (str) => {
  const chars = _.filter(str, (c) => !GSM7.test(c));
  return chars.length > 0 ? chars : null;
};

export const strPresent = (val) => val && val.trim().length > 0;

export const toFormData = (obj, opts = {}) => {
  let formData = new FormData();
  const { nestedFields, dateWithTime } = opts;

  for (let key in obj) {
    if (nestedFields && nestedFields.includes(key)) {
      // append nested object
      for (let dataKey in obj[key]) {
        if (obj[key][dataKey]) {
          let val = obj[key][dataKey];
          if (val instanceof Date && dateWithTime) {
            val = formatHumanDateAndTime(val);
          }
          if (val instanceof Date && !dateWithTime) {
            val = formatDateUrl(val);
          }
          if (val instanceof Array) {
            val.forEach((v) => {
              formData.append(`${_.snakeCase(key)}[${_.snakeCase(dataKey)}][]`, v);
            });
          } else {
            formData.append(`${_.snakeCase(key)}[${_.snakeCase(dataKey)}]`, val);
          }
        }
      }
    } else {
      if (obj[key]) {
        let val = obj[key];
        if (val instanceof Date && dateWithTime) {
          val = formatHumanDateAndTime(val);
        }
        if (val instanceof Date && !dateWithTime) {
          val = formatDateUrl(val);
        }
        if (val instanceof Array) {
          val.forEach((v) => {
            formData.append(`${_.snakeCase(key)}[]`, v);
          });
        } else {
          formData.append(_.snakeCase(key), val);
        }
      }
    }
  }

  return formData;
};

export function commaErrors(errors) {
  if (!errors) return null;
  return Array.isArray(errors) ? errors.join(", ") : errors;
}

export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export function isPhone(number) {
  return number.replace(/[-_\s.()]/g, "").match(/^\+?\d+$/)?.[0]?.length > 0;
}
