import React, { useEffect } from "react";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LaunchIcon from "@mui/icons-material/Launch";
import { camelizeLocationSearch, updateWithRouter } from "lib/queryString";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import ChangeRequests from "./queues/ChangeRequests";
import ChangeRequestsFilter from "./queues/ChangeRequestsFilter";
import { useFilterStyles } from "components/admin_v2/common/FilterHelpers";
import { queuesStore } from "./queues/stores/queuesStore";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import StudentModeChangeDialog from "components/admin_v2/mode_editor/StudentModeChangeDialog";
import I18n from "utils/i18n.js";
import { INCIDENT_REPORT_URL } from "../../utils/constants";

const Queues = (props) => {
  const classes = useFilterStyles();
  const { isUserAdmin, isUserSchoolStaff } = useStoreState((s) => s.app);
  const { modeDialogOpen } = useStoreState((s) => s.calendar);
  const store = useLocalStore(() => queuesStore(camelizeLocationSearch(props)));
  const [state, actions] = store;
  const { setModeDialogOpen } = useStoreActions((s) => s.calendar);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  const onModeDialogClose = () => setModeDialogOpen(false);
  const onModeDialogSave = () => {
    setModeDialogOpen(false);
    actions.setRefresh(!state.refresh);
  };
  const updateQueryString = updateWithRouter(props);
  const createIssueBtn = isUserAdmin ? (
    <PrimaryBtn
      key="new-issue-btn"
      onClick={() => setModeDialogOpen(true)}
      disabled={modeDialogOpen}
    >
      {I18n.t("ui.btn.new_request")}
    </PrimaryBtn>
  ) : null;
  const incidentReportBtn = isUserSchoolStaff ? (
    <PrimaryBtn key="incident-report-btn" href={INCIDENT_REPORT_URL} target="_blank">
      Report Incident <LaunchIcon fontSize="small" style={{ marginLeft: "0.5rem" }} />
    </PrimaryBtn>
  ) : null;
  const actionButtons = [createIssueBtn, incidentReportBtn];

  return (
    <React.Fragment>
      <TopPageLayout title="Queues" actionButtons={actionButtons} />
      <Box my={4}>
        <Paper className={classes.paper}>
          <ChangeRequestsFilter state={state} updateQueryString={updateQueryString} />
          {state.scope === "requests" ? (
            <ChangeRequests {...props} queuesStore={store} updateQueryString={updateQueryString} />
          ) : null}
        </Paper>
      </Box>
      {isUserAdmin && (
        <StudentModeChangeDialog
          onClose={onModeDialogClose}
          onCancel={onModeDialogClose}
          onSave={onModeDialogSave}
          date={state.date}
          showViewProfile={true}
          isAdminChangeRequest={true}
          isCustom={false}
        />
      )}
    </React.Fragment>
  );
};

export default Queues;
