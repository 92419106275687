import React, { useEffect } from "react";
import { snakeCase, startsWith } from "lodash";
import { parseISO } from "date-fns";
import { Dialog, Divider, Typography, Button } from "@mui/material";
import { FormDialogTitle, FormDialogActions } from "components/admin_v2/ui/FormDialog";
import { useLocalStore } from "easy-peasy";
import { useStoreState } from "easy-peasy";
import I18n from "utils/i18n.js";
import RequiredNote from "components/admin_v2/ui/RequiredNote";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import VendorScheduleForm from "./VendorScheduleForm";
import { vendorScheduleDialogStore } from "components/admin_v2/common/stores/vendorScheduleDialogStore";
import { fetchRouteOptions as apiFetchRouteOptions } from "services/apiRoutes";
import { fetchRouteOptions as vendorsApiFetchRouteOptions } from "services/vendorsApiRoutes";
import Spinner from "components/admin_v2/ui/Spinner";
import { TRIP_DIRECTIONS } from "utils/constants";
import WarningLabel from "components/admin_v2/common/WarningLabel.jsx";

const SIMULATE_TRACKING_ENVS = ["development", "qa", "demo", "staging"];
const VEHICLES_DIRECTIONS_IDS = ["inboundVehicleId", "outboundVehicleId"];

const VendorScheduleDialog = ({
  open,
  page,
  onClose,
  onSuccess,
  selectedDateRouteId = null,
  selectedDirection = null,
  selectedDate = null,
  editRoute = null,
  editTrip = null
}) => {
  const store = useLocalStore(() => vendorScheduleDialogStore());
  const [state, actions] = store;
  const cls = useCommonPageStyles();
  const { route } = state;
  const { isUserAdmin } = useStoreState((s) => s.app);
  const routeId = editTrip?.route_id || selectedDateRouteId;
  const date = editTrip?.date_iso || selectedDate;
  const disabledForStaff = page !== "vendors_dashboard" && !isUserAdmin;

  useEffect(() => {
    if (!route) return;

    actions.setLoading(true);
    const optionsFn =
      page === "vendors_dashboard" ? vendorsApiFetchRouteOptions : apiFetchRouteOptions;
    optionsFn(route.id)
      .then((options) => {
        actions.setOptions(options);
      })
      .finally(() => actions.setLoading(false));
  }, [route]);

  useEffect(() => {
    if (!editTrip) return;

    actions.setBookingId(editTrip.booking_id);
  }, [editTrip]);

  useEffect(() => {
    if (!state.form.permanent) {
      actions.setFutureAssignmentsWarning(false);
      return;
    }

    actions.fetchFutureAssignments({ page: page });
  }, [state.form.permanent, state.form.startDate]);

  useEffect(() => {
    if (!routeId && !editRoute) return;

    actions.setLoading(true);

    if (editRoute) {
      actions.setRoute(editRoute);
    } else {
      actions.fetchRoute({ routeId: routeId, page: page, date: date });
    }

    return () => {
      actions.setRoute(null);
      actions.setLoading(false);
    };
  }, [editRoute, routeId]);

  useEffect(() => {
    if (!open || !route) return;

    actions.fetchSchedulesUpdateInfo({ page: page, date: date });
  }, [open, route]);

  useEffect(() => {
    if (!route) return;

    let defaultFields = {};
    if (editTrip) {
      const tripDate = parseISO(date);
      const tripVehicleField = `${TRIP_DIRECTIONS[editTrip?.trip_type]}VehicleId`;
      const routeVehicleField =
        tripVehicleField === "inboundVehicleId" ? "outboundVehicleId" : "inboundVehicleId";
      defaultFields[tripVehicleField] = editTrip.vehicle_id;
      defaultFields[routeVehicleField] = route[snakeCase(routeVehicleField)];
      defaultFields["transportationVendorId"] = editTrip.vendor_id;
      defaultFields["startDate"] = tripDate;
      defaultFields["permanent"] = false;

      if (startsWith(editTrip.trip_type, "to_")) {
        defaultFields["applyInbound"] = true;
      } else {
        defaultFields["applyOutbound"] = true;
      }
    } else {
      if (selectedDate) {
        const tripDate = parseISO(selectedDate);
        defaultFields["startDate"] = tripDate;

        VEHICLES_DIRECTIONS_IDS.forEach((directionId) => {
          defaultFields[directionId] = route[snakeCase(directionId)];
        });
        defaultFields["transportationVendorId"] = route[`${selectedDirection}_vendor_id`];
      } else {
        defaultFields["transportationVendorId"] = route.transportation_vendor_id;
      }
    }
    actions.initializeForm(defaultFields);
  }, [route]);

  if (!open) return null;

  const onSubmit = () => {
    actions.submit({ routeId: route.id, onSuccess: onSuccess, page: page, date: date });
  };

  const toggleSimulateTrackingDialog = () => {
    actions.submitTrackingForm(editTrip.id);
  };

  const handleClose = () => {
    onClose();
    actions.setBookingEdit(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      scroll="paper"
    >
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          <FormDialogTitle onClose={handleClose}>
            <Typography variant="h1">{I18n.t("ui.vendor_schedule.add")}</Typography>
            {route && state.schedulesUpdatedAt && (
              <Typography className={cls.greyLabel}>
                {I18n.t("ui.vendor_schedule.last_updated", {
                  date: state.schedulesUpdatedAt,
                  tz: route.time_zone_abbr,
                  user: state.schedulesUpdatedBy
                })}
              </Typography>
            )}
            <RequiredNote />
          </FormDialogTitle>
          <Divider className={cls.divider} />
          <VendorScheduleForm
            cls={cls}
            store={store}
            route={route}
            page={page}
            trip={editTrip}
            disabledForStaff={disabledForStaff}
          />
          {state.futureAssignmentsWarning && (
            <WarningLabel
              nowrap={false}
              label="There are future one-day assignments to this route that are exceptions and will override this permanent change on those days."
            />
          )}
          <FormDialogActions
            onSubmit={onSubmit}
            onClose={handleClose}
            disabled={!state.isValid || disabledForStaff}
            submitLabel={I18n.t("data_management.assigned_vehicles.form.submit")}
          >
            {SIMULATE_TRACKING_ENVS.includes(ENV.RAILS_ENV) && isUserAdmin && (
              <>
                <div style={{ order: 2, flexGrow: 1 }}></div>
                <Button
                  key="trip-action-tracking"
                  variant="outlined"
                  className={cls.btn}
                  style={{ order: 3 }}
                  onClick={toggleSimulateTrackingDialog}
                >
                  {I18n.t("trip.actions.simulate_tracking")}
                </Button>
              </>
            )}
          </FormDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default VendorScheduleDialog;
