import React, { Fragment } from "react";
import cn from "classnames";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import ErrorList from "components/admin_v2/ui/ErrorList";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import I18n from "utils/i18n.js";

const GroupContactsForm = ({ store }) => {
  const cls = useTableFormStyles();
  const [state, actions] = store;
  const { groupContacts, errors } = state;

  const updateContact = (index, value) => {
    actions.updateGroupContact({ index, value });
  };

  const remove = (index) => {
    if (window.confirm("Are you sure?")) {
      actions.removeGroupContact(index);
    }
  };

  return (
    <Fragment>
      <ErrorList errors={errors} prefix="groupContacts" />
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell className={cls.tableHeader}>
              {I18n.t("school_config.group_contact.form.contact.label")}
            </TableCell>
            <TableCell className={[cls.tableHeader, cls.iconCell].join(" ")}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupContacts?.map((groupContact, index) =>
            groupContact._destroy ? null : (
              <TableRow hover tabIndex={-1} key={`contact-${index}-row`}>
                <TableCell className={cls.cell}>
                  <FormControl className={cls.field}>
                    <Autocomplete
                      className="group-contact-contact"
                      options={state.contactOptions}
                      getOptionLabel={(option) => option.name || ""}
                      onChange={(_, val) => updateContact(index, val)}
                      size="small"
                      value={
                        state.contactOptions.find(
                          (co) =>
                            co.id == groupContact.contactableId &&
                            co.type == groupContact.contactableType
                        ) || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth variant="outlined" />
                      )}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={[cls.cell, cls.iconCell].join(" ")}>
                  <Link
                    color="secondary"
                    onClick={() => remove(index)}
                    className={cls.remove}
                    underline="hover"
                  >
                    <DeleteOutline />
                  </Link>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <Button
        className={cn("group-contact-add", cls.btn)}
        variant="contained"
        color="secondary"
        onClick={actions.newGroupContact}
      >
        {I18n.t("school_config.new_group_contact.btn")}
      </Button>
    </Fragment>
  );
};

export default GroupContactsForm;
