import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const ADMIN_UNSCOPED_PATHS = [
  "districts",
  "drivers",
  "exports",
  "programs",
  "reports",
  "route_imports",
  "schools",
  "search",
  "tracking_devices",
  "trips",
  "users",
  "vehicles",
  "vendors",
  "vendor_staff",
  "routes/paired"
];

const PAGE_PATHS =
  ADMIN_UNSCOPED_PATHS +
  [
    "curbside",
    "students",
    "routes",
    "reports",
    "trips",
    "changelog",
    "text_blasts",
    "stop_locations",
    "dismissal_groups",
    "communication_groups",
    "queues"
  ];

export const DISTRICT_ALL_SLUG = "all-districts";

// school slug is on index 3, district is on 2; ["", "admin", "district slug", "school slug"]
export const DISTRICT_SLUG_INDEX = 2;
export const SCHOOL_SLUG_INDEX = 3;

export const isUnscopedPath = (pathname = null) => {
  let path = pathname || history.location.pathname;
  return ADMIN_UNSCOPED_PATHS.some((p) => path.match(`/admin/${p}`));
};

export const isPagePart = (part) => PAGE_PATHS.includes(part);

export const getDistrictSlugFromPath = () => {
  const pathname = history.location.pathname;
  return isUnscopedPath(pathname) ? null : pathname.split("/")[DISTRICT_SLUG_INDEX];
};

export const getSchoolSlugFromPath = () => {
  const pathname = history.location.pathname;
  return isUnscopedPath(pathname) ? null : pathname.split("/")[SCHOOL_SLUG_INDEX];
};

export default history;
