import React from "react";
import classNames from "classnames";
import { Box, Grid, Paper, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState } from "easy-peasy";
import { parseISO, isToday, isPast, format as dateFormat } from "date-fns";
import { sameDayOrAfter } from "lib/dates";
import { concat, filter, isEmpty } from "lodash";
import { DayOffLabel } from "components/admin_v2/ui/DayOffLabel";
import CustomTrips from "./weekly/CustomTrips";
import DayTrip from "./weekly/DayTrip";
import UnenrolledLabel from "./common/UnenrolledLabel";
import I18n from "utils/i18n.js";

const CalendarDay = ({
  dayTrips: { from_school, to_school, from_custom, to_custom, date, day_off },
  index
}) => {
  const cls = useStyles();
  const day = parseISO(date);
  const today = isToday(day);
  const { discarded, unenrolled_at, school_id } = useStoreState((s) => s.calendar.student);

  const wrapperCls = classNames(cls.dayWrapper, { past: !today && isPast(day) });
  const headerCls = classNames(cls.dayHeader, { today: today });

  const customTrips = filter(concat(from_custom, to_custom), (t) => !isEmpty(t.stops));
  const unenrolled = discarded && sameDayOrAfter(day, parseISO(unenrolled_at));
  return (
    <Grid item className={wrapperCls} data-test-id="dayWrapper">
      <Paper elevation={4}>
        <Box className={headerCls}>
          <div className={cls.dayTitle}>{dateFormat(day, "EEEE")}</div>
          <div>{dateFormat(day, "LLLL d")}</div>
        </Box>
        <Box p={2}>
          <Box className={cls.dayTrip} mb={5}>
            {day_off ? (
              <DayOffLabel label={I18n.t("ui.day_off.calendar")} py={1} />
            ) : unenrolled ? (
              <UnenrolledLabel label={I18n.t("students.unenrolled")} py={1} />
            ) : (
              <DayTrip day={day} trip={to_school} tripType="to_school" index={index} />
            )}
          </Box>
          <Box className={cls.dayTrip}>
            {!day_off && !unenrolled && (
              <DayTrip day={day} trip={from_school} tripType="from_school" index={index} />
            )}
          </Box>
        </Box>
        {unenrolled ? (
          <>
            <Divider />
            <Box p={2} height="64px"></Box>
          </>
        ) : (
          <CustomTrips day={day} trips={customTrips} index={index} schoolId={school_id} />
        )}
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  dayWrapper: {
    flex: 1,
    height: "100%",
    color: theme.palette.text.primary,
    "&.past": {
      color: theme.custom.MEDIUM_GREY,
      opacity: "0.5"
    }
  },
  dayHeader: {
    textAlign: "center",
    padding: theme.spacing(2),
    borderBottom: "solid 1px",
    borderBottomColor: theme.custom.MEDIUM_GREY,
    cursor: "pointer",
    "&.today": {
      color: theme.palette.primary.main,
      backgroundColor: theme.custom.LIGHT_BLUE
    }
  },
  dayTitle: {
    fontWeight: theme.custom.BOLD,
    fontSize: "0.87rem"
  },
  dayTrip: {
    minHeight: theme.spacing(24)
  }
}));

export default CalendarDay;
