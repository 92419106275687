import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import { filter, sortBy } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "../common/useCommonPageStyles";

const ALL = I18n.t("trip.avl_filters.all_schools");
const NO_DISTRICT_LABEL = "Without District";

const MultiSchoolsSelect = ({
  schools,
  schoolIds,
  onChange,
  withAllOption = false,
  withClearSelection = false,
  onClear,
  multiple = true,
  disabled = false,
  rest
}) => {
  const cls = useStyles();
  const cCls = useCommonPageStyles();

  const groupBy = (option) => {
    if (option == ALL) return option;
    return option.district_name || NO_DISTRICT_LABEL;
  };

  const renderOption = (props, option, input) => {
    return (
      <FormControlLabel
        key={`schools-select--${option?.id}`}
        className="schools-select--option"
        control={<GreenCheckbox checked={input.selected} value={option.id} />}
        label={
          option.name ? (
            <>
              <span className={cCls.schoolCode}>{option.code}</span> {option.name}
            </>
          ) : (
            ""
          )
        }
        style={{ marginLeft: "1em" }}
        {...props}
      />
    );
  };

  const selectGroup = (groupName, checked) => {
    const selectedSchools = schools.filter((s) => {
      const inGroup =
        groupName == ALL
          ? true
          : s.district_name
          ? s.district_name === groupName
          : groupName === NO_DISTRICT_LABEL;
      const alreadySelected = schoolIds.indexOf(s.id) > -1;
      return checked ? !inGroup && alreadySelected : inGroup || alreadySelected;
    });
    onChange({}, selectedSchools);
  };

  const renderGroup = (params) => {
    const grouped =
      params.group == ALL
        ? schools
        : schools.filter((s) => {
            return s.district_name
              ? s.district_name === params.group
              : params.group === NO_DISTRICT_LABEL;
          });
    const checked = grouped.length > 0 && grouped.every((s) => schoolIds.indexOf(s.id) > -1);

    return (
      <Box key={params.key}>
        <FormControlLabel
          key={`schools-select-group--${params.key}`}
          control={
            <GreenCheckbox
              checked={checked}
              value={params.group}
              onChange={() => selectGroup(params.group, checked)}
            />
          }
          label={params.group || ""}
          style={{ marginLeft: "0.5em" }}
        />
        {!(params.group == ALL) && params.children}
      </Box>
    );
  };

  let options = sortBy(schools, "district_name");
  if (withAllOption) {
    options.unshift(ALL);
  }

  return (
    <Box className={withClearSelection ? cls.wrapper : ""}>
      <Autocomplete
        multiple={multiple}
        options={options}
        groupBy={groupBy}
        getOptionLabel={(option) =>
          option.code && option.name ? `${option.code}:  ${option.name}` : option.name || ""
        }
        renderOption={renderOption}
        renderGroup={renderGroup}
        disableCloseOnSelect={true}
        onChange={onChange}
        size="small"
        disabled={disabled}
        value={
          multiple
            ? filter(schools, (s) => schoolIds.indexOf(s.id) > -1)
            : schools.find((s) => s.id === schoolIds) || null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            placeholder={schoolIds.length === 0 ? "Select Schools" : ""}
          />
        )}
        {...rest}
      />
      {withClearSelection && (
        <Box className={cls.clearSelection}>
          <span>
            {`${schoolIds.length} of ${schools.length} selected`}
            <Link onClick={onClear} className={cls.clearLink}>
              (Clear Selection)
            </Link>
          </span>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center"
  },
  clearSelection: {
    marginLeft: theme.spacing(2),
    fontStyle: "italic",
    display: "flex"
  },
  clearLink: {
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  }
}));

export default MultiSchoolsSelect;
