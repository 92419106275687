import React, { Fragment } from "react";
import { Dialog, Typography, Box } from "@mui/material";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import { get } from "lodash";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import StopsForm from "./stop/StopsForm";
import SavedStop from "./stop/SavedStop";
import I18n from "utils/i18n.js";
import { addStopStore } from "./stores/addStopStore";

const AddStopDialog = ({ open, toggleDialog }) => {
  const { school } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const { route, date, direction, savedLocationId } = useStoreState((s) => s.routeEditor);
  const { updateSchedule, setSavedLocationId } = useStoreActions((s) => s.routeEditor);
  const stopDialogStore = useLocalStore(() => addStopStore());
  const [state, actions] = stopDialogStore;

  const closeDialog = () => {
    actions.resetState();
    toggleDialog();
  };

  const onSubmit = () => {
    const schoolId =
      school?.id || state.schoolId || (route.routable_type === "school" ? route.routable_id : null);

    actions
      .submit({ routeId: route.id, schoolId: schoolId })
      .then((response) => {
        actions.setStopSaved(true);
        setSavedLocationId(response.location_id);
        setFlashMessage({ message: response.message });
        updateSchedule({ date: date, direction: direction });
      })
      .catch((err) => {
        const error = get(err.response, "data.message", I18n.t("stop.create.message.fail"));
        actions.setErrors({ "": error });
      })
      .finally(() => actions.setLoading(false));
  };

  const onRollback = () => {
    actions
      .rollback({ routeId: route.id, stopLocationId: savedLocationId })
      .then((response) => {
        updateSchedule({ date: date, direction: direction });
        setFlashMessage({ message: response.message });
        setSavedLocationId(null);
        actions.setStopSaved(false);
      })
      .catch((err) => {
        const error = get(err.response, "data.message", I18n.t("stop.rollback.message.fail"));
        setFlashMessage({ message: error });
      })
      .finally(() => actions.setLoading(false));
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      {state.stopSaved ? (
        <SavedStop store={stopDialogStore} closeDialog={closeDialog} onRollback={onRollback} />
      ) : (
        <Fragment>
          <FormDialogTitle onClose={closeDialog}>
            <Typography variant="h1">Add a Stop to Route</Typography>
            <Box mt={1}>
              <Typography variant="h2">
                {route ? `${route.name} | ${route.vehicle_type}` : null}
              </Typography>
              {route?.with_schedule && (
                <Typography variant="subtitle1" color="error">
                  {route.schedule_details}
                </Typography>
              )}
            </Box>
          </FormDialogTitle>
          <FormDialogContent>
            <StopsForm store={stopDialogStore} />
          </FormDialogContent>
          <FormDialogActions
            onSubmit={onSubmit}
            onClose={closeDialog}
            disabled={state.isDisabled}
          />
        </Fragment>
      )}
    </Dialog>
  );
};

export default AddStopDialog;
