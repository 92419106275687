import React from "react";
import classNames from "classnames";
import { Link, TableCell, Tooltip, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { grey } from "@mui/material/colors";
import { last, snakeCase } from "lodash";
import EventChip from "components/admin_v2/ui/EventChip";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";

const WO_CONFIRM_EVENT_LIST = ["activity", "cancel", "change", "one_off", "remove", "no_show"];
const REJECT_EVENT_LIST = ["activity", "cancel", "change", "one_off", "remove", "no_show"];
export const CANCEL_EVENT_LIST = ["activity", "cancel", "no_show"];

export const yellowEvent = (eventType) =>
  ["activity", "cancel", "ppu", "change", "change_req"].includes(eventType);
export const redEvent = (eventType) => ["one_off", "no_show", "remove"].includes(eventType);

export const confirmEvent = ({ currentEvent, eventType, eventText = null }) => {
  const cancelMessage = () => {
    if (!eventText) return `cancel this ${I18n.t(`student.event.${currentEvent}`)}`;
    return I18n.t(`student.event.revert.${snakeCase(eventText)}`);
  };
  const confirmationMessage =
    eventType !== "ok"
      ? `Would you like to add ${eventText || eventType.toUpperCase()}?`
      : `Would you like to ${cancelMessage()}?`;

  if (!WO_CONFIRM_EVENT_LIST.includes(eventType) && !confirm(confirmationMessage)) {
    return false;
  }
  return true;
};

const CrossedOutTableCell = withStyles((_theme) => ({
  body: {
    color: grey[400],
    textDecoration: "line-through"
  }
}))(TableCell);

export const IconCell = withStyles((theme) => ({
  body: {
    padding: theme.spacing(0.5),
    paddingRight: 0,
    textAlign: "right",
    width: "1.25rem"
  }
}))(TableCell);

export const tableCellType = (eventType, dayOff = false) =>
  dayOff || REJECT_EVENT_LIST.includes(eventType) ? CrossedOutTableCell : TableCell;

const EVENT_LABELS = {
  pass: "yellow",
  new: "green",
  cancel: "yellow",
  no_show: "red",
  activity: "yellow",
  one_off: "red"
};

export const EventLabel = ({ addEvent, trip, eventType = null }) => {
  let label = !addEvent && eventType === "one_off" ? null : eventType;
  if (addEvent) label = trip.has_one_off || eventType === "one_off" ? "one_off" : "pass";
  if (trip?.first_day) label = "new";
  const labelData = label ? EVENT_LABELS[label] : null;
  if (!labelData) return null;

  return (
    <Tooltip title={I18n.t(`student.event.icon.${label}.tooltip`)} arrow={true}>
      <Box>
        <EventChip type={labelData} label={I18n.t(`student.event.icon.${label}.text`)} />
      </Box>
    </Tooltip>
  );
};

export const EventStatus = ({ events, changeRequestId = null, onChangeRequest = null }) => {
  const cls = useStyles();
  if (changeRequestId) {
    return (
      <span className={cls.status}>
        <span className={classNames(cls.event, { greenEvent: true })}>
          {I18n.t("student.event.reason.requested")}
        </span>
        {onChangeRequest ? (
          <Link
            component="button"
            underline="always"
            onClick={onChangeRequest}
            sx={{ verticalAlign: "baseline" }}
          >
            #{changeRequestId}
          </Link>
        ) : (
          `#${changeRequestId}`
        )}
      </span>
    );
  }

  const eventStatus = (event) => {
    if (!event) return null;
    const textClass = classNames(
      cls.event,
      cls.status,
      { yellowEvent: yellowEvent(event.event_type) },
      { redEvent: redEvent(event.event_type) }
    );
    if (event.status) {
      const reason = I18n.t(`student.event.reason.${event.event_type}`);
      const details = I18n.t(`student.event.status.${event.status}`);
      return (
        <span key={`status-${event.event_type}`} className={cls.status}>
          {reason}
          <span className={textClass}>{details}</span>
        </span>
      );
    } else {
      return (
        <span key={`status-${event.event_type}`} className={textClass}>
          {I18n.t(`student.event.${event.event_type}`)}
        </span>
      );
    }
  };

  return eventStatus(last(events));
};

const useStyles = makeStyles((theme) => ({
  status: {
    fontWeight: theme.custom.SEMI_BOLD
  },
  red: {
    backgroundColor: theme.custom.RED
  },
  yellow: {
    backgroundColor: theme.custom.YELLOW
  },
  green: {
    backgroundColor: theme.custom.GREEN
  },
  grey: {
    backgroundColor: theme.custom.MEDIUM_GREY
  },
  event: {
    "&.redEvent": { color: theme.custom.RED },
    "&.yellowEvent": { color: theme.custom.YELLOW },
    "&.greenEvent": { color: theme.custom.GREEN }
  }
}));
