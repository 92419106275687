import React, { useEffect } from "react";
import { useLocalStore, useStoreActions } from "easy-peasy";
import { useHistory } from "react-router";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import I18n from "utils/i18n.js";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import DismissalGroupForm from "./DismissalGroupForm";
import { dismissalGroupsStore } from "components/admin_v2/school_config/stores/dismissalGroupsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DismissalGroupEdit = (props) => {
  const id = props.match.params.id;
  const cls = useCommonPageStyles();
  const history = useHistory();
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const store = useLocalStore(() => dismissalGroupsStore());
  const [state, actions] = store;
  const { dismissalGroup } = state;

  useEffect(() => {
    if (!id) return;

    actions.fetchDismissalGroup(id);
  }, [id]);

  const onSubmit = () => {
    actions.save().then((r) => {
      setFlashMessage({ message: r.message });
      history.push(pageRoutes.dismissalGroup(r.dismissalGroup.id));
    });
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <BreadCrumbs
        path={pageRoutes.dismissalGroups()}
        label={I18n.t("school_config.dismissal_groups.breadcrumb")}
      />
      <Box className={cls.titleWithBtn}>
        <Typography variant="h1" gutterBottom>
          {dismissalGroup?.name}
        </Typography>
        <Box className={cls.btnsWrapper}>
          <OutlinedBtn className={`${cls.actionBtn} active`} disabled>
            {I18n.t("school_config.edit_dismissal_group.btn")}
          </OutlinedBtn>
          <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
            {dismissalGroup?.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
          </OutlinedBtn>
        </Box>
      </Box>
      <Paper className={cls.paper}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <DismissalGroupForm store={store} onSubmit={onSubmit} />{" "}
            <PrimaryBtn onClick={onSubmit} disabled={!state.isValid}>
              {I18n.t("school_config.dismissal_groups.form.save")}
            </PrimaryBtn>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default DismissalGroupEdit;
