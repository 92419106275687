import { action, thunk } from "easy-peasy";
import { easyStateSetters } from "lib/easyState";
import { setFlashMessage } from "services";
import { fetchChangeRequest } from "services/apiChangeRequests";

export const defaultState = {
  // status
  reqDialogOpen: false,
  // data
  dialogChangeReq: null
};

export const changeReqDialogModel = (initialData = {}) => ({
  ...easyStateSetters(defaultState, initialData),

  closeReqDialog: action((state) => {
    state.reqDialogOpen = false;
    state.dialogChangeReq = null;
  }),

  setReqDialog: action((state, chReq) => {
    state.dialogChangeReq = chReq;
    state.reqDialogOpen = true;
  }),

  openReqDialog: thunk((actions, params, h) => {
    if (h.getState().dialogChangeReq?.id == params?.id) return;
    if (params?.student) {
      actions.setReqDialog(params);
    } else {
      return fetchChangeRequest(params?.id)
        .then((chReq) => actions.setReqDialog(chReq))
        .catch((err) => setFlashMessage(err.message));
    }
  })
});
