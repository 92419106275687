import React from "react";
import { FormControl } from "@mui/material";
import { isEmpty } from "lodash";
import AddressSelect from "../address/AddressSelect";
import ToggleCreate from "components/admin_v2/common/ToggleCreate";
import StopNewForm from "./StopNewForm";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";

const StopLocationForm = ({ store, cls }) => {
  const [state, actions] = store;
  const labelKeys = {
    choose: I18n.t("ui.stop_location.choose"),
    new: I18n.t("ui.stop_location.new")
  };
  const labels = { links: labelKeys, labels: labelKeys };

  return (
    <FormControl fullWidth required error={!isEmpty(state.errors)}>
      <ErrorList errors={state.errors} />
      <ToggleCreate
        labels={labels}
        cls={cls}
        create={state.workflow?.newLocation}
        setCreate={actions.toggleLocation}
      />
      {state.workflow?.newLocation ? (
        <StopNewForm store={store} cls={cls} />
      ) : (
        <AddressSelect store={store} cls={cls} />
      )}
    </FormControl>
  );
};

export default StopLocationForm;
