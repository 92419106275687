import React from "react";
import { Typography } from "@mui/material";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DistrictConfigTabs from "components/admin_v2/district_config/DistrictConfigTabs";
import DistrictForm from "components/admin_v2/district_config/districts/DistrictForm";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";

const NEW_DISTRICT = {};

const DistrictNew = (_props) => {
  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("district_config.title")} />
      <DistrictConfigTabs />
      <BreadCrumbs
        path={pageRoutes.districts()}
        label={I18n.t("district_config.districts.breadcrumb")}
      />
      <Typography variant="h1" gutterBottom>
        {I18n.t("district_config.new_district.title")}
      </Typography>
      <DistrictForm district={NEW_DISTRICT} />
    </React.Fragment>
  );
};

export default DistrictNew;
