import React from "react";
import { Box, Typography } from "@mui/material";
import EventSelect from "components/admin_v2/common/EventSelect";
import TripEditorForm from "../common/TripEditorForm";
import TripChangeEditor from "./TripChangeEditor";
import UndoPersistentChange from "../common/UndoPersistentChange";
import useTripEditorStyles from "../common/useTripEditorStyles";
import { getAnchorType } from "components/admin_v2/students/stores/stopsModel";
import I18n from "utils/i18n.js";

const CustomTrip = ({ modeChangeStore, trip, idx }) => {
  const [state, actions] = modeChangeStore;
  const cls = useTripEditorStyles();
  const tripData = state.getTripData(idx);
  const isAddChangeRequest = tripData.change_request && !tripData.stop;
  let anchorType,
    stops = [];

  const tripType = trip.trip_type;
  const tripDetails = state.getDetails(idx);

  if (!tripDetails.isAddTrip) {
    anchorType = getAnchorType(tripType);
    if (isAddChangeRequest) {
      stops = [null];
    } else {
      if (tripData.stop) {
        const { anchor } = tripData.stop;
        stops = anchorType === "dropoff" ? [tripData.stop, anchor] : [anchor, tripData.stop];
      } else {
        stops = [null];
      }
    }
  }

  const updateStudentAttendance = (eventType) => {
    if (eventType === tripDetails.eventType) return;
    actions.updateCalendarTrip({ idx, eventType });
  };

  const tripForms = stops.map((stop, stopIdx) => (
    <TripEditorForm
      key={`tre-${stopIdx}`}
      trip={trip}
      tripType={trip.trip_type}
      stop={stop}
      student={state.student}
      route={tripData.route}
      day={state.currentDate}
      label={I18n.t(`ui.stop.label.${stopIdx === 0 ? "pickup" : "dropoff"}`)}
      tripDetails={tripDetails}
      withChangeRequestLabel={stopIdx === 0}
      onEventSelect={stop?.anchor_types ? null : updateStudentAttendance}
    >
      {!stop?.anchor_types && <UndoPersistentChange modeChangeStore={modeChangeStore} idx={idx} />}
    </TripEditorForm>
  ));

  return (
    <TripChangeEditor modeChangeStore={modeChangeStore} tripType={tripType} idx={idx}>
      {tripDetails.isAddTrip ? (
        <Box pt={2} px={2} display="flex" justifyContent="space-between" width="100%">
          <Typography variant="subtitle1" className={cls.label}>
            {I18n.t("ui.mode_editor.add_trip")}
          </Typography>
          <Box>
            <EventSelect eventType="change" disabled={true} />
          </Box>
        </Box>
      ) : (
        tripForms
      )}
    </TripChangeEditor>
  );
};

export default CustomTrip;
