import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { StoreProvider } from "easy-peasy";
import store from "context/admin_v2/store";
import history from "context/history";
import theme from "./theme";
import Home from "./Home";

const App = (props) => {
  window.__testing = ENV?.RAILS_ENV === "test" ? {} : undefined;

  store.getActions().app.initialState(props);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StoreProvider store={store}>
            <React.Fragment>
              <CssBaseline />
              <Router history={history}>
                <CompatRouter>
                  <Home />
                </CompatRouter>
              </Router>
            </React.Fragment>
          </StoreProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
