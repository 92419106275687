import fileDownload from "js-file-download";
import { reject, isNil } from "lodash";
import xhr from "lib/xhr";
import { queryString } from "lib/util";
import store from "context/admin_v2/store";
import { DISTRICT_ALL_SLUG } from "context/history";

export const apiUrl = (path) => `/admin/api/${path}`;
export const vendorsApiUrl = (path) => `/vendors/api/${path}`;
export const parentsApiUrl = (path) => `/parents/api/${path}`;

export const saveScheduleChange = async (params) => {
  const url = apiUrl(`stops/override_student_stops`);
  return await xhr.post(url, params);
};

export const fetchStops = async (params = {}) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`stops?${query}`));
};

export const fetchChangelogs = async (params = {}) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`changelogs?${query}`));
};

export const downloadChangelogCsv = async (params) => {
  const url = apiUrl("changelogs/csv");
  return await xhr.post(url, params, { preserveResponse: true }).then((response) => {
    let filename = response.headers["content-disposition"].match(/filename="(.+)"/) && RegExp.$1;
    fileDownload(response.data, filename);
  });
};

export const addStop = async (params) => {
  const url = apiUrl(`stops/add_stops`);
  return await xhr.post(url, params);
};

export const updateStop = async (id, params) => {
  const url = apiUrl(`stops/${id}/update`);
  return await xhr.post(url, params);
};

export const deleteStop = async (params) => {
  const url = apiUrl(`stops/delete_stops`);
  return await xhr.post(url, params);
};

export const scopedPath = (path, slugs = {}, unscopedForAdmin = false) => {
  const { districtSlug, schoolSlug } = slugs || {};
  const { school, district, isUserAdmin } = store.getState().app;

  if (unscopedForAdmin && isUserAdmin) {
    return `/admin/${path}`;
  }

  return reject(
    [
      "",
      "admin",
      district ? districtSlug || district?.slug || DISTRICT_ALL_SLUG : DISTRICT_ALL_SLUG,
      schoolSlug || school?.slug,
      path
    ],
    isNil
  ).join("/");
};

export const pageRoutes = {
  // sessions & passwords:
  signIn: () => "/users/sign_in",
  newPassword: () => "/users/password/new",
  editPassword: () => "/users/password/edit",

  // comms:
  communication: (id) => (id ? `/communication/school/${id}` : "/communication"),

  // staff & admin pages, unscoped for admins:
  reports: () => scopedPath("reports", {}, true),
  reportFiles: () => scopedPath("reports/files", {}, true),

  // staff & admin pages, scoped for all:
  curbside: () => scopedPath("curbside"),
  queues: () => scopedPath("queues"),
  routes: () => scopedPath("routes"),
  route: (id, slugs = {}) => scopedPath(`routes/${id}`, slugs),
  routeEdit: (id, slugs = {}) => scopedPath(`routes/${id}/edit`, slugs),
  routeStops: (id, slugs = {}) => scopedPath(`routes/${id}/stops`, slugs),
  routesList: () => scopedPath("routes"),
  students: () => scopedPath("students"),
  student: (id, slugs = {}) => scopedPath(`students/${id}`, slugs),
  studentCalendar: (id) => scopedPath(`students/${id}`),
  studentProfile: (id) => scopedPath(`students/${id}/profile`),
  studentEdit: (id) => scopedPath(`students/${id}/edit`),
  studentNew: () => scopedPath("students/new"),

  // only admins views, unscoped:
  schools: () => "/admin/schools",
  schoolNew: () => `/admin/schools/new`,
  school: (id) => `/admin/schools/${id}`,
  devices: () => "/admin/tracking_devices",
  districts: () => "/admin/districts",
  districtNew: () => `/admin/districts/new`,
  district: (id) => `/admin/districts/${id}`,
  districtEdit: (id) => `/admin/districts/${id}/edit`,
  drivers: () => "/admin/drivers",
  driverNew: () => `/admin/drivers/new`,
  driver: (id) => `/admin/drivers/${id}`,
  driverEdit: (id) => `/admin/drivers/${id}/edit`,
  exports: () => `/admin/exports`,
  programs: () => "/admin/programs",
  program: (id) => `/admin/programs/${id}`,
  programEdit: (id) => `/admin/programs/${id}/edit`,
  vehicles: () => "/admin/vehicles",
  vehicle: (id) => `/admin/vehicles/${id}`,
  vehicleEdit: (id) => `/admin/vehicles/${id}/edit`,
  vendors: () => "/admin/vendors",
  vendorNew: () => `/admin/vendors/new`,
  vendor: (id) => `/admin/vendors/${id}`,
  vendorEdit: (id) => `/admin/vendors/${id}/edit`,
  vendorStaffs: () => "/admin/vendor_staff",
  vendorStaff: (id) => `/admin/vendor_staff/${id}`,
  vendorStaffEdit: (id) => `/admin/vendor_staff/${id}/edit`,
  vendorStaffNew: () => `/admin/vendor_staff/new`,
  districtConfig: () => "/admin/district_config",
  slackIntegration: (id) => `/slack/schools/${id}/integration`,
  search: () => "/admin/search",
  users: () => "/admin/users",
  user: (id) => `/admin/users/${id}`,
  userEdit: (id) => `/admin/users/${id}/edit`,
  userNew: () => "/admin/users/new",
  pairedRoutes: () => "/admin/routes/paired",
  advancedSearch: () => "/admin/advanced_search",

  // only admin views, scoped:
  changelog: () => scopedPath("changelog"),
  changelogCsv: () => scopedPath("changelog/csv"),
  commsGroups: () => scopedPath("communication_groups"),
  commsGroup: (id) => scopedPath(`communication_groups/${id}`),
  commsGroupEdit: (id) => scopedPath(`communication_groups/${id}/edit`),
  commsGroupNew: () => scopedPath(`communication_groups/new`),
  staffs: () => scopedPath("school_staff"),
  staff: (id) => scopedPath(`school_staff/${id}`),
  staffEdit: (id) => scopedPath(`school_staff/${id}/edit`),
  staffNew: () => scopedPath(`school_staff/new`),
  studentImports: () => scopedPath("student_imports"),
  routeImports: () => "/admin/route_imports",
  routeImportConflicts: (id) => `/admin/route_imports/${id}/conflicts`,
  textBlasts: () => scopedPath("text_blasts"),
  textBlast: (id) => scopedPath(`text_blasts/${id}`),
  stopLocations: () => scopedPath("stop_locations"),
  stopLocation: (id) => scopedPath(`stop_locations/${id}`),
  stopLocationEdit: (id) => scopedPath(`stop_locations/${id}/edit`),
  dismissalGroups: () => scopedPath("dismissal_groups"),
  dismissalGroup: (id) => scopedPath(`dismissal_groups/${id}`),
  dismissalGroupEdit: (id) => scopedPath(`dismissal_groups/${id}/edit`),

  // unscoped for all:
  trips: () => "/admin/trips",
  tripMap: (id) => `/admin/trips/${id}/map`,

  // only vendors views, unscoped:
  vendorRoot: (search) => `/vendors${search}`
};

export const slugsFor = (item) => {
  return {
    districtSlug: item?.district_slug || item?.districtSlug,
    schoolSlug: item?.school_slug || item?.schoolSlug
  };
};
