import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import cn from "classnames";
import { snakeCase } from "lodash";
import { Typography, Paper, Box, Grid } from "@mui/material";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import UserAssociations from "components/admin_v2/settings_config/users/UserAssociations";
import I18n from "utils/i18n.js";
import { usersStore } from "components/admin_v2/common/stores/usersStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DETAIL_FIELDS = [
  "email",
  "firstName",
  "lastName",
  "role",
  "systemUser",
  "vendor",
  "staffRole",
  "createdAt",
  "updatedAt"
];

const VendorStaff = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => usersStore({ role: "vendor_staff" }));
  const id = props.match.params.id;
  const user = state.user;

  useEffect(() => {
    if (!id) return;
    actions.fetchUser(id);

    return () => {
      actions.setUser(null);
    };
  }, [id]);

  const value = (prop, val) => {
    if (prop === "systemUser") {
      return val ? I18n.t("ui.positive") : I18n.t("ui.negative");
    } else {
      return val;
    }
  };

  const detail = (prop) => {
    return (
      <Box className={cn(`details-${prop}`, cls.detail)} key={prop}>
        <div className={cls.detailHeader}>
          {I18n.t(`vendor_config.staff.labels.${snakeCase(prop)}`)}
        </div>
        <div>{value(prop, user[prop]) || I18n.t("ui.empty_field")}</div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      <BreadCrumbs
        path={pageRoutes.vendorStaffs()}
        label={I18n.t("vendor_config.staffs.breadcrumb")}
      />
      {user && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {user.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.resetPassword(id)}>
              {I18n.t("ui.btn.reset_password")}
            </OutlinedBtn>
            <RouterLink className={cls.actionBtn} to={pageRoutes.vendorStaffEdit(user.id)}>
              <OutlinedBtn>{I18n.t("vendor_config.edit_staff.btn")}</OutlinedBtn>
            </RouterLink>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
              {user.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
            </OutlinedBtn>
          </Box>
        </Box>
      )}
      <Box my={5}>
        <Paper className={cn("staff-details", cls.paper)}>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              <Box className={cls.subTitle}>{I18n.t("vendor_config.staff.title.details")}</Box>
              <Grid container className={cls.grid} spacing={5}>
                <Grid item xs={6}>
                  {user && DETAIL_FIELDS.map(detail)}
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Box>
      {user && <UserAssociations user={user} />}
    </React.Fragment>
  );
};

export default VendorStaff;
