import React from "react";
import { FormControl, FormGroup, FormLabel, OutlinedInput } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";

const PeriodSelectorRepeat = ({ value, onChange }) => {
  const cls = useStyles();

  return (
    <FormControl fullWidth={true}>
      <FormLabel className={cls.label}>{I18n.t("ui.route.period.repeat")}</FormLabel>
      <FormGroup row={true}>
        <OutlinedInput
          className={cls.inputNumber}
          variant="outlined"
          type="number"
          inputProps={{ min: 1 }}
          value={value || 1}
          onChange={(e) => onChange(e.target.value)}
          required
        />
        <FormLabel className={cls.inputLabel}>{I18n.t("ui.route.period.repeat_range")}</FormLabel>
      </FormGroup>
    </FormControl>
  );
};

export const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  inputNumber: {
    maxWidth: theme.spacing(10)
  },
  inputLabel: {
    alignSelf: "center",
    marginLeft: theme.spacing(1)
  }
}));

export default PeriodSelectorRepeat;
