import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ChangeReqStopHeader from "./common/ChangeReqStopHeader";
import useTripEditorStyles from "components/admin_v2/mode_editor/common/useTripEditorStyles";
import ChangeReqForm from "./ChangeReqForm";
import I18n from "utils/i18n.js";

const ChangeReqTrip = ({ requestStore, changeReq }) => {
  const cls = useTripEditorStyles();
  const state = requestStore[0];
  const trip = state.getTrip;
  const label = I18n.t(`change_requests.dialog.change_from.${changeReq.trip_type}`);

  return (
    <Box className={cls.tripEditorWrapper}>
      <Box className={cls.tripInfo}>
        <Box className={cls.yellowBox}>
          <Typography variant="subtitle1" className={cls.label}>
            {`Change requested | ${changeReq.label}`}
          </Typography>
          <Typography variant="subtitle1">{`By: ${changeReq.requestor}`}</Typography>
        </Box>
        <Typography variant="subtitle1" className={cls.label}>
          {label}
        </Typography>
        <ChangeReqStopHeader requestStore={requestStore} changeReq={changeReq} cls={cls} />
      </Box>
      <Divider variant="fullWidth" className={cls.separator} />
      <Box className={cls.changeBox}>
        <ChangeReqForm
          requestStore={requestStore}
          changeReq={changeReq}
          currentRoute={trip?.route}
        />
      </Box>
    </Box>
  );
};

export default ChangeReqTrip;
