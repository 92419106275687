import React from "react";
import { useStoreState } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import NumberChip from "components/admin_v2/ui/NumberChip";
import { randKey } from "lib/util";
import StopTimeInput from "./StopTimeInput";
import { getStopMark, stopAddress } from "./common";

const StopRow = ({ stopsRow, stopNumber }) => {
  const cls = useStyles();
  const { savedLocationId, selectedDay } = useStoreState((s) => s.routeEditor);
  // highlight recently added stop
  const newStop = !savedLocationId ? false : savedLocationId === stopsRow.stop_location.id;
  const isSelected = (stopDate) => stopDate === selectedDay;
  const stopMark = getStopMark(stopsRow);

  return (
    <TableRow key={`stop-location-${stopsRow.stop_location.id}`}>
      <TableCell component="td" scope="row" align="left">
        <Box className={cls.stopAddress}>
          <Box className={cls.locationNum}>
            <NumberChip type={stopMark.type}>{stopMark.mark || stopNumber}</NumberChip>
          </Box>
          <Box className={cls.addressWrapper}>{stopAddress(stopsRow.stop_location)}</Box>
        </Box>
      </TableCell>
      {stopsRow.stops.map(({ stop, date }) => (
        <StopTimeInput
          stop={stop}
          date={date}
          newStop={newStop}
          isSelected={isSelected(date)}
          key={`stop-${stop ? stop.id : randKey()}`}
        />
      ))}
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  stopAddress: { display: "flex", alignItems: "center" },
  locationNum: {
    marginRight: theme.spacing(2)
  },
  selectedCol: {
    backgroundColor: theme.palette.background.lightBlue
  }
}));

export default StopRow;
