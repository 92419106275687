import React from "react";
import Box from "@mui/material/Box";
import { useStoreState } from "easy-peasy";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import { useFilterStyles, ScopeButtons } from "components/admin_v2/common/FilterHelpers";
import { reportTypes } from "./ReportHelpers";

const PERIOD_OPTIONS = [
  { id: "past_7", name: "Past 7 Days" },
  { id: "past_14", name: "Past 14 Days" },
  { id: "past_30", name: "Past 30 Days" }
];

const SCOPE_OPTIONS = [
  ["self", "My Reports"],
  ["all", "All Reports"]
];

const ReportFilesFilters = ({ reportStore, updateQueryString }) => {
  const cls = useFilterStyles();
  const [state, _actions] = reportStore;
  const { schools, user, isUserAdmin, hasMultipleSchools } = useStoreState((s) => s.app);

  return (
    <Box className={cls.options}>
      <ScopeButtons
        options={SCOPE_OPTIONS}
        currentScope={state.scope}
        onChange={updateQueryString}
      />
      {(isUserAdmin || hasMultipleSchools) && (
        <SelectFilter
          id="school-filter"
          label="All Schools"
          value={state.schoolId || ""}
          onChange={(e) => updateQueryString({ page: 1, school_id: e.target.value })}
          items={schools}
        />
      )}
      <SelectFilter
        id="period-filter"
        label="All Time"
        value={state.period || ""}
        onChange={(e) => updateQueryString({ page: 1, period: e.target.value })}
        items={PERIOD_OPTIONS}
      />
      <SelectFilter
        className={cls.selector}
        label="All Reports"
        value={state.reportType || ""}
        onChange={(e) => updateQueryString({ page: 1, report_type: e.target.value })}
        items={reportTypes(user, false)}
      />
    </Box>
  );
};

export default ReportFilesFilters;
