import { computed, thunk } from "easy-peasy";
import easyState from "lib/easyState";
import { groupBy, sortBy } from "lodash";
import { setFlashMessage } from "services";
import { fetchSchoolsList } from "services/apiSchools";
import { fetchDistrictsList } from "services/apiDistricts";

// Store for fetching all districts/schools as we need them at settings edit
const scopeStore = (_initialData) => ({
  ...easyState("allDistricts", []),
  ...easyState("allSchools", []),

  groupedSchools: computed((state) =>
    groupBy(sortBy(state.allSchools, "district_name"), "district_name")
  ),

  fetchAllScopes: thunk((actions, _params = {}) =>
    Promise.all([fetchDistrictsList({ withAll: true }), fetchSchoolsList({ withAll: true })])
  ),

  fetchAllDistricts: thunk((actions, params = {}) => {
    fetchDistrictsList({ withAll: true })
      .then((resp) => {
        actions.setAllDistricts(resp.districts);
      })
      .catch((err) => {
        if (params.withError) throw err;
        setFlashMessage(err.message);
      });
  }),

  fetchAllSchools: thunk((actions, params = {}) => {
    fetchSchoolsList({ withAll: true })
      .then((resp) => {
        actions.setAllSchools(resp.schools);
      })
      .catch((err) => {
        if (params.withError) throw err;
        setFlashMessage(err.message);
      });
  })
});

export default scopeStore;
