import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const getCurbsideFilters = async (params = {}) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`daily_schedule/filters?${query}`), { transformData: true });
};

export const getDailySchedule = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`daily_schedule?${query}`));
};
