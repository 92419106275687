import React, { Fragment } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import VendorStaffForm from "./VendorStaffForm";
import I18n from "utils/i18n.js";
import { usersStore } from "components/admin_v2/common/stores/usersStore";
import { pageRoutes } from "services/api";

const VendorStaffNew = (_props) => {
  const { vendorId } = useStoreState((s) => s.vendor);
  const [state, _actions] = useLocalStore(() => usersStore({ role: "vendor_staff" }));

  const newUser = { transportationVendorId: vendorId, systemUser: false };

  return (
    <Fragment>
      <TopPageLayout title={I18n.t("vendor_config.new_staff.title")} />
      <VendorConfigTabs />

      <BreadCrumbs
        path={pageRoutes.vendorStaffs()}
        label={I18n.t("vendor_config.staffs.breadcrumb")}
      />
      <VendorStaffForm role={state.role} user={newUser} />
    </Fragment>
  );
};

export default VendorStaffNew;
