import React from "react";
import { useStoreState } from "easy-peasy";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatPhone, randKey } from "lib/util";
import { pageRoutes, slugsFor } from "services/api";
import Language from "components/shared/Language";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import ButtonLink from "components/admin_v2/ui/ButtonLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import StudentUnenrolled from "./common/StudentUnenrolled";
import I18n from "utils/i18n.js";

const StudentHeader = ({ student, children, view = "calendar" }) => {
  const cls = useStyles();
  const { readOnly } = useStoreState((s) => s.app);
  const { id, name, grade, program, contact, discarded } = student;
  const riderType = student.rider_type || student.riderType;

  const slugs = slugsFor(student);

  const subtitle = (
    <BreadCrumbs
      path={pageRoutes.students()}
      label={I18n.t("data_management.students.breadcrumb")}
      sub={[name, I18n.t(`student.rider_type.${riderType}`), id].filter((s) => s).join(" - ")}
    />
  );

  let sideInfo = [];
  if (program) sideInfo.push(program);
  if (grade) sideInfo.push(`Grade ${grade}`);

  const buttons = [
    { type: "calendar", label: "Calendar", path: pageRoutes.studentCalendar(id, slugs) },
    { type: "profile", label: "Profile", path: pageRoutes.studentProfile(id, slugs) },
    { type: "edit", label: "Edit", path: pageRoutes.studentEdit(id, slugs) }
  ].map((btn) => {
    if (view === "calendar" && btn.type === "edit") return null;
    if (readOnly && btn.type === "edit") return null;

    let btnProps =
      view === btn.type
        ? { variant: "contained", color: "secondary" }
        : { variant: "outlined", color: "primary" };

    return (
      <ButtonLink className={cls.btn} key={randKey()} {...btnProps} to={btn.path}>
        {btn.label}
      </ButtonLink>
    );
  });

  const title = discarded ? <StudentUnenrolled student={student} /> : name;

  return (
    <TopPageLayout
      title={title}
      subtitle={subtitle}
      filters={
        <Box>
          <>
            <Typography component="div" variant="subtitle1" className={cls.sideInfo}>
              {sideInfo.join(" | ")}
            </Typography>
            <Typography component="div" variant="subtitle1" className={cls.sideInfo}>
              {formatPhone(contact)}
              <aside>
                <Language target={student} style="plain" />
              </aside>
            </Typography>
          </>
        </Box>
      }
      actionButtons={buttons}
    >
      {children}
    </TopPageLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  btn: {
    marginLeft: theme.spacing(1)
  },
  sideInfo: {
    textAlign: "right",
    marginRight: theme.spacing(1),

    "& > aside": {
      display: "inline-block",
      fontSize: "0.87rem",
      backgroundColor: theme.custom.MEDIUM_GREY,
      padding: theme.spacing(0, 1),
      marginLeft: theme.spacing(1),
      borderRadius: theme.spacing(0.5)
    }
  }
}));

export default StudentHeader;
