import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchDistricts = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`districts?${query}`), { transformData: true });
};

export const fetchDistrictsList = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`districts/list?${query}`), { transformData: true });
};

export const fetchDistrict = async (id) => {
  return await xhr.get(apiUrl(`districts/${id}`), { transformData: true });
};

export const saveDistrict = async (id, params) => {
  const url = apiUrl(`districts/${id}`);
  return await xhr.post(url, { district: params }, { transformData: true });
};

export const createDistrict = async (params) => {
  const url = apiUrl(`districts`);
  return await xhr.post(url, { district: params }, { transformData: true });
};

export const deleteDistrict = async (id) => {
  const url = apiUrl(`districts/${id}/delete`);
  return await xhr.post(url);
};

export const batchDelete = async (ids) => {
  const url = apiUrl("districts/batch_delete");
  return await xhr.post(url, { district_ids: ids });
};
