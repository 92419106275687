import React, { Fragment } from "react";
import { Box, Dialog, Typography } from "@mui/material";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import FormDatePicker from "components/admin_v2/ui/FormDatePicker";
import FileUpload from "components/admin_v2/ui/FileUpload";
import I18n from "utils/i18n.js";

const AddRouteImportDialog = ({ store, cls, open, toggleDialog }) => {
  const [state, actions] = store;

  const closeDialog = () => {
    actions.setErrors({});
    toggleDialog();
  };

  const onFileSelect = (e) => {
    actions.setNewImport({ ...state.newImport, files: e.target.files[0] });
  };

  const onStartDateChange = (e) => {
    actions.setNewImport({ ...state.newImport, startDate: e });
  };

  const onSubmit = () => {
    actions.submit();
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <Fragment>
        <FormDialogTitle onClose={closeDialog}>
          <Typography variant="h1">{I18n.t("data_management.new_route_import.title")}</Typography>
        </FormDialogTitle>
        <FormDialogContent className={cls.dialogCont}>
          <Box className={cls.formSection}>
            <FormDatePicker
              label={I18n.t(`data_management.imports.form.start_date.label`)}
              desc={I18n.t(`data_management.imports.form.start_date.description`)}
              date={state.newImport.startDate}
              onChange={onStartDateChange}
              errors={state.errors?.start_date}
              required
              reverse
            />
          </Box>
          <Box>
            <FileUpload
              itemType="file"
              accept=".csv"
              file={state.newImport.files}
              onChange={onFileSelect}
              error={state.errors?.files}
            />
          </Box>
        </FormDialogContent>
        <FormDialogActions
          onSubmit={onSubmit}
          onClose={closeDialog}
          disabled={state.submitDisabled}
          submitLabel={I18n.t("data_management.imports.form.submit")}
        />
      </Fragment>
    </Dialog>
  );
};

export default AddRouteImportDialog;
