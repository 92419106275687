import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchCommsGroups, fetchCommsGroup, deleteCommsGroup } from "services/apiCommsGroups";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { setFlashMessage } from "services";

export const defaultState = {
  // status
  loading: false,
  refresh: false,
  // filters
  query: null,
  // data
  groups: [],
  group: null,
  errors: {}
};

export const commsGroupsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["query"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchCommsGroups: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage"]);

    actions.setLoading(true);

    return fetchCommsGroups({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.groups = data.groups;
    state.collection = data.groups;
    state.total = parseInt(data.total);
  }),

  removeGroup: action((state, id) => {
    const newGroupsList = state.groups.filter((g) => g.id !== id);
    state.groups = newGroupsList;
  }),

  fetchCommsGroup: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchCommsGroup(id)
      .then((resp) => {
        actions.setGroup(resp.group);
      })
      .finally(() => actions.setLoading(false));
  }),

  deleteCommsGroup: thunk((actions, id, h) => {
    const state = h.getState();
    const { group } = state;
    actions.setLoading(true);

    return deleteCommsGroup(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (group) {
          // redirect
        } else {
          actions.removeGroup(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
