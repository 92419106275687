import React, { useCallback, useEffect, useState } from "react";
import { ArrowRightRounded, ArrowDropDownRounded } from "@mui/icons-material";
import { snakeCase } from "lodash";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { Collapse } from "@mui/material";

function getPersistedState(title, type) {
  const val = localStorage.getItem(`schoolExpanded:${title}:${type}`);
  // if we don't have a value yet, by default forms are expanded, and show are collapsed
  if (!val) return type === "form" ? true : false;

  return JSON.parse(val);
}

function setPersistedState(title, type, value) {
  localStorage.setItem(`schoolExpanded:${title}:${type}`, JSON.stringify(value));
}

const CollapsibleSection = ({ title, children, count = 0, type = "form", persist = true }) => {
  const cls = useCommonPageStyles();
  const [expanded, setExpanded] = useState(__testing ? true : !!getPersistedState(title, type));

  useEffect(() => {
    if (!persist) return;

    setPersistedState(title, type, expanded);
  }, [expanded]);

  const toggleExpanded = useCallback(() => {
    setExpanded((e) => !e);
  }, []);

  return (
    <>
      <div className={cls.collapsibleHeader}>
        <a onClick={toggleExpanded}>
          {expanded ? (
            <ArrowDropDownRounded className="caret" />
          ) : (
            <ArrowRightRounded className="caret" />
          )}
          {I18n.t(`school_config.school.title.${snakeCase(title)}`)}
        </a>
        <aside className={cls.itemsCount}>({I18n.t("ui.items", { count: count })})</aside>
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </>
  );
};

export default CollapsibleSection;
