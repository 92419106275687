import React, { Fragment } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { formatDateUrl } from "lib/dates";
import { saveStudentRouteEvent } from "services/apiStudents";
import { confirmEvent, EventLabel } from "components/admin_v2/common/EventHelpers";
import { isOtherRoute, getRouteType } from "components/admin_v2/students/stores/stopsModel";
import EventSimpleSelect from "components/admin_v2/common/EventSimpleSelect";

const ModeChangeTrip = ({ day, trip, tripType, index, eventType, addEvent = false, children }) => {
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const { isUserAdmin } = useStoreState((s) => s.app);
  const { student } = useStoreState((s) => s.calendar);
  const {
    updateTrips,
    setRefresh,
    setModeDialogOpen,
    setModeDialogDate,
    setChangeTripData,
    openReqDialog
  } = useStoreActions((s) => s.calendar);
  const changeRequest = trip?.events?.change_request;

  const openChangeReqDialog = () => {
    if (changeRequest) openReqDialog(changeRequest);
  };

  const openModeDialog = () => {
    setModeDialogDate(day);
    setChangeTripData({ tripType, eventType, changeRequestId: changeRequest?.id });
    setModeDialogOpen(true);
  };

  const updateStudentAttendance = (trip, currentEvent) => (eventType, eventText) => {
    if (eventType == currentEvent || !confirmEvent({ eventType, currentEvent, eventText })) {
      return;
    }

    if (["change", "remove"].includes(eventType)) {
      setModeDialogDate(day);
      setChangeTripData({ tripType, eventType, stopId: trip?.id });
      setModeDialogOpen(true);
      return;
    }

    saveStudentRouteEvent({
      id: student.id,
      trip_type: tripType,
      stop_id: trip?.id,
      event_type: eventType === "ok" ? null : eventType,
      date: formatDateUrl(day)
    })
      .then(({ message, type }) => {
        if (
          ["change", "one_off", "student_add", "activity"].includes(currentEvent) ||
          (currentEvent === "cancel" && getRouteType(tripType) !== "regular")
        ) {
          setRefresh((r) => !r);
        } else {
          updateTrips({ index, trip_type: tripType, event: eventType, stopId: trip?.id });
        }
        if (message) setFlashMessage({ message: message, type: type });
      })
      .catch((err) => setFlashMessage({ message: err.message }));
  };

  return (
    <Fragment>
      {children}
      <EventSimpleSelect
        fullWidth={false}
        withoutBg={true}
        eventType={eventType}
        tripType={tripType}
        isOtherRoute={isOtherRoute(tripType)}
        isUnassigned={!trip.route}
        startTime={trip?.route?.start_time}
        onChange={updateStudentAttendance(trip, eventType)}
        schoolId={student.school_id}
        date={day}
        disabled={eventType === "change_req" || trip?.stop?.route_discarded || trip?.change_request}
        withActivity={trip?.with_activity}
        events={trip?.events?.data || []}
        changeRequestId={changeRequest?.id}
        onChangeRequest={() => (isUserAdmin ? openChangeReqDialog() : openModeDialog())}
      >
        <EventLabel addEvent={addEvent} trip={trip} eventType={eventType} />
      </EventSimpleSelect>
    </Fragment>
  );
};

export default ModeChangeTrip;
