import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/ReportProblem";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { CancelBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import I18n from "utils/i18n.js";

const ICON_TYPES = {
  info: InfoIcon,
  warning: WarningIcon
};

const AlertDialog = ({
  store,
  callBack,
  children,
  title,
  iconType = null,
  submitParams = {},
  submitLabel,
  cancelLabel
}) => {
  const cls = useStyles();
  const [state, actions] = store;

  const onSubmit = () => {
    actions.setAlertOpen(false);
    callBack(submitParams);
  };

  const onCancel = () => actions.setAlertOpen(false);

  const iconClass = classNames(
    cls.icon,
    { warning: iconType === "warning" },
    { info: iconType === "info" }
  );

  const TitleIcon = iconType ? ICON_TYPES[iconType] : null;

  return (
    <Dialog
      open={state.alertOpen}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ padding: 1 }}
    >
      <DialogTitle
        id="alert-dialog-title"
        component="div"
        sx={{ display: "inline-flex", alignItems: "start", paddingRight: 2, paddingLeft: 2 }}
      >
        {iconType ? (
          <Box component="span" className={iconClass} sx={{ marginRight: 1 }}>
            <TitleIcon sx={{ lineHeight: 1 }} />
          </Box>
        ) : null}
        <span>{title}</span>
      </DialogTitle>
      <DialogContent sx={{ paddingRight: 2, paddingLeft: 2 }}>{children}</DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", paddingRight: 2, paddingLeft: 2 }}>
        <PrimaryBtn onClick={onSubmit}>{submitLabel || I18n.t("ui.btn.save_and_close")}</PrimaryBtn>
        <CancelBtn onClick={onCancel}>{cancelLabel || I18n.t("ui.btn.cancel")}</CancelBtn>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "1.5rem",
    "&.info": {
      color: theme.custom.DARK_GREY_1
    },
    "&.warning": {
      color: theme.custom.YELLOW
    }
  }
}));

export default AlertDialog;
