import React, { Fragment } from "react";
import { format } from "date-fns";
import { capitalize, map } from "lodash";
import { Box, FormControlLabel, RadioGroup, FormGroup } from "@mui/material";
import DaySelector from "components/admin_v2/common/DaySelector";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import { DayOffChip } from "components/admin_v2/ui/DayOffLabel";
import TripTypeRadioFilter from "components/admin_v2/common/TripTypeRadioFilter";
import { DIRECTION_OPTIONS } from "utils/constants";
import I18n from "utils/i18n.js";
import useStyles from "./useStyles";

const CurbsideFilter = ({ state, date, updateQueryString }) => {
  const cls = useStyles();

  const updateDate = (newDate) =>
    updateQueryString({ page: 1, date: format(newDate, "yyyy-MM-dd") });
  const updateDirection = (e) => {
    const val = e.target.value;
    const typeFilters = val == "custom" ? { regular_only: null, all_other: null } : {};
    updateQueryString({ page: 1, trip_type: val, ...typeFilters });
  };
  const updateRoute = (e) => updateQueryString({ page: 1, route_id: e.target.value });
  const updateVehicleType = (e) => updateQueryString({ page: 1, vehicle_type: e.target.value });
  const updateScope = (e) => updateQueryString({ page: 1, scope: e.target.value });
  const updateChangesOnly = (e) =>
    updateQueryString({ page: 1, changes_only: e.target.checked ? true : null });

  const updateTypeFilters = (e) => {
    if (e.target.value == "all_other") {
      updateQueryString({ page: 1, regular_only: null, all_other: true });
    } else if (e.target.value == "regular_only") {
      updateQueryString({ page: 1, regular_only: true, all_other: null });
    } else {
      updateQueryString({ page: 1, regular_only: null, all_other: null });
    }
  };

  const scopesFilter = map(["all", "assigned", "unassigned"], (v) => (
    <FormControlLabel
      key={`rs-${v}`}
      id={`curbside-scope-${v}`}
      className={cls.scope}
      value={v}
      control={<GreenRadio />}
      label={capitalize(v)}
    />
  ));

  return (
    <Fragment>
      <Box className={cls.topActions}>
        <Box display="flex">
          <DaySelector date={date} onSelectDate={updateDate} skipWeekends={true} />
          {state.dayOff && <DayOffChip />}
        </Box>
        <Box>
          <SelectFilter
            id="routes"
            label="All Routes"
            value={state.routeId}
            onChange={updateRoute}
            items={state.routes}
          />
          <SelectFilter
            id="vehicle-type"
            label="All Vehicle Types"
            value={state.vehicleType}
            onChange={updateVehicleType}
            items={state.vehicleTypes}
          />
        </Box>
      </Box>
      <Box className={cls.topActions}>
        <Box display="flex">
          <SelectFilter
            value={state?.tripType || ""}
            onChange={updateDirection}
            items={DIRECTION_OPTIONS}
            variant="outlined"
            ml={0}
            id="trip-type"
          />
          <FormGroup row className={cls.scope}>
            <TripTypeRadioFilter
              selected={state.typeFiltersValue}
              onChange={updateTypeFilters}
              disabled={state.tripType == "custom"}
            />
            <FormControlLabel
              key="changes-only"
              control={<GreenCheckbox checked={!!state.changesOnly} onChange={updateChangesOnly} />}
              label={I18n.t("ui.curbside.filter.changes_only")}
            />
          </FormGroup>
        </Box>
        <Box>
          <RadioGroup
            aria-label="scope"
            name="scope"
            value={state.scope}
            onChange={updateScope}
            row
          >
            {scopesFilter}
          </RadioGroup>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CurbsideFilter;
