import React, { useMemo } from "react";
import { useStoreState } from "easy-peasy";
import { isAfter } from "date-fns";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ArchivedBadge from "./ArchivedBadge";
import { toDate, daysOfWeek, sameDayOrBefore } from "lib/dates";

const ArchivedRow = () => {
  const { route, date } = useStoreState((s) => s.routeEditor);

  const weekDays = useMemo(() => daysOfWeek(date), [date]);
  const lastDate = route.discarded ? toDate(route.end_date) : null;

  if (weekDays.every((d) => sameDayOrBefore(d, lastDate))) return null;

  return (
    <TableRow>
      <TableCell component="td" scope="row" align="left"></TableCell>
      {weekDays.map((weekDay) => (
        <TableCell key={`discarded-${weekDay}`} scope="row" align="center">
          {lastDate && isAfter(weekDay, lastDate) ? <ArchivedBadge /> : null}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ArchivedRow;
