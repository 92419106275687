import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

export const ScopeButtons = ({ options, currentScope, onChange }) => {
  const cls = useFilterStyles();
  const scopeButtons = options.map(([name, label]) => {
    const isActive = currentScope === name;
    const btnProps = {
      variant: isActive ? "contained" : "outlined",
      color: isActive ? "secondary" : "primary",
      disabled: name === "issues"
    };

    return (
      <Button
        {...btnProps}
        className={cls.btn}
        key={`btn-scope-${name}`}
        onClick={() => onChange({ page: 1, scope: name })}
      >
        {label}
      </Button>
    );
  });

  return <Box className={cls.scopeBtns}>{scopeButtons}</Box>;
};

export const useFilterStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  options: {
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  scopeBtns: {
    flex: 1
  },
  btn: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1, 4),
    height: "2.5rem",
    position: "relative"
  },
  floatRight: {
    marginLeft: "auto",
    alignSelf: "flex-end"
  },
  center: {
    alignSelf: "center"
  },
  form: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  small: {
    width: theme.spacing(3.5),
    padding: 0
  }
}));
