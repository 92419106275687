import React from "react";
import { compact } from "lodash";
import RouteAnchorInfo from "./RouteAnchorInfo";
import RouteStopForm from "./RouteStopForm";

const RouteStopsForm = ({ store, tripType, withoutStopToggle = false, cls }) => {
  const [state, _actions] = store;

  const stops = compact([
    <RouteStopForm
      key={`r-direction-${tripType}`}
      withCheckBox={false}
      withTopMargin={state.anchor === "pickup"}
      withoutStopToggle={withoutStopToggle}
      direction={{ type: tripType }}
      store={store}
      cls={cls}
    />,
    <RouteAnchorInfo
      key={`r-anchor-direction-${tripType}`}
      tripType={tripType}
      store={store}
      cls={cls}
    />
  ]);

  return state.anchor === "dropoff" ? stops : stops.reverse();
};

export default RouteStopsForm;
