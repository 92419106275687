import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const ButtonLink = (props) => {
  const classes = useStyles();
  const buttonProps = { ...props };
  delete buttonProps["to"];

  return (
    <Link to={props.to} className={classes.unstyledHref}>
      <Button {...buttonProps} />
    </Link>
  );
};

const useStyles = makeStyles((_theme) => ({
  unstyledHref: {
    textDecoration: "none"
  }
}));

export default ButtonLink;
