import React, { useEffect, useCallback } from "react";
import { useLocalStore, useStoreActions, useStoreState } from "easy-peasy";
import { Box, Link, Paper, TableRow, TableCell } from "@mui/material";
import SearchInput from "components/admin_v2/ui/SearchInput";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/settings_config/SettingsConfigTabs";
import UsersFilter from "components/admin_v2/settings_config/users/UsersFilter";
import RouterLink from "components/admin_v2/nav/RouterLink";
import BatchSelect from "components/admin_v2/common/BatchSelect";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { usersStore } from "components/admin_v2/common/stores/usersStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import { batchArchive, batchUnarchive } from "services/apiUsers";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { formatPhone } from "lib/util";

const LABELS_I18N = "settings_config.user.labels";

const Users = (props) => {
  const cls = useCommonPageStyles();
  const isRestrictedAdmin = useStoreState((s) => s.app.isRestrictedAdmin);
  const { onChangeScope } = useStoreActions((s) => s.app);
  const store = useLocalStore(() =>
    usersStore({ ...camelizeLocationSearch(props), role: "admin" })
  );
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);
  const updateQueryString = updateWithRouter(props);
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  let actionsList = [];
  if (!isRestrictedAdmin) {
    if (state.scope == "archived") {
      actionsList.push({ label: I18n.t("ui.btn.unarchive"), action: batchUnarchive });
    } else {
      actionsList.push({ label: I18n.t("ui.btn.archive"), action: batchArchive });
    }
  }

  const resetState = useCallback(() => {
    updateQueryString({ page: 1 });
    actions.toggleRefresh();
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchUsers({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [searchInput, state.page, state.perPage, state.scope, state.refresh, sortBy]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  const updatePage = (_e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const headers = [
    {
      field: "batchCheck",
      label: (
        <GreenCheckbox
          checked={state.allSelected}
          onChange={(e) => actions.updateAllSelected(e.target.checked)}
        />
      ),
      skipSorting: true
    },
    { field: "id", label: "ID" },
    { field: "first_name", label: I18n.t(`${LABELS_I18N}.first_name`) },
    { field: "last_name", label: I18n.t(`${LABELS_I18N}.last_name`) },
    { field: "email", label: I18n.t(`${LABELS_I18N}.last_name`) },
    { field: "schools", label: I18n.t(`${LABELS_I18N}.schools`), skipSorting: true },
    { field: "phone_number", label: I18n.t(`${LABELS_I18N}.phone_number`), skipSorting: true },
    { field: "created_at", label: I18n.t(`${LABELS_I18N}.created_at`) },
    { field: "", label: "" }
  ].map(sortableColumn);

  const row = (user) => (
    <TableRow hover key={`user-${user.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <GreenCheckbox
          checked={state.isSelected(user.id)}
          onChange={(e) => actions.updateSelectedIds({ isAdd: e.target.checked, id: user.id })}
        />
      </TableCell>
      <TableCell component="th" style={{ width: "5%" }}>
        <RouterLink to={pageRoutes.user(user.id)} className={cls.tableLink}>
          {user.id}
        </RouterLink>
      </TableCell>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.schools.map((s) => s.name).join(", ")}</TableCell>
      <TableCell>{formatPhone(user.phoneNumber)}</TableCell>
      <TableCell>{user.createdAt}</TableCell>
      <TableCell>
        <RouterLink to={pageRoutes.user(user.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.view")}
        </RouterLink>
        <RouterLink to={pageRoutes.userEdit(user.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.edit")}
        </RouterLink>
        {!isRestrictedAdmin && (
          <Link
            onClick={() => actions.toggleArchive(user.id)}
            className={cls.tableLink}
            underline="hover"
          >
            {user.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
          </Link>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("settings_config.title")} />
      <SchoolConfigTabs />
      <Box className={cls.btnsWrapper}>
        {!isRestrictedAdmin && (
          <RouterLink className={cls.actionBtn} to={pageRoutes.userNew()}>
            <OutlinedBtn>{I18n.t("settings_config.new_user.btn")}</OutlinedBtn>
          </RouterLink>
        )}
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          <BatchSelect store={store} actionsList={actionsList} />
          <UsersFilter state={state} updateQueryString={updateQueryString} />
          <SearchInput cls value={state.query} onChange={onSearch} />
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.users.map(row)}
          </PaginatedTable>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default Users;
