import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import capitalize from "lodash/capitalize";
import { useHistory } from "react-router";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import I18n from "utils/i18n.js";
import { routeImportsStore } from "./stores/routeImportsStore";
import { OutlinedBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const RESOLUTIONS = ["accept", "reject"];
const HEADERS = ["student_route", "conflict", "actions"];

const RouteImport = (props) => {
  const cls = useCommonPageStyles();
  const history = useHistory();
  const [state, actions] = useLocalStore(() => routeImportsStore());
  const id = props.match.params.id;

  useEffect(() => {
    if (!id) return;

    actions.fetchImportConflicts(id);
  }, [id]);

  const onSubmit = () => {
    actions.submitConflicts(id);
    history.push(pageRoutes.routeImports());
  };

  const row = (conflict) => (
    <TableRow key={`conflict-${conflict.id}`}>
      <TableCell>{conflict.schedule_details.name}</TableCell>
      <TableCell>
        {conflict.conflict_details.map((cd) => conflictDetails(cd, conflict.trip_type))}
      </TableCell>
      <TableCell>
        <RadioGroup value={state.eventType} row>
          {RESOLUTIONS.map((resolution) => (
            <FormControlLabel
              key={resolution}
              value={resolution}
              control={
                <GreenRadio
                  onChange={() =>
                    actions.changeResolution({ conflict: conflict, resolution: resolution })
                  }
                  checked={conflict.resolution === resolution}
                />
              }
              label={`${capitalize(resolution)} New`}
            />
          ))}
        </RadioGroup>
      </TableCell>
    </TableRow>
  );

  const conflictDetails = (cd, trip_type) => {
    return (
      <Box key={`cd-${cd.route_id}-${trip_type}-${cd.dates}`}>
        <Typography>{`${cd.dates}. ${cd.stop_location_address}. ${cd.days_of_week}`}</Typography>
        <Typography>{`${cd.route_name} - ${trip_type}`}</Typography>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <BreadCrumbs
        path={pageRoutes.routeImports()}
        label={I18n.t("data_management.import_conflicts.breadcrumb")}
      />
      <Box className={cls.titleWithBtn}>
        <Typography variant="h1" gutterBottom>
          {I18n.t("data_management.import_conflicts.title")}
        </Typography>
        <Box className={cls.btnsWrapper}>
          {RESOLUTIONS.map((resolution) => (
            <OutlinedBtn
              key={resolution}
              className={cls.actionBtn}
              onClick={() => actions.changeAll(resolution)}
            >
              {I18n.t(`data_management.import_conflicts.${resolution}_all`)}
            </OutlinedBtn>
          ))}
          <PrimaryBtn color="primary" className={cls.actionBtn} onClick={onSubmit}>
            {I18n.t("data_management.import_conflicts.submit")}
          </PrimaryBtn>
        </Box>
      </Box>
      <Paper className={cls.paper}>
        {state.loading ? (
          <Spinner />
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {HEADERS.map((header) => (
                  <TableCell component="th" align="left" key={header}>
                    {I18n.t(`data_management.import_conflicts.${header}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{state.conflicts.map((conflict) => row(conflict))}</TableBody>
          </Table>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default RouteImport;
