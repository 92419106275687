import React, { useCallback, useEffect } from "react";
import cn from "classnames";
import memoize from "fast-memoize";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { Box, TextField, FormControlLabel, Grid, Link } from "@mui/material";
import FormDatePicker from "components/admin_v2/ui/FormDatePicker";
import FormSelect from "components/admin_v2/ui/FormSelect";
import { useStoreActions } from "easy-peasy";
import { strPresent, formatPhone } from "lib/util";
import useTesting from "lib/useTesting";
import { pageRoutes } from "services/api";
import { GRADES } from "utils/constants";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import PhoneInput from "components/admin_v2/common/PhoneInput";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import PrimaryAddressForm from "components/admin_v2/forms/PrimaryAddressForm";
import I18n from "utils/i18n.js";
import isDate from "date-fns/esm/fp/isDate/index.js";

const fieldVal = (val) => {
  return Array.isArray(val) ? val.join(", ") : val;
};

const SchoolDetailsForm = ({ formStore }) => {
  const cls = useCommonPageStyles();
  const history = useHistory();
  const { setFlashMessage, updateDistricts } = useStoreActions((s) => s.app);
  const [state, actions] = formStore;
  const { school, details, errors } = state;

  useTesting({
    "schoolForm:updateDetails": (field, value) => {
      actions.updateDetails({ [field]: value });
    }
  });

  useEffect(() => {
    actions.fetchAllDistricts();
  }, []);

  const onSubmit = () => {
    actions
      .save()
      .then((resp) => {
        setFlashMessage({ message: resp.message });
        updateDistricts();
        if (!school.id) history.push(pageRoutes.school(resp.school.id));
      })
      .catch((err) =>
        setFlashMessage({
          message:
            err.response?.data?.message || I18n.t("data_management.errors.fail", { name: "School" })
        })
      );
  };

  const updateSelectedGrades = useCallback((e) =>
    actions.updateSelectedGrades({ isAdd: e.target.checked, value: e.target.value })
  );

  // NOTE: we need memoize here as useCallback will give us not a lot because new function will be created on each rerender
  // Another option is to move key to data attributes of input
  const updateField = useCallback(
    memoize((key) => (e) => {
      let value;
      if (isDate(e)) {
        value = e;
      } else {
        value = e.target.value;
      }
      const newDetails = { ...details, [key]: value };
      actions.setDetails(newDetails);
    }),
    [details]
  );

  const updateSlackIntegration = useCallback(
    (e) => {
      actions.setDetails({
        ...details,
        slackIntegrationAttributes: {
          ...details.slackIntegrationAttributes,
          enabled: e.target.checked
        }
      });
    },
    [details]
  );

  const updateAdminAccess = useCallback(
    (e) => actions.setDetails({ ...details, allAdminsAccess: e.target.checked }),
    [details]
  );

  const cancel = () => actions.cancel();

  if (!details) return null;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          {state.isEditMode ? (
            <>
              <TextField
                variant="standard"
                label={
                  <RequiredLabel
                    label={I18n.t("school_config.school.form.name.label")}
                    condition={strPresent(details.name)}
                  />
                }
                value={details.name || ""}
                onChange={updateField("name")}
                className={cn("school-name", cls.field)}
                error={!!errors?.name}
                helperText={errors?.name?.[0]}
              />
              <TextField
                variant="standard"
                label={
                  <RequiredLabel
                    label={I18n.t("school_config.school.form.slug.label")}
                    condition={strPresent(details.slug)}
                  />
                }
                value={details.slug || ""}
                onChange={updateField("slug")}
                className={cn("school-slug", cls.field)}
                error={!!errors?.slug}
                helperText={errors?.slug?.[0]}
              />
            </>
          ) : (
            <>
              <Box className={cn("details-name", cls.detail)}>
                <div className={cls.detailHeader}>{I18n.t(`school_config.school.labels.name`)}</div>
                <div>{fieldVal(school?.name) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cn("details-slug", cls.detail)}>
                <div className={cls.detailHeader}>{I18n.t(`school_config.school.labels.slug`)}</div>
                <div>{fieldVal(school?.slug) || I18n.t("ui.empty_field")}</div>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          {state.isEditMode ? (
            <>
              <TextField
                variant="standard"
                label={
                  <RequiredLabel
                    label={I18n.t("school_config.school.form.code.label")}
                    condition={strPresent(details.code)}
                  />
                }
                value={details.code || ""}
                onChange={updateField("code")}
                className={cn("school-code", cls.field)}
                error={!!errors?.code}
                helperText={errors?.code?.[0]}
              />
              <TextField
                variant="standard"
                label={I18n.t("school_config.school.form.icabbi_account_ref.label")}
                value={details.icabbiAccountRef || ""}
                onChange={updateField("icabbiAccountRef")}
                className={cls.field}
                error={!!errors?.icabbiAccountRef}
                helperText={errors?.icabbiAccountRef?.[0]}
              />
              <TextField
                label={I18n.t("school_config.school.form.hotline_email.label")}
                variant="standard"
                value={details.hotlineEmail || ""}
                onChange={updateField("hotlineEmail")}
                className={cn("school-hotline", cls.field)}
                error={!!errors?.hotlineEmail}
                helperText={errors?.hotlineEmail?.[0]}
              />
              <TextField
                variant="standard"
                label={I18n.t("school_config.school.form.emergency_contact.label")}
                value={details.emergencyContact || ""}
                InputProps={{
                  inputComponent: PhoneInput
                }}
                onChange={updateField("emergencyContact")}
                className={cls.field}
                error={!!errors?.emergencyContact}
                helperText={errors?.emergencyContact?.[0]}
              />
              <TextField
                variant="standard"
                label={I18n.t("school_config.school.form.school_days_count.label")}
                value={details.schoolDaysCount || ""}
                onChange={updateField("schoolDaysCount")}
                className={cn("school-daysCount", cls.field)}
                error={!!errors?.schoolDaysCount}
                helperText={errors?.schoolDaysCount?.[0]}
              />
            </>
          ) : (
            <>
              <Box className={cls.detail}>
                <div className={cls.detailHeader}>{I18n.t(`school_config.school.labels.code`)}</div>
                <div>{fieldVal(school?.code) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cn(cls.detail)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.icabbi_account_ref`)}
                </div>
                <div>{fieldVal(school?.icabbiAccountRef) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cn("details-hotline", cls.detail)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.hotline_email`)}
                </div>
                <div>{fieldVal(school?.hotlineEmail) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cn(cls.detail)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.emergency_contact`)}
                </div>
                <div>{formatPhone(school?.emergencyContact) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cn(cls.detail)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.form.school_days_count.label`)}
                </div>
                <div>{fieldVal(school?.schoolDaysCount) || I18n.t("ui.empty_field")}</div>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          {state.isEditMode ? (
            <>
              <Box className={cn("details-dates", cls.formSection)}>
                <FormDatePicker
                  label={I18n.t(`school_config.school.form.first_day.label`)}
                  date={details.firstDay}
                  onChange={updateField("firstDay")}
                  errors={errors?.firstDay}
                  required
                  reverse
                />
              </Box>
              <Box className={cls.formSection}>
                <FormDatePicker
                  label={I18n.t(`school_config.school.form.last_day.label`)}
                  date={details.lastDay}
                  onChange={updateField("lastDay")}
                  errors={errors?.lastDay}
                  required
                  reverse
                />
              </Box>
            </>
          ) : (
            <>
              <Box className={cn("details-first-day", cls.detail, cls.halfWidth)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.first_day`)}
                </div>
                <div>{fieldVal(school?.firstDay) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cn("details-last-day", cls.detail, cls.halfWidth)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.last_day`)}
                </div>
                <div>{fieldVal(school?.lastDay) || I18n.t("ui.empty_field")}</div>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={9} className="school-grades">
          {state.isEditMode ? (
            GRADES.map((grade) => (
              <FormControlLabel
                key={grade}
                control={
                  <GreenCheckbox
                    checked={state.isGradeChecked(grade)}
                    onChange={updateSelectedGrades}
                    value={grade}
                  />
                }
                label={grade}
              />
            ))
          ) : (
            <>
              <Box className={cn(cls.detail)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.created_at`)}
                </div>
                <div>{fieldVal(school?.createdAt) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cn("details-grades", cls.detail)}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.grades_served`)}
                </div>
                <div>{fieldVal(school?.gradesServed) || I18n.t("ui.empty_field")}</div>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      {school?.id && (
        <Grid container spacing={5} mb={state.isEditMode ? 3 : 0}>
          <Grid item xs={4}>
            <Box className={cn(cls.detail)}>
              <div className={cls.detailHeader}>
                {I18n.t(`school_config.school.labels.time_zone_abbr`)}
              </div>
              <div>{fieldVal(school?.timeZoneAbbr) || I18n.t("ui.empty_field")}</div>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={5}>
        <Grid item xs={4}>
          {state.isEditMode ? (
            <Box className={cls.formSection}>
              <FormSelect
                itemType="district"
                options={state.allDistricts}
                value={details.districtId || ""}
                onChange={updateField("districtId")}
                cls={cls}
                clsKeys={["fullSelect", "fullSelect"]}
                error={errors?.district}
              />
            </Box>
          ) : (
            <>
              <Box className={cls.detail}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.district`)}
                </div>
                <div>{fieldVal(school?.districtName) || I18n.t("ui.empty_field")}</div>
              </Box>
              <Box className={cls.detail} mb={2}>
                <div className={cls.detailHeader}>
                  {I18n.t(`school_config.school.labels.slack_integration_enabled`)}
                </div>
                <div>{school?.slackIntegrationEnabled ? "YES" : "NO"}</div>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      {!school?.id && state.isEditMode && (
        <FormControlLabel
          control={<GreenCheckbox checked={details.allAdminsAccess} onChange={updateAdminAccess} />}
          label={I18n.t(`school_config.school.form.all_admins_access.label`)}
        />
      )}
      {state.isEditMode && school?.slackIntegration && (
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={details.slackIntegrationAttributes.enabled}
              onChange={updateSlackIntegration}
            />
          }
          label={I18n.t(`school_config.school.form.slack_integration.label`)}
        />
      )}
      {!school?.id && <PrimaryAddressForm store={formStore} updateField={updateField} />}
      {state.isEditMode && (
        <Box my={3}>
          <PrimaryBtn
            variant="contained"
            color="primary"
            size="large"
            onClick={onSubmit}
            disabled={state.loading || !state.isValid}
          >
            {I18n.t(`ui.btn.${school.id ? "save" : "create"}`)}
          </PrimaryBtn>
          {school.id ? (
            <>
              <Link component="button" onClick={cancel} className={cls.cancelLink}>
                {I18n.t("ui.btn.cancel")}
              </Link>
            </>
          ) : (
            <NavLink to={pageRoutes.schools()} className={cls.cancelLink}>
              {I18n.t("ui.btn.cancel")}
            </NavLink>
          )}
        </Box>
      )}
    </>
  );
};

export default SchoolDetailsForm;
