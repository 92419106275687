import { thunk } from "easy-peasy";
import easyState from "lib/easyState";

const avlStore = {
  ...easyState("sortBy", { field: "pickup_time_end_full", order: "asc" }),

  updateSorting: thunk((actions, field, h) => {
    const sortBy = h.getState().sortBy;

    if (field !== sortBy.field) {
      actions.setSortBy({ field: field, order: "asc" });
    } else {
      const newOrder = sortBy.order === "asc" ? "desc" : "asc";
      actions.setSortBy({ field: field, order: newOrder });
    }
  })
};

export default avlStore;
