import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ScopeBtn from "../ui/ScopeBtn";

const DirectionBtns = ({ direction, onSelect, flex = 1 }) => {
  const cls = useStyles({ flex });

  const activeScope = (tripType) => {
    return tripType === direction ? "active" : null;
  };

  return (
    <Box className={cls.directionBtns}>
      <Typography component="span" variant="subtitle2">
        <ScopeBtn
          size="large"
          active={activeScope("to_school")}
          onClick={() => onSelect("to_school")}
        >
          To school
        </ScopeBtn>
        <ScopeBtn
          size="large"
          active={activeScope("from_school")}
          onClick={() => onSelect("from_school")}
        >
          From school
        </ScopeBtn>
      </Typography>
    </Box>
  );
};

export const useStyles = makeStyles((theme) => ({
  directionBtns: {
    height: theme.spacing(7.3),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0),
    flex: (props) => props.flex,
    whiteSpace: "pre"
  }
}));

export default DirectionBtns;
