import React, { Fragment } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Spinner from "components/admin_v2/ui/Spinner";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import FormSelect from "components/admin_v2/ui/FormSelect";
import TransportationTypes from "./TransportationTypes";
import TripType from "./TripType";
import useOptions from "./useOptions";
import useStyles from "./useStyles";
import I18n from "utils/i18n.js";

const DEFAULT_OPTIONS = {
  transportationTypes: [],
  transportationVendors: [],
  grades: [],
  reportFormats: [
    { id: "csv", name: "CSV" },
    { id: "pdf", name: "PDF" }
  ]
};

const CurbsideReportForm = ({ reportStore }) => {
  const cls = useStyles();
  const [state, actions] = reportStore;
  const form =
    state.reportType === "district_curbside" ? state.districtCurbsideForm : state.curbsideForm;
  const { options, loading } = useOptions(state.reportType, DEFAULT_OPTIONS);

  if (loading) return <Spinner />;

  const updateForm = (data) => {
    state.reportType === "district_curbside"
      ? actions.setDistrictCurbsideForm({ ...form, ...data })
      : actions.setCurbsideForm({ ...form, ...data });
  };

  const handleChange = (field) => {
    return (e) => {
      const val = field === "transportationVendorIds" ? [e.target.value] : e.target.value;
      updateForm({ [field]: val });
    };
  };

  const setOrder = (e) => {
    updateForm({ orderByDismissalGroup: e.target.checked });
  };

  const setSplitPages = (e) => {
    updateForm({ splitPages: e.target.checked });
  };

  const setAnchorTimeInclude = (e) => {
    updateForm({ includeTime: e.target.checked });
  };

  const curbsideTimeLabel =
    form.tripType === ""
      ? I18n.t(`report.curbside.time.all`)
      : I18n.t(`report.curbside.time.${form.tripType}`);

  return (
    <Fragment>
      <TripType form={form} onChange={handleChange("tripType")} hasAll={true} cls={cls} />
      <TransportationTypes
        form={form}
        errors={state.reportErrors}
        options={options}
        updateForm={updateForm}
      />
      <FormSelect
        itemType="vendor"
        options={options.transportationVendors}
        value={form.transportationVendorIds[0]}
        onChange={handleChange("transportationVendorIds")}
        cls={cls}
      />
      <FormSelect
        itemType="grade"
        options={options.grades}
        value={form.grade}
        onChange={handleChange("grade")}
        cls={cls}
      />
      <FormControl component="fieldset" className={cls.field}>
        <FormControlLabel
          key="curbside-time"
          control={<GreenCheckbox checked={form.includeTime} onChange={setAnchorTimeInclude} />}
          label={curbsideTimeLabel}
        />
      </FormControl>
      <FormControl component="fieldset" className={cls.field}>
        <FormControlLabel
          key="curbside-order"
          control={<GreenCheckbox checked={form.orderByDismissalGroup} onChange={setOrder} />}
          label="Order by dismissal group"
        />
      </FormControl>
      {form.reportFormat === "pdf" ? (
        <FormControl component="fieldset" className={cls.field}>
          <FormControlLabel
            key="curbside-split-pages"
            control={<GreenCheckbox checked={form.splitPages} onChange={setSplitPages} />}
            label="Split route tables into new pages"
          />
        </FormControl>
      ) : null}
      <FormSelect
        itemType="reportFormat"
        options={options.reportFormats}
        value={form.reportFormat || "pdf"}
        onChange={handleChange("reportFormat")}
        cls={cls}
      />
      <FilesNotice tripType={form.tripType} reportFormat={form.reportFormat} />
    </Fragment>
  );
};

const FilesNotice = ({ tripType, reportFormat }) => {
  const cls = useStyles();

  // 'All' sessions/tripType == ""
  if (tripType !== "") return null;

  const notice = I18n.t(`report.curbside.aside.${reportFormat}`);
  return <aside className={cls.filesNotice} dangerouslySetInnerHTML={{ __html: notice }} />;
};

export default CurbsideReportForm;
