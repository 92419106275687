import React from "react";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

const StyledBtn = withStyles((theme) => ({
  root: {
    height: "100%",
    border: "none",
    borderRadius: 0,
    borderTopWidth: theme.spacing(0.7),
    borderTopStyle: "solid",
    borderTopColor: theme.custom.MEDIUM_GREY_2,
    marginRight: theme.spacing(1),
    fontSize: ".85rem",
    fontWeight: theme.custom.BOLD,
    backgroundColor: "transparent",
    color: theme.custom.MEDIUM_GREY_2,
    "&:hover": {
      borderTopColor: theme.custom.MEDIUM_DARK_GREY,
      color: theme.custom.MEDIUM_DARK_GREY,
      backgroundColor: "transparent"
    }
  }
}))((props) => <Button {...props} />);

const ScopeBtn = (props) => {
  const cls = useStyles();
  const btnCls = props.active ? cls.activeScopeBtn : "";

  return <StyledBtn className={btnCls} {...props} />;
};

const useStyles = makeStyles((theme) => ({
  activeScopeBtn: {
    color: theme.custom.DARK_GREY_1,
    borderTopColor: theme.custom.DARK_GREY_1,
    "&:hover": {
      color: theme.custom.DARK_GREY_1,
      borderTopColor: theme.custom.DARK_GREY_1
    }
  }
}));

export default ScopeBtn;
