import React from "react";
import { Box, FormControl, FormLabel, Link } from "@mui/material";
import classNames from "classnames";
import { isEmpty } from "lodash";
import AnchorLabel from "components/admin_v2/ui/AnchorLabel";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import StopNewForm from "components/admin_v2/students/trip/stop/StopNewForm";

const RouteAnchorForm = ({
  store,
  cls,
  tripType,
  currentAnchor,
  changeRequest = false,
  withTopMargin = false,
  disabled = false
}) => {
  const [state, actions] = store;
  const { errors, errorStop } = state.getStopErrorsFor(tripType, state.anchor);
  const stopType = state.anchor;
  const linkClass = classNames({
    [cls.disabled]: currentAnchor && state.isSameStop(tripType, stopType, currentAnchor)
  });

  const onUseSameAnchor = () => {
    actions.setStopFrom({ tripType, stopType, stop: currentAnchor });
  };

  return (
    <FormControl
      className={stopType === "dropoff" || withTopMargin ? cls.withTopMargin : null}
      fullWidth
      error={!isEmpty(errors)}
      required={!disabled}
    >
      <ErrorList errors={errorStop} />
      <Box display="flex" justifyContent="space-between" alignItems="baseline" mt={1}>
        <Box display="flex" alignItems="baseline">
          <FormLabel className={cls.label}>{I18n.t(`ui.stop.${stopType}`)}</FormLabel>
          <Box component="span" ml={1}>
            <AnchorLabel />
          </Box>
        </Box>
        {currentAnchor && (
          <Box>
            <Link
              component="button"
              onClick={onUseSameAnchor}
              underline="always"
              className={linkClass}
            >
              {I18n.t(`ui.stop.${changeRequest ? "requested" : "same"}.${stopType}`)}
            </Link>
          </Box>
        )}
      </Box>
      <StopNewForm
        store={store}
        cls={cls}
        stopType={stopType}
        tripType={tripType}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default RouteAnchorForm;
