import React, { Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import I18n from "utils/i18n.js";

const CreateReportButtons = ({ reportStore }) => {
  const cls = useStyles();
  const [state, actions] = reportStore;

  if (!state.reportType) return null;

  let buttons;
  if (state.reportType === "avery") {
    buttons = [
      {
        name: "preview",
        label: I18n.t("report.form.btn.preview"),
        action: actions.previewAveryLabels
      },
      {
        name: "print",
        label: I18n.t("ui.btn.print"),
        action: actions.printAveryLabels
      }
    ];
  } else {
    buttons = [
      {
        name: "create",
        label: I18n.t("report.form.btn.create"),
        action: actions.createReport
      }
    ];
  }
  return (
    <Fragment>
      {buttons.map((btn) => (
        <Button
          key={`report-btn-${btn.name}`}
          variant="contained"
          color="primary"
          className={cls.btn}
          onClick={btn.action}
          disabled={state.submitDisabled}
        >
          {btn.label}
        </Button>
      ))}
      <Button key="report-btn-cancel" variant="outlined" onClick={actions.reset}>
        {I18n.t("ui.btn.cancel")}
      </Button>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(1)
  }
}));

export default CreateReportButtons;
