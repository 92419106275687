import React from "react";
import { Box, FormControl, FormLabel, FormControlLabel } from "@mui/material";
import { isEmpty } from "lodash";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import StopNewForm from "../stop/StopNewForm";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";

const RouteNewStopForm = ({ store, stopType, tripType, cls }) => {
  const [state, actions] = store;
  const { errorStop } = state.getStopErrorsFor(tripType, stopType);

  return (
    <FormControl fullWidth error={!isEmpty(errorStop)} required>
      <Box display="flex" justifyContent="space-between" alignItems="baseline" mt={1}>
        <FormLabel className={cls.label}>{I18n.t(`ui.stop.${stopType}`)}</FormLabel>
        {isOtherRoute(state.routeType) && (
          <FormControlLabel
            checked={state.anchor === stopType}
            value={stopType}
            key={`stop-anchor-${stopType}`}
            control={<GreenRadio onChange={() => actions.setAnchor(stopType)} />}
            label={I18n.t("ui.stop.anchor")}
          />
        )}
      </Box>
      <ErrorList errors={errorStop} />
      <StopNewForm store={store} cls={cls} stopType={stopType} tripType={tripType} />
    </FormControl>
  );
};

export default RouteNewStopForm;
