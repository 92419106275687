import React from "react";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { formatDateUrl } from "lib/dates";
import { fetchStops } from "services/api";
import I18n from "utils/i18n.js";

const StopSelect = ({ store, stopType, tripType, disabled = false }) => {
  const [state, actions] = store;
  const stop = state.getStopFor(tripType, stopType);

  const onChangeStop = (stop) => {
    actions.setStop({ stop, stopType, tripType });
  };

  return (
    <AutocompleteSelect
      id={`stop-select-${tripType}`}
      getOptions={fetchStops}
      onOptionSelect={onChangeStop}
      placeholder={I18n.t("ui.stop.choose")}
      labelField={"name_route_time"}
      searchParams={{
        date: formatDateUrl(state.period.startDate),
        trip_type: tripType,
        route_id: state.route?.id,
        school_id: state.currentSchoolId,
        school_ids: state.route?.school_ids
      }}
      defaultValue={stop}
      resetOptions={state.route?.id}
      disabled={disabled}
    />
  );
};

export default StopSelect;
