import {
  getAnchorType,
  getRouteType,
  getStopFrom,
  getStopType
} from "components/admin_v2/students/stores/stopsModel";
import { toDate, formatHumanDate } from "lib/dates";
import { assign, castArray, get, set } from "lodash";
import { TRIP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import { TRIP_MODE_TYPES } from "./modeChangeStore";

// default data for change request trips
export const defaultTripEditData = (params) => {
  const {
    date,
    school,
    student,
    tripType,
    changeMode = null,
    route = null,
    rideType = "students",
    isNewStudent = false
  } = params;
  if (!changeMode) return {};
  if (
    isNewStudent &&
    [TRIP_MODE_TYPES.create_new_route, TRIP_MODE_TYPES.request_new_stop].includes(changeMode)
  )
    return defaultTripNewStudentData(params);
  const routeType = route?.route_type || route?.routeType || getRouteType(tripType);
  const baseData = {
    anchor: getAnchorType(tripType),
    period: { startDate: date, endDate: date, days: [] },
    routeType,
    school: school,
    changeMode,
    student,
    nonResetFields: ["anchor", "routeType", "tripTypes"],
    tripTypes:
      changeMode === TRIP_MODE_TYPES.create_new_route && routeType === "regular"
        ? TRIP_TYPES.regular
        : [tripType],
    rideType: rideType || "students",
    day: date,
    workflow: { createRoute: changeMode === TRIP_MODE_TYPES.create_new_route, stops: {} }
  };
  if (changeMode === TRIP_MODE_TYPES.request_new_stop) {
    const stopType = getStopType(tripType);
    const workflow = {
      createRoute: false,
      stops: { [tripType]: { [stopType]: "new" } }
    };
    return assign(baseData, { workflow });
  }
  return changeMode === TRIP_MODE_TYPES.create_one_off_route
    ? assign(baseData, defaultTripOneOffData(params))
    : baseData;
};

const getStudentPrimaryAddress = (student) => {
  if (!student.addresses) return null;

  return (student.addresses?.find((a) => a.primary) || student.addresses[0])?.address;
};

// default data in case of one-off (only for other trips + admin user)
const defaultTripOneOffData = ({ date, student, tripType, rideType, route, changeMode }) => {
  if (route?.route_type === "custom") return {};
  const leg = tripType == "to_school" ? "AM" : "PM";
  const stopType = getStopType(tripType);
  const routeName = `ONEOFF_${leg}_${student.last_name}_${formatHumanDate(date)}`;
  const workflow = {
    createRoute: true,
    stops: { [tripType]: { [stopType]: "student" } }
  };
  let stops = {};
  if (route) {
    TRIP_TYPES.regular.forEach((direction) => {
      const anchorType = getAnchorType(direction);
      const path = `${direction}.${anchorType}`;
      const anchors = castArray(get(route, `anchors.${direction}`, []));
      const anchor = student.school_id
        ? anchors.find((a) => a.school_id === student.school_id)
        : anchors[0];
      const sameStop = getStopFrom(anchor || {});
      set(stops, path, sameStop);
    });
  }
  if (student.addresses?.length === 1) {
    set(stops, `${tripType}.${stopType}.stopLocation.address`, student.addresses[0].address);
  }
  return {
    route: { name: routeName, ridersType: rideType || "students", oneoff: true },
    tripTypes: TRIP_TYPES.regular,
    changeMode,
    stops,
    workflow
  };
};

// default data when we're creating transportation for new student
const defaultTripNewStudentData = ({ date, school, student, tripType, changeMode }) => {
  const stopType = getStopType(tripType);
  const workflow = {
    createRoute: changeMode === TRIP_MODE_TYPES.create_new_route,
    stops: { [tripType]: { [stopType]: "student" } }
  };
  const stops = {
    [tripType]: { [stopType]: { stopLocation: { address: getStudentPrimaryAddress(student) } } }
  };
  const endDate = toDate(school.last_day);

  return {
    anchor: getAnchorType(tripType),
    changeMode,
    period: { startDate: date, endDate, days: [] },
    routeType: "regular",
    school: school,
    student,
    nonResetFields: ["anchor", "routeType", "tripTypes"],
    tripTypes: changeMode === TRIP_MODE_TYPES.create_new_route ? TRIP_TYPES.regular : [tripType],
    day: date,
    workflow,
    stops
  };
};
