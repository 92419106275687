import { action, thunk } from "easy-peasy";
import { easyStateSetters } from "lib/easyState";
import { formatDateUrl } from "lib/dates";
import { saveScheduleChange } from "services/api";

export const defaultState = {
  errors: {},
  period: {}
};

const defaultPeriodFor = (date) => ({ startDate: date, endDate: date, days: [] });
const initStoreFromProps = (props) => ({ period: defaultPeriodFor(props.date) });

export const removeEditorStore = (initialData = {}) => ({
  ...easyStateSetters(defaultState, initStoreFromProps(initialData)),

  updateState: action((state, period) => {
    state.period = period;
  }),

  submit: thunk((actions, params = {}, h) => {
    const state = h.getState();
    const { currentDate, student, trip } = params;
    const period = {
      startDate: formatDateUrl(state.period.startDate),
      endDate: state.period.endDate === "" ? "" : formatDateUrl(state.period.endDate),
      daysOfWeek: state.period.days
    };
    const events = [
      {
        id: student.id,
        date: formatDateUrl(currentDate),
        eventType: "remove",
        stopId: trip.stop?.id,
        tripType: trip.trip_type,
        period
      }
    ];

    return saveScheduleChange({
      school_id: student.school_id,
      student_id: student.id,
      events,
      stops: []
    });
  })
});
