import React from "react";
import { addDays } from "date-fns";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker } from "@mui/x-date-pickers";
import { useStoreState } from "easy-peasy";
import { isEmpty, map } from "lodash";
import I18n from "utils/i18n.js";
import FormSelect from "components/admin_v2/ui/FormSelect";
import MultiSchoolsSelect from "components/admin_v2/ui/MultiSchoolsSelect";
import Spinner from "components/admin_v2/ui/Spinner";
import { reportTypes } from "./ReportHelpers";
import ReportGenerationStatus from "./ReportGenerationStatus";
import useTesting from "lib/useTesting";
import useStyles from "./forms/useStyles";
import { DISTRICT_REPORTS } from "./stores/createReportStore";
import { ROUTE_SHEETS_ADD_DAYS } from "utils/constants";

const DATE_RANGE_REPORTS = [
  "adm",
  "historical_trips",
  "all_trips",
  "changelogs",
  "route_sheets",
  "school_events"
];
const DATE_RANGE_DISABLED = ["route_sheets"];
const NO_SCHOOL_REPORTS = ["route_sheets", "district_curbside"];

const CreateReportHeader = ({ reportStore }) => {
  const cls = useStyles();
  const { districts, schools, user, hasDistrict, hasMultipleSchools } = useStoreState((s) => s.app);
  const [state, actions] = reportStore;

  useTesting({
    "createReport:setDate": (date) => {
      actions.setDate(date);
    }
  });

  if (hasMultipleSchools && isEmpty(schools)) return <Spinner />;

  const selectReportType = (e) => actions.updateReportType(e.target.value);
  const selectDistrict = (e) => actions.setDistrictId(e.target.value);
  const handleSchoolsChange = (_e, newValues) => actions.setSchoolIds(map(newValues, "id"));

  const reportOptions = reportTypes(user, true, hasDistrict);

  const datePickers = [
    {
      open: state.datePickerOpen,
      onOpen: () => actions.setDatePickerOpen(true),
      onClose: () => actions.setDatePickerOpen(false),
      value: state.date,
      onChange: (e) => {
        actions.setDate(e);
        if (DATE_RANGE_DISABLED.includes(state.reportType)) {
          actions.setDateTo(addDays(e, ROUTE_SHEETS_ADD_DAYS));
        }
      },
      label: I18n.t("report.form.label.date_from")
    },
    {
      open: state.dateToPickerOpen,
      onOpen: () => actions.setDateToPickerOpen(true),
      onClose: () => actions.setDateToPickerOpen(false),
      value: state.dateTo,
      onChange: actions.setDateTo,
      disabled: DATE_RANGE_DISABLED.includes(state.reportType) ? true : false,
      label: I18n.t("report.form.label.date_to")
    }
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item sx={{ width: "66%" }}>
        <FormSelect
          itemType="reportType"
          options={reportOptions}
          value={state.reportType}
          onChange={selectReportType}
          cls={cls}
          clsKeys={["fullSelect", "fullSelect"]}
        />
        <Box mb={1} mt={2}>
          <ReportGenerationStatus reportStore={reportStore} />
        </Box>
      </Grid>
      {state.reportType === "changelogs" && (
        <Grid item sm={4}>
          <Typography>{I18n.t("report.form.hint.changelogs_date_range")}</Typography>
        </Grid>
      )}
      {DATE_RANGE_REPORTS.includes(state.reportType) ? (
        <Grid item sm={4}>
          <Box display="flex">
            <FormLabel className={cls.label}>
              {state.reportType === "route_sheets"
                ? I18n.t("report.form.label.sheets_date_range")
                : I18n.t("report.form.label.date_range")}
            </FormLabel>
            {state.reportType === "route_sheets" && (
              <Box display="flex">
                <InfoIcon className={cls.infoHint} />
                <Typography className={cls.hint}>
                  {I18n.t("report.form.hint.route_sheets_date_range")}
                </Typography>
              </Box>
            )}
          </Box>
          {datePickers.map((datePicker) => {
            return (
              <Box className={cls.pickerWrapper} key={datePicker.label}>
                <FormLabel className={cls.label}>{datePicker.label}</FormLabel>
                <DatePicker
                  inputFormat="MMMM do"
                  disableMaskedInput
                  open={datePicker.open}
                  onOpen={() => datePicker.onOpen()}
                  onClose={() => datePicker.onClose()}
                  value={datePicker.value}
                  onChange={(e) => datePicker.onChange(e)}
                  disabled={datePicker.disabled}
                  renderInput={(props) => <TextField {...props} variant="outlined" />}
                />
              </Box>
            );
          })}
        </Grid>
      ) : (
        <Grid item sm={4}>
          <FormLabel className={cls.label}>{I18n.t("report.form.label.date")}</FormLabel>
          <DatePicker
            inputFormat="MMMM do"
            disableMaskedInput
            open={state.datePickerOpen}
            onOpen={() => actions.setDatePickerOpen(true)}
            onClose={() => actions.setDatePickerOpen(false)}
            value={state.date}
            onChange={actions.setDate}
            renderInput={(props) => <TextField {...props} variant="outlined" />}
          />
        </Grid>
      )}
      {hasMultipleSchools && !NO_SCHOOL_REPORTS.includes(state.reportType) && (
        <Grid item sx={{ width: "66%" }}>
          <FormControl className="report-school" variant="outlined" fullWidth>
            <FormLabel className={cls.label}>{I18n.t("report.form.label.school")}</FormLabel>
            <MultiSchoolsSelect
              schools={schools}
              schoolIds={state.schoolIds}
              onChange={handleSchoolsChange}
            />
          </FormControl>
        </Grid>
      )}
      {DISTRICT_REPORTS.includes(state.reportType) && (
        <Grid item sx={{ width: "66%" }}>
          <FormSelect
            itemType="district"
            options={districts}
            value={state.districtId}
            onChange={selectDistrict}
            cls={cls}
            clsKeys={["fullSelect", "fullSelect"]}
            error={state.reportErrors?.districtId || state.reportErrors?.district}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CreateReportHeader;
