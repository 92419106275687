import React from "react";
import { capitalize } from "lodash";
import classNames from "classnames";
import { Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useContainerStyles } from "components/admin_v2/useStyles";
import I18n from "utils/i18n.js";

const BANNER_ENVIRONMENTS = ["development", "qa", "staging", "demo"];

export const EnvBannerVisible = () => BANNER_ENVIRONMENTS.includes(ENV.RAILS_ENV);

const Envbar = (_props) => {
  const RAILS_ENV = ENV.RAILS_ENV;
  const cls = useStyles();
  const clsContainer = useContainerStyles();

  const toolbarCls = classNames(clsContainer.mainGutterPadding, [cls.toolbar, RAILS_ENV]);

  return (
    EnvBannerVisible() && (
      <Toolbar className={toolbarCls}>
        <Typography className={cls.toolbarContainer}>
          {I18n.t(`ui.env_banner`, { env: capitalize(RAILS_ENV) })}
        </Typography>
      </Toolbar>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    position: "sticky",
    top: 0,
    zIndex: 21,
    height: theme.spacing(5),
    display: "flex",
    minHeight: "auto",
    "&.staging": {
      backgroundColor: "#ffb700"
    },
    "&.qa, &.development": {
      backgroundColor: "#E399BD"
    },
    "&.demo": {
      backgroundColor: "#7fc944"
    }
  },
  toolbarContainer: {
    display: "flex",
    flex: "1",
    color: "#4D4E55",
    fontWeight: "bold",
    maxWidth: theme.custom.CONTAINER_WIDTH,
    margin: "0 auto"
  }
}));

export default Envbar;
