import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import { Typography, Box } from "@mui/material";
import { OutlinedBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import VendorForm from "components/admin_v2/vendor_config/vendors/VendorForm";
import I18n from "utils/i18n.js";
import { PROVIDERS_WITH_DEVICES } from "utils/constants";
import { vendorsStore } from "components/admin_v2/vendor_config/stores/vendorsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const VendorEdit = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => vendorsStore());
  const vendor = state.vendor;
  const id = props.match.params.id;

  useEffect(() => {
    if (!id) return;

    actions.fetchVendor(id);

    return () => {
      actions.setVendor(null);
    };
  }, [id]);

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      <BreadCrumbs path={pageRoutes.vendors()} label={I18n.t("vendor_config.vendors.breadcrumb")} />
      {vendor && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {vendor.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <OutlinedBtn className={`${cls.actionBtn} active`} disabled>
              {I18n.t("vendor_config.edit_vendor.btn")}
            </OutlinedBtn>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
              {vendor.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
            </OutlinedBtn>
            {PROVIDERS_WITH_DEVICES.includes(vendor.provider) && (
              <PrimaryBtn className={cls.actionBtn} onClick={() => actions.refetchDevices(id)}>
                {I18n.t("ui.btn.refetch_devices")}
              </PrimaryBtn>
            )}
          </Box>
        </Box>
      )}
      {state.loading ? <Spinner /> : <VendorForm vendor={vendor} />}
    </React.Fragment>
  );
};

export default VendorEdit;
