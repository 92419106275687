import { computed } from "easy-peasy";
import { strPresent } from "lib/util";
import tableSettingStore from "./tableSettingStore";
import { createAddress, removeAddress, saveAddress } from "services/apiAddresses";

const addressesStore = (initial = {}) => ({
  ...tableSettingStore({
    entity: { name: "Address", key: "address" },
    endPoints: {
      createEntity: createAddress,
      removeEntity: removeAddress,
      saveEntity: saveAddress
    },
    ...initial
  }),

  // validate address
  isValid: computed((state) => (idx) => {
    const data = state.getRow(idx)?.entity || {};
    return strPresent(data.name) && strPresent(data.address);
  }),

  newEntity: computed((state) => {
    return {
      id: null,
      name: "",
      address: "",
      primary: state.data.length === 0, // the first one is primary
      lat: null,
      lng: null
    };
  }),

  formData: computed((state) => (idx) => {
    return state.data[idx].entity;
  })
});

export default addressesStore;
