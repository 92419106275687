import React, { Fragment, useEffect } from "react";
import { snakeCase } from "lodash";
import { useHistory } from "react-router";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useLocalStore, useStoreActions } from "easy-peasy";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import GroupContactsForm from "components/admin_v2/school_config/comms_config/GroupContactsForm";
import PhoneNumbersForm from "components/admin_v2/common/PhoneNumbersForm";
import CommsGroupDetailsForm from "./CommsGroupDetailsForm";
import { commsGroupFormStore } from "components/admin_v2/school_config/stores/commsGroupFormStore";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const CommsGroupForm = ({ group }) => {
  const cls = useCommonPageStyles();
  const history = useHistory();
  const formStore = useLocalStore(() => commsGroupFormStore(group));
  const [state, actions] = formStore;
  const { setFlashMessage } = useStoreActions((s) => s.app);

  useEffect(() => {
    actions.fetchContactOptions(state.details?.schoolId);
  }, [state.details?.schoolId]);

  const onSubmit = () => {
    actions.save().then((resp) => {
      setFlashMessage({ message: resp.message });
      history.push(pageRoutes.commsGroup(resp.group.id));
    });
  };

  let forms = {
    details: <CommsGroupDetailsForm store={formStore} />,
    contacts: <GroupContactsForm store={formStore} />,
    phoneNumbers: (
      <PhoneNumbersForm
        store={formStore}
        phoneRequired={false}
        maxSize={1}
        disabled={!!group?.id}
        withTwilio={true}
      />
    )
  };

  return (
    <Fragment>
      {Object.entries(forms).map(([formTitle, form]) => (
        <Box key={formTitle} my={2.5} className={`group-form-${snakeCase(formTitle)}`}>
          <Paper className={cls.paper}>
            <div className={cls.subTitle}>
              {I18n.t(`school_config.comms_group.title.${snakeCase(formTitle)}`)}
            </div>
            {form}
          </Paper>
        </Box>
      ))}
      <Box my={5}>
        <PrimaryBtn
          className="form-save"
          onClick={onSubmit}
          disabled={state.loading || !state.isValid}
        >
          {I18n.t("ui.btn.save")}
        </PrimaryBtn>
      </Box>
    </Fragment>
  );
};

export default CommsGroupForm;
