import { action, computed } from "easy-peasy";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";

// this store should be mixed in another componentStore.
// for example:
//    const myFormStore = createComponentStore((model) => ({
//      // form store fields ...
//      ...commGroupsStore(model?.groupContacts)
//    }))
//

const commGroupsStore = (initial) => ({
  ...easyState("groupContacts", initial ?? []),
  ...easyState("removedGroupContacts", []),

  groupContactsAttributes: computed((state) => [
    ...state.groupContacts,
    ...state.removedGroupContacts
  ]),

  isValidPGroupContacts: computed((state) => () => {
    const groupContacts = state.groupContacts;

    return groupContacts.every(
      (t) => strPresent(t.groupableType) && Number.isInteger(t.groupableId)
    );
  }),

  newGroupContact: action((state, _payload) => {
    state.groupContacts = [
      ...state.groupContacts,
      {
        groupableType: null,
        groupableId: null
      }
    ];
  }),

  updateGroupContact: action((state, payload) => {
    const { index, value } = payload;
    if (!value) return;

    const { id, type } = value;
    if (state.groupContacts[index]) {
      state.groupContacts[index]["groupableId"] = id;
      state.groupContacts[index]["groupableType"] = type;
    }
  }),

  removeGroupContact: action((state, index) => {
    const groupContact = state.groupContacts[index];
    if (groupContact.id) {
      // if it's a persisted groupContact we must set to _destroy
      state.removedGroupContacts.push({ ...groupContact, _destroy: true });
    }
    state.groupContacts.splice(index, 1);
  })
});

export default commGroupsStore;
