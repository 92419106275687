import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const searchCommsGroups = async (params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`communication_groups/search?${query}`));
};

export const fetchCommsGroups = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`communication_groups?${query}`), { transformData: true });
};

export const fetchCommsGroup = async (id) => {
  return await xhr.get(apiUrl(`communication_groups/${id}`), { transformData: true });
};

export const createCommsGroup = async (params) => {
  const url = apiUrl(`communication_groups`);
  return await xhr.post(url, params, { transformData: true });
};

export const saveCommsGroup = async (id, params) => {
  const url = apiUrl(`communication_groups/${id}`);
  return await xhr.post(url, params, { transformData: true });
};

export const deleteCommsGroup = async (id) => {
  const url = apiUrl(`communication_groups/${id}/delete`);
  return await xhr.post(url, {}, { transformData: true });
};

export const batchDelete = async (ids) => {
  const url = apiUrl("communication_groups/batch_delete");
  return await xhr.post(url, { communication_group_ids: ids });
};
