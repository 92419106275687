import React, { useEffect } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import cn from "classnames";
import { Typography, Box } from "@mui/material";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import SchoolForm from "components/admin_v2/school_config/schools/SchoolForm";
import I18n from "utils/i18n.js";
import { schoolsStore } from "components/admin_v2/school_config/stores/schoolsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { Redirect } from "react-router-dom";

const TOMS_HOST = `https://${ENV.APP_HOST}`;
const SLACK_CLIENT = ENV.SLACK_CLIENT_ID;
const SLACK_OAUTH_URL = "https://slack.com/oauth/authorize";
const SLACK_SCOPES = ["channels:history", "channels:read", "channels:write", "chat:write:bot"];

const slackIntegrationUrl = (id) => {
  const redirectUrl = `${TOMS_HOST}${pageRoutes.slackIntegration(id)}`;
  const params = [
    `client_id=${SLACK_CLIENT}`,
    `redirect_uri=${redirectUrl}`,
    `scope=${encodeURI(SLACK_SCOPES.join(" "))}`
  ].join("&");
  return `${SLACK_OAUTH_URL}?${params}`;
};

const SchoolEdit = (props) => {
  const { user, isRestrictedAdmin } = useStoreState((s) => s.app);
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => schoolsStore());
  const school = state.school;
  const id = props.match.params.id;

  useEffect(() => {
    if (!id) return;

    actions.fetchSchool(id);

    return () => {
      actions.setSchool(null);
    };
  }, [id]);

  if (state.loading) {
    return <Spinner />;
  }

  if (isRestrictedAdmin && school && !user.school_ids.includes(school.id)) {
    return <Redirect to={pageRoutes.schools()} />;
  }

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <BreadCrumbs path={pageRoutes.schools()} label={I18n.t("school_config.schools.breadcrumb")} />
      {school && (
        <>
          <Box className={cls.titleWithBtn}>
            <Typography variant="h1" gutterBottom>
              {school.name}
            </Typography>
            <Box className={cn("school-actions", cls.btnsWrapper)}>
              <OutlinedBtn
                className={cn("school-slackIntegrationBtn", cls.actionBtn)}
                component="a"
                href={slackIntegrationUrl(id)}
              >
                {school.slackIntegrationEnabled
                  ? I18n.t("ui.btn.update_slack_token")
                  : I18n.t("ui.btn.add_slack_integration")}
              </OutlinedBtn>
              {!isRestrictedAdmin && (
                <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
                  {school.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
                </OutlinedBtn>
              )}
            </Box>
          </Box>
          <SchoolForm school={school} />
        </>
      )}
    </React.Fragment>
  );
};

export default SchoolEdit;
