import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    marginBottom: theme.spacing(3)
  },
  label: {
    display: "block",
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  pickerWrapper: {
    maxWidth: theme.spacing(40),
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    alignItems: "center"
  },
  select: {
    maxWidth: theme.spacing(40)
  },
  fullSelect: {
    width: "100%"
  },
  wideSelect: {
    maxWidth: "66%"
  },
  inlineGroup: {
    display: "block"
  },
  inline: {
    display: "inline-block",
    marginRight: theme.spacing(7),
    "& .MuiFormControlLabel-label": {
      position: "relative",
      top: theme.spacing(0.3)
    }
  },
  filesNotice: {
    position: "relative",
    top: theme.spacing(-2.5),
    color: theme.custom.MEDIUM_DARK_GREY
  },
  infoHint: {
    color: theme.custom.MEDIUM_DARK_GREY,
    fontSize: "1rem",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  hint: {
    marginTop: theme.spacing(0.4),
    color: theme.custom.DARK_GREY_2,
    fontSize: "0.8rem",
    fontStyle: "italic"
  },
  smallerPadding: {
    paddingTop: `${theme.spacing(1.2)} !important`
  }
}));

export default useStyles;
