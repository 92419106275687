import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { fetchDistricts, fetchDistrict, deleteDistrict } from "services/apiDistricts";
import { setFlashMessage } from "services";
import { pageRoutes } from "services/api";
import history from "context/history";

export const defaultState = {
  // status
  loading: false,
  refresh: false,
  // filters
  query: null,
  // data
  districts: [],
  district: null,
  errors: {}
};

export const districtsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["scope", "query"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchDistricts: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope"]);

    actions.setLoading(true);

    return fetchDistricts({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.districts = data.districts;
    state.collection = data.districts;
    state.total = parseInt(data.total);
  }),

  addDistrict: action((state, districtForm) => {
    let districtsList = state.districts;
    districtsList.unshift(districtForm);
    state.districts = districtsList;
  }),

  removeDistrict: action((state, id) => {
    const newDistrictsList = state.districts.filter((sl) => sl.id !== id);
    state.districts = newDistrictsList;
  }),

  fetchDistrict: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchDistrict(id)
      .then((resp) => {
        actions.setDistrict(resp.district);
      })
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  deleteDistrict: thunk((actions, id, h) => {
    actions.setLoading(true);
    const { district } = h.getState();

    deleteDistrict(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (district) {
          history.push({ pathname: pageRoutes.districts() });
        } else {
          actions.removeDistrict(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
