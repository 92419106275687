import React from "react";
import TableRow from "@mui/material/TableRow";
import classNames from "classnames";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { tableCellType, IconCell } from "components/admin_v2/common/EventHelpers";
import { studentWithDate } from "lib/queryString";
import { slugsFor } from "services/api";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";
import useStyles from "./useStyles";

const StudentTableRow = ({ day, student, eventType = null, children }) => {
  const cls = useStyles({ eventType });

  const EventTableCell = tableCellType(eventType, student.day_off);

  return (
    <TableRow key={student.id} className={cls.studentRow}>
      <EventTableCell align="left" className={classNames(cls.cell, cls.idCell)}>
        <RouterLink to={studentWithDate(day, student.id, slugsFor(student))}>
          {student.id}
        </RouterLink>
      </EventTableCell>
      <IconCell className={classNames(cls.cell, cls.gradeCell)} padding="none">
        {withoutAccommodation(student) ? null : <StudentAccommodationPopup student={student} />}
      </IconCell>
      <EventTableCell align="left" className={classNames(cls.cell, cls.studentCell)}>
        {student.first_name}
      </EventTableCell>
      <EventTableCell align="left" className={classNames(cls.cell, cls.studentCell)}>
        {student.last_name}
      </EventTableCell>
      <EventTableCell align="left" className={classNames(cls.cell, cls.gradeCell)}>
        {student.grade}
      </EventTableCell>
      {children}
    </TableRow>
  );
};

export default StudentTableRow;
