import React, { Fragment, useEffect } from "react";
import { snakeCase } from "lodash";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useLocalStore, useStoreActions } from "easy-peasy";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";
import PhoneNumbersForm from "components/admin_v2/common/PhoneNumbersForm";
import CommGroupsForm from "components/admin_v2/common/CommGroupsForm";
import Spinner from "components/admin_v2/ui/Spinner";
import UserDetailsForm from "./UserDetailsForm";
import { userFormStore } from "components/admin_v2/common/stores/userFormStore";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";

const UserForm = ({ role, user }) => {
  const cls = useCommonPageStyles();
  const history = useHistory();
  const formStore = useLocalStore(() => userFormStore({ role, user }));
  const [state, actions] = formStore;
  const { setFlashMessage, updateScopes } = useStoreActions((s) => s.app);

  useEffect(() => {
    actions.fetchAllSchoolsDistricts({ role });

    return () => {
      actions.setAllSchools([]);
      actions.setAllDistricts([]);
    };
  }, []);

  useEffect(() => {
    if (!user?.id) return;

    actions.fetchGroupOptions(user.id);
  }, [user?.id]);

  if (state.loading) return <Spinner />;

  const onSubmit = () => {
    actions
      .save()
      .then((resp) => {
        setFlashMessage({ message: resp.message });
        updateScopes({ userId: resp.user.id });
        history.push(pageRoutes.user(resp.user.id));
      })
      .catch((err) =>
        setFlashMessage({
          message:
            err.response?.data?.message || I18n.t("data_management.errors.fail", { name: "User" })
        })
      )
      .finally(() => actions.setLoading(false));
  };

  const forms = {
    details: <UserDetailsForm store={formStore} />,
    phoneNumbers: <PhoneNumbersForm store={formStore} phoneRequired={state.phoneRequired} />,
    communicationGroups: <CommGroupsForm store={formStore} />
  };

  return (
    <Fragment>
      {Object.entries(forms).map(([formTitle, form]) => (
        <Box key={formTitle} my={2.5} className={`user-form-${snakeCase(formTitle)}`}>
          <Paper className={cls.paper}>
            <div className={cls.subTitle}>
              {I18n.t(`settings_config.user.title.${snakeCase(formTitle)}`)}
            </div>
            {form}
          </Paper>
        </Box>
      ))}
      <Box my={5}>
        <PrimaryBtn size="large" onClick={onSubmit} disabled={state.loading || !state.isValid}>
          {I18n.t("ui.btn.save")}
        </PrimaryBtn>
        <NavLink to={pageRoutes.user(user?.id)} className={cls.cancelLink}>
          {I18n.t("ui.btn.cancel")}
        </NavLink>
      </Box>
    </Fragment>
  );
};

export default UserForm;
