import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchRoutes = async (options = {}) => {
  const query = queryString(options);
  return await xhr.get(apiUrl(`routes?${query}`), { transformData: true });
};

export const fetchRouteFilters = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`routes/filters?${query}`), { transformData: true });
};

export const fetchRoutesList = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`routes/list?${query}`));
};

export const addReturnRoute = async (routeId, params = {}) => {
  const url = apiUrl(`routes/${routeId}/create_return`);
  return await xhr.post(url, params);
};

export const saveRoute = async (params, options = {}) => {
  const url = apiUrl(`routes`);
  return await xhr.post(url, params, options);
};

export const updateRoute = async (id, params) => {
  const url = apiUrl(`routes/${id}`);
  return await xhr.post(url, { route: params });
};

export const archiveRoute = async (id) => {
  const url = apiUrl(`routes/${id}/archive`);
  return await xhr.post(url, {});
};

export const fetchRoute = async (routeId, params = {}) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/${routeId}?${query}`));
};

export const fetchRouteOptions = async (options = {}) => {
  const query = queryString({});
  return await xhr.get(apiUrl(`routes/options?${query}`), options);
};

export const fetchVehicleTypes = async (options = {}) => {
  const query = queryString({});
  return await xhr.get(apiUrl(`routes/vehicle_types?${query}`), options);
};

export const searchRoutes = async (params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/search?${query}`));
};

export const fetchActiveRoutes = async (params = {}) => {
  const query = queryString(params);
  const url = apiUrl(`routes/active?${query}`);
  return await xhr.get(url);
};

export const fetchRouteSchedule = async (routeId, params = {}) => {
  const query = queryString(params, true);
  const url = apiUrl(`routes/${routeId}/schedule?${query}`);
  return await xhr.get(url);
};

export const saveStopEvents = async (routeId, stopId, params) => {
  const url = apiUrl(`routes/${routeId}/stops/${stopId}/events`);
  return await xhr.post(url, params);
};

export const pushToAvl = async (routeId) => {
  const query = queryString({});
  return await xhr.post(apiUrl(`routes/${routeId}/push_to_avl?${query}`));
};

export const fetchWeekStops = async (routeId, params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/${routeId}/week_stops?${query}`));
};

export const fetchWeekTransport = async (routeId, params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/${routeId}/week_transport?${query}`));
};

export const fetchDayStops = async (routeId, params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/${routeId}/day_stops?${query}`), { transformData: true });
};

export const searchAssignedStops = async (routeId, studentId, params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/${routeId}/assigned_stops/${studentId}?${query}`));
};

export const fetchVendorSchedulesInfo = async (routeId) => {
  return await xhr.get(apiUrl(`routes/${routeId}/vendor_schedules_info`));
};

export const fetchFutureOneDayAssignments = async (routeId, params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/${routeId}/future_one_day_assignments?${query}`));
};
