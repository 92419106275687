import React, { Fragment } from "react";
import { useLocalStore } from "easy-peasy";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/settings_config/SettingsConfigTabs";
import UserForm from "./UserForm";
import I18n from "utils/i18n.js";
import { usersStore } from "components/admin_v2/common/stores/usersStore";
import { pageRoutes } from "services/api";

const UserNew = (_props) => {
  const [state, _actions] = useLocalStore(() => usersStore({ role: "admin" }));

  return (
    <Fragment>
      <TopPageLayout title={I18n.t("settings_config.new_user.title")} />
      <SchoolConfigTabs />

      <BreadCrumbs path={pageRoutes.users()} label={I18n.t("settings_config.users.breadcrumb")} />
      <UserForm role={state.role} user={{}} />
    </Fragment>
  );
};

export default UserNew;
