import React, { Fragment } from "react";
import { Divider } from "@mui/material";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import RouteNewForm from "./route/RouteNewForm";
import RouteForm from "./route/RouteForm";
import ToggleCreate from "components/admin_v2/common/ToggleCreate";
import RoutePeriodSelect from "./route/RoutePeriodSelect";
import RouteNewDirectionsForm from "./route/RouteNewDirectionsForm";

const TripForm = ({ cls, store }) => {
  const [state, actions] = store;
  const labelKeys = { choose: I18n.t("ui.route.choose"), new: I18n.t("ui.route.new") };
  const labels = { links: labelKeys, labels: labelKeys };

  const toggleCreateRoute = (createRoute) => {
    actions.resetState({ workflow: { createRoute, stops: {} }, routeType: state.routeType });
  };

  return (
    <Fragment>
      <ErrorList errors={state.getTopLevelErrors} />
      <ToggleCreate
        labels={labels}
        create={state.workflow.createRoute || false}
        setCreate={toggleCreateRoute}
        cls={cls}
      />
      {state.workflow.createRoute ? (
        <RouteNewForm store={store} cls={cls}>
          <RoutePeriodSelect store={store} cls={cls} />
          <Divider className={cls.divider} />
          <RouteNewDirectionsForm cls={cls} store={store} />
        </RouteNewForm>
      ) : (
        <RouteForm store={store} cls={cls} />
      )}
    </Fragment>
  );
};

export default TripForm;
