import { computed, thunk } from "easy-peasy";
import { pick } from "lodash";
import { camelizeKeys } from "humps";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";
import { createVendor, saveVendor } from "services/apiVendors";
import addressesStore from "components/admin_v2/common/stores/addressesStore";
import phoneNumbersStore from "components/admin_v2/common/stores/phoneNumbersStore";

const REQUIRED = ["name", "provider"];

export const vendorFormStore = (vendor) => {
  const details = {
    ...pick(vendor, ["name", "provider", "email", "apiKey"]),
    vendorCredentialAttributes: vendor?.vendorCredential || {}
  };

  return {
    ...easyState("vendor", vendor),
    ...easyState("details", details),
    ...easyState("errors"),
    ...easyState("loading", false),

    ...addressesStore(vendor?.addresses),
    ...phoneNumbersStore(vendor?.phoneNumbers),

    isValid: computed((state) => {
      const { details } = state;
      const validDetails = REQUIRED.every((field) => strPresent(details[field]?.toString()));
      const validAddrs = state.isValidAddresses({ noAddressAllowed: true });
      const validPhones = state.isValidPhoneNumbers({ noPhoneAllowed: true });
      return validDetails && validAddrs && validPhones;
    }),

    formData: computed((state) => {
      return {
        vendor: {
          ...state.details,
          addressesAttributes: state.addressesAttributes,
          phoneNumbersAttributes: state.phoneNumbersAttributes
        }
      };
    }),

    save: thunk(async (actions, _payload, h) => {
      const state = h.getState();
      if (!state.isValid) return Promise.reject();

      actions.setLoading(true);
      actions.setErrors(null);
      const data = state.formData;
      try {
        const vendor = state.vendor;
        if (vendor.id) {
          return await saveVendor(vendor.id, data);
        } else {
          return await createVendor(data);
        }
      } catch (err) {
        const errors = err.response?.data?.errors;
        actions.setErrors(camelizeKeys(errors));
        throw err;
      } finally {
        actions.setLoading(false);
      }
    })
  };
};
