import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";

const SelectFilter = (props) => {
  const {
    id,
    value,
    onChange,
    items,
    label,
    variant = "standard",
    selectedPrefix = null,
    disabled = false
  } = props;
  const cls = useStyles(props);
  const itemName = (item) => {
    return selectedPrefix && item.id === value ? `${selectedPrefix} ${item.name}` : item.name;
  };

  return (
    <FormControl className={cls.formControl}>
      <Select
        id={`${id}-select`}
        value={items?.length ? value || "" : ""}
        onChange={onChange}
        key={`${id}-select`}
        variant={variant}
        classes={{ select: cls[`${variant}Select`] || "" }}
        displayEmpty
        disabled={disabled}
      >
        {label && (
          <MenuItem value="" className={cls[variant]} disabled={props.disabledLabel}>
            {label}
          </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem value={item.id} className={cls.select} key={`${id}-select-${item.id}`}>
            {itemName(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: (props) => theme.spacing(props.width || 25),
    marginRight: (props) => (props.mr === 0 ? 0 : theme.spacing(props.mr || 2)),
    marginLeft: (props) => (props.ml === 0 ? 0 : theme.spacing(props.ml || 2))
  },
  outlined: {
    height: theme.spacing(5)
  },
  standard: {
    backgroundColor: theme.custom.WHITE,
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.08)"
    },
    '&[data-value=""]': {
      minHeight: theme.spacing(4)
    }
  }
}));

export default SelectFilter;
