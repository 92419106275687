import React from "react";
import { Box } from "@mui/material";
import { upperFirst, get, map, snakeCase } from "lodash";
import FormDatePicker from "./FormDatePicker";
import I18n from "utils/i18n.js";

const FormDateRange = (props) => {
  const dates = map(["startDate", "endDate"], (key) => (
    <FormDatePicker
      key={`dt-${key}`}
      label={I18n.t(`ui.stop.${snakeCase(key)}`)}
      date={props[key]}
      onChange={props[`on${upperFirst(key)}Change`]}
      errors={get(props, `errors.${key}`)}
    />
  ));

  return (
    <Box display="flex" mt={2}>
      {dates}
    </Box>
  );
};

export default FormDateRange;
