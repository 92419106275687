import React from "react";
import { startsWith } from "lodash";
import { IMaskInput } from "react-imask";

const ANY = /[0-9a-z]/;
const US_MASK = [/[1-9+]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", ANY, ANY, ANY, ANY];
const INT_MASK = [
  "+",
  /[1-9]/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  ANY,
  ANY,
  ANY
];
const CLEAR_REGEX = /[-_\s.()]/g;

const PhoneInput = React.forwardRef((props, ref) => {
  const { value, onChange, ...other } = props;

  const mask = (val) => {
    let max, newMask;
    let num = val.replace(CLEAR_REGEX, "");

    if (startsWith(num, "+") && !startsWith(num, "+1")) {
      newMask = [...INT_MASK];
      max = 13;
    } else {
      newMask = [...US_MASK];
      max = 10;
    }

    if (num.length > max) {
      for (let i = 0; i < num.length - max; i++) {
        newMask.push(ANY);
      }
    }
    return newMask;
  };

  const input = startsWith(value, "+1") ? value.replace(/^\+1/, "") : value;

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      value={input}
      mask={mask}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

export default PhoneInput;
