import { createTheme } from "@mui/material/styles";

const themeConfig = {
  WHITE: "#ffffff",
  BLUE: "#218eed",
  LIGHT_BLUE: "rgba(84, 193, 255, .3)",
  LIGHT_BLUE_2: "#B0C5E6",
  YELLOW: "#ffb700",
  DARK_YELLOW: "#e6a100",
  LIGHT_YELLOW: "#fcfbdc",
  GREEN: "#7fc944",
  DARK_GREEN: "#258633",
  BRIGHT_GREEN: "#56D161",
  RED: "#ed6a66",
  DARK_RED: "#ba3733",
  DARK_GREY_1: "#4e4f56",
  DARK_GREY_2: "#6b7d8e",
  MEDIUM_DARK_GREY: "#8295ae",
  MEDIUM_GREY: "#d2d9e2",
  MEDIUM_GREY_2: "#B9C0C9",
  LIGHT_GREY: "#eff2f6",
  LIGHT_GREY_2: "#e5e8ec",
  PURPLE: "#2a0134",

  FONT: "proximanova, Roboto, sans-serif",
  SEMI_BOLD: "700",
  BOLD: "800",

  CONTAINER_WIDTH: "1680px"
};

const { palette } = createTheme();

export default createTheme({
  components: {
    MuiCssBaseline: {
      // to support v4 styles
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "700px",
          padding: "24px"
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        // NOTE: to support v4 styles, would be great to get rid of this, but controls (select/autocomplete) sizing will be off
        outlined: {
          backgroundColor: themeConfig.WHITE,
          padding: "10px 24px 10px 16px",
          lineHeight: "1.1876em",
          minHeight: "auto"
        },
        select: {
          "&:focus": {
            backgroundColor: themeConfig.WHITE
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: themeConfig.LIGHT_GREY_2,
          color: themeConfig.DARK_GREY_2,
          borderColor: themeConfig.MEDIUM_GREY
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({ ownerState }) => ({
          ...(ownerState?.startAdornment
            ? { padding: "8px 12px 8px 0px" }
            : { padding: "8px 12px" })
        })
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&[disabled]": {
            color: palette.action.disabled,
            pointerEvents: "none"
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: themeConfig.BLUE,
      contrastText: themeConfig.WHITE
    },
    secondary: {
      main: themeConfig.DARK_GREY_2
    },
    text: {
      primary: themeConfig.DARK_GREY_1,
      link: themeConfig.BLUE
    },
    background: {
      mediumGrey: themeConfig.MEDIUM_GREY,
      lightGrey: themeConfig.LIGHT_GREY,
      darkGrey: themeConfig.DARK_GREY_2,
      white: themeConfig.WHITE,
      lightBlue: themeConfig.LIGHT_BLUE
    }
  },
  typography: {
    fontFamily: themeConfig.FONT,
    button: {
      textTransform: "none",
      fontSize: "0.87rem",
      fontWeight: themeConfig.SEMI_BOLD
    },
    body2: {
      fontSize: "0.87rem",
      fontWeight: 400
    },
    h1: {
      fontSize: "1.7rem",
      fontWeight: themeConfig.BOLD
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: themeConfig.BOLD
    },
    h3: {
      fontSize: "1rem",
      fontWeight: themeConfig.BOLD
    }
  },
  custom: themeConfig
});

export const themeComms = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em"
        }
      }
    }
  },
  custom: themeConfig
});
