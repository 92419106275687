import React, { Fragment, useEffect } from "react";
import { snakeCase } from "lodash";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useLocalStore, useStoreActions } from "easy-peasy";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";
import PhoneNumbersForm from "components/admin_v2/common/PhoneNumbersForm";
import CommGroupsForm from "components/admin_v2/common/CommGroupsForm";
import VendorStaffDetailsForm from "./VendorStaffDetailsForm";
import { userFormStore } from "components/admin_v2/common/stores/userFormStore";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const VendorStaffForm = ({ role, user }) => {
  const cls = useCommonPageStyles();
  const history = useHistory();
  const formStore = useLocalStore(() => userFormStore({ role, user }));
  const [state, actions] = formStore;
  const { setFlashMessage } = useStoreActions((s) => s.app);

  useEffect(() => {
    if (!user?.id) return;

    actions.fetchGroupOptions(user.id);
  }, [user?.id]);

  const onSubmit = () => {
    actions.save().then((resp) => {
      setFlashMessage({ message: resp.message });
      history.push(pageRoutes.vendorStaff(resp.user.id));
    });
  };

  const forms = {
    details: <VendorStaffDetailsForm store={formStore} />,
    phoneNumbers: <PhoneNumbersForm store={formStore} phoneRequired={state.phoneRequired} />,
    communicationGroups: <CommGroupsForm store={formStore} />
  };

  return (
    <Fragment>
      {Object.entries(forms).map(([formTitle, form]) => (
        <Box key={formTitle} my={2.5} className={`staff-form-${snakeCase(formTitle)}`}>
          <Paper className={cls.paper}>
            <div className={cls.subTitle}>
              {I18n.t(`vendor_config.staff.title.${snakeCase(formTitle)}`)}
            </div>
            {form}
          </Paper>
        </Box>
      ))}
      <Box my={5}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onSubmit}
          disabled={state.loading || !state.isValid}
        >
          {I18n.t("ui.btn.save")}
        </Button>
        <NavLink to={pageRoutes.vendorStaff(user?.id)} className={cls.cancelLink}>
          {I18n.t("ui.btn.cancel")}
        </NavLink>
      </Box>
    </Fragment>
  );
};

export default VendorStaffForm;
