import React, { Fragment, useCallback, useMemo } from "react";
import memoize from "fast-memoize";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import ErrorList from "components/admin_v2/ui/ErrorList";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { searchAddress } from "services/apiAddresses";
import { strPresent } from "lib/util";
import { useTableFormStyles } from "./useTableFormStyles";

// For reusing this component, we expect to receive a form store that
// mixin the `./stores/addressesStore`
//
const AddressesForm = ({
  store,
  schoolId = null,
  withoutSchool = false,
  addressRequired = true,
  withTz = false
}) => {
  const cls = useTableFormStyles();
  const [state, actions] = store;
  const { addresses, errors } = state;

  const updateField = useCallback(
    memoize((index, field) => (e) => {
      const value = e.target.value;
      actions.updateAddress({ index, field, value });
    }),
    []
  );

  const remove = useCallback((e) => {
    if (window.confirm("Are you sure?")) {
      actions.removeAddress(e.currentTarget.dataset.index);
    }
  }, []);

  const data = useMemo(
    () =>
      (addresses || []).map((addr, index) =>
        addr._destroy ? null : (
          <TableRow hover tabIndex={-1} key={`addr-${index}-row`}>
            <TableCell className={[cls.cell, cls.smCell].join(" ")} key={`addr-${index}-primary`}>
              <GreenCheckbox
                checked={addr.primary}
                onChange={(_e) => actions.updatePrimaryAddress(index)}
                className={cls.check}
              />
            </TableCell>
            <TableCell className={cls.cellSelect} key={`addr-${index}-address`}>
              <AutocompleteSelect
                id={`addr-${index}-address-input`}
                getOptions={searchAddress}
                filterOptions={(options) => options} // we don't need to filter any results
                onOptionSelect={(data) => {
                  const value = data ? data.description : null;
                  actions.updateAddress({ index, field: "address", value });
                }}
                defaultSchool={withoutSchool ? "all" : "none"}
                searchParams={{ school_id: schoolId, withoutSchool, withTz }}
                labelField={"description"}
                defaultValue={addr.address || ""}
                placeholder="Address"
                maxWidth="100%"
              />
            </TableCell>
            <TableCell className={cls.cell} key={`addr-${index}-name`}>
              <TextField
                variant="standard"
                id={`addr-${index}-name-input`}
                label={<RequiredLabel label="Name" condition={strPresent(addr.name)} />}
                value={addr.name || ""}
                onChange={updateField(index, "name")}
                className={cls.field}
              />
            </TableCell>
            <TableCell className={cls.cell} key={`addr-${index}-lat`}>
              <TextField
                variant="standard"
                id={`addr-${index}-lat-input`}
                label="Lat"
                value={addr.lat || ""}
                onChange={updateField(index, "lat")}
                className={cls.field}
                type="number"
                inputProps={{ step: "any" }}
              />
            </TableCell>
            <TableCell className={cls.cell} key={`addr-${index}-lng`}>
              <TextField
                variant="standard"
                id={`addr-${index}-lng-input`}
                label="Lon"
                value={addr.lng || ""}
                onChange={updateField(index, "lng")}
                className={cls.field}
                type="number"
                inputProps={{ step: "any" }}
              />
            </TableCell>
            {withTz && (
              <TableCell className={cls.cell} key={`addr-${index}-time-zone`}>
                <Typography variant="body2" className={cls.marginTop}>
                  {addr.timezone}
                </Typography>
              </TableCell>
            )}
            <TableCell className={[cls.cell, cls.iconCell].join(" ")} key={`addr-${index}-del`}>
              <Link
                color="secondary"
                onClick={remove}
                className={cls.remove}
                data-index={index}
                underline="hover"
              >
                <DeleteOutline />
              </Link>
            </TableCell>
          </TableRow>
        )
      ),
    [addresses, schoolId]
  );

  if (!addresses) return;

  return (
    <Fragment>
      <ErrorList errors={errors} prefix="addresses" />
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell className={[cls.tableHeader, cls.smCell].join(" ")} key={`addr-h-primary`}>
              Primary
            </TableCell>
            <TableCell className={cls.tableHeader} key={`addr-h-address`}>
              Address
            </TableCell>
            <TableCell className={cls.tableHeader} key={`addr-h-name`}>
              Name
            </TableCell>
            <TableCell className={cls.tableHeader} key={`addr-h-lat`}>
              Lat
            </TableCell>
            <TableCell className={cls.tableHeader} key={`addr-h-lng`}>
              Lon
            </TableCell>
            {withTz && (
              <TableCell className={cls.tableHeader} key={`addr-h-timezone`}>
                Timezone
              </TableCell>
            )}
            <TableCell
              className={[cls.tableHeader, cls.iconCell].join(" ")}
              key={`addr-h-remove`}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{data}</TableBody>
      </Table>
      {addressRequired && (
        <div style={{ padding: "1em 1em 0" }}>
          <RequiredLabel text="At least one address is required" condition={addresses.length > 0} />
        </div>
      )}
      <Button
        className={cls.btn}
        variant="contained"
        color="secondary"
        onClick={actions.newAddress}
      >
        New Address
      </Button>
    </Fragment>
  );
};

export default AddressesForm;
