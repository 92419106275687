import ReactGA from "react-ga4";
import { isEmpty } from "lodash";

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !ENV.NODE_ENV || ENV.NODE_ENV === "development";
  if (!isEmpty(window.analyticsConf?.gaMeasurementId)) {
    ReactGA.initialize(window.analyticsConf.gaMeasurementId, { debug: isDev });
  }
};

const sendEvent = (payload) => {
  if (!window.gtag) return;
  ReactGA.event(payload);
};

const sendPageview = ({ path, userId }) => {
  if (!window.gtag) return;
  if (userId) ReactGA.set({ userId, toms_user_id: userId });
  ReactGA.send({ hitType: "pageview", page: path });
};

export default {
  init,
  sendEvent,
  sendPageview
};
