import React from "react";
import { Box, Link, FormControlLabel } from "@mui/material";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import { map } from "lodash";
import I18n from "utils/i18n.js";

const TIMES_OPTIONS = map(["all", "future"], (val) => ({
  id: val,
  name: I18n.t(`school_config.day_delta.actions.filter.select.${val}`)
}));

const DaysDeltaFilters = ({ formStore }) => {
  const [state, actions] = formStore;

  const updateTimesType = (e) =>
    actions.updatePersistedField({ field: "timesType", value: e.target.value });
  const updateShowAllTimes = (e) =>
    actions.updatePersistedField({ field: "allTimes", value: e.target.checked });

  return (
    <Box p={2} display="flex" justifyContent="space-between">
      <Box display="flex">
        <SelectFilter
          value={state.timesType}
          onChange={updateTimesType}
          items={TIMES_OPTIONS}
          variant="outlined"
          ml={0}
          id="dd-filterTimes"
        />
        <FormControlLabel
          key="dd-allTimes"
          control={<GreenCheckbox checked={!!state.allTimes} onChange={updateShowAllTimes} />}
          label={I18n.t("school_config.day_delta.actions.filter.show_times")}
        />
      </Box>
      <Link component="button" onClick={actions.toggleAllExpanded}>
        {I18n.t(
          `school_config.day_delta.actions.${state.expanded ? "collapse_all" : "expand_all"}`
        )}
      </Link>
    </Box>
  );
};

export default DaysDeltaFilters;
