import React from "react";
import { Box, FormLabel, Link } from "@mui/material";
import { get } from "lodash";

const ToggleCreate = ({ labels, cls, create, setCreate }) => {
  const toggleCreate = (e) => {
    e.preventDefault();
    setCreate(!create);
  };

  const toggleLink = (
    <Link href="#" onClick={toggleCreate} underline="always">
      {get(labels, `links.${create ? "choose" : "new"}`)}
    </Link>
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="baseline" mt={1}>
      <FormLabel className={cls.label}>
        {get(labels, `labels.${create ? "new" : "choose"}`)}
      </FormLabel>
      {toggleLink}
    </Box>
  );
};

export default ToggleCreate;
