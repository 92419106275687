import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import cn from "classnames";
import { snakeCase } from "lodash";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import I18n from "utils/i18n.js";
import { dismissalGroupsStore } from "components/admin_v2/school_config/stores/dismissalGroupsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DETAIL_FIELDS = ["school", "name", "createdAt", "updatedAt"];

const DismissalGroup = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => dismissalGroupsStore());
  const id = props.match.params.id;
  const { dismissalGroup } = state;

  useEffect(() => {
    if (!id) return;

    actions.fetchDismissalGroup(id);
  }, [id]);

  const detail = (prop) => {
    return (
      <Box className={cn(`details-${prop}`, cls.detail)} key={prop}>
        <div className={cls.detailHeader}>
          {I18n.t(`school_config.dismissal_group.labels.${snakeCase(prop)}`)}
        </div>
        <div>{dismissalGroup[prop] || "--"}</div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <BreadCrumbs
        path={pageRoutes.dismissalGroups()}
        label={I18n.t("school_config.dismissal_groups.breadcrumb")}
      />
      {dismissalGroup && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {dismissalGroup.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <RouterLink
              className={cls.actionBtn}
              to={pageRoutes.dismissalGroupEdit(dismissalGroup.id)}
            >
              <OutlinedBtn>{I18n.t("school_config.edit_dismissal_group.btn")}</OutlinedBtn>
            </RouterLink>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
              {dismissalGroup.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
            </OutlinedBtn>
          </Box>
        </Box>
      )}
      <Paper className={cls.paper}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={cls.subTitle}>
              {I18n.t("school_config.dismissal_group.title.details")}
            </Box>
            <Grid container className={cn("dismissal-group-details", cls.grid)} spacing={5}>
              <Grid item xs={6}>
                {dismissalGroup && DETAIL_FIELDS.map(detail)}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default DismissalGroup;
