import React from "react";
import { Box, Link, Typography } from "@mui/material";
import useStyles from "./useTripEditorStyles";

const UndoPersistentChange = ({ modeChangeStore, idx }) => {
  const cls = useStyles();
  const [state, actions] = modeChangeStore;
  const trip = state.getTrip(idx);
  const tripDetails = state.getDetails(idx);
  if (tripDetails?.dropdownEnabled || trip?.change_request) return null;

  const undoPersistentChange = () => {
    actions.updateTripDetails({
      idx,
      eventType: tripDetails.initialEventType,
      dropdownEnabled: true
    });
  };

  return (
    <Box>
      <Typography variant="body2">
        The one-off student event will be canceled.
        <Link
          className={cls.undoLink}
          onClick={undoPersistentChange}
          component="button"
          underline="hover"
        >
          Undo
        </Link>
      </Typography>
    </Box>
  );
};

export default UndoPersistentChange;
