import React from "react";
import { Box, FormControl, FormLabel, FormControlLabel, RadioGroup } from "@mui/material";
import { isEmpty, map } from "lodash";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import StopNewForm from "components/admin_v2/students/trip/stop/StopNewForm";
import { STOP_TYPES } from "components/admin_v2/students/stores/addTripStore";

const AddRouteStopForm = ({ store, cls, tripType }) => {
  const [state, actions] = store;
  const { errors, errorStop } = state.getStopErrorsFor(tripType, state.anchor);
  const stopType = state.anchor;

  const anchorTypesRadio = map(STOP_TYPES, (type) => (
    <FormControlLabel
      value={type}
      key={`anchor-type-${type}`}
      control={<GreenRadio />}
      label={I18n.t(`ui.stop.label.${type}`)}
    />
  ));

  const handleChange = (event) => actions.switchAnchor(event.target.value);

  return (
    <FormControl fullWidth error={!isEmpty(errors)} required>
      <ErrorList errors={errorStop} />
      <Box display="flex" justifyContent="space-between" alignItems="baseline" mt={1}>
        <FormLabel className={cls.label}>{I18n.t(`ui.stop.${stopType}`)}</FormLabel>
        <RadioGroup value={stopType} onChange={handleChange} row>
          {anchorTypesRadio}
        </RadioGroup>
      </Box>
      <StopNewForm store={store} cls={cls} stopType={stopType} tripType={tripType} />
    </FormControl>
  );
};

export default AddRouteStopForm;
