import React, { Fragment, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import cn from "classnames";
import { startCase } from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import I18n from "utils/i18n.js";
import { commaErrors, strPresent } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const VEHICLE_TYPES = ["cab", "bus", "van", "walk", "PPU", "distance", "carpool", "transit"];

const VehicleForm = ({ store }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { vehicleForm, errors } = state;
  const { updateVendors } = useStoreActions((s) => s.vendor);
  const { vendors } = useStoreState((s) => s.vendor);

  useEffect(() => {
    updateVendors();
  }, []);

  useEffect(() => {
    if (!state.vehicleForm?.transportationVendorId) {
      actions.setTrackingDevices([]);
      return;
    }

    actions.updateTrackingDevices(state.vehicleForm?.transportationVendorId);
  }, [state.vehicleForm?.transportationVendorId]);

  const onChange = (field) => (e) => {
    let updates = { ...vehicleForm, [field]: e.target.value };

    if (field === "transportationVendorId") {
      updates.trackingDeviceId = null;
    }

    actions.setVehicleForm(updates);
  };

  const changeDevice = (val) => {
    actions.setVehicleForm({ ...vehicleForm, trackingDeviceId: val || null });
  };

  return (
    <Fragment>
      <Grid container className={cls.grid} spacing={5}>
        <Grid item xs={8}>
          <FormControl className={cls.field} error={!!errors?.vendorId} variant="standard">
            <InputLabel>{I18n.t("vendor_config.vehicles.form.vendor.label")}</InputLabel>
            {vendors.length > 0 && (
              <Select
                value={vehicleForm?.transportationVendorId || ""}
                onChange={onChange("transportationVendorId")}
                className="vehicle-vendor"
                MenuProps={{ className: "vehicle-vendor-menu" }}
              >
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {errors?.vendorId && <FormHelperText>{commaErrors(errors?.vendor_id)}</FormHelperText>}
          </FormControl>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("vendor_config.vehicles.form.name.label")}
                condition={strPresent(vehicleForm?.name)}
              />
            }
            value={vehicleForm?.name || ""}
            onChange={onChange("name")}
            className={cls.field}
            error={!!errors?.name}
            helperText={commaErrors(errors?.name)}
          />
          <TextField
            variant="standard"
            label={I18n.t("vendor_config.vehicles.form.external_id.label")}
            value={vehicleForm?.externalId || ""}
            onChange={onChange("externalId")}
            className={cls.field}
            error={!!errors?.externalId}
            helperText={commaErrors(errors?.externalId)}
          />
          <FormControl className={cls.field} error={!!errors?.trackingDeviceId}>
            <Autocomplete
              options={state.trackingDevices}
              getOptionLabel={(option) => option.name || ""}
              onChange={(_, val) => changeDevice(val?.id)}
              size="small"
              value={
                state.trackingDevices.find((td) => td.id == vehicleForm?.trackingDeviceId) || ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder={I18n.t("vendor_config.vehicles.form.tracking_device_id.label")}
                />
              )}
            />
            {errors?.trackingDeviceId && (
              <FormHelperText>{commaErrors(errors?.trackingDeviceId)}</FormHelperText>
            )}
          </FormControl>
          <TextField
            variant="standard"
            label={I18n.t("vendor_config.vehicles.form.license_plate.label")}
            value={vehicleForm?.licensePlate || ""}
            onChange={onChange("licensePlate")}
            className={cn("vehicle-licensePlate", cls.field)}
            error={!!errors?.licensePlate}
            helperText={commaErrors(errors?.licensePlate)}
          />
          <TextField
            variant="standard"
            label={I18n.t("vendor_config.vehicles.form.capacity.label")}
            value={vehicleForm?.capacity || ""}
            onChange={onChange("capacity")}
            className={cn("vehicle-capacity", cls.field)}
            type="number"
            error={!!errors?.capacity}
            helperText={commaErrors(errors?.capacity)}
          />
          <FormControl className={cls.field} error={!!errors?.vehicleType} variant="standard">
            <InputLabel>{I18n.t("vendor_config.vehicles.form.vehicle_type.label")}</InputLabel>
            <Select
              value={vehicleForm?.vehicleType || ""}
              onChange={onChange("vehicleType")}
              className="vehicle-type"
              MenuProps={{ className: "vehicle-type-menu" }}
            >
              {VEHICLE_TYPES.map((v_type) => (
                <MenuItem key={v_type} value={v_type}>
                  {startCase(v_type)}
                </MenuItem>
              ))}
            </Select>
            {errors?.vehicleType && (
              <FormHelperText>{commaErrors(errors?.vehicleType)}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default VehicleForm;
