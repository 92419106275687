import React from "react";
import { ListItem, List, Link, Typography } from "@mui/material";
import { keys, map, pull } from "lodash";
import classNames from "classnames";
import { componentsJoin } from "lib/util";
import I18n from "utils/i18n.js";
import AddressNewForm from "../address/AddressNewForm";
import AddressSchoolForm from "../address/AddressSchoolForm";

const STOP_NEW_STATES = {
  school: AddressSchoolForm,
  new: AddressNewForm
};

const StopNewForm = ({ store, cls }) => {
  const [state, actions] = store;
  const workflow = state.workflow.schoolStop ? "school" : "new";
  const otherWorkflows = pull(keys(STOP_NEW_STATES), workflow);

  const onChangeWorkflow = (e) => {
    e.preventDefault();
    actions.toggleSchoolStop();
  };

  const workflowLinks = map(otherWorkflows, (w) => (
    <ListItem
      key={`wf-${w}`}
      className={classNames(cls.listItem, cls.listItemBottomDensed)}
      disableGutters
    >
      <Link href="#" onClick={onChangeWorkflow} underline="always">
        {I18n.t(`ui.address.${w}`)}
      </Link>
    </ListItem>
  ));

  const renderSeparator = (idx) => (
    <ListItem key={`wf-del-${idx}`} className={cls.listItem}>
      <Typography color="primary">|</Typography>
    </ListItem>
  );

  const AddressComponent = STOP_NEW_STATES[workflow];

  return (
    <AddressComponent store={store} cls={cls}>
      {workflowLinks.length > 0 && (
        <List className={classNames(cls.list, cls.alignRight)} disablePadding>
          {componentsJoin(workflowLinks, renderSeparator)}
        </List>
      )}
    </AddressComponent>
  );
};

export default StopNewForm;
