import React from "react";
import { useLocalStore } from "easy-peasy";
import { Box, Grid, FormControlLabel, FormControl, FormLabel, OutlinedInput } from "@mui/material";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import RouterLink from "components/admin_v2/nav/RouterLink";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { sessionsStore } from "./sessions/stores/sessionsStore";
import { pageRoutes } from "services/api";
import I18n from "utils/i18n.js";

const NewSession = (_props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => sessionsStore());

  const onSubmit = () => {
    actions.signInUser();
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container className={cls.gridCenter}>
        <Grid item xs={6} sm={4} md={3} lg={3}>
          <Box className={cls.formSection}>
            <FormControl fullWidth>
              <FormLabel className={cls.label}>{I18n.t("sessions.form.email.label")}</FormLabel>
              <OutlinedInput
                className={cls.textField}
                value={state.email || ""}
                onChange={(e) => actions.setEmail(e.target.value)}
                name="email"
              />
            </FormControl>
          </Box>
          <Box className={cls.formSection}>
            <FormControl fullWidth>
              <FormLabel className={cls.label}>{I18n.t("sessions.form.password.label")}</FormLabel>
              <OutlinedInput
                className={cls.textField}
                value={state.password || ""}
                onChange={(e) => actions.setPassword(e.target.value)}
                type="password"
                name="password"
              />
            </FormControl>
          </Box>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.rememberMe}
                onChange={(e) => actions.setRememberMe(e.target.checked)}
              />
            }
            label={I18n.t("sessions.form.remember_me.label")}
          />
          <Box className={cls.actionsWrapper}>
            <PrimaryBtn
              onClick={onSubmit}
              disabled={state.submitDisabled}
              className={cls.signInBtn}
              type="submit"
            >
              {I18n.t("sessions.form.submit")}
            </PrimaryBtn>
          </Box>
          <Box>
            <RouterLink className={cls.sessionsLink} to={pageRoutes.newPassword()}>
              {I18n.t("sessions.links.new_password")}
            </RouterLink>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewSession;
