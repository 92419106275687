import { thunk } from "easy-peasy";
import { startOfTomorrow } from "date-fns";
import easyState from "lib/easyState";
import { formatDateUrl } from "lib/dates";
import { unenrollStudent, reenrollStudent } from "services/apiStudents";

const enrollmentStudentStore = ({ student }) => {
  return {
    ...easyState("open", false),
    ...easyState("pickerOpen", false),
    ...easyState("processing", false),
    ...easyState("date", startOfTomorrow()),
    ...easyState("note", ""),

    unenroll: thunk((actions, _payload, h) => {
      const state = h.getState();
      actions.setProcessing(true);
      const date = formatDateUrl(state.date);
      const params = { studentId: student.id, date, note: state.note };
      return unenrollStudent(params).finally(() => actions.setProcessing(false));
    }),

    reenroll: thunk((actions, _payload, _h) => {
      actions.setProcessing(true);
      const params = { studentId: student.id };
      return reenrollStudent(params).finally(() => actions.setProcessing(false));
    })
  };
};

export default enrollmentStudentStore;
