import { action, computed } from "easy-peasy";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";

// this store should be mixed in another componentStore.
// for example:
//    const myFormStore = useLocalStore((model) => ({
//      // form store fields ...
//      ...groupContactsStore(model?.groupContacts)
//    }))
//

const groupContactsStore = (initial) => ({
  ...easyState("groupContacts", initial || []),
  ...easyState("removedGroupContacts", []),

  // getGroupContacts
  groupContactsAttributes: computed((state) => {
    const { groupContacts, removedGroupContacts } = state;
    return [...groupContacts, ...removedGroupContacts];
  }),

  // validateGroupContacts
  isValidGroupContacts: computed((state) => {
    const { groupContacts } = state;

    return groupContacts.every(
      (t) => strPresent(t.contactableType) && Number.isInteger(t.contactableId)
    );
  }),

  newGroupContact: action((state, _payload) => {
    state.groupContacts = [
      ...state.groupContacts,
      {
        contactableType: null,
        contactableId: null
      }
    ];
  }),

  updateGroupContact: action((state, payload) => {
    const { index, value } = payload;
    const { id, type } = value;
    if (state.groupContacts[index]) {
      state.groupContacts[index]["contactableId"] = id;
      state.groupContacts[index]["contactableType"] = type;
    }
  }),

  removeGroupContact: action((state, index) => {
    const groupContact = state.groupContacts[index];
    if (groupContact.id) {
      // if it's a persisted groupContact we must set to _destroy
      state.removedGroupContacts.push({ ...groupContact, _destroy: true });
    }
    state.groupContacts.splice(index, 1);
  })
});

export default groupContactsStore;
