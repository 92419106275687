import React, { Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { useStoreActions, useStoreState } from "easy-peasy";
import { cancelChangeReq } from "services/apiChangeRequests";
import { formatSimpleDate } from "lib/dates";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import I18n from "utils/i18n.js";

const ChangeRequest = ({ trip, tripType }) => {
  const cls = useStyles();
  const { setModeDialogOpen, setRefresh } = useStoreActions((s) => s.calendar);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const { user } = useStoreState((s) => s.app);
  const changeReq = trip.change_request;
  const oneDayChange = changeReq.start_date === changeReq.end_date ? true : false;
  const formattedDate = formatSimpleDate(changeReq.start_date);

  // available only for staff
  const cancelReq = () => {
    cancelChangeReq(changeReq.id).then((response) => {
      setFlashMessage({ message: response.message });
      setModeDialogOpen(false);
      setRefresh(true);
    });
  };

  return (
    <Fragment>
      <FormControl component="fieldset">
        <RadioGroup row>
          <FormControlLabel
            control={<GreenRadio checked={oneDayChange} disabled={!oneDayChange} />}
            label={`${formattedDate} Only`}
          />
          <FormControlLabel
            control={<GreenRadio />}
            label={`${formattedDate}, until the route's end date`}
            checked={!oneDayChange}
            disabled={oneDayChange}
          />
        </RadioGroup>
      </FormControl>
      {changeReq.requestor_note ? (
        <Box className={cls.reqNote} mt={2}>
          {changeReq.requestor_note}
        </Box>
      ) : null}
      <Typography variant="subtitle1" className={cls.label}>
        {I18n.t(`change_requests.dialog.change_to.${tripType}`)}
      </Typography>
      <Box className={cls.reqNote}>{changeReq.stop_address}</Box>
      {!user.admin ? (
        <Button variant="contained" color="secondary" onClick={cancelReq}>
          Cancel Request
        </Button>
      ) : null}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  reqNote: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    width: "100%",
    borderRadius: theme.spacing(0.5),
    borderColor: theme.custom.MEDIUM_GREY,
    borderWidth: theme.spacing(0.1),
    borderStyle: "solid"
  },
  label: {
    fontWeight: theme.custom.BOLD
  }
}));

export default ChangeRequest;
