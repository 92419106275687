// use for sticky filter
export const getPersistedFilter = ({ app, page, filter, isIdsArray = false }) => {
  if (!app || !page || !filter) return;

  const val = localStorage.getItem(`${app}:${page}:filter:${filter}`);
  if (!val) return;

  return isIdsArray ? val.split(",").map((v) => parseInt(v)) : val;
};

// update sticky filter
export const setPersistedFilter = ({ app, page, filter, value }) => {
  if (!app || !page || !filter) return;

  localStorage.setItem(`${app}:${page}:filter:${filter}`, value);
};
