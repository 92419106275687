import { action, computed, thunk } from "easy-peasy";
import { pick, set } from "lodash";
import easyState, { easyStateDate, easyStateSetters } from "../../lib/easyState";
import {
  defaultState as changeReqDialogDefaultState,
  changeReqDialogModel
} from "components/admin_v2/queues/stores/changeReqDialogModel";

const calendarStore = {
  ...easyStateDate("date", null),
  ...easyState("student", null),
  ...easyState("trips", []),
  ...easyState("refresh", false),
  ...easyState("modeDialogOpen", false),
  ...easyState("modeDialogDate", null),
  ...easyState("changeTripType", null),
  ...easyState("changeTripEvent", null),
  ...easyState("changeTripStopId", null),
  ...easyState("changeRequestId", null),
  ...easyStateSetters(changeReqDialogDefaultState, {}),
  ...changeReqDialogModel(),

  changeParams: computed((state) =>
    pick(state, ["changeTripType", "changeTripEvent", "changeTripStopId", "changeRequestId"])
  ),

  resetChangeTripData: action((state) => {
    state.changeTripType = null;
    state.changeTripEvent = null;
    state.changeTripStopId = null;
    state.changeRequestId = null;
  }),

  setChangeTripData: action(
    (state, { tripType, eventType, stopId = null, changeRequestId = null }) => {
      state.changeTripType = tripType;
      state.changeTripEvent = eventType;
      state.changeTripStopId = stopId;
      state.changeRequestId = changeRequestId;
    }
  ),

  updateCalendar: thunk((actions, payload) => {
    actions.setStudent(payload.student);
    actions.setTrips(payload.trips);
  }),

  updateTrips: action((state, payload) => {
    const { index, trip_type, event, stopId } = payload;
    let trip = state.trips[index][trip_type];
    let stop = stopId ? trip.stops.find((s) => s.id === stopId) : null;
    trip.cancel = event === "cancel";
    trip.no_show = event === "no_show";
    trip.activity = event === "activity";
    if (stop) {
      set(stop, "events.cancel", event === "cancel");
      set(stop, "events.no_show", event === "no_show");
      set(stop, "events.activity", event === "activity");
    }
  })
};

export default calendarStore;
