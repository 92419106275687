import { action, thunk } from "easy-peasy";
import { isArrayLike, isEmpty } from "lodash";
import { easyStateSetters } from "lib/easyState";

export const defaultState = {
  // status
  refresh: true,
  loading: false,
  open: false,
  // control values
  input: null,
  value: null,
  // options
  options: []
};

const isBlank = (val) => !val || (isArrayLike(val) && isEmpty(val));

export const autocompleteStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),

  reset: action((state, { defaultValue }) => {
    state.value = defaultValue || null;
    state.input = null;
    state.options = [];
    state.open = false;
    state.refresh = !state.refresh;
  }),

  updateValue: action((state, { value }) => {
    state.value = value;
    state.input = null;
  }),

  fetchOptions: thunk((actions, params = {}) => {
    const { getOptions, searchParams, defaultSchool, debouncedInput } = params;
    if (isBlank(searchParams?.school_id) && defaultSchool === "none") return;

    actions.setLoading(true);
    return getOptions({ ...searchParams, input: debouncedInput })
      .then((response) => actions.setOptions(response))
      .finally(() => {
        actions.setLoading(false);
      });
  })
});
