import withStyles from "@mui/styles/withStyles";
import TableCell from "@mui/material/TableCell";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.custom.DARK_GREY_2,
    fontWeight: theme.custom.BOLD,
    fontSize: "0.8rem",
    paddingBottom: theme.spacing(0.5),
    whiteSpace: "pre-line"
  }
}))(TableCell);

export default StyledTableCell;
