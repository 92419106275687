import { createStore } from "easy-peasy";
import appStore from "./appStore";
import calendarStore from "./calendarStore";

const store = createStore({
  app: appStore,
  calendar: calendarStore
});

export default store;
