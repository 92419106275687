import React, { Fragment } from "react";
import cn from "classnames";
import { CalendarMonth, AccessAlarm } from "@mui/icons-material";
import { parseISO, isPast, isToday } from "date-fns";
import FormDatePicker from "components/admin_v2/ui/FormDatePicker";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { FormControl, Box, FormControlLabel, RadioGroup, Radio } from "@mui/material";

const DatePickerWithPeriod = ({
  selectedDate,
  periodValue,
  onChangeDate,
  onChangePeriod,
  dateOptionsAvailable,
  label,
  trip = null,
  disabledForStaff = false,
  disablePast = false
}) => {
  const cls = useCommonPageStyles();

  const pastDate = trip
    ? isPast(parseISO(trip.date_iso)) && !isToday(parseISO(trip.date_iso))
    : false;

  return (
    <Fragment>
      <Box display="flex">
        <FormControl
          name="schedule-start-date"
          className={cn(cls.withTopMargin, cls.halfSelect)}
          variant="standard"
        >
          <Box>
            <FormDatePicker
              label={label}
              date={selectedDate}
              onChange={onChangeDate}
              disablePast={disablePast}
              disabled={trip && pastDate ? true : false || disabledForStaff}
              labelCls={cls.label}
              required
              reverse
            />
          </Box>
        </FormControl>
        <FormControl
          name="schedule-permanent"
          className={cn(cls.secondRowMt, cls.twoThirdsSelect)}
          variant="standard"
        >
          <RadioGroup value={periodValue} onChange={onChangePeriod} row>
            {dateOptionsAvailable.map((opt) => (
              <Box key={`date-opt-${opt.label}`}>
                {opt.id === true ? (
                  <CalendarMonth fontSize="small" className={cls.calendarIcon} />
                ) : (
                  <AccessAlarm fontSize="small" className={cls.alarmIcon} />
                )}
                <FormControlLabel
                  key={`date-opt-${opt.label}`}
                  value={opt.id}
                  control={<Radio size="small" />}
                  label={opt.label}
                  disabled={disabledForStaff || !!(trip && pastDate)}
                  className={opt.id ? cls.radioNegativeMargin : cls.radioNegativeMargin2}
                  data-test-id={`period-permanent-${opt.id}`}
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Fragment>
  );
};

export default DatePickerWithPeriod;
