import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchStudentsList = async (stopId, params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`stops/${stopId}/students_list?${query}`));
};

export const fetchWeekDays = async (params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`stops/find_week_days?${query}`), { transformData: true });
};

export const updateStopSchedule = async (stopId, params) => {
  return await xhr.post(apiUrl(`stops/${stopId}/schedule`), params);
};
