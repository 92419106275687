import React from "react";
import { Box } from "@mui/material";
import { compact } from "lodash";
import { getAnchorType } from "components/admin_v2/students/stores/stopsModel";
import { parseTime, formatDisplayTime } from "lib/dates";
import I18n from "utils/i18n.js";

const ChangeRequestInfo = ({ changeReq, cls }) => {
  const anchor = changeReq.stops?.anchor;
  if (!anchor) return null;

  const anchorType = getAnchorType(changeReq.trip_type);
  const anchorTime = anchor.time ? formatDisplayTime(parseTime(anchor.time)) : null;

  return (
    <Box className={cls.reqNote} mb={-1}>
      {I18n.t(`change_requests.dialog.anchor.${anchorType}`)}
      {compact([anchor.stop_location?.address, anchorTime]).join(", ")}
    </Box>
  );
};

export default ChangeRequestInfo;
