import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  nowrap: {
    whiteSpace: "nowrap"
  },
  cell: {
    padding: theme.spacing(1, 2)
  },
  dayDisabled: {
    backgroundColor: theme.palette.background.mediumGrey,
    pointerEvents: "none"
  },
  dayOff: {
    color: theme.custom.RED
  },
  dayAdjusted: {
    color: theme.custom.YELLOW
  },
  dayIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold"
  },
  dayWithPair: {
    justifyContent: "space-between"
  },
  time: {
    backgroundColor: theme.palette.background.darkGrey
  },
  timeBox: {
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.darkGrey,
      boxShadow: "none",
      cursor: "default"
    }
  },
  emptyTime: {
    backgroundColor: theme.custom.WHITE,
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    height: theme.spacing(3.75),
    borderWidth: theme.spacing(0.1),
    borderColor: theme.palette.background.mediumGrey,
    borderStyle: "solid",
    minWidth: "80px",
    "&:hover": {
      backgroundColor: theme.custom.WHITE,
      boxShadow: "none",
      cursor: "default"
    }
  },
  stopContent: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start"
  },
  mainStopInfo: {
    minWidth: "80px"
  },
  stopStudentsInfo: {
    minWidth: "40%",
    width: "40%",
    textAlign: "left"
  },
  studentsListLink: {
    color: theme.custom.BLUE,
    marginLeft: theme.spacing(-1),
    fontWeight: theme.custom.BOLD,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  editPlaceholder: {
    width: theme.spacing(2.5)
  },
  strikedThrough: {
    textDecoration: "line-through"
  },
  withEvents: {
    backgroundColor: theme.custom.YELLOW,
    "&:hover": {
      backgroundColor: theme.custom.YELLOW,
      boxShadow: "none",
      cursor: "default"
    }
  },
  stopLink: {
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  selectedCol: {
    backgroundColor: theme.palette.background.lightBlue
  }
}));

export default useStyles;
