import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "inline-block",
    borderBottom: "none",
    padding: theme.spacing(1, 2)
  },
  idCell: {
    width: "10%",
    textDecoration: "underline"
  },
  studentCell: {
    width: "20%"
  },
  gradeCell: {
    width: "5%"
  },
  labelsCell: {
    width: "8%",
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0)
  },
  actionsCell: {
    width: "27%"
  },
  addStudentCell: {
    width: "100%"
  },
  periodCell: {
    paddingTop: 0,
    width: "100%"
  },
  studentsTable: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  studentRow: {
    backgroundColor: theme.palette.background.lightGrey,
    marginBottom: (props) => (props.eventType === "remove" ? 0 : theme.spacing(0.5)),
    display: "block"
  },
  select: {
    width: "100%",
    backgroundColor: theme.custom.WHITE,
    textAlign: "left",
    "not(&.Mui-disabled):hover": {
      backgroundColor: "rgba(0,0,0, 0.08)"
    },
    "&.Mui-disabled": {
      "-webkitTextFillColor": "inherit"
    }
  },
  selectIcon: {
    "&.Mui-disabled": {
      color: theme.custom.WHITE
    }
  },
  ok: {
    color: theme.custom.GREEN,
    fontWeight: theme.custom.SEMI_BOLD
  },
  yellowEvent: {
    color: `${theme.custom.YELLOW} !important`,
    fontWeight: theme.custom.SEMI_BOLD
  },
  redEvent: {
    color: `${theme.custom.RED} !important`,
    fontWeight: theme.custom.SEMI_BOLD
  },
  formCtrl: {
    width: theme.spacing(16.5)
  }
}));

export default useStyles;
