import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import { capitalize } from "lodash";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import I18n from "utils/i18n.js";
import { textBlastsStore } from "./stores/textBlastsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import useSorting from "lib/useSorting";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import classNames from "classnames";

const TextBlast = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => textBlastsStore(camelizeLocationSearch(props)));
  const id = props.match.params.id;
  const textBlast = state.textBlast;

  const { sortBy, sortableColumn } = useSorting({ field: "id", order: "desc" });

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    if (!id) return;

    actions.fetchTextBlast(id);
  }, [id]);

  useEffect(() => {
    actions.fetchTextBlastMessages({
      id: id,
      sortByField: sortBy.field,
      sortByOrder: sortBy.order
    });
  }, [state.page, state.perPage, state.status, textBlast, sortBy]);

  const detail = ({ label, value, addCls }) => (
    <Box className={cls.detail} key={label}>
      <div className={cls.detailHeader}>{label}</div>
      <div className={addCls}>{value || "--"}</div>
    </Box>
  );

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const details = {
    left: [
      { label: I18n.t("data_management.text_blast.labels.school"), value: textBlast?.school },
      { label: I18n.t("data_management.text_blast.labels.user"), value: textBlast?.user },
      {
        label: I18n.t("data_management.text_blast.labels.process_at"),
        value: `${textBlast?.process_at} (${textBlast?.time_zone_abbr})`
      }
    ],
    right: [
      {
        label: I18n.t("data_management.text_blast.labels.status"),
        value: capitalize(textBlast?.status),
        addCls: cls[`${textBlast?.status}Text`]
      },
      {
        label: I18n.t("data_management.text_blast.labels.created_at"),
        value: textBlast?.created_at
      }
    ]
  };

  const headers = [
    { field: "number", label: "Phone Number" },
    { field: "status", label: "Status" },
    { field: "note", label: "Note", skipSorting: true },
    { field: "body", label: "Text" }
  ].map(sortableColumn);

  const row = (message) => (
    <TableRow key={`message-${message.id}`}>
      <TableCell style={{ width: "15%" }}>{message.number}</TableCell>
      <TableCell style={{ width: "10%" }}>
        <Box className={classNames(cls[`message-${message.status}`], cls.statusBox)}>
          {capitalize(message.status)}
        </Box>
      </TableCell>
      <TableCell style={{ width: "20%" }}>{message.note}</TableCell>
      <TableCell style={{ width: "55%" }}>{message.body}</TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <BreadCrumbs
        path={pageRoutes.textBlasts()}
        label={I18n.t("data_management.text_blasts.breadcrumb")}
      />
      <Typography variant="h1" gutterBottom>
        {I18n.t("data_management.text_blast.title.main", { id: id })}
      </Typography>
      <Paper className={cls.paper}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={cls.subTitle}>{I18n.t("data_management.text_blast.title.details")}</Box>
            <Grid container className={cls.grid} spacing={5}>
              <Grid item xs={6}>
                {details.left.map(detail)}
              </Grid>
              <Grid item xs={6}>
                {details.right.map(detail)}
              </Grid>
            </Grid>
          </>
        )}

        <Box className={cls.mt}>
          <div className={cls.detailHeader}>
            {I18n.t("data_management.text_blast.labels.details")}
          </div>
          {textBlast?.details && <Typography>{textBlast.details}</Typography>}
        </Box>
      </Paper>
      <Paper className={`${cls.paper} ${cls.mt}`}>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.messages?.map(row)}
          </PaginatedTable>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default TextBlast;
