import React from "react";
import { Box, Divider, Typography } from "@mui/material";

const TopPageLayout = ({
  title,
  subtitle,
  bottomSubtitle,
  filters,
  actionButtons,
  children,
  divider = true,
  fullSizeFilter = false
}) => {
  const filterBox = fullSizeFilter ? <Box flex="1">{filters}</Box> : filters;

  return (
    <>
      <Box display="flex">
        <Box flex={fullSizeFilter ? "0" : "1"}>
          {subtitle}
          <Typography variant="h1" gutterBottom>
            {title}
          </Typography>
          {bottomSubtitle}
        </Box>
        {filterBox}
        {actionButtons ? <Box>{actionButtons}</Box> : null}
      </Box>
      {children}
      {divider ? (
        <Box my={2.5}>
          <Divider />
        </Box>
      ) : null}
    </>
  );
};

export default TopPageLayout;
