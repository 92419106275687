import React, { Fragment } from "react";
import { FormControl, FormLabel } from "@mui/material";
import ErrorList from "components/admin_v2/ui/ErrorList";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import I18n from "utils/i18n.js";
import { getAnchorTypeFor } from "../common";

const AddReturnForm = ({ store, direction }) => {
  const [state, actions] = store;
  const stopType = getAnchorTypeFor(direction);

  return (
    <Fragment>
      <ErrorList errors={state.errors} />
      <FormControl required>
        <FormLabel>{I18n.t(`ui.stop.time.${stopType}`)}</FormLabel>
        <TimePickerInput dateTime={state.time} onChange={(time) => actions.setTime(time)} />
      </FormControl>
    </Fragment>
  );
};

export default AddReturnForm;
