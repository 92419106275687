import React from "react";
import cn from "classnames";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { makeStyles } from "@mui/styles";
import { tripColor } from "components/admin_v2/trips/tripHelpers";

const WARNING_STATUSES = ["no_vendor", "no_vehicle", "no_device", "no_booking", "alert"];

const TripStatusTooltip = ({ trip }) => {
  const cls = useStyles();
  const tripStatusCls = cn(`${tripColor(trip).name}-bg`, cls.statusTooltip, cls.statusCell);
  const warningCls = trip.status_by_time.id === "alert" ? cls.red : cls.yellow;

  return (
    <Tooltip
      title={trip.status_by_time.label}
      placement="right-start"
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      className={cls.statusTooltip}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [20, -5]
            }
          }
        ]
      }}
    >
      <Box className={tripStatusCls} display="flex">
        {WARNING_STATUSES.includes(trip.status_by_time.id) ? (
          <PriorityHighIcon className={warningCls} />
        ) : (
          " "
        )}
      </Box>
    </Tooltip>
  );
};

export default TripStatusTooltip;

const useStyles = makeStyles((theme) => ({
  statusCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  statusTooltip: {
    width: theme.spacing(2.5),
    minHeight: "100%",
    float: "left",
    position: "absolute",
    borderRight: `1px solid ${theme.custom.DARK_GREY_2}`,
    borderTop: `1px solid ${theme.custom.DARK_GREY_2}`,
    top: "0",
    left: "0",
    bottom: "0"
  },
  red: {
    color: theme.custom.RED
  },
  yellow: {
    color: theme.custom.YELLOW
  }
}));
