import { computed } from "easy-peasy";
import { flatMap, sortBy } from "lodash";

// computed methods to select school addresses and school times
export const stopsSchoolModel = () => ({
  routableSchoolsAddresses: computed((state) =>
    state.routableSchools?.length > 0 ? flatMap(state.routableSchools, (s) => s.addresses) : []
  ),

  schoolAddresses: computed((state) => {
    const withMultipleSchools = state.routableSchools?.length > 1;
    return state.routableSchools?.length > 0
      ? flatMap(state.routableSchools, (s) => {
          return s.addresses.map((v) => ({
            id: v.id,
            name: v.address,
            primary: v.primary,
            school: withMultipleSchools ? s.name : null
          }));
        })
      : [];
  }),

  schoolTimes: computed((state) => ({ stopType, tripType }) => {
    const school = state.selectedSchool({ stopType, tripType });
    return school
      ? sortBy(
          (school.school_times || []).filter((v) => v.time_type === stopType),
          (v) => !v.primary
        ).map((v) => ({ id: v.id, name: v.display_name, primary: v.primary }))
      : [];
  })
});
