import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const SearchInput = ({ cls, value, onChange }) => {
  return (
    <OutlinedInput
      className={cls.searchField}
      value={value || ""}
      onChange={onChange}
      name="search"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export default SearchInput;
