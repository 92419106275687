import { hotjar } from "react-hotjar";

// hjid: Stands for 'Hotjar ID' - Your site's ID. This is the ID which tells Hotjar which site settings it should load and where it should save the data collected.
// hjsv: Stands for 'Hotjar Snippet Version' - The version of the Tracking Code you are using. This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones. Knowing which version your site includes allows hotjar team to contact you and inform you accordingly.
const init = () => {
  // Enable debug mode on the local development environment
  const hjid = window.analyticsConf?.hotjarId;
  const hjsv = window.analyticsConf?.hotjarSnippetVersion;
  if (hjid && hjsv) {
    hotjar.initialize(hjid, hjsv);
  }
};

const sendEvent = (payload) => {
  if (!window.hj) return;
  hotjar.event(payload);
};

const sendPageview = ({ path, userId }) => {
  if (!window.hj) return;
  // Identify the user
  if (userId) hotjar.identify(userId, { toms_user_id: userId });
  hotjar.stateChange(path);
};

export default {
  init,
  sendEvent,
  sendPageview
};
