import { action, thunk, computed } from "easy-peasy";
import { pick, pull } from "lodash";
import { camelizeKeys } from "humps";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";
import { createDistrict, saveDistrict } from "services/apiDistricts";
import phoneNumbersStore from "components/admin_v2/common/stores/phoneNumbersStore";
import scopeStore from "components/admin_v2/common/stores/scopeStore";

const REQUIRED = ["name", "slug", "code"];

export const districtFormStore = (district) => {
  const details = {
    ...pick(district, ["name", "slug", "code", "icabbiAccountRef", "hotlineEmail", "schoolIds"])
  };

  return {
    ...easyState("district", district),
    ...easyState("details", details),
    ...easyState("errors"),
    ...easyState("loading", false),

    ...phoneNumbersStore(district?.phoneNumbers),
    ...scopeStore(),

    isGradeChecked: computed((state) => (grade) => state.details.gradesServed?.includes(grade)),

    updateSelectedGrades: action((state, data) => {
      const { isAdd, value } = data;
      let selectedGrades = state.details.gradesServed;
      if (isAdd) {
        selectedGrades.push(value);
      } else {
        pull(selectedGrades, value);
      }

      state.details = { ...state.details, gradesServed: selectedGrades };
    }),

    isValid: computed((state) => {
      const { details } = state;
      const validDetails = REQUIRED.every((field) => strPresent(details[field]?.toString()));
      const validPhones = state.isValidPhoneNumbers({ noPhoneAllowed: true });
      return validDetails && validPhones;
    }),

    formData: computed((state) => {
      return {
        ...state.details,
        phoneNumbersAttributes: state.phoneNumbersAttributes
      };
    }),

    save: thunk(async (actions, _payload, h) => {
      const state = h.getState();
      if (!state.isValid) return;
      actions.setLoading(true);
      actions.setErrors(null);
      const data = state.formData;
      try {
        const district = state.district;
        if (district.id) {
          return await saveDistrict(district.id, data);
        } else {
          return await createDistrict(data);
        }
      } catch (err) {
        const errors = err.response?.data?.errors;
        actions.setErrors(camelizeKeys(errors));
        throw err;
      } finally {
        actions.setLoading(false);
      }
    })
  };
};
