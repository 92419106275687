import { action, thunk, computed } from "easy-peasy";
import { get, intersection, isEmpty, pull } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { fetchWeekDays } from "services/apiStops";
import { setFlashMessage } from "services";
import { weekDays, formatDateUrl } from "lib/dates";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";

export const defaultState = {
  // status
  isFirstRun: true, // we don't need to change selected days on first run for change request if any selected days
  // period dates related
  onlyDateChecked: null,
  startDate: null,
  endDate: null,
  // period days related
  selectedDays: [], // selected days, pre-selected on first render for that set of additional data/current date
  availableDays: weekDays, // days available for the stop, if no stop - all
  daysOnCurrentRoute: [], // days for student on current route, if no student - stop days, if no stop - route
  daysOnOtherRoute: [], // days for student on other routes if any (only for regular routes)
  // repeat related
  week: 1
};

const initStoreFromProps = ({ period, defaultDate, routeEndDate, notOccursOn }) => {
  const startDate = period?.startDate || defaultDate;
  let endDate = notOccursOn || period?.endDate === "" ? "" : period?.endDate || startDate;
  const onlyDateChecked = period?.onlyDateChecked === null ? null : period?.onlyDateChecked;
  if (!onlyDateChecked && routeEndDate) endDate = routeEndDate;

  return {
    startDate,
    endDate,
    onlyDateChecked: onlyDateChecked,
    selectedDays: onlyDateChecked ? [] : period?.days || []
  };
};

export const periodSelectorStore = (initialData) => ({
  ...easyStateSetters(defaultState, initStoreFromProps(initialData)),

  isDisabled: computed(
    (state) => (weekDay) => state.availableDays && !state.availableDays.includes(weekDay)
  ),
  isChecked: computed((state) => (weekDay) => state.selectedDays.includes(weekDay)),
  isOnOtherRoute: computed((state) => (weekDay) => state.daysOnOtherRoute.includes(weekDay)),

  // workflow when we have > 1 session and only one period selector
  // it's possible for custom trips at Add Trip on Student Calendar view now
  fetchAllWeekDays: thunk((actions, params = {}, h) => {
    const { student, route, changeRequest, disabled } = params;
    if (
      (isEmpty(route?.stops) && !route?.routeId && isOtherRoute(route.routeType)) ||
      isEmpty(route?.tripTypes)
    ) {
      actions.setEmptyWeekDays();
      return;
    }
    const { startDate, endDate } = h.getState();
    const { routeId, tripTypes, stops } = route;
    let withStop = false;

    const fetchWeekRequests = tripTypes.map((tripType) => {
      const stopId = get(stops, `${tripType}.id`);
      withStop = withStop || stopId;
      return fetchWeekDays({
        date: formatDateUrl(startDate),
        studentId: student?.id,
        stopId,
        routeId,
        endDate: formatDateUrl(endDate || route.endDate),
        tripType
      });
    });

    return Promise.all(fetchWeekRequests)
      .then((results) => {
        // need to take intersection/combination of all results
        let data = {
          availableDays: weekDays,
          routeDays: weekDays,
          daysOnCurrentRoute: [],
          otherRouteDays: []
        };
        results.forEach((result) => {
          data.availableDays = intersection(data.availableDays, result.availableDays);
          data.routeDays = intersection(data.routeDays, result.routeDays);
          data.daysOnCurrentRoute = data.daysOnCurrentRoute.concat(result.daysOnCurrentRoute);
          data.otherRouteDays = data.otherRouteDays.concat(result.otherRouteDays);
        });
        actions.setWeekDays({ ...data, changeRequest, disabled, stop: withStop });
      })
      .catch((err) => setFlashMessage(err.message));
  }),

  // normal workflow
  fetchWeekDays: thunk((actions, params = {}, h) => {
    const { stopToAdd, student, route, changeRequest, disabled } = params;
    if (!(stopToAdd?.id || student?.id || route?.routeId) || route?.isNewRoute) {
      actions.setEmptyWeekDays(route?.isNewRoute);
      return;
    }
    const { startDate, endDate } = h.getState();
    const { routeId, tripType } = route;

    return fetchWeekDays({
      date: formatDateUrl(startDate),
      studentId: student?.id,
      stopId: stopToAdd?.id,
      routeId,
      endDate: formatDateUrl(endDate || route.endDate),
      tripType
    })
      .then((results) => {
        actions.setWeekDays({ ...results, changeRequest, disabled, stop: stopToAdd?.id });
      })
      .catch((err) => setFlashMessage(err.message));
  }),

  chooseOneDay: action((state, { routeType, defaultDate }) => {
    if (routeType === "regular") {
      state.endDate = state.startDate;
    } else {
      state.startDate = defaultDate;
      state.endDate = defaultDate;
    }
    state.onlyDateChecked = true;
    state.selectedDays = [];
  }),

  chooseMultipleDays: action((state, { routeType, parsedRouteEndDate, withDateRange }) => {
    state.endDate = routeType === "regular" && !withDateRange ? "" : parsedRouteEndDate;
    state.onlyDateChecked = false;
  }),

  setEmptyWeekDays: action((state, isNewRoute = false) => {
    state.availableDays = weekDays;
    state.daysOnCurrentRoute = [];
    state.daysOnOtherRoute = [];
    if (isNewRoute) state.selectedDays = weekDays;
  }),

  setWeekDays: action((state, data) => {
    state.availableDays = data.availableDays;
    state.daysOnCurrentRoute = data.currentRouteDays;
    state.daysOnOtherRoute = data.otherRouteDays;
    if (data.disabled || (state.isFirstRun && data.changeRequest && !isEmpty(state.selectedDays))) {
      state.isFirstRun = false;
      return;
    }
    state.isFirstRun = false;
    if (data.changeRequest && !isEmpty(data.currentRouteDays)) {
      state.selectedDays = intersection(data.availableDays, data.currentRouteDays);
    } else {
      state.selectedDays = data.stop ? data.availableDays : data.routeDays;
    }
  }),

  updateEndDate: action((state, date) => {
    state.endDate = date ? date : "";
  }),

  updateSelectedDays: action((state, data) => {
    const { isAdd, value } = data;
    isAdd ? state.selectedDays.push(value) : pull(state.selectedDays, value);
  })
});
