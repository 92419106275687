import { action, thunk, computed } from "easy-peasy";
import { easyStateSetters } from "lib/easyState";
import { assign, get, map, omit, partition } from "lodash";
import { formatISO, addDays } from "date-fns";
import { camelize, camelizeKeys } from "humps";
import I18n from "utils/i18n.js";
import { printHTML } from "lib/util";
import {
  generateReport,
  previewReport,
  reportsUnseen,
  reportDownloaded
} from "services/apiReports";
import { fetchRouteOptions as fetchVendorRouteOptions } from "services/apiVendors";
import { fetchRouteOptions as fetchSchoolRouteOptions } from "services/apiSchools";
import { setFlashMessage } from "services";
import store from "context/admin_v2/store";
import { ROUTE_SHEETS_ADD_DAYS } from "utils/constants";

const NO_SCHOOLS_REPORTS = ["route_sheets"];

const CURBSIDE_FORM = {
  grade: null,
  tripType: "",
  transportationTypes: [],
  transportationVendorIds: [],
  reportFormat: "pdf",
  includeTime: false,
  orderByDismissalGroup: false,
  splitPages: false
};

const DISTRICT_CURBSIDE_FORM = {
  grade: null,
  tripType: "",
  transportationTypes: [],
  transportationVendorIds: [],
  reportFormat: "pdf",
  includeTime: false,
  orderByDismissalGroup: false,
  splitPages: false
};

const ADM_FORM = {
  transportationTypes: [],
  transportationVendorIds: [],
  programId: null
};

const AVERY_FORM = {
  tripType: "",
  transportationTypes: [],
  grades: [],
  dismissalGroupIds: [],
  routeIds: []
};

const ALL_TRIPS_FORM = {
  ridersType: ""
};

const ROUTE_SHEETS_FORM = {
  routesFor: null,
  transportationVendorIds: [],
  routeIds: [],
  routesWithChanges: false
};

export const defaultState = {
  // common for all reports data
  reportType: null,
  date: null,
  dateTo: null,
  datePickerOpen: false,
  dateToPickerOpen: false,
  districtId: null,
  schoolIds: [],
  routes: [],
  // forms related data
  curbsideForm: CURBSIDE_FORM,
  districtCurbsideForm: DISTRICT_CURBSIDE_FORM,
  admForm: ADM_FORM,
  averyForm: AVERY_FORM,
  allTripsForm: ALL_TRIPS_FORM,
  routeSheetsForm: ROUTE_SHEETS_FORM,
  reportErrors: null,
  // generation related data
  unseen: 0,
  generating: false,
  generatingCount: 0,
  generated: [],
  // preview dialog
  dialog: { open: false, content: null },
  // status
  loading: false,
  routesLoading: false
};

export const DISTRICT_REPORTS = ["district_curbside"];

export const reportStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),

  submitDisabled: computed((state) => {
    if (state.reportType !== "route_sheets") {
      return false;
    } else {
      const form = state.routeSheetsForm;

      return (
        (form.routeIds.length < 1 && !form.routesWithChanges) ||
        (form.transportationVendorIds.length < 1 && state.schoolIds.length < 1) ||
        !state.date ||
        !state.dateTo
      );
    }
  }),

  removeGenerated: action((state, index) => {
    state.generated.splice(index, 1);
  }),

  loadUnseen: thunk((actions, _payload, h) => {
    const { schoolIds } = h.getState();
    reportsUnseen({ schoolIds })
      .then((resp) => {
        actions.setUnseen(resp.count || 0);
      })
      .catch((err) => {
        actions.setUnseen(0);
        setFlashMessage(err.message);
      });
  }),

  reset: action((state) => {
    assign(
      state,
      omit(defaultState, [
        "date",
        "dateTo",
        "schoolIds",
        "districtId",
        "unseen",
        "generating",
        "generatingCount",
        "generated"
      ])
    );
  }),

  createReport: thunk((actions, _payload, h) => {
    const state = h.getState();
    if (!state.date) {
      setFlashMessage("Date is required.");
      return;
    }

    actions.setLoading(true);
    const date = formatISO(state.date, { representation: "date" });
    const dateTo = state.dateTo ? formatISO(state.dateTo, { representation: "date" }) : null;
    const districtSchoolsParams = DISTRICT_REPORTS.includes(state.reportType)
      ? { districtId: state.districtId }
      : { schoolIds: state.schoolIds };

    let params = {
      date: date,
      dateTo: dateTo,
      ...districtSchoolsParams,
      ...get(state, `${camelize(state.reportType)}Form`, {})
    };

    if (!NO_SCHOOLS_REPORTS.includes(state.reportType)) {
      params.schoolIds = state.schoolIds;
    }

    generateReport({ report_type: state.reportType, report: params })
      .then((resp) => {
        const [errors, jids] = partition(resp, (r) => r.error);
        store.getActions().app.addReportJIDs(jids);
        if (jids.length) {
          actions.reset();
          actions.updateGeneratingCount(jids.length);
        }
        if (errors.length) {
          setFlashMessage(map(errors, "error").join("\n"));
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
        actions.setError(err);
      })
      .finally(() => actions.setLoading(false));
  }),

  reportDownloaded: thunk((actions, report) => {
    reportDownloaded(report)
      .then(() => actions.loadUnseen())
      .catch((err) => setFlashMessage(err.message));
  }),

  reportReceived: thunk((actions, response) => {
    if (!store.getState().app.jidValid(response.jid, response.report?.creator_id)) return;

    store.getActions().app.removeReportJID(response.jid);
    if (response.status === "failed") {
      setFlashMessage(`Report generation failed: ${response.error}`);
    }
    actions.updateGenerated(camelizeKeys(response));
  }),

  updateGenerated: action((state, response) => {
    if (response.status !== "failed") {
      state.generating = --state.generatingCount > 0;
      if (response.report.reportType !== "changelogs") state.unseen++;
      state.generated = [...state.generated, response.report];
    } else {
      state.generating = false;
      state.generatingCount = 0;
    }
  }),

  updateGeneratingCount: action((state, count) => {
    state.generatingCount += count;
    state.generating = true;
  }),

  updateReportType: action((state, reportType) => {
    state.reportErrors = null;
    state.reportType = reportType;
    // reset dateTo if changing to route sheets
    if (reportType === "route_sheets") {
      state.dateTo = addDays(state.date, ROUTE_SHEETS_ADD_DAYS);
    }
  }),

  updateDialog: action((state, payload) => {
    state.dialog = { ...state.dialog, ...payload };
  }),

  updateRouteOptions: thunk((actions, _payload, h) => {
    const { routeSheetsForm, date, dateTo, schoolIds } = h.getState();
    const { transportationVendorIds } = routeSheetsForm;
    actions.setRouteSheetsForm({ routeIds: [], ...routeSheetsForm });

    if (transportationVendorIds.length < 1 && !schoolIds[0]) {
      actions.setRoutes([]);
      return;
    }

    const params = {
      date: formatISO(date, { representation: "date" }),
      dateTo: formatISO(dateTo, { representation: "date" }),
      with_custom: true
    };
    actions.setRoutesLoading(true);

    const fetchFn = schoolIds[0] ? fetchSchoolRouteOptions : fetchVendorRouteOptions;

    fetchFn(schoolIds[0] || transportationVendorIds[0], params)
      .then((resp) => {
        actions.setRoutes(resp.routes);
      })
      .catch((err) => {
        actions.setRoutes([]);
        actions.setError(err);
      })
      .finally(() => actions.setRoutesLoading(false));
  }),

  fetchAveryLabels: thunk((actions, payload, h) => {
    const { date, schoolIds, averyForm, reportType } = h.getState();
    if (!date) {
      setFlashMessage("Date is required.");
      return;
    }

    const params = {
      date: formatISO(date, { representation: "date" }),
      schoolIds,
      ...averyForm
    };
    actions.setReportErrors(null);
    previewReport({ report_type: reportType, report: params })
      .then((resp) => {
        payload?.success?.(resp);
      })
      .catch((err) => {
        actions.setError(err);
        payload?.error?.(err);
      });
  }),

  previewAveryLabels: thunk((actions, _payload, _h) => {
    actions.updateDialog({ open: true, content: null });
    actions.fetchAveryLabels({
      success: (resp) => {
        actions.updateDialog({ content: resp });
      },
      error: (_err) => {
        actions.updateDialog({ open: false, content: null });
      }
    });
  }),

  printAveryLabels: thunk((actions, remote = true, h) => {
    const elem = document.getElementById("avery-print");
    if (remote) {
      // Browsers only allow a window to be opened from a user (e.g. click) event, when
      // trying to call it from the xhr callback we are blocked. As a workaroung we
      // create the window beforehand (on the click event) and then add the content when
      // the request returns
      let printable = window.open("", "PRINT");
      actions.fetchAveryLabels({
        success: (resp) => {
          elem.innerHTML = resp.pages?.join("\n");
          printHTML(elem, printable);
          actions.updateDialog({ content: resp });
        }
      });
    } else {
      const content = h.getState().dialog.content;
      elem.innerHTML = content?.pages?.join("\n");
      printHTML(elem);
    }
  }),

  closeDialog: thunk((actions, _payload) => {
    actions.updateDialog({ open: false });
  }),

  setError: thunk((actions, err) => {
    const errors = err.response?.data?.errors;
    if (errors) {
      actions.setReportErrors(errors);
    } else {
      setFlashMessage(I18n.t("ui.errors.report"));
    }
    console.error("report err:", errors, err);
  })
});
