import { thunk } from "easy-peasy";
import { fetchVendorsList } from "services/apiVendors";
import easyState from "lib/easyState";

const vendorStore = {
  ...easyState("vendorId", null),
  ...easyState("vendors", []),

  changeVendor: thunk(async (actions, id, h) => {
    const state = h.getState();
    if (state.vendorId === id) return;

    actions.setVendorId(id);
  }),

  updateVendors: thunk(async (actions, _payload, _h) => {
    const resp = await fetchVendorsList();
    actions.setVendors(resp.vendors);
  })
};

export default vendorStore;
