import React from "react";
import { Navigate } from "react-router-dom-v5-compat";
import { compact } from "lodash";
// import { Paper } from "@mui/material";
// import { makeStyles } from "@mui/material/styles";
// import TopPageLayout from "components/admin_v2/nav/TopPageLayout";

const Dashboard = (props) => {
  // const classes = useStyles();

  // return (
  //   <React.Fragment>
  //     <TopPageLayout title="Dashboard" />
  //     <Paper className={classes.root}>Not Implemented</Paper>
  //   </React.Fragment>
  // );

  const fullPath = compact([
    props.match.params?.district_slug,
    props.match.params?.school_slug,
    "curbside"
  ]).join("/");
  return <Navigate replace to={`/admin/${fullPath}`} />;
};

// const useStyles = makeStyles((_theme) => {});

export default Dashboard;
