import React, { Fragment } from "react";
import { Dialog, Typography } from "@mui/material";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import { useStoreActions } from "easy-peasy";
import VehicleForm from "./VehicleForm";
import I18n from "utils/i18n.js";

const AddVehicleDialog = ({ cls, open, store, toggleDialog }) => {
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [state, actions] = store;
  const defaultForm = { transportationVendorId: state.vendorId };

  const closeDialog = () => {
    toggleDialog();
    actions.setErrors({});
    actions.setVehicleForm(defaultForm);
  };

  const onSubmit = () => {
    actions.save().then((r) => {
      setFlashMessage({ message: r.message });
      actions.addVehicle(r.vehicle);
      toggleDialog();
      actions.setVehicleForm(defaultForm);
    });
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <Fragment>
        <FormDialogTitle onClose={closeDialog}>
          <Typography variant="h1">{I18n.t("vendor_config.new_vehicle.title")}</Typography>
        </FormDialogTitle>
        <FormDialogContent className={cls.dialogCont}>
          <VehicleForm store={store} />
        </FormDialogContent>
        <FormDialogActions
          onSubmit={onSubmit}
          onClose={closeDialog}
          disabled={!state.isValid}
          submitLabel={I18n.t("vendor_config.vehicles.form.submit")}
        />
      </Fragment>
    </Dialog>
  );
};

export default AddVehicleDialog;
