import React, { Fragment } from "react";
import { Typography } from "@mui/material";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import ArchivedBadge from "../ArchivedBadge";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";

export const RouteTitle = ({ route }) => {
  if (!route) return null;

  return (
    <Fragment>
      {route.name}
      {route.with_schedule && route.not_occurs_on && (
        <Typography variant="inherit" color="error" display="inline" component="sup">
          **
        </Typography>
      )}
      {route.discarded && <ArchivedBadge size="small" />}
    </Fragment>
  );
};

export const RouteSubtitle = () => (
  <BreadCrumbs path={pageRoutes.routes()} label={I18n.t("data_management.routes.breadcrumb")} />
);
