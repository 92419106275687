import { easyStateSetters } from "lib/easyState";

export const defaultState = {
  // status
  alertOpen: false,
  // data
  alert: {}
};

// this store should be mixed in another componentStore.
// for example:
//    const myFormStore = useLocalStore((model) => ({
//      // form store fields ...
//      ...alertStore({ alertOpen })
//    }))
//
const alertStore = (initialData = {}) => ({
  ...easyStateSetters(defaultState, initialData)
});

export default alertStore;
