import xhr from "../../../lib/xhr";
import mapboxgl from "mapbox-gl";
import { chunk } from "lodash";
import { filter, last, assign, flatMapDepth } from "lodash";
import { setFlashMessage } from "services";
import theme from "../theme";
import { tripColor, etaDiffColor } from "./tripHelpers";
import I18n from "utils/i18n.js";

mapboxgl.accessToken = ENV.MAPBOX_KEY;
const apiUrl = (path) => `https://api.mapbox.com/${path}`;

const ICONS = {
  cab: "icon-cab-",
  bus: "icon-school-bus-",
  van: "icon-van-",
  _default: "icon-car-"
};

const ROUTE_DATA_OBJECT = {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: []
  }
};

// Mapbox limits on GPS positions number:
// matching API: 100
// directions API: 25
// optimized-trips API: 12
// we take chunk sizes as (limit - 1) as we'll have to prepend last coords set of previous chunk to the next chunk
const MATCH_API_CHUNK_SIZE = 99;
const DIRECTIONS_API_CHUNK_SIZE = 24;
const iconForTrip = (v_type, route_riders) => (ICONS[v_type] || ICONS._default) + route_riders;

export const changeCursorToPointer = (map) => {
  map.getCanvas().style.cursor = "pointer";
};
export const changeCursorToDefault = (map) => {
  map.getCanvas().style.cursor = "";
};
export const flyTo = (map, coords) => {
  map.flyTo({
    around: coords,
    center: coords,
    zoom: 18
  });
};

// NOTE: Leaving in case it might be useful in the future. Currently not in use
// call mapbox directions API to project route
export const routeDirections = async (coords) => {
  if (coords.length < 2) return coords;

  const coordsChunks = chunk(coords, DIRECTIONS_API_CHUNK_SIZE);
  return Promise.all(
    coordsChunks.map((coords, idx) => requestTripDirections(coordsChunks, coords, idx))
  )
    .then((results) => {
      return flatMapDepth(
        results,
        (r) => r.routes?.map((route) => route.geometry.coordinates) || [],
        2
      );
    })
    .catch((err) => {
      setFlashMessage(`${err}`);
    });
};

// call mapbox match API to align past route to the roads
export const matchRoute = async (coords) => {
  if (coords.length < 2) return coords;

  const coordsChunks = chunk(coords, MATCH_API_CHUNK_SIZE);
  return Promise.all(coordsChunks.map((coords, idx) => requestMatching(coordsChunks, coords, idx)))
    .then((results) => {
      return flatMapDepth(
        results,
        (r) => r.matchings?.map((match) => match.geometry.coordinates) || [],
        2
      );
    })
    .catch((err) => setFlashMessage(`${err}`));
};

export const requestTripDirections = async (coordsChunks, coords, idx) => {
  if (idx > 0) {
    coords.unshift(last(coordsChunks[idx - 1]));
  }

  const path =
    "directions/v5/mapbox/driving/" +
    coords.join(";") +
    "?overview=full&geometries=geojson&access_token=" +
    mapboxgl.accessToken;

  return await xhr.get(apiUrl(path));
};

const requestMatching = async (coordsChunks, coords, idx) => {
  if (idx > 0) {
    coords.unshift(last(coordsChunks[idx - 1]));
  }

  const path = `matching/v5/mapbox/driving/${coords.join(";")}?geometries=geojson&access_token=${
    mapboxgl.accessToken
  }`;

  return await xhr.get(apiUrl(path));
};

export const addSchools = (map) => {
  map.addSource("schools", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: []
    }
  });

  map.addLayer({
    id: "schools-blue-circle",
    source: "schools",
    type: "circle",
    paint: {
      "circle-radius": 32,
      "circle-color": theme.custom.BLUE,
      "circle-opacity": 0.4
    }
  });

  map.addLayer({
    id: "schools-white-circle",
    source: "schools",
    type: "circle",
    paint: {
      "circle-radius": 16,
      "circle-color": theme.custom.BLUE,
      "circle-stroke-color": theme.custom.WHITE,
      "circle-stroke-width": 2.4
    }
  });

  map.addLayer({
    id: "schools-icons",
    source: "schools",
    type: "symbol",
    layout: {
      "icon-image": "building-15",
      "icon-size": 1.3
    },
    paint: {
      "text-color": theme.custom.BLUE
    }
  });
};

export const addSchoolsData = (map, schoolsData) => {
  const schoolFeatures = schoolsData.map((sd) => ({
    geometry: {
      type: "Point",
      coordinates: [sd.lng, sd.lat]
    },
    properties: {
      label: sd.name,
      address: sd.address
    }
  }));

  const data = {
    type: "FeatureCollection",
    features: schoolFeatures
  };
  map.getSource("schools")?.setData(data);
};

export const addStopsData = (map) => {
  map.addSource("stops", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: []
    }
  });

  map.addSource("schoolStops", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: []
    }
  });

  map.addSource("missedStops", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: []
    }
  });

  map.addLayer({
    id: "stops",
    source: "stops",
    type: "circle",
    paint: {
      "circle-radius": 10,
      "circle-color": ["get", "bgColor"],
      "circle-stroke-color": ["get", "border"],
      "circle-stroke-width": 2
    }
  });

  map.addLayer({
    id: "stops-numbers",
    type: "symbol",
    source: "stops",
    layout: {
      "text-field": ["get", "number"],
      "text-font": ["Montserrat ExtraBold"],
      "text-size": 15,
      "text-offset": [0, 0.1],
      "text-allow-overlap": true
    },
    paint: {
      "text-color": ["get", "textColor"]
    }
  });

  map.addLayer({
    id: "school-stops-circle",
    source: "schoolStops",
    type: "circle",
    paint: {
      "circle-radius": 32,
      "circle-color": theme.custom.LIGHT_BLUE_2,
      "circle-opacity": 0.4
    }
  });

  map.addLayer({
    id: "school-stops-white-circle",
    source: "schoolStops",
    type: "circle",
    paint: {
      "circle-radius": 16,
      "circle-color": theme.custom.LIGHT_BLUE_2,
      "circle-stroke-color": theme.custom.WHITE,
      "circle-stroke-width": 2.4
    }
  });

  map.addLayer({
    id: "school-stops-icons",
    source: "schoolStops",
    type: "symbol",
    layout: {
      "icon-image": "building-15",
      "icon-size": 1.3
    },
    paint: {
      "text-color": theme.custom.LIGHT_BLUE_2
    }
  });

  map.addLayer({
    id: "missed-stops-circle",
    source: "missedStops",
    type: "circle",
    paint: {
      "circle-radius": 6,
      "circle-color": theme.custom.DARK_GREY_2,
      "circle-stroke-color": theme.custom.WHITE,
      "circle-stroke-width": 1,
      "circle-translate": [-10, -10]
    }
  });
  map.addLayer({
    id: "missed-stops-exclamation",
    type: "symbol",
    source: "missedStops",
    layout: {
      "text-font": ["Montserrat ExtraBold"],
      "text-size": 10,
      "text-field": "!",
      "text-offset": [-1.0, -0.95],
      "text-allow-overlap": true
    },
    paint: {
      "text-color": theme.custom.WHITE
    }
  });
};

export const addTripsData = (map, trips) => {
  trips.forEach((t) => {
    let coords = [];
    if (t.last_position) {
      coords = [t.last_position.lng, t.last_position.lat];
    }

    map.addSource(`current-pos-${t.id}`, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            geometry: {
              type: "Point",
              coordinates: coords
            },
            properties: {
              tripId: t.id
            }
          }
        ]
      }
    });

    map.addLayer({
      id: `current-pos-${t.id}`,
      source: `current-pos-${t.id}`,
      type: "circle",
      paint: {
        "circle-radius": 10,
        "circle-color": tripColor(t).color,
        "circle-stroke-color": theme.custom.WHITE,
        "circle-stroke-width": 3
      }
    });

    map.addLayer({
      id: `current-pos-op-${t.id}`,
      source: `current-pos-${t.id}`,
      type: "circle",
      layout: {
        visibility: "none"
      },
      paint: {
        "circle-radius": 32,
        "circle-color": tripColor(t).color,
        "circle-opacity": 0.4
      }
    });

    map.addLayer({
      id: `vehicle-icon-${t.id}`,
      source: `current-pos-${t.id}`,
      type: "symbol",
      layout: {
        visibility: "none",
        "icon-image": iconForTrip(t.vehicle_type, t.route_riders),
        "icon-size": 0.23
      }
    });
  });
};

export const addRoutes = (map, trips) => {
  trips.forEach((t) => {
    map.addSource(`proj-route-${t.id}`, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    });

    map.addLayer(
      {
        id: `proj-route-${t.id}`,
        source: `proj-route-${t.id}`,
        type: "line",
        layout: {
          visibility: "none",
          "line-join": "round",
          "line-cap": "square"
        },
        paint: {
          "line-color": theme.custom.WHITE,
          "line-width": 2,
          "line-dasharray": [1.5, 1.3]
        }
      },
      "waterway-label"
    );
  });
};

export const addActualRoute = (map) => {
  map.addSource("act-route", {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: []
      }
    }
  });

  map.addLayer(
    {
      id: "act-route",
      source: "act-route",
      type: "line",
      layout: {
        "line-join": "round",
        "line-cap": "square"
      },
      paint: {
        "line-color": theme.custom.WHITE,
        "line-width": 2
      }
    },
    "waterway-label"
  );
};

export const addRouteWaypoints = (map) => {
  map.addSource("act-route-waypoints", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: []
    }
  });

  map.addLayer({
    id: "act-route-waypoints",
    source: "act-route-waypoints",
    type: "symbol",
    layout: {
      "icon-image": "avl-waypoint-i",
      "icon-size": 1.3,
      "icon-offset": [0, -5]
    }
  });
};

export const addRouteData = (map, trip, coords) => {
  const data = assign({}, ROUTE_DATA_OBJECT, {
    geometry: { type: "LineString", coordinates: coords }
  });
  map.getSource(`proj-route-${trip.id}`)?.setData(data);
  map.setLayoutProperty(`proj-route-${trip.id}`, "visibility", "visible");
};

export const addActualRouteData = (map, coords, trip) => {
  const data = assign({}, ROUTE_DATA_OBJECT, {
    geometry: { type: "LineString", coordinates: coords }
  });

  // show a blue line for finished trips, and a white line for ongoing
  const lineColor = trip?.ended_at ? theme.custom.BLUE : theme.custom.WHITE;
  map.setPaintProperty("act-route", "line-color", lineColor);

  map.getSource("act-route")?.setData(data);
};

export const addRouteWaypointsData = (map, positions) => {
  const data = {
    type: "FeatureCollection",
    features: positions.map((position) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [position.lng, position.lat]
      },
      properties: {
        time: position.time
      }
    }))
  };

  map.getSource("act-route-waypoints")?.setData(data);
};

export const showActiveTrip = (map, trip) => {
  map.setPaintProperty(`current-pos-${trip.id}`, "circle-opacity", 1);
  map.setPaintProperty(`current-pos-${trip.id}`, "circle-stroke-opacity", 1);
  map.setPaintProperty(`current-pos-${trip.id}`, "circle-radius", 16);
  map.setPaintProperty(`current-pos-${trip.id}`, "circle-color", tripColor(trip).color);
  map.setPaintProperty(`current-pos-op-${trip.id}`, "circle-color", tripColor(trip).color);
  map.setLayoutProperty(`current-pos-op-${trip.id}`, "visibility", "visible");
  map.setLayoutProperty(`vehicle-icon-${trip.id}`, "visibility", "visible");
  map.setPaintProperty(`proj-route-${trip.id}`, "line-color", theme.custom.WHITE);
  map.moveLayer(`current-pos-op-${trip.id}`, `current-pos-${trip.id}`);
};

export const hideInactiveTrips = (map, trips) => {
  trips.forEach((trip) => {
    map.setPaintProperty(`current-pos-${trip.id}`, "circle-opacity", 0.4);
    map.setPaintProperty(`current-pos-${trip.id}`, "circle-stroke-opacity", 0.4);
    map.setPaintProperty(`current-pos-${trip.id}`, "circle-radius", 10);
    map.setLayoutProperty(`current-pos-op-${trip.id}`, "visibility", "none");
    map.setLayoutProperty(`vehicle-icon-${trip.id}`, "visibility", "none");
    map.setLayoutProperty(`proj-route-${trip.id}`, "visibility", "none");
  });
};

export const removeOpacity = (map, trips) => {
  trips.forEach((trip) => {
    map.setPaintProperty(`current-pos-${trip.id}`, "circle-opacity", 1);
    map.setPaintProperty(`current-pos-${trip.id}`, "circle-stroke-opacity", 1);
  });
};

export const updateTripPosition = (map, trip, coords) => {
  const data = {
    type: "FeatureCollection",
    features: [
      {
        geometry: {
          type: "Point",
          coordinates: coords
        },
        properties: {
          tripId: trip.id
        }
      }
    ]
  };

  map.getSource(`current-pos-${trip.id}`)?.setData(data);
  map.setPaintProperty(`current-pos-${trip.id}`, "circle-color", tripColor(trip).color);
  map.setPaintProperty(`current-pos-op-${trip.id}`, "circle-color", tripColor(trip).color);
};

export const hideProjectedRoutes = (map, trips) => {
  trips.forEach((trip) => {
    map.setLayoutProperty(`proj-route-${trip.id}`, "visibility", "none");
  });
};

export const removeSelectedTripData = (map) => {
  const stopsData = { type: "FeatureCollection", features: [] };
  const schoolStopsData = { type: "FeatureCollection", features: [] };
  const missedStopsData = { type: "FeatureCollection", features: [] };
  map.getSource("schoolStops")?.setData(schoolStopsData);
  map.getSource("missedStops")?.setData(missedStopsData);
  map.getSource("stops")?.setData(stopsData);
};

export const updateStopsData = (map, tripInfo) => {
  const stops = filter(tripInfo.stops, (s) => !s.is_school);
  const schoolStops = filter(tripInfo.stops, (s) => s.is_school && !s.is_anchor);
  const missedStops = filter(tripInfo.stops, (s) => s.is_missed);
  const anchorStop = tripInfo.stops.find((s) => s.is_anchor);

  const stopsData = {
    type: "FeatureCollection",
    features: stops.map((s) => {
      const stopInfo = tripInfo.trip.stops_info[s.id];

      let bgColor = theme.custom.WHITE;
      let textColor = theme.custom.DARK_GREY_2;
      let border = theme.custom.DARK_GREY_2;

      if (s.is_missed) {
        bgColor = border = theme.custom.DARK_GREY_2;
        textColor = theme.custom.WHITE;
      } else if (stopInfo?.completed) {
        bgColor = border = etaDiffColor(stopInfo?.completed_diff);
        textColor = theme.custom.WHITE;
      }

      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [s.lng, s.lat]
        },
        properties: {
          id: s.id,
          label: I18n.t("ui.avl.labels.stop"),
          name: s.name,
          address: s.address,
          number: s.number,
          bgColor: bgColor,
          textColor: textColor,
          border: border
        }
      };
    })
  };
  const schoolStopsData = {
    type: "FeatureCollection",
    features: schoolStops.map((s) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [s.lng, s.lat]
      },
      properties: {
        label: s.school,
        address: s.address
      }
    }))
  };
  const missedStopsData = {
    type: "FeatureCollection",
    features: missedStops.map((s) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [s.lng, s.lat]
      }
    }))
  };

  map.getSource(`stops`)?.setData(stopsData);
  map.getSource(`schoolStops`)?.setData(schoolStopsData);
  map.getSource(`missedStops`)?.setData(missedStopsData);
  // need to change schools layer in case selected trip has school different from selected
  if (anchorStop) {
    addSchoolsData(map, [
      {
        name: anchorStop.school,
        address: anchorStop.address,
        lng: anchorStop.lng,
        lat: anchorStop.lat
      }
    ]);
  }
};
