import React, { Fragment, useState, useCallback, useMemo } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import { strPresent, validE164Format } from "lib/util";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import ErrorList from "components/admin_v2/ui/ErrorList";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import PhoneInput from "./PhoneInput";
import { useTableFormStyles } from "./useTableFormStyles";
import { LANGUAGES, LINE_TYPES } from "utils/constants";

// For reusing this component, we expect to receive a form store that
// mixin the `./stores/phoneNumbersStore`
//
const PhoneNumbersForm = ({
  store,
  phoneRequired = true,
  maxSize,
  disabled,
  withTwilio = false
}) => {
  const cls = useTableFormStyles();
  const [state, actions] = store;
  const { phoneNumbers, errors } = state;
  const [fieldErrs, setFieldErrs] = useState([]);

  const remove = useCallback((e) => {
    if (window.confirm("Are you sure?")) {
      const index = e.currentTarget.dataset.index;
      actions.removePhoneNumber(index);
      fieldErrs[index] = undefined;
      setFieldErrs(fieldErrs);
    }
  }, []);

  const data = useMemo(
    () =>
      (phoneNumbers || []).map((phone, index) =>
        phone._destroy ? null : (
          <TableRow hover tabIndex={-1} key={`phone-${index}-row`}>
            <TableCell className={[cls.cell, cls.smCell].join(" ")} key={`phone-${index}-primary`}>
              <GreenCheckbox
                checked={phone.primary}
                onChange={(_e) => actions.updatePrimaryPhone(index)}
                className={cls.check}
                disabled={disabled}
              />
            </TableCell>
            <TableCell className={cls.cell} key={`phone-${index}-number`}>
              <TextField
                variant="standard"
                id={`phone-${index}-number-input`}
                label={<RequiredLabel label="Number" condition={strPresent(phone.number)} />}
                InputProps={{
                  inputComponent: PhoneInput
                }}
                value={phone.number || ""}
                onChange={(e) => updateField(index, "number", e)}
                className={cls.field}
                error={!!fieldErrs[index]?.number}
                helperText={fieldErrs[index]?.number}
                disabled={disabled}
              />
            </TableCell>
            <TableCell className={cls.cell} key={`phone-${index}-name`}>
              <TextField
                variant="standard"
                id={`phone-${index}-name-input`}
                label={<RequiredLabel label="Name" condition={strPresent(phone.name)} />}
                value={phone.name || ""}
                onChange={(e) => updateField(index, "name", e)}
                className={cls.field}
                disabled={disabled}
              />
            </TableCell>
            <TableCell
              className={[cls.cell, cls.cellType].join(" ")}
              key={`phone-${index}-lineType`}
            >
              <FormControl className={cls.field} variant="standard">
                <InputLabel id={`phone-${index}-lineType-label`}>Type</InputLabel>
                <Select
                  labelId={`phone-${index}-lineType-label`}
                  id={`phone-${index}-lineType`}
                  value={phone.lineType || "mobile"}
                  onChange={(e) => updateField(index, "lineType", e)}
                  disabled={disabled}
                >
                  {LINE_TYPES.map((lineType) => (
                    <MenuItem key={`line-type-${index}-${lineType}`} value={lineType}>
                      {lineType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            {!withTwilio && (
              <TableCell className={[cls.cell, cls.cellOpt].join(" ")} key={`phone-${index}-opt`}>
                <FormControl className={cls.field} variant="standard">
                  <InputLabel id={`phone-${index}-opt-label`}>Opt Out</InputLabel>
                  <Select
                    labelId={`phone-${index}-opt-label`}
                    id={`phone-${index}-opt`}
                    value={phone.optOutCommunication ? "YES" : "NO"}
                    onChange={(e) => updateField(index, "optOutCommunication", e)}
                    disabled={disabled}
                  >
                    {["YES", "NO"].map((opt) => (
                      <MenuItem key={`opt-${index}-${opt}`} value={opt}>
                        {opt}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            )}
            <TableCell className={[cls.cell, cls.cellType].join(" ")} key={`phone-${index}-lang`}>
              <FormControl className={cls.field} variant="standard">
                <InputLabel id={`phone-${index}-lang-label`}>Language</InputLabel>
                <Select
                  labelId={`phone-${index}-lang-label`}
                  id={`phone-${index}-lang`}
                  value={phone.language || "english"}
                  onChange={(e) => updateField(index, "language", e)}
                  disabled={disabled}
                >
                  {LANGUAGES.map((lang) => (
                    <MenuItem key={`lang-${index}-${lang}`} value={lang}>
                      {lang}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            {withTwilio && (
              <TableCell className={[cls.cell, cls.smCell].join(" ")} key={`phone-${index}-twilio`}>
                <GreenCheckbox
                  id={`phone-${index}-twilio`}
                  checked={phone.twilio}
                  onChange={(e) => updateField(index, "twilio", e)}
                  className={cls.check}
                  disabled={disabled}
                />
              </TableCell>
            )}
            <TableCell className={[cls.cell, cls.iconCell].join(" ")} key={`phone-${index}-del`}>
              {!disabled && (
                <Link
                  color="secondary"
                  onClick={remove}
                  className={cls.remove}
                  data-index={index}
                  underline="hover"
                >
                  <DeleteOutline />
                </Link>
              )}
            </TableCell>
          </TableRow>
        )
      ),
    [phoneNumbers, withTwilio]
  );

  if (!phoneNumbers) return;

  const updateField = (index, field, e) => {
    let value = e.target.value;
    if (field === "optOutCommunication") {
      value = e.target.value === "YES";
    }
    if (field === "twilio") {
      value = e.target.checked;
    }
    actions.updatePhoneNumber({ index, field, value });
    if (field === "number") {
      validateNumber(index, value);
    }
  };

  const validateNumber = (index, number) => {
    const err = number.length === 0 || validE164Format(number) ? null : "invalid number";
    fieldErrs[index] = err ? { number: err } : undefined;
    setFieldErrs(fieldErrs);
  };

  return (
    <Fragment>
      <ErrorList errors={errors} prefix="phoneNumbers" />
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell className={[cls.tableHeader, cls.smCell].join(" ")} key={`phone-h-primary`}>
              Primary
            </TableCell>
            <TableCell className={cls.tableHeader} key={`phone-h-number`}>
              Number
            </TableCell>
            <TableCell className={cls.tableHeader} key={`phone-h-name`}>
              Name
            </TableCell>
            <TableCell className={[cls.tableHeader, cls.cellType].join(" ")} key={`phone-h-type`}>
              Type
            </TableCell>
            {!withTwilio && (
              <TableCell className={[cls.tableHeader, cls.cellOptH].join(" ")} key={`phone-h-opt`}>
                Opt-Out Comms
              </TableCell>
            )}
            <TableCell className={cls.tableHeader} key={`phone-h-lang`}>
              Language
            </TableCell>
            {withTwilio && (
              <TableCell className={[cls.tableHeader, cls.smCell].join(" ")} key={`phone-h-twil`}>
                Twilio
              </TableCell>
            )}
            <TableCell
              className={[cls.tableHeader, cls.iconCell].join(" ")}
              key={`phone-h-remove`}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{data}</TableBody>
      </Table>
      {phoneRequired && (
        <div style={{ padding: "1em 1em 0" }}>
          <RequiredLabel
            text="At least one phone number is required"
            condition={phoneNumbers.length > 0}
          />
        </div>
      )}
      {(!maxSize || phoneNumbers.length < maxSize) && (
        <Button
          className={cls.btn}
          variant="contained"
          color="secondary"
          onClick={actions.newPhoneNumber}
        >
          New Number
        </Button>
      )}
    </Fragment>
  );
};

export default PhoneNumbersForm;
