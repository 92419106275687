import React, { useEffect, useCallback } from "react";
import { useLocalStore, useStoreActions } from "easy-peasy";
import { startCase } from "lodash";
import { Box, Link, Paper, TableRow, TableCell } from "@mui/material";
import BatchSelect from "components/admin_v2/common/BatchSelect";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import SearchInput from "components/admin_v2/ui/SearchInput";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { commsGroupsStore } from "components/admin_v2/school_config/stores/commsGroupsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import { formatHumanDate } from "lib/dates";
import { batchDelete } from "services/apiCommsGroups";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const LABELS_I18N = "school_config.comms_group.labels";

const CommsGroups = (props) => {
  const cls = useCommonPageStyles();
  const { onChangeScope } = useStoreActions((s) => s.app);
  const store = useLocalStore(() => commsGroupsStore({ ...camelizeLocationSearch(props) }));
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);
  const updateQueryString = updateWithRouter(props);
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  const resetState = useCallback(() => {
    updateQueryString({ page: 1 });
    actions.toggleRefresh();
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchCommsGroups({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [searchInput, state.page, state.perPage, state.refresh, sortBy]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  const updatePage = (_e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const headers = [
    {
      field: "batchCheck",
      label: (
        <GreenCheckbox
          checked={state.allSelected}
          onChange={(e) => actions.updateAllSelected(e.target.checked)}
        />
      ),
      skipSorting: true
    },
    { field: "id", label: "ID" },
    { field: "name", label: I18n.t(`${LABELS_I18N}.name`) },
    { field: "school", label: I18n.t(`${LABELS_I18N}.school`), skipSorting: true },
    { field: "reply_type", label: I18n.t(`${LABELS_I18N}.reply_type`) },
    { field: "created_at", label: I18n.t(`${LABELS_I18N}.created_at`) },
    { field: "updated_at", label: I18n.t(`${LABELS_I18N}.updated_at`) },
    { field: "route", label: I18n.t(`${LABELS_I18N}.route`) },
    { field: "route_type", label: I18n.t(`${LABELS_I18N}.route_type`) },
    { field: "avl_notifications", label: I18n.t(`${LABELS_I18N}.avl_notifications`) },
    { field: "", label: "" }
  ].map(sortableColumn);

  const row = (group) => (
    <TableRow hover key={`group-${group.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <GreenCheckbox
          checked={state.isSelected(group.id)}
          onChange={(e) => actions.updateSelectedIds({ isAdd: e.target.checked, id: group.id })}
        />
      </TableCell>
      <TableCell component="th" style={{ width: "5%" }}>
        <RouterLink to={pageRoutes.commsGroup(group.id)} className={cls.tableLink}>
          {group.id}
        </RouterLink>
      </TableCell>
      <TableCell>{group.name}</TableCell>
      <TableCell>{group.school || group.schools}</TableCell>
      <TableCell>{startCase(group.replyType)}</TableCell>
      <TableCell>{formatHumanDate(group.createdAt)}</TableCell>
      <TableCell>{formatHumanDate(group.updatedAt)}</TableCell>
      <TableCell>{group.route}</TableCell>
      <TableCell>{group.routeType}</TableCell>
      <TableCell>
        {group.avlNotifications ? I18n.t("ui.positive") : I18n.t("ui.negative")}
      </TableCell>
      <TableCell>
        <RouterLink to={pageRoutes.commsGroup(group.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.view")}
        </RouterLink>
        <RouterLink to={pageRoutes.commsGroupEdit(group.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.edit")}
        </RouterLink>
        <Link
          onClick={() => actions.deleteCommsGroup(group.id)}
          className={cls.tableLink}
          underline="hover"
        >
          {I18n.t("ui.btn.delete")}
        </Link>
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <Box className={cls.btnsWrapper}>
        <RouterLink className={cls.actionBtn} to={pageRoutes.commsGroupNew()}>
          <OutlinedBtn>{I18n.t("school_config.new_comms_group.btn")}</OutlinedBtn>
        </RouterLink>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          <BatchSelect
            store={store}
            actionsList={[{ label: I18n.t("ui.btn.delete"), action: batchDelete }]}
          />
          <SearchInput cls value={state.query} onChange={onSearch} />
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.groups.map(row)}
          </PaginatedTable>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default CommsGroups;
