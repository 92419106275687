import React, { Fragment, useState } from "react";
import { useStoreState } from "easy-peasy";
import I18n from "utils/i18n.js";
import StudentForm from "./StudentForm";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import { pageRoutes } from "services/api";

const NEW_STUDENT = {
  id: null,
  lastName: "",
  firstName: "",
  schoolId: null,
  dismissalGroupId: null,
  grade: null,
  programId: null,
  sisId: "",
  transportationId: "",
  communicationGroups: [],
  addresses: [],
  phoneNumbers: []
};

const StudentNew = (_props) => {
  const { school } = useStoreState((s) => s.app);
  const [student, _] = useState({ ...NEW_STUDENT, schoolId: school?.id || "" });

  return (
    <Fragment>
      <TopPageLayout
        title={I18n.t("students.new_student")}
        subtitle={
          <BreadCrumbs
            path={pageRoutes.students()}
            label={I18n.t("data_management.students.breadcrumb")}
          />
        }
      />
      <DataManagementTabs />
      <StudentForm student={student} />
    </Fragment>
  );
};

export default StudentNew;
