import React, { Fragment } from "react";
import { useHistory } from "react-router";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import makeStyles from "@mui/styles/makeStyles";
import { useLocalStore, useStoreActions } from "easy-peasy";
import { isNil } from "lodash";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import StudentModeChangeDialog from "components/admin_v2/mode_editor/StudentModeChangeDialog";
import AddressesForm from "components/admin_v2/common/AddressesForm";
import PhoneNumbersForm from "components/admin_v2/common/PhoneNumbersForm";
import StudentAccommodationForm from "./StudentAccommodationForm";
import StudentDetailsForm from "./StudentDetailsForm";
import studentFormStore from "./stores/studentFormStore";

const StudentForm = ({ student }) => {
  const cls = useStyles();
  const history = useHistory();
  const { setModeDialogOpen } = useStoreActions((s) => s.calendar);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const formStore = useLocalStore(() => studentFormStore({ student }));
  const [state, actions] = formStore;

  const redirect = (student) => {
    setModeDialogOpen(false);
    history.push(pageRoutes.studentProfile(student?.id || state.student.id));
  };

  const onSubmit = (_e) => {
    actions
      .save()
      .then((student) => {
        if (!student?.id) {
          setFlashMessage({ message: I18n.t("data_management.errors.fail", { name: "Student" }) });
        } else if (state.addTransportation) {
          actions.setStudent(student);
          setModeDialogOpen(true);
        } else {
          redirect(student);
        }
      })
      .catch((err) => {
        setFlashMessage({
          message:
            err?.response?.data?.message ||
            I18n.t("data_management.errors.fail", { name: "Student" })
        });
      });
  };

  return (
    <Fragment>
      <Box my={2.5} className="student-form-details">
        <Paper className={cls.paper}>
          <div className={cls.title}>{I18n.t("students.form.details")}</div>
          <StudentDetailsForm student={student} formStore={formStore} cls={cls} />
        </Paper>
      </Box>
      <Box my={2.5} className="student-form-accommodation">
        <Paper className={cls.paper}>
          <div className={cls.title}>{I18n.t("students.form.accommodation")}</div>
          <StudentAccommodationForm student={student} formStore={formStore} cls={cls} />
        </Paper>
      </Box>
      <Box my={2.5} className="student-form-addresses">
        <Paper className={cls.paper}>
          <div className={cls.title}>{I18n.t("students.form.addresses")}</div>
          <AddressesForm
            store={formStore}
            schoolId={state.details?.schoolId || (state.student || student)?.schoolId}
          />
        </Paper>
      </Box>
      <Box my={2.5} className="student-form-phone-numbers">
        <Paper className={cls.paper}>
          <div className={cls.title}>{I18n.t("students.form.phone_numbers")}</div>
          <PhoneNumbersForm store={formStore} />
        </Paper>
      </Box>
      {isNil(student.id) && (
        <Box my={2.5} className="student-form-add-transportation">
          <Paper className={cls.paper}>
            <div className={cls.title}>{I18n.t("students.form.transportation")}</div>
            <Grid container className={cls.grid} spacing={5}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.addTransportation}
                      onChange={(e) => actions.setAddTransportation(e.target.checked)}
                      className={cls.check}
                    />
                  }
                  label={I18n.t("students.form.add_transportation")}
                />
              </Grid>
              <Grid item xs={9}>
                <FormLabel className={cls.mx}>
                  {I18n.t("students.form.start_date")}
                  <span className={cls.required}>*</span>
                </FormLabel>
                <DatePicker
                  open={state.datePickerOpen}
                  onOpen={() => actions.setDatePickerOpen(true)}
                  onClose={() => actions.setDatePickerOpen(false)}
                  value={state.startDate}
                  onChange={actions.setStartDate}
                  desktopModeMediaQuery="@media (min-width:320px)"
                  renderInput={(props) => (
                    <TextField name="add-transportation-date" variant="outlined" {...props} />
                  )}
                />
                {state.addTransportation && isNil(state.startDate) && (
                  <span className={cls.mx}>
                    <RequiredLabel text="Start date is required" />
                  </span>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
      <Box my={5}>
        <PrimaryBtn
          variant="contained"
          color="primary"
          size="large"
          onClick={onSubmit}
          disabled={!state.isValid || state.loading}
        >
          {I18n.t("ui.btn.save")}
        </PrimaryBtn>
      </Box>
      {isNil(student.id) && (
        <StudentModeChangeDialog
          onClose={redirect}
          onCancel={redirect}
          onSave={redirect}
          student={state.student}
          date={state.startDate}
          showViewProfile={false}
          isNewStudent={true}
        />
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontWeight: theme.custom.BOLD,
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    fontSize: "1rem"
  },
  grid: {
    flexGrow: 1
  },
  mx: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  required: {
    color: theme.custom.RED
  },
  select: {
    minWidth: theme.spacing(24),
    maxWidth: "100%"
  },
  field: {
    marginBottom: theme.spacing(3)
  }
}));

export default StudentForm;
