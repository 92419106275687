import React, { useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import { pick } from "lodash";
import { formatISO } from "date-fns";
import {
  FormControlLabel,
  Box,
  Button,
  Paper,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocalStore, useStoreActions, useStoreState, thunk, thunkOn } from "easy-peasy";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import RouteSelector from "./RouteSelector";
import RouteTabs from "./RouteTabs";
import { toDate } from "lib/dates";
import easyState from "lib/easyState";
import { toJS } from "lib/util";
import { pageRoutes } from "services/api";
import { fetchRoute, fetchVehicleTypes, updateRoute, archiveRoute } from "services/apiRoutes";
import { RouteTitle, RouteSubtitle } from "./common/RouteTitles";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import VendorSchedules from "./VendorSchedules";
import ErrorList from "components/admin_v2/ui/ErrorList";
import SchoolCode from "components/admin_v2/ui/SchoolCode";
import usePrefixedInput from "lib/usePrefixedInput";
import I18n from "utils/i18n.js";

const FORM_FIELDS = ["name", "start_date", "end_date", "vehicle_type", "comment", "looping"];

const routeStore = (_initial) => ({
  ...easyState("route"),
  ...easyState("form"),
  ...easyState("errors"),
  ...easyState("options", {
    vehicle_types: []
  }),

  updateForm: thunk((actions, { field, evt }, h) => {
    const form = h.getState().form;
    let value;
    if (field === "start_date" || field === "end_date") {
      value = formatISO(evt, { representation: "date" });
    } else if (field === "name") {
      value = evt;
    } else if (field === "looping") {
      value = evt.target.checked;
    } else if (evt) {
      value = evt.target ? evt.target.value : evt.id;
    } else {
      value = null;
    }

    let params = { [field]: value };
    if (field === "transportation_vendor_id") {
      params["inbound_vehicle_id"] = null;
      params["outbound_vehicle_id"] = null;
    }

    actions.setForm({ ...form, ...params });
  }),

  onSetRoute: thunkOn(
    (actions) => actions.setRoute,
    (actions, target) => {
      if (!target.payload) return;

      let newForm = pick(target.payload, FORM_FIELDS);
      actions.setForm(newForm);
    }
  )
});

const RouteEdit = (props) => {
  const cls = useStyles();
  const history = useHistory();
  const { isUserAdmin } = useStoreState((s) => s.app);
  const { onChangeScope, setFlashMessage } = useStoreActions((s) => s.app);
  const [state, actions] = useLocalStore(() => routeStore());
  const { route, form, errors, options } = state;

  const [routeNameVal, updateRouteName] = usePrefixedInput({
    value: form?.name,
    prefix: route?.routable_code,
    update: (val) => actions.updateForm({ field: "name", evt: val })
  });

  useEffect(() => {
    if (!isUserAdmin) return;

    const routeId = props.match.params.id;
    if (!routeId) return;
    fetchRoute(routeId, { withPairs: true }).then((resp) => actions.setRoute(resp));
  }, [props.match.params.id]);

  useEffect(() => {
    onChangeScope(() => {
      actions.setRoute(null);
      history.push(pageRoutes.routes());
    });

    fetchVehicleTypes().then((options) => {
      actions.setOptions(options);
    });

    return () => {
      actions.setRoute(null);
      onChangeScope(null);
    };
  }, []);

  if (!route || !form || route.discarded) return null;

  const changeRoute = (route) => {
    actions.setRoute(route);
    if (route) history.push(pageRoutes.routeEdit(route.id));
  };

  const changeField = (field) => (evt) => {
    actions.updateForm({ field, evt });
  };

  const cancel = () => {
    history.push(pageRoutes.route(route.id));
  };

  const onSubmit = () => {
    actions.setErrors(null);

    updateRoute(route.id, toJS(form))
      .then((resp) => {
        setFlashMessage({ message: resp.message });
        history.push(pageRoutes.route(route.id));
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;
        actions.setErrors(errors);
      });
  };

  const archive = () => {
    const confirmationText = I18n.t("route.edit.confirm_archive");
    if (confirm(confirmationText)) {
      archiveRoute(route.id)
        .then((resp) => {
          setFlashMessage({ message: resp.message });
          history.push(pageRoutes.routes());
        })
        .catch((err) => {
          const message = err.response?.data?.message;
          setFlashMessage({ message });
        });
    }
  };

  const id = route.id;

  return (
    <Fragment>
      <TopPageLayout
        title={<RouteTitle route={route} />}
        subtitle={<RouteSubtitle />}
        filters={<RouteSelector route={route} onChange={changeRoute} />}
        actionButtons={[]}
      />
      <RouteTabs route={route} location={location} />
      <Paper className={cls.root}>
        <Box className={cls.wrapper}>
          <ErrorList errors={errors?.route} />
          <TextField
            variant="standard"
            id={`route-${id}-routable-type`}
            label={I18n.t("route.edit.routable_type")}
            value={
              route?.routable_type
                ? `${I18n.t(`ui.route.routable_type.select.${route.routable_type}`)} (${
                    route.routable_name
                  })`
                : ""
            }
            disabled={true}
            className={cls.fieldLg}
          />
          <TextField
            variant="standard"
            id={`route-${id}-name`}
            label={`${I18n.t("route.edit.name")} (${I18n.t(
              `ui.route.route_type.${route.route_type}`
            )})`}
            value={routeNameVal}
            onChange={updateRouteName}
            className={cls.fieldLg}
            error={!!errors?.name}
            helperText={errors?.name}
            disabled={route.non_riding_type}
            InputProps={{ startAdornment: <SchoolCode code={route?.routable_code} /> }}
          />
          <Grid container className={cls.grid} columnSpacing={3} rowSpacing={0}>
            <Grid item xs={6}>
              <FormControl
                className={cls.fieldDate}
                error={!!errors?.start_date}
                name="route-start-date"
                variant="standard"
              >
                <DatePicker
                  className={cls.field}
                  label={I18n.t("route.edit.start_date")}
                  inputFormat="MMMM dd, yyyy"
                  desktopModeMediaQuery="@media (min-width:320px)"
                  value={toDate(form.start_date)}
                  onChange={changeField("start_date")}
                  renderInput={(props) => <TextField variant="standard" {...props} />}
                  disabled={route.non_riding_type}
                  disableMaskedInput
                />
                {errors?.start_date && <FormHelperText>{errors?.start_date}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                className={cls.fieldDate}
                error={!!errors?.end_date}
                name="route-end-date"
                variant="standard"
              >
                <DatePicker
                  className={[cls.field, cls.fieldEnd].join(" ")}
                  label={I18n.t("route.edit.end_date")}
                  inputFormat="MMMM dd, yyyy"
                  desktopModeMediaQuery="@media (min-width:320px)"
                  value={toDate(form.end_date)}
                  onChange={changeField("end_date")}
                  renderInput={(props) => <TextField variant="standard" {...props} />}
                  disabled={route.non_riding_type}
                  disableMaskedInput
                />
                {errors?.end_date && <FormHelperText>{errors?.end_date}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={cls.grid} spacing={3}>
            <Grid item xs={6}>
              <FormControl className={cls.field} variant="standard">
                <InputLabel>{I18n.t(`ui.form.vehicle_type.label`)}</InputLabel>
                <Select
                  labelId={`route-vehicle-type`}
                  value={options.vehicle_types.length ? form.vehicle_type : ""}
                  onChange={changeField("vehicle_type")}
                  disabled={route.non_riding_type}
                >
                  {options.vehicle_types.map((vt) => (
                    <MenuItem key={`opt-$${vt}`} value={vt}>
                      {vt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box>
            <FormControl fullWidth>
              <FormLabel className={cls.smallLabel}>{I18n.t("ui.route.comment.label")}</FormLabel>
              <TextField
                variant="outlined"
                multiline
                rows={2}
                value={form.comment || ""}
                className={cls.comment}
                onChange={changeField("comment")}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={<GreenCheckbox checked={form.looping} onChange={changeField("looping")} />}
                className={cls.label}
                label={I18n.t("ui.route.looping.label")}
              />
            </FormControl>
          </Box>
          <Box className={cls.actions}>
            <Button
              variant="contained"
              color="primary"
              className={cls.btn}
              onClick={onSubmit}
              disabled={route.non_riding_type}
            >
              {I18n.t("route.edit.update")}
            </Button>
            <Button variant="outlined" className={cls.btn} onClick={cancel}>
              {I18n.t("ui.btn.cancel")}
            </Button>
            {isUserAdmin && (
              <>
                <div className={cls.btnSeparator} />
                <Button
                  variant="outlined"
                  className={cls.btn}
                  onClick={archive}
                  disabled={route.non_riding_type}
                >
                  {I18n.t("ui.btn.archive")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Paper>
      {!route.non_riding_type && <VendorSchedules props={props} route={route} />}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  wrapper: {
    width: "70%",
    marginBottom: theme.spacing(3)
  },
  grid: {
    flexGrow: 1,
    marginTop: "0",
    marginBottom: "0"
  },
  fieldLg: {
    width: "100%",
    marginBottom: theme.spacing(3)
  },
  field: {
    width: "75%",
    marginBottom: theme.spacing(3)
  },
  fieldEnd: {
    float: "right"
  },
  fieldDate: {
    width: "100%",
    display: "block"
  },
  actions: {
    margin: theme.spacing(4, 0),
    display: "flex",
    flexDirection: "row"
  },
  btn: {
    marginRight: theme.spacing(2)
  },
  btnSeparator: {
    borderLeft: `solid 1px ${theme.custom.MEDIUM_GREY}`,
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
    position: "relative",
    top: "2px"
  },
  comment: {
    backgroundColor: theme.custom.WHITE,
    width: "100%",
    "& .MuiInputBase-root": {
      padding: 0
    }
  },
  smallLabel: {
    fontSize: "0.8rem"
  }
}));

export default RouteEdit;
