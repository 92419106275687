import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import ErrorList from "components/admin_v2/ui/ErrorList";
import Spinner from "components/admin_v2/ui/Spinner";
import TripsEditor from "./trips/TripsEditor";
import ModeChangeForm from "./common/ModeChangeForm";

const ModeChangeEditor = ({
  modeChangeStore,
  showViewProfile,
  saveChanges,
  onCancel,
  onDateChange,
  isCustom,
  inline
}) => {
  const [state, _actions] = modeChangeStore;

  return (
    <ModeChangeForm
      modeChangeStore={modeChangeStore}
      showViewProfile={showViewProfile}
      saveChanges={saveChanges}
      onCancel={onCancel}
      onDateChange={onDateChange}
      inline={inline}
    >
      {state.calendar ? (
        <Fragment>
          {state.loading && <Spinner />}
          <TripsEditor
            modeChangeStore={modeChangeStore}
            isCustom={isCustom}
            saveChanges={saveChanges}
            onCancel={onCancel}
          />
        </Fragment>
      ) : isEmpty(state.errors) ? (
        <Spinner />
      ) : (
        <ErrorList errors={state.errors} />
      )}
    </ModeChangeForm>
  );
};

export default ModeChangeEditor;
