import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const BooleanText = ({ val }) => {
  const cls = useStyles();
  const text = val ? "Yes" : "No";
  const textCls = val ? cls.greenText : cls.redText;

  return <Box className={textCls}>{text}</Box>;
};

const useStyles = makeStyles((theme) => ({
  greenText: {
    color: theme.custom.GREEN
  },
  redText: {
    color: theme.custom.RED
  }
}));

export default BooleanText;
