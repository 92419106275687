import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const submitRouteImport = async (params) => {
  const url = apiUrl(`route_imports`);
  return await xhr.post(url, params);
};

export const fetchImportConflicts = async (id) => {
  return await xhr.get(apiUrl(`route_imports/${id}/conflicts`));
};

export const resolveConflicts = async (id, params) => {
  const url = apiUrl(`route_imports/${id}/resolve_conflicts`);
  return await xhr.post(url, params);
};

export const fetchRouteImports = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`route_imports?${query}`));
};
