import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  routeDetailsTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  weekChange: {
    margin: theme.spacing(1, 1, 0, 1)
  },
  datePicker: {
    marginTop: "0",
    "& .MuiTextField-root": {
      marginTop: "0"
    }
  },
  hideStops: {
    display: "inline-block",
    marginRight: theme.spacing(7),
    "& .MuiFormControlLabel-label": {
      position: "relative",
      top: theme.spacing(0.3)
    }
  },
  comment: {
    backgroundColor: theme.custom.WHITE,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2)
  }
}));

export default useStyles;
