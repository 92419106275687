import React from "react";
import { useStoreState } from "easy-peasy";
import { first } from "lodash";
import { FormControl, FormLabel } from "@mui/material";
import FormSelect from "components/admin_v2/ui/FormSelect";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { searchAddress } from "services/apiAddresses";
import I18n from "utils/i18n.js";

const AddressNewForm = ({ store, cls, children }) => {
  const [state, actions] = store;
  const { isCrossSchool, route } = useStoreState((s) => s.routeEditor);
  const { schools, school } = useStoreState((s) => s.app);
  // TODO: (DISTRICT) Should we refetch here? Will all route schools be available (looks like so)
  const schoolOptions = isCrossSchool
    ? schools.filter((s) => route?.school_ids.includes(s.id))
    : [];

  const onSchoolSelect = (e) => actions.setSchoolId(e.target.value);

  return (
    <>
      {isCrossSchool && (
        <FormControl className={cls.withBottomMargin} fullWidth required>
          <FormLabel className={cls.label}>
            {I18n.t("ui.route.routable_type.label.school")}
          </FormLabel>
          <FormSelect
            itemType="school"
            options={schoolOptions}
            value={state.schoolId || school?.id}
            onChange={onSchoolSelect}
            cls={cls}
            clsKeys={["select", "select"]}
            withoutLabel={true}
            required
          />
        </FormControl>
      )}
      <AutocompleteSelect
        id={`route-new-stop-location`}
        getOptions={searchAddress}
        filterOptions={(options) => options} // we don't need to filter any results
        onOptionSelect={(data) => {
          const address = data ? data.description : null;
          actions.setStopLocation({ address, name: address });
        }}
        searchParams={{ school_id: state.schoolId || first(route?.school_ids) }}
        labelField={"description"}
        defaultValue={state.stopLocation?.address || ""}
      />
      {children}
    </>
  );
};

export default AddressNewForm;
