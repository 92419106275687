import React, { Fragment, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Box, Divider } from "@mui/material";
import { flatMap, map } from "lodash";
import { AddTripButton, DropdownTrips } from "components/admin_v2/common/CustomTripsBtns";
import CustomTrip from "./CustomTrip";
import AddTripDialog from "../trip/AddTripDialog";
import { compareCustomTripByTime } from "../common";

const CustomTrips = ({ day, trips, index, schoolId }) => {
  const { isUserAdmin, readOnly, isPastDate } = useStoreState((s) => s.app);
  const { refresh } = useStoreState((s) => s.calendar);
  const { setRefresh, setModeDialogOpen, setModeDialogDate, setChangeTripData } = useStoreActions(
    (s) => s.calendar
  );
  const [addTripDialogOpen, setAddTripDialogOpen] = useState(false);
  const [openExtraTrips, setOpenExtraTrips] = useState(false);
  const stops = flatMap(trips, "stops").sort(compareCustomTripByTime);
  const countTrips = stops.length;

  const onAddTripClose = (withRefresh = false) => {
    setAddTripDialogOpen(false);
    if (withRefresh) setRefresh(!refresh);
  };

  const onAddTripChangeOpen = () => {
    setModeDialogDate(day);
    setChangeTripData({ tripType: "to_custom", eventType: "change", stopId: null });
    setModeDialogOpen(true);
  };

  const addTripButton = () => {
    if (readOnly) return null;

    return isUserAdmin ? (
      <AddTripButton
        setAddTripDialogOpen={setAddTripDialogOpen}
        addTripDialogOpen={addTripDialogOpen}
      />
    ) : (
      <AddTripButton
        setAddTripDialogOpen={onAddTripChangeOpen}
        addTripDialogOpen={true}
        disabled={isPastDate(schoolId, day)}
      />
    );
  };

  const dropdownTrips = (
    <DropdownTrips
      setOpenExtraTrips={setOpenExtraTrips}
      openExtraTrips={openExtraTrips}
      countTrips={countTrips}
    />
  );

  const extraTrips = map(stops, (trip, idx) => (
    <CustomTrip key={`ctrip-${idx}`} trip={trip} day={day} index={index} />
  ));
  const extraTripsDropdown = openExtraTrips ? (
    <Fragment>
      {extraTrips}
      {dropdownTrips}
      {addTripButton()}
    </Fragment>
  ) : (
    dropdownTrips
  );

  return (
    <Fragment>
      <Divider />
      <Box p={2}>{countTrips === 0 ? addTripButton() : extraTripsDropdown}</Box>
      {isUserAdmin ? (
        <AddTripDialog day={day} open={addTripDialogOpen} onClose={onAddTripClose} />
      ) : null}
    </Fragment>
  );
};

export default CustomTrips;
