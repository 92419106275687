import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import RouterLink from "components/admin_v2/nav/RouterLink";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import makeStyles from "@mui/styles/makeStyles";

const BreadCrumbs = ({ path, label, sub = null }) => {
  const cls = useStyles();

  return (
    <Box className={cls.wrapper}>
      <ArrowLeftIcon className={cls.icon} />
      <Breadcrumbs aria-label="breadcrumb" className={cls.breadCrumb}>
        <RouterLink to={path}>{label}</RouterLink>
        {sub && <Typography>{sub}</Typography>}
      </Breadcrumbs>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex"
  },
  icon: {
    color: theme.custom.BLUE
  },
  breadCrumb: {
    marginBottom: theme.spacing(2)
  }
}));

export default BreadCrumbs;
