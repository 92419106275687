import React, { Fragment, useMemo } from "react";
import { FormControl, FormLabel } from "@mui/material";
import RouteCalendar from "components/admin_v2/common/RouteCalendar";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { formatDateUrl } from "lib/dates";
import { fetchStops } from "services/api";
import { fetchRoute } from "services/apiRoutes";
import RouteAnchorInfo from "components/admin_v2/students/trip/route/RouteAnchorInfo";
import I18n from "utils/i18n.js";

const ChangeToExistingStopForm = ({ store, tripType, stopType, cls, children }) => {
  const [state, actions] = store;
  const stop = state.getStopFor(tripType, stopType);
  const resetOptions = useMemo(() => [tripType, state.rideType], [tripType, state.rideType]);

  const onChangeStop = (stop) => {
    actions.setStop({ stop, stopType, tripType });
    if (stop) {
      fetchRoute(stop.route_id, {
        date: formatDateUrl(state.period.startDate),
        with_anchor: true
      }).then((response) => {
        actions.setRoute(response);
      });
    }
  };

  return (
    <Fragment>
      <FormControl required fullWidth>
        <FormLabel className={cls.label}>
          {I18n.t(`ui.mode_editor.form.existing_stop.${stopType}`)}
        </FormLabel>
        <AutocompleteSelect
          id={`stop-select-${tripType}`}
          getOptions={fetchStops}
          onOptionSelect={onChangeStop}
          placeholder={I18n.t("ui.mode_editor.form.existing_stop.placeholder")}
          labelField={"name_route_time"}
          searchParams={{
            date: formatDateUrl(state.period.startDate),
            trip_type: tripType,
            school_id: state.student?.school_id || state.school?.id,
            riders_type: state.rideType,
            only_riding: true,
            only_for_school: true
          }}
          // we're fetching all stops at once so don't need to refetch on input change
          localSearch={true}
          defaultValue={stop}
          resetOptions={resetOptions}
        />
        {children}
      </FormControl>
      {state.route?.with_schedule && (
        <RouteCalendar
          date={state.period.startDate}
          route={state.route}
          tripTypes={state.tripTypes}
        />
      )}
      {state.route?.id && (
        <RouteAnchorInfo store={store} cls={cls} tripType={tripType} withTopMargin={true} />
      )}
    </Fragment>
  );
};

export default ChangeToExistingStopForm;
