import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Spinner from "components/admin_v2/ui/Spinner";
import StopRow from "./StopRow";
import AddStopDialog from "./AddStopDialog";
import StopsTableHeader from "./StopsTableHeader";
import DayOffsRow from "./schedule/DayOffsRow";
import ScheduleRepeatRow from "./schedule/ScheduleRepeatRow";
import { useDirectionScheduleUpdate } from "./common/hooks";

const EditStopsTable = ({ onColumnSelect }) => {
  const cls = useStyles();
  const { user } = useStoreState((s) => s.app);
  const { route, sortedStops, addStopDialogOpen } = useStoreState((s) => s.routeEditor);
  const { setAddStopDialogOpen } = useStoreActions((s) => s.routeEditor);
  const [loading, setLoading] = useState(false);

  useDirectionScheduleUpdate(location, setLoading, true);

  const toggleAddStopDialog = () => {
    setAddStopDialogOpen(!addStopDialogOpen);
  };

  return loading ? (
    <Spinner />
  ) : (
    <Table className={cls.stopsTable} data-test-id="stops-table">
      <TableBody>
        <TableRow>
          <TableCell className={cls.addStopCell}>
            <AddCircleIcon
              onClick={toggleAddStopDialog}
              color="primary"
              className={cls.addStopIcon}
              id="add-stop-i"
              disabled={route?.discarded || route.non_riding_type}
            />
            <Typography display="inline" className={cls.addStopLabel}>
              Add a Stop
            </Typography>
          </TableCell>
          <StopsTableHeader onColumnSelect={onColumnSelect} editStops />
        </TableRow>
        <DayOffsRow withoutSelection={true} />
        <ScheduleRepeatRow />
        {sortedStops({ withFilteredSchedule: false }).map((stopsRow, idx) => (
          <StopRow
            stopsRow={stopsRow}
            stopNumber={idx + 1}
            key={`stop-location-${stopsRow.stop_location.id}`}
          />
        ))}
      </TableBody>
      {user.admin ? (
        <AddStopDialog open={addStopDialogOpen} toggleDialog={toggleAddStopDialog} />
      ) : null}
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  stopsTable: {
    borderTopWidth: theme.spacing(0.1),
    borderTopColor: theme.palette.background.mediumGrey,
    borderTopStyle: "solid",
    "& .MuiTableCell-root": {
      borderTop: `${theme.spacing(0.1)} solid ${theme.palette.background.mediumGrey}`,
      borderRight: `${theme.spacing(0.1)} solid ${theme.palette.background.mediumGrey}`,
      borderLeft: `${theme.spacing(0.1)} solid ${theme.palette.background.mediumGrey}`
    }
  },
  addStopCell: {
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    border: "none !important"
  },
  addStopLabel: {
    marginLeft: theme.spacing(2),
    fontWeight: theme.custom.BOLD
  },
  addStopIcon: {
    cursor: "pointer",
    fontSize: "2.8rem"
  }
}));

export default EditStopsTable;
