import { action, computed, thunk } from "easy-peasy";
import { camelizeKeys } from "humps";
import _ from "lodash";
import { parseTime, formatDisplayTime } from "lib/dates";
import { ROUTABLE_TYPES } from "./addTripStore";
import { fetchSchoolsData } from "services/apiSchools";
import { stopsSchoolModel } from "./stopsSchoolModel";

export const getAnchorType = (tripType) => (_.startsWith(tripType, "to") ? "dropoff" : "pickup");
export const getStopType = (tripType) => (_.startsWith(tripType, "to") ? "pickup" : "dropoff");
export const getRouteType = (tripType) => {
  if (_.endsWith(tripType, "school") || _.endsWith(tripType, "regular")) return "regular";
  if (_.endsWith(tripType, "activity")) return "activity";
  return "custom";
};
export const getStopWorkflow = (stop = {}) => {
  if (stop.schoolAddress) return "school";
  if (stop.id) return "chooseStop";
  if (stop.stopLocation?.id) return "choose";
  return "new";
};
export const isOtherRoute = (tripType) =>
  _.endsWith(tripType, "custom") || _.endsWith(tripType, "activity");

// get stop data from serialized location + time data
export const getStopFrom = (stop) => {
  const data = camelizeKeys(stop);
  if (!data) return {};
  return {
    masterId: data.masterId,
    refresh: data.stopLocationId || data.stopLocation?.id,
    stopLocation: {
      address: data.address || data.stopLocation?.address,
      id: data.stopLocationId || data.stopLocation?.id,
      name: data.address || data.stopLocation?.address,
      type: "StopLocation"
    },
    schoolAddress: !!data.masterId,
    time: parseTime(data.time)
  };
};

// stops related methods for using forms from trip/stops and trip/addresses
// it's supposed to have store model with
// school, errors, stops, route, workflow
export const stopsModel = () => ({
  ...stopsSchoolModel(),

  currentSchoolId: computed(
    (state) => state.student?.school_id || state.schoolId || state.school?.id
  ),
  getRouteAnchorFor: computed((state) => (tripType) => {
    const anchors = _.get(state.route, `anchors.${tripType}`, []);
    return state.getRouteAnchorFrom(anchors);
  }),
  getRouteAnchorFrom: computed((state) => (anchors) => {
    // TODO: (MULTIPLE ANCHORS) Check if this will work, we're showing anchor only for requested school
    if (!state.currentSchoolId) return anchors[0] || {};
    return anchors.find((a) => a.school_id === state.currentSchoolId) || {};
  }),

  getStopErrorsFor: computed((state) => (tripType, stopType) => {
    let errorTripType = tripType;
    if (tripType === "direct" || tripType === "return") {
      errorTripType =
        state.anchor === "dropoff" && tripType === "direct" ? "toCustom" : "fromCustom";
    }
    // update state errors in case if we have errors with real trip type and direct/return at store
    let stateErrors = _.mapKeys(state.errors || {}, (_v, k) =>
      k === errorTripType ? tripType : k
    );
    let errors = _.get(
      stateErrors,
      `${_.camelCase(tripType)}.${stopType}`,
      _.get(stateErrors, _.camelCase(tripType), {})
    );
    let isPlainErrors = _.isString(errors);
    if (isPlainErrors && /time/i.test(errors)) {
      isPlainErrors = false;
      errors = { time: errors };
    }
    // omit errors from other stop
    if (!isPlainErrors) errors = _.omit(errors, stopType === "pickup" ? "dropoff" : "pickup");
    const errorStop = isPlainErrors ? { "": errors } : _.omit(errors, "time");
    const errorTime = _.get(errors, "time", false);
    return { errors, errorStop, errorTime };
  }),

  getStopFor: computed(
    (state) => (tripType, stopType) => _.get(state.stops, `${tripType}.${stopType}`)
  ),

  getWorkflowFor: computed(
    (state) =>
      (tripType, stopType, defaultValue = null) =>
        _.get(state.workflow.stops, `${tripType}.${stopType}`, defaultValue)
  ),

  isCrossSchool: computed((state) => state.routableType === ROUTABLE_TYPES.crossSchool),

  isSameStop: computed((state) => (tripType, stopType, aStop) => {
    const bStop = state.getStopFor(tripType, stopType);
    if (!aStop || !bStop) return false;
    const address = aStop.address || aStop.stop_location?.address;
    if (address !== bStop.stopLocation?.address) return false;
    const bTime = formatDisplayTime(bStop.time);
    if (!aStop.time || aStop.time === bTime) return true;
    return formatDisplayTime(parseTime(aStop.time)) === bTime;
  }),

  routableSchool: computed((state) => ({ stopType, tripType }) => {
    const path = `${tripType}.${stopType}.masterId`;
    const addressId = _.get(state.stops, path);
    if (!addressId) return null;
    return state.routableSchools.find((s) => _.map(s.addresses, "id").includes(addressId));
  }),

  studentAddresses: computed((state) =>
    state.student
      ? _.map(state.student.addresses, (v) => ({
          id: v.address,
          name: v.address,
          primary: v.primary
        }))
      : []
  ),

  selectedSchool: computed((state) => (params) => {
    if (state.isCrossSchool) return state.routableSchool(params);
    const schoolId = state.currentSchoolId;
    return state.routableSchools.find((s) => s.id === schoolId);
  }),

  setStop: action((state, { stop, tripType, stopType, workflow }) => {
    const path = `${tripType}.${stopType}`;
    _.set(state.stops, path, stop);
    if (workflow) {
      _.set(state.workflow.stops, path, workflow);
    }
  }),

  // init stop from another one
  setStopFrom: action((state, { stop, tripType, stopType }) => {
    const path = `${tripType}.${stopType}`;
    const sameStop = getStopFrom(stop);
    _.set(state.stops, path, sameStop);
    _.set(state.workflow.stops, path, getStopWorkflow(sameStop));
  }),

  setStopSchool: action((state, { tripType, stopType, workflow }) => {
    const path = `${tripType}.${stopType}`;
    _.set(state.stops, path, {
      masterId: state.routableSchoolsAddresses[0]?.id,
      stopLocation: _.omit(state.routableSchoolsAddresses[0] || {}, "id"),
      schoolAddress: true,
      timeId: _.get(state.stops, `${path}.timeId`),
      time: _.get(state.stops, `${path}.time`)
    });
    _.set(state.workflow.stops, path, workflow);
  }),

  setStopStudent: action((state, { tripType, stopType, workflow }) => {
    const path = `${tripType}.${stopType}`;
    _.set(state.stops, path, {
      stopLocation: { address: state.studentAddresses[0].name }
    });
    _.set(state.workflow.stops, path, workflow);
  }),

  setStopSchoolWorkflow: action((state, { tripType, stopType, workflow }) => {
    const path = `${tripType}.${stopType}`;
    _.set(state.stops, path, {
      masterId: null,
      stopLocation: null,
      schoolAddress: true
    });
    _.set(state.workflow.stops, path, workflow);
  }),

  setStopLocation: action((state, { stopLocation, tripType, stopType }) => {
    const path = `${tripType}.${stopType}`;
    _.set(state.stops, path, { stopLocation });
  }),

  setSchoolStopLocation: action((state, { stopLocation, tripType, stopType }) => {
    const path = `${tripType}.${stopType}`;
    const timeId = _.get(state.stops, `${path}.timeId`);
    if (stopLocation) {
      _.set(state.stops, path, {
        masterId: stopLocation.id,
        stopLocation: _.omit(stopLocation, "id"),
        schoolAddress: true,
        timeId
      });
    } else {
      _.set(state.stops, path, {
        masterId: null,
        stopLocation: null,
        schoolAddress: true,
        timeId
      });
    }
  }),

  setStopSchoolTime: action((state, { tripType, stopType, timeId }) => {
    _.set(state.stops, `${tripType}.${stopType}.timeId`, timeId);
    _.set(state.stops, `${tripType}.${stopType}.time`, null);
    _.set(state.stops, `${tripType}.${stopType}.waitTime`, null);
  }),

  setStopTime: action((state, { tripType, stopType, time }) => {
    _.set(state.stops, `${tripType}.${stopType}.time`, time);
    _.set(state.stops, `${tripType}.${stopType}.timeId`, null);
  }),

  setUserAnchorTime: action((state, { tripType, stopType, time }) => {
    _.set(state.stops, `${tripType}.${stopType}.time`, time.time);
    _.set(state.stops, `${tripType}.${stopType}.waitTime`, time.waitTime);
    _.set(state.stops, `${tripType}.${stopType}.timeId`, null);
  }),

  updateSchoolStopData: thunk((actions, params = {}, h) => {
    const { stopType, tripType } = params;
    const state = h.getState();
    if (state.schoolAddresses?.length === 1) {
      actions.setStopSchool({ stopType, tripType, workflow: "school" });
    }
    const schoolTimes = state.schoolTimes({ stopType, tripType });
    actions.setStopSchoolTime({ timeId: schoolTimes[0]?.id, stopType, tripType });
  }),

  updateSchoolStopNewData: thunk((actions, params = {}, h) => {
    const { stopType, tripType, workflow } = params;
    const state = h.getState();
    actions.setStopSchoolWorkflow({ stopType, tripType, workflow });
    if (state.schoolAddresses?.length === 1) {
      actions.setStopSchool({ stopType, tripType, workflow });
    }
  }),

  fetchSchoolsData: thunk((actions, _params = {}, h) => {
    const state = h.getState();
    const options = state.isCrossSchool
      ? { districtId: state.districtId || state.route?.routableId, schoolId: null }
      : { districtId: null, schoolId: state.currentSchoolId };
    return fetchSchoolsData(options).then((data) => actions.setRoutableSchools(data.schools));
  })
});
