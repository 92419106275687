import React, { Fragment } from "react";
import cn from "classnames";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import I18n from "utils/i18n.js";
import { commaErrors, strPresent } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const ProgramForm = ({ store }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { programForm, errors } = state;

  const onChange = (field) => (e) => {
    actions.setProgramForm({ ...programForm, [field]: e.target.value });
  };

  return (
    <Fragment>
      <Grid container className={cls.grid} spacing={5}>
        <Grid item xs={8}>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("settings_config.programs.form.name.label")}
                condition={strPresent(programForm?.name)}
              />
            }
            value={programForm?.name || ""}
            onChange={onChange("name")}
            className={cn("program-name", cls.field)}
            error={!!errors?.name}
            helperText={commaErrors(errors?.name)}
          />
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("settings_config.programs.form.abbreviation.label")}
                condition={strPresent(programForm?.abbreviation)}
              />
            }
            value={programForm?.abbreviation || ""}
            onChange={onChange("abbreviation")}
            className={cn("program-abbreviation", cls.field)}
            error={!!errors?.abbreviation}
            helperText={commaErrors(errors?.abbreviation)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProgramForm;
