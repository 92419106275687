import React, { useMemo } from "react";
import { Dialog, Typography, Box } from "@mui/material";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import { appendParamToUrl } from "lib/util";
import { get } from "lodash";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import I18n from "utils/i18n.js";
import { addReturnStore, getReturnDirection } from "../stores/addReturnStore";
import AddReturnForm from "./AddReturnForm";

const AddReturnDialog = ({ open, toggleDialog }) => {
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const { route, direction } = useStoreState((s) => s.routeEditor);
  const { setRouteAndDirection } = useStoreActions((s) => s.routeEditor);
  const currentDirection = useMemo(() => getReturnDirection(direction), [direction]);
  const store = useLocalStore(() => addReturnStore(), [open]);
  const [state, actions] = store;

  const closeDialog = () => {
    actions.resetState();
    toggleDialog();
  };

  const onSubmit = () => {
    actions
      .submit({ routeId: route.id, direction: currentDirection })
      .then((response) => {
        appendParamToUrl("direction", currentDirection);
        setRouteAndDirection({ route: response.route, direction: currentDirection });
        setFlashMessage({ message: response.message });
      })
      .catch((err) => {
        const error = get(err.response, "data.message", I18n.t("route.create_return.message.fail"));
        actions.setErrors({ "": error });
      })
      .finally(() => actions.setLoading(false));
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <FormDialogTitle onClose={closeDialog}>
        <Typography variant="h1">{I18n.t("ui.route.details.create_return")}</Typography>
        <Box mt={1}>
          <Typography variant="h2">
            {route ? `${route.name} | ${route.vehicle_type}` : null}
          </Typography>
        </Box>
      </FormDialogTitle>
      <FormDialogContent>
        <AddReturnForm store={store} direction={currentDirection} />
      </FormDialogContent>
      <FormDialogActions onSubmit={onSubmit} onClose={closeDialog} disabled={state.isDisabled} />
    </Dialog>
  );
};

export default AddReturnDialog;
