import React from "react";
import PeriodSelectorRepeat from "components/admin_v2/common/PeriodSelectorRepeat";
import FormDateRange from "components/admin_v2/ui/FormDateRange";
import ErrorList from "components/admin_v2/ui/ErrorList";
import RouteNewForm from "components/admin_v2/students/trip/route/RouteNewForm";
import SchoolStopForm from "components/admin_v2/students/trip/stop/SchoolStopForm";
import AddRouteStopForm from "./AddRouteStopForm";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";

const AddRouteForm = ({ cls, store }) => {
  const [state, actions] = store;
  const endDate = state.period.endDate || state.school?.last_day;

  return (
    <RouteNewForm cls={cls} store={store}>
      <ErrorList errors={state.getTopLevelErrors} />
      <FormDateRange
        startDate={state.period.startDate}
        endDate={endDate}
        errors={state.errors}
        onStartDateChange={(startDate) =>
          actions.setPeriod({ ...state.period, startDate, endDate })
        }
        onEndDateChange={(endDate) => actions.setPeriod({ ...state.period, endDate })}
      />
      <PeriodSelectorRepeat
        value={state.period?.week}
        onChange={(week) => actions.setPeriod({ ...state.period, endDate, week })}
      />
      {isOtherRoute(state.routeType) && (
        <AddRouteStopForm cls={cls} store={store} tripType="direct" />
      )}
      {state.routeType === "regular" &&
        state.tripTypes.map((tripType) => (
          <SchoolStopForm
            key={`add-anchor-${tripType}`}
            cls={cls}
            store={store}
            tripType={tripType}
          />
        ))}
    </RouteNewForm>
  );
};

export default AddRouteForm;
