import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const apiFetchRoutePairs = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`route_pairs?${query}`), { transformData: true });
};

export const fetchRoutesList = async () => {
  const url = apiUrl(`route_pairs/routes_options`);
  return await xhr.get(url, { transformData: true });
};

export const fetchPairedRouteData = async (params) => {
  const query = queryString(params, true);
  const url = apiUrl(`route_pairs/route_data?${query}`);
  return await xhr.get(url, { transformData: true });
};

export const createRoutePair = async (params) => {
  const url = apiUrl(`route_pairs`);
  return await xhr.post(url, { route_pair: params }, { transformData: true });
};

export const removeRoutePair = async (id) => {
  const url = apiUrl(`route_pairs/${id}/delete`);
  return await xhr.post(url);
};

export const saveRoutePair = async (id, params) => {
  const url = apiUrl(`route_pairs/${id}`);
  return await xhr.post(url, { route_pair: params }, { transformData: true });
};
