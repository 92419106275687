import React, { Fragment, useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import { parseISO } from "date-fns";
import { FormControl, FormGroup, Typography, Box, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { findStartDate } from "lib/dates";
import FormDateRange from "components/admin_v2/ui/FormDateRange";
import PeriodSelectorDays from "./PeriodSelectorDays";
import PeriodSelectorRepeat from "./PeriodSelectorRepeat";
import DatePickerWithPeriod from "components/admin_v2/ui/DatePickerWithPeriod";
import { pageRoutes, slugsFor } from "services/api";
import { periodSelectorStore } from "./stores/periodSelectorStore";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import useDidMountEffect from "lib/useDidMountEffect";
import I18n from "utils/i18n.js";
import { PERIOD_DATE_OPTIONS } from "utils/constants";

const PeriodSelector = ({
  // dates
  referenceDate = null, // date that will be used as start/end date if period empty, past dates will be converted to today
  // additional data to query days for date range selector
  route, // id, tripType, routeType, endDate (route end date to set as end default date for range mode for custom route) + tripTypes/stops for custom route, withRepeatWeek/withDateRange/notOccursOn/isNewRoute
  student,
  stopToAdd,
  // to get initial data and callback on change
  period,
  onPeriodChange,
  // UI related toggles
  errors, // related to period selector errors if any on host component submission
  label = true,
  disabled = false,
  changeRequest = false, // if change request or not (difference in how choose pre-select)
  options = {} // options, e.g. withoutOneDay, withoutDays, withoutDaysRefetch
}) => {
  const classes = useStyles();

  const defaultDate = findStartDate(referenceDate); // date to set as default for custom route when switch to one-day mode
  const parsedRouteEndDate = route?.endDate ? parseISO(route.endDate) : null; // date to set as end route date for custom routes
  const store = useLocalStore(() =>
    periodSelectorStore({
      period,
      defaultDate,
      routeEndDate: parsedRouteEndDate,
      notOccursOn: route?.notOccursOn
    })
  );
  const [state, actions] = store;
  const routeType = route?.routeType || "regular";

  useDidMountEffect(() => {
    if (!state.onlyDateChecked && route?.endDate) actions.updateEndDate(parsedRouteEndDate);
  }, [route?.endDate]);

  // need to set multiple days if route isn't running this week
  useDidMountEffect(() => {
    if (route?.notOccursOn) actions.chooseMultipleDays({ routeType, parsedRouteEndDate });
  }, [route?.notOccursOn]);

  useEffect(() => {
    onPeriodChange({
      startDate: state.startDate,
      endDate: state.endDate,
      days: state.selectedDays,
      week: state.week,
      onlyDateChecked: state.onlyDateChecked
    });
  }, [state.startDate, state.endDate, state.selectedDays, state.week]);

  useEffect(() => {
    if (state.onlyDateChecked || options.withoutDays || options.withoutDaysRefetch) return;
    if (route.tripTypes?.length === 0 || route.tripTypes?.length > 1) {
      actions.fetchAllWeekDays({ student, route, changeRequest, disabled });
    } else {
      actions.fetchWeekDays({ stopToAdd, student, route, changeRequest, disabled });
    }
  }, [stopToAdd?.id, student?.id, route?.routeId, route?.tripTypes, state.endDate]);

  const changeOneDay = (e) => {
    if (e.target.value === "true") {
      actions.chooseMultipleDays({
        routeType,
        parsedRouteEndDate,
        withDateRange: route?.withDateRange
      });
    } else {
      actions.chooseOneDay({ routeType, defaultDate });
    }
  };

  const changeDates = (date) => {
    actions.setStartDate(date);
    if (state.onlyDateChecked) actions.setEndDate(date);
  };

  return (
    <Fragment>
      {label && (
        <Typography variant="subtitle1" className={classes.title}>
          <RequiredLabel
            label={I18n.t("ui.form.period.label")}
            condition={!options.required}
            text=""
          />
        </Typography>
      )}
      <FormControl component="fieldset">
        <DatePickerWithPeriod
          selectedDate={state.startDate}
          periodValue={state.onlyDateChecked === null ? null : !state.onlyDateChecked}
          onChangeDate={changeDates}
          onChangePeriod={changeOneDay}
          dateOptionsAvailable={PERIOD_DATE_OPTIONS}
          label={I18n.t(`data_management.assigned_vehicles.form.dates.label`)}
        />
      </FormControl>
      {options.withoutDays && route?.withRepeatWeek && (
        <Box>
          <PeriodSelectorRepeat value={state.week} onChange={actions.setWeek} />
        </Box>
      )}
      {state.onlyDateChecked || state.onlyDateChecked === null || options.withoutDays ? null : (
        <Fragment>
          {!changeRequest && (isOtherRoute(routeType) || route?.withDateRange) && (
            <FormDateRange
              startDate={state.startDate}
              endDate={state.endDate}
              errors={errors}
              onStartDateChange={actions.setStartDate}
              onEndDateChange={actions.updateEndDate}
            />
          )}
          {route?.withRepeatWeek && (
            <PeriodSelectorRepeat value={state.week} onChange={actions.setWeek} />
          )}
          <Typography className={classes.daysTitle}>Apply to the following days:</Typography>
          <FormGroup row className={classes.days}>
            <PeriodSelectorDays disabled={disabled} store={store} />
          </FormGroup>
        </Fragment>
      )}
      {student && (
        <Box my={2}>
          <Link
            href={pageRoutes.student(student.id, slugsFor(student))}
            target="_blank"
            underline="always"
          >
            View full schedule*
          </Link>
        </Box>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.custom.BOLD,
    marginTop: theme.spacing(3)
  },
  daysTitle: {
    marginTop: theme.spacing(3)
  },
  days: {
    display: "flex",
    justifyContent: "space-between"
  },
  striked: {
    textDecoration: "line-through"
  }
}));

export default PeriodSelector;
