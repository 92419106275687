import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import EventSimpleSelect from "components/admin_v2/common/EventSimpleSelect";
import { EventLabel } from "components/admin_v2/common/EventHelpers";
import useTripEditorStyles from "./useTripEditorStyles";
import RouteLink from "./RouteLink";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";

const TripEditorForm = ({
  day,
  trip,
  tripType,
  stop,
  route,
  label,
  children,
  tripDetails = {},
  student = null,
  onEventSelect = null,
  withRouteLink = true,
  withChangeRequestLabel = true
}) => {
  const cls = useTripEditorStyles();
  const { initialEventType, eventType, dropdownEnabled = true } = tripDetails;

  return (
    <Box className={cls.tripInfo}>
      {trip?.change_request && withChangeRequestLabel ? (
        <Box className={cls.yellowBox}>
          <Typography variant="subtitle1" className={cls.label}>
            {`Change request pending | ${trip.change_request.label}`}
          </Typography>
          <Typography variant="subtitle1">{`By: ${trip.change_request.requestor}`}</Typography>
        </Box>
      ) : null}
      <Typography variant="subtitle1" className={cls.label}>
        {label}
      </Typography>
      <Box display="flex" mt={1}>
        <Box mr={1}>
          <Chip className={cls.stopTimeChip} label={stop ? stop.time : "--:--"} />
        </Box>
        <Box mr={1}>
          <EventLabel
            addEvent={initialEventType === "student_add" || trip?.student_add}
            trip={trip}
            eventType={initialEventType}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <RouteLink
            day={day}
            stop={stop}
            route={route}
            student={student}
            withRouteLink={withRouteLink}
          >
            {children}
          </RouteLink>
          <Box>
            {onEventSelect && (
              <EventSimpleSelect
                tripType={tripType}
                eventType={initialEventType}
                chosenEventType={eventType}
                isOtherRoute={isOtherRoute(tripType)}
                startTime={trip?.route?.start_time}
                onChange={onEventSelect}
                schoolId={student.school_id}
                date={day}
                disabled={!dropdownEnabled}
                withActivity={trip?.with_activity}
                events={trip?.events?.data || []}
                changeRequestId={trip?.change_request?.id}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TripEditorForm;
