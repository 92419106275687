import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchPrograms = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`programs?${query}`), { transformData: true });
};

export const fetchProgram = async (id) => {
  return await xhr.get(apiUrl(`programs/${id}`), { transformData: true });
};

export const createProgram = async (params) => {
  const url = apiUrl(`programs`);
  return await xhr.post(url, params, { transformData: true });
};

export const saveProgram = async (id, params) => {
  const url = apiUrl(`programs/${id}`);
  return await xhr.post(url, params, { transformData: true });
};

export const archiveProgram = async (id) => {
  const url = apiUrl(`programs/${id}/archive`);
  return await xhr.post(url, {}, { transformData: true });
};

export const batchArchive = async (ids) => {
  const url = apiUrl(`programs/batch_archive`);
  return await xhr.post(url, { program_ids: ids });
};

export const batchUnarchive = async (ids) => {
  const url = apiUrl(`programs/batch_unarchive`);
  return await xhr.post(url, { program_ids: ids });
};
