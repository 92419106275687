import React, { useEffect } from "react";
import { OutlinedInput } from "@mui/material";
import FormSelect from "components/admin_v2/ui/FormSelect";
import { get } from "lodash";
import { getAnchorType } from "components/admin_v2/students/stores/stopsModel";

const SchoolTimeForm = ({ store, tripType, cls }) => {
  const stopType = getAnchorType(tripType);
  const [state, actions] = store;
  const schoolTimes = state.schoolTimes({ stopType, tripType });
  const selectedSchool = state.selectedSchool({});
  const timeId = get(state.stops, `${tripType}.timeId`);

  useEffect(() => {
    if (selectedSchool?.id) {
      actions.setStopSchoolTime({ timeId: schoolTimes[0]?.id, tripType });
    }
  }, [selectedSchool?.id]);

  if (schoolTimes.length === 1) {
    return (
      <OutlinedInput
        className={cls.fieldInput}
        value={schoolTimes[0]?.name || ""}
        readOnly={true}
        required
      />
    );
  }

  const onChange = (e) => actions.setStopSchoolTime({ timeId: e.target.value, tripType });

  return (
    <FormSelect
      itemType="schoolTime"
      i18Key="stop"
      options={schoolTimes}
      value={timeId}
      onChange={onChange}
      cls={cls}
      clsKeys={["select", "select"]}
      withoutLabel={true}
      required
    />
  );
};

export default SchoolTimeForm;
