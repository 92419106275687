import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const EventChip = withStyles((theme) => ({
  root: {
    color: theme.custom.WHITE,
    fontWeight: theme.custom.SEMI_BOLD,
    borderRadius: theme.spacing(0.6),
    width: (props) => props.width
  }
}))((props) => {
  const cls = useStyles();
  const { type, ...rest } = props;
  return <Chip className={cls[type] || ""} {...rest} />;
});

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: theme.custom.RED
  },
  yellow: {
    backgroundColor: theme.custom.YELLOW
  },
  green: {
    backgroundColor: theme.custom.GREEN
  },
  grey: {
    backgroundColor: theme.custom.MEDIUM_GREY
  },
  outlinedRed: {
    backgroundColor: theme.custom.WHITE,
    border: `1px solid ${theme.custom.RED}`,
    color: theme.custom.RED
  }
}));

export default EventChip;
