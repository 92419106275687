import { action, thunk, computed } from "easy-peasy";
import { first, without } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { addReturnRoute } from "services/apiRoutes";
import { formatTime } from "lib/dates";
import I18n from "utils/i18n.js";

export const defaultState = {
  // status
  loading: false,
  // data
  errors: {},
  time: null
};

const DIRECTIONS = ["to_custom", "from_custom"];

export const getReturnDirection = (direction) => first(without(DIRECTIONS, direction));

export const addReturnStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),

  // calculate if we can submit or not
  isDisabled: computed((state) => {
    if (state.loading) return true;
    return !state.time;
  }),

  resetState: action((state, params = {}) => {
    return { ...defaultState, ...params };
  }),

  submit: thunk((actions, params, h) => {
    const state = h.getState();
    let apiParams = { direction: params.direction };
    actions.setLoading(true);

    try {
      apiParams.time = formatTime(state.time);
    } catch (e) {
      const data = { message: I18n.t("route.errors.dates.invalid") };
      return Promise.reject({ response: { data } });
    }

    return addReturnRoute(params.routeId, apiParams);
  })
});
