import React from "react";
import { FormControlLabel } from "@mui/material";
import { weekDays } from "lib/dates";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";

const PeriodSelectorDays = ({ store, disabled }) => {
  const [state, actions] = store;
  return weekDays.map((day) => (
    <FormControlLabel
      key={day}
      control={
        <GreenCheckbox
          checked={state.isChecked(day)}
          onChange={(e) =>
            actions.updateSelectedDays({ isAdd: e.target.checked, value: e.target.value })
          }
          value={day}
          disabled={disabled || state.isDisabled(day)}
          data-test-id="dayCheckbox"
        />
      }
      label={`${day}${state.isOnOtherRoute(day) ? "*" : ""}`}
    />
  ));
};

export default PeriodSelectorDays;
