import React from "react";
import CheckboxList from "./CheckboxList";

const TransportationTypes = ({ form, errors, options, updateForm }) => {
  const updateTransportationTypes = (value) => {
    updateForm({ transportationTypes: value });
  };

  return (
    <CheckboxList
      name="transportation_type"
      label="Transportation Types"
      options={options.transportationTypes}
      values={form.transportationTypes}
      update={updateTransportationTypes}
      error={errors?.transportationTypes?.[0]}
    />
  );
};

export default TransportationTypes;
