import React, { Fragment } from "react";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";

const ErrorList = ({ errors, prefix }) => {
  const cls = useStyles();

  if (!errors || _.isEmpty(errors)) return null;

  let errs = Object.entries(_.isString(errors) ? { "": errors } : errors);
  if (prefix) {
    const regexp = new RegExp(prefix);
    errs = errs.filter(([key, _v]) => key.match(regexp));
  }

  if (!errs || errs.length === 0) return null;

  const errorMessages = (val) => {
    if (_.isArray(val)) {
      return val.join("\n");
    } else if (_.isObject(val)) {
      return _.values(val).join("\n");
    } else {
      return val;
    }
  };

  return (
    <Fragment>
      <div className={cls.errTitle}>Errors:</div>
      <ul className={cls.errs}>
        {errs.map(([k, val]) => {
          const key = prefix ? k.replace(`${prefix}.`, "") : k;
          const title = key ? `${_.startCase(key)}: ` : "";
          return (
            <li key={prefix ? `${prefix}-err-${key}` : `err-${key}`}>
              {title}
              {errorMessages(val)}
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  errTitle: {
    color: theme.custom.RED
  },
  errs: {
    color: theme.custom.RED,
    margin: theme.spacing(1, 0, 2),
    paddingLeft: theme.spacing(2),
    whiteSpace: "pre-wrap"
  }
}));

export default ErrorList;
