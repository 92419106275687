import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useStoreState } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Box, List, ListItem, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { PrimaryBtn, CancelBtn } from "components/admin_v2/ui/Buttons";
import { format } from "date-fns";
import { map, get } from "lodash";
import { formatHumanDate, formatDateUrl, weekDay, weekDays } from "lib/dates";
import { pageRoutes } from "services/api";
import { getAnchorType } from "components/admin_v2/students/stores/stopsModel";

const SavedStop = ({ store, closeDialog, onRollback }) => {
  const history = useHistory();
  const classes = useStyles();
  const { route, date } = useStoreState((s) => s.routeEditor);
  const state = store[0];

  const goToEditStops = () => {
    closeDialog();
    const path = pageRoutes.routeStops(route.id);
    if (path === history.location.pathname) return;

    history.push(`${path}?date=${formatDateUrl(date)}`);
  };

  const renderSavedStop = (stop, period, schoolTimes) => {
    const oneDayChange = period.endDate === period.startDate ? weekDay(period.endDate) : null;
    const schoolTime = stop.timeId ? schoolTimes.find((t) => t.id === stop.timeId)?.name : "";
    return (
      <Fragment>
        <Typography>{stop.time ? format(stop.time, "HH:mm") : schoolTime}</Typography>
        <Typography>
          {formatHumanDate(period.startDate)}
          {" until "}
          {period.endDate ? formatHumanDate(period.endDate) : "route end date"}
        </Typography>
        <List>
          {weekDays.map((day) => (
            <ListItem key={day} className={classes.daysList}>
              <span className={classes.checkIcon}>
                {(period.days.includes(day) || day === oneDayChange) && (
                  <CheckIcon style={{ fontSize: 18 }} />
                )}
              </span>
              <Typography>{day}</Typography>
            </ListItem>
          ))}
        </List>
      </Fragment>
    );
  };

  const stopsInfo = map(route.directions, (direction) => {
    const stop = get(state.stops, direction.type);
    const period = get(state.periods, direction.type);
    const schoolTimes = state.schoolTimes({
      stopType: getAnchorType(direction.type),
      tripType: direction.type
    });
    return (
      <Box key={`si-${direction.type}`} className={classes.directionInfo}>
        <Typography variant="subtitle1" className={classes.bold}>
          {direction.label}
        </Typography>
        {stop && period && renderSavedStop(stop, period, schoolTimes)}
      </Box>
    );
  });

  return (
    <Fragment>
      <Box className={classes.savedStopTitle}>
        <Typography variant="subtitle1" className={classes.bold}>
          Stop Added to Route
        </Typography>
      </Box>
      <Box className={classes.stopEditor}>
        <Typography variant="subtitle1" className={classes.bold}>
          {state.stopLocation?.address}
        </Typography>
        <Divider variant="fullWidth" className={classes.separator} />
        <Box display="flex">{stopsInfo}</Box>
      </Box>
      <Box>
        <PrimaryBtn onClick={goToEditStops} withMargin>
          Done
        </PrimaryBtn>
        <CancelBtn onClick={onRollback}>Back</CancelBtn>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.custom.BOLD
  },
  savedStopTitle: {
    backgroundColor: theme.custom.GREEN,
    color: theme.custom.WHITE,
    padding: theme.spacing(1),
    height: theme.spacing(5),
    borderRadius: theme.spacing(0.5),
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  directionInfo: {
    flex: 1
  },
  daysList: {
    padding: theme.spacing(0)
  },
  checkIcon: {
    width: theme.spacing(4)
  }
}));

export default SavedStop;
