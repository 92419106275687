import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const searchUsers = async (role, params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`users/${role}/search?${query}`));
};

export const fetchUsers = async (role, params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`users/${role}?${query}`), { transformData: true });
};

export const fetchUser = async (role, id) => {
  return await xhr.get(apiUrl(`users/${role}/${id}`), { transformData: true });
};

export const archiveUser = async (role, id) => {
  const url = apiUrl(`users/${role}/${id}/archive`);
  return await xhr.post(url, {}, { transformData: true });
};

export const resetPasswordUser = async (role, id) => {
  const url = apiUrl(`users/${role}/${id}/reset_password`);
  return await xhr.post(url);
};

export const batchArchive = async (ids, { role }) => {
  const url = apiUrl(`users/${role}/batch_archive`);
  return await xhr.post(url, { user_ids: ids });
};

export const batchUnarchive = async (ids, { role }) => {
  const url = apiUrl(`users/${role}/batch_unarchive`);
  return await xhr.post(url, { user_ids: ids });
};

export const createUser = async (role, params) => {
  return await xhr.post(apiUrl(`users/${role}`), params, { transformData: true });
};

export const saveUser = async (role, id, params) => {
  return await xhr.post(apiUrl(`users/${role}/${id}`), params, { transformData: true });
};

export const fetchGroupOptions = async (userId) => {
  const url = apiUrl(`users/${userId}/group_options`);
  return await xhr.get(url);
};
