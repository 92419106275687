import React, { Fragment } from "react";
import classNames from "classnames";
import { Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Logo from "../../../../assets/images/4mativ-logo-white.svg";
import { useContainerStyles } from "components/admin_v2/useStyles";

const LoginAppbar = (_props) => {
  const cls = useStyles();
  const clsContainer = useContainerStyles();

  const toolbarCls = classNames(clsContainer.mainGutterPadding, [cls.toolbar]);
  return (
    <Fragment>
      <Toolbar className={toolbarCls}>
        <div className={cls.toolbarContainer}>
          <img src={Logo} alt="logo" className={cls.logo} />
        </div>
      </Toolbar>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.custom.DARK_GREY_2,
    height: theme.spacing(18),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2.2),
    paddingBottom: theme.spacing(2.2)
  },
  toolbarContainer: {
    display: "flex",
    maxWidth: theme.custom.CONTAINER_WIDTH,
    margin: "0 auto",
    alignItems: "center"
  },
  logo: {
    width: theme.spacing(14)
  }
}));

export default LoginAppbar;
