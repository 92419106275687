import React, { Fragment, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import startCase from "lodash/startCase";
import { pageRoutes } from "services/api";
import EventChip from "components/admin_v2/ui/EventChip";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { IconCell } from "components/admin_v2/common/EventHelpers";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";

const RequestRow = ({ changeReq, onClick }) => {
  const cls = useStyles();
  const [open, setOpen] = useState(false);
  const urgent = changeReq.urgent;
  const status = changeReq.status;

  const toggleNote = () => {
    setOpen(!open);
  };

  const openReqDialogForChangeReq = (e) => {
    e.preventDefault();
    onClick(changeReq);
  };

  const statusLabel = () => {
    if (urgent) return <EventChip width="100%" type="red" label="Urgent" />;
    if (changeReq.date_resolved)
      return <EventChip width="100%" type="grey" label={changeReq.date_resolved} />;
    if (changeReq.status === "cancelled")
      return <EventChip width="100%" type="grey" label="Cancelled" />;
    return <EventChip width="100%" type="green" label={startCase(status)} />;
  };

  const statusBadge = () => {
    if (changeReq.status === "pending")
      return <AccessTimeIcon className={cls.pendingBadge} fontSize="large" />;
    if (changeReq.status === "rejected") return <EventChip type="grey" label="X" />;
    if (!changeReq.date_resolved && changeReq.event_type === "unenroll")
      return <EventChip type="outlinedRed" label="U" title="Unenroll" />;
    if (!changeReq.date_resolved) return null;
    if (changeReq.start_date != changeReq.end_date) return <EventChip type="green" label="N" />;
    if (EventChip.event_type === "change") return <EventChip type="yellow" label="P" />;
    if (changeReq.event_type === "unenroll") return <EventChip type="red" label="U" />;
    return <EventChip type="red" label="$" />;
  };

  const dropdownArrow = changeReq.requestor_note ? (
    open ? (
      <ArrowDropDownIcon />
    ) : (
      <ArrowRightIcon />
    )
  ) : null;

  return (
    <Fragment>
      <TableRow key={`changeReq-${changeReq.id}`}>
        <InfoCell className={cls.iconCell} onClick={toggleNote}>
          {dropdownArrow}
        </InfoCell>
        <InfoCell>
          <Link
            component="button"
            underline="always"
            onClick={openReqDialogForChangeReq}
            id="ch-req-id"
          >
            {changeReq.id}
          </Link>
        </InfoCell>
        <InfoCell>{changeReq.date_created}</InfoCell>
        <InfoCell>{changeReq.requestor}</InfoCell>
        <InfoCell>{changeReq.school.name}</InfoCell>
        <InfoCell>{changeReq.program}</InfoCell>
        <IconCell className={cls.iconCell}>
          {withoutAccommodation(changeReq.student) ? null : (
            <StudentAccommodationPopup student={changeReq.student} />
          )}
        </IconCell>
        <InfoCell component="th">
          <RouterLink
            to={pageRoutes.student(changeReq.student.id, { schoolSlug: changeReq.school.slug })}
            schoolId={changeReq.school.id}
          >
            {`${changeReq.student.first_name} ${changeReq.student.last_name}`}
          </RouterLink>
        </InfoCell>
        <InfoCell>
          <Typography variant="body2" className={changeReq.urgent ? cls.urgentText : null}>
            {changeReq.effective_date}
          </Typography>
        </InfoCell>
        <InfoCell>{statusBadge()}</InfoCell>
        <InfoCell>{statusLabel()}</InfoCell>
      </TableRow>
      <TableRow>
        <TableCell className={cls.noteCell} colSpan={10} padding="none">
          {open && changeReq.requestor_note ? (
            <Box className={cls.noteBox}>
              <Typography variant="subtitle1">{changeReq.requestor_note}</Typography>
            </Box>
          ) : null}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const InfoCell = withStyles((_theme) => ({
  root: {
    borderBottom: "none",
    borderCollapse: "initial"
  }
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  iconCell: {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    width: theme.spacing(2),
    cursor: "pointer",
    borderBottom: "none"
  },
  noteCell: {
    borderTop: "none",
    width: "100%"
  },
  noteBox: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2, 2, 6),
    backgroundColor: theme.custom.LIGHT_GREY,
    border: `1px ${theme.palette.background.mediumGrey} solid`,
    borderRadius: theme.spacing(0.5)
  },
  urgentText: {
    color: theme.custom.RED
  },
  pendingBadge: {
    color: theme.custom.YELLOW
  },
  typeLabel: {
    borderRadius: theme.spacing(0.5),
    display: "inline-block",
    padding: theme.spacing(0.25, 1),
    textAlign: "center",
    width: theme.spacing(10)
  },
  yellow: {
    border: `solid 1px ${theme.custom.YELLOW}`,
    color: theme.custom.YELLOW
  },
  red: {
    border: `solid 1px ${theme.custom.RED}`,
    color: theme.custom.RED
  }
}));

export default RequestRow;
