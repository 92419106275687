import React, { Fragment } from "react";
import { Link } from "@mui/material";
import { pageRoutes } from "services/api";
import RouterLink from "components/admin_v2/nav/RouterLink";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";
import useStyles from "./useStyles";

const CurbsideStopCell = ({ dayOff, trip, stop, openModeDialogForStudent, EventTableCell }) => {
  const cls = useStyles();
  const isCustom = isOtherRoute(stop?.trip_type || trip?.trip_type);

  return (
    <Fragment>
      <EventTableCell>
        {trip.route ? (
          (!isCustom && dayOff) || trip.route.discarded ? (
            <>
              <div>{stop.location_name}</div>
              {stop.address}
            </>
          ) : (
            <RouterLink to={pageRoutes.route(trip.route.id)} onClick={openModeDialogForStudent}>
              <div>{stop.location_name}</div>
              {stop.address}
            </RouterLink>
          )
        ) : dayOff ? (
          "Unassigned"
        ) : (
          <Link href="#modeChange" onClick={openModeDialogForStudent} underline="hover">
            Unassigned
          </Link>
        )}
      </EventTableCell>
      <EventTableCell className={cls.nowrap}>
        {stop ? stop.time : "N/A"}
        {stop && <span className={"time-zone"}>{trip.route.time_zone_abbr}</span>}
      </EventTableCell>
    </Fragment>
  );
};

export default CurbsideStopCell;
