import React from "react";
import { Dialog, Divider, Typography } from "@mui/material";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import { useLocalStore, useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import I18n from "utils/i18n.js";
import { addTripStore, TRIP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import RouteTypeSelector from "components/admin_v2/students/trip/RouteTypeSelector";
import useStyles from "components/admin_v2/students/trip/useStyles";
import AddRouteForm from "./route/AddRouteForm";
import RouteHeaderSelector from "./route/RouteHeaderSelector";
import { routeWithDate } from "lib/queryString";

const AddRouteDialog = ({ open, toggleDialog }) => {
  const { school, district } = useStoreState((s) => s.app);
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const defaultData = {
    period: {
      startDate: new Date(),
      endDate: school?.last_day || district?.lastDay || null,
      week: 1
    },
    routeType: "regular",
    tripTypes: TRIP_TYPES.regular,
    school,
    schoolId: school?.id,
    day: new Date(),
    workflow: { createRoute: true, stops: {} }
  };
  const routeStore = useLocalStore(() => addTripStore(defaultData), [open]);
  const [state, actions] = routeStore;
  const cls = useStyles();
  let history = useHistory();

  if (!open) return null;

  const closeDialog = () => {
    actions.resetState(defaultData);
    toggleDialog();
  };

  const onSubmit = () => {
    actions
      .submit({ submitType: "route" })
      .then((r) => {
        toggleDialog();
        setFlashMessage({ message: r.message });
        history.push(routeWithDate(r.route.startDate, r.route.id));
      })
      .catch((err) => {
        const errors = get(err.response, "data.errors", {
          base: I18n.t("route.create.message.fail")
        });
        actions.setErrors(errors);
      })
      .finally(() => actions.setLoading(false));
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={closeDialog} scroll="paper">
      <FormDialogTitle onClose={closeDialog}>
        <Typography variant="h1">{I18n.t("ui.route.add")}</Typography>
        <RouteHeaderSelector cls={cls} store={routeStore} />
      </FormDialogTitle>
      <FormDialogContent>
        <RouteTypeSelector cls={cls} store={routeStore} />
        <Divider className={cls.divider} />
        <AddRouteForm cls={cls} store={routeStore} />
      </FormDialogContent>
      <FormDialogActions onSubmit={onSubmit} onClose={closeDialog} disabled={state.isDisabled} />
    </Dialog>
  );
};

export default AddRouteDialog;
