import { action, thunk, computed } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { saveStudentRouteEvent } from "services/apiStudents";
import { getCurbsideFilters, getDailySchedule } from "services/apiCurbside";
import { setFlashMessage } from "services";
import {
  defaultState as changeReqDialogDefaultState,
  changeReqDialogModel
} from "components/admin_v2/queues/stores/changeReqDialogModel";

export const defaultState = {
  // options
  dismissalGroups: [],
  routes: [],
  vehicleTypes: [],
  // filters
  dismissalGroupId: null,
  routeId: null,
  scope: "assigned",
  tripType: "to_school",
  vehicleType: null,
  regularOnly: null,
  changesOnly: null,
  allOther: null,
  // status
  loading: 0,
  refresh: false, // local refresh additional to global one, only for refetch on filters change
  // data
  dayOff: false,
  modeDialogStudent: null,
  modeDialogTripType: null,
  trips: [],
  ...changeReqDialogDefaultState
};

const RESET_PROPS = ["routeId", "dismissalGroupId"];

export const curbsideStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...changeReqDialogModel(initialData),

  typeFiltersValue: computed((state) => {
    if (state.regularOnly) {
      return "regular_only";
    } else if (state.allOther) {
      return "all_other";
    } else {
      return "all";
    }
  }),

  addLoading: action((state) => {
    state.loading += 1;
  }),
  decrLoading: action((state) => {
    state.loading -= 1;
  }),
  isLoading: computed((state) => state.loading !== 0),

  dismissalGroup: computed((state) =>
    state.dismissalGroupId ? find(state.dismissalGroups, ["id", state.dismissalGroupId]) : null
  ),

  isCustomTripType: computed((state) => state.tripType === "custom"),

  setFromRouter: action((state, props = {}) => {
    const defaultProps = pick(defaultState, [
      "dismissalGroupId",
      "routeId",
      "scope",
      "tripType",
      "vehicleType",
      "regularOnly",
      "changesOnly",
      "allOther"
    ]);
    ["dismissalGroupId", "routeId"].forEach((key) => {
      if (props[key]) props[key] = parseInt(props[key]);
    });
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  // triggered when filters useEffect dependencies change
  // if filters include selected vehicleType - refetch trips
  // if filters do not contain selected vehicleType - reset vehicleType
  // which will trigger trips refetch
  fetchFiltersWithTrips: thunk(async (actions, params, h) => {
    const state = h.getState();
    const filterParams = pick(state, ["routeId"]);
    const filters = await getCurbsideFilters({ date: params.date, ...filterParams });

    if (state.vehicleType && !filters.vehicleTypes?.find((vt) => vt.id === state.vehicleType)) {
      // resetting vehicle type will trigger trips refetch
      actions.setVehicleType(null);
    } else {
      // trigger refetching
      actions.setRefresh(!state.refresh);
    }
    // need to set fetched filters always
    actions.setFilters(filters);
  }),

  fetchCurbsideFilters: thunk((actions, params = {}, h) => {
    const stateParams = pick(h.getState(), ["routeId"]);

    return getCurbsideFilters({ ...params, ...stateParams }).then((r) => actions.setFilters(r));
  }),

  fetchTrips: thunk((actions, params = {}, h) => {
    actions.addLoading();
    const stateParams = pick(h.getState(), [
      "page",
      "perPage",
      "dismissalGroupId",
      "routeId",
      "scope",
      "tripType",
      "vehicleType",
      "regularOnly",
      "changesOnly",
      "allOther"
    ]);
    return getDailySchedule({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.decrLoading());
  }),

  reset: action((state) => {
    const defaultProps = pick(defaultState, RESET_PROPS);
    assign(state, defaultProps, enhancePaginationProps({}));
  }),

  setData: action((state, data) => {
    state.dayOff = data.day_off;
    state.trips = data.trips;
    state.total = parseInt(data.total);
  }),

  setFilters: action((state, data) => {
    state.dismissalGroups = data.dismissalGroups;
    state.vehicleTypes = data.vehicleTypes;
    state.routes = data.routes;
  }),

  setModeDialogData: action((state, { student, tripType }) => {
    state.modeDialogStudent = student;
    state.modeDialogTripType = tripType;
  }),

  updateStudentAttendance: thunk((actions, params = {}) => {
    return saveStudentRouteEvent({
      ...params,
      eventType: params.eventType === "ok" ? null : params.eventType
    }).catch((err) => setFlashMessage(err.message));
  })
});
