import React, { useEffect } from "react";
import { without, compact } from "lodash";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import TripsStatusFilter from "components/admin_v2/trips/shared/TripsStatusFilter";
import I18n from "utils/i18n.js";
import { VEHICLE_TYPES } from "utils/constants";

const SEARCH_TYPES = ["Address", "Student", "Route"];

const TripsBoardFilters = ({ tripsStore, updateQueryString, cls }) => {
  const [state, actions] = tripsStore;

  const updateVehicleType = (e) => {
    updateQueryString({ vehicle_type: e.target.value });
  };
  const updateVendorId = (e) => {
    updateQueryString({ vendor_id: e.target.value });
  };
  const updateSearch = (_e, val) => {
    let searchProps = {};

    if (val) {
      searchProps[`${val.type.toLowerCase()}_id`] = val.id;
    }

    if (val) {
      actions[`set${val.type}`](val);
    }

    without(SEARCH_TYPES, val?.type).forEach((t) => (searchProps[`${t.toLowerCase()}_id`] = ""));
    updateQueryString(searchProps);
  };

  // Update search options when trips selection change
  useEffect(() => {
    actions.fetchSearchOptions();
  }, [state.tripsIds.join(",")]);

  // fetch search options if not loaded when clicking on autocomplete input
  useEffect(() => {
    if (!state.optionsLoading || state.searchOptions.length > 0) return;

    actions.fetchSearchOptions();

    return () => actions.setOptionsLoading(false);
  }, [state.optionsLoading]);

  return (
    <Box className={cls.tripsFilters}>
      <Box className={cls.searchInputWrapper}>
        <Autocomplete
          id="avl-trips-search"
          value={state.searchVal}
          onOpen={() => actions.setOptionsLoading(true)}
          options={
            state.searchOptions.length
              ? state.searchOptions
              : compact([state.route, state.student, state.address])
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          onChange={updateSearch}
          loading={state.optionsLoading}
          classes={{ inputRoot: cls.searchField }}
          disabled={state.loading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={I18n.t("trip.avl_labels.search")}
              variant="outlined"
            />
          )}
        />
      </Box>
      <TripsStatusFilter state={state} updateQueryString={updateQueryString} />
      <SelectFilter
        label={<Typography variant="body2">{I18n.t("trip.avl_labels.vehicle_type")}</Typography>}
        value={state.vehicleType}
        onChange={updateVehicleType}
        items={VEHICLE_TYPES}
        width={20}
        mr={0}
        variant="outlined"
        disabled={state.loading}
      />
      <SelectFilter
        label={<Typography variant="body2">{I18n.t("trip.avl_labels.vendor")}</Typography>}
        value={state.vendorId}
        onChange={updateVendorId}
        items={state.vendorOptions}
        width={20}
        mr={0}
        variant="outlined"
        disabled={state.loading}
      />
    </Box>
  );
};

export default TripsBoardFilters;
