import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchTextBlasts = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`text_blasts?${query}`));
};

export const fetchTextBlast = async (id) => {
  return await xhr.get(apiUrl(`text_blasts/${id}`));
};

export const fetchTextBlastMessages = async (id, params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`text_blasts/${id}/messages?${query}`));
};

export const submitTextBlast = async (params) => {
  const url = apiUrl(`text_blasts`);
  return await xhr.post(url, params);
};

export const cancelTextBlast = async (id) => {
  const url = apiUrl(`text_blasts/${id}/cancel`);
  return await xhr.post(url);
};
