import { action, computed } from "easy-peasy";
import { isValid, parseISO } from "date-fns";
import { sortBy, isNumber, omit, pick, cloneDeep, orderBy, compact } from "lodash";
import { easyStateSetters } from "lib/easyState";
import tableSettingStore, { EDIT_MODE } from "components/admin_v2/forms/stores/tableSettingStore";
import { formatDateUrl, parseTime, formatTime } from "lib/dates";
import { getPersistedState, setPersistedState } from "lib/util";
import { createDayDelta, removeDayDelta, updateDayDelta } from "services/apiDayDelta";

export const OFF_TYPE = {
  singleDay: "single_day",
  interval: "interval"
};

export const ADJUSTMENT_TYPE = {
  earlyRelease: "early_release",
  lateStart: "late_start"
};

export const DELTA_TYPE = {
  time: "time",
  delta: "delta"
};

const defaultState = {
  expanded: false,
  allTimes: true,
  timesType: "all",
  viewTimesOpen: false,
  viewIndex: null,
  schoolTimes: []
};

const initDataFromModel = (data) => ({
  ...data,
  time: parseTime(data.displayTime),
  startDate: parseISO(data.startDate),
  endDate: parseISO(data.endDate),
  occurrences: data.occurrences.map((d) => parseISO(d))
});

const daysDeltaStore = (initial = {}) => ({
  ...tableSettingStore({
    entity: { name: "Early Release/Late Start", key: "dayDelta" },
    endPoints: {
      createEntity: createDayDelta,
      removeEntity: removeDayDelta,
      saveEntity: updateDayDelta
    },
    ...omit(initial, ["data", "schoolTimes"]),
    data: initial.data?.map((d) => initDataFromModel(d)) ?? []
  }),
  ...easyStateSetters(defaultState, {
    ...pick(initial, ["schoolTimes"]),
    allTimes: getPersistedState("dayDelta", "allTimes"),
    timesType: getPersistedState("dayDelta", "timesType")
  }),

  getViewDayDelta: computed((state) => {
    const { data, viewIndex } = state;
    if (!isNumber(viewIndex)) return null;
    return viewIndex >= 0 ? data[viewIndex]?.entity : state.newEntity;
  }),

  initData: computed((_state) => (data) => initDataFromModel(data)),

  namesTimes: computed((state) => (idx) => {
    const entity = state.getRow(idx)?.entity || {};
    const stType = entity.adjustmentType === ADJUSTMENT_TYPE.earlyRelease ? "pickup" : "dropoff";
    return sortBy(
      compact(
        state.schoolTimes
          .filter((st) => st.timeType === stType)
          .map((st) => {
            const delta = entity.schoolDeltaTimes.find((d) => d.schoolTimeId === st.id);
            const newTime = delta
              ? new Date(parseTime(st.time).getTime() + delta.delta * 1000)
              : null;
            if (!delta && !state.allTimes) return null;
            return {
              active: !!delta,
              name: st.name,
              originalTime: st.time,
              newTime
            };
          })
      ),
      ["name"]
    );
  }),

  newEntity: computed((_state) => {
    return {
      id: null,
      comment: "",
      adjustmentType: ADJUSTMENT_TYPE.earlyRelease,
      offType: OFF_TYPE.singleDay,
      deltaType: DELTA_TYPE.time,
      startDate: null,
      time: null,
      schoolDeltaTimes: []
    };
  }),

  formData: computed((state) => (idx) => {
    const entity = state.data[idx].entity;
    return {
      ...entity,
      time: formatTime(entity.time),
      startDate: isValid(entity.startDate) ? formatDateUrl(entity.startDate) : null,
      endDate: isValid(entity.endDate) ? formatDateUrl(entity.endDate) : null
    };
  }),

  sortBy: action((state, sortBy) => {
    const { field, order } = sortBy;
    if (field === "delta") {
      state.data = orderBy(
        state.data,
        [
          (d) => d.entity.deltaType,
          (d) => (d.entity.deltaType === DELTA_TYPE.time ? d.entity.time : d.entity.delta)
        ],
        [order, order]
      );
    } else {
      state.data = orderBy(state.data, `entity.${field}`, order);
    }
  }),

  updatePersistedField: action((state, { field, value }) => {
    state[field] = value;
    setPersistedState("dayDelta", field, value);
  }),

  updateData: action((state, { daysDelta, schoolTimes }) => {
    state.schoolTimes = schoolTimes;
    const data = daysDelta.map((d) => initDataFromModel(d));
    state.initialData = cloneDeep(data);
    data.forEach((d) => {
      let entity = state.data.find((sd) => d.id === sd.entity.id);
      if (entity) {
        entity.entity = d;
      } else {
        state.data.push({
          entity: d,
          errors: {},
          editMode: EDIT_MODE.view
        });
      }
    });
  }),

  toggleAllExpanded: action((state) => {
    const expanded = !state.expanded;
    state.expanded = expanded;
    state.data.forEach((d) => {
      d.expanded = expanded;
    });
  }),

  toggleExpanded: action((state, { idx }) => {
    const expanded = !state.data[idx].expanded;
    state.data[idx].expanded = expanded;
    if (
      !state.data.some((d) => d.expanded !== expanded && d.entity.offType === OFF_TYPE.interval)
    ) {
      state.expanded = expanded;
    }
  }),

  toggleViewTimes: action((state, { idx, open }) => {
    state.viewIndex = idx ? parseInt(idx) : null;
    state.viewTimesOpen = open;
  })
});

export default daysDeltaStore;
