import React from "react";
import { compact } from "lodash";
import CustomTrip from "./CustomTrip";
import RegularTrip from "./RegularTrip";
import { useStoreState } from "easy-peasy";

const TripsEditor = ({ modeChangeStore }) => {
  const [state, _actions] = modeChangeStore;
  const { getSchool } = useStoreState((s) => s.app);
  const school = getSchool(state.student.school_id);
  const TripComponent = state.isCustom ? CustomTrip : RegularTrip;
  const trips = () =>
    state.trips.map((trip, idx) => {
      // check if changes were applied and not show those trips
      // make sense only if there were errors during submission as we're submitting multiple events
      if (state.getDetails(idx).completed) return null;
      return (
        <TripComponent
          key={`trip-${idx}`}
          trip={trip}
          idx={idx}
          school={school}
          modeChangeStore={modeChangeStore}
        />
      );
    });

  return compact(trips());
};

export default TripsEditor;
