import { action, computed, thunk } from "easy-peasy";
import { isEqual, cloneDeep, orderBy } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { setFlashMessage } from "services";
import { camelizeKeys } from "humps";
import I18n from "utils/i18n.js";

// this store should be mixed in another stores for table settings.
// includes common options for them:
//
// for example:
//    const myFormStore = useLocalStore((model) => ({
//      // form store fields ...
//      ...tableSettingStore({ data: model?.addresses })
//    }))
// Also store should support
// - computed newEntity to return params for new entity
// - computed formData(idx) to return formData for specific row
// - computed isValid(idx) to check if row is valid
// - endpoints like removeEntity/saveEntity/createEntity

export const EDIT_MODE = {
  view: "view",
  edit: "edit"
};

const defaultState = {
  loading: false,
  noDataAllowed: true,
  listChanged: false,
  // removeEntity/saveEntity/createEntity
  endPoints: {},
  // name of entity
  entity: {},
  // id and type of parentEntity
  parentEntity: {},
  // data
  data: [],
  initialData: []
};

const initStoreFromPros = ({ data, entity, ...rest }) => {
  const initialData = cloneDeep(data);
  const tableData =
    data?.map((d) => {
      return {
        entity: d,
        errors: {},
        editMode: EDIT_MODE.view
      };
    }) ?? [];
  return {
    initialData,
    data: tableData,
    entity,
    ...rest
  };
};

const tableSettingStore = (initial) => ({
  ...easyStateSetters(defaultState, initStoreFromPros(initial)),

  dataPersisted: computed((state) => state.data.filter((d) => d.entity.id)),
  dataNew: computed((state) => state.data.filter((d) => !d.entity.id)),
  // get row by idx
  getRow: computed((state) => (idx) => state.data[idx]),

  // if row has changes or not
  withChanges: computed((state) => (idx) => {
    const data = state.data[idx]?.entity;
    if (!data.id) return true;
    const initialData = state.initialData.find((d) => d.id === data.id);
    return initialData ? !isEqual(initialData, data) : false;
  }),

  isEditMode: computed((state) => (idx) => state.getRow(idx)?.editMode === EDIT_MODE.edit),

  initData: computed((_state) => (data) => data),

  addRow: action((state, _payload) => {
    state.data.push({
      entity: {
        id: null,
        ...state.newEntity
      },
      errors: {},
      editMode: EDIT_MODE.edit
    });
  }),

  cancelChanges: action((state, { idx }) => {
    let data = state.data[idx];
    if (data.entity.id) {
      const initialData = state.initialData.find((d) => d.id === data.entity.id);
      if (initialData) data.entity = cloneDeep(initialData);
      data.editMode = EDIT_MODE.view;
    } else {
      data.entity = { ...state.newEntity };
    }
    data.errors = {};
  }),

  removeData: action((state, idx) => {
    state.data.splice(idx, 1);
  }),

  setErrors: action((state, { idx, errors = {} }) => {
    state.data[idx].errors = errors;
  }),

  sortBy: action((state, sortBy) => {
    const { field, order } = sortBy;
    state.data = orderBy(state.dataPersisted, `entity.${field}`, order).concat(
      orderBy(state.dataNew, `entity.${field}`, order)
    );
  }),

  updateMode: action((state, payload) => {
    const { idx, editMode } = payload;
    if (state.data[idx]) {
      state.data[idx].editMode = editMode;
    }
  }),

  updatePrimary: action((state, payload) => {
    const { idx } = payload;
    state.data.forEach((d, i) => {
      if (d.entity.id) {
        d.entity.primary = i == idx;
        let initialData = state.initialData.find((init) => init.id === d.entity.id);
        if (initialData) initialData.primary = d.entity.primary;
      }
    });
  }),

  updateRowField: action((state, payload) => {
    const { idx, field, value } = payload;
    if (state.data[idx]) {
      state.data[idx].entity[field] = value;
    }
  }),

  updateRow: action((state, { idx, data }) => {
    const newData = state.initData(data);
    if (state.data[idx]) {
      state.data[idx].entity = cloneDeep(newData);
      state.data[idx].errors = {};
      let initialData = state.initialData.find((d) => d.id === newData.id);
      if (!initialData) state.initialData.push(newData);
      else initialData = newData;
    }
  }),

  remove: thunk(async (actions, params = {}, h) => {
    const state = h.getState();
    const { idx } = params;
    const data = state.data[idx].entity;
    if (!state.noDataAllowed && data.id && state.dataPersisted.length <= 1) {
      actions.setErrors({
        idx,
        errors: { editMode: I18n.t("data_management.errors.empty", { name: state.entity.name }) }
      });
      return;
    }
    actions.setLoading(true);
    if (!data.id) {
      actions.removeData(idx);
      actions.setLoading(false);
      return;
    }
    try {
      const resp = await state.endPoints.removeEntity(data.id, {
        parentEntity: state.parentEntity
      });
      actions.removeData(idx);
      actions.setListChanged(!state.listChanged);
      setFlashMessage(resp.message);
    } catch (err) {
      const errors = err.response?.data?.errors || {};
      actions.setErrors({ idx, errors: camelizeKeys(errors) });
      setFlashMessage(
        err.response?.data?.message ||
          I18n.t("data_management.errors.remove", { name: state.entity.name })
      );
      throw err;
    } finally {
      actions.setLoading(false);
    }
  }),

  save: thunk(async (actions, payload, h) => {
    const state = h.getState();
    const { idx } = payload;

    if (!state.isValid(idx)) return Promise.reject();

    actions.setLoading(true);
    actions.setErrors({ idx });
    try {
      const data = state.formData(idx);
      let newData;
      if (data.id) {
        newData = await state.endPoints.saveEntity(data.id, {
          ...data,
          parentEntity: state.parentEntity
        });
      } else {
        newData = await state.endPoints.createEntity({
          ...data,
          parentEntity: state.parentEntity
        });
      }
      actions.updateRow({ idx, data: newData[state.entity.key] });
      actions.updateMode({ idx, editMode: EDIT_MODE.view });
      if (newData[state.entity.key].primary) actions.updatePrimary({ idx });
      actions.setListChanged(!state.listChanged);
      setFlashMessage(newData.message);
      return Promise.resolve(newData);
    } catch (err) {
      const errors = err.response?.data?.errors;
      actions.setErrors({ idx, errors: camelizeKeys(errors) });
      setFlashMessage(
        err.response?.data?.message ||
          I18n.t("data_management.errors.fail", { name: state.entity.name })
      );
      throw err;
    } finally {
      actions.setLoading(false);
    }
  })
});

export default tableSettingStore;
