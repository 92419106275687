import React, { Fragment } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItem,
  List,
  Link,
  Typography
} from "@mui/material";
import { get, keys, map, pull } from "lodash";
import { formatTimeWithTz } from "lib/dates";
import { componentsJoin } from "lib/util";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import I18n from "utils/i18n.js";
import AddressNewForm from "../address/AddressNewForm";
import AddressSchoolForm from "../address/AddressSchoolForm";
import AddressSelect from "../address/AddressSelect";
import AddressStudentForm from "../address/AddressStudentForm";
import { useStoreState } from "easy-peasy";

const STOP_NEW_STATES = {
  school: AddressSchoolForm,
  student: AddressStudentForm,
  new: AddressNewForm,
  choose: AddressSelect
};

const StopNewForm = ({
  store,
  stopType,
  tripType,
  cls,
  disabled = false,
  withoutChoose = false
}) => {
  const [state, actions] = store;
  const { isUserAdmin } = useStoreState((s) => s.app);
  const workflow = state.getWorkflowFor(tripType, stopType, withoutChoose ? "new" : "choose");
  const { errorTime } = state.getStopErrorsFor(tripType, stopType);
  const otherWorkflows = pull(
    keys(STOP_NEW_STATES),
    workflow,
    state.student ? "" : "student",
    withoutChoose ? "choose" : ""
  );

  const onChangeWorkflow = (workflow) => (e) => {
    e.preventDefault();
    if (workflow === "school") {
      actions
        .fetchSchoolsData()
        .then(() => actions.updateSchoolStopNewData({ stopType, tripType, workflow }));
    } else if (workflow === "student" && state.studentAddresses.length === 1) {
      actions.setStopStudent({ stopType, tripType, workflow });
    } else {
      actions.setStop({ stop: {}, stopType, tripType, workflow });
    }
  };

  const onChangeTime = (time) => actions.setStopTime({ stopType, tripType, time });

  const workflowLinks = map(otherWorkflows, (w) => (
    <ListItem key={`wf-${tripType}-${w}`} className={cls.listItem} disableGutters>
      <Link href="#" onClick={onChangeWorkflow(w)} underline="always">
        {I18n.t(`ui.address.${w}`)}
      </Link>
    </ListItem>
  ));

  const renderSeparator = (idx) => (
    <ListItem key={`wf-del-${idx}`} className={cls.listItem}>
      <Typography color="primary">|</Typography>
    </ListItem>
  );

  const AddressComponent = STOP_NEW_STATES[workflow];
  const tzField = state.routable?.type == "School" ? "time_zone_abbr" : "timeZoneAbbr";

  return (
    <Fragment>
      <AddressComponent
        store={store}
        stopType={stopType}
        tripType={tripType}
        cls={cls}
        disabled={disabled}
      />
      {disabled ? (
        <Box mt={2} />
      ) : (
        <List className={cls.list} disablePadding>
          {componentsJoin(workflowLinks, renderSeparator)}
        </List>
      )}
      {(isUserAdmin || disabled) && (
        <FormControl error={!!errorTime} required={!disabled}>
          <FormLabel className={cls.label}>
            {I18n.t(`ui.stop.time.${stopType}`)}
            {state.routable && (
              <span className={"time-zone"}>
                {I18n.t(`ui.stop.time.time_zone`, { tz: state.routable[tzField] })}
              </span>
            )}
          </FormLabel>
          {disabled ? (
            <Box component="span">
              {formatTimeWithTz(
                get(state.stops, `${tripType}.${stopType}.time`, null),
                state.routable?.[tzField]
              )}
            </Box>
          ) : (
            <>
              <TimePickerInput
                dateTime={get(state.stops, `${tripType}.${stopType}.time`, null)}
                onChange={onChangeTime}
                disabled={disabled}
              />
              {errorTime && <FormHelperText>{errorTime}</FormHelperText>}
            </>
          )}
        </FormControl>
      )}
    </Fragment>
  );
};

export default StopNewForm;
