import React, { useEffect, useCallback } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { isSameDay } from "date-fns";
import { isInteger, map } from "lodash";
import { useLocalStore, useStoreActions, useStoreState } from "easy-peasy";
import useSorting from "lib/useSorting";
import { dateOrMondayFromQuery, formatDateUrl } from "lib/dates";
import { parseWithRouter, updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { confirmEvent } from "components/admin_v2/common/EventHelpers";
import Spinner from "components/admin_v2/ui/Spinner";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import StudentModeChangeDialog from "components/admin_v2/mode_editor/StudentModeChangeDialog";
import ChangeRequestDialog from "components/admin_v2/change_requests/ChangeRequestDialog";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import CurbsideRow from "./curbside/CurbsideRow";
import CurbsideFilter from "./curbside/CurbsideFilter";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";
import { curbsideStore } from "./curbside/stores/curbsideStore";
import I18n from "utils/i18n.js";

const REGULAR_COLUMNS = [
  { field: "accommodation", label: "", skipSorting: true },
  { field: "last_name", label: "Last Name" },
  { field: "first_name", label: "First Name" },
  { field: "school", label: "School" },
  { field: "route", label: "Route" },
  { field: "vehicle", label: "Vehicle" },
  { field: "stop", label: "Stop" },
  { field: "time", label: "Time" },
  { field: "grade", label: "Grade" },
  { field: "program", label: "Program" },
  { field: "phone", label: "Phone", skipSorting: true },
  { field: "", label: "", skipSorting: true },
  { field: "status", label: "Change", skipSorting: true }
];

const A_B_COLUMNS = [
  { field: "accommodation", label: "", skipSorting: true },
  { field: "last_name", label: "Last Name" },
  { field: "first_name", label: "First Name" },
  { field: "school", label: "School" },
  { field: "route", label: "Route" },
  { field: "vehicle", label: "Vehicle" },
  { field: "pickup", label: "Start Address" },
  { field: "pickup_time", label: "Time" },
  { field: "dropoff", label: "Destination" },
  { field: "dropoff_time", label: "Time" },
  { field: "grade", label: "Grade" },
  { field: "program", label: "Program" },
  { field: "phone", label: "Phone", skipSorting: true },
  { field: "", label: "", skipSorting: true },
  { field: "status", label: "Change", skipSorting: true }
];

const Curbside = (props) => {
  const { date, refresh } = useStoreState((s) => s.calendar);
  const { district, school, allSchools, filtersReset } = useStoreState((s) => s.app);
  const { setDate, setRefresh } = useStoreActions((s) => s.calendar);
  const { setFlashMessage, onChangeScope, setFiltersReset } = useStoreActions((s) => s.app);
  const { setModeDialogOpen, setChangeTripData, resetChangeTripData } = useStoreActions(
    (s) => s.calendar
  );
  const { sortBy, sortableColumn } = useSorting({ field: "", order: "asc" });
  const [state, actions] = useLocalStore(() => curbsideStore(camelizeLocationSearch(props)));
  const cls = useStyles();
  const updateQueryString = updateWithRouter(props);

  const refetchParams = () => {
    let params = {
      date: formatDateUrl(date),
      sortField: sortBy.field,
      sortOrder: sortBy.order
    };
    if (allSchools) params.showAllSchools = true;

    return params;
  };

  useEffect(() => {
    parseWithRouter(props, {
      date: [
        (queryDate) => {
          const newDate = dateOrMondayFromQuery(queryDate);
          if (date && isSameDay(date, newDate)) {
            return;
          }
          setDate(newDate);
        },
        null
      ]
    });
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  const isDateWillBeUpdated = (props) => {
    if (!date) return true;
    const params = parseWithRouter(props);
    const newDate = dateOrMondayFromQuery(params.date);
    return !isSameDay(date, newDate);
  };

  const resetScopeChanged = useCallback(() => {
    // reset school specific state filters
    actions.reset();
    // set to remove school specific filters from path
    setFiltersReset((r) => !r);
  }, []);

  // remove vehicle type filter from query string if it's not present in the list
  useEffect(() => {
    if (state.vehicleTypes.find((vt) => vt.id === state.vehicleType)) return;

    updateQueryString({ vehicle_type: null });
  }, [state.vehicleTypes]);

  useEffect(() => {
    onChangeScope(resetScopeChanged);
    return () => onChangeScope(null);
  }, []);

  useEffect(() => {
    if (isDateWillBeUpdated(props)) return;

    // this will set refresh to fetch trips
    actions.fetchFiltersWithTrips(refetchParams());
  }, [date, state.routeId, school?.id, district?.id]);

  useEffect(() => {
    if (isDateWillBeUpdated(props)) return;

    actions.fetchTrips(refetchParams());
  }, [
    refresh,
    state.refresh,
    state.tripType,
    state.dismissalGroupId,
    state.scope,
    state.vehicleType,
    state.page,
    state.perPage,
    state.changesOnly,
    state.regularOnly,
    state.allOther,
    allSchools,
    sortBy
  ]);

  // remove school specific filters from search query
  useEffect(() => {
    if (filtersReset) {
      updateQueryString({ route_id: null, dismissal_group_id: null, page: 1 });
      setFiltersReset(false);
    }
  }, [filtersReset]);

  if (!date) return null;

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });
  const updatePerPage = (e) => updateQueryString({ page: 1, per_page: e.target.value });
  const updateDismissalGroup = (e) =>
    updateQueryString({ page: 1, dismissal_group_id: e.target.value });

  const updateStudentAttendance = (trip, currentEvent) => (eventType, eventText) => {
    if (eventType == currentEvent || !confirmEvent({ currentEvent, eventType, eventText })) {
      return;
    }

    if (["change", "remove"].includes(eventType)) {
      openModeDialog(trip, eventType);
      return;
    }

    if (!trip.stop) return;

    actions
      .updateStudentAttendance({
        eventType,
        id: trip.student.id,
        stopId: trip.stop.id,
        tripType: trip.stop.trip_type,
        date: formatDateUrl(date)
      })
      .then((r) => {
        if (r.message) setFlashMessage({ message: r.message, type: r.type });
        setRefresh(!refresh);
      });
  };

  const onModeDialogClose = () => {
    resetChangeTripData();
    setModeDialogOpen(false);
  };

  const onModeDialogSave = () => {
    resetChangeTripData();
    setModeDialogOpen(false);
    setRefresh(!refresh);
  };

  const onChangeReqDialogClose = () => actions.closeReqDialog();

  const onChangeReqDialogSave = () => {
    actions.closeReqDialog();
    setRefresh(!refresh);
  };

  const openChangeReqDialog = (row) => {
    if (row.change_request) actions.openReqDialog(row.change_request);
  };

  const openModeDialog = (trip, eventType = null) => {
    const tripType = trip.stop?.trip_type || trip.trip_type || state.tripType;
    setChangeTripData({
      tripType,
      eventType,
      stopId: trip.stop?.id,
      changeRequestId: isInteger(trip.change_request?.id) ? trip.change_request.id : null
    });
    actions.setModeDialogData({
      student: trip.student,
      tripType
    });
    setModeDialogOpen(true);
  };

  const select = (
    <SelectFilter
      id="dismissal"
      label="All Dismissal Groups"
      value={state.dismissalGroupId}
      onChange={updateDismissalGroup}
      items={state.dismissalGroups}
      variant="outlined"
    />
  );

  const tableHeaders = (state.isCustomTripType ? A_B_COLUMNS : REGULAR_COLUMNS).map(sortableColumn);

  const tableRows = map(state.trips, (trip, idx) => (
    <CurbsideRow
      key={`trip-${idx}`}
      date={date}
      dayOff={state.dayOff}
      trip={trip}
      isCustomTripType={state.isCustomTripType}
      openModeDialog={openModeDialog}
      updateStudentAttendance={updateStudentAttendance}
      openChangeReqDialog={openChangeReqDialog}
    />
  ));

  const createReportBtn = (
    <Button
      disabled
      variant="contained"
      color="primary"
      key="db-report-btn"
      className="top-page-button"
    >
      <Typography variant="body1">Create Report</Typography>
    </Button>
  );

  const actionButtons = [createReportBtn];

  const pageTitle = state.dismissalGroup
    ? `Dismissal Group: ${state.dismissalGroup.name}`
    : "Curbside";

  const shouldSelectSchool = state.trips.length === 0 && !allSchools && !school && !district;

  return (
    <React.Fragment>
      <TopPageLayout title={pageTitle} filters={select} actionButtons={actionButtons} />
      <Paper className={cls.root}>
        <CurbsideFilter state={state} date={date} updateQueryString={updateQueryString} />
        {state.isLoading ? (
          <Spinner />
        ) : (
          <Box>
            <PaginatedTable
              headers={tableHeaders}
              count={state.total}
              perPage={state.perPage}
              page={state.page}
              onPageChange={updatePage}
              onChangePerPage={updatePerPage}
            >
              {tableRows}
            </PaginatedTable>
            {shouldSelectSchool ? (
              <Typography variant="subtitle1" className={cls.empty}>
                {I18n.t("ui.curbside.select_school")}
              </Typography>
            ) : null}
          </Box>
        )}
      </Paper>
      <StudentModeChangeDialog
        onClose={onModeDialogClose}
        onCancel={onModeDialogClose}
        onSave={onModeDialogSave}
        student={state.modeDialogStudent}
        date={date}
        showViewProfile={true}
        isCustom={state.isCustomTripType || isOtherRoute(state.modeDialogTripType)}
      />
      <ChangeRequestDialog
        onClose={onChangeReqDialogClose}
        onCancel={onChangeReqDialogClose}
        onSave={onChangeReqDialogSave}
        reqDialogOpen={state.reqDialogOpen}
        changeReq={state.dialogChangeReq}
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  clearSorting: {
    color: theme.custom.BLUE,
    right: theme.spacing(2),
    textDecoration: "none"
  },
  empty: {
    fontSize: "1.2rem",
    fontWeight: theme.custom.BOLD,
    padding: theme.spacing(4, 0),
    textAlign: "center"
  }
}));

export default Curbside;
