import React, { useState } from "react";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { formatDateUrl } from "lib/dates";
import { fetchStops } from "services/api";
import { fetchRoute } from "services/apiRoutes";
import useDidMountEffect from "lib/useDidMountEffect";
import I18n from "utils/i18n.js";

const RouteStopSelect = ({ store, stopType, tripType, disabled = false }) => {
  const [state, actions] = store;
  const stop = state.getStopFor(tripType, stopType);
  const [resetOptions, setResetOptions] = useState(false);

  useDidMountEffect(() => {
    if ((!state.route?.id && !stop) || (state.route && state.route.id !== stop?.route_id)) {
      setResetOptions(!resetOptions);
    }
  }, [state.route?.id]);

  const onChangeStop = (stop) => {
    actions.setStop({ stop, stopType, tripType });
    if (stop && state.route?.id !== stop.route_id) {
      fetchRoute(stop.route_id, {
        date: formatDateUrl(state.period.startDate),
        with_anchor: true
      }).then((response) => {
        actions.setRoute(response);
        actions.setRouteReset(!state.routeReset);
      });
    }
  };

  return (
    <AutocompleteSelect
      id={`stop-select-${tripType}`}
      getOptions={fetchStops}
      onOptionSelect={onChangeStop}
      placeholder={I18n.t("ui.stop.choose")}
      labelField={"name_route_time"}
      searchParams={{
        date: formatDateUrl(state.period.startDate),
        trip_type: tripType,
        route_id: state.route?.id,
        school_id: state.student?.school_id || state.school?.id
      }}
      defaultValue={stop}
      resetOptions={resetOptions}
      disabled={disabled}
    />
  );
};

export default RouteStopSelect;
