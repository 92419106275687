import React, { useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import RequestRow from "./RequestRow";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import ChangeRequestDialog from "components/admin_v2/change_requests/ChangeRequestDialog";
import useSorting from "lib/useSorting";
import { useStoreActions, useStoreState } from "easy-peasy";

const ChangeRequests = (props) => {
  const [state, actions] = props.queuesStore;
  const { sortBy, sortableColumn } = useSorting();
  const { district, school, filtersReset } = useStoreState((s) => s.app);
  const { onChangeScope, setFiltersReset } = useStoreActions((s) => s.app);

  useEffect(() => {
    actions.fetchChangeRequestsFilters();
  }, [state.refresh, school?.id, district?.id]);

  useEffect(() => {
    actions.fetchChangeRequestsData({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [
    school?.id,
    district?.id,
    state.page,
    state.perPage,
    state.requestorId,
    state.status,
    state.student,
    state.refresh,
    state.to,
    state.from,
    sortBy
  ]);

  const resetState = useCallback(() => {
    // reset school specific state filters
    actions.reset();
    // set to remove school specific filters from path
    setFiltersReset((r) => !r);
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  const updateQueryString = props.updateQueryString;

  const updatePage = (e, newPage) => {
    updateQueryString({ page: newPage + 1 });
  };

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onDialogClose = () => actions.closeReqDialog();

  const onDialogSave = () => {
    actions.closeReqDialog();
    actions.setRefresh(!state.refresh);
  };

  const openDialog = (row) => actions.openReqDialog(row);

  // remove school specific filters from search query
  useEffect(() => {
    if (filtersReset) {
      updateQueryString({ requestor_id: null, student: null, page: 1 });
      setFiltersReset(false);
    }
  }, [filtersReset]);

  const headers = [
    { field: "", label: "", skipSorting: true },
    { field: "id", label: "ID" },
    { field: "created_at", label: "Timestamp" },
    { field: "requestor", label: "Requestor", skipSorting: true },
    { field: "school", label: "School", skipSorting: true },
    { field: "program", label: "Program", skipSorting: true },
    { field: "accommodation", label: "", skipSorting: true },
    { field: "student", label: "Student", skipSorting: true },
    { field: "start_date", label: "Effective Date" },
    { field: "type", label: "Type", skipSorting: true },
    { field: "status", label: "Status", skipSorting: true }
  ].map(sortableColumn);

  return (
    <React.Fragment>
      <Box my={3}>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.changeRequests.map((req) => (
              <RequestRow
                changeReq={req}
                key={req.id}
                onClick={openDialog}
                queuesStore={props.queuesStore}
              />
            ))}
          </PaginatedTable>
        )}
        <ChangeRequestDialog
          onClose={onDialogClose}
          onCancel={onDialogClose}
          onSave={onDialogSave}
          reqDialogOpen={state.reqDialogOpen}
          changeReq={state.dialogChangeReq}
        />
      </Box>
    </React.Fragment>
  );
};

export default ChangeRequests;
