import makeStyles from "@mui/styles/makeStyles";

const useTripEditorStyles = makeStyles((theme) => ({
  separator: {
    marginTop: theme.spacing(2)
  },
  label: {
    fontWeight: theme.custom.BOLD
  },
  tripEditorWrapper: {
    backgroundColor: theme.palette.background.lightGrey,
    borderRadius: theme.spacing(0.6),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tripInfo: {
    padding: theme.spacing(2)
  },
  stopTimeChip: {
    backgroundColor: theme.palette.background.darkGrey,
    color: theme.custom.WHITE,
    fontWeight: theme.custom.SEMI_BOLD,
    borderRadius: theme.spacing(0.6),
    minWidth: "3.75rem"
  },
  undoLink: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: "baseline"
  },
  yellowBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.custom.YELLOW,
    color: theme.custom.WHITE,
    width: "100%",
    borderRadius: theme.spacing(0.5),
    borderColor: theme.custom.YELLOW,
    borderWidth: theme.spacing(0.1),
    borderStyle: "solid",
    fontWeight: theme.custom.BOLD
  },
  changeBox: {
    padding: theme.spacing(2),
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: theme.custom.YELLOW
  },
  note: {
    backgroundColor: theme.custom.WHITE,
    width: "100%"
  },
  newStopInfo: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.custom.WHITE,
    color: theme.custom.YELLOW,
    width: "100%",
    borderRadius: theme.spacing(0.5),
    borderColor: theme.custom.YELLOW,
    borderWidth: theme.spacing(0.1),
    borderStyle: "solid",
    fontWeight: theme.custom.BOLD
  }
}));

export default useTripEditorStyles;
