import React from "react";
import { NavLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, MenuItem } from "@mui/material";
import { TabMenuList } from "components/admin_v2/ui/TabMenuList";
import { kebabCase } from "lodash";

const NavTabs = ({ tabs, location = null }) => {
  const cls = useStyles();

  return (
    <Box className={cls.wrapper}>
      <Typography component="span" variant="subtitle2" className={cls.menuContainer}>
        <TabMenuList>
          {tabs.map(({ label, path, disabled = false, exact = true }) => (
            <MenuItem
              button="true"
              exact={exact}
              key={label}
              component={NavLink}
              to={{ pathname: path, search: location?.search }}
              className={cls.navButton}
              activeClassName={cls.activeNavLink}
              disabled={disabled}
              data-test-id={`nav-tab-${kebabCase(label)}`}
            >
              <Box className={cls.label}>{label}</Box>
            </MenuItem>
          ))}
        </TabMenuList>
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: theme.spacing(7.3),
    maxWidth: theme.custom.CONTAINER_WIDTH,
    marginTop: theme.spacing(-2.5),
    display: "flex",
    padding: theme.spacing(0)
  },
  menuContainer: {
    flex: 1
  },
  label: {
    margin: "auto"
  },
  navButton: {
    minWidth: "9%",
    marginRight: theme.spacing(1),
    height: "100%",
    fontSize: "1rem",
    fontWeight: "bold",
    padding: theme.spacing(0, 2),
    borderTopWidth: theme.spacing(0.7),
    borderTopColor: theme.custom.MEDIUM_GREY_2,
    borderTopStyle: "solid",
    color: theme.custom.MEDIUM_GREY_2,
    "&:hover": {
      borderTopColor: theme.custom.MEDIUM_DARK_GREY,
      color: theme.custom.MEDIUM_DARK_GREY,
      backgroundColor: "transparent"
    }
  },
  activeNavLink: {
    color: theme.custom.DARK_GREY_1,
    paddingTop: theme.spacing(0.3),
    borderTopWidth: theme.spacing(0.7),
    borderTopColor: theme.custom.DARK_GREY_1,
    borderTopStyle: "solid",
    "&:hover": {
      color: theme.custom.DARK_GREY_1,
      borderTopColor: theme.custom.DARK_GREY_1
    }
  }
}));

export default NavTabs;
