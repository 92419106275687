import React, { useState, useEffect } from "react";
import { FormControl, FormLabel } from "@mui/material";
import FormSelect from "components/admin_v2/ui/FormSelect";
import { formatDateUrl } from "lib/dates";
import { fetchStops } from "services/api";
import I18n from "utils/i18n.js";

const CreateModeForm = ({ store, tripType, stopType, cls, children }) => {
  const [state, actions] = store;
  const [options, setOptions] = useState([]);
  const stop = state.getStopFor(tripType, stopType);

  useEffect(() => {
    if (!state.period.startDate) return;

    fetchStops({
      date: formatDateUrl(state.period.startDate),
      trip_type: tripType,
      school_id: state.currentSchoolId,
      non_riding: true
    }).then((stops) => setOptions(stops));
  }, [state.period.startDate]);

  const onChangeStop = (e) => {
    const chosenStop = options.find((s) => s.id === e.target.value);
    actions.setStop({ stop: chosenStop, stopType, tripType });
    actions.setRoute(
      chosenStop?.route_id
        ? { id: chosenStop.route_id, end_date: chosenStop.route_end_date, non_riding_type: true }
        : null
    );
  };

  return (
    <FormControl required fullWidth>
      <FormLabel className={cls.label}>{I18n.t("ui.mode_editor.form.change_mode.label")}</FormLabel>
      <FormSelect
        itemType="nonRiding"
        i18Key="stop"
        options={options}
        value={stop?.id}
        onChange={onChangeStop}
        cls={cls}
        clsKeys={["select", "select"]}
        withoutLabel={true}
        required
      />
      {children}
    </FormControl>
  );
};

export default CreateModeForm;
