import { action, thunk } from "easy-peasy";
import { assign, pick, pull } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { fetchStudents, unenrollStudents, reenrollStudents } from "services/apiStudents";
import { setFlashMessage } from "services";
import I18n from "utils/i18n.js";

export const defaultState = {
  // options
  dismissalGroups: [],
  // filters
  dismissalGroupId: null,
  scope: "active",
  query: null,
  // status
  loading: false,
  unenrolling: false,
  refresh: false
};

export const studentsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["dismissalGroupId", "scope"]);
    if (props.refresh) props.refresh = !state.refresh;
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  batchUnenroll: thunk((actions, _params = {}, h) => {
    const state = h.getState();
    actions.setUnenrolling(true);
    unenrollStudents(state.currentBatchIds)
      .then((r) => {
        const failed = r.failed || [];
        setFlashMessage(
          failed.length > 0
            ? I18n.t("students.unenroll.action.failure", { count: failed.length })
            : I18n.t("students.unenroll.action.success")
        );
        actions.setFromRouter({ refresh: true });
        actions.updateSelectedIds(pull(state.currentBatchIds, failed));
      })
      .catch((r) =>
        setFlashMessage(r.message || I18n.t("students.unenroll.action.failure", { count: "all" }))
      )
      .finally(() => actions.setUnenrolling(false));
  }),

  batchReenroll: thunk((actions, _params = {}, h) => {
    const state = h.getState();
    actions.setLoading(true);
    reenrollStudents(state.currentBatchIds)
      .then((r) => {
        const failed = r.failed || [];
        setFlashMessage(
          failed.length > 0
            ? I18n.t("students.reenroll.action.failure", { count: failed.length })
            : I18n.t("students.reenroll.action.success")
        );
        actions.setFromRouter({ refresh: true });
        actions.updateSelectedIds(pull(state.currentBatchIds, failed));
      })
      .catch((r) =>
        setFlashMessage(r.message || I18n.t("students.reenroll.action.failure", { count: "all" }))
      )
      .finally(() => actions.setLoading(false));
  }),

  fetchStudentsData: thunk((actions, params, h) => {
    actions.setLoading(true);
    const stateParams = pick(h.getState(), [
      "page",
      "perPage",
      "dismissalGroupId",
      "scope",
      "query"
    ]);

    return fetchStudents({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.collection = data.students;
    state.total = parseInt(data.total);
    state.dismissalGroups = data.dismissalGroups;
  })
});
