import React from "react";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { searchStopLocations } from "services/apiStopLocations";
import I18n from "utils/i18n.js";

const AddressSelect = ({ store, stopType, tripType, disabled = false }) => {
  const [state, actions] = store;
  const stop = state.getStopFor(tripType, stopType);

  return (
    <AutocompleteSelect
      getOptions={searchStopLocations}
      onOptionSelect={(stopLocation) =>
        actions.setStopLocation({ stopLocation, stopType, tripType })
      }
      labelField={"name"}
      placeholder={I18n.t("ui.stop.choose")}
      searchParams={{
        school_id: state.currentSchoolId,
        school_ids: state.route?.school_ids,
        only_riding: true
      }}
      defaultValue={stop?.stopLocation}
      disabled={disabled}
      resetOptions={stop?.refresh}
    />
  );
};

export default AddressSelect;
