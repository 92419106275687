import _ from "lodash";

export const errorMessages = (errors, prefix = "") =>
  _.map(errors, (val, key) => {
    const title = _.startCase(key);
    const message = _.isArray(val) ? val[0] : val;
    return _.isObject(message)
      ? errorMessages(message, `${prefix}${title} `)
      : _.isEmpty(title)
      ? message
      : `${title}: ${message}`;
  });

export function validate(config, viewModel) {
  return _.pickBy(
    Object.fromEntries(
      Object.keys(config).map((key) => {
        const validators = [config[key]].flat();

        const error = validators.reduce((error, validator) => {
          if (error) {
            return error;
          }

          switch (validator) {
            case "email":
              return validateEmail(viewModel[key]);
            case "presence":
              return validatePresence(viewModel[key]);
          }
        }, undefined);

        return [key, error];
      })
    )
  );
}

function validateEmail(value) {
  if (/.+@.+/.test(value)) {
    return undefined;
  }

  return "is invalid";
}

function validatePresence(value) {
  if (value.trim()) {
    return undefined;
  }

  return "can't be blank";
}
