import React, { Fragment, useState } from "react";
import { FormControl, FormLabel, FormControlLabel, Box } from "@mui/material";
import { useStoreState } from "easy-peasy";
import { get, isEmpty, kebabCase } from "lodash";
import classNames from "classnames";
import { getAnchorType } from "components/admin_v2/students/stores/stopsModel";
import useDidmountEffect from "lib/useDidMountEffect";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import PeriodSelector from "components/admin_v2/common/PeriodSelector";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import SchoolTimeForm from "./SchoolTimeForm";
import I18n from "utils/i18n.js";

const StopDirectionForm = ({ store, cls, direction }) => {
  const { route, date, isCrossSchool } = useStoreState((s) => s.routeEditor);
  const [state, actions] = store;
  const isChecked = !isEmpty(get(state.stops, direction.type));
  const stopType = getAnchorType(direction.type);
  const isNewSchool = isChecked && state.isSchoolStop && !route.school_ids.includes(state.schoolId);
  const [isSchoolTime, setIsSchoolTime] = useState(false);
  const period = get(state.periods, direction.type);

  useDidmountEffect(() => setIsSchoolTime(false), [state.schoolId]);

  return (
    <Box id={`form-${kebabCase(direction.type)}`}>
      <FormControlLabel
        classes={{ label: cls.bold, root: cls.select }}
        control={
          <GreenCheckbox
            checked={isChecked}
            onChange={() => actions.toggleDirection(direction.type)}
          />
        }
        label={direction.label}
      />
      {isChecked && (
        <Fragment>
          {isNewSchool || isSchoolTime ? (
            <FormControl className={classNames(cls.withTopMargin, cls.time)} required>
              <FormLabel className={cls.label}>
                {I18n.t(`ui.stop.time.${stopType}`)}
                <span>{I18n.t(`ui.stop.time.time_zone`, { tz: route.time_zone_abbr })}</span>
              </FormLabel>
              <SchoolTimeForm store={store} cls={cls} tripType={direction.type} />
            </FormControl>
          ) : (
            <>
              <Box>{I18n.t(`ui.stop.time.time_zone`, { tz: route.time_zone_abbr })}</Box>
              <TimePickerInput
                dateTime={get(state.stops, `${direction.type}.time`)}
                onChange={(time) => actions.setTime({ direction: direction.type, time })}
              />
            </>
          )}
          {state.isSchoolStop && isCrossSchool && !isNewSchool && (
            <Box>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={isSchoolTime}
                    onChange={() => setIsSchoolTime(!isSchoolTime)}
                  />
                }
                label={I18n.t("ui.stop.time.school")}
              />
            </Box>
          )}
          <PeriodSelector
            referenceDate={date}
            period={period}
            route={{
              routeId: route.id,
              routeType: route.route_type,
              endDate: route.end_date,
              tripType: direction.type,
              notOccursOn: route.not_occurs_on
            }}
            onPeriodChange={(period) => actions.setPeriod({ direction: direction.type, period })}
            options={{ withoutDaysRefetch: period?.days.length > 0 }}
          />
        </Fragment>
      )}
    </Box>
  );
};

export default StopDirectionForm;
