// custom message-bus adapter
// should be invoked once for the pack

import "./message-bus";
import "./message-bus-ajax";

MessageBus.enableChunkedEncoding = false;

// how often do you want the callback to fire in ms
MessageBus.callbackInterval = appConf?.pollingInterval ?? 15 * 1000;

MessageBus.start(); // call once at startup

// enable globally
window.MessageBus = MessageBus;
