import { action, thunk, computed } from "easy-peasy";
import { compact, forEach, isEmpty, get, set, values, omit, map } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { setFlashMessage } from "services";
import { addStop, deleteStop } from "services/api";
import { fetchSchoolsData } from "services/apiSchools";
import { formatDateUrl, formatTime } from "lib/dates";
import { isValid } from "date-fns";
import { stopsSchoolModel } from "components/admin_v2/students/stores/stopsSchoolModel";
import I18n from "utils/i18n.js";

export const defaultState = {
  // status
  loading: false,
  // state
  workflow: { newLocation: false, schoolStop: false },
  schoolId: null, // selected school ids for new stops, make sense only for xs routes
  routableSchools: [],
  stopSaved: false,
  // data
  errors: {},
  stopLocation: null,
  stops: {},
  periods: {}
};

export const stopsToParams = (stops, periods) => {
  let stopsParams = [];
  forEach(stops, (v, k) => {
    if (!v) return;
    const period = periods[k];
    const endDate = !period.endDate || period.endDate === "" ? "" : formatDateUrl(period.endDate);
    const stopParams = {
      startDate: formatDateUrl(period.startDate),
      endDate,
      daysOfWeek: period.days,
      tripType: v.tripType,
      time: v.time ? formatTime(v.time) : null,
      timeId: v.timeId
    };

    stopsParams.push(stopParams);
  });
  return stopsParams;
};

export const addStopStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...stopsSchoolModel(),

  // calculate if we can submit or not
  isDisabled: computed((state) => {
    if (state.loading) return true;
    if (!state.stopLocation || isEmpty(state.stops)) return true;
    if (state.periods && values(state.periods).some((p) => p.onlyDateChecked === null)) return true;
    return values(state.stops).some((s) => s && !s.timeId && !isValid(s.time));
  }),

  isSchoolStop: computed((state) => state.workflow.schoolStop && state.stopLocation?.masterId),

  selectedSchool: computed((state) => (_params) => {
    if (!state.schoolId) return null;
    return state.routableSchools.find((s) => s.id === state.schoolId);
  }),

  resetState: action((state, params = {}) => {
    return { ...defaultState, ...params };
  }),

  setSchoolStopLocation: action((state, { id }) => {
    const stopLocation = state.routableSchoolsAddresses.find((a) => a.id === id);
    const schoolId =
      state.routableSchools.find((s) => map(s.addresses, "id").includes(id))?.id || null;
    if (state.schoolId !== schoolId) {
      state.schoolId = schoolId;
      compact(values(state.stops)).forEach((s) => {
        s.time = null;
        s.timeId = null;
      });
    }
    state.stopLocation = { masterId: id, ...omit(stopLocation || {}, "id") };
  }),

  setStopSchoolTime: action((state, { tripType, timeId }) => {
    set(state.stops, `${tripType}.timeId`, timeId);
  }),

  setPeriod: action((state, { direction, period }) => {
    set(state.periods, direction, period);
  }),

  setTime: action((state, { direction, time }) => {
    set(state.stops, `${direction}.time`, time);
  }),

  submit: thunk((actions, params, h) => {
    const state = h.getState();
    const stopLocation = { ...state.stopLocation };
    if (!stopLocation.school_id) {
      stopLocation.schoolId = state.schoolId;
    }
    let apiParams = { stopLocation, ...params };

    actions.setLoading(true);

    try {
      apiParams.stops = stopsToParams(state.stops, state.periods);
    } catch (e) {
      const data = { message: I18n.t("route.errors.dates.invalid") };
      return Promise.reject({ response: { data } });
    }

    return addStop(apiParams);
  }),

  rollback: thunk((actions, params, h) => {
    const state = h.getState();
    const apiParams = { stops: stopsToParams(state.stops, state.periods), ...params };

    actions.setLoading(true);
    return deleteStop(apiParams);
  }),

  toggleDirection: action((state, direction) => {
    if (get(state.stops, direction)) {
      set(state.stops, direction, null);
    } else {
      set(state.stops, direction, {
        tripType: direction,
        time: null,
        timeId: null
      });
    }
  }),

  toggleLocation: action((state) => {
    state.stopLocation = null;
    state.schoolId = null;
    state.workflow = { newLocation: !state.workflow.newLocation, schoolStop: false };
  }),

  toggleSchoolStop: action((state) => {
    state.stopLocation = null;
    state.schoolId = null;
    state.workflow = { newLocation: true, schoolStop: !state.workflow.schoolStop };
    compact(values(state.stops)).forEach((s) => {
      s.time = null;
      s.timeId = null;
    });
  }),

  fetchSchoolsData: thunk((actions, params = {}) => {
    const { route } = params;
    const options =
      route.routable_type === "district"
        ? { districtId: route.routable_id, schoolId: null }
        : { districtId: null, schoolId: route.routable_id };
    return fetchSchoolsData(options)
      .then((data) => actions.setRoutableSchools(data.schools))
      .catch((err) => setFlashMessage(err.message));
  })
});
