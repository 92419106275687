import React, { useMemo } from "react";
import { Box, TableRow, TableCell, Tooltip } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import UpdateIcon from "@mui/icons-material/Update";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useStoreState } from "easy-peasy";
import classNames from "classnames";
import { daysOfWeek, formatDateUrl } from "lib/dates";
import useStyles from "./useStyles";
import I18n from "utils/i18n.js";

const DayOffsRow = ({ withoutSelection = false }) => {
  const cls = useStyles();
  const { date, withDaysOffOrAdjustment, isDayOff, isAdjustedSchedule, route, direction } =
    useStoreState((s) => s.routeEditor);
  const weekDays = useMemo(() => daysOfWeek(date), [date]);
  if (!withDaysOffOrAdjustment) return null;

  const hasPair =
    (direction === "to_school" && route.inbound_pair) ||
    (direction === "from_school" && route.outbound_pair);
  const weekDaysCells = weekDays.map((weekDay) => {
    const day = formatDateUrl(weekDay);
    const clsCell = classNames(cls.cell, {
      [cls.selectedCol]: !withoutSelection && formatDateUrl(date) === day
    });
    const dayOff = isDayOff(day);
    const adjustedSchedule = isAdjustedSchedule(day);

    const clsIcon = classNames(cls.dayIcon, {
      [cls.dayOff]: dayOff,
      [cls.dayAdjusted]: !dayOff && adjustedSchedule,
      [cls.dayWithPair]: hasPair
    });
    const key = dayOff ? "day_off" : "adjusted_schedule";
    return (
      <TableCell key={`doff-${weekDay}`} scope="row" align="center" className={clsCell}>
        {dayOff || adjustedSchedule ? (
          <Box className={clsIcon}>
            <Tooltip title={I18n.t(`ui.${key}.tooltip.table`)} placement="bottom" arrow>
              <Box className={cls.dayIcon}>
                {dayOff ? <EventBusyIcon /> : <UpdateIcon />}
                <Box ml={1}>{I18n.t(`ui.${key}.table`)}</Box>
              </Box>
            </Tooltip>
            {hasPair && (
              <Tooltip title={I18n.t("ui.paired_warning.tooltip")} placement="top" arrow>
                <InsertLinkIcon fontSize="small" />
              </Tooltip>
            )}
          </Box>
        ) : null}
      </TableCell>
    );
  });

  return (
    <TableRow>
      <TableCell />
      {weekDaysCells}
    </TableRow>
  );
};

export default DayOffsRow;
