import React, { useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Dialog from "@mui/material/Dialog";
import { Link } from "react-router-dom";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import { pageRoutes, slugsFor } from "services/api";
import RouterLink from "components/admin_v2/nav/RouterLink";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import Spinner from "components/admin_v2/ui/Spinner";
import SearchInput from "components/admin_v2/ui/SearchInput";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import useDebounce from "lib/useDebounce";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import EventChip from "components/admin_v2/ui/EventChip";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import { useFilterStyles, ScopeButtons } from "components/admin_v2/common/FilterHelpers";
import { studentsStore } from "./students/stores/studentsStore.jsx";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import { IconCell } from "components/admin_v2/common/EventHelpers";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";

const SCOPE_OPTIONS = [
  ["active", "Active"],
  ["archived", "Unenrolled"]
];

const Students = (props) => {
  const cls = useFilterStyles();
  const cpCls = useCommonPageStyles();
  const { isUserAdmin, district, school, readOnly } = useStoreState((s) => s.app);
  const { onChangeScope } = useStoreActions((s) => s.app);
  const [state, actions] = useLocalStore(() => studentsStore(camelizeLocationSearch(props)));
  const { sortBy, sortableColumn } = useSorting();
  const searchInput = useDebounce(state.query);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchStudentsData({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [
    searchInput,
    district?.id,
    school?.id,
    state.refresh,
    state.page,
    state.perPage,
    state.dismissalGroupId,
    state.scope,
    sortBy
  ]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  const resetState = useCallback(() => {
    props.history.push({ pathname: "", search: "" });
    actions.setFromRouter({ refresh: true });
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const onSelectDismissal = (e) =>
    updateQueryString({ page: 1, dismissal_group_id: e.target.value });

  const headers = [
    isUserAdmin
      ? {
          field: "batchCheck",
          label: (
            <GreenCheckbox
              checked={state.allSelected}
              onChange={(e) => actions.updateAllSelected(e.target.checked)}
            />
          ),
          skipSorting: true
        }
      : null,
    { field: "id", label: "ID" },
    { field: "accommodation", label: "", skipSorting: true },
    { field: "last_name", label: "Last Name" },
    { field: "first_name", label: "First Name" },
    { field: "school", label: "School" },
    { field: "rider_type", label: "Rider Type" },
    { field: "grade", label: "Grade", skipSorting: true },
    { field: "program", label: "Program" },
    { field: "dismissal_group", label: "Dismissal Group" },
    { field: "sis_id", label: "SIS Id" },
    { field: "created_at", label: "Created" },
    { field: "_event", label: "", skipSorting: true }
  ]
    .filter(Boolean)
    .map(sortableColumn);

  const row = (student) => (
    <TableRow key={`student-${student.id}`}>
      {isUserAdmin && (
        <TableCell component="th" style={{ width: "5%" }}>
          <GreenCheckbox
            checked={state.isSelected(student.id)}
            onChange={(e) => actions.updateSelectedIds({ isAdd: e.target.checked, id: student.id })}
          />
        </TableCell>
      )}
      <TableCell component="th">
        <RouterLink to={pageRoutes.student(student.id, slugsFor(student))}>{student.id}</RouterLink>
      </TableCell>
      <IconCell>
        {withoutAccommodation(student) ? null : <StudentAccommodationPopup student={student} />}
      </IconCell>
      <TableCell>{student.lastName}</TableCell>
      <TableCell>{student.firstName}</TableCell>
      <TableCell>{student.schoolName}</TableCell>
      <TableCell>{I18n.t(`student.rider_type.${student.riderType}`)}</TableCell>
      <TableCell align="center">{student.grade}</TableCell>
      <TableCell>{student.program}</TableCell>
      <TableCell>{student.dismissalGroup?.name}</TableCell>
      <TableCell>{student.sisId}</TableCell>
      <TableCell>{student.createdAt}</TableCell>
      <TableCell className={cls.small}>
        {student.unenrollPending ? (
          <EventChip type="red" label="U" title="Peding unenroll" />
        ) : null}
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <Box className={cpCls.btnsWrapper}>
        {!readOnly && (
          <Link className={cpCls.actionBtn} to={pageRoutes.studentNew()}>
            <OutlinedBtn>
              <Typography variant="subtitle1">{I18n.t("students.new_student")}</Typography>
            </OutlinedBtn>
          </Link>
        )}
        {isUserAdmin && (
          <>
            <OutlinedBtn
              className={cpCls.actionBtn}
              onClick={state.scope === "active" ? actions.batchUnenroll : actions.batchReenroll}
              disabled={state.currentBatchIds.length === 0}
            >
              <Typography variant="subtitle1">
                {I18n.t(`students.${state.scope === "active" ? "unenroll" : "reenroll"}.selected`)}
              </Typography>
            </OutlinedBtn>
            <OutlinedBtn className={cpCls.actionBtn} disabled={true}>
              <Typography variant="subtitle1">{I18n.t("students.create_report")}</Typography>
            </OutlinedBtn>
          </>
        )}
      </Box>
      <Box my={3}>
        <Paper className={cls.paper}>
          <Box className={cls.options}>
            <Box>
              <ScopeButtons
                options={SCOPE_OPTIONS}
                currentScope={state.scope}
                onChange={updateQueryString}
              />
            </Box>
            <Box className={cls.center}>
              <SearchInput cls value={state.query} onChange={onSearch} />
            </Box>
            <Box className={cls.floatRight}>
              <SelectFilter
                id="dismissal"
                label="All Dismissal Groups"
                value={state.dismissalGroupId}
                onChange={onSelectDismissal}
                items={state.dismissalGroups}
              />
            </Box>
          </Box>

          {state.loading ? (
            <Spinner />
          ) : (
            <PaginatedTable
              headers={headers}
              count={state.total}
              perPage={state.perPage}
              page={state.page}
              onPageChange={updatePage}
              onChangePerPage={updatePerPage}
            >
              {state.collection.map(row)}
            </PaginatedTable>
          )}
        </Paper>
        {state.unenrolling && (
          <Dialog open={true} maxWidth="md">
            <Typography variant="h2" style={{ textAlign: "center" }}>
              {I18n.t("students.unenroll.processing.title")}
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "center" }}>
              {I18n.t("students.unenroll.processing.subtitle")}
            </Typography>
            <Spinner />
          </Dialog>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Students;
