import { action, thunk, computed } from "easy-peasy";
import { parseISO } from "date-fns";
import { keys, pickBy, remove, isEmpty, sortBy } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { searchAssignedStops } from "services/apiRoutes";
import { fetchStudentsList, updateStopSchedule } from "services/apiStops";
import { saveQuickStudentEvent } from "services/apiStudents";
import { formatDateUrl } from "lib/dates";
import { setFlashMessage } from "services";

export const defaultState = {
  // status
  loading: false,
  // state
  studentAddOpen: false,
  // data
  errors: {},
  events: [],
  students: [], // shaped as { student, period }
  stop: null,
  // data for add student
  period: null,
  routeToCancel: null,
  student: null,
  stopToCancel: null
};

const selectedEventFor = (s) => keys(pickBy(s.events))[0] || (s.change_request ? "change_req" : "");

const defaultPeriodFor = (stop) => {
  const stopDate = parseISO(stop.date);
  return { startDate: stopDate, endDate: stopDate, days: [] };
};

// NOTE: as we're removing ability to change current assignments code can be cleaned,
// keep it almost without changes to simplify current change + in case if they'll want
// to return this back
export const addStudentStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),

  // disable if loading, no changes or add student dialog is open (student is not null)
  isDisabled: computed((state) => state.loading || !!state.student || isEmpty(state.students)),

  addStudentToStop: action((state) => {
    const { student, period, stopToCancel } = state;
    if (student) state.students.push({ student, period, stopToCancel });
  }),

  quickStudentEvent: thunk((actions, params, h) => {
    const state = h.getState();
    actions.setLoading(true);
    const eventParams = {
      date: state.period.startDate,
      tripType: state.stop.stop.trip_type,
      stop_id: state.stop.stop.id,
      eventType: params.eventType
    };

    saveQuickStudentEvent(params.student.id, { event: eventParams }).then((r) => {
      setFlashMessage(r.message);
      actions.fetchStudents(state.stop);
    });
  }),

  checkAssignedStops: thunk((actions, params) => {
    const { route, stop, student } = params;
    if (!student) {
      actions.resetStudent();
      return;
    }

    searchAssignedStops(route.id, student.id, {
      date: stop.date,
      stop_id: stop.stop.id
    }).then((response) => actions.setStudentData(response));
  }),

  removeStudent: action((state, student) => {
    remove(state.students, (data) => data.student.id === student.id);
  }),

  resetState: action((state, params = {}) => {
    return { ...defaultState, ...params };
  }),

  resetStudent: action((state, withToggleAddStudent = false) => {
    state.period = defaultPeriodFor(state.stop);
    state.student = null;
    state.stopToCancel = null;
    state.routeToCancel = null;
    state.errors = {};
    if (withToggleAddStudent) state.studentAddOpen = !state.studentAddOpen;
  }),

  saveChanges: thunk((actions, params, h) => {
    actions.setLoading(true);
    const state = h.getState();
    const stop = state.stop.stop;
    const stops = state.students.map((data) => {
      const endDate = data.period.endDate === "" ? "" : formatDateUrl(data.period.endDate);
      return {
        studentId: data.student.id,
        schoolId: data.student.schoolId || data.student.school_id,
        stops: [
          {
            routeId: stop.route_id,
            fromStopId: data.stopToCancel?.id, // to create change for other trips
            stopId: stop.id,
            tripType: stop.trip_type,
            startDate: formatDateUrl(data.period.startDate),
            endDate,
            daysOfWeek: data.period.days
          }
        ],
        events: []
      };
    });

    return updateStopSchedule(stop.id, {
      date: state.stop.date,
      // data for update_stop_events
      events: [],
      // data for override_student_stops
      stops
    });
  }),

  setEventsFor: action((state, { stop, data }) => {
    state.period = defaultPeriodFor(stop);
    state.stop = stop;

    state.events = sortBy(
      data.add_events
        .map((s) => ({
          addEvent: true,
          changeRequest: s.change_request,
          eventType: keys(pickBy(s.events))[0] || "student_add",
          selectedEvent: selectedEventFor(s),
          events: s.events,
          student: s.student,
          has_one_off: s.has_one_off
        }))
        .concat(
          data.students.map((s) => ({
            addEvent: false,
            changeRequest: s.change_request,
            eventType: keys(pickBy(s.events))[0] || "",
            events: s.events,
            student: s.student,
            first_day: s.first_day
          }))
        ),
      (s) => s.student?.last_name
    );
  }),

  setStudentData: action((state, data) => {
    state.student = data.student;
    state.stopToCancel = data.stop;
    state.routeToCancel = data.route;
    state.errors = {};
  }),

  fetchStudents: thunk((actions, stop, _h) => {
    actions.setLoading(true);
    return fetchStudentsList(stop.stop.id, { date: stop.date })
      .then((data) => actions.setEventsFor({ stop, data }))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  })
});
