import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";

const StudentUnenrolled = ({ student }) => {
  const { name, unenrolled_at } = student;
  const cls = useStyles();

  return (
    <>
      {name}
      <span className={cls.badge} title={unenrolled_at ? `Unenrolled date: ${unenrolled_at}` : ""}>
        {I18n.t("students.unenrolled")}
      </span>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {
    border: `1px solid ${theme.custom.DARK_RED}`,
    borderRadius: theme.spacing(0.5),
    color: theme.custom.DARK_RED,
    fontSize: "0.6em",
    fontWeight: theme.custom.SEMI_BOLD,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.25, 2)
  }
}));

export default StudentUnenrolled;
