import React, { useRef, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";

const OverflowTooltip = ({ children, text, wrapperCls, boardState = null }) => {
  const cls = useStyles();
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    setIsOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth);
  }, [boardState]);

  const title = <span className={cls.tooltipTitle}>{text}</span>;

  return (
    <Tooltip
      title={title}
      disableHoverListener={!isOverflowed}
      placement="right"
      arrow={true}
      className={cls.tooltip}
    >
      <div ref={textRef} className={`${cls.childrenWrapper} ${wrapperCls}`}>
        {children}
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles((_theme) => ({
  tooltipTitle: {
    fontSize: "0.85rem"
  },
  childrenWrapper: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default OverflowTooltip;
