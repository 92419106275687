import React from "react";
import { Dialog, Typography, Box } from "@mui/material";
import { useStoreState } from "easy-peasy";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import FormSelect from "components/admin_v2/ui/FormSelect";
import FileUpload from "components/admin_v2/ui/FileUpload";
import I18n from "utils/i18n.js";

const AddStudentImportDialog = ({ store, cls, open, toggleDialog }) => {
  const { schools } = useStoreState((s) => s.app);
  const [state, actions] = store;

  const closeDialog = () => {
    actions.setErrors({});
    toggleDialog();
  };

  const handleChange = (e) => {
    actions.setNewImport({ ...state.newImport, importableId: e.target.value });
  };

  const onFileSelect = (e) => {
    actions.setNewImport({ ...state.newImport, files: e.target.files[0] });
  };

  const onSubmit = () => {
    actions.submit();
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <Box className="add-student-import-form">
        <FormDialogTitle onClose={closeDialog}>
          <Typography variant="h1">{I18n.t("data_management.new_student_import.title")}</Typography>
        </FormDialogTitle>
        <FormDialogContent className={cls.dialogCont}>
          <Box className={cls.formSection}>
            <FormSelect
              itemType="school"
              options={schools}
              value={state.newImport.importableId}
              onChange={handleChange}
              cls={cls}
              clsKeys={["fullSelect", "fullSelect"]}
              error={state.errors?.importable}
              required
            />
          </Box>
          <FileUpload
            itemType="file"
            accept=".csv"
            file={state.newImport.files}
            onChange={onFileSelect}
            error={state.errors?.files}
            required
          />
        </FormDialogContent>
        <FormDialogActions
          onSubmit={onSubmit}
          onClose={closeDialog}
          disabled={state.submitDisabled}
          submitLabel={I18n.t("data_management.imports.form.submit")}
        />
      </Box>
    </Dialog>
  );
};

export default AddStudentImportDialog;
