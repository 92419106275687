import React from "react";
import withStyles from "@mui/styles/withStyles";
import { DialogTitle, DialogContent, DialogActions, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CancelBtn, PrimaryBtn } from "./Buttons";
import I18n from "utils/i18n.js";

export const FormDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle className={classes.root} component="div" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const FormDialogContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightGrey,
    borderRadius: "5px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2)
  }
}))((props) => {
  const { children, className, ...other } = props;
  return (
    <DialogContent className={className} {...other}>
      {children}
    </DialogContent>
  );
});

export const FormDialogActions = withStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(1),
    justifyContent: "flex-start"
  }
}))((props) => {
  const { children, classes, onSubmit, onClose, disabled, submitLabel, ...other } = props;
  return (
    <DialogActions className={classes.root} {...other}>
      {children}
      <PrimaryBtn onClick={onSubmit} disabled={disabled} data-test-id="submit">
        {submitLabel || I18n.t("ui.btn.save_and_close")}
      </PrimaryBtn>
      <CancelBtn onClick={onClose} data-test-id="cancel-dialog">
        {I18n.t("ui.btn.cancel")}
      </CancelBtn>
    </DialogActions>
  );
});

export const FormDialogActionsBothSides = withStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(1),
    justifyContent: "space-between"
  }
}))((props) => {
  const { children, classes, onSubmit, onClose, disabled, submitLabel, ...other } = props;
  return (
    <DialogActions className={classes.root} {...other}>
      <Box>
        <PrimaryBtn onClick={onSubmit} disabled={disabled}>
          {submitLabel || I18n.t("ui.btn.save_and_close")}
        </PrimaryBtn>
        <CancelBtn onClick={onClose} sx={{ ml: 1 }}>
          {I18n.t("ui.btn.cancel")}
        </CancelBtn>
      </Box>
      {children}
    </DialogActions>
  );
});
