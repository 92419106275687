import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import { snakeCase } from "lodash";
import cn from "classnames";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import I18n from "utils/i18n.js";
import { vehiclesStore } from "components/admin_v2/vendor_config/stores/vehiclesStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DETAIL_FIELDS = [
  "vendor",
  "name",
  "licensePlate",
  "capacity",
  "icabbiId",
  "vehicleType",
  "trackingDevice",
  "createdAt",
  "updatedAt"
];

const Vehicle = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => vehiclesStore());
  const id = props.match.params.id;
  const { vehicle } = state;

  useEffect(() => {
    if (!id) return;

    actions.fetchVehicle(id);
  }, [id]);

  const detail = (prop) => {
    return (
      <Box className={cls.detail} key={prop} data-test-id="detail">
        <div className={cls.detailHeader}>
          {I18n.t(`vendor_config.vehicle.labels.${snakeCase(prop)}`)}
        </div>
        <div>{vehicle[prop] || "--"}</div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      <BreadCrumbs
        path={pageRoutes.vehicles()}
        label={I18n.t("vendor_config.vehicles.breadcrumb")}
      />
      {vehicle && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {vehicle.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <RouterLink className={cls.actionBtn} to={pageRoutes.vehicleEdit(vehicle.id)}>
              <OutlinedBtn>{I18n.t("vendor_config.edit_vehicle.btn")}</OutlinedBtn>
            </RouterLink>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
              {vehicle.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
            </OutlinedBtn>
          </Box>
        </Box>
      )}
      <Paper className={cls.paper}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={cls.subTitle}>{I18n.t("vendor_config.vehicle.title.details")}</Box>
            <Grid
              container
              className={cn("vehicle-details", cls.grid)}
              spacing={5}
              data-test-id="vehicle-details"
            >
              <Grid item xs={6}>
                {vehicle && DETAIL_FIELDS.map(detail)}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default Vehicle;
