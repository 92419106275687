import React from "react";
import cn from "classnames";
import Grid from "@mui/material/Grid";
import CarIcon from "../../../../assets/images/vehicles/icon-car-students.svg";
import CabIcon from "../../../../assets/images/vehicles/icon-cab-students.svg";
import VanIcon from "../../../../assets/images/vehicles/icon-van-students.svg";
import BusIcon from "../../../../assets/images/vehicles/icon-school-bus-students.svg";
import NumberChip from "../ui/NumberChip";

const ICONS = {
  cab: CabIcon,
  bus: BusIcon,
  van: VanIcon,
  _default: CarIcon
};

const VehicleDivider = ({ trip, cls, etaStatus }) => {
  const iconComponent = ICONS[trip.vehicle_type] || ICONS._default;

  return (
    <Grid container className={cls.stopsItem}>
      <Grid item className={cls.leftChip}>
        <NumberChip className={cn(cls.vehicleChip, cls[etaStatus], "statusChip")}>
          <img src={iconComponent} height={20} width={20} />
        </NumberChip>
      </Grid>
    </Grid>
  );
};

export default VehicleDivider;
