import React from "react";
import { Button } from "@mui/material";
import { AddCircle, ExpandMore, ExpandLess } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import I18n from "utils/i18n.js";

export const AddTripButton = ({ setAddTripDialogOpen, addTripDialogOpen, disabled = false }) => {
  const cls = useStyles();
  return (
    <Button
      className={cls.btn}
      size="small"
      onClick={() => setAddTripDialogOpen(!addTripDialogOpen)}
      disabled={disabled}
      fullWidth
    >
      <AddCircle className={cls.btnIcon} color="primary" fontSize="medium" />
      <b>{I18n.t("ui.student.weekly.add_trip")}</b>
    </Button>
  );
};

export const DropdownTrips = ({ setOpenExtraTrips, openExtraTrips, countTrips }) => {
  const cls = useStyles();
  return (
    <Button
      className={cls.btn}
      color="primary"
      size="small"
      startIcon={openExtraTrips ? <ExpandLess /> : <ExpandMore />}
      onClick={() => setOpenExtraTrips(!openExtraTrips)}
      fullWidth
    >
      {I18n.t(`ui.student.weekly.${openExtraTrips ? "hide" : "show"}_trip`, { count: countTrips })}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  btn: {
    paddingLeft: 0,
    justifyContent: "start"
  },
  btnIcon: {
    marginRight: theme.spacing(0.5)
  }
}));
