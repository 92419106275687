import React, { Fragment } from "react";
import { TableCell } from "@mui/material";
import classNames from "classnames";
import { EventLabel } from "components/admin_v2/common/EventHelpers";
import StudentTableRow from "./StudentTableRow";
import EventSimpleSelect from "components/admin_v2/common/EventSimpleSelect";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import useStyles from "./useStyles";

const NO_ACTION_TYPES = ["cancel", "change"];

const StudentRow = ({ idx, store, route }) => {
  const cls = useStyles();
  const [state, actions] = store;
  const stopStudent = state.events[idx];
  const quickCancelPPU =
    !route.non_riding_type &&
    !NO_ACTION_TYPES.includes(stopStudent.eventType) &&
    !stopStudent.addEvent;
  const { student, eventType, addEvent } = stopStudent;

  return (
    <Fragment>
      <StudentTableRow student={student} eventType={eventType} day={state.period?.startDate}>
        {!quickCancelPPU && (
          <TableCell align="right" className={classNames(cls.cell, cls.labelsCell)}>
            <EventLabel addEvent={addEvent} trip={stopStudent} eventType={eventType} />
          </TableCell>
        )}
        <TableCell align="right" className={cls.cell} colSpan={quickCancelPPU ? 2 : 1}>
          {quickCancelPPU && (
            <>
              <PrimaryBtn
                minWidth={13}
                withMargin
                onClick={() => actions.quickStudentEvent({ ...stopStudent, eventType: "ppu" })}
                data-test-id="ppu-student-btn"
              >
                PPU
              </PrimaryBtn>
              <PrimaryBtn
                minWidth={13}
                onClick={() => actions.quickStudentEvent({ ...stopStudent, eventType: "cancel" })}
                data-test-id="cancel-student-btn"
              >
                Cancel
              </PrimaryBtn>
            </>
          )}
          <EventSimpleSelect
            eventType={stopStudent.eventType}
            disabled={true}
            isTextVersion={true}
            dayOff={stopStudent.student.day_off}
            events={stopStudent.events.data}
            changeRequestId={stopStudent.changeRequest}
          />
        </TableCell>
      </StudentTableRow>
    </Fragment>
  );
};

export default StudentRow;
