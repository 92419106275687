import React, { useEffect, Fragment } from "react";
import {
  Dialog,
  Typography,
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  OutlinedInput,
  Divider
} from "@mui/material";
import { useStoreState } from "easy-peasy";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import { commaErrors } from "lib/util";
import FormSelect from "components/admin_v2/ui/FormSelect";
import I18n from "utils/i18n.js";

const AddStopLocationDialog = ({ cls, open, store, toggleDialog }) => {
  const { school, schools } = useStoreState((s) => s.app);
  const [state, actions] = store;
  const newStop = state.stopLocationForm;

  useEffect(() => {
    if (schools.length === 0) return;

    actions.setStopLocationForm({ ...state.stopLocationForm, schoolId: school?.id });
  }, [school, schools]);

  const closeDialog = () => {
    actions.setErrors({});
    toggleDialog();
  };

  const handleChange = (field) => (e) => {
    actions.setStopLocationForm({ ...newStop, [field]: e.target.value });
  };

  const handleAddressChange = (field) => (e) => {
    actions.setStopLocationForm({
      ...newStop,
      addressAttributes: { ...newStop.addressAttributes, [field]: e.target.value }
    });
  };

  const onSubmit = () => {
    actions.submit();
  };

  const addressFields = [
    { name: "address", required: true, type: "text" },
    { name: "lat", required: false, type: "number" },
    { name: "lng", required: false, type: "number" }
  ];

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <Fragment>
        <FormDialogTitle onClose={closeDialog}>
          <Typography variant="h1">{I18n.t("data_management.new_stop_location.title")}</Typography>
        </FormDialogTitle>
        <FormDialogContent className={cls.dialogCont}>
          <Box className={cls.formSection}>
            <FormSelect
              itemType="school"
              options={schools}
              value={newStop.schoolId}
              onChange={handleChange("schoolId")}
              cls={cls}
              clsKeys={["fullSelect", "fullSelect"]}
              error={commaErrors(state.errors?.school)}
              required
            />
          </Box>
          <Box className={cls.formSection}>
            <FormControl fullWidth required error={!!state.errors?.name}>
              <FormLabel className={cls.label}>
                {I18n.t("data_management.stop_locations.form.name.label")}
              </FormLabel>
              <OutlinedInput
                className={cls.textField}
                value={newStop.name || ""}
                onChange={handleChange("name")}
                placeholder={I18n.t("data_management.stop_locations.form.name.empty")}
                required
              />
              {state.errors?.name && (
                <FormHelperText>{commaErrors(state.errors?.name)}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Divider variant="fullWidth" className={cls.formDivider} />
          {addressFields.map(({ name, required, type }) => (
            <Box className={cls.formSection} key={name}>
              <FormControl
                fullWidth
                required={required}
                error={!!state.errors?.[`address.${name}`]}
              >
                <FormLabel className={cls.label}>
                  {I18n.t(`data_management.stop_locations.form.${name}.label`)}
                </FormLabel>
                <OutlinedInput
                  className={cls.textField}
                  value={newStop.addressAttributes[name] || ""}
                  onChange={handleAddressChange(name)}
                  placeholder={I18n.t(`data_management.stop_locations.form.${name}.empty`)}
                  type={type}
                />
                {state.errors?.[`address.${name}`] && (
                  <FormHelperText>{commaErrors(state.errors?.[`address.${name}`])}</FormHelperText>
                )}
              </FormControl>
            </Box>
          ))}
        </FormDialogContent>
        <FormDialogActions
          onSubmit={onSubmit}
          onClose={closeDialog}
          disabled={state.submitDisabled}
          submitLabel={I18n.t("data_management.stop_locations.form.submit")}
        />
      </Fragment>
    </Dialog>
  );
};

export default AddStopLocationDialog;
