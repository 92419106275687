import React, { Fragment, useEffect, useMemo } from "react";
import {
  Box,
  Link,
  FormControl,
  FormHelperText,
  FormLabel,
  Divider,
  OutlinedInput,
  TextField,
  RadioGroup,
  FormControlLabel
} from "@mui/material";
import { omit } from "lodash";
import FormSelect from "components/admin_v2/ui/FormSelect";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import SchoolCode from "components/admin_v2/ui/SchoolCode";
import usePrefixedInput from "lib/usePrefixedInput";

const RIDE_TYPES = ["students", "delivery"];
const NON_RIDING_ROUTE_LABELS = ["distance", "ppu", "walk", "carpool", "transit"];

const RouteNewForm = ({
  cls,
  store,
  children,
  withRouteTypeChoice = true,
  updateTripDetails = null,
  tripIdx = null
}) => {
  const [state, actions] = store;
  const routeName = useDebounce(state.route?.name);
  const code = useMemo(() => state.routable?.code, [state.currentSchoolId, state.districtId]);

  useEffect(() => {
    actions.fetchRouteOptions();
    actions.fetchRouteNames();
  }, [code]);

  useEffect(() => {
    if (routeName?.length > 0 && state.routeNames.find((r) => routeName === r.name)) {
      actions.setErrors({ ...state.errors, name: I18n.t("ui.errors.taken") });
    } else {
      actions.setErrors(omit(state.errors, ["name"]));
    }
  }, [routeName]);

  const onChange = (field) => (e) => {
    const val = field === "looping" ? e.target.checked : e.target.value;
    actions.setRouteField({ [field]: val });
  };

  const onVendorScheduleChange = (field) => (e) =>
    actions.setVendorScheduleField({ [field]: e.target.value });

  const errors = state.errors;

  const rideTypesRadio = RIDE_TYPES.map((type) => (
    <FormControlLabel
      value={type}
      key={`ride-type-${type}`}
      control={<GreenRadio />}
      label={I18n.t(`ui.route.riders_type.${type}`)}
      className={cls.radioInput}
      disabled={type === "delivery" && state.isCrossSchool}
    />
  ));

  const [routeNameVal, updateRouteName] = usePrefixedInput({
    value: state.route?.name,
    prefix: code,
    update: (name) => actions.setRouteField({ name })
  });

  const onChangeWorkflow = (newMode, e) => {
    e.preventDefault();
    actions.setChangeMode(newMode);
    updateTripDetails({ idx: tripIdx, changeMode: newMode });
  };

  return (
    <Fragment>
      {withRouteTypeChoice && (
        <FormControl className={cls.field} component="fieldset" error={!!state.errors.base}>
          <FormLabel className={cls.label}>{I18n.t("ui.route.route_type.label")}</FormLabel>
          <RadioGroup value={state.route.ridersType} onChange={onChange("ridersType")} row>
            {rideTypesRadio}
          </RadioGroup>
        </FormControl>
      )}
      <FormControl fullWidth required error={!!errors.name}>
        <Box className={cls.oneoffLabelLink}>
          <FormLabel className={cls.label}>{I18n.t("ui.route.name.label")}</FormLabel>
          {state.changeMode === "create_one_off_route" && (
            <Link
              href="#"
              onClick={(e) => onChangeWorkflow("change_to_existing_oneoff_stop", e)}
              underline="always"
            >
              {I18n.t("ui.route.choose_existing_oneoff")}
            </Link>
          )}
        </Box>
        <OutlinedInput
          name="route-name-input"
          className={cls.fieldInput}
          value={routeNameVal}
          onChange={updateRouteName}
          placeholder={I18n.t("ui.route.name.placeholder")}
          startAdornment={<SchoolCode code={code} />}
          required
        />
        {errors?.name && <FormHelperText>{errors?.name}</FormHelperText>}
      </FormControl>
      {children}
      <Divider className={cls.divider} />
      <FormSelect
        itemType="vehicleType"
        i18Key="route"
        options={state.shapedVehicleTypes.filter(
          (type) => !NON_RIDING_ROUTE_LABELS.includes(type.name.toLowerCase())
        )}
        value={state.route.vehicleType}
        onChange={onChange("vehicleType")}
        cls={cls}
        error={errors.vehicleType}
        required
      />
      <FormSelect
        itemType="vendor"
        i18Key="route"
        options={state.options.transportationVendors.filter(
          (vendor) => !NON_RIDING_ROUTE_LABELS.includes(vendor.name.toLowerCase())
        )}
        value={state.vendorSchedule.transportationVendorId}
        onChange={onVendorScheduleChange("transportationVendorId")}
        cls={cls}
        error={errors.transportationVendorId}
        required
      />
      <Divider className={cls.divider} />
      <FormControl fullWidth>
        <FormLabel className={cls.label}>{I18n.t("ui.route.comment.label")}</FormLabel>
        <TextField
          variant="outlined"
          multiline
          rows={2}
          className={cls.comment}
          onChange={onChange("comment")}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormControlLabel
          control={<GreenCheckbox checked={state.route.looping} onChange={onChange("looping")} />}
          className={cls.label}
          label={I18n.t("ui.route.looping.label")}
        />
      </FormControl>
    </Fragment>
  );
};

export default RouteNewForm;
