import React, { Fragment } from "react";
import { useStoreState } from "easy-peasy";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import CommsGroupForm from "./CommsGroupForm";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";

const CommsGroupNew = (_props) => {
  const { school } = useStoreState((s) => s.app);

  const newCommsGroup = { schoolId: school?.id, avlNotifications: false, replyType: "one_to_one" };

  return (
    <Fragment>
      <TopPageLayout title={I18n.t("school_config.new_comms_group.title")} />
      <SchoolConfigTabs />
      <BreadCrumbs
        path={pageRoutes.commsGroups()}
        label={I18n.t("school_config.comms_groups.breadcrumb")}
      />
      <CommsGroupForm group={newCommsGroup} />
    </Fragment>
  );
};

export default CommsGroupNew;
