import React, { Fragment } from "react";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import StaffForm from "./StaffForm";
import I18n from "utils/i18n.js";
import { pageRoutes } from "services/api";

const StaffNew = (_props) => {
  const newUser = {
    systemUser: false,
    communicationAccess: false,
    schoolUsers: []
  };

  return (
    <Fragment>
      <TopPageLayout title={I18n.t("school_config.new_staff.title")} />
      <SchoolConfigTabs />

      <BreadCrumbs path={pageRoutes.staffs()} label={I18n.t("school_config.staffs.breadcrumb")} />
      <StaffForm user={newUser} />
    </Fragment>
  );
};

export default StaffNew;
