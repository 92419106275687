export const getReturnStopType = (stopType) => (stopType === "dropoff" ? "pickup" : "dropoff");

const getPickupTime = (trip) => {
  if (trip.events.change_add) return trip.raw_time;
  return trip.anchor.anchor_types.indexOf("dropoff") > -1 ? trip.raw_time : trip.anchor.raw_time;
};

export const compareCustomTripByTime = (a, b) => {
  const aTime = getPickupTime(a);
  const bTime = getPickupTime(b);
  if (aTime === bTime) return 0;
  return aTime > bTime ? 1 : -1;
};
