import { action, computed, thunk } from "easy-peasy";
import _ from "lodash";
import { easyStateSetters } from "lib/easyState";
import { formatTime, formatDateUrl } from "lib/dates";
import { saveChangeRequests } from "services/apiChangeRequests";
import { fetchRouteOptions, fetchRoutesList, saveRoute } from "services/apiRoutes";
import { assignStudent } from "services/apiStudents";
import { setFlashMessage } from "services";
import { getReturnStopType } from "../common";
import { getAnchorType, stopsModel, isOtherRoute } from "./stopsModel";
import store from "context/admin_v2/store";
import I18n from "utils/i18n.js";

export const defaultState = {
  // options for new route
  options: {
    drivers: [],
    vehicles: [],
    vehicleTypes: [],
    transportationVendors: []
  },
  routeNames: [],
  // dialog data
  day: null,
  school: null,
  student: null,
  nonResetFields: [],
  // options for school stops
  routableSchools: [],
  // dialog status
  loading: false,
  disabled: false,
  // service states (in use to reset autocomplete)
  routeReset: false,
  // dialog state
  anchor: "dropoff",
  changeMode: null,
  returnTrip: false,
  routeType: "custom",
  routableType: "school",
  districtId: null,
  schoolId: null,
  rideType: "students",
  errors: {},
  // data
  // period
  period: {
    startDate: null,
    endDate: null,
    days: []
  },
  // route, shaped like this depends on chosen workflow (id for existing, other for new route)
  // const ROUTE_DEFAULT = {
  //   id: null,
  //   name: null,
  //   vehicleType: "",
  //   driver null,
  //   vehicle: null
  // };
  vendorSchedule: { transportationVendorId: null },
  route: { ridersType: "students", looping: false },
  // stops, hash by tripType/stopType, shaped like this depends on chosen workflow
  // (id for existing, other for new stops)
  // const STOP_DEFAULT = {
  //   id: null,
  //   schoolAddress: false,
  //   masterId: null,
  //   stopLocation: {
  //     id: null,
  //     address: null
  //   },
  //   time: null,
  //   timeId: null (in use in regular routes anchors only),
  //   waitTime: 0 (in use in regular routes anchors only),
  // };
  stops: {},
  // trip types
  tripTypes: [],
  // workflows: select/new route, stops: select/new stops, addresses: select/new/school
  workflow: { createRoute: false, stops: {} }
};

// errors that will be processed on sublevels
const PROCESSING_ERRORS = [
  "name",
  "direct",
  "return",
  "toCustom",
  "fromCustom",
  "toSchool",
  "fromSchool",
  "startDate",
  "endDate"
];

export const STOP_TYPES = {
  pickup: "pickup",
  dropoff: "dropoff"
};

export const TRIP_TYPES = {
  regular: ["to_school", "from_school"],
  custom: ["to_custom", "from_custom"],
  activity: ["to_custom", "from_custom"]
};

export const ROUTABLE_TYPES = {
  singleSchool: "school",
  crossSchool: "district"
};

const REGULAR_ANCHOR_PATHS = ["to_school.dropoff", "from_school.pickup"];

const toISODate = (date) => (date ? formatDateUrl(date) : date);

export const addTripStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...stopsModel(),

  getTopLevelErrors: computed((state) =>
    _.get(state.errors, "route", _.omit(state.errors, PROCESSING_ERRORS))
  ),
  routable: computed((state) => {
    if (state.isCrossSchool) {
      return state.districtId ? store.getState().app.getDistrict(state.districtId) : null;
    }
    return state.currentSchoolId ? store.getState().app.getSchool(state.currentSchoolId) : null;
  }),
  shapedVehicleTypes: computed((state) =>
    _.map(state.options.vehicleTypes, (v) => ({ id: v, name: v }))
  ),
  stopToAdd: computed((state) =>
    state.route.id ? _.first(_.values(_.get(state.stops, state.tripTypes[0], {}))) : null
  ),
  // calculate if we can submit or not
  isDisabled: computed((state) => {
    if (state.loading) return true;
    if (state.period.onlyDateChecked === null) return true;
    if (state.route.id && state.tripTypes.length === 0) return true;
    if (_.keys(state.stops).length < state.tripTypes.length) return true;
    const newRouteParams =
      state.route.name && state.vendorSchedule.transportationVendorId && state.route.vehicleType;
    if (!(state.route.id || newRouteParams)) return true;
    // check that all regular route anchors & times are set
    if (state.workflow.createRoute && state.routeType === "regular") {
      if (
        _.find(REGULAR_ANCHOR_PATHS, (path) => {
          const stop = _.get(state.stops, path);
          return !((stop.timeId || stop.time) && stop.masterId);
        })
      )
        return true;
      // check that both pickup & dropoff stops are selected for each trip type
      if (
        state.tripTypes.find((tripType) => {
          const values = _.values(state.stops[tripType]);
          if (
            values.length < _.keys(state.workflow.stops[tripType]).length ||
            values.find((v) => {
              if (
                !v.stopLocation ||
                !v.stopLocation.address ||
                v.stopLocation.address.length === 0 ||
                !(v.timeId || v.time)
              )
                return true;
            })
          )
            return true;
        })
      )
        return true;
    }
    return false;
  }),
  // calculate if we can create change request or not
  isChangeDisabled: computed((state) => {
    if (state.loading) return true;
    if (state.route.id && state.tripTypes.length === 0) return true;
    if (state.tripTypes.length !== 1) return true;
    if (_.keys(state.stops).length < state.tripTypes.length) return true;
    const stops = _.values(state.stops[state.tripTypes[0]]);
    if (state.route.id && stops.find((s) => !s?.id)) return true;
    if (
      !state.route.id &&
      stops.find(
        (s) => !s || !(s.id || s.stopLocation?.id || s.masterId || s.stopLocation?.address)
      )
    )
      return true;
    return false;
  }),

  fetchRouteOptions: thunk((actions) => {
    return fetchRouteOptions({ transformData: true })
      .then((options) => actions.setOptions(options))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  fetchRouteNames: thunk((actions, params = {}, h) => {
    const state = h.getState();
    const options = state.isCrossSchool
      ? { districtId: state.districtId, schoolId: null }
      : { districtId: null, schoolId: state.currentSchoolId };
    return fetchRoutesList({ ...params, ...options, name_only: true })
      .then((routes) => {
        actions.setRouteNames(routes);
      })
      .catch((err) => setFlashMessage(err.message));
  }),

  resetState: action((state, params = {}) => {
    // need to set default route direction in case route has only one direction,
    // in use at RouteStopForm
    if (params.route?.id && params.route.directions.length === 1) {
      params.tripTypes = [params.route.directions[0].type];
    }
    // need to set tripTypes in case of regular route creation
    if (
      _.get(params, "workflow.createRoute", state.workflow.createRoute) &&
      state.routeType === "regular"
    ) {
      params.tripTypes = TRIP_TYPES.regular;
    }
    // get non reset data (additional to default)
    const stateParams = _.pick(state, state.nonResetFields);
    // if we're setting route at resetState, set routeType as route.routeType
    if (params.route?.id) {
      stateParams.routeType = params.route.route_type || params.route.routeType || state.routeType;
    }
    _.assign(
      state,
      defaultState,
      // params that should stay intact during reset unless they're overriden by params
      {
        day: state.day,
        school: state.school,
        student: state.student,
        nonResetFields: state.nonResetFields,
        options: state.options,
        period: { startDate: state.day, endDate: state.day }
      },
      // custom params that should stay intact
      stateParams,
      // params that we're overwriting
      params
    );
  }),

  setPeriodEndDate: action((state, date) => {
    _.set(state.period, "endDate", date);
  }),

  setRouteField: action((state, params) => {
    state.route = { ...state.route, ...params };
  }),

  setVendorScheduleField: action((state, params) => {
    state.vendorSchedule = { ...state.vendorSchedule, ...params };
  }),

  setTripType: action((state, { tripType, checked }) => {
    if (checked) {
      state.tripTypes = _.uniq(state.tripTypes.concat(tripType));
    } else {
      _.pull(state.tripTypes, tripType);
      state.stops[tripType] = {};
      state.workflow.stops[tripType] = {};
    }
  }),

  // switch anchor on route creation
  switchAnchor: action((state, newAnchorType) => {
    state.anchor = newAnchorType;
    state.workflow.stops.direct = _.mapKeys(state.workflow.stops.direct || {}, () => newAnchorType);
    state.stops.direct = _.mapKeys(state.stops.direct || {}, () => newAnchorType);
  }),

  submit: thunk((actions, params = {}, h) => {
    actions.setLoading(true);
    const state = h.getState();
    let tripTypes =
      state.workflow.createRoute && isOtherRoute(state.routeType) && !params.keepTripType
        ? ["direct"]
        : state.tripTypes;
    let stateParams = _.cloneDeep(
      _.pick(state, [
        "period",
        "routeType",
        "routableType",
        "route",
        "anchor",
        "stops",
        "vendorSchedule"
      ])
    );
    if (state.workflow.createRoute && state.returnTrip) {
      tripTypes = tripTypes.concat("return");
      _.forEach(_.get(stateParams.stops, "return", {}), (v, k) => {
        const directStop = _.get(stateParams.stops, `direct.${getReturnStopType(k)}`);
        if (directStop) {
          _.set(v, "masterId", directStop.masterId);
          _.set(v, "schoolAddress", directStop.schoolAddress);
          _.set(v, "stopLocation", directStop.stopLocation);
        }
      });
    }
    try {
      _.forEach(stateParams.stops, (stops, _k) =>
        _.forEach(stops, (v, _k) => {
          if (_.isDate(v.time) && !v.id) v.time = formatTime(v.time);
        })
      );
      stateParams.period.startDate = toISODate(state.period.startDate);
      stateParams.period.endDate = toISODate(state.period.endDate);
      // PeriodSelector component gives back empty end date if till end of the route was chosen for regular routes
      // fixing that behaviour here
      if (!stateParams.period.endDate && state.routeType === "regular" && state.route.id) {
        stateParams.period.endDate = toISODate(state.route.end_date);
      }
    } catch (e) {
      const data = { errors: { base: I18n.t("route.errors.dates.invalid") } };
      return Promise.reject({ response: { data } });
    }

    const schoolId = state.student?.school_id || state.school?.id;

    if (params.submitType === "route") {
      return saveRoute(
        {
          ...stateParams,
          tripTypes,
          schoolId: state.schoolId || schoolId,
          districtId: state.districtId,
          withAnchor: params.withAnchor
        },
        { transformData: params.withAnchor ? false : true }
      );
    }
    if (params.submitType === "change") {
      return saveChangeRequests({
        ...stateParams,
        tripTypes,
        studentId: state.student.id,
        tripId: params.tripId,
        eventTypes: params.eventTypes || {},
        note: params.note,
        schoolId
      });
    }
    return assignStudent({
      ...stateParams,
      tripTypes,
      id: state.student.id,
      tripId: params.tripId,
      eventTypes: params.eventTypes || {},
      schoolId
    });
  }),

  updateRideType: action((state, rideType) => {
    if (state.isCrossSchool && rideType === "delivery") return;
    state.rideType = rideType;
    state.route = { ...defaultState.route };
    _.forEach(state.stops, (stops, _k) =>
      _.forEach(stops, (v, _k) => {
        if (v.id) v = {};
      })
    );
  }),

  updateRouteType: action((state, routeType) => {
    state.routeType = routeType;
    state.workflow.stops = {};
    _.assign(state, _.pick(defaultState, ["stops", "tripTypes", "returnTrip", "anchor", "errors"]));
    if (state.workflow?.createRoute && !state.schoolId) state.schoolId = state.school?.id;
    if (state.workflow?.createRoute && state.routeType === "regular") {
      state.tripTypes = TRIP_TYPES.regular;
    }
  }),

  updateTripType: action((state, { tripType, isNewStop = false }) => {
    if (state.tripTypes.includes(tripType)) return;
    const prevTripType = state.tripTypes[0];
    state.tripTypes = [tripType];
    state.anchor = getAnchorType(tripType);
    state.route = { ...defaultState.route };
    if (isNewStop && prevTripType) {
      const prevAnchor = getAnchorType(prevTripType);
      _.set(
        state.workflow.stops,
        `${tripType}.${state.anchor}`,
        _.get(state.workflow.stops, `${prevTripType}.${prevAnchor}`)
      );
      _.set(
        state.workflow.stops,
        `${tripType}.${prevAnchor}`,
        _.get(state.workflow.stops, `${prevTripType}.${state.anchor}`)
      );
      _.set(
        state.stops,
        `${tripType}.${state.anchor}`,
        _.get(state.stops, `${prevTripType}.${prevAnchor}`)
      );
      _.set(
        state.stops,
        `${tripType}.${state.anchor}`,
        _.get(state.stops, `${prevTripType}.${prevAnchor}`)
      );
      _.set(state.workflow.stops, prevTripType, {});
      _.set(state.stops, prevTripType, {});
    } else {
      state.workflow.stops = {};
      state.stops = {};
    }
  })
});
