import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import makeStyles from "@mui/styles/makeStyles";

const EventBadge = ({ eventType, status }) => {
  const cls = useStyles();
  const eventBadgeClass = eventType === "change" ? cls.change : cls.oneOff;
  const eventLabelClass = eventType === "change" ? cls.changeLabel : cls.oneOffLabel;
  const eventLabel = eventType === "change" ? "CHANGE" : "1-OFF";
  return (
    <Box>
      <Box display="flex" ml={2} mt={0.5}>
        {status === "pending" ? (
          <ScheduleIcon className={eventBadgeClass} fontSize="large" />
        ) : (
          <Fragment>
            <CheckCircleIcon className={eventBadgeClass} fontSize="large" />
            <Typography className={eventLabelClass}>{eventLabel}</Typography>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  change: {
    color: theme.custom.YELLOW
  },
  oneOff: {
    color: theme.custom.RED
  },
  changeLabel: {
    color: theme.custom.YELLOW,
    fontWeight: theme.custom.BOLD,
    padding: theme.spacing(0.5)
  },
  oneOffLabel: {
    color: theme.custom.RED,
    fontWeight: theme.custom.BOLD,
    padding: theme.spacing(0.5)
  }
}));

export default EventBadge;
