import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import { Typography, Box } from "@mui/material";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import VendorStaffForm from "components/admin_v2/vendor_config/staff/VendorStaffForm";
import I18n from "utils/i18n.js";
import { usersStore } from "components/admin_v2/common/stores/usersStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const VendorStaffEdit = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => usersStore({ role: "vendor_staff" }));
  const user = state.user;
  const id = props.match.params.id;

  useEffect(() => {
    if (!id) return;

    actions.fetchUser(id);

    return () => {
      actions.setUser(null);
    };
  }, [id]);

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      <BreadCrumbs
        path={pageRoutes.vendorStaffs()}
        label={I18n.t("vendor_config.staffs.breadcrumb")}
      />
      {user && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {user.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.resetPassword(id)}>
              {I18n.t("ui.btn.reset_password")}
            </OutlinedBtn>
            <OutlinedBtn className={`${cls.actionBtn} active`} disabled>
              {I18n.t("vendor_config.edit_staff.btn")}
            </OutlinedBtn>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
              {user.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
            </OutlinedBtn>
          </Box>
        </Box>
      )}
      {state.loading ? <Spinner /> : <VendorStaffForm role={state.role} user={user} />}
    </React.Fragment>
  );
};

export default VendorStaffEdit;
