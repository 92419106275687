import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import {
  fetchTextBlasts,
  submitTextBlast,
  fetchTextBlast,
  fetchTextBlastMessages,
  cancelTextBlast as apiCancelTextBlast
} from "services/apiTextBlasts";
import { setFlashMessage } from "services";
import { toFormData } from "lib/util";
import { refreshModel } from "./refreshModel";

const BLAST_FORM = {
  file: null,
  schoolId: null,
  processAt: new Date()
};

export const defaultState = {
  schoolId: null,
  status: null,
  // status
  addTextBlastDialogOpen: false,
  loading: false,
  submitDisabled: false,
  // data
  textBlasts: [],
  textBlast: null,
  newBlast: { ...BLAST_FORM },
  conflicts: [],
  errors: {}
};

export const textBlastsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...refreshModel(),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["schoolId", "status"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchBlasts: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["page", "perPage", "status"]);

    actions.setLoading(true);

    return fetchTextBlasts({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => {
        actions.setLoading(false);
      });
  }),

  fetchTextBlast: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchTextBlast(id)
      .then((resp) => {
        actions.setTextBlast(resp.text_blast);
      })
      .finally(() => actions.setLoading(false));
  }),

  fetchTextBlastMessages: thunk((actions, params, h) => {
    const state = h.getState();
    const stateParams = pick(state, ["page", "perPage", "status"]);
    const id = params["id"];

    actions.setLoading(true);

    return fetchTextBlastMessages(id, { ...params, ...stateParams })
      .then((r) => {
        return actions.setMessagesData(r);
      })
      .catch((err) => setFlashMessage(err.message))
      .finally(() => {
        actions.setLoading(false);
      });
  }),

  setData: action((state, data) => {
    state.textBlasts = data.text_blasts;
    state.total = parseInt(data.total);
  }),

  setMessagesData: action((state, data) => {
    state.messages = data.messages;
    state.total = parseInt(data.total);
  }),

  addTextBlast: action((state, newBlast) => {
    let textBlastsList = state.textBlasts;
    textBlastsList.unshift(newBlast);
    state.textBlasts = textBlastsList;
  }),

  textBlastChange: action((state, textBlast) => {
    state.textBlasts = state.textBlasts.map((i) => {
      return i.id === textBlast.id ? textBlast : i;
    });
  }),

  submit: thunk((actions, _payload, h) => {
    actions.setSubmitDisabled(true);
    actions.setErrors({});
    const state = h.getState();

    submitTextBlast(toFormData(state.newBlast, { dateWithTime: true }))
      .then((resp) => {
        setFlashMessage(resp.message);
        actions.addTextBlast(resp.text_blast);
        actions.setAddTextBlastDialogOpen(false);
        actions.setNewBlast({ ...BLAST_FORM });
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;
        actions.setErrors(errors);
      })
      .finally(() => actions.setSubmitDisabled(false));
  }),

  cancelTextBlast: thunk((actions, id, _h) => {
    actions.setLoading(true);

    apiCancelTextBlast(id)
      .then((resp) => {
        actions.textBlastChange(resp.text_blast);
      })
      .finally(() => actions.setLoading(false));
  })
});
