import React from "react";
import FormSelect from "components/admin_v2/ui/FormSelect";
import Spinner from "components/admin_v2/ui/Spinner";
import useOptions from "./useOptions";
import useStyles from "./useStyles";

const DEFAULT_OPTIONS = {
  ridersType: []
};

const AllTripsReportForm = ({ reportStore }) => {
  const cls = useStyles();
  const [state, actions] = reportStore;
  const form = state.allTripsForm;
  const { options, loading } = useOptions(state.reportType, DEFAULT_OPTIONS);

  if (loading) return <Spinner />;

  const updateForm = (data) => {
    actions.setAllTripsForm({ ...form, ...data });
  };

  const handleChange = (field) => (e) => {
    updateForm({ [field]: e.target.value });
  };

  return (
    <>
      <FormSelect
        itemType="ridersType"
        options={options.ridersType}
        value={form.ridersType}
        onChange={handleChange("ridersType")}
        cls={cls}
      />
    </>
  );
};

export default AllTripsReportForm;
