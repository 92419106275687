const usePrefixedInput = ({ value, prefix, update }) => {
  const inputValue = (value || "").replace(`${prefix}_`, "");

  const updater = (e) => {
    const input = e.target.value;
    const newValue = input?.length ? `${prefix}_${input}` : "";
    update(newValue);
  };

  return [inputValue, updater];
};

export default usePrefixedInput;
