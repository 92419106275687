import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import cn from "classnames";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import I18n from "utils/i18n.js";
import { stopLocationsStore } from "./stores/stopLocationsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DETAIL_FIELDS = ["school", "name", "address", "created_at", "updated_at"];

const StopLocation = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => stopLocationsStore());
  const id = props.match.params.id;
  const stopLocation = state.stopLocation;

  useEffect(() => {
    if (!id) return;

    actions.fetchStopLocation(id);
  }, [id]);

  const detail = (prop) => {
    return (
      <Box className={cn(`details-${prop}`, cls.detail)} key={prop}>
        <div className={cls.detailHeader}>
          {I18n.t(`data_management.stop_location.labels.${prop}`)}
        </div>
        <div>{stopLocation[prop] || "--"}</div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <BreadCrumbs
        path={pageRoutes.stopLocations()}
        label={I18n.t("data_management.stop_locations.breadcrumb")}
      />
      {stopLocation && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {stopLocation.name}
            {stopLocation.duplicate ? <sup>(copy)</sup> : null}
          </Typography>
          {!stopLocation.is_school && (
            <RouterLink className={cls.actionBtn} to={pageRoutes.stopLocationEdit(stopLocation.id)}>
              <OutlinedBtn>{I18n.t("data_management.edit_stop_location.btn")}</OutlinedBtn>
            </RouterLink>
          )}
        </Box>
      )}
      <Paper className={cn("stop-location-details", cls.paper)}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={cls.subTitle}>
              {I18n.t("data_management.stop_location.title.details")}
            </Box>
            <Grid container className={cls.grid} spacing={5}>
              <Grid item xs={6}>
                {stopLocation && DETAIL_FIELDS.map(detail)}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default StopLocation;
