import React from "react";
import classNames from "classnames";
import { Table, TableRow, TableCell, TableBody } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { useStoreState } from "easy-peasy";
import { partition } from "lodash";
import { formatDate, formatDateUrl } from "lib/dates";
import { IconLink } from "components/admin_v2/forms/TableActionCell";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import I18n from "utils/i18n.js";

const DayDeltaOccurrencesTable = ({
  schoolId,
  occurrences,
  removeCallback = null,
  styles = {}
}) => {
  const { isPastDate } = useStoreState((s) => s.app);
  const cls = useTableFormStyles();
  const [past, future] = partition(occurrences, (d) => isPastDate(schoolId, d));
  const pastOccurrences = past.map((o) => (
    <TableRow key={`op-${o}`}>
      <TableCell className={cls.cellDisabled}>{formatDate(o)}</TableCell>
      {removeCallback ? (
        <TableCell className={cls.cellDisabled}>
          {I18n.t("school_config.day_delta.occurrence.past")}
        </TableCell>
      ) : null}
    </TableRow>
  ));
  const futureOccurrences = future.map((o) => (
    <TableRow key={`of-${o}`}>
      <TableCell>{formatDate(o)}</TableCell>
      {removeCallback ? (
        <TableCell className={classNames(cls.cellBase, cls.iconCell)} align="center">
          <IconLink onClick={removeCallback} idx={formatDateUrl(o)} type="remove">
            <DeleteOutline />
          </IconLink>
        </TableCell>
      ) : null}
    </TableRow>
  ));
  return (
    <Table sx={styles} size="small" aria-label="occurrences">
      <TableBody>
        {pastOccurrences}
        {futureOccurrences}
      </TableBody>
    </Table>
  );
};

export default DayDeltaOccurrencesTable;
