import React from "react";
import withStyles from "@mui/styles/withStyles";
import Checkbox from "@mui/material/Checkbox";

const GreenCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: theme.custom.GREEN
    }
  },
  checked: {}
}))((props) => <Checkbox color="default" {...props} />);

export default GreenCheckbox;
