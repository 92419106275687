import React, { Fragment, useEffect } from "react";
import cn from "classnames";
import { FormControl, FormLabel, FormHelperText, Box } from "@mui/material";
import { get, isEmpty, values } from "lodash";
import classNames from "classnames";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";
import { getAnchorTypeFor } from "components/admin_v2/routes/common";
import TimePickerInput from "components/admin_v2/ui/TimePickerInput";
import AddressSchoolForm from "../address/AddressSchoolForm";
import SchoolTimeForm from "./SchoolTimeForm";

// School stops (pickup/dropoff) for regular routes
const SchoolStopForm = ({ store, cls, tripType, withTimePicker = false }) => {
  const [state, actions] = store;
  const stopType = getAnchorTypeFor(tripType);
  const { errorStop, errorTime } = state.getStopErrorsFor(tripType, stopType);
  const stop = get(state.stops, `${tripType}.${stopType}`);
  const userAnchorTime = { time: get(stop, "time"), waitTime: get(stop, "waitTime") };

  useEffect(() => {
    if (!state.currentSchoolId && !state.districtId) return;
    actions.fetchSchoolsData().then(() => actions.updateSchoolStopData({ stopType, tripType }));
  }, [state.currentSchoolId, state.districtId]);

  const onChangeTime = (time) => actions.setStopTime({ stopType, tripType, time });
  const tzField = state.routable?.type == "School" ? "time_zone_abbr" : "timeZoneAbbr";

  return (
    <Fragment>
      <FormControl
        className={cls.withTopMargin}
        fullWidth
        error={!isEmpty(values(errorStop))}
        required
      >
        <FormLabel className={cls.label}>{I18n.t(`ui.stop.school.${stopType}`)}</FormLabel>
        <ErrorList errors={errorStop} />
        <AddressSchoolForm store={store} stopType={stopType} tripType={tripType} cls={cls} />
      </FormControl>
      <FormControl className={classNames(cls.withTopMargin, cls.time)} error={!!errorTime} required>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <FormLabel className={cn(cls.label, cls.halfSelect)}>
            {I18n.t(`ui.stop.time.${stopType}`)}
            {state.routable && (
              <span className={"time-zone"}>
                {I18n.t(`ui.stop.time.time_zone`, { tz: state.routable[tzField] })}
              </span>
            )}
          </FormLabel>{" "}
          {userAnchorTime.time && (
            <>
              <FormLabel className={cn(cls.timePicker, cls.label)}>
                <span className={cls.timeInputLabel}>{I18n.t("ui.stop.time.arrival_time")}</span>
              </FormLabel>
              <FormLabel className={cn(cls.timeField, cls.label)}>
                <span className={cls.timeInputLabel}>{I18n.t("ui.stop.time.wait_time")}</span>
              </FormLabel>
              <FormLabel className={cn(cls.timeField, cls.label)} required={false}>
                <span className={cls.timeInputLabel}>{I18n.t("ui.stop.time.departure_time")}</span>
              </FormLabel>
            </>
          )}
        </Box>
        {withTimePicker ? (
          <TimePickerInput
            dateTime={get(state.stops, `${tripType}.${stopType}.time`, null)}
            onChange={onChangeTime}
          />
        ) : (
          <SchoolTimeForm
            store={store}
            stopType={stopType}
            tripType={tripType}
            cls={cls}
            tz={state.routable?.[tzField]}
            userAnchorTime={userAnchorTime}
          />
        )}
        {errorTime && <FormHelperText>{errorTime}</FormHelperText>}
      </FormControl>
    </Fragment>
  );
};

export default SchoolStopForm;
