import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router";
import { Box, Typography, Divider, Dialog, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocalStore, useStoreActions, useStoreState } from "easy-peasy";
import { parseISO } from "date-fns";
import { get } from "lodash";
import ChangeReqTrip from "./ChangeReqTrip";
import Spinner from "components/admin_v2/ui/Spinner";
import { FormDialogTitle } from "components/admin_v2/ui/FormDialog";
import { CancelBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import { pageRoutes, slugsFor } from "services/api";
import { routeWithDate } from "lib/queryString";
import ChangeReqUnenroll from "./ChangeReqUnenroll";
import ChangeReqEffectiveDate from "./common/ChangeReqEffectiveDate";
import EventBadge from "./common/EventBadge";
import changeRequestStore from "./stores/requestStore";
import RouterLink from "components/admin_v2/nav/RouterLink";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";
import I18n from "utils/i18n.js";

const ChangeRequestDialog = ({ changeReq, reqDialogOpen, onSave, onClose, onCancel }) => {
  const cls = useStyles();
  const history = useHistory();
  const { isUserAdmin, school } = useStoreState((s) => s.app);
  const { changeSchool, setFlashMessage } = useStoreActions((s) => s.app);
  const requestStore = useLocalStore(() => changeRequestStore());
  const [state, actions] = requestStore;
  const { isUnenroll, calendar, status } = state;

  useEffect(() => {
    if (!reqDialogOpen || !changeReq) {
      return;
    }
    actions.setChangeReq(changeReq);
  }, [changeReq, reqDialogOpen]);

  if (!state.changeReq || !changeReq?.student) return null;

  if (!isUnenroll && (!reqDialogOpen || !calendar)) {
    return null;
  }

  const student = changeReq.student;
  const title = `${student.first_name} ${student.last_name}`;
  const contact = student.primary_phone_number
    ? `${student.primary_phone_number.number} (${student.primary_phone_number.name})`
    : "";

  const redirectToRoute = () => {
    history.push(
      routeWithDate(
        state.effectiveDate || parseISO(changeReq.start_date),
        state.createdRouteId,
        slugsFor(changeReq.student)
      )
    );
  };

  const saveChanges = () => {
    actions
      .save()
      .then((resp) => {
        setFlashMessage({ message: resp.message });
        // redirect to route page if any route was created, check if we may need to change school
        if (state.createdRouteId) {
          if (changeReq.student?.school_id && school?.id !== changeReq.student.school_id) {
            changeSchool(student.school_id).then(() => {
              redirectToRoute();
            });
          } else {
            redirectToRoute();
          }
        } else {
          actions.resetStateToDefault();
          onSave();
        }
      })
      .catch((err) => {
        const message = get(
          err.response,
          "data.message",
          I18n.t("change_requests.dialog.message.fail")
        );
        actions.setErrors({ base: message });
        setFlashMessage({ message: I18n.t("change_requests.dialog.message.fail") });
      })
      .finally(() => actions.setLoading(false));
  };

  const onDialogClose = () => {
    actions.resetStateToDefault();
    onClose();
  };

  return (
    <Fragment>
      <Dialog open={reqDialogOpen} maxWidth="md" onClose={onDialogClose}>
        <FormDialogTitle onClose={onDialogClose}>
          <Box display="flex" alignItems="center">
            <Typography variant="h2">{title}</Typography>
            {withoutAccommodation(student) ? null : (
              <StudentAccommodationPopup student={student} marginLeft={1} />
            )}
          </Box>
          <Typography variant="body1">
            {student.program} | {`Grade ${student.grade}`}
          </Typography>
          <Typography variant="body1">{contact}</Typography>
        </FormDialogTitle>
        <Divider variant="fullWidth" className={cls.separator} />
        <Box display="flex" justifyContent="space-between">
          <ChangeReqEffectiveDate
            changeReq={changeReq}
            requestStore={requestStore}
            cls={cls}
            isUserAdmin={isUserAdmin}
          />
          <RouterLink
            to={pageRoutes.student(student.id, slugsFor(student))}
            schoolId={student.school_id}
            component={Button}
            variant="outlined"
            className={cls.profileBtn}
          >
            View Student Profile
          </RouterLink>
        </Box>
        {state.loading || (!isUnenroll && !calendar) ? (
          <Spinner />
        ) : isUnenroll ? (
          <ChangeReqUnenroll requestStore={requestStore} changeReq={changeReq} />
        ) : (
          <ChangeReqTrip requestStore={requestStore} changeReq={changeReq} />
        )}
        <Divider variant="fullWidth" className={cls.separator} />
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box mr={2} display="flex">
              <PrimaryBtn
                onClick={saveChanges}
                disabled={state.isDisabled || state.resolved || !isUserAdmin}
              >
                Save
              </PrimaryBtn>
              {state.showEventBadge && <EventBadge eventType={state.eventType} status={status} />}
            </Box>
            <Box>
              <CancelBtn onClick={onCancel}>Exit</CancelBtn>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  separator: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  label: {
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.custom.LIGHT_GREY,
    fontWeight: theme.custom.BOLD,
    borderRadius: theme.spacing(0.3)
  },
  profileBtn: {
    height: "100%",
    backgroundColor: theme.custom.WHITE
  }
}));

export default ChangeRequestDialog;
