import { action } from "easy-peasy";
import easyState from "lib/easyState";

const appStore = {
  ...easyState("user"),
  ...easyState("schools", []),
  ...easyState("flashMessage"),

  initialState: action((state, props) => {
    Object.entries(props).forEach(([key, val]) => {
      state[key] = val;
    });
  })
};

export default appStore;
