import { startCase } from "lodash";

const Language = ({ target, style, render }) => {
  const language = target ? startCase(target.language) : null;

  if (!language) return null;

  const langStr = style === "plain" ? language : `(${language})`;

  if (render) return render(langStr);

  return langStr;
};

export default Language;
