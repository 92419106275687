import { createStore } from "easy-peasy";
import appStore from "./appStore";
import avlStore from "./avlStore";
import calendarStore from "./calendarStore";
import routeEditorStore from "./routeEditorStore";
import vendorStore from "./vendorStore";

const store = createStore({
  app: appStore,
  avl: avlStore,
  calendar: calendarStore,
  routeEditor: routeEditorStore,
  vendor: vendorStore
});

export default store;
