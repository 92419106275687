import React, { useEffect } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import cn from "classnames";
import { snakeCase } from "lodash";
import { Typography, Paper, Box, Grid } from "@mui/material";
import { OutlinedBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import VendorAssociations from "components/admin_v2/vendor_config/vendors/VendorAssociations";
import I18n from "utils/i18n.js";
import { PROVIDERS_WITH_DEVICES } from "utils/constants";
import { vendorsStore } from "components/admin_v2/vendor_config/stores/vendorsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DETAIL_FIELDS = ["name", "email", "provider", "createdAt", "updatedAt"];

const Vendor = (props) => {
  const isRestrictedAdmin = useStoreState((s) => s.app.isRestrictedAdmin);
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => vendorsStore());
  const id = props.match.params.id;
  const vendor = state.vendor;

  useEffect(() => {
    if (!id) return;

    actions.fetchVendor(id);

    return () => {
      actions.setVendor(null);
    };
  }, [id]);

  const detail = (prop) => {
    return (
      <Box className={cn(`details-${prop}`, cls.detail)} key={prop}>
        <div className={cls.detailHeader}>
          {I18n.t(`vendor_config.vendor.labels.${snakeCase(prop)}`)}
        </div>
        <div>{vendor[prop] || "--"}</div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      <BreadCrumbs path={pageRoutes.vendors()} label={I18n.t("vendor_config.vendors.breadcrumb")} />
      {vendor && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {vendor.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            {!isRestrictedAdmin && (
              <>
                <RouterLink className={cls.actionBtn} to={pageRoutes.vendorEdit(vendor.id)}>
                  <OutlinedBtn>{I18n.t("vendor_config.edit_vendor.btn")}</OutlinedBtn>
                </RouterLink>
                <OutlinedBtn className={cls.actionBtn} onClick={() => actions.toggleArchive(id)}>
                  {vendor.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
                </OutlinedBtn>
              </>
            )}
            {PROVIDERS_WITH_DEVICES.includes(vendor.provider) && (
              <PrimaryBtn className={cls.actionBtn} onClick={() => actions.refetchDevices(id)}>
                {I18n.t("ui.btn.refetch_devices")}
              </PrimaryBtn>
            )}
          </Box>
        </Box>
      )}
      <Box my={5}>
        <Paper className={cn("vendor-details", cls.paper)}>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              <Box className={cls.subTitle}>{I18n.t("vendor_config.vendor.title.details")}</Box>
              <Grid container className={cls.grid} spacing={5}>
                <Grid item xs={6}>
                  {vendor && DETAIL_FIELDS.map(detail)}
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Box>
      {vendor && <VendorAssociations vendor={vendor} />}
    </React.Fragment>
  );
};

export default Vendor;
