import React from "react";
import NavTabs from "components/admin_v2/common/NavTabs";
import { pageRoutes } from "services/api";

const SchoolConfigTabs = () => {
  const tabs = [
    { label: "Users", path: pageRoutes.users(), exact: false },
    { label: "Programs", path: pageRoutes.programs(), exact: false }
  ];

  return <NavTabs tabs={tabs} />;
};

export default SchoolConfigTabs;
