import React, { useEffect } from "react";
import { useLocalStore } from "easy-peasy";
import { Typography, Box } from "@mui/material";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import CommsGroupForm from "components/admin_v2/school_config/comms_config/CommsGroupForm";
import I18n from "utils/i18n.js";
import { commsGroupsStore } from "components/admin_v2/school_config/stores/commsGroupsStore";
import { pageRoutes } from "services/api";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const CommsGroupEdit = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => commsGroupsStore());
  const group = state.group;
  const id = props.match.params.id;

  useEffect(() => {
    if (!id) return;

    actions.fetchCommsGroup(id);

    return () => {
      actions.setGroup(null);
    };
  }, [id]);

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <BreadCrumbs
        path={pageRoutes.commsGroups()}
        label={I18n.t("school_config.comms_groups.breadcrumb")}
      />
      {group && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {group.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <OutlinedBtn className={`${cls.actionBtn} active`} disabled>
              {I18n.t("school_config.edit_comms_group.btn")}
            </OutlinedBtn>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.deleteCommsGroup(id)}>
              {I18n.t("ui.btn.delete")}
            </OutlinedBtn>
          </Box>
        </Box>
      )}
      {state.loading ? <Spinner /> : <CommsGroupForm group={group} />}
    </React.Fragment>
  );
};

export default CommsGroupEdit;
