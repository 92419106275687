import React, { useEffect } from "react";
import { useHistory } from "react-router";
import cn from "classnames";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useLocalStore, useStoreState } from "easy-peasy";
import { CancelBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import Spinner from "components/admin_v2/ui/Spinner";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import I18n from "utils/i18n.js";
import { stopLocationsStore } from "./stores/stopLocationsStore";
import { pageRoutes } from "services/api";
import { commaErrors } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const StopLocationEdit = (props) => {
  const cls = useCommonPageStyles();
  const history = useHistory();
  const { schools } = useStoreState((s) => s.app);
  const [state, actions] = useLocalStore(() => stopLocationsStore());
  const id = props.match.params.id;
  const stopLocationForm = state.stopLocationForm;
  const errors = state.errors;

  useEffect(() => {
    if (!id) return;

    actions.fetchStopLocation(id);
  }, [id]);

  const onChange = (field) => (e) => {
    actions.setStopLocationForm({ ...stopLocationForm, [field]: e.target.value });
  };

  const onAddressChange = (field) => (e) => {
    actions.setStopLocationForm({
      ...stopLocationForm,
      addressAttributes: { ...stopLocationForm.addressAttributes, [field]: e.target.value }
    });
  };

  const onSubmit = () => {
    actions.updateStopLocation().then(() => {
      history.push(pageRoutes.stopLocation(id));
    });
  };

  const addressFields = [
    { name: "address", type: "text" },
    { name: "lat", type: "number" },
    { name: "lng", type: "number" }
  ];

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <BreadCrumbs
        path={pageRoutes.stopLocations()}
        label={I18n.t("data_management.stop_locations.breadcrumb")}
      />
      <Box className={cls.titleWithBtn}>
        <Typography variant="h1" gutterBottom>
          {stopLocationForm?.name}
          {state.stopLocation?.duplicate ? <sup>(copy)</sup> : null}
        </Typography>
      </Box>
      <Paper className={cn("stop-location-form", cls.paper)}>
        {state.loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={cls.subTitle}>
              {I18n.t("data_management.stop_location.title.details")}
            </Box>
            <Grid container className={cls.grid} spacing={5}>
              <Grid item xs={6}>
                <FormControl className={cls.field} error={!!errors?.schoolId} variant="standard">
                  <InputLabel>
                    {I18n.t("data_management.stop_locations.form.school.label")}
                  </InputLabel>
                  <Select
                    value={stopLocationForm?.schoolId || ""}
                    onChange={onChange("schoolId")}
                    className="stop-location-school"
                    MenuProps={{ className: "stop-location-school-menu" }}
                    disabled={state.editDisabled}
                  >
                    {schools.map((school) => (
                      <MenuItem key={school.id} value={school.id}>
                        {school.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.schoolId && (
                    <FormHelperText>{commaErrors(errors?.school_id)}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  variant="standard"
                  label={I18n.t("data_management.stop_locations.form.name.label")}
                  value={stopLocationForm?.name || ""}
                  onChange={onChange("name")}
                  className={cn("stop-location-name", cls.field)}
                  error={!!errors.name}
                  helperText={commaErrors(errors?.name)}
                  disabled={state.editDisabled}
                />
                {addressFields.map(({ name, type }) => (
                  <TextField
                    variant="standard"
                    key={name}
                    label={I18n.t(`data_management.stop_locations.form.${name}.label`)}
                    value={stopLocationForm?.addressAttributes?.[name] || ""}
                    onChange={onAddressChange(name)}
                    className={cn(`stop-location-address-${name}`, cls.field)}
                    type={type}
                    error={!!state.errors?.[`address.${name}`]}
                    helperText={commaErrors(state.errors?.[`address.${name}`])}
                    disabled={state.editDisabled}
                  />
                ))}
              </Grid>
            </Grid>
            <Box className={cls.actionsWrapper}>
              <PrimaryBtn
                onClick={onSubmit}
                color="primary"
                disabled={state.editDisabled || state.submitDisabled}
              >
                {I18n.t("data_management.stop_location.update")}
              </PrimaryBtn>
              <RouterLink className={cls.actionBtn} to={pageRoutes.stopLocation(id)}>
                <CancelBtn>
                  <Typography variant="subtitle1">{I18n.t("ui.btn.cancel")}</Typography>
                </CancelBtn>
              </RouterLink>
            </Box>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default StopLocationEdit;
