import { Box, FormControl, Grid, MenuItem, Paper, Select, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { get, times } from "lodash";
import { useStoreActions } from "easy-peasy";
import { isSameDay } from "date-fns";
import { fetchSchoolsList } from "services/apiSchools";
import { searchStudents } from "services/apiStudents";
import { dateOrMondayFromQuery } from "lib/dates";
import { camelizeLocationSearch } from "lib/queryString";
import StudentModeChangeDialog from "components/admin_v2/mode_editor/StudentModeChangeDialog";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";

const Search = (props) => {
  const { changeSchool } = useStoreActions((s) => s.app);
  const [student, setStudent] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [school, setSchool] = useState(null);
  const [currentDate, setCurrentDate] = useState(() => {
    const date = get(camelizeLocationSearch(props), "date");
    return dateOrMondayFromQuery(date);
  });

  const cls = useStyles();

  useEffect(() => {
    fetchSchoolsList().then((r) => setSchoolOptions(r.schools));
  }, []);

  const onSelectStudent = (newStudent) => setStudent(newStudent);

  const onSelectSchool = (e) => {
    const newSchool = schoolOptions.find((s) => s.id == e.target.value);
    setSchool(newSchool);
    if (newSchool && student?.school_id !== newSchool.id) setStudent(null);
  };

  const onViewProfile = () => {
    // must return the promise
    return Promise.resolve(changeSchool(student.school_id));
  };

  const onDateChange = (date) => {
    if (isSameDay(currentDate, date)) return;
    setCurrentDate(date);
  };

  return (
    <React.Fragment>
      <TopPageLayout title="Search for All Students" actionButtons={[]} />
      <Box my={3}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item sm={8}>
            <AutocompleteSelect
              id="student-search-input"
              getOptions={searchStudents}
              onOptionSelect={onSelectStudent}
              searchParams={{ school_id: school?.id, withFullProfile: true }}
              defaultSchool={"all"}
              labelField={"name"}
              placeholder="Start Typing ..."
            />
          </Grid>
          <Grid item sm={4}>
            <FormControl variant="outlined" className={cls.schools}>
              <Select value={school?.id || ""} onChange={onSelectSchool} displayEmpty>
                <MenuItem value="">
                  <Typography variant="body2">All Schools</Typography>
                </MenuItem>
                {schoolOptions.map(({ id, name }) => (
                  <MenuItem key={`school-sel-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box my={4} className={cls.student}>
          {student && (
            <Paper className={cls.paper}>
              {times(2, (idx) => (
                <StudentModeChangeDialog
                  key={`st-mode-change-${idx}`}
                  inline={true}
                  isCustom={idx === 1}
                  student={student}
                  date={currentDate}
                  showViewProfile={true}
                  onViewProfile={onViewProfile}
                  onDateChange={onDateChange}
                  refreshOnSave={true}
                />
              ))}
            </Paper>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  schools: {
    width: "100%"
  },
  student: {
    minHeight: theme.spacing(40)
  },
  paper: {
    padding: theme.spacing(2),
    flexGrow: 0,
    maxWidth: "65.6667%",
    flexBasis: "65.6667%"
  }
}));

export default Search;
