import { addDays, isToday, startOfWeek, max, isSameDay } from "date-fns";
import React from "react";
import CalendarSelector from "components/admin_v2/ui/CalendarSelector";
import { formatHumanDate } from "lib/dates";

const DaySelector = ({ date, onSelectDate, skipWeekends, minDate, disabled = false }) => {
  if (!date) return null;

  const isSameMinDate = minDate && isSameDay(date, minDate);
  const isDisabledPast = minDate && !isSameMinDate && date < minDate;
  const startDate = isDisabledPast ? minDate : date;
  const dayOfWeek = startDate.getDay();
  let nextDate = minDate ? max([minDate, addDays(date, 1)]) : addDays(date, 1);
  let prevDate = isDisabledPast || isSameMinDate ? null : addDays(date, -1);

  if (skipWeekends) {
    nextDate =
      dayOfWeek >= 5 || dayOfWeek === 0
        ? startOfWeek(addDays(startDate, 7), { weekStartsOn: 1 })
        : nextDate;

    prevDate =
      prevDate && dayOfWeek < 2
        ? addDays(startOfWeek(addDays(date, -3), { weekStartsOn: 1 }), 4)
        : prevDate;

    if (prevDate && prevDate < minDate) prevDate = null;
  }

  const formattedDate = formatHumanDate(date);
  const label = isToday(date) ? `Today: ${formattedDate}` : formattedDate;

  const dateUpdate = (newDate) => {
    if (skipWeekends && [0, 6].includes(newDate.getDay())) {
      newDate = startOfWeek(addDays(newDate, 7), { weekStartsOn: 1 });
    }

    onSelectDate(newDate);
  };

  const pickerProps = minDate ? { minDate } : {};

  return (
    <CalendarSelector
      label={label}
      date={date}
      onSelectDate={dateUpdate}
      next={nextDate}
      prev={prevDate}
      flex="0 0 auto"
      pickerProps={pickerProps}
      disabled={disabled}
    />
  );
};

export default DaySelector;
