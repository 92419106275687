import xhr from "lib/xhr";
import { apiUrl } from "./api";

export const fetchDayDeltaTimes = async (id) => {
  return await xhr.get(apiUrl(`days_delta/${id}`), { transformData: true });
};

export const createDayDelta = async (params) => {
  const url = apiUrl(`days_delta`);
  return await xhr.post(url, { day_delta: params }, { transformData: true });
};

export const removeDayDelta = async (id, params) => {
  const url = apiUrl(`days_delta/${id}/delete`);
  return await xhr.post(url, { day_delta: params }, { transformData: true });
};

export const updateDayDelta = async (id, params) => {
  const url = apiUrl(`days_delta/${id}`);
  return await xhr.post(url, { day_delta: params }, { transformData: true });
};
