import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const searchStopLocations = async (params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`stop_locations/search?${query}`));
};

export const fetchStopLocations = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`stop_locations?${query}`));
};

export const fetchStopLocation = async (id) => {
  return await xhr.get(apiUrl(`stop_locations/${id}`));
};

export const submitStopLocation = async (params) => {
  const url = apiUrl(`stop_locations`);
  return await xhr.post(url, params);
};

export const updateStopLocation = async (id, params) => {
  const url = apiUrl(`stop_locations/${id}`);
  return await xhr.post(url, params);
};

export const apiArchiveStopLocation = async (id) => {
  const url = apiUrl(`stop_locations/${id}/archive`);
  return await xhr.post(url);
};

export const batchArchive = async (ids) => {
  const url = apiUrl("stop_locations/batch_archive");
  return await xhr.post(url, { stop_location_ids: ids });
};
