import React from "react";
import I18n from "utils/i18n.js";
import theme from "../theme";
import { orderBy, isNil } from "lodash";

// Selecting color for highlighting based on eta_diff of a trip:
// if ETA is different from expected by 10 minutes (delayed) we show yellow trip
// if it's more than 25 minutes - we show red trip
// if ETA diff is less than 10 minutes - it's green (almost on time)
const ETA_DIFF = { min: 10, max: 20 };
const ETA_DIFF_COLORS = {
  late: theme.custom.YELLOW,
  veryLate: theme.custom.RED,
  onTime: theme.custom.GREEN
};

const TRIPS_STATUS_ORDER = { alert: 1, late: 2, very_late: 3, no_data: 4, on_time: 5, arrived: 6 };

export const tripColor = (trip) => {
  switch (trip.status_by_time.id) {
    case "alert":
    case "no_vendor":
    case "no_vehicle":
    case "no_device":
    case "no_booking":
      return { name: "white", color: theme.custom.WHITE };
    case "on_time":
      return { name: "green", color: ETA_DIFF_COLORS.onTime };
    case "arrived":
      return { name: "blue", color: theme.custom.BLUE };
    case "late":
      return { name: "yellow", color: ETA_DIFF_COLORS.late };
    case "very_late":
      return { name: "red", color: ETA_DIFF_COLORS.veryLate };
    default:
      return { name: "grey", color: theme.custom.DARK_GREY_2 };
  }
};

export const etaDiffColor = (etaDiff) => ETA_DIFF_COLORS[stopEtaStatus(etaDiff)];

export const stopEtaStatus = (etaDiff) => {
  if (isNil(etaDiff)) {
    return "noData";
  } else if (etaDiff > ETA_DIFF.max) {
    return "veryLate";
  } else if (etaDiff > ETA_DIFF.min) {
    return "late";
  } else {
    return "onTime";
  }
};

export const timeDiffStr = (diff) => {
  return I18n.t("trip.statuses.eta_time", {
    symbol: diff > 0 ? "+" : "-",
    minutes: Math.abs(diff)
  });
};

export const tripTz = (tz, tzCls = "time-zone") => {
  return <span className={tzCls}>{tz}</span>;
};

export const reorderTrips = (trips, sortBy) => {
  if (sortBy.field === "status") {
    return orderBy(trips, (t) => TRIPS_STATUS_ORDER[t.status_by_time.id], sortBy.order);
  } else {
    return orderBy(trips, sortBy.field, sortBy.order);
  }
};
