import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const SIZES = { regular: 60, small: 20 };

const Spinner = ({ size = "regular", withMargin = true }) => (
  <div style={{ display: "flex" }}>
    <CircularProgress
      style={withMargin ? { margin: "20px auto" } : null}
      size={SIZES[size]}
      thickness={4}
    />
  </div>
);

export default Spinner;
