import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchVendorsList = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`transportation_vendors/list?${query}`));
};

export const fetchVendorTransports = async (vendorId) => {
  return await xhr.get(apiUrl(`transportation_vendors/${vendorId}/transports`));
};

export const fetchVendors = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`transportation_vendors?${query}`), { transformData: true });
};

export const fetchVendor = async (id) => {
  return await xhr.get(apiUrl(`transportation_vendors/${id}`), { transformData: true });
};

export const saveVendor = async (id, params) => {
  const url = apiUrl(`transportation_vendors/${id}`);
  return await xhr.post(url, params, { transformData: true });
};

export const createVendor = async (params) => {
  const url = apiUrl(`transportation_vendors`);
  return await xhr.post(url, params);
};

export const archiveVendor = async (id) => {
  const url = apiUrl(`transportation_vendors/${id}/archive`);
  return await xhr.post(url);
};

export const refetchVendorDevices = async (id) => {
  const url = apiUrl(`transportation_vendors/${id}/refetch_devices`);
  return await xhr.post(url);
};

export const batchArchive = async (ids) => {
  const url = apiUrl(`transportation_vendors/batch_archive`);
  return await xhr.post(url, { vendor_ids: ids });
};

export const batchUnarchive = async (ids) => {
  const url = apiUrl(`transportation_vendors/batch_unarchive`);
  return await xhr.post(url, { vendor_ids: ids });
};

export const fetchRouteOptions = async (id, params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`transportation_vendors/${id}/route_options?${query}`));
};
