import React, { useState, useEffect } from "react";
import cn from "classnames";
import { format as dateFormat, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { startsWith, isNil } from "lodash";
import { Box, Tooltip, Grid, Typography, Link } from "@mui/material";
import { ArrowRightRounded, ArrowDropDownRounded, Group } from "@mui/icons-material";
import NumberChip from "../ui/NumberChip";
import RouterLink from "components/admin_v2/nav/RouterLink";
import VehicleDivider from "./VehicleDivider";
import { studentWithDate } from "lib/queryString";
import { slugsFor } from "services/api";
import { stopEtaStatus, timeDiffStr, tripTz } from "./tripHelpers";
import { flyTo } from "./mapboxApi";
import { simplePluralize } from "lib/util";
import I18n from "utils/i18n.js";

const TripStop = ({ tripInfo, stop, tripsStore, cls }) => {
  const [state, _actions] = tripsStore;
  const [expanded, setExpanded] = useState({
    pickup: !state.collapseAllStudents,
    dropoff: !state.collapseAllStudents
  });
  const { map } = state;
  const school = tripInfo.school;
  const trip = tripInfo.trip;
  const isDropOff = startsWith(trip.trip_type, "to") ? stop.is_dropoff : !stop.is_pickup;
  const len = (arr) => (arr ? arr.length : 0);

  useEffect(() => {
    if (state.collapseAllStudents) {
      setExpanded({ pickup: false, dropoff: false });
    } else {
      setExpanded({ pickup: true, dropoff: true });
    }
  }, [state.collapseAllStudents]);

  const stopInfo = trip.stops_info[stop.id];
  const isNext = stop.id === trip.next_stop?.id;
  const etaDiff = stopInfo?.eta_diff;
  const isCompleted = stopInfo?.completed;
  const etaStatus =
    isCompleted && !isNil(stopInfo?.completed_diff)
      ? stopEtaStatus(stopInfo?.completed_diff)
      : stopEtaStatus(etaDiff);

  const day = parseISO(trip.date_iso);

  let completedTime = null;
  if (isCompleted && stopInfo?.completed_time && school?.time_zone) {
    try {
      completedTime = dateFormat(
        utcToZonedTime(stopInfo?.completed_time, school.time_zone),
        "HH:mm"
      );
    } catch (err) {
      console.error(err);
    }
  }

  const isOnTime = isCompleted ? completedTime === stop.time : etaStatus === "onTime";
  const isMissed = stop.is_missed;

  const timeDiffCls = cn(cls[etaStatus], "statusLabel", cls.timeDiff);
  const timeCls = cn("statusLabel", {
    [cls.timeInfo]: stop,
    [cls.striked]: isCompleted && !isOnTime,
    [cls.onTime]: isOnTime && !isCompleted
  });
  const stopChipCls = stop.is_school
    ? cls.schoolChip
    : cn(cls.stopChip, "statusChip", {
        [cls.missed]: isMissed,
        [cls[etaStatus]]: isCompleted && !isMissed
      });

  const handleExpandClick = (type) => {
    setExpanded({ ...expanded, [type]: !expanded[type] });
  };

  return (
    <>
      {isNext && !tripInfo.trip.ended_at ? (
        <>
          <VehicleDivider trip={trip} cls={cls} etaStatus={etaStatus} />
          <Grid container className={cls.stopsItem}>
            <Grid item className={cls.leftChip} />
            <Grid item className={cls.mainInfo}>
              {isNext ? (
                <Typography variant="body2" color="secondary">
                  {I18n.t("ui.trip.next_stop")}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </>
      ) : null}
      <Grid container className={cn(cls.stopsItem, { [cls.stopsItemLine]: isCompleted })}>
        <Grid item className={cls.leftChip}>
          <NumberChip className={stopChipCls}>{stop.number}</NumberChip>
          {isMissed ? (
            <NumberChip className={cn(cls.missed, cls.attentionChip)}>!</NumberChip>
          ) : null}
        </Grid>
        <Grid item className={cls.mainInfo}>
          {isMissed ? (
            <Typography variant="body2" className={cls.missedLabel}>
              {I18n.t("ui.trip.missed_stop")}
            </Typography>
          ) : null}
          <Link
            underline="none"
            style={{ cursor: "pointer" }}
            onClick={() => flyTo(map, [stop.lng, stop.lat])}
          >
            <Typography variant="body2" className={cls.boldInfo}>
              {stop.location_name}
            </Typography>
            <Typography variant="body2" className={cls.boldInfo}>
              {stop.address}
            </Typography>
          </Link>
          {["pickup", "dropoff"].map(
            (type) =>
              stop[`students_${type}`].length > 0 && (
                <Box key={type}>
                  <Box
                    display="flex"
                    className={cls.studentsListLink}
                    onClick={() => handleExpandClick(type)}
                  >
                    {expanded[type] ? (
                      <ArrowDropDownRounded className="caret" />
                    ) : (
                      <ArrowRightRounded className="caret" />
                    )}
                    {I18n.t(`ui.students.${type}`, {
                      students: simplePluralize(len(stop[`students_${type}`]), "Student")
                    })}
                  </Box>
                  <Box className={cls.studentsList}>
                    {expanded[type] &&
                      stop[`students_${type}`].map((student) => (
                        <Typography variant="body2" color="secondary" key={`student-${student.id}`}>
                          <RouterLink
                            to={studentWithDate(day, student.id, slugsFor(student))}
                            target="_blank"
                          >
                            {type === "pickup" ? "+ " : "- "}
                            {student.name}
                          </RouterLink>
                        </Typography>
                      ))}
                    {stop.hidden_count > 0 && (
                      <Typography variant="body2" color="secondary">
                        {isDropOff ? "-" : "+"}
                        {stop.hidden_count}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )
          )}
        </Grid>
        <Grid item className={cls.studentsInfo}>
          <Tooltip
            title={`Total riders when leaving this stop: ${stop.students_counter}`}
            arrow={true}
          >
            <Group className={cls.studentsIcon} style={{ fontSize: 15 }} />
          </Tooltip>
          <Typography variant="body2">{stop.students_counter}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={timeCls}>
            {stop.time}
            {tripTz(trip.time_zone_abbr, cn("time-zone", cls.stopTz))}
          </Typography>
          {isCompleted ? (
            <CompletedTime
              isOnTime={isOnTime}
              completedTime={completedTime}
              className={timeDiffCls}
              tzCls={cn("time-zone", cls.stopTz)}
              trip={trip}
            />
          ) : (
            <EtaTime etaDiff={etaDiff} className={timeDiffCls} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

function CompletedTime({ isOnTime, completedTime, className, trip, tzCls }) {
  return isOnTime ? null : (
    <Typography variant="body2" className={className}>
      {completedTime}
      {tripTz(trip.time_zone_abbr, tzCls)}
    </Typography>
  );
}

function EtaTime({ etaDiff, className }) {
  return etaDiff && etaDiff !== 0 ? (
    <Typography variant="body2" className={className}>
      {timeDiffStr(etaDiff)}
    </Typography>
  ) : null;
}

export default TripStop;
