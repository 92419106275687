import React from "react";
import { fetchRoutesList, searchRoutes } from "services/apiRoutes";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { useStoreState } from "easy-peasy";

const SelectRoutes = ({ onOptionSelect, schoolIds = [] }) => {
  const { isUserAdmin } = useStoreState((s) => s.app);
  return (
    <AutocompleteSelect
      id="select-routes"
      getOptions={isUserAdmin ? searchRoutes : fetchRoutesList}
      onOptionSelect={onOptionSelect}
      searchParams={{ school_id: schoolIds, name_only: true }}
      defaultValue={[]}
      labelField="name"
      placeholder="All routes"
      multiple={true}
      maxWidth="66%"
    />
  );
};

export default SelectRoutes;
