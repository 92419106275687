import { Box, Paper, TableCell, TableRow, Typography } from "@mui/material";
import React, { Fragment, useEffect, useCallback, useState } from "react";
import { useLocalStore, useStoreState, useStoreActions } from "easy-peasy";
import { Link } from "react-router-dom";
import useSorting from "lib/useSorting";
import { formatHumanDate } from "lib/dates";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes, slugsFor } from "services/api";
import Spinner from "components/admin_v2/ui/Spinner";
import AddRouteDialog from "./routes/AddRouteDialog";
import RoutesFilter from "./routes/RoutesFilter";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { routesStore } from "./routes/stores/routesStore";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";

const Routes = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => routesStore(camelizeLocationSearch(props)));
  const { sortBy, sortableColumn } = useSorting();
  const { isUserAdmin, district, school } = useStoreState((s) => s.app);
  const { onChangeScope } = useStoreActions((s) => s.app);
  const [addRouteDialogOpen, setAddRouteDialogOpen] = useState(false);

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchRouteFilters();
  }, [state.refresh, state.scope]);

  useEffect(() => {
    actions.fetchRoutes({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [
    district?.id,
    school?.id,
    state.refresh,
    state.page,
    state.perPage,
    state.scope,
    state.running,
    state.vehicleType,
    state.vendor,
    sortBy
  ]);

  // this is not real callback, link will be saved at the app context
  // so not possible to use dynamic values here
  const resetState = useCallback(() => {
    props.history.push({ pathname: "", search: "" });
    actions.setFromRouter({ refresh: true });
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => {
    updateQueryString({ page: newPage + 1 });
  };

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const toggleAddRouteDialog = () => {
    setAddRouteDialogOpen((d) => !d);
  };

  const headers = [
    { field: "id", label: "ID" },
    { field: "name", label: "Route Name" },
    { field: "routable_type", label: "XS?" },
    { field: "routable", label: "School/District" },
    { field: "first_date", label: "Start Date" },
    { field: "last_date", label: "End Date" },
    { field: "route_type", label: "Type" },
    { field: "vehicle_type", label: "Vehicle Type" },
    { field: "vendor", label: "Vendor", skipSorting: true },
    { field: "inbound_vehicle", label: "To School Vehicle" },
    { field: "inbound_pair", label: "To School Paired Route", skipSorting: true },
    { field: "outbound_vehicle", label: "From School Vehicle" },
    { field: "outbound_pair", label: "From School Paired Route", skipSorting: true }
    // NOTE: uncomment when drivers pages need to be shown again
    // { field: "inbound_driver", label: "Inbound Driver" },
    // { field: "outbound_driver", label: "Outbound Driver" }
  ].map(sortableColumn);

  const routeRows = () =>
    state.routes.map((route, idx) => {
      return (
        <TableRow key={`route-${idx}`}>
          <TableCell component="th" scope="route">
            <RouterLink to={pageRoutes.route(route.id, slugsFor(route))}>{route.id}</RouterLink>
          </TableCell>
          <TableCell component="th" scope="route">
            <RouterLink to={pageRoutes.route(route.id, slugsFor(route))}>{route.name}</RouterLink>
          </TableCell>
          <TableCell component="th" scope="route">
            {I18n.t(`ui.route.routable_type.short.${route.routableType.toLowerCase()}`)}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.routableName}
          </TableCell>
          <TableCell component="th" scope="route">
            {formatHumanDate(route.startDate)}
          </TableCell>
          <TableCell component="th" scope="route">
            {formatHumanDate(route.endDate)}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.routeType ? I18n.t(`ui.route.route_type.${route.routeType}`) : ""}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.vehicleType}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.vendor}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.inboundVehicle}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.inboundPair && (
              <>
                <Typography className={cls.smallLabel}>
                  {I18n.t(`data_management.paired_routes.info.tier`, {
                    tier: route.inboundPair.tier
                  })}
                </Typography>
                {route.inboundPair.route.name}
              </>
            )}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.outboundVehicle}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.outboundPair && (
              <>
                <Typography className={cls.smallLabel}>
                  {I18n.t(`data_management.paired_routes.info.tier`, {
                    tier: route.outboundPair.tier
                  })}
                </Typography>
                {route.outboundPair.route.name}
              </>
            )}
          </TableCell>
          {/* NOTE: uncomment when drivers pages need to be shown again */}
          {/* <TableCell component="th" scope="route">
            {route.inboundDriver}
          </TableCell>
          <TableCell component="th" scope="route">
            {route.outboundDriver}
          </TableCell> */}
        </TableRow>
      );
    });

  return (
    <Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      {isUserAdmin && (
        <Box className={cls.btnsWrapper}>
          <OutlinedBtn className={cls.actionBtn} onClick={toggleAddRouteDialog}>
            <Typography variant="subtitle1">Add a Route</Typography>
          </OutlinedBtn>
          <Link className={cls.actionBtn} to={pageRoutes.pairedRoutes()}>
            <OutlinedBtn>
              <Typography variant="subtitle1">Manage Paired Routes</Typography>
            </OutlinedBtn>
          </Link>
        </Box>
      )}
      <Paper>
        <RoutesFilter state={state} actions={actions} updateQueryString={updateQueryString} />
        {state.loading ? (
          <Spinner />
        ) : (
          <Box>
            <PaginatedTable
              headers={headers}
              count={state.total}
              perPage={state.perPage}
              page={state.page}
              onPageChange={updatePage}
              onChangePerPage={updatePerPage}
            >
              {routeRows()}
            </PaginatedTable>
          </Box>
        )}
      </Paper>
      {isUserAdmin ? (
        <AddRouteDialog open={addRouteDialogOpen} toggleDialog={toggleAddRouteDialog} />
      ) : null}
    </Fragment>
  );
};

export default Routes;
