import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  customTrip: {
    backgroundColor: theme.custom.LIGHT_GREY
  },
  topActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2.2),
    alignItems: "center",
    position: "relative"
  },
  phoneCell: {
    position: "relative"
  },
  phoneIndicator: {
    position: "absolute",
    top: "-2px"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  eventCell: {
    margin: "auto"
  },
  leftSpacingSm: {
    marginLeft: theme.spacing(1)
  },
  scope: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export default useStyles;
