import { action, thunk } from "easy-peasy";
import { pick, assign } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { setFlashMessage } from "services";
import { fetchSearchItems } from "services/apiAdvancedSearch";

export const defaultState = {
  // status
  loading: false,
  // filters
  query: "",
  searchableType: null,
  // data
  results: []
};

export const advancedSearchStore = (initialData = {}) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["query"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  performSearch: thunk((actions, _payload, h) => {
    actions.setLoading(true);
    const stateParams = pick(h.getState(), ["query", "page", "perPage"]);
    let searchableType = h.getState().searchableType;
    if (searchableType === "vendor") searchableType = "transportationVendor";

    fetchSearchItems({ searchableType: searchableType, ...stateParams })
      .then((resp) => {
        actions.setResults(resp.items);
        actions.setTotal(resp.total);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => {
        actions.setLoading(false);
      });
  })
});
