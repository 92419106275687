import React, { useEffect } from "react";
import { Dialog, Typography, Box, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useLocalStore, useStoreActions } from "easy-peasy";
import { get } from "lodash";
import cn from "classnames";
import { FormDialogTitle, FormDialogActions } from "components/admin_v2/ui/FormDialog";
import ErrorList from "components/admin_v2/ui/ErrorList";
import Spinner from "components/admin_v2/ui/Spinner";
import AddStudent from "./student/AddStudent";
import AddedStudentRow from "./student/AddedStudentRow";
import StudentRow from "./student/StudentRow";
import { addStudentStore } from "./stores/addStudentStore";
import I18n from "utils/i18n.js";
import useStyles from "./student/useStyles";

const HEADERS = [
  { label: "ID", clsName: "idCell" },
  { label: "", clsName: "gradeCell" },
  { label: "First Name", clsName: "studentCell" },
  { label: "Last Name", clsName: "studentCell" },
  { label: "Grade", clsName: "gradeCell" },
  { label: "", clsName: "labelsCell" },
  { label: "One Day Changes", clsName: "actionsCell" }
];

const StopStudentsDialog = ({ route, stop, open, toggleDialog }) => {
  const cls = useStyles();
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const { setSchedule } = useStoreActions((s) => s.routeEditor);
  const store = useLocalStore(() => addStudentStore());
  const [state, actions] = store;

  useEffect(() => {
    if (stop) actions.fetchStudents(stop);
  }, [stop]);

  const closeDialog = () => {
    toggleDialog();
    actions.resetState();
  };

  const saveChanges = () => {
    actions
      .saveChanges()
      .then((r) => {
        closeDialog();
        setSchedule(r.stops);
        setFlashMessage({ message: r.message });
      })
      .catch((err) => {
        const message = get(err, "message", I18n.t("stop.add_student.message.fail"));
        actions.setErrors({ "": message });
        setFlashMessage({ message });
      })
      .finally(() => actions.setLoading(false));
  };

  if (!stop || !state.stop) {
    return null;
  }

  const studentsTable = () => (
    <>
      <ErrorList errors={state.errors} />
      <Table className={cls.studentsTable} aria-label="simple table">
        <TableBody>
          <AddStudent route={route} stop={stop} store={store} />
          <TableRow className={cls.studentRow}>
            {HEADERS.map((header) => (
              <TableCell
                className={cn(cls.cell, cls[header.clsName])}
                key={`${header.label}-${header.clsName}`}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
          {state.students.map((student) => (
            <AddedStudentRow
              key={`row-as-${student.student.id}`}
              student={student.student}
              day={student.period.startDate}
              actions={actions}
            />
          ))}
          {state.events.map((event, idx) => (
            <StudentRow
              idx={idx}
              store={store}
              key={`row-${event.addEvent ? "ae" : "ss"}-${event.student.id}`}
              route={route}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );

  return (
    <Dialog open={open} maxWidth="md" onClose={closeDialog}>
      <FormDialogTitle onClose={closeDialog}>
        <Typography variant="h1">Edit Stop Student List</Typography>
        <Box mt={1}>
          <Typography variant="h2">
            {route ? `${route.name} | ${route.vehicle_type}` : null}
          </Typography>
          <Typography variant="subtitle1">{stop.stop.address}</Typography>
          {route?.with_schedule && (
            <Typography variant="subtitle1" color="error">
              {route.schedule_details}
            </Typography>
          )}
        </Box>
      </FormDialogTitle>
      {state.loading ? <Spinner /> : studentsTable()}
      <FormDialogActions onSubmit={saveChanges} onClose={closeDialog} disabled={state.isDisabled} />
    </Dialog>
  );
};

export default StopStudentsDialog;
