import React from "react";
import { TableCell, TableRow, Box } from "@mui/material";
import NumberChip from "components/admin_v2/ui/NumberChip";
import { randKey } from "lib/util";
import { getStopMark, stopAddress } from "./common";
import ScheduleCell from "./schedule/ScheduleCell";

const ScheduleRow = ({
  stopsRow,
  stopNumber,
  selectedDay,
  capacity,
  counter,
  expandAll,
  onStopSelect
}) => {
  const stopMark = getStopMark(stopsRow);
  const cells = stopsRow.stops.map((data) => {
    return (
      <ScheduleCell
        key={`stop-${data.stop ? data.stop.id : randKey()}`}
        data={data}
        capacity={capacity}
        counter={counter}
        expandAll={expandAll}
        selectedDay={selectedDay}
        stopAnchor={stopMark.mark}
        onStopSelect={onStopSelect}
      />
    );
  });

  return (
    <TableRow>
      <TableCell component="td" scope="row" align="left">
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <NumberChip type={stopMark.type}>{stopMark.mark || stopNumber}</NumberChip>
          </Box>
          <Box>{stopAddress(stopsRow.stop_location)}</Box>
        </Box>
      </TableCell>
      {cells}
    </TableRow>
  );
};

export default ScheduleRow;
