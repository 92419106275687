import React from "react";
import { MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LaunchIcon from "@mui/icons-material/Launch";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import QueuesNotification from "components/admin_v2/queues/QueuesNotification";

const NavbarItem = ({ tab }) => {
  const { isUserAdmin, selectedTab, selectedSubTab } = useStoreState((s) => s.app);
  const { setSelectedTab, setSelectedSubTab } = useStoreActions((s) => s.app);
  const cls = useStyles();

  const toggleSelectedTabs = () => {
    if (tab.child) {
      setSelectedSubTab(tab);
    } else if (!tab.items) {
      setSelectedTab(null);
      setSelectedSubTab(null);
    } else {
      setSelectedTab(tab);
      setSelectedSubTab(tab.items[0]);
    }
  };

  const isActive =
    (tab.items && tab.label === selectedTab?.label) ||
    (tab.childTabs && tab.label === selectedSubTab?.label);
  const tabCls = `${cls.navButton} ${isActive ? cls.activeNavLink : ""} ${tab.className || ""}`;

  const tabProps = ({ path, exact, redirect }) => {
    if (redirect) {
      return { component: "a", href: path, target: "_blank" };
    } else {
      return { component: NavLink, exact, activeClassName: cls.activeNavLink };
    }
  };

  return (
    <MenuItem
      button="true"
      to={tab.path}
      className={tabCls}
      onClick={toggleSelectedTabs}
      {...tabProps(tab)}
    >
      {tab.label.toUpperCase()}
      {isUserAdmin && tab.label === "Queues" ? <QueuesNotification /> : null}
      {tab.items && <ArrowDropDownIcon />}
      {tab.redirect && <LaunchIcon fontSize="small" />}
    </MenuItem>
  );
};

const useStyles = makeStyles((theme) => ({
  navButton: {
    marginRight: theme.spacing(3.3),
    height: "100%",
    fontSize: ".85rem",
    fontWeight: "bold",
    padding: theme.spacing(0),
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  activeNavLink: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(0.3),
    borderBottomWidth: theme.spacing(0.5),
    borderBottomColor: theme.palette.primary.main,
    borderBottomStyle: "solid"
  }
}));

export default NavbarItem;
