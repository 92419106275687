import React, { useEffect } from "react";
import { flatten, compact } from "lodash";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router";
import { useStoreState, useStoreActions } from "easy-peasy";
import ButtonLink from "components/admin_v2/ui/ButtonLink";
import { pageRoutes, slugsFor } from "services/api";
import { searchStudents } from "services/apiStudents";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { TabMenuList } from "components/admin_v2/ui/TabMenuList";
import { useContainerStyles } from "components/admin_v2/useStyles";
import NavbarItem from "./NavbarItem";
import I18n from "utils/i18n.js";

const Navbar = (_props) => {
  const {
    isUserAdmin,
    isRestrictedAdmin,
    isUserSchoolStaff,
    hasDistrict,
    hasCommsAccess,
    school,
    selectedTab,
    selectedSubTab
  } = useStoreState((s) => s.app);
  const { setSelectedTab, setSelectedSubTab } = useStoreActions((s) => s.app);
  const history = useHistory();
  const cls = useStyles();
  const clsContainer = useContainerStyles();

  const configurationTabs = [
    {
      label: "Data Management",
      path: isUserAdmin ? pageRoutes.studentImports() : pageRoutes.routes(),
      child: true,
      exact: false,
      childTabs: [
        "routes",
        "students",
        "route_imports",
        "student_imports",
        "text_blasts",
        "stop_locations",
        "exports"
      ]
    }
  ];

  if (isUserAdmin) {
    configurationTabs.push(
      ...compact([
        {
          label: "School Config.",
          path: pageRoutes.schools(),
          child: true,
          exact: false,
          childTabs: ["schools", "dismissal_groups", "school_staff", "communication_groups"]
        },
        {
          label: "Vendor Config.",
          path: pageRoutes.vendors(),
          child: true,
          exact: true,
          childTabs: ["vendors", "vehicles", "drivers", "vendor_staff", "devices"]
        },
        isRestrictedAdmin && !hasDistrict
          ? null
          : {
              label: "District Config.",
              path: pageRoutes.districts(),
              child: true,
              exact: true,
              childTabs: ["districts"]
            },
        {
          label: "Settings",
          path: pageRoutes.users(),
          child: true,
          exact: true,
          childTabs: ["users", "programs"]
        }
      ])
    );
  }

  const tabs = [
    // { label: "Dashboard", path: pageRoutes.dashboard(), exact: true },
    { label: "Live Curbside", path: pageRoutes.curbside(), exact: true },
    {
      label: "Tracking",
      path: pageRoutes.trips(),
      exact: false,
      redirect: true
    },
    { label: "Reports", path: pageRoutes.reports(), exact: false },
    { label: "Queues", path: pageRoutes.queues(), exact: false },
    {
      label: "Configuration",
      path: isUserAdmin ? pageRoutes.studentImports() : pageRoutes.students(),
      exact: false,
      items: configurationTabs
    }
  ];

  // use third level nav item childTabs to determine if first & second level nav is active
  useEffect(() => {
    if (selectedTab && selectedSubTab) return;

    const parentSubTabs = flatten(compact(tabs.map((t) => t.items)));
    const subTab = parentSubTabs.find(
      (t) => t.childTabs && t.childTabs.some((c) => history.location.pathname.includes(`/${c}`))
    );
    if (!subTab) return;

    const parentTab = tabs.find((t) => t.items && t.items.includes(subTab));
    setSelectedTab(parentTab);
    setSelectedSubTab(subTab);
  }, []);

  const onSelectStudent = (student) => {
    if (student) {
      history.push(pageRoutes.student(student.id, slugsFor(student)));
    }
  };

  const metaPage = history.location.pathname === "/admin_v2/search";

  if (hasCommsAccess) {
    tabs.push({
      label: "Communication",
      path: pageRoutes.communication(school?.id),
      exact: false,
      redirect: true,
      className: cls.commsButton
    });
  }

  return (
    <>
      <Box className={clsContainer.mainGutterPadding}>
        <Box className={cls.wrapper}>
          <Typography component="span" variant="subtitle2" className={cls.menuContainer}>
            <TabMenuList>
              {tabs.map((tab) => {
                return <NavbarItem tab={tab} key={tab.label} />;
              })}
            </TabMenuList>
          </Typography>
          <Box className={cls.side}>
            {!metaPage && (
              <AutocompleteSelect
                variant="standard"
                getOptions={searchStudents}
                onOptionSelect={onSelectStudent}
                filterOptions={(x) => x}
                searchParams={{ school_id: school?.id, navbar: true }}
                defaultSchool={"all"}
                labelField={"label"}
                placeholder="Find a Student"
                clearAfterChange={true}
                title="Search for students by Name, SSID or Transportation ID"
              />
            )}
          </Box>
          {isUserAdmin || isUserSchoolStaff ? (
            <Box mt={1.5}>
              <ButtonLink variant="text" color="primary" to={pageRoutes.advancedSearch()}>
                {I18n.t("ui.advanced_search.button")}
              </ButtonLink>
            </Box>
          ) : null}
        </Box>
      </Box>
      {selectedTab ? (
        <Box className={`${clsContainer.mainGutterPadding} ${cls.subNav}`}>
          <Box className={`${cls.wrapper} ${cls.subNav}`}>
            <Typography component="span" variant="subtitle2" className={cls.menuContainer}>
              <TabMenuList>
                {selectedTab.items.map((tab) => {
                  return <NavbarItem tab={tab} key={tab.label} />;
                })}
              </TabMenuList>
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: theme.spacing(7),
    maxWidth: theme.custom.CONTAINER_WIDTH,
    margin: "0 auto",
    display: "flex",
    padding: 0
  },
  menuContainer: {
    flex: 1
  },
  side: {
    height: theme.spacing(6.3),
    width: theme.spacing(30),
    marginTop: theme.spacing(1)
  },
  commsButton: {
    backgroundColor: "rgba(255, 180, 0, 0.2)",
    padding: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "rgba(255, 180, 0, 0.3)"
    }
  },
  subNav: {
    backgroundColor: theme.custom.LIGHT_GREY
  }
}));

export default Navbar;
