import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  alignRight: {
    justifyContent: "flex-end"
  },
  bold: {
    fontWeight: "bold"
  },
  comment: {
    backgroundColor: theme.custom.WHITE,
    width: "100%"
  },
  disabled: {
    color: theme.custom.MEDIUM_DARK_GREY,
    pointerEvents: "none"
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  field: {
    width: "100%",
    marginBottom: theme.spacing(3)
  },
  fieldInput: {
    height: theme.spacing(5),
    backgroundColor: theme.custom.WHITE
  },
  label: {
    color: theme.palette.text.primary,
    display: "block",
    fontWeight: theme.custom.BOLD,
    fontSize: "0.9rem",
    marginBottom: theme.spacing(1),
    "&:focus, &:active, &.Mui-focused": {
      color: theme.palette.text.primary
    }
  },
  list: {
    display: "inline-flex"
  },
  listItem: {
    flex: "0 0 auto",
    width: "auto"
  },
  listItemBottomDensed: {
    paddingBottom: 0
  },
  listDelimiter: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  placeholder: {
    color: theme.palette.text.disabled
  },
  timePicker: {
    width: "20%"
  },
  timeInputLabel: {
    fontSize: "0.7rem"
  },
  select: {
    width: "100%"
  },
  halfSelect: {
    width: "40%"
  },
  time: {
    minWidth: "100%"
  },
  timeField: {
    width: "15%"
  },
  waitTimeField: {
    backgroundColor: theme.custom.WHITE
  },
  numberInput: {
    maxWidth: theme.spacing(16)
  },
  radioInput: {
    width: "25%"
  },
  oneoffLabelLink: {
    display: "flex",
    justifyContent: "space-between"
  },
  withTopMargin: {
    marginTop: theme.spacing(2)
  },
  withBottomMargin: {
    marginBottom: theme.spacing(2)
  }
}));

export default useStyles;
