import React, { Fragment } from "react";
import { Dialog, Typography } from "@mui/material";
import {
  FormDialogTitle,
  FormDialogContent,
  FormDialogActions
} from "components/admin_v2/ui/FormDialog";
import { useStoreActions } from "easy-peasy";
import ProgramForm from "./ProgramForm";
import I18n from "utils/i18n.js";

const AddProgramDialog = ({ cls, open, store, toggleDialog }) => {
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [state, actions] = store;

  const closeDialog = () => {
    toggleDialog();
    actions.setErrors({});
  };

  const onSubmit = () => {
    actions.save().then((r) => {
      setFlashMessage({ message: r.message });
      actions.addProgram(r.program);
      toggleDialog();
    });
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={toggleDialog}>
      <Fragment>
        <FormDialogTitle onClose={closeDialog}>
          <Typography variant="h1">{I18n.t("settings_config.new_program.title")}</Typography>
        </FormDialogTitle>
        <FormDialogContent className={cls.dialogCont}>
          <ProgramForm store={store} />
        </FormDialogContent>
        <FormDialogActions
          onSubmit={onSubmit}
          onClose={closeDialog}
          disabled={state.loading || !state.isValid}
          submitLabel={I18n.t("settings_config.programs.form.submit")}
        />
      </Fragment>
    </Dialog>
  );
};

export default AddProgramDialog;
