import { action, thunk, computed } from "easy-peasy";
import { concat, intersectionWith, castArray, pullAll, uniq } from "lodash";
import easyState from "lib/easyState";
import { setFlashMessage } from "services";

const batchActionsStore = (_initialData) => ({
  ...easyState("collection", []),
  ...easyState("batchIds", []),

  isSelected: computed((state) => (id) => state.batchIds.includes(id)),
  allSelected: computed(
    (state) =>
      state.collection.length > 0 && state.collection.length == state.currentBatchIds.length
  ),
  currentBatchIds: computed((state) =>
    intersectionWith(state.collection, state.batchIds, (item, batchId) => item.id === batchId).map(
      (item) => item.id
    )
  ),

  toggleRefresh: action((state) => {
    if (!state.page || state.page === 1) state.refresh = !state.refresh;
    state.batchIds = [];
  }),

  updateSelectedIds: action((state, data) => {
    const { isAdd, id } = data;
    let selectedIds = state.batchIds;
    if (isAdd) {
      selectedIds.push(...castArray(id));
    } else {
      pullAll(selectedIds, castArray(id));
    }

    state.batchIds = selectedIds;
  }),

  updateAllSelected: action((state, selectAll) => {
    if (selectAll) {
      state.batchIds = uniq(
        concat(
          state.batchIds,
          state.collection.map((c) => c.id)
        )
      );
    } else {
      pullAll(state.batchIds, state.currentBatchIds);
    }
  }),

  selectAll: action((state, _payload) => {
    state.batchIds = uniq(
      concat(
        state.batchIds,
        state.collection.map((c) => c.id)
      )
    );
  }),

  deselectAll: action((state, _payload) => {
    pullAll(state.batchIds, state.currentBatchIds);
  }),

  submitBatchAction: thunk(async (actions, { apiEndpoint, optional = {} }, h) => {
    const { currentBatchIds, refresh } = h.getState();
    if (!currentBatchIds.length) return;
    actions.setLoading(true);

    apiEndpoint(currentBatchIds, optional)
      .then((r) => {
        actions.deselectAll();
        actions.setRefresh(!refresh);
        setFlashMessage(r.message);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => {
        actions.setLoading(false);
      });
  })
});

export default batchActionsStore;
