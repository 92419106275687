import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchExports, runExport as apiRunExport } from "services/apiExports";
import { setFlashMessage } from "services";

export const defaultState = {
  // status
  loading: false,
  // data
  effectiveFrom: new Date(),
  effectiveTo: new Date(),
  exports: []
};

export const exportsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),

  setFromRouter: action((_state, props) => {
    assign(enhancePaginationProps(props));
  }),

  fetchExports: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["page", "perPage"]);

    actions.setLoading(true);

    return fetchExports({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => {
        actions.setLoading(false);
      });
  }),

  runExport: action((state, _props) => {
    const { effectiveFrom, effectiveTo } = state;

    apiRunExport({ export: { effectiveFrom, effectiveTo } })
      .then((resp) => {
        setFlashMessage(resp.message);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  setData: action((state, data) => {
    state.exports = data.exports;
    state.total = parseInt(data.total);
  })
});
