import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState, useStoreActions } from "easy-peasy";
import { format as dateFormat, isToday, isPast } from "date-fns";
import { assign, compact } from "lodash";
import { Box, TableCell, Typography, Tooltip } from "@mui/material";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WarningIcon from "@mui/icons-material/Warning";
import classNames from "classnames";
import { daysOfWeek, formatDateUrl, formatDate } from "lib/dates";

const VEHICLE_ICONS = {
  van: <AirportShuttleIcon fontSize="small" />,
  bus: <DirectionsBusIcon fontSize="small" />,
  cab: <DirectionsCarIcon fontSize="small" />
};

const StopsTableHeader = ({ editStops, onColumnSelect, handleExpand }) => {
  const { date, linkDays, transportData } = useStoreState((s) => s.routeEditor);
  const { setVendorScheduleDialogOpen } = useStoreActions((s) => s.routeEditor);
  const weekDays = useMemo(() => daysOfWeek(date), [date]);
  const selectedDay = useMemo(() => formatDateUrl(date), [date]);
  const cls = useStyles({ editStops });

  const isSelected = (weekDay) => formatDateUrl(weekDay) === selectedDay;

  const openVendorScheduleDialog = (weekDay) => {
    if (editStops) return;
    if (isPast(weekDay) && !isToday(weekDay)) return;

    setVendorScheduleDialogOpen(true);
  };

  const colCls = (weekDay) => {
    return classNames(cls.weekDay, {
      [cls.currentDay]: !editStops && isToday(weekDay),
      [cls.selectedCol]: isSelected(weekDay) && (!isToday(weekDay) || editStops),
      [cls.wideCol]: isSelected(weekDay) && !editStops
    });
  };

  const tableHeaderLabel = (weekDay) => {
    const label = isToday(weekDay) ? "Today" : dateFormat(weekDay, "MMM do");
    const dayKey = formatDate(weekDay);

    return (
      <Box className={cls.headerContainer}>
        {isSelected(weekDay) && (
          <Box flex={1}>
            {!editStops && (
              <>
                <Box className={cls.expandBtn} onClick={() => handleExpand(true)} key="expand">
                  Expand All Students
                </Box>
                <Box className={cls.expandBtn} onClick={() => handleExpand(false)} key="collapse">
                  Collapse All Students
                </Box>
              </>
            )}
          </Box>
        )}
        <Box flex={1}>
          <Typography className={cls.date}>{label}</Typography>
          <Typography>{dateFormat(weekDay, "EEEE")}</Typography>
        </Box>
        {(isSelected(weekDay) ||
          (transportData[dayKey] &&
            (!transportData[dayKey]?.vendor || !transportData[dayKey]?.vehicle))) && (
          <Box flex={1} className={cls.vehicleInfo}>
            {!editStops && (
              <Box onClick={() => openVendorScheduleDialog(weekDay)}>
                <Box>
                  {transportData[dayKey]?.vendor && transportData[dayKey]?.vehicle ? (
                    VEHICLE_ICONS[transportData[dayKey]?.vehicle_type]
                  ) : (
                    <Tooltip
                      title={
                        !transportData[dayKey]?.vendor
                          ? "No vendor assigned"
                          : "No vehicle assigned"
                      }
                      arrow={true}
                    >
                      <WarningIcon className={cls.warningIcon} />
                    </Tooltip>
                  )}
                </Box>
                {isSelected(weekDay) && (
                  <Box className={cls.changeRunBtn}>
                    {compact([transportData[dayKey]?.vendor, transportData[dayKey]?.vehicle]).join(
                      " | "
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return weekDays.map((weekDay) => (
    <TableCell
      key={weekDay}
      scope="row"
      className={colCls(weekDay)}
      align="center"
      onClick={() => onColumnSelect(weekDay)}
    >
      {!linkDays || isSelected(weekDay) ? tableHeaderLabel(weekDay) : null}
    </TableCell>
  ));
};

const useStyles = makeStyles((theme) => ({
  weekDay: (props) =>
    assign(
      {
        padding: theme.spacing(1, 2),
        "&:hover": {
          cursor: "pointer"
        }
      },
      props.editStops ? { width: "13%" } : {}
    ),
  currentDay: {
    backgroundColor: theme.palette.background.lightBlue
  },
  wideCol: {
    minWidth: theme.spacing(50)
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end"
  },
  expandBtn: {
    fontSize: "0.75rem",
    textDecoration: "underline",
    color: theme.palette.primary.main
  },
  changeRunBtn: {
    fontSize: "0.7rem",
    color: theme.palette.primary.main
  },
  vehicleInfo: {
    alignSelf: "end",
    fontSize: "0.75rem"
  },
  warningIcon: {
    color: theme.custom.YELLOW
  },
  date: { fontWeight: theme.custom.BOLD },
  selectedCol: (props) =>
    props.editStops
      ? {
          borderBottomColor: theme.palette.primary.main,
          borderBottomWidth: theme.spacing(0.5),
          borderBottomStyle: "solid"
        }
      : { backgroundColor: theme.palette.background.lightBlue }
}));

export default StopsTableHeader;
