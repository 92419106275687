import React, { Fragment, useMemo } from "react";
import { useLocalStore, useStoreActions } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Link, Divider, Typography } from "@mui/material";
import { assign, get } from "lodash";
import { camelizeKeys } from "humps";
import { toDate } from "lib/dates";
import { pageRoutes } from "services/api";
import Spinner from "components/admin_v2/ui/Spinner";
import { CancelBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import { addTripStore, TRIP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import ErrorList from "components/admin_v2/ui/ErrorList";
import {
  getStopFrom,
  getStopWorkflow,
  isOtherRoute
} from "components/admin_v2/students/stores/stopsModel";
import PeriodSelector from "components/admin_v2/common/PeriodSelector";
import RouteNewForm from "components/admin_v2/students/trip/route/RouteNewForm";
import SchoolStopForm from "components/admin_v2/students/trip/stop/SchoolStopForm";
import RouteAnchorForm from "components/admin_v2/students/trip/route/RouteAnchorForm";
import useTripStyles from "components/admin_v2/students/trip/useStyles";
import I18n from "utils/i18n.js";

const defaultDataFrom = (request, routeType, tripType) => {
  const data = {
    anchor: request.anchorType,
    period: {
      startDate: request.period.startDate,
      endDate: toDate(request.changeReq.school.last_day),
      days: []
    },
    routeType,
    school: request.school,
    nonResetFields: ["routeType", "tripTypes"],
    tripTypes: routeType === "regular" ? TRIP_TYPES.regular : [tripType],
    day: toDate(request.changeReq.start_date)
  };
  const stopType = request.anchorType;
  const stopData = request.anchorData ? getStopFrom(request.anchorData) : null;
  const workflow = {
    createRoute: true,
    stops: stopData ? { [tripType]: { [stopType]: getStopWorkflow(stopData) } } : {}
  };
  const stops = request.anchorData
    ? {
        [tripType]: { [stopType]: stopData }
      }
    : {};
  return assign({}, data, { workflow, stops });
};

const AddRouteForm = ({ store, toggleForm }) => {
  const classes = useStyles();
  const cls = useTripStyles();
  const { setFlashMessage } = useStoreActions((s) => s.app);
  const [requestState, requestActions] = store;
  const tripType = requestState.changeReq.trip_type;
  const routeType = requestState.routeType;
  const defaultData = useMemo(() => defaultDataFrom(requestState, routeType, tripType), []);
  const tripStore = useLocalStore(() => addTripStore(defaultData));
  const [state, actions] = tripStore;

  const onSubmit = () => {
    actions
      // need to set keepTripType otherwise trip type on route creation for custom route will be direct
      .submit({ submitType: "route", keepTripType: true, withAnchor: true })
      .then((r) => {
        setFlashMessage({ message: r.message });
        requestActions.setNewRoute(r.route);
        toggleForm();
      })
      .catch((err) => {
        const errors = get(err.response, "data.errors", {
          base: I18n.t("route.create.message.fail")
        });
        actions.setErrors(camelizeKeys(errors));
      })
      .finally(() => actions.setLoading(false));
  };

  return (
    <Fragment>
      <Box className={classes.formWrapper} mt={2}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1" className={classes.newRoutelabel}>
              Create New Route
            </Typography>
          </Box>
          <Link href={pageRoutes.routesList()} target="_blank" underline="always">
            open routes in a new window
          </Link>
        </Box>
        <Divider variant="fullWidth" className={cls.divider} />
        {state.loading ? (
          <Spinner />
        ) : (
          <RouteNewForm cls={cls} store={tripStore}>
            <ErrorList errors={state.getTopLevelErrors} />
            <PeriodSelector
              period={state.period}
              referenceDate={state.day}
              label={false}
              onPeriodChange={actions.setPeriod}
              errors={state.errors}
              route={{ withRepeatWeek: true }}
              changeRequest={true}
              options={{ withoutDays: true, withoutOneDay: !requestState.oneDayChange }}
            />
            {isOtherRoute(state.routeType) && (
              <RouteAnchorForm
                cls={cls}
                store={tripStore}
                tripType={tripType}
                currentAnchor={requestState.anchorData}
                changeRequest={true}
                withTopMargin={true}
              />
            )}
            {state.routeType === "regular" &&
              state.tripTypes.map((tripType) => (
                <SchoolStopForm
                  key={`add-anchor-${tripType}`}
                  cls={cls}
                  store={tripStore}
                  tripType={tripType}
                />
              ))}
          </RouteNewForm>
        )}
        <Box mt={2}>
          <PrimaryBtn onClick={onSubmit} disabled={state.isDisabled} withMargin>
            Save Route
          </PrimaryBtn>
          <CancelBtn onClick={toggleForm}>Cancel</CancelBtn>
        </Box>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: "100%",
    backgroundColor: theme.custom.WHITE,
    padding: theme.spacing(1),
    border: `1px ${theme.custom.YELLOW} solid`,
    borderRadius: theme.spacing(0.5)
  },
  newRoutelabel: {
    fontWeight: theme.custom.BOLD,
    display: "inline-block"
  },
  separator: {
    marginTop: theme.spacing(1)
  }
}));

export default AddRouteForm;
