import React from "react";
import Box from "@mui/material/Box";

const StopPopup = ({ address, label, cls }) => {
  return (
    <Box className={cls.popup}>
      <p className={cls.label}>{label}</p>
      <p className={cls.popupInfo}>{address}</p>
    </Box>
  );
};

export default StopPopup;
