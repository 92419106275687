import React, { Fragment } from "react";
import { Typography, Box, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreState } from "easy-peasy";
import { parseISO } from "date-fns";
import { first } from "lodash";
import { pastAndNotToday } from "lib/dates";
import { searchStudents } from "services/apiStudents";
import PeriodSelector from "components/admin_v2/common/PeriodSelector";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { CancelBtn, PrimaryBtn } from "components/admin_v2/ui/Buttons";
import I18n from "utils/i18n.js";

const AddStudent = ({ route, stop, store }) => {
  const cls = useStyles();
  const { isUserAdmin, school, readOnly } = useStoreState((s) => s.app);
  const [state, actions] = store;

  const checkAssignedStops = (student) => actions.checkAssignedStops({ route, stop, student });
  const addStudentToStop = () => {
    actions.addStudentToStop();
    actions.resetStudent(true);
  };
  const toggleAddStudent = () => actions.resetStudent(true);

  return (
    <TableRow className={cls.addStudentRow}>
      <TableCell align="left" className={cls.addStudentCell}>
        {state.studentAddOpen ? (
          <Fragment>
            <AutocompleteSelect
              getOptions={searchStudents}
              onOptionSelect={checkAssignedStops}
              searchParams={{
                school_id: school?.id || first(route.school_ids),
                school_ids: route.school_ids
              }}
              labelField={"name_with_grade"}
              defaultSchool="all"
              defaultValue={state.student || ""}
            />
            {state.stopToCancel ? (
              <Box my={3}>
                <Typography className={cls.cancelHeading}>Change from:</Typography>
                <Typography className={cls.cancelRoute}>
                  {state.routeToCancel
                    ? `${state.routeToCancel.vehicle_type} | ${state.routeToCancel.name}`
                    : null}
                </Typography>
                <Typography>{state.stopToCancel.address}</Typography>
              </Box>
            ) : null}
            {isUserAdmin && state.student ? (
              <PeriodSelector
                referenceDate={parseISO(stop.date)}
                student={state.student}
                stopToAdd={stop.stop}
                period={state.period}
                onPeriodChange={actions.setPeriod}
                route={{ routeId: route.id, routeType: route.route_type, endDate: route.end_date }}
              />
            ) : null}
            {!isUserAdmin ? (
              <Typography className={cls.cancelInfoText}>
                This change applies to this date only
              </Typography>
            ) : null}
            <Box my={1}>
              <PrimaryBtn
                withMargin={true}
                disabled={
                  !state.student ||
                  pastAndNotToday(parseISO(stop.date)) ||
                  state.period?.onlyDateChecked === null
                }
                onClick={addStudentToStop}
              >
                {I18n.t("ui.btn.add_student")}
              </PrimaryBtn>
              <CancelBtn onClick={toggleAddStudent}>{I18n.t("ui.btn.cancel")}</CancelBtn>
            </Box>
          </Fragment>
        ) : (
          <PrimaryBtn
            size="large"
            disabled={pastAndNotToday(parseISO(stop.date)) || readOnly}
            onClick={toggleAddStudent}
            withExtraPadding={true}
          >
            {I18n.t("ui.btn.add_student")}
          </PrimaryBtn>
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  addStudentCell: {
    display: "block",
    width: "100%",
    padding: theme.spacing(1, 2)
  },
  addStudentRow: {
    verticalAlign: "top",
    backgroundColor: theme.palette.background.lightGrey,
    marginBottom: theme.spacing(0.5),
    display: "block"
  },
  cancelRoute: {
    fontWeight: theme.custom.BOLD
  },
  cancelInfoText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  cancelHeading: {
    marginBottom: theme.spacing(2)
  }
}));

export default AddStudent;
