import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Avatar from "@mui/material/Avatar";

const NumberChip = withStyles((theme) => ({
  root: {
    height: theme.spacing(4.3),
    width: theme.spacing(4.3),
    fontSize: "1rem",
    fontWeight: theme.custom.BOLD,
    color: theme.custom.DARK_GREY_1,
    backgroundColor: theme.custom.LIGHT_GREY
  }
}))((props) => {
  const cls = useStyles();
  return <Avatar className={cls[props.type || ""] || ""} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  terminus: {
    color: theme.custom.WHITE,
    backgroundColor: theme.custom.BLUE
  },
  intermediate: {
    backgroundColor: theme.palette.info.light
  },
  regularTracking: {
    border: `1px solid ${theme.custom.DARK_GREY_2}`,
    backgroundColor: theme.custom.WHITE,
    cursor: "pointer"
  },
  trackingNoData: {
    border: `1px solid ${theme.custom.DARK_GREY_2}`,
    backgroundColor: theme.custom.DARK_GREY_2,
    color: theme.custom.WHITE,
    cursor: "pointer"
  },
  trackingCompleted: {
    border: `1px solid ${theme.custom.DARK_GREY_2}`,
    backgroundColor: theme.custom.BLUE,
    color: theme.custom.WHITE,
    cursor: "pointer"
  }
}));

export default NumberChip;
