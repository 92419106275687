import React, { useEffect, Fragment } from "react";
import PeriodSelector from "components/admin_v2/common/PeriodSelector";
import ErrorList from "components/admin_v2/ui/ErrorList";
import RouteAnchorForm from "components/admin_v2/students/trip/route/RouteAnchorForm";
import RouteNewForm from "components/admin_v2/students/trip/route/RouteNewForm";
import SchoolStopForm from "components/admin_v2/students/trip/stop/SchoolStopForm";
import AddRouteStopForm from "./AddRouteStopForm";
import { TRIP_MODE_TYPES } from "../stores/modeChangeStore";
import { TRIP_TYPES } from "components/admin_v2/students/stores/addTripStore";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";
import { startsWith } from "lodash";

const AddRouteForm = ({
  cls,
  store,
  stopType,
  tripType,
  trip,
  tripDetails,
  tripIdx,
  updateTripDetails,
  periodEnabled = true,
  children
}) => {
  const [state, actions] = store;
  const isCustom = isOtherRoute(tripType);

  useEffect(() => {
    if (!periodEnabled && state.period.startDate !== state.period.endDate) {
      actions.setPeriodEndDate(state.period.startDate);
    }
  }, [periodEnabled]);

  const stopWithAnchor = (withTimePicker = false) => [
    <AddRouteStopForm
      key={`add-stop-${tripType}`}
      cls={cls}
      store={store}
      stopType={stopType}
      tripType={tripType}
    />,
    isCustom ? (
      <RouteAnchorForm
        currentAnchor={trip?.stop?.anchor}
        key={`add-anchor-${tripType}`}
        cls={cls}
        store={store}
        tripType={tripType}
      />
    ) : (
      <SchoolStopForm
        key={`add-anchor-${tripType}`}
        cls={cls}
        store={store}
        tripType={tripType}
        withTimePicker={withTimePicker}
      />
    )
  ];

  const stops = (isCustom ? [tripType] : TRIP_TYPES.regular).map((routeTripType) => {
    const withTimePicker =
      routeTripType === tripType && tripDetails.changeMode === TRIP_MODE_TYPES.create_one_off_route;
    return routeTripType === tripType ? (
      <Fragment key={`add-anchor-stop-${tripType}`}>
        {startsWith(tripType, "to")
          ? stopWithAnchor(withTimePicker)
          : stopWithAnchor(withTimePicker).reverse()}
      </Fragment>
    ) : (
      <SchoolStopForm
        key={`add-anchor-${routeTripType}`}
        cls={cls}
        store={store}
        tripType={routeTripType}
        withTimePicker={withTimePicker}
      />
    );
  });

  return (
    <RouteNewForm
      cls={cls}
      store={store}
      withRouteTypeChoice={false}
      tripIdx={tripIdx}
      updateTripDetails={updateTripDetails}
    >
      {children}
      <ErrorList errors={state.getTopLevelErrors} />
      {stops}
      {periodEnabled && (
        <PeriodSelector
          period={state.period}
          referenceDate={state.day}
          label={true}
          onPeriodChange={actions.setPeriod}
          route={{
            withDateRange: true,
            endDate: state.school?.last_day,
            withRepeatWeek: true,
            isNewRoute: true,
            tripType
          }}
          student={state.student}
          errors={state.errors}
          changeRequest={true}
        />
      )}
    </RouteNewForm>
  );
};

export default AddRouteForm;
