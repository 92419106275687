import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import { omit } from "lodash";

export const CancelBtn = withStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.custom.WHITE,
    padding: theme.spacing(0.75, 2),
    minWidth: theme.spacing(16)
  }
}))((props) => <Button variant="outlined" {...props} />);

export const PrimaryBtn = withStyles((theme) => ({
  root: {
    marginRight: (props) => (props.withMargin ? theme.spacing(1) : 0),
    minWidth: (props) => theme.spacing(props.minWidth || 16),
    padding: (props) => (props.withExtraPadding ? theme.spacing(1, 6) : theme.spacing(0.75, 2))
  }
}))((props) => (
  <Button
    variant="contained"
    color="primary"
    {...omit(props, "withMargin", "withExtraPadding", "minWidth")}
  />
));

export const DangerBtn = withStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.custom.RED,
    color: theme.custom.WHITE,
    padding: theme.spacing(0.75, 2),
    minWidth: theme.spacing(16),

    "&:hover": {
      backgroundColor: theme.custom.DARK_RED
    }
  }
}))((props) => <Button variant="contained" {...props} />);

export const OutlinedBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.custom.WHITE,
    border: `solid 1px ${theme.custom.DARK_GREY_2}`
  }
}))((props) => <Button variant="outlined" {...props} />);

export const CalendarBtn = (props) => {
  const { thin, ...rest } = props;
  const cls = useBtnStyles({ thin });
  return <Button className={cls.calendarBtn} variant="outlined" {...rest} />;
};

export const useBtnStyles = makeStyles((theme) => ({
  calendarBtn: {
    padding: (props) => (props.thin ? theme.spacing(1, 0.5) : theme.spacing(0.5, 1)),
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(2),
    "&.Mui-disabled": {
      opacity: 0.5
    }
  }
}));
