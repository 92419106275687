import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import Typography from "@mui/material/Typography";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import { fetchRoutesList } from "services/apiRoutes";

const RouteSelector = ({ route, onChange }) => {
  const [routes, setRoutes] = useState([]);
  const { school } = useStoreState((s) => s.app);
  const selectedRouteId = route ? route.parent_id || route.id : "";

  useEffect(() => {
    fetchRoutesList().then((response) => {
      setRoutes(response);
    });
  }, [school?.id]);

  const updateSelectedRoute = (e) => {
    const route = routes.find((r) => r.id == e.target.value);
    onChange(route);
  };

  if (!routes.length) {
    return null;
  }

  return (
    <SelectFilter
      id="routes"
      label={<Typography variant="body2">Switch Routes</Typography>}
      value={selectedRouteId}
      onChange={updateSelectedRoute}
      items={routes}
      disabledLabel={true}
      width={32}
      mr={0}
      variant="outlined"
    />
  );
};

export default RouteSelector;
