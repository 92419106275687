import React, { useCallback } from "react";
import { TableCell, Link, FormHelperText } from "@mui/material";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import cn from "classnames";
import {
  DeleteOutline,
  EditOutlined,
  CheckCircleRounded,
  CancelRounded
} from "@mui/icons-material";
import { EDIT_MODE } from "./stores/tableSettingStore";
import { commaErrors } from "lib/util";
import I18n from "utils/i18n.js";

export const IconLink = ({
  onClick,
  type,
  idx = -1,
  disabled = false,
  withoutTop = true,
  children
}) => {
  const cls = useTableFormStyles({ withoutTop });
  return (
    <Link
      component="button"
      color="secondary"
      onClick={onClick}
      className={cls.remove}
      data-idx={idx}
      underline="hover"
      disabled={disabled}
      title={I18n.t(`data_management.actions.${type}`)}
    >
      {children}
    </Link>
  );
};

export const TableActionEdit = ({ idx, name, editCallback, withoutTop = true }) => {
  const cls = useTableFormStyles();
  return (
    <TableCell
      className={[cls.cellBase, cls.iconCell, cls.cellTop].join(" ")}
      key={`${name}-${idx}-actions`}
    >
      <IconLink onClick={editCallback} idx={idx} withoutTop={withoutTop} type="edit">
        <EditOutlined />
      </IconLink>
    </TableCell>
  );
};

const TableActionCell = ({
  store,
  idx,
  saveCallback = null,
  removeCallback = null,
  disabled = false,
  verticalAlign = null,
  deleteOnly = false
}) => {
  const cls = useTableFormStyles();
  const [state, actions] = store;
  const data = state.getRow(idx);
  const isActive = disabled || !state.isValid(idx);
  const cellCls = cn(cls.cellBase, cls.iconCell, {
    [cls.cellTop]: verticalAlign === "top",
    [cls.cellPadding]: verticalAlign === "top"
  });

  const edit = useCallback((e) => {
    actions.updateMode({ idx: e.currentTarget.dataset.idx, editMode: EDIT_MODE.edit });
  }, []);

  const save = useCallback((e) => {
    const params = { idx: e.currentTarget.dataset.idx };
    saveCallback ? saveCallback(params) : actions.save(params).catch(() => {});
  }, []);

  const cancelChanges = useCallback((e) => {
    actions.cancelChanges({ idx: e.currentTarget.dataset.idx });
  }, []);

  const remove = useCallback((e) => {
    if (!data.entity.id || window.confirm("Are you sure?")) {
      const params = { idx: e.currentTarget.dataset.idx };
      removeCallback ? removeCallback(params) : actions.remove(params).catch(() => {});
    }
  }, []);

  const onlyDeleteFlow = () => {
    return (
      <IconLink onClick={remove} idx={idx} disabled={disabled} type="remove">
        <DeleteOutline />
      </IconLink>
    );
  };

  const normalFlowBtns = () => {
    return (
      <>
        {data.editMode === EDIT_MODE.view ? (
          <IconLink onClick={edit} idx={idx} disabled={disabled} type="edit">
            <EditOutlined />
          </IconLink>
        ) : (
          <>
            <IconLink onClick={save} idx={idx} disabled={isActive} type="save">
              <CheckCircleRounded />
            </IconLink>
            <IconLink onClick={cancelChanges} idx={idx} disabled={disabled} type="cancel">
              <CancelRounded />
            </IconLink>
            <IconLink onClick={remove} idx={idx} disabled={disabled} type="remove">
              <DeleteOutline />
            </IconLink>
          </>
        )}
        {data.errors?.editMode && (
          <FormHelperText error sx={{ mt: 1 }}>
            {commaErrors(data.errors.editMode)}
          </FormHelperText>
        )}
      </>
    );
  };

  return (
    <TableCell className={cellCls} key={`${state.entity.name}-${idx}-actions`}>
      {deleteOnly ? onlyDeleteFlow() : normalFlowBtns()}
    </TableCell>
  );
};

export default TableActionCell;
