import React from "react";
import { pick } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";

const BatchSelect = ({ store, actionsList }) => {
  const cls = useStyles();
  const [state, actions] = store;
  const optional = { ...pick(state, ["role"]) };

  const performAction = (e) => {
    if (window.confirm("Are you sure?")) {
      actions.submitBatchAction({ apiEndpoint: e.target.value.action, optional });
    }
  };

  return (
    <Select
      id="batch-select"
      variant="outlined"
      value={""}
      displayEmpty
      className={cls.select}
      onChange={performAction}
      disabled={state.batchIds.length == 0}
      classes={{ select: cls.select }}
    >
      <MenuItem value="">
        <Typography variant="body2">Batch Actions</Typography>
      </MenuItem>
      {actionsList.map((action) => (
        <MenuItem key={action.label} value={action} id={action.label}>
          {action.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    marginRight: theme.spacing(1),
    width: theme.spacing(20),
    fontSize: "14px",
    backgroundColor: theme.custom.WHITE
  }
}));

export default BatchSelect;
