import { action, computed, thunk } from "easy-peasy";
import { pick } from "lodash";
import { camelizeKeys } from "humps";
import easyState from "lib/easyState";
import { strPresent } from "lib/util";
import { createUser, saveUser, fetchGroupOptions } from "services/apiUsers";
import phoneNumbersStore from "components/admin_v2/common/stores/phoneNumbersStore";
import scopeStore from "components/admin_v2/common/stores/scopeStore";
import commGroupsStore from "components/admin_v2/common/stores/commGroupsStore";
import { setFlashMessage } from "services";

const REQUIRED = ["firstName", "lastName", "email"];

export const userFormStore = ({ role, user }) => {
  let details = {
    ...pick(user, [
      "firstName",
      "lastName",
      "email",
      "systemUser",
      "communicationAccess",
      "readOnly",
      "staffRole",
      "transportationVendorId",
      "restrictedAccess"
    ])
  };

  if (role === "admin" || role === "school_staff") {
    details = {
      ...details,
      schoolUsersAttributes: user?.schoolUsers || []
    };
  }

  return {
    ...easyState("user", user),
    ...easyState("details", details),
    ...easyState("errors"),
    ...easyState("loading", false),

    ...easyState("groupOptions", []),
    ...phoneNumbersStore(user?.phoneNumbers),
    ...commGroupsStore(user?.groupContacts),
    ...scopeStore(),

    schoolChecked: computed(
      (state) => (schoolId) =>
        !!state.details?.schoolUsersAttributes?.find((s) => s.schoolId === schoolId && !s._destroy)
    ),

    smsEnabled: computed(
      (state) => (schoolId) =>
        !!state.details?.schoolUsersAttributes?.find((s) => s.schoolId === schoolId)
          ?.smsNotifications
    ),

    accessValue: computed((state) => {
      if (state.details.systemUser) {
        return "system_user";
      } else if (state.details.readOnly) {
        return "read_only";
      } else {
        return "no_access";
      }
    }),

    initNewUser: action((state, params = {}) => {
      if (params.role === "admin") {
        state.user.schoolUsers = state.allSchools.map((s) => ({
          schoolId: s.id,
          smsNotifications: false
        }));
        state.details.schoolUsersAttributes = state.user.schoolUsers;
      }
      if (params.role === "school_staff" && params.schoolId) {
        state.user.schoolUsers = [{ schoolId: params.schoolId, smsNotifications: false }];
        state.details.schoolUsersAttributes = state.user.schoolUsers;
      }
    }),

    phoneRequired: computed((state) => state.groupContacts?.length > 0),

    isValid: computed((state) => {
      const { details, phoneRequired, phoneNumbers } = state;
      const validDetails = REQUIRED.every((field) => strPresent(details[field]?.toString()));
      const validPhones = state.isValidPhoneNumbers({ noPhoneAllowed: true });
      const validPhoneGroupsAssoc = !phoneRequired || phoneNumbers.length > 0;
      const validVendor = role === "vendor_staff" ? !!details.transportationVendorId : true;

      return validDetails && validPhones && validVendor && validPhoneGroupsAssoc;
    }),

    formData: computed((state) => {
      return {
        user: {
          ...state.details,
          phoneNumbersAttributes: state.phoneNumbersAttributes,
          groupContactsAttributes: state.groupContactsAttributes
        }
      };
    }),

    fetchAllSchoolsDistricts: thunk((actions, params = {}, h) => {
      const { user } = h.getState();
      actions.setLoading(true);
      actions
        .fetchAllScopes()
        .then((data) => {
          actions.setAllSchools(data[1].schools);
          actions.setAllDistricts(data[0].districts);
          if (user && !user.id) actions.initNewUser(params);
        })
        .catch((err) => setFlashMessage(err.message))
        .finally(() => actions.setLoading(false));
    }),

    fetchGroupOptions: thunk((actions, userId, _h) => {
      fetchGroupOptions(userId)
        .then((r) => actions.setGroupOptions(r.groups))
        .catch((err) => setFlashMessage(err.message));
    }),

    save: thunk(async (actions, _payload, h) => {
      const state = h.getState();
      if (!state.isValid) return;

      actions.setErrors(null);
      actions.setLoading(true);

      const data = state.formData;

      try {
        const user = state.user;

        if (user.id) {
          return await saveUser(role, user.id, data);
        } else {
          return await createUser(role, data);
        }
      } catch (err) {
        const errors = err.response?.data?.errors;
        actions.setErrors(camelizeKeys(errors));
        throw err;
      } finally {
        actions.setLoading(false);
      }
    })
  };
};
