import { action, thunk, computed } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { camelizeKeys } from "humps";
import { strPresent } from "lib/util";
import {
  fetchDismissalGroups,
  fetchDismissalGroup,
  archiveDismissalGroup,
  createDismissalGroup,
  saveDismissalGroup
} from "services/apiDismissalGroups";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { setFlashMessage } from "services";

const REQUIRED = ["name", "schoolId"];
const FORM_ATTRS = ["id", "name", "schoolId"];

export const defaultState = {
  // status
  addDismissalGroupDialogOpen: false,
  loading: false,
  submitDisabled: false,
  refresh: false,
  // filters
  schoolId: null,
  scope: "active",
  query: null,
  // data
  dismissalGroups: [],
  dismissalGroup: null,
  groupForm: {},
  errors: {}
};

export const dismissalGroupsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  // valid
  isValid: computed((state) => {
    const { groupForm } = state;
    const validGroup = REQUIRED.every((field) => strPresent(groupForm[field]?.toString()));
    return validGroup;
  }),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["schoolId", "scope"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchDismissalGroups: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope"]);

    actions.setLoading(true);

    return fetchDismissalGroups({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.dismissalGroups = data.dismissalGroups;
    state.collection = data.dismissalGroups;
    state.total = parseInt(data.total);
  }),

  addDismissalGroup: action((state, group) => {
    let dismissalGroupsList = state.dismissalGroups;
    dismissalGroupsList.unshift(group);
    state.dismissalGroups = dismissalGroupsList;
  }),

  removeDismissalGroup: action((state, archivedId) => {
    const newStopsList = state.dismissalGroups.filter((sl) => sl.id !== archivedId);
    state.dismissalGroups = newStopsList;
  }),

  save: thunk(async (actions, _payload, h) => {
    const state = h.getState();
    if (!state.isValid) return Promise.reject();

    actions.setErrors({});
    try {
      const { groupForm } = state;
      if (groupForm.id) {
        return await saveDismissalGroup(groupForm.id, groupForm);
      } else {
        return await createDismissalGroup(groupForm);
      }
    } catch (err) {
      const errors = err.response?.data?.errors;
      actions.setErrors(camelizeKeys(errors));
      throw err;
    }
  }),

  fetchDismissalGroup: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchDismissalGroup(id)
      .then((resp) => {
        actions.setDismissalGroup(resp.dismissalGroup);
        actions.setGroupForm({ ...pick(resp.dismissalGroup, FORM_ATTRS) });
      })
      .finally(() => actions.setLoading(false));
  }),

  toggleArchive: thunk((actions, id, h) => {
    const dismissalGroup = h.getState().dismissalGroup;
    actions.setLoading(true);

    archiveDismissalGroup(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (dismissalGroup) {
          actions.setDismissalGroup(resp.dismissalGroup);
        } else {
          actions.removeDismissalGroup(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
