import React from "react";
import NavTabs from "components/admin_v2/common/NavTabs";
import { pageRoutes } from "services/api";

const SchoolConfigTabs = () => {
  const tabs = [
    { label: "School Details", path: pageRoutes.schools(), exact: false },
    { label: "Dismissal Groups", path: pageRoutes.dismissalGroups(), exact: false },
    { label: "School Staff", path: pageRoutes.staffs(), exact: false },
    { label: "Comms Configuration", path: pageRoutes.commsGroups(), exact: false }
  ];

  return <NavTabs tabs={tabs} />;
};

export default SchoolConfigTabs;
