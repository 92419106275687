import React, { useEffect } from "react";
import { Box, Tabs, Tab, TableCell, TableRow } from "@mui/material";
import { upperFirst } from "lodash";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import RouteIcon from "@mui/icons-material/Route";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import { useLocalStore } from "easy-peasy";
import makeStyles from "@mui/styles/makeStyles";
import { advancedSearchStore } from "components/admin_v2/advanced_search/stores/advancedSearchStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes, slugsFor } from "services/api";
import RouterLink from "components/admin_v2/nav/RouterLink";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import useDebounce from "lib/useDebounce";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import SearchInput from "components/admin_v2/ui/SearchInput";
import Spinner from "components/admin_v2/ui/Spinner";
import I18n from "utils/i18n.js";

const TABS = [
  { label: "all", value: null },
  { label: "students", value: "student" },
  { label: "routes", value: "route" },
  { label: "users", value: "user" },
  { label: "schools", value: "school" },
  { label: "vendors", value: "vendor" }
];

const ICONS = {
  student: <SchoolIcon />,
  route: <RouteIcon />,
  user: <PersonIcon />,
  school: <ApartmentIcon />,
  district: <ApartmentIcon />,
  vendor: <EmojiTransportationIcon />
};

const AdvancedSearch = (props) => {
  const cls = useStyles();
  const store = useLocalStore(() => advancedSearchStore());
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);

  const updateQueryString = updateWithRouter(props);

  const handleTabChange = (_evt, idx) => {
    actions.setSearchableType(TABS[idx]?.value);
  };

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    if (!state.query.length) {
      actions.setResults([]);
      return;
    }

    actions.performSearch();
  }, [searchInput, state.searchableType, state.page, state.perPage]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const tabs = TABS.map((tab) => (
    <Tab
      key={tab.value}
      label={I18n.t(`ui.advanced_search.tabs.${tab.label}`)}
      className={cls.tab}
    />
  ));

  const selectedTabIdx = TABS.findIndex((tab) => tab.value === state.searchableType);
  const resultsType = state.searchableType
    ? `${upperFirst(state.searchableType)}s`
    : I18n.t("ui.advanced_search.tabs.all");

  const highlightMatches = (text, query) => {
    const regex = new RegExp(`(${query.replace("+", "\\+")})`, "gi");
    const highlightedText = text.replace(regex, "<strong>$1</strong>");
    return { __html: highlightedText };
  };

  const link = (result) => {
    let type = result.searchableType.toLowerCase();
    if (type === "user" && result.staffType) {
      type = result.staffType === "vendor" ? "vendorStaff" : "staff";
    }

    const path = pageRoutes[type](result.searchableId, slugsFor(result));
    return (
      <RouterLink to={path}>
        <span dangerouslySetInnerHTML={highlightMatches(result.name, state.query)} />
      </RouterLink>
    );
  };

  const row = (result) => (
    <TableRow key={`result-${result.id}`}>
      <TableCell className={cls.iconCell}>{ICONS[result.searchableType.toLowerCase()]}</TableCell>
      <TableCell>
        <Box>
          {link(result)}
          {result.discardedAt ? (
            <span className={cls.archived}>
              {result.searchableType.match(/student/i) ? "Unenrolled" : "Archived"}
            </span>
          ) : null}
        </Box>
        <Box dangerouslySetInnerHTML={highlightMatches(result.info, state.query)} />
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Box className={cls.wrapper}>
        <Box className={cls.searchContent}>
          <TopPageLayout
            title={I18n.t("ui.advanced_search.title")}
            bottomSubtitle={I18n.t("ui.advanced_search.subtitle")}
            actionButtons={[]}
          />
          <SearchInput cls={cls} value={state.query} onChange={onSearch} />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={selectedTabIdx} onChange={handleTabChange}>
              {tabs}
            </Tabs>
          </Box>
          <Box className={cls.total}>
            {I18n.t("ui.advanced_search.total", {
              rows: state.results.length,
              type: resultsType,
              total: state.total
            })}
          </Box>
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            tableCls={cls.resultsTable}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.results.map(row)}
          </PaginatedTable>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "grid",
    justifyItems: "center"
  },
  searchContent: {
    width: "60%"
  },
  searchField: {
    marginBottom: theme.spacing(5),
    width: "100%",
    backgroundColor: theme.custom.WHITE
  },
  total: {
    fontStyle: "italic"
  },
  resultsTable: {
    width: "60%"
  },
  iconCell: {
    width: theme.spacing(1)
  },
  archived: {
    fontSize: "0.8em",
    padding: theme.spacing(0, 1),
    backgroundColor: theme.custom.MEDIUM_GREY,
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5)
  }
}));

export default AdvancedSearch;
