import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const createAddress = async (params) => {
  const url = apiUrl(`addresses`);
  return await xhr.post(url, { address: params }, { transformData: true });
};

export const removeAddress = async (id, params) => {
  const url = apiUrl(`addresses/${id}/delete`);
  return await xhr.post(url, { address: params }, { transformData: true });
};

export const saveAddress = async (id, params) => {
  const url = apiUrl(`addresses/${id}`);
  return await xhr.post(url, { address: params }, { transformData: true });
};

export const searchAddress = async (params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`addresses/search?${query}`));
};
