import { I18n as I18nJS } from "i18n-js";
import translations from "translations.json";

const I18n = new I18nJS();
I18n.store(translations);
I18n.defaultLocale = "en";
I18n.enableFallback = true;
I18n.locale = "en";

export default I18n;
