import { thunk, action, computed } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { setFlashMessage } from "services";
import { signIn, signOut } from "services/apiSessions";
import { requestPassword, updatePassword } from "services/apiPasswords";
import { pageRoutes } from "services/api";
import I18n from "utils/i18n.js";
import history from "../../../../context/history";

export const defaultState = {
  // status
  loading: false,
  // data
  user: null,
  email: null,
  password: null,
  passwordConfirmation: null,
  resetPasswordToken: null,
  rememberMe: false
};

export const sessionsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),

  submitDisabled: computed((state) => state.loading || !state.email || !state.password),

  setFromRouter: action((state, _props) => {
    const defaultProps = pick(defaultState, ["resetPasswordToken"]);
    assign(state, defaultProps);
  }),

  signInUser: thunk(async (actions, _p, h) => {
    actions.setLoading(true);
    const state = h.getState();

    signIn({
      user: { email: state.email, password: state.password, rememberMe: state.rememberMe }
    })
      .then((r) => {
        window.location.pathname = r.redirect_path;
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  }),

  signOutUser: action((_state) => {
    signOut()
      .then(() => {
        window.location.replace("/users/sign_in");
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  requestNewPassword: action((state) => {
    requestPassword({ user: { email: state.email } })
      .then(() => {
        setFlashMessage(I18n.t("sessions.password_request.success"));
        history.push(pageRoutes.signIn());
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  changePassword: action((state) => {
    updatePassword({
      user: {
        password: state.password,
        passwordConfirmation: state.passwordConfirmation,
        resetPasswordToken: state.resetPasswordToken
      }
    })
      .then(() => {
        setFlashMessage(I18n.t("sessions.password_reset.success"));
        window.location.pathname = "/admin/curbside";
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  })
});
