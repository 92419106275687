import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useStyles from "./useStyles";

const SelectMulti = ({ label, options, schoolIds = null, onChange, placeholder }) => {
  const cls = useStyles();

  let opts;
  if (schoolIds !== null) {
    opts = options.filter((o) => schoolIds.includes(o.schoolId));
  } else {
    opts = options;
  }

  return (
    <FormControl variant="outlined" className={cls.field}>
      <FormLabel className={cls.label}>{label}: </FormLabel>
      <Autocomplete
        multiple
        options={opts}
        getOptionLabel={(option) => option.name}
        className={cls.wideSelect}
        size="small"
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} fullWidth variant="outlined" placeholder={placeholder} />
        )}
      />
    </FormControl>
  );
};

export default SelectMulti;
