import React from "react";
import { FormControl, FormLabel, FormHelperText, Select, MenuItem } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import { decamelize } from "humps";
import I18n from "utils/i18n.js";

const formatName = (name, school) => (school ? `${name} (${school})` : name);

const FormSelect = (props) => {
  const {
    itemType,
    options,
    value,
    onChange,
    cls,
    error,
    required,
    withoutLabel = false,
    errorNoMargin = false,
    disabled = false
  } = props;
  const clsKeys = props.clsKeys || ["field", "select"];
  const idFor = (label) => `adm-${itemType}-${label}`;
  const clsLocal = useStyles();
  const clsSelect = cn(cls[clsKeys[1]] || {}, clsLocal.select);
  const i18Key = `${props.i18Key || "form"}.${decamelize(itemType)}`;

  return (
    <FormControl
      variant="outlined"
      className={cls[clsKeys[0]] || {}}
      error={!!error}
      required={!!required}
    >
      {!withoutLabel && (
        <FormLabel id={idFor("label")} className={cls.label} required={!!required}>
          {I18n.t(`ui.${i18Key}.label`)}
        </FormLabel>
      )}
      <Select
        className={clsSelect}
        labelId={idFor("label")}
        id={idFor("select")}
        value={options.length ? value || "" : ""}
        name={itemType}
        onChange={onChange}
        displayEmpty
        disabled={disabled}
      >
        {itemType !== "reportFormat" && (
          <MenuItem key={idFor("all")} value="">
            <span className={cls.placeholder}>{I18n.t(`ui.${i18Key}.empty`)}</span>
          </MenuItem>
        )}
        {options.map(({ id, name, primary, school }) => (
          <MenuItem key={idFor(id)} value={id}>
            {primary ? (
              <span>
                {formatName(name, school)} <StarIcon fontSize="inherit" color="action" />
              </span>
            ) : (
              formatName(name, school)
            )}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText className={errorNoMargin ? clsLocal.noMarginLeft : null}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.custom.WHITE,
    "& .MuiSelect-selectMenu": {
      backgroundColor: theme.custom.WHITE
    }
  },
  noMarginLeft: {
    marginLeft: 0
  }
}));

export default FormSelect;
