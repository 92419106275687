import React, { Fragment } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Spinner from "components/admin_v2/ui/Spinner";
import useStyles from "./useStyles";
import useOptions from "./useOptions";
import TripType from "./TripType";
import TransportationTypes from "./TransportationTypes";
import Grades from "./Grades";
import SelectMulti from "./SelectMulti";
import SelectRoutes from "./SelectRoutes";
import AveryLabelsPreview from "../AveryLabelsPreview";

const DEFAULT_OPTIONS = {
  transportationTypes: [],
  schools: [],
  grades: [],
  dismissalGroups: [],
  routes: []
};

const AveryLabelsReportForm = ({ reportStore }) => {
  const cls = useStyles();
  const [state, actions] = reportStore;
  const form = state.averyForm;
  const { options, loading } = useOptions(state.reportType, DEFAULT_OPTIONS);

  if (loading) return <Spinner />;

  const updateForm = (data) => {
    actions.setAveryForm({ ...form, ...data });
  };

  const handleChange = (e) => {
    updateForm({ [e.target.name]: e.target.value });
  };

  const updateDismissalGroups = (_, groups) => {
    updateForm({ dismissalGroupIds: groups.map((g) => g.id) });
  };

  const updateRoutes = (routes) => {
    updateForm({ routeIds: routes.map((r) => r.id) });
  };

  return (
    <Fragment>
      <TripType form={form} onChange={handleChange} hasAll={true} cls={cls} />
      <TransportationTypes
        form={form}
        errors={state.reportErrors}
        options={options}
        updateForm={updateForm}
      />
      <Grades
        form={form}
        reportType="avery"
        errors={state.reportErrors}
        options={options}
        updateForm={updateForm}
      />
      <SelectMulti
        label="Dismissal Groups"
        options={options.dismissalGroups}
        schoolIds={state.schoolIds}
        onChange={updateDismissalGroups}
        placeholder={form.dismissalGroupIds.length === 0 ? "All groups" : ""}
      />
      <FormControl variant="outlined" className={cls.field}>
        <FormLabel className={cls.label}>Routes: </FormLabel>
        <SelectRoutes onOptionSelect={updateRoutes} schoolIds={state.schoolIds} />
      </FormControl>
      <AveryLabelsPreview reportStore={reportStore} />
    </Fragment>
  );
};

export default AveryLabelsReportForm;
