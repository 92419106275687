import { action, thunk } from "easy-peasy";
import { assign, pick } from "lodash";
import I18n from "utils/i18n.js";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchUsers, fetchUser, archiveUser, resetPasswordUser } from "services/apiUsers";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { setFlashMessage } from "services";

export const defaultState = {
  // status
  loading: false,
  addStaffDialogOpen: false,
  refresh: false,
  // filters
  role: "",
  scope: "active",
  query: null,
  // data
  users: [],
  user: null,
  errors: {}
};

export const usersStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["scope", "query"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchUsers: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope", "role"]);

    actions.setLoading(true);

    return fetchUsers(stateParams.role, { ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.users = data.users;
    state.collection = data.users;
    state.total = parseInt(data.total);
  }),

  addUser: action((state, userForm) => {
    let usersList = state.users;
    usersList.unshift(userForm);
    state.users = usersList;
  }),

  removeUser: action((state, id) => {
    const newUsersList = state.users.filter((sl) => sl.id !== id);
    state.users = newUsersList;
  }),

  fetchUser: thunk((actions, id, h) => {
    const role = h.getState().role;
    actions.setLoading(true);

    fetchUser(role, id)
      .then((resp) => {
        actions.setUser(resp.user);
      })
      .finally(() => actions.setLoading(false));
  }),

  resetPassword: action((state, id) => {
    if (window.confirm(I18n.t("ui.confirm_password_reset"))) {
      const role = state.role;

      resetPasswordUser(role, id)
        .then((resp) => {
          setFlashMessage(resp.message);
        })
        .catch((err) => {
          setFlashMessage(err.message);
        });
    }
  }),

  toggleArchive: thunk((actions, id, h) => {
    const state = h.getState();
    const { role, user } = state;
    actions.setLoading(true);

    archiveUser(role, id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (user) {
          actions.setUser(resp.user);
        } else {
          actions.removeUser(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
