import React from "react";
import { useStoreState } from "easy-peasy";
import NavTabs from "components/admin_v2/common/NavTabs";
import { pageRoutes } from "services/api";
import { compact } from "lodash";

const DataManagementTabs = () => {
  const { isUserAdmin, isRestrictedAdmin } = useStoreState((s) => s.app);

  const tabs = isUserAdmin
    ? compact([
        { label: "Student Imports", path: pageRoutes.studentImports(), exact: false },
        isRestrictedAdmin
          ? null
          : { label: "Route Imports", path: pageRoutes.routeImports(), exact: false },
        { label: "Students", path: pageRoutes.students(), exact: false },
        { label: "Routes", path: pageRoutes.routes(), exact: false },
        { label: "Text Blasts", path: pageRoutes.textBlasts(), exact: false },
        { label: "Stops", path: pageRoutes.stopLocations(), exact: false },
        { label: "Exports", path: pageRoutes.exports(), exact: false }
      ])
    : [
        { label: "Students", path: pageRoutes.students(), exact: false },
        { label: "Routes", path: pageRoutes.routes(), exact: false }
      ];

  return <NavTabs tabs={tabs} />;
};

export default DataManagementTabs;
