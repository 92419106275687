import React, { Fragment } from "react";
import {
  Box,
  OutlinedInput,
  FormLabel,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  ListItemText
} from "@mui/material";
import { startCase } from "lodash";
import classNames from "classnames";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";
import { ACCOMODATION_TYPES } from "utils/constants";
import I18n from "utils/i18n.js";

const MAX_NOTE_SIZE = 300;

const StudentAccommodationForm = ({ student, formStore, cls }) => {
  const [formState, formActions] = formStore;
  const { accommodation, errors } = formState;
  const clsTableForm = useTableFormStyles();

  if (!accommodation) return null;

  const { id = -1 } = student;

  const updateField = (key, e) => {
    const value = e.target.value;
    const newData = { ...accommodation, [key]: value };
    formActions.setAccommodation(newData);
  };

  return (
    <Fragment>
      <FormControl
        className={classNames(cls.field, cls.select)}
        error={!!errors?.accommodationTypes}
        variant="standard"
      >
        <InputLabel id={`student-${id}-accommodation-label`}>
          {I18n.t("student.accommodation.accommodation_type")}
        </InputLabel>
        <Select
          multiple
          labelId={`student-${id}-accommodation-label`}
          id={`student-${id}-accommodation-type`}
          value={accommodation.selectedAccommodationTypes || []}
          renderValue={(selected) => selected.map((x) => startCase(x)).join(", ")}
          onChange={(e) => updateField("selectedAccommodationTypes", e)}
        >
          {ACCOMODATION_TYPES.map((type) => (
            <MenuItem key={`ac-type-${type}`} value={type}>
              <GreenCheckbox
                checked={
                  accommodation.selectedAccommodationTypes
                    ? accommodation.selectedAccommodationTypes.includes(type)
                    : false
                }
              />
              <ListItemText primary={I18n.t(`student.accommodation.accommodation_types.${type}`)} />
            </MenuItem>
          ))}
        </Select>
        {errors?.accommodationTypes && (
          <FormHelperText>{errors?.accommodationTypes[0]}</FormHelperText>
        )}
      </FormControl>
      <FormControl className={cls.field} error={!!errors?.accommodationNote} fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="baseline">
          <FormLabel
            id={`student-${id}-accommodationNote-label`}
            className={clsTableForm.tableHeader}
          >
            {I18n.t("student.accommodation.accommodation_note")}
          </FormLabel>
          <Typography variant="subtitle2">
            {I18n.t("student.accommodation.remaining", {
              count: MAX_NOTE_SIZE - (accommodation.accommodationNote?.length || 0)
            })}
          </Typography>
        </Box>
        <OutlinedInput
          multiline
          minRows={4}
          id={`student-${id}-accommodationNote`}
          value={accommodation.accommodationNote || ""}
          onChange={(e) => updateField("accommodationNote", e)}
          error={!!errors?.accommodationNote}
          inputProps={{ maxLength: MAX_NOTE_SIZE }}
        />
        {errors?.accommodationNote && (
          <FormHelperText>{errors?.accommodationNote[0]}</FormHelperText>
        )}
      </FormControl>
    </Fragment>
  );
};

export default StudentAccommodationForm;
