import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import cn from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import I18n from "utils/i18n.js";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ArrivalBoardRow from "./ArrivalBoardRow";
import { BOARD_STATES } from "components/admin_v2/trips/stores/tripsStore";

const ArrivalBoard = ({ tripsStore }) => {
  const cls = useStyles();
  const [state, actions] = tripsStore;
  const { sortBy } = useStoreState((s) => s.avl);
  const { updateSorting } = useStoreActions((s) => s.avl);
  const trips = state.trips;
  const boardCollapsed = state.boardState === BOARD_STATES.collapsed;
  const boardFull = state.boardState === BOARD_STATES.full;
  const boardHalf = state.boardState === BOARD_STATES.half;

  const expandBtn = (
    <ArrowForwardIosIcon
      size="large"
      className={cls.expandBtn}
      onClick={() => actions.toggleBoardState(true)}
      data-test-id="expand-btn"
    />
  );
  const collapseBtn = (
    <ArrowBackIosIcon
      size="large"
      className={cls.expandBtn}
      onClick={() => actions.toggleBoardState(false)}
      data-test-id="collapse-btn"
    />
  );

  const headers = [
    {
      field: "status",
      label: I18n.t("trip.avl_columns.route"),
      visible: true,
      btn: boardCollapsed ? expandBtn : collapseBtn,
      cls: `${cls.routeCell} ${cls.routeHeader}`,
      skipSorting: true
    },
    {
      field: "pickup_time_end_full",
      label: I18n.t("trip.avl_columns.first_pickup"),
      visible: !boardCollapsed
    },
    { field: "pickup_eta", label: I18n.t("trip.avl_columns.first_pickup_eta"), visible: boardFull },
    {
      field: "dropoff_time_full",
      label: I18n.t("trip.avl_columns.last_dropoff"),
      visible: !boardCollapsed,
      btn: boardHalf ? expandBtn : null
    },
    {
      field: "dropoff_eta",
      label: I18n.t("trip.avl_columns.last_dropoff_eta"),
      visible: boardFull
    },
    { field: "vendor", label: I18n.t("trip.avl_columns.vendor"), visible: boardFull },
    { field: "vehicle_type", label: I18n.t("trip.avl_columns.mode"), visible: boardFull },
    {
      field: "vehicle_info",
      label: I18n.t("trip.avl_columns.vehicle"),
      visible: boardFull
    },
    {
      field: "tracking_link",
      label: I18n.t("trip.avl_columns.tracking_link"),
      visible: boardFull,
      btn: boardFull ? collapseBtn : null
    }
  ];

  const row = (trip) => <ArrivalBoardRow trip={trip} tripsStore={tripsStore} key={trip.id} />;

  return (
    <Table aria-label="simple table" className={cls.tripsTable}>
      <TableHead className={cls.headerClass}>
        <TableRow>
          {headers.map((header) => {
            return header.visible ? (
              <TableCell
                align="left"
                key={header.label}
                className={`${cls.headerCell} ${header.cls}`}
              >
                <Box className={cls.boardHeader}>
                  <Box className={cls.sortableHeader}>
                    {header.field === "status" && (
                      <SortIcon
                        fontSize="small"
                        className={cn(cls.sortIcon, {
                          [cls.statusFilter]: sortBy.field === "status"
                        })}
                        onClick={() => updateSorting(header.field)}
                      />
                    )}
                    {header.field === sortBy.field && header.field !== "status" && (
                      <>
                        {sortBy.order === "asc" ? (
                          <ArrowDownward className={cls.sortIcon} />
                        ) : (
                          <ArrowUpward className={cls.sortIcon} />
                        )}
                      </>
                    )}
                    <div
                      className={cn(cls.label, { [cls.sortable]: !header.skipSorting })}
                      onClick={() => updateSorting(header.field)}
                    >
                      {header.label}
                    </div>
                  </Box>
                  {header.btn}
                </Box>
              </TableCell>
            ) : null;
          })}
        </TableRow>
      </TableHead>
      <TableBody>{trips.map(row)}</TableBody>
    </Table>
  );
};

export default ArrivalBoard;

const useStyles = makeStyles((theme) => ({
  headerClass: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.custom.WHITE,
    zIndex: 1
  },
  tripsTable: {
    tableLayout: "fixed"
  },
  routeCell: {
    width: theme.spacing(25),
    minWidth: theme.spacing(25),
    maxWidth: theme.spacing(25)
  },
  boardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  routeHeader: {
    paddingLeft: `${theme.spacing(2)} !important`
  },
  headerCell: {
    height: theme.spacing(8),
    padding: theme.spacing(0.5),
    fontWeight: theme.custom.BOLD
  },
  label: {
    maxWidth: "90%"
  },
  expandBtn: {
    marginRight: theme.spacing(2),
    cursor: "pointer"
  },
  sortIcon: {
    fontSize: "0.87rem",
    position: "relative",
    cursor: "pointer"
  },
  sortable: {
    cursor: "pointer"
  },
  sortableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  statusFilter: {
    color: theme.custom.BLUE
  }
}));
