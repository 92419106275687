import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchVendorSchedules = async (params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`routes/${params.routeId}/vendor_schedules?${query}`), {
    transformData: true
  });
};

export const submitVendorSchedule = async (routeId, scheduleParams, opts = {}) => {
  const url = apiUrl(`routes/${routeId}/vendor_schedules`);
  return await xhr.post(
    url,
    { vendor_schedule: scheduleParams, opts: opts },
    { transformData: true }
  );
};

export const removeVendorSchedule = async (id) => {
  const url = apiUrl(`vendor_schedules/${id}/delete`);
  return await xhr.post(url);
};
