import makeStyles from "@mui/styles/makeStyles";
import { grey } from "@mui/material/colors";
import classNames from "classnames";

export const clsTable = (cls, isEditMode, opts = {}) => {
  const { verticalAlign, smallerText } = opts;
  const clsPrimary = classNames(cls.smCell, cls.cellBase, {
    [cls.cell]: isEditMode,
    [cls.cellView]: !isEditMode,
    [cls.cellTop]: verticalAlign === "top"
  });
  const clsSelect = classNames({
    [cls.cellSelect]: isEditMode,
    [cls.cellView]: !isEditMode,
    [cls.cellBase]: !isEditMode,
    [cls.cellTop]: verticalAlign === "top"
  });
  const clsCell = classNames(cls.cellBase, {
    [cls.cell]: isEditMode,
    [cls.cellView]: !isEditMode,
    [cls.cellTop]: verticalAlign === "top",
    [cls.cellSmallText]: smallerText
  });
  const clsMedium = classNames(cls.cell, cls.cellMedium);
  return { clsPrimary, clsSelect, clsCell, clsMedium };
};

export const useTableFormStyles = makeStyles((theme) => ({
  table: {
    "& .Mui-error": {
      whiteSpace: "pre-wrap"
    }
  },
  tableHeader: {
    color: theme.custom.DARK_GREY_2,
    fontSize: "0.85rem",
    fontWeight: theme.custom.BOLD,
    paddingTop: 0,
    paddingBottom: 0,
    "& sup": {
      lineHeight: 1
    }
  },
  smCell: {
    maxWidth: theme.spacing(7.5),
    padding: theme.spacing(0, 1),
    whiteSpace: "nowrap"
  },
  checkCell: {
    width: theme.spacing(6),
    padding: theme.spacing(0, 1)
  },
  iconCell: {
    verticalAlign: "middle",
    padding: theme.spacing(0)
  },
  cell: {
    fontSize: "1rem",
    verticalAlign: "top"
  },
  cellBase: {
    fontSize: "1rem"
  },
  cellDisabled: {
    color: theme.palette.text.disabled
  },
  cellLink: {
    verticalAlign: "middle",
    "[disabled]": {
      color: grey[400],
      cursor: "not-allowed"
    }
  },
  cellSelect: {
    fontSize: "1rem",
    verticalAlign: "bottom",
    minWidth: theme.spacing(40)
  },
  cellType: {
    width: theme.spacing(20)
  },
  cellLang: {
    width: theme.spacing(25)
  },
  cellOpt: {
    width: theme.spacing(15)
  },
  cellOptH: {
    width: theme.spacing(17)
  },
  cellXSmall: {
    width: theme.spacing(12)
  },
  cellSmall: {
    width: theme.spacing(20)
  },
  cellMedium: {
    width: theme.spacing(30)
  },
  cellLarge: {
    width: theme.spacing(45)
  },
  cellView: {
    verticalAlign: "inherit"
  },
  cellTop: {
    verticalAlign: "top"
  },
  cellPadding: {
    padding: theme.spacing(2)
  },
  cellSmallText: {
    fontSize: "0.8rem"
  },
  check: {
    position: "relative",
    top: "1rem"
  },
  field: {
    width: "100%"
  },
  cellStrikeThrough: {
    textDecoration: "line-through",
    color: theme.custom.RED
  },
  cellActive: {
    color: theme.custom.GREEN
  },
  btn: {
    margin: theme.spacing(2)
  },
  remove: {
    cursor: "pointer",
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    padding: "6px",
    display: "inline-block",
    borderRadius: theme.spacing(2.25),
    position: "relative",
    top: (props) => (props.withoutTop ? 0 : "1rem"),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  highlighted: {
    backgroundColor: theme.custom.LIGHT_YELLOW
  },
  marginTop: {
    marginTop: theme.spacing(2.5)
  },
  unstyledList: {
    listStyle: "none",
    marginLeft: 0,
    paddingLeft: 0
  },
  fixedTable: {
    tableLayout: "fixed"
  },
  textAlert: {
    color: theme.custom.RED
  }
}));
