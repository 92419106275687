import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchExports = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`exports?${query}`), { transformData: true });
};

export const runExport = async (params) => {
  const url = apiUrl(`exports/run_traversa_export`);
  return await xhr.post(url, params, { transformData: true });
};
