import { action, thunk, computed } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { camelizeKeys } from "humps";
import { strPresent } from "lib/util";
import {
  fetchPrograms,
  fetchProgram,
  archiveProgram,
  createProgram,
  saveProgram
} from "services/apiPrograms";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { setFlashMessage } from "services";

const REQUIRED = ["name", "abbreviation"];
const FORM_ATTRS = ["id", "name", "abbreviation"];

export const defaultState = {
  // status
  addProgramDialogOpen: false,
  loading: false,
  submitDisabled: false,
  refresh: false,
  // filters
  scope: "active",
  query: null,
  // data
  programs: [],
  program: null,
  programForm: {},
  errors: {}
};

export const programsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  isValid: computed((state) => {
    const { programForm } = state;
    const validGroup = REQUIRED.every((field) => strPresent(programForm[field]?.toString()));
    return validGroup;
  }),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["scope"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchPrograms: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "scope"]);

    actions.setLoading(true);

    return fetchPrograms({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.programs = data.programs;
    state.collection = data.programs;
    state.total = parseInt(data.total);
  }),

  addProgram: action((state, program) => {
    let programsList = state.programs;
    programsList.unshift(program);
    state.programs = programsList;
  }),

  removeProgram: action((state, archivedId) => {
    const newStopsList = state.programs.filter((sl) => sl.id !== archivedId);
    state.programs = newStopsList;
  }),

  save: thunk(async (actions, _payload, h) => {
    const state = h.getState();
    if (!state.isValid) return Promise.reject();

    actions.setLoading(true);
    actions.setErrors({});
    try {
      const { programForm } = state;
      if (programForm.id) {
        return await saveProgram(programForm.id, programForm);
      } else {
        return await createProgram(programForm);
      }
    } catch (err) {
      const errors = err.response?.data?.errors;
      actions.setErrors(camelizeKeys(errors));
      throw err;
    } finally {
      actions.setLoading(false);
    }
  }),

  fetchProgram: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchProgram(id)
      .then((resp) => {
        actions.setProgram(resp.program);
        actions.setProgramForm({ ...pick(resp.program, FORM_ATTRS) });
      })
      .finally(() => actions.setLoading(false));
  }),

  toggleArchive: thunk((actions, id, h) => {
    const program = h.getState().program;
    actions.setLoading(true);

    archiveProgram(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        if (program) {
          actions.setProgram(resp.program);
        } else {
          actions.removeProgram(id);
        }
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  })
});
