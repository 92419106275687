import React from "react";
import cn from "classnames";
import { Paper, Box, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { useTableFormStyles } from "components/admin_v2/common/useTableFormStyles";

const CommsGroupAssociations = ({ group }) => {
  const cls = useCommonPageStyles();
  const clsTableForm = useTableFormStyles();

  if (!group) return;

  const contacts = [
    { column: "ID", value: (c) => c.id },
    { column: "Type", value: (c) => c.type },
    { column: "Name", value: (c) => c.name },
    { column: "Contact Numbers", value: (c) => c.contactNumbers.join(", ") }
  ];

  return (
    <Box my={5}>
      <Paper className={cn("comm-group-contacts", cls.paper)}>
        <Box className={cls.subTitle}>{I18n.t(`school_config.comms_group.title.contacts`)}</Box>
        <Table aria-label="table" className={cls.table}>
          <TableHead>
            <TableRow>
              {contacts.map((col) => (
                <TableCell
                  className={clsTableForm.tableHeader}
                  key={`contact-header-${col.column}`}
                >
                  {col.column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {group?.contacts?.map((contact) => (
              <TableRow hover tabIndex={-1} key={`contact-row-${contact.id}-${contact.id}`}>
                {contacts.map((col) => (
                  <TableCell className={clsTableForm.cell} key={`contact-cell-${col.column}`}>
                    {col.value(contact)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};
export default CommsGroupAssociations;
