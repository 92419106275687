import React, { Fragment, useMemo } from "react";
import { Typography } from "@mui/material";
import { get } from "lodash";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import RouteCalendar from "components/admin_v2/common/RouteCalendar";
import { TRIP_MODE_TYPES } from "components/admin_v2/mode_editor/stores/modeChangeStore";
import { formatDateUrl } from "lib/dates";
import { fetchActiveRoutes } from "services/apiRoutes";
import I18n from "utils/i18n.js";

const renderRouteOptions = (labelField) => (props, option) => {
  const label = get(option, labelField);
  return option.with_schedule ? (
    <li {...props}>
      <span>
        {label || option.label}
        <Typography color="error" display="inline">
          {" "}
          <sup>**</sup>
        </Typography>
      </span>
    </li>
  ) : (
    <li {...props}>{label || option.label}</li>
  );
};

const RouteSelect = ({ store, extraParams = {}, disabled = false, labelField = "label" }) => {
  const [state, actions] = store;
  const resetOptions = useMemo(
    () => [state.tripTypes, state.rideType, state.routeReset, state.routeType],
    [[...state.tripTypes, state.rideType, state.routeReset, state.routeType].join("_")]
  );
  const oneoffRoutes = state.changeMode === TRIP_MODE_TYPES.create_new_oneoff_stop;

  const onChangeRoute = (value) => {
    if (value?.id != state.route?.id) {
      actions.resetState({ route: value || {}, changeMode: state.changeMode });
    }
  };

  return (
    <Fragment>
      <AutocompleteSelect
        getOptions={fetchActiveRoutes}
        onOptionSelect={onChangeRoute}
        searchParams={{
          date: formatDateUrl(state.period.startDate),
          route_type: state.routeType,
          school_id: state.currentSchoolId,
          trip_types: state.tripTypes,
          with_anchor: true,
          oneoff: oneoffRoutes,
          ...extraParams
        }}
        localSearch={true}
        placeholder={I18n.t("ui.route.choose")}
        renderOption={renderRouteOptions(labelField)}
        labelField={labelField}
        defaultValue={state.route?.id ? state.route : null}
        resetOptions={resetOptions}
        disabled={disabled}
      />
      {state.route?.with_schedule && (
        <RouteCalendar
          date={state.period.startDate}
          route={state.route}
          tripTypes={state.tripTypes}
        />
      )}
    </Fragment>
  );
};

export default RouteSelect;
