import React from "react";
import { Box } from "@mui/material";
import { get } from "lodash";
import AutocompleteSelect from "components/admin_v2/common/AutocompleteSelect";
import { searchAddress } from "services/apiAddresses";
import I18n from "utils/i18n.js";

const AddressNewForm = ({ store, stopType, tripType, returnTrip = false, disabled = false }) => {
  const [state, actions] = store;
  const stopAddress = get(state.stops, `${tripType}.${stopType}.stopLocation.address`);
  const readOnly =
    get(state.stops, `${tripType}.${stopType}.schoolAddress`, false) || returnTrip || disabled;

  const onChange = (data) => {
    const address = data ? data.description : null;
    actions.setStopLocation({ stopLocation: { address }, stopType, tripType });
  };

  return readOnly ? (
    <Box component="span">{stopAddress || I18n.t("ui.stop.new")}</Box>
  ) : (
    <AutocompleteSelect
      id={`add-new-addr-${tripType}-${stopType}`}
      getOptions={searchAddress}
      onOptionSelect={onChange}
      labelField={"description"}
      placeholder={I18n.t("ui.stop.new")}
      searchParams={{ school_id: state.currentSchoolId }}
      defaultValue={stopAddress || ""}
    />
  );
};

export default AddressNewForm;
