import React from "react";
import { Container, Grid, Box, Typography, Link } from "@mui/material";
import classNames from "classnames";
import Page from "./Page";
import Appbar from "./nav/Appbar";
import LoginAppbar from "./nav/LoginAppbar";
import FlashMessage from "components/admin_v2/ui/FlashMessage";
import { useStoreState } from "easy-peasy";
import { useContainerStyles, useHomeStyles } from "./useStyles";
import useAnalytics from "lib/useAnalytics";
import I18n from "utils/i18n.js";

const Home = (_props) => {
  const cls = useHomeStyles();
  const clsContainer = useContainerStyles();
  const { user } = useStoreState((s) => s.app);
  useAnalytics(user?.id);

  function Copyright() {
    return (
      <Typography variant="body2">
        {"©"} {new Date().getFullYear()} - Formative Technologies
      </Typography>
    );
  }

  const gridClasses = classNames(cls.mainGrid, clsContainer.mainGutterPadding, [
    { [cls.signInGrid]: !user }
  ]);

  const showAppBar = () => {
    return user ? <Appbar /> : <LoginAppbar />;
  };

  return (
    <Container maxWidth="xl" className={cls.mainContainer}>
      {showAppBar()}
      <Grid container className={gridClasses}>
        <FlashMessage />
        <Grid item md={12} className={cls.pageContainer}>
          <Page />
        </Grid>
      </Grid>
      <Box className={cls.footer}>
        <Link
          variant="body2"
          href="https://4mativ.org/privacy-policy"
          target="_blank"
          rel="noopener"
        >
          {I18n.t("ui.footer.privacy")}
        </Link>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Home;
