import React, { Fragment } from "react";
import { useLocalStore } from "easy-peasy";
import { Box, Grid, FormControl, FormLabel, OutlinedInput } from "@mui/material";
import { PrimaryBtn } from "components/admin_v2/ui/Buttons";
import { camelizeLocationSearch } from "lib/queryString";
import RouterLink from "components/admin_v2/nav/RouterLink";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { sessionsStore } from "./sessions/stores/sessionsStore";
import { pageRoutes } from "services/api";
import I18n from "utils/i18n.js";

const EditPassword = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => sessionsStore(camelizeLocationSearch(props)));

  const onSubmit = () => {
    actions.changePassword();
  };

  return (
    <Fragment>
      <Grid container className={cls.gridCenter}>
        <Grid item xs={6} sm={4} md={3} lg={3}>
          <Box className={cls.formSection}>
            <FormControl fullWidth>
              <FormLabel className={cls.label}>
                {I18n.t("sessions.form.new_password.label")}
              </FormLabel>
              <OutlinedInput
                className={cls.textField}
                value={state.password || ""}
                onChange={(e) => actions.setPassword(e.target.value)}
                type="password"
                name="password"
              />
            </FormControl>
          </Box>
          <Box className={cls.formSection}>
            <FormControl fullWidth>
              <FormLabel className={cls.label}>
                {I18n.t("sessions.form.password_confirmation.label")}
              </FormLabel>
              <OutlinedInput
                className={cls.textField}
                value={state.passwordConfirmation || ""}
                onChange={(e) => actions.setPasswordConfirmation(e.target.value)}
                type="password"
                name="password_confirmation"
              />
            </FormControl>
          </Box>
          <Box className={cls.actionsWrapper}>
            <PrimaryBtn
              onClick={onSubmit}
              disabled={!state.password || state.password !== state.passwordConfirmation}
              className={cls.signInBtn}
            >
              {I18n.t("sessions.buttons.update_password")}
            </PrimaryBtn>
          </Box>
          <Box>
            <RouterLink className={cls.sessionsLink} to={pageRoutes.signIn()}>
              {I18n.t("sessions.links.sign_in")}
            </RouterLink>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EditPassword;
