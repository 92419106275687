import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import { changeRequestsNotification } from "services/apiChangeRequests";
import useMessageBus from "lib/useMessageBus";

const QueuesNotification = () => {
  const cls = useStyles();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const path = history.location.pathname + history.location.search;

  useEffect(() => {
    changeRequestsNotification().then((r) => setShow(!!r.notification));
  }, [path]);

  useMessageBus({
    channel: "chreqs-notification",
    condition: true,
    received: (r) => setShow(!!r.notification)
  });

  return (
    <span className={cls.notifContainer}>
      {show && <span id="ch-req-notification" className={cls.notification} />}
    </span>
  );
};

const useStyles = makeStyles((theme) => ({
  notifContainer: {
    position: "relative"
  },
  notification: {
    backgroundColor: theme.custom.RED,
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    position: "absolute",
    left: "-2.25em",
    top: "-1.5em"
  }
}));

export default QueuesNotification;
