import React from "react";
import { useStoreState } from "easy-peasy";
import { uniqBy } from "lodash";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatDateUrl } from "lib/dates";
import SelectFilter from "components/admin_v2/ui/SelectFilter";
import DaySelector from "components/admin_v2/common/DaySelector";
import TripTypeRadioFilter from "components/admin_v2/common/TripTypeRadioFilter";
import { DIRECTION_OPTIONS } from "utils/constants";
import I18n from "utils/i18n.js";

const TripsTopFilters = ({ state, date, updateQueryString, cls }) => {
  const { schools } = useStoreState((s) => s.app);

  const selectDate = (newDate) => {
    updateQueryString({ date: formatDateUrl(newDate) });
  };
  const updateTimezone = (e) => {
    updateQueryString({ time_zone: e.target.value });
  };
  const updateRouteType = (e) => {
    updateQueryString({ route_type: e.target.value });
  };
  const updateDirection = (e) => {
    const val = e.target.value;
    let params = {};
    params.trip_type = val;

    if (val == "custom" && state.routeType != "all") {
      params.route_type = "all";
    }
    updateQueryString(params);
  };

  return (
    <Box className={cls.tripsFilters} ml={schools.length > 1 ? 17 : 4}>
      <DaySelector
        date={date}
        onSelectDate={selectDate}
        skipWeekends={true}
        key="date-filter"
        className={cls.filterItem}
        disabled={state.loading}
      />
      <SelectFilter
        value={state.tripType}
        onChange={updateDirection}
        items={DIRECTION_OPTIONS}
        variant="outlined"
        ml={0}
        id="trip-type"
        disabled={state.loading}
      />
      <SelectFilter
        label={<Typography variant="body2">{I18n.t("trip.avl_labels.time_zone")}</Typography>}
        value={state.timeZone}
        onChange={updateTimezone}
        items={uniqBy(
          uniqBy(schools, "time_zone_abbr").map((school) => ({
            id: school.time_zone_abbr,
            name: school.time_zone_abbr
          }))
        )}
        width={18}
        ml={1}
        variant="outlined"
        disabled={state.loading}
      />
      <TripTypeRadioFilter
        selected={state.routeType}
        onChange={updateRouteType}
        disabled={state.tripType == "custom" || state.loading}
      />
    </Box>
  );
};

export default TripsTopFilters;
