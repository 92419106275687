import React, { useEffect } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import { Box, Link, Paper, TableRow, TableCell } from "@mui/material";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import VendorSelector from "components/admin_v2/nav/VendorSelector";
import BatchSelect from "components/admin_v2/common/BatchSelect";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import AddVehicleDialog from "./AddVehicleDialog";
import RouterLink from "components/admin_v2/nav/RouterLink";
import VehiclesFilter from "components/admin_v2/vendor_config/vehicles/VehiclesFilter";
import SearchInput from "components/admin_v2/ui/SearchInput";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { vehiclesStore } from "components/admin_v2/vendor_config/stores/vehiclesStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import { batchArchive, batchUnarchive } from "services/apiVehicles";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const Vehicles = (props) => {
  const cls = useCommonPageStyles();
  const store = useLocalStore(() => vehiclesStore(camelizeLocationSearch(props)));
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);
  const updateQueryString = updateWithRouter(props);
  const { vendorId } = useStoreState((s) => s.vendor);
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchVehicles({
      sortByField: sortBy.field,
      sortByOrder: sortBy.order,
      vendorId: vendorId
    });
  }, [searchInput, state.page, state.perPage, state.scope, state.refresh, vendorId, sortBy]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  useEffect(() => {
    actions.setVehicleForm({ transportationVendorId: vendorId });
  }, [vendorId]);

  let actionsList = [];
  if (state.scope == "archived") {
    actionsList.push({ label: I18n.t("ui.btn.unarchive"), action: batchUnarchive });
  } else {
    actionsList.push({ label: I18n.t("ui.btn.archive"), action: batchArchive });
  }

  const updatePage = (_e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const toggleAddVehicleDialog = () => {
    actions.setAddVehicleDialogOpen(!state.addVehicleDialogOpen);
  };

  const headers = [
    {
      field: "batchCheck",
      label: (
        <GreenCheckbox
          checked={state.allSelected}
          onChange={(e) => actions.updateAllSelected(e.target.checked)}
        />
      ),
      skipSorting: true
    },
    { field: "id", label: "ID" },
    { field: "transportation_vendor", label: "Vendor" },
    { field: "name", label: "Name" },
    { field: "tracking_device", label: "Tracking Device" },
    { field: "license_plate", label: "License Plate" },
    { field: "capacity", label: "Capacity" },
    { field: "vehicle_type", label: "Vehicle Type" },
    { field: "", label: "" }
  ].map(sortableColumn);

  const row = (vehicle) => (
    <TableRow key={`vehicle-${vehicle.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <GreenCheckbox
          checked={state.isSelected(vehicle.id)}
          onChange={(e) => actions.updateSelectedIds({ isAdd: e.target.checked, id: vehicle.id })}
        />
      </TableCell>
      <TableCell component="th" style={{ width: "5%" }}>
        <RouterLink to={pageRoutes.vehicle(vehicle.id)} className={cls.tableLink}>
          {vehicle.id}
        </RouterLink>
      </TableCell>
      <TableCell style={{ width: "15%" }}>{vehicle.vendor}</TableCell>
      <TableCell style={{ width: "10%" }}>{vehicle.name}</TableCell>
      <TableCell style={{ width: "25%" }}>{vehicle.trackingDevice}</TableCell>
      <TableCell style={{ width: "10%" }}>{vehicle.licensePlate}</TableCell>
      <TableCell style={{ width: "5%" }}>{vehicle.capacity}</TableCell>
      <TableCell style={{ width: "10%" }}>{vehicle.vehicleType}</TableCell>
      <TableCell>
        <RouterLink to={pageRoutes.vehicle(vehicle.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.view")}
        </RouterLink>
        <RouterLink to={pageRoutes.vehicleEdit(vehicle.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.edit")}
        </RouterLink>
        <Link
          onClick={() => actions.toggleArchive(vehicle.id)}
          className={cls.tableLink}
          underline="hover"
        >
          {vehicle.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
        </Link>
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout
        title={I18n.t("vendor_config.title")}
        actionButtons={[<VendorSelector key="vendor-select" />]}
      />
      <VendorConfigTabs />
      <Box className={cls.btnsWrapper}>
        <OutlinedBtn onClick={toggleAddVehicleDialog}>
          {I18n.t("vendor_config.new_vehicle.btn")}
        </OutlinedBtn>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          <BatchSelect store={store} actionsList={actionsList} />
          <VehiclesFilter state={state} updateQueryString={updateQueryString} />
          <SearchInput cls value={state.query} onChange={onSearch} />
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.vehicles.map(row)}
          </PaginatedTable>
        )}
      </Paper>
      <AddVehicleDialog
        cls={cls}
        open={state.addVehicleDialogOpen}
        store={store}
        toggleDialog={toggleAddVehicleDialog}
      />
    </React.Fragment>
  );
};

export default Vehicles;
