import { action } from "easy-peasy";
import { isSameDay } from "date-fns";
import { assign, camelCase, isUndefined, map, keys } from "lodash";

const easyState = (name, defaultValue = null) => ({
  [name]: isUndefined(defaultValue) ? null : defaultValue,
  [camelCase(`set ${name}`)]: action((state, value) => {
    state[name] = value;
  })
});

// setters for date values, will check if this is the same day and will not cause re-render
// to force re-render use setRefreshDate method
export const easyStateDate = (name = "date", defaultValue = null) => ({
  [name]: isUndefined(defaultValue) ? null : defaultValue,
  [camelCase(`set ${name}`)]: action((state, value) => {
    if (!isSameDay(state[name], value)) state[name] = value;
  }),
  [camelCase(`set refresh ${name}`)]: action((state, value) => {
    state[name] = value;
  })
});

// initialize multiple values from defaultState
export const easyStateSetters = (obj, initialData = {}) => {
  return assign(
    {},
    ...map(keys(obj), (name) =>
      easyState(name, isUndefined(initialData[name]) ? obj[name] : initialData[name])
    )
  );
};

export default easyState;
