import React, { Fragment } from "react";
import { FormLabel, FormControlLabel, FormHelperText, RadioGroup } from "@mui/material";
import cn from "classnames";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { map } from "lodash";
import { strPresent, commaErrors } from "lib/util";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import SchoolsSelectForm from "components/admin_v2/common/SchoolsSelectForm";
import { STAFF_BOOLEAN_FIELDS } from "utils/constants";
import I18n from "utils/i18n.js";

const ACCESS_OPTIONS = ["no_access", "read_only", "system_user"];

const StaffDetailsForm = ({ store }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { details, errors } = state;

  const updateField = (key, e) => {
    let value;
    if (STAFF_BOOLEAN_FIELDS.includes(key)) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    const newDetails = { ...details, [key]: value };
    actions.setDetails(newDetails);
  };

  const updateAccess = (e) => {
    if (e.target.value == "system_user") {
      actions.setDetails({ ...details, systemUser: true, readOnly: false });
    } else if (e.target.value == "read_only") {
      actions.setDetails({ ...details, systemUser: false, readOnly: true });
    } else {
      actions.setDetails({ ...details, systemUser: false, readOnly: false });
    }
  };

  const accessOptionsRadio = map(ACCESS_OPTIONS, (opt) => (
    <FormControlLabel
      value={opt}
      key={`access-${opt}`}
      control={<GreenRadio />}
      label={I18n.t(`school_config.staffs.form.${opt}.label`)}
    />
  ));

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("school_config.staffs.form.email.label")}
                condition={strPresent(details.email)}
              />
            }
            value={details.email || ""}
            onChange={(e) => updateField("email", e)}
            className={cn("staff-email", cls.field)}
            error={!!errors?.email}
            helperText={errors?.email?.[0]}
            name="email"
          />
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("school_config.staffs.form.first_name.label")}
                condition={strPresent(details.firstName)}
              />
            }
            value={details.firstName || ""}
            onChange={(e) => updateField("firstName", e)}
            className={cn("staff-firstName", cls.field)}
            error={!!errors?.firstName}
            helperText={errors?.firstName?.[0]}
            name="first_name"
          />
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("school_config.staffs.form.last_name.label")}
                condition={strPresent(details.lastName)}
              />
            }
            value={details.lastName || ""}
            onChange={(e) => updateField("lastName", e)}
            className={cn("staff-lastName", cls.field)}
            error={!!errors?.lastName}
            helperText={errors?.lastName?.[0]}
            name="last_name"
          />
          <TextField
            variant="standard"
            label={I18n.t("school_config.staffs.form.staff_role.label")}
            value={details.staffRole || ""}
            onChange={(e) => updateField("staffRole", e)}
            className={cn("staff-staffRole", cls.field)}
            error={!!errors?.staffRole}
            helperText={errors?.staffRole?.[0]}
            name="staff_role"
          />
          <RadioGroup value={state.accessValue} onChange={(e) => updateAccess(e)} row>
            {accessOptionsRadio}
          </RadioGroup>
          <Box>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={details.communicationAccess}
                  onChange={(e) => updateField("communicationAccess", e)}
                  disabled={!details.communicationAccess}
                />
              }
              label={I18n.t("school_config.staffs.form.communication_access.label")}
            />
          </Box>
          <Box mt={2} className="staff-school">
            <FormLabel className={cls.label} error={!!errors?.schoolUsers}>
              {I18n.t("school_config.staffs.form.school.label")}
            </FormLabel>
            {errors?.schoolUsers && (
              <FormHelperText error={true}>{commaErrors(errors?.schoolUsers)}</FormHelperText>
            )}
            <SchoolsSelectForm store={store} cls={cls} withNotifications={false} />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StaffDetailsForm;
