import React, { useEffect } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import { Box, Paper, TableRow, TableCell, Dialog } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import VendorSelector from "components/admin_v2/nav/VendorSelector";
import BatchSelect from "components/admin_v2/common/BatchSelect";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import RouterLink from "components/admin_v2/nav/RouterLink";
import SearchInput from "components/admin_v2/ui/SearchInput";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { devicesStore } from "components/admin_v2/vendor_config/stores/devicesStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import { batchDelete } from "services/apiDevices";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const Devices = (props) => {
  const cls = useCommonPageStyles();
  const store = useLocalStore(() => devicesStore(camelizeLocationSearch(props)));
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);
  const updateQueryString = updateWithRouter(props);
  const { vendorId } = useStoreState((s) => s.vendor);
  const { sortBy, sortableColumn } = useSorting({ field: "name", order: "desc" });

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchDevices({
      sortByField: sortBy.field,
      sortByOrder: sortBy.order,
      vendorId: vendorId
    });
  }, [searchInput, state.page, state.perPage, state.scope, state.refresh, vendorId, sortBy]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  const actionsList = [{ label: I18n.t("ui.btn.delete"), action: batchDelete }];

  const updatePage = (_e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const headers = [
    {
      field: "batchCheck",
      label: (
        <GreenCheckbox
          checked={state.allSelected}
          onChange={(e) => actions.updateAllSelected(e.target.checked)}
        />
      ),
      skipSorting: true
    },
    { field: "id", label: "ID" },
    { field: "provider", label: "Provider" },
    { field: "name", label: "Name" },
    { field: "device_id", label: "Device Id", skipSorting: true },
    { field: "transportation_vendor", label: "Vendor", skipSorting: true },
    { field: "vehicle", label: "Vehicle" },
    { field: "identifier", label: "Identifier", skipSorting: true }
  ].map(sortableColumn);

  const row = (device) => (
    <TableRow key={`device-${device.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <GreenCheckbox
          checked={state.isSelected(device.id)}
          onChange={(e) => actions.updateSelectedIds({ isAdd: e.target.checked, id: device.id })}
        />
      </TableCell>
      <TableCell component="th" style={{ width: "5%" }}>
        {device.id}
      </TableCell>
      <TableCell style={{ width: "18%" }}>{device.provider}</TableCell>
      <TableCell style={{ width: "18%" }}>{device.name}</TableCell>
      <TableCell style={{ width: "18%" }}>{device.deviceId}</TableCell>
      <TableCell style={{ width: "18%" }}>{device.vendor}</TableCell>
      <TableCell style={{ width: "18%" }}>
        <RouterLink to={pageRoutes.vehicle(device.vehicleId)} className={cls.tableLink}>
          {device.vehicleName}
        </RouterLink>
      </TableCell>
      <TableCell style={{ width: "5%" }}>
        <InfoIcon
          style={{ cursor: "pointer" }}
          color="grey"
          onClick={() => actions.dialogOpen(device)}
        />
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout
        title={I18n.t("vendor_config.title")}
        actionButtons={[<VendorSelector key="vendor-select" />]}
      />
      <VendorConfigTabs />
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          <BatchSelect store={store} actionsList={actionsList} />
          <SearchInput cls value={state.query} onChange={onSearch} />
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.devices.map(row)}
          </PaginatedTable>
        )}
      </Paper>
      <Dialog open={state.openDialog} onClose={actions.dialogClose} maxWidth="md">
        {state.dialogDevice ? state.dialogDevice.identifier : null}
      </Dialog>
    </React.Fragment>
  );
};

export default Devices;
