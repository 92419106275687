import React, { useEffect } from "react";
import { useLocalStore, useStoreState } from "easy-peasy";
import { Box, Link, Paper, TableRow, TableCell } from "@mui/material";
import SearchInput from "components/admin_v2/ui/SearchInput";
import BatchSelect from "components/admin_v2/common/BatchSelect";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import VendorConfigTabs from "components/admin_v2/vendor_config/VendorConfigTabs";
import VendorsFilter from "components/admin_v2/vendor_config/vendors/VendorsFilter";
import RouterLink from "components/admin_v2/nav/RouterLink";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { vendorsStore } from "components/admin_v2/vendor_config/stores/vendorsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import { batchArchive, batchUnarchive } from "services/apiVendors";
import useSorting from "lib/useSorting";
import I18n from "utils/i18n.js";
import useDebounce from "lib/useDebounce";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const Vendors = (props) => {
  const isRestrictedAdmin = useStoreState((s) => s.app.isRestrictedAdmin);
  const cls = useCommonPageStyles();
  const store = useLocalStore(() => vendorsStore(camelizeLocationSearch(props)));
  const [state, actions] = store;
  const searchInput = useDebounce(state.query);
  const updateQueryString = updateWithRouter(props);
  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    actions.fetchVendors({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [searchInput, state.page, state.perPage, state.scope, sortBy, state.refresh]);

  useEffect(() => {
    updateQueryString({ page: 1, query: searchInput });
  }, [searchInput]);

  let actionsList = [];
  if (!isRestrictedAdmin) {
    if (state.scope == "archived") {
      actionsList.push({ label: I18n.t("ui.btn.unarchive"), action: batchUnarchive });
    } else {
      actionsList.push({ label: I18n.t("ui.btn.archive"), action: batchArchive });
    }
  }

  const updatePage = (_e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSearch = (e) => {
    actions.setQuery(e.target.value);
  };

  const headers = [
    {
      field: "batchCheck",
      label: (
        <GreenCheckbox
          checked={state.allSelected}
          onChange={(e) => actions.updateAllSelected(e.target.checked)}
        />
      ),
      skipSorting: true
    },
    { field: "id", label: "ID" },
    { field: "name", label: "Vendor" },
    { field: "provider", label: "Provider" },
    { field: "created_at", label: "Created" },
    { field: "", label: "" }
  ].map(sortableColumn);

  const row = (vendor) => (
    <TableRow hover key={`vendor-${vendor.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        <GreenCheckbox
          checked={state.isSelected(vendor.id)}
          onChange={(e) => actions.updateSelectedIds({ isAdd: e.target.checked, id: vendor.id })}
        />
      </TableCell>
      <TableCell component="th" style={{ width: "5%" }}>
        <RouterLink to={pageRoutes.vendor(vendor.id)} className={cls.tableLink}>
          {vendor.id}
        </RouterLink>
      </TableCell>
      <TableCell style={{ width: "20%" }}>{vendor.name}</TableCell>
      <TableCell style={{ width: "20%" }}>{vendor.provider}</TableCell>
      <TableCell style={{ width: "20%" }}>{vendor.createdAt}</TableCell>
      <TableCell>
        <RouterLink to={pageRoutes.vendor(vendor.id)} className={cls.tableLink}>
          {I18n.t("ui.btn.view")}
        </RouterLink>
        {!isRestrictedAdmin && (
          <>
            <RouterLink to={pageRoutes.vendorEdit(vendor.id)} className={cls.tableLink}>
              {I18n.t("ui.btn.edit")}
            </RouterLink>
            <Link
              onClick={() => actions.toggleArchive(vendor.id)}
              className={cls.tableLink}
              underline="hover"
            >
              {vendor.discarded ? I18n.t("ui.btn.unarchive") : I18n.t("ui.btn.archive")}
            </Link>
          </>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("vendor_config.title")} />
      <VendorConfigTabs />
      {!isRestrictedAdmin && (
        <Box className={cls.btnsWrapper}>
          <RouterLink className={cls.actionBtn} to={pageRoutes.vendorNew()}>
            <OutlinedBtn>{I18n.t("vendor_config.new_vendor.btn")}</OutlinedBtn>
          </RouterLink>
        </Box>
      )}
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          <BatchSelect store={store} actionsList={actionsList} />
          <VendorsFilter state={state} updateQueryString={updateQueryString} />
          <SearchInput cls value={state.query} onChange={onSearch} />
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.vendors.map(row)}
          </PaginatedTable>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default Vendors;
