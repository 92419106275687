import xhr from "lib/xhr";
import { vendorsApiUrl } from "./api";

export const submitVendorSchedule = async (routeId, scheduleParams, opts, params = {}) => {
  const url = vendorsApiUrl(`routes/${routeId}/vendor_schedules`);
  return await xhr.post(
    url,
    { vendor_schedule: scheduleParams, opts: opts, ...params },
    { transformData: true }
  );
};
