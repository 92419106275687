import React, { useEffect } from "react";
import {
  Paper,
  Box,
  TableRow,
  Typography,
  Table,
  TableHead,
  TableBody,
  Button,
  TableCell,
  Link,
  Tooltip
} from "@mui/material";
import cn from "classnames";
import { format, parseISO, isBefore, isPast, startOfDay, endOfDay } from "date-fns";
import {
  Warning,
  DeleteOutline,
  Sort,
  ArrowDropDownRounded,
  Check,
  Star,
  InfoOutlined
} from "@mui/icons-material";
import { useLocalStore } from "easy-peasy";
import useSorting from "lib/useSorting";
import Spinner from "components/admin_v2/ui/Spinner";
import I18n from "utils/i18n.js";
import { getPersistedFilter, setPersistedFilter } from "lib/persistedFilter";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import { useTableFormStyles, clsTable } from "components/admin_v2/common/useTableFormStyles";
import StyledTableCell from "components/admin_v2/ui/StyledTableCell";
import { vendorSchedulesStore } from "components/admin_v2/routes/stores/vendorSchedulesStore.jsx";
import SchedulesRadioFilter from "components/admin_v2/routes/vendor_schedules/SchedulesRadioFilter.jsx";
import VendorScheduleDialog from "components/admin_v2/routes/vendor_schedules/VendorScheduleDialog.jsx";
import WarningLabel from "components/admin_v2/common/WarningLabel.jsx";

const LABELS_I18N = "data_management.assigned_vehicles.headers";
const DATE_FORMAT = "MMM d, yyyy";

const IconLink = ({ onClick, disabled = false, children }) => {
  return (
    <Link
      component="button"
      color="secondary"
      onClick={onClick}
      underline="hover"
      disabled={disabled}
      title={I18n.t(`data_management.actions.delete`)}
    >
      {children}
    </Link>
  );
};

const WarningHint = ({ cls, label }) => {
  return (
    <Box className={cls.warningLabel} mt={2} mb={2}>
      <Warning fontSize="small" className={cn(cls.icon, cls.warningLabelIcon)} />
      {label}
    </Box>
  );
};

const VendorScheduleRow = ({ schedule, cls, tableCls, state, actions, route }) => {
  const { clsCell } = clsTable(tableCls, { verticalAlign: "top", smallerText: true });
  const startDate = parseISO(schedule.startDate);
  const endDate = parseISO(schedule.endDate);

  const unassignedWarning =
    isPast(endOfDay(endDate)) || schedule.booking ? (
      I18n.t(`data_management.assigned_vehicles.no_vehicle`)
    ) : (
      <WarningLabel label={I18n.t(`data_management.assigned_vehicles.no_vehicle`)} />
    );

  const effectiveDates = () => {
    if (schedule.tilEnd) {
      return I18n.t(`data_management.assigned_vehicles.dates.til_end`, {
        start_date: format(startDate, DATE_FORMAT)
      });
    } else if (!schedule.permanent) {
      return format(endDate, DATE_FORMAT);
    } else {
      return I18n.t(`data_management.assigned_vehicles.dates.to_date`, {
        start_date: format(startDate, DATE_FORMAT),
        end_date: format(endDate, DATE_FORMAT)
      });
    }
  };

  const vehicleName = (schedule, type) => {
    const isInUseToday = schedule.id === route[`${type}_vendor_schedule_id`];
    const vehicle = schedule[`${type}Vehicle`];

    if (vehicle) {
      return (
        <>
          {isInUseToday && (
            <Tooltip
              title={I18n.t(`data_management.assigned_vehicles.tooltips.today`)}
              arrow={true}
            >
              <Star className={cls.icon} />
            </Tooltip>
          )}
          {vehicle}
        </>
      );
    } else if (!vehicle) {
      return unassignedWarning;
    } else if (state.anotherVendor(schedule, type)) {
      return <WarningLabel label={I18n.t(`data_management.assigned_vehicles.another_vendor`)} />;
    } else {
      return unassignedWarning;
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell className={cn(clsCell, tableCls.cellXSmall)}>
        {schedule.currentPermanent && (
          <Tooltip
            title={I18n.t(`data_management.assigned_vehicles.tooltips.permanent`)}
            arrow={true}
          >
            <Check />
          </Tooltip>
        )}
      </TableCell>
      <TableCell className={cn(clsCell, tableCls.cellLarge)}>{effectiveDates()}</TableCell>
      <TableCell className={cn(clsCell, tableCls.cellMedium)}>{schedule.vendor}</TableCell>
      <TableCell className={clsCell}>{vehicleName(schedule, "inbound")}</TableCell>
      <TableCell
        className={cn(clsCell, tableCls.cellMedium, {
          [cls.crossedOut]: !schedule.applyInboundPair
        })}
      >
        {route.inbound_pair?.route?.name}
      </TableCell>
      <TableCell className={cn(clsCell, tableCls.cellMedium)}>
        {vehicleName(schedule, "outbound")}
      </TableCell>
      <TableCell
        className={cn(clsCell, tableCls.cellMedium, {
          [cls.crossedOut]: !schedule.applyOutboundPair
        })}
      >
        {route.outbound_pair?.route?.name}
      </TableCell>
      <TableCell className={cn(clsCell, tableCls.cellXSmall)}>{schedule.note}</TableCell>
      <TableCell className={cn(clsCell, tableCls.cellXSmall)}>
        <IconLink
          onClick={() => actions.deleteSchedule(schedule.id)}
          disabled={isBefore(startOfDay(parseISO(schedule.startDate)), startOfDay(new Date()))}
          type="remove"
        >
          <DeleteOutline />
        </IconLink>
      </TableCell>
    </TableRow>
  );
};

const VendorSchedules = ({ props, route }) => {
  const cls = useCommonPageStyles();
  const tableCls = useTableFormStyles();
  const store = useLocalStore(() =>
    vendorSchedulesStore({
      dateScope:
        getPersistedFilter({ app: "routes", page: "routeEdit", filter: "dateScope" }) || "today"
    })
  );
  const [state, actions] = store;

  const { sortBy, sortableColumn } = useSorting(
    {
      field: "effectiveDates",
      order: "asc",
      callback: actions.sortBy
    },
    { app: "vendorSchedules", page: "routeEdit" }
  );

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    if (!route) return;

    actions.fetchVendorSchedules({ routeId: route.id, sortBy: sortBy });
  }, [route?.id, state.dateScope, state.refreshVendorSchedules]);

  const closeDialog = () => {
    actions.setVendorScheduleDialogOpen(false);
  };

  const updateQueryString = updateWithRouter(props);

  const headers = [
    {
      field: "permanent",
      label: (
        <Sort
          fontSize="small"
          className={cn(cls.sortIcon, {
            [cls.statusFilter]: sortBy.field === "status"
          })}
        />
      ),
      tooltip: I18n.t(`${LABELS_I18N}.permanent`),
      clsName: "cellXSmall"
    },
    { field: "startDate", label: I18n.t(`${LABELS_I18N}.dates`), clsName: "cellLarge" },
    { field: "vendor", label: I18n.t(`${LABELS_I18N}.vendor`) },
    { field: "inboundVehicle", label: I18n.t(`${LABELS_I18N}.inbound_vehicle`) },
    { field: "inboundPair", label: I18n.t(`${LABELS_I18N}.inbound_pair`) },
    { field: "outboundVehicle", label: I18n.t(`${LABELS_I18N}.outbound_vehicle`) },
    { field: "outboundPair", label: I18n.t(`${LABELS_I18N}.outbound_pair`) },
    {
      field: "notes",
      label: I18n.t(`${LABELS_I18N}.notes`),
      skipSorting: true,
      clsName: "cellMedium"
    },
    { field: "actions", label: I18n.t(`${LABELS_I18N}.actions`), skipSorting: true }
  ].map(sortableColumn);

  const rows = state.vendorSchedules.map((vs) => (
    <VendorScheduleRow
      key={`schedule-${vs.id}-row`}
      schedule={vs}
      cls={cls}
      tableCls={tableCls}
      state={state}
      actions={actions}
      route={route}
    />
  ));

  const onFilterChange = (e) => {
    const val = e.target.value;
    setPersistedFilter({ app: "routes", page: "routeEdit", filter: "dateScope", value: val });
    actions.setDateScope(val);
    updateQueryString({ date_scope: val });
  };

  const onVendorScheduleAdd = () => {
    actions.setVendorScheduleDialogOpen(false);
    actions.setRefreshVendorSchedules(!state.refreshVendorSchedules);
  };

  return (
    <>
      <Paper className={cn(cls.paper, cls.mt)}>
        {state.schedulesLoading ? (
          <Spinner />
        ) : (
          <>
            <Box display="flex">
              <ArrowDropDownRounded className="caret" fontSize="large" />
              <Typography variant="h1" gutterBottom>
                {I18n.t("data_management.assigned_vehicles.title")}
              </Typography>
              <aside className={cls.itemsCount}>
                ({I18n.t("ui.items", { count: state.vendorSchedules.length })})
              </aside>
            </Box>
            <Box className={cls.hint}>
              <InfoOutlined fontSize="small" />
              <Box ml={1}>
                Note: 1-day assignments are exceptions to permanent assignments. These exceptions
                always override the permanent assignment for that day.
              </Box>
            </Box>
            <SchedulesRadioFilter selected={state.dateScope} onChange={onFilterChange} />
            <Table aria-label="table" className={tableCls.fixedTable}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <StyledTableCell
                      className={cn(tableCls.tableHeader, tableCls[header.clsName], {
                        [tableCls.cellXSmall]: header.size == "xsmall"
                      })}
                      key={header.key}
                    >
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
            <Box display="flex">
              <Button
                className={tableCls.btn}
                variant="contained"
                color="secondary"
                onClick={() => actions.setVendorScheduleDialogOpen(true)}
              >
                Add New Assignment
              </Button>
              {state.vendorSchedules.length === 0 && (
                <WarningHint
                  cls={cls}
                  label={I18n.t("data_management.assigned_vehicles.no_schedules")}
                />
              )}
              {state.noFallbackError && (
                <WarningHint
                  cls={cls}
                  label={I18n.t("data_management.assigned_vehicles.errors.no_prev_schedule")}
                />
              )}
            </Box>
          </>
        )}
      </Paper>
      <VendorScheduleDialog
        open={state.vendorScheduleDialogOpen}
        editRoute={route}
        page="route"
        onClose={closeDialog}
        onSuccess={onVendorScheduleAdd}
      />
    </>
  );
};

export default VendorSchedules;
