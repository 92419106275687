import React, { Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";

const RouteStopsHint = () => {
  const cls = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        color="primary"
        aria-label="Hint"
        size="large"
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Box display="flex">
          <Box className={cls.popoverContent}>
            Linking days allows you edit all times for a given stop at once. All changes will also
            affect future stop times for this route.
          </Box>
          <Box>
            <IconButton
              key="close"
              aria-label="close"
              color="primary"
              onClick={handleClose}
              size="large"
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    padding: theme.spacing(2),
    paddingRight: 0,
    maxWidth: 250
  }
}));

export default RouteStopsHint;
