import React, { useEffect } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useStoreActions, useStoreState } from "easy-peasy";

const VendorSelector = ({ placeholder = "Select a Vendor" }) => {
  const { vendorId, vendors } = useStoreState((s) => s.vendor);
  const { changeVendor, updateVendors } = useStoreActions((s) => s.vendor);
  const cls = useStyles();

  useEffect(() => {
    updateVendors();
  }, []);

  const handleChange = (e) => {
    changeVendor(e.target.value);
  };

  return (
    <div className={cls.wrapper}>
      <FormControl variant="outlined" className={cls.selector}>
        <Select
          value={vendors.length < 1 ? "" : vendorId || ""}
          onChange={handleChange}
          displayEmpty
          classes={{ select: cls.selector }}
        >
          <MenuItem value="">
            <Typography variant="body2">{placeholder}</Typography>
          </MenuItem>
          {vendors?.map(({ id, name }) => (
            <MenuItem key={`vendor-sel-${id}`} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    position: "relative",
    top: theme.spacing(1.5)
  },
  selector: {
    width: theme.spacing(40),
    backgroundColor: theme.custom.WHITE,
    borderRadius: theme.spacing(0.5)
  }
}));

export default VendorSelector;
