import React from "react";
import RequestRouteForm from "../route/RequestRouteForm";

const RequestNewStopForm = ({ store, tripType, trip, stopType, cls }) => {
  return (
    <RequestRouteForm
      store={store}
      stopType={stopType}
      tripType={tripType}
      cls={cls}
      isNewStop={true}
      trip={trip}
      withTopMargin={false}
    />
  );
};

export default RequestNewStopForm;
