import React from "react";
import { useStoreState } from "easy-peasy";
import cn from "classnames";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ErrorIcon from "@mui/icons-material/Error";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Checkbox, Link, TableCell, TableRow, Tooltip } from "@mui/material";
import OverflowTooltip from "components/admin_v2/ui/OverflowTooltip";
import RouterLink from "components/admin_v2/nav/RouterLink";
import GreenSwitch from "components/admin_v2/ui/GreenSwitch";
import { pageRoutes } from "services/api";
import { tripTz } from "./tripHelpers";
import { BOARD_STATES } from "components/admin_v2/trips/stores/tripsStore";
import ActualTime from "./ActualTime";
import { makeStyles } from "@mui/styles";
import I18n from "utils/i18n.js";
import TripStatusTooltip from "components/admin_v2/trips/shared/TripStatusTooltip";

const ArrivalBoardRow = ({ trip, tripsStore }) => {
  const cls = useStyles();
  const [state, actions] = tripsStore;
  const { readOnly } = useStoreState((s) => s.app);
  const boardCollapsed = state.boardState === BOARD_STATES.collapsed;
  const boardFull = state.boardState === BOARD_STATES.full;
  const isActive = state.activeTrips.includes(trip);
  const timeWrapperCls = cn(cls.cellContent, {
    [cls.shiftLeft]: trip.trip_type === "to_school"
  });

  const toggleTracking = () => {
    actions.toggleTracking(trip.id);
  };

  const copyTrackingLink = () => {
    actions.copyTrackingLink(trip);
  };

  const vehiclePopupLink = (linkContent) => {
    return (
      <Link
        className={cls.tripLink}
        onClick={() => actions.updateEditTrip(trip)}
        underline="hover"
        color="inherit"
        disabled={readOnly}
        id={`edit-trip-${trip.id}`}
      >
        {linkContent}
      </Link>
    );
  };

  const updateActiveTrips = (trip) => {
    return (e) => {
      const checked = e.target.checked;
      let chTrips = state.activeTrips;
      const index = chTrips.indexOf(trip);

      if (index === -1 && checked) {
        chTrips.push(trip);
      } else if (index !== -1 && !checked) {
        chTrips.splice(index, 1);
      }

      actions.setActiveTrips([...chTrips]);
    };
  };

  const pairedInfo = () => {
    if (!trip.paired_trip) return;

    if (trip.pairing_with_adjustment)
      return (
        <Tooltip
          arrow
          title={I18n.t("trip.tooltips.pairing_with_adjustment", { pair: trip.paired_trip })}
        >
          <InsertLinkIcon className={cls.warningIcon} />
        </Tooltip>
      );

    return (
      <Tooltip arrow title={I18n.t("trip.tooltips.pairing", { pair: trip.paired_trip })}>
        <InsertLinkIcon className={cls.noticeIcon} />
      </Tooltip>
    );
  };

  const trackingSwitch = () => {
    if (trip.ended_at) {
      return "Route Completed";
    }

    let disabled = false;
    if ((!trip.vendor || !trip.vehicle_id) && !trip.booking) {
      disabled = true;
    }

    return (
      <>
        <GreenSwitch
          checked={!!trip.tracking_allowed_at}
          onChange={toggleTracking}
          disabled={disabled}
        />
        {trip.tracking_allowed_at && (
          <Link className={cls.copyLink} onClick={copyTrackingLink}>
            Copy link
          </Link>
        )}
      </>
    );
  };

  return (
    <TableRow key={`trip-${trip.id}`} className={isActive ? cls.checkedRow : ""}>
      <TableCell component="td" align="left" className={cls.routeCell}>
        <TripStatusTooltip trip={trip} cls={cls} />
        <Box display="flex">
          <Checkbox
            checked={isActive}
            onChange={updateActiveTrips(trip)}
            value={trip}
            classes={{ root: cls.check, checked: cls.checked }}
          />
          <OverflowTooltip
            text={trip.route_name}
            wrapperCls={cls.tripLinkWrapper}
            boardState={state.boardState}
          >
            <Link onClick={() => actions.updateSelectedTrip(trip)} underline="hover">
              {trip.route_name}
            </Link>
          </OverflowTooltip>
          {pairedInfo()}
        </Box>
      </TableCell>
      {!boardCollapsed ? (
        <TableCell component="td" align="left">
          <Box className={cls.cellContent}>
            {trip.pickup_time_end}
            {tripTz(trip.time_zone_abbr)}
            {trip.trip_type === "from_school" ? <ArrowRightIcon className={cls.tripIcon} /> : null}
          </Box>
        </TableCell>
      ) : null}
      {boardFull ? (
        <TableCell component="td" align="left">
          {trip.status_by_time.id === "alert" ? (
            <ErrorIcon className={cls.alertIcon} />
          ) : (
            <ActualTime type="pickup" trip={trip} cls={cls} />
          )}
        </TableCell>
      ) : null}
      {!boardCollapsed ? (
        <TableCell component="td" align="left">
          <Box className={timeWrapperCls}>
            {trip.trip_type === "to_school" ? <ArrowRightIcon className={cls.tripIcon} /> : null}
            {trip.dropoff_time}
            {tripTz(trip.time_zone_abbr, cls.tripTz)}
          </Box>
        </TableCell>
      ) : null}
      {boardFull
        ? [
            <TableCell component="td" align="left" key="dropoff">
              <ActualTime type="dropoff" trip={trip} cls={cls} />
            </TableCell>,
            <TableCell component="td" align="left" key="vendor">
              {trip.vendor || (
                <Box className={cls.linkText}>
                  <WarningIcon fontSize="small" className={cls.warningIcon} key={"icon"} />
                  {vehiclePopupLink(I18n.t("trip.links.assign_vendor"))}
                </Box>
              )}
            </TableCell>,
            <TableCell component="td" align="left" key="mode">
              {trip.vehicle_type}
            </TableCell>,
            <TableCell component="td" align="left" key="vehicle">
              {vehiclePopupLink(
                trip.vehicle_info || (
                  <Box className={cls.linkText}>
                    <WarningIcon fontSize="small" className={cls.warningIcon} key={"icon"} />
                    {I18n.t("trip.links.assign_vehicle")}
                  </Box>
                )
              )}
              {!trip.booking && !trip.tracking_device_id && trip.vehicle_id && (
                <Box className={cls.linkText}>
                  <WarningIcon fontSize="small" className={cls.warningIcon} />
                  <RouterLink
                    to={pageRoutes.vehicleEdit(trip.vehicle_id)}
                    className={cls.tripLink}
                    target="_blank"
                    disabled={readOnly}
                  >
                    {I18n.t("trip.links.assign_tracking_device")}
                  </RouterLink>
                </Box>
              )}
            </TableCell>,
            <TableCell key="tracking">{trackingSwitch()}</TableCell>
          ]
        : null}
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  tripLinkWrapper: {
    display: "inline-block",
    marginTop: theme.spacing(0.25),
    cursor: "pointer"
  },
  tripLink: {
    cursor: "pointer"
  },
  linkText: {
    display: "flex",
    color: theme.custom.BLUE,
    textDecoration: "underline",
    alignItems: "center",
    marginLeft: theme.spacing(-4)
  },
  routeCell: {
    position: "relative",
    paddingRight: theme.spacing(0.25)
  },
  check: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0),
    margin: theme.spacing(0, 2),
    "&$checked": {
      color: theme.custom.DARK_GREY_2
    }
  },
  checkedRow: {
    backgroundColor: theme.custom.LIGHT_GREY_2
  },
  checked: {},
  cellContent: {
    display: "flex",
    alignItems: "center"
  },
  shiftLeft: {
    marginLeft: theme.spacing(-3)
  },
  tripIcon: {
    color: theme.custom.BLUE
  },
  alertIcon: {
    display: "block",
    color: theme.custom.RED
  },
  etaIcon: {
    fontSize: "0.8rem",
    alignSelf: "flex-start"
  },
  noticeIcon: {
    color: "#6B7D8E",
    marginLeft: theme.spacing(1)
  },
  warningIcon: {
    color: "#FFA500",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  copyLink: {
    cursor: "pointer",
    textDecoration: "none"
  }
}));

export default ArrivalBoardRow;
