import React from "react";
import FormSelect from "components/admin_v2/ui/FormSelect";
import { get } from "lodash";
import AddressNewForm from "./AddressNewForm";

const AddressSchoolForm = (props) => {
  const { store, stopType, tripType, cls, returnTrip = false, disabled = false } = props;
  const [state, actions] = store;

  if (returnTrip || state.schoolAddresses?.length === 1) {
    return <AddressNewForm {...props} />;
  }

  const stop = get(state.stops, `${tripType}.${stopType}`);
  const onChange = (e) => {
    const stopLocation = state.routableSchoolsAddresses.find((a) => a.id === e.target.value);
    actions.setSchoolStopLocation({ stopLocation, stopType, tripType });
  };

  return (
    <FormSelect
      itemType="school"
      i18Key="stop"
      options={state.schoolAddresses}
      value={stop?.masterId}
      onChange={onChange}
      cls={cls}
      clsKeys={["select", "select"]}
      withoutLabel={true}
      disabled={disabled}
      required
    />
  );
};

export default AddressSchoolForm;
