import React, { Fragment } from "react";
import cn from "classnames";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import { useStoreState } from "easy-peasy";
import I18n from "utils/i18n.js";
import { commaErrors, strPresent } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const DismissalGroupForm = ({ store }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { groupForm, errors } = state;
  const { schools } = useStoreState((s) => s.app);

  const onChange = (field) => (e) => {
    actions.setGroupForm({ ...groupForm, [field]: e.target.value });
  };

  return (
    <Fragment>
      <Grid container className={cn("dismissal-group", cls.grid)} spacing={5}>
        <Grid item xs={8}>
          <FormControl className={cls.field} error={!!errors?.schoolId} variant="standard">
            <InputLabel>{I18n.t("school_config.dismissal_groups.form.school.label")}</InputLabel>
            {schools.length && (
              <Select
                value={groupForm?.schoolId || ""}
                onChange={onChange("schoolId")}
                className="dismissal-group-school"
                MenuProps={{ className: "dismissal-group-school-menu" }}
              >
                {schools.map((school) => (
                  <MenuItem key={school.id} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {errors?.schoolId && <FormHelperText>{commaErrors(errors?.school_id)}</FormHelperText>}
          </FormControl>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("school_config.dismissal_groups.form.name.label")}
                condition={strPresent(groupForm?.name)}
              />
            }
            value={groupForm?.name || ""}
            onChange={onChange("name")}
            className={cn("dismissal-group-name", cls.field)}
            error={!!errors?.name}
            helperText={commaErrors(errors?.name)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DismissalGroupForm;
