import React from "react";
import { Select, MenuItem } from "@mui/material";
import I18n from "utils/i18n.js";

const STATUSES = {
  unenroll_form: ["approved", "rejected"],
  status: ["approved", "pending", "rejected"]
};

const ChangeReqFormStatus = ({ cls, statusType, store, isUserAdmin }) => {
  const [state, actions] = store;
  const selectResolveStatus = (e) => {
    actions.setStatus(e.target.value);
  };
  return (
    <Select
      className={cls.select}
      value={state.displayStatus}
      onChange={selectResolveStatus}
      variant="outlined"
      displayEmpty
      disabled={!isUserAdmin || state.resolved}
      data-test-id="resolve-type"
    >
      <MenuItem value="">{I18n.t(`change_requests.${statusType}.resolve_type`)}</MenuItem>
      {STATUSES[statusType].map((status) => (
        <MenuItem key={`status-sel-${status}`} value={status}>
          {I18n.t(`change_requests.${statusType}.option_${status}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ChangeReqFormStatus;
