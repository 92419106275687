import React, { Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Divider } from "@mui/material";
import { useStoreState } from "easy-peasy";
import { map } from "lodash";
import Spinner from "components/admin_v2/ui/Spinner";
import StopLocationForm from "./StopLocationForm";
import StopDirectionForm from "./StopDirectionForm";
import { componentsJoin } from "lib/util";
import useTripStyles from "components/admin_v2/students/trip/useStyles";

const StopsForm = ({ store }) => {
  const cls = useStyles();
  const clsTrip = useTripStyles();
  const { route } = useStoreState((s) => s.routeEditor);
  const [state, _actions] = store;

  const directions = map(route.directions, (d) => (
    <StopDirectionForm key={`dir-${d.type}`} cls={clsTrip} store={store} direction={d} />
  ));

  const renderSeparator = (idx) => <Divider key={`r-direction-${idx}`} className={cls.separator} />;

  return state.loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <StopLocationForm store={store} cls={clsTrip} />
      <Divider variant="fullWidth" className={cls.separator} />
      {componentsJoin(directions, renderSeparator)}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  separator: {
    margin: theme.spacing(2, 0)
  },
  label: {
    fontWeight: theme.custom.BOLD,
    flex: 1,
    marginBottom: theme.spacing(1)
  },
  bold: {
    fontWeight: theme.custom.BOLD
  },
  locationInput: {
    width: "100%",
    backgroundColor: theme.custom.WHITE,
    height: theme.spacing(5),
    padding: theme.spacing(0, 1)
  }
}));

export default StopsForm;
